import {
  type ValidatedInput,
  type ToolAutocomplete,
  AutocompleteGroups
} from "@toolflow/shared";

function formatToolbuilderBlockForAutocomplete(
  input: ValidatedInput,
  group: AutocompleteGroups.Inputs | AutocompleteGroups.BlockOutputs
): ToolAutocomplete {
  return {
    primary: input.config?.label || input.name,
    secondary: "",
    id: input.config?.label || input.name || "",
    group
  };
}

export default formatToolbuilderBlockForAutocomplete;
