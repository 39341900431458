import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import { ChipNodeHtmlTags, ClickableTypes } from "@toolflow/shared";
import { useEffect, useMemo } from "react";
import useWorkspaceAssets from "../../../../../features/pages/workstation/assets/useWorkspaceAssets";
import SnippetChip from "../../../chips/SnippetChip";

const AssetViewerComponent = (props: NodeViewProps) => {
  const assets = useWorkspaceAssets();

  const asset = useMemo(() => {
    return assets.filter((i) => i.id === props.node.attrs.inputId)[0];
  }, [props.node.attrs.inputId, assets]);

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode
    queueMicrotask(() => {
      try {
        if (!!asset) {
          props.updateAttributes({
            textValue: asset.value,
            name: asset.name
          });
        } else {
          props.deleteNode(); // when an input gets deleted (like if the tool was deleted), we want to delete the inputs
        }
      } catch {}
    });
  }, [asset?.name, asset?.value]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.InputComponent} dontTriggerBubble`}
    >
      {asset && (
        <SnippetChip
          text={asset.name}
          onDelete={props.deleteNode}
          type={ClickableTypes.INPUT}
          tabIndex={-1}
        />
      )}
    </NodeViewWrapper>
  );
};

export default AssetViewerComponent;
