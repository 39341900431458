import { Grid, Typography } from "@mui/material";
import { type JsonSchemaInputWrapperProps } from "@toolflow/shared";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import JsonSchemaConstructor from "./jsonSchemaConstructor/JsonSchemaConstructor";

function JsonSchemaInputField({
  field,
  id,
  settings
}: JsonSchemaInputWrapperProps) {
  const { fieldKey } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });
  const schema = getFieldValue(fieldKey, "");

  return (
    <Grid container spacing={2} className="m-t-8px">
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h8">{field.label}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitleLarge">{field.subtitle}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <JsonSchemaConstructor id={id} schema={schema} />
      </Grid>
    </Grid>
  );
}

export default JsonSchemaInputField;
