import { Box } from "@mui/material";
import type { SheetAsset } from "@toolflow/shared";
import React from "react";
import { useAssetContext } from "../../../../../../../../utilities/contexts/AssetContext";
import AssetName from "../../AssetName";
import SheetAssetIcons from "../SheetAssetIcons";

function SheetAssetHeader() {
  const { asset: input } = useAssetContext<SheetAsset>();
  return (
    <Box className="flex align-i-center w-100-percent p-24px">
      <AssetName text={input.name} />
      <SheetAssetIcons />
    </Box>
  );
}

export default SheetAssetHeader;
