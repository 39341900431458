import React from "react";
import ProfileAction from "./ProfileAction";
import UpdateProfileField from "./UpdateProfileField";
import ResetPasswordButton from "./ResetPasswordButton";
import { Divider, Typography } from "@mui/material";
import { useIsAuth0DatabaseConnection } from "../../../../auth/hooks/useIsAuth0DatabaseConnection";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";

const ChangePasswordOrEmail = () => {
  const { isAuth0DatabaseConnection } = useIsAuth0DatabaseConnection();

  if (!isAuth0DatabaseConnection()) return null;
  return (
    <FullWidthCard className="p-32px m-t-16px m-b-32px">
      <Typography variant="h7">Email</Typography>
      <ProfileAction isSocial className="m-t-8px">
        <UpdateProfileField
          type="email"
          label="Email"
          successMessage="Email updated and verification email sent"
          className="m-t-24px"
        />
      </ProfileAction>
      <Divider className="m-v-24px" />
      <Typography variant="h7">Password</Typography>
      <div className="m-t-16px">
        <ProfileAction isSocial>
          <ResetPasswordButton />
        </ProfileAction>
      </div>
    </FullWidthCard>
  );
};

export default ChangePasswordOrEmail;
