import React from "react";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import styles from "./loadingThreadMessage.module.css";

const cx = parsedClassNames.bind(styles);

const LoadingDot = ({
  isLast,
  animationDelay
}: {
  isLast?: boolean;
  animationDelay: string;
}) => {
  return (
    <div
      className={cx("flex align-i-center", styles["loading-dot-wrapper"], {
        [styles["loading-dot-spacing"]]: !isLast
      })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={"0 0 8 8"}
        style={{ animationDelay }}
        className={cx(styles["loading-dot-animate"])}
      >
        <circle cx="4" cy="4" r="3" />
      </svg>
    </div>
  );
};

const LoadingThreadMessage = () => {
  return (
    <div className={cx(styles["loading-wrapper"], "flex align-i-center")}>
      <LoadingDot animationDelay="0s" />
      <LoadingDot animationDelay="0.3s" />
      <LoadingDot isLast animationDelay="0.6s" />
    </div>
  );
};
export default LoadingThreadMessage;
