import type { MultiUserInputResponseWithHandledError } from "@toolflow/shared";
import updateSingleValueInRow from "./updateSingleValueInRow";
import updateSheetDataWithRow from "./updateSheetDataWithRow";
import { TSetSheetData, TSheetDataRef } from "../../sheetTypes";

const updateSheetDataWithSingleValue = (
  rowIndex: number,
  fieldName: string,
  newValue: MultiUserInputResponseWithHandledError,
  sheetDataRef: TSheetDataRef,
  setSheetData: TSetSheetData
) => {
  const existingValue = sheetDataRef.current[rowIndex][fieldName];
  if (JSON.stringify(existingValue) !== JSON.stringify(newValue)) {
    const updatedRow = updateSingleValueInRow(
      sheetDataRef.current[rowIndex],
      fieldName,
      newValue
    );
    const newSheetData = updateSheetDataWithRow(
      sheetDataRef.current,
      updatedRow,
      rowIndex
    );

    setSheetData(newSheetData);
  }
};

export default updateSheetDataWithSingleValue;
