// src/store/navigationSlice.ts
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import getTitleByPath from "./functions/getTitleByPath";

interface NavigationState {
  currentPath: string;
  previousPath: {
    path: string;
    documentTitle: string;
  };
  currentPathWithDocumentTitle: {
    path: string;
    documentTitle: string;
  };
}

const initialState: NavigationState = {
  currentPath: "/",
  previousPath: {
    documentTitle: "Toolflow",
    path: ""
  }, // we can make this an array if we want to store multiple paths
  currentPathWithDocumentTitle: {
    documentTitle: "Toolflow",
    path: ""
  }
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    updatePaths(
      state,
      action: PayloadAction<{ path: string; replaced?: boolean }>
    ) {
      if (!action.payload.replaced) {
        state.previousPath = {
          path: state.currentPath,
          documentTitle: getTitleByPath(state.currentPath)
        };
      }
      state.currentPath = action.payload.path;
      state.currentPathWithDocumentTitle = {
        path: action.payload.path,
        documentTitle: getTitleByPath(action.payload.path)
      };
    }
  }
});

export const { updatePaths } = navigationSlice.actions;
export default navigationSlice.reducer;
