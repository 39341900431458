import type { CustomToolOutputField } from "@toolflow/shared";
import { useDispatch } from "react-redux";
import { outputIconDict } from "../../../../../../../../../utilities/constants/iconDict";
import { DrawerEnum, setDrawer } from "../../../../../store/toolBuilderSlice";
import ClickableInputOutput from "../../inputs/inputBuilders/ClickableInputOutput";
import DeleteToolOutputFieldButton from "./DeleteToolOutputFieldButton";
import { ToolbuilderOutputFieldContextProvider } from "./ToolbuilderOutputFieldContext";

function ToolOutputField({
  toolOutputField
}: {
  toolOutputField: CustomToolOutputField;
}) {
  const reduxDispatch = useDispatch();
  function handleUpdateOutputClick(id: string) {
    reduxDispatch(
      setDrawer({
        type: DrawerEnum.outputs,
        outputId: id
      })
    );
  }

  return (
    <ToolbuilderOutputFieldContextProvider toolOutputField={toolOutputField}>
      <div className="flex">
        <ClickableInputOutput
          icon={outputIconDict[toolOutputField.type]}
          name={toolOutputField.config?.label || toolOutputField.name}
          id={toolOutputField.id}
          SecondaryAction={DeleteToolOutputFieldButton}
          className="m-b-8px"
          onClick={handleUpdateOutputClick}
        />
      </div>
    </ToolbuilderOutputFieldContextProvider>
  );
}

export default ToolOutputField;
