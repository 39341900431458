import type { EditorOptions } from "@tiptap/core";
import { useMemo } from "react";
import {
  type TExtensionsConfig,
  type UseExtensionsOptions
} from "@toolflow/shared";
import { customExtensions } from "./customExtensions";
import { getExtensionsFromConfig } from "./getExtensionsFromConfig";

export default function useExtension({
  placeholder,
  disableCustomExtensions = false,
  enableAutolinkExtension = false,
  disableDefaultEnter = false,
  disableFormattingExtensions = false
}: TExtensionsConfig & UseExtensionsOptions = {}): EditorOptions["extensions"] {
  return useMemo(
    () =>
      getExtensionsFromConfig(
        {
          placeholder,
          disableCustomExtensions,
          enableAutolinkExtension,
          disableDefaultEnter,
          disableFormattingExtensions
        },
        customExtensions
      ),
    [
      placeholder,
      disableCustomExtensions,
      enableAutolinkExtension,
      disableDefaultEnter,
      disableFormattingExtensions
    ]
  );
}
