import { useEffect } from "react";
import useCurrentAssetsRef from "../../../../pages/workstation/assets/useCurrentAssetsRef";
import useToolCompleted from "./useToolCompleted";
import useSetInputLoadingFalse from "./useSetInputLoadingFalse";
import useToolStillLoading from "./useToolStillLoading";
import { useEditorToolCardContext } from "../EditorToolCardContext";

const useStopInputLoadingOnToolComplete = () => {
  const { handleClose } = useEditorToolCardContext();
  const currentAssets = useCurrentAssetsRef();

  const toolCompleted = useToolCompleted();

  const setInputLoadingFalse = useSetInputLoadingFalse();
  const toolStillLoading = useToolStillLoading();
  // i think we need a timer here instead of a queue microtask,
  // because otherwise, the end of the prompt might get cut off
  useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    if (toolCompleted) {
      timerId = setTimeout(() => {
        setInputLoadingFalse(currentAssets.current);
        handleClose();
      }, 300);

      // note -> previously we opened / closed websockets here
      // deleting a node closed the websocket which messed up any other
      // websocket call
      // instead, we are keeping one websocket open for the entire app
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [toolStillLoading]);
};

export default useStopInputLoadingOnToolComplete;
