import React from "react";
import useGetMappedInput from "./hooks/useGetMappedInput";
import {
  HARDCODE_INPUT_TYPE,
  MAP_TO_PREVIOUS_INPUT_TYPE,
  REQUEST_INPUT_TYPE
} from "../../WorkflowBuilderConstants";
import MapToPreviousInputSelect from "./MapToPreviousInputSelect";
import HardCodeInputField from "./HardcodeInputField";
import { useCustomToolInputFieldContext } from "../../../../../../utilities/contexts/CustomToolInputFieldContext";
import { CardContent } from "@mui/material";

const additionFieldsMapper = {
  [HARDCODE_INPUT_TYPE]: <HardCodeInputField />,
  [REQUEST_INPUT_TYPE]: null,
  [MAP_TO_PREVIOUS_INPUT_TYPE]: <MapToPreviousInputSelect />
};

function AdditionalActionSettings() {
  const { input } = useCustomToolInputFieldContext();
  const mappedInput = useGetMappedInput(input.id);
  if (!mappedInput) return null;
  const elementToDisplay = additionFieldsMapper[mappedInput.type];
  if (!elementToDisplay) return null;
  return <CardContent>{elementToDisplay}</CardContent>;
}

export default AdditionalActionSettings;
