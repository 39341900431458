import React from "react";
import { ThemeProvider } from "@mui/material";
import ToolflowHeader from "../../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import { SnippetFormCardContainer } from "./SnippetFormCardContainer";
import SnippetBreadcrumbs from "./SnippetBreadcrumbs";
import CenterContentContainer from "../../../../utilities/components/containers/CenterContentContainer";
import knowledgeTheme from "../theme/knowledgeTheme";

function SnippetFormPage() {
  return (
    <ThemeProvider theme={knowledgeTheme}>
      <ToolflowHeader title="Knowledge">
        <CenterContentContainer>
          <SnippetBreadcrumbs />
          <SnippetFormCardContainer />
        </CenterContentContainer>
      </ToolflowHeader>
    </ThemeProvider>
  );
}

export default SnippetFormPage;
