import React, { useEffect, useRef } from "react";
import { CssBaseline, useTheme } from "@mui/material";
import useExtension from "./hooks/useExtensions/useExtension";
import type { Content } from "@tiptap/core";
import { RichTextReadOnly } from "mui-tiptap";
import type { RichTextEditorRef } from "mui-tiptap";

import { ThemeProvider } from "@emotion/react";

export const GenericRichContent = ({
  content,
  className = "",
  rerender = false
}: {
  content: Content;
  className?: string;
  rerender?: boolean; // if we want the editor to be able to rerender, like the design page:
}) => {
  const rteRef = useRef<RichTextEditorRef>(null);
  const extensions = useExtension();

  // i don't like doing this, but i've added tiptap
  // styles to app.css with !important to space out the nodes a bit
  // having trouble with precedence of themes right now and
  // styling the mui-tiptap
  const theme = useTheme();
  const editor = rteRef.current?.editor;
  useEffect(() => {
    if (!editor || editor.isDestroyed || !rerender) {
      return;
    }
    if (!editor.isFocused || !editor.isEditable) {
      // Use queueMicrotask per https://github.com/ueberdosis/tiptap/issues/3764#issuecomment-1546854730
      queueMicrotask(() => {
        editor.chain().setContent(content).blur().run();
      });
    }
  }, [
    JSON.stringify(content),
    editor,
    editor?.isEditable,
    editor?.isFocused,
    rerender
  ]);

  if (!content) return null;

  return (
    <div className={className}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* We use RichTextEditor because we need to allow people to highlight the text
          RichTextReadOnly makes contentEditable="false" which doesnt allow people to select text
          we then handle keydown events to not allow people to type within the text ,
          but we allow copying with copy-c*/}
        <RichTextReadOnly
          autofocus={"all"}
          // editable={false}
          // ref={rteRef}
          content={content}
          extensions={extensions}
          // RichTextFieldProps={{ variant: "standard" }}
          editorProps={{
            handleDOMEvents: {
              keydown: (view, event) => {
                if ((event.ctrlKey || event.metaKey) && event.key === "c") {
                } else {
                  event.preventDefault();
                }
                return true;
              }
            }
          }}
        />
      </ThemeProvider>
    </div>
  );
};
