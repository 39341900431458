import { useDispatch, useSelector } from "react-redux";
import { useStepsFieldArrayContext } from "../StepsContext";
import useGetStepIndex from "./useGetStepIndex";
import { selectStepId, updateStepId } from "../../workflowBuilderSlice";

const useHandleRemoveStep = (stepId: string) => {
  const { remove } = useStepsFieldArrayContext();
  const stepIndex = useGetStepIndex(stepId);
  const dispatch = useDispatch();
  const selectedStep = useSelector(selectStepId);
  const closeStep = () => {
    if (selectedStep === stepId) {
      dispatch(updateStepId(undefined));
    }
  };
  const handleRemove = () => {
    closeStep();
    remove(stepIndex);
  };
  return handleRemove;
};

export default useHandleRemoveStep;
