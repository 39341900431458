import { useEffect, useRef } from "react";
import { useAuthStore } from "../../../../../features/auth/hooks/useAuthStore";

const useIsLoadingRef = () => {
  const { isLoading } = useAuthStore();
  // needed to handleDomEvent to disable inserting a "/" when the editor is loading
  const isLoadingRef = useRef<boolean>(isLoading);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);
  return isLoadingRef;
};

export default useIsLoadingRef;
