import { useMarketplaceToolEntities } from "./useMarketplaceToolEntities";
import { getUniqueEntity } from "../../entities/functions/getUniqueEntity";
import { useGetCurrentUserTools } from "./useGetCurrentUserTools";

const useGetAvailableTools = () => {
  const { tools } = useGetCurrentUserTools();
  const marketplaceEntities = useMarketplaceToolEntities();
  return getUniqueEntity([...marketplaceEntities, ...tools]);
};

export default useGetAvailableTools;
