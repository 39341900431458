import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { setDialogOpen } from "../../store/toolBuilderSlice";

const useBuilderDrawer = () => {
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  const drawerExpanded = useSelector(
    (state: RootState) => state.toolbuilder.drawerExpanded
  );
  const dispatch = useDispatch();

  const dialogOpen = useSelector(
    (state: RootState) => state.toolbuilder.dialogOpen
  );

  const closeDialog = () => {
    dispatch(setDialogOpen(false));
  };

  return {
    drawer,
    expanded: drawerExpanded,
    dialogOpen,
    closeDialog
  };
};

export default useBuilderDrawer;
