import type { TWorkflowProgress, TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL } from "../../../../helpers/workspaceConstants";
import { useFormContext } from "react-hook-form";
import useGetWorkflowProgress from "./useGetWorkflowProgress";

const useSetWorkflowProgress = (workflowStepId: string) => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const workflowProgress = useGetWorkflowProgress();
  const setWorkflowProgress = (workflowStepProgress: TWorkflowProgress) => {
    setValue(
      WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL,
      { ...workflowProgress, [workflowStepId]: workflowStepProgress },
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      }
    );
  };
  return setWorkflowProgress;
};

export default useSetWorkflowProgress;
