import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { FileReference } from "@toolflow/shared";
import { useSelector } from "react-redux";
import { useGetFilesQuery } from "../../ToolflowAPI/rtkRoutes/fileApi";
import { selectAllFiles } from "../../ToolflowAPI/rtkRoutes/selectors/fileSelectors";

function FileDropdown({
  action,
  disabled,
  currentFile,
  fileExtensions
}: {
  action: (s: FileReference | null) => void;
  disabled?: boolean;
  currentFile: FileReference | null;
  fileExtensions?: string[];
}) {
  useGetFilesQuery();
  const files = useSelector(selectAllFiles).filter(
    (file) =>
      !fileExtensions || fileExtensions.includes(`.${file.fileExtension}`)
  );

  const handleSelect = (e: SelectChangeEvent) => {
    if (files) {
      const selectedFile = files.find(
        (file: FileReference) => file._id === e.target.value
      );
      action(selectedFile || null);
    }
  };

  if (!files || files.length === 0) return null;

  return (
    <FormControl
      margin="normal"
      className="flex-grow-1"
      disabled={disabled}
      fullWidth
    >
      <InputLabel>Loaded files</InputLabel>
      <Select
        label="Loaded Files"
        value={currentFile?._id || "Upload new file"}
        className="nowheel nodrag nopan"
        onChange={handleSelect}
      >
        <MenuItem value={"Upload new file"}>Upload new file</MenuItem>
        {files &&
          files.map((file: FileReference) => (
            <MenuItem value={file._id} key={file._id}>
              {file.fileName}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

export default FileDropdown;
