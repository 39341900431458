import { useMemo } from "react";
import { getPromptFillerBase, type TExtensionsConfig } from "@toolflow/shared";
import { getExtensionsFromConfig } from "../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";
import { generateText, type JSONContent } from "@tiptap/core";
import { customExtensions } from "../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { selectAllSnippets } from "../../../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import { useSelector } from "react-redux";

const usePromptFiller = (extensionsConfig: TExtensionsConfig) => {
  const extensions = getExtensionsFromConfig(
    extensionsConfig,
    customExtensions
  );
  const snippets = useSelector(selectAllSnippets);

  const insertUserInputIntoPrompt = useMemo(
    () =>
      getPromptFillerBase(
        (content: JSONContent) => generateText(content, extensions),
        snippets
      ),
    [extensions, snippets]
  );

  return insertUserInputIntoPrompt;
};

export default usePromptFiller;
