import React from "react";
import NavigationProviderBlocker from "../../../navigation/components/NavigationProviderBlocker";
import ResetAgentButton from "./ResetAgentButton";
import { AGENT } from "@toolflow/shared";
import SaveAgentDialog from "./SaveAgentDialog/SaveAgentDialog";
import useAgentBlocker from "./hooks/useAgentBlocker";

function AgentNavigationProvider({ children }: { children: React.ReactNode }) {
  const blocker = useAgentBlocker();

  return (
    <NavigationProviderBlocker
      blocker={blocker}
      type={AGENT}
      actions={
        <>
          <ResetAgentButton />
          <SaveAgentDialog />
        </>
      }
    >
      {children}
    </NavigationProviderBlocker>
  );
}
export default AgentNavigationProvider;
