import {
  FieldType,
  type FieldConfig,
  type ScraperBlockPropsV1
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../../../useUpdateField";

function ScraperBlockInnerV1({
  data,
  id
}: Omit<ScraperBlockPropsV1, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const updateField = useUpdateField(id);

  const configs: FieldConfig = {
    id,
    data: settings,
    updateField,
    fields: [
      {
        type: FieldType.PromptInput,
        label: "Url",
        config: {
          prependKey: "settings",
          fieldKey: "urlFieldInputKey",
          maxRows: 1,
          minRows: 1
        }
      },
      {
        type: FieldType.SelectInputV1,
        label: "Preferred Result Type",
        config: {
          fieldKey: "preferredScraperResultType",
          options: ["markdown", "html", "text"]
        }
      }
    ]
  };

  return (
    <div className="m-t-16px">
      <FieldsByType configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}

export default ScraperBlockInnerV1;
