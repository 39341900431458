import React from "react";
import { TextField } from "@mui/material";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../../../globalTheme/muiUtils/appTheme";
import handleEnterBlur from "../../../../../../../../../utilities/functions/handleEnterBlur";

function ChangeInputFieldDescriptionTextField({
  disabled
}: {
  disabled?: boolean;
}) {
  const index = useGetCurrentToolInputFieldIndex();
  const description = useGetCurrentToolInputField()?.description;
  const { changeInputDescription } = useToolInputFieldsContext();

  return (
    <TextField
      fullWidth
      disabled={disabled}
      // size={small ? "small" : undefined}
      label="Description"
      autoComplete="off"
      multiline
      onKeyDown={handleEnterBlur}
      maxRows={MAX_ROWS_LARGE_TEXTFIELD}
      className="nowheel nodrag nopan"
      value={description}
      onChange={(e) => changeInputDescription(e.target.value, index)}
    />
  );
}

export default ChangeInputFieldDescriptionTextField;
