import React from "react";
import { LinkBubbleMenu, TableBubbleMenu } from "mui-tiptap";
import ToolBubbleMenu from "./toolBubbleMenu/ToolBubbleMenu";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";

const WorkspaceMenus = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { disableFormattingMenu, container } = muiTipTapProps;
  return (
    <>
      {!disableFormattingMenu && (
        <>
          <LinkBubbleMenu container={container?.current} />
          <TableBubbleMenu container={container?.current} />
        </>
      )}
      <ToolBubbleMenu />
    </>
  );
};

export default WorkspaceMenus;
