import type {
  BlockDataTypesWithDefault,
  DecimalString
} from "@toolflow/shared";
import createBlockDict from "./helpers/createBlockDict";

const getBlockPaperLabel = (
  type: BlockDataTypesWithDefault["type"],
  version?: DecimalString
): string => {
  const dict = createBlockDict<string>("blockPaperLabel", version);

  return dict[type] || dict.default;
};

export default getBlockPaperLabel;
