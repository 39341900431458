import React from "react";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
const cx = classNames;

function BottomGradient({ className = "" }: { className?: string }) {
  return (
    <div
      className={cx("pos-absolute w-100-percent bottom-gradient", className)}
    />
  );
}

export default BottomGradient;
