import React from "react";
import EntityNotFound from "./EntityNotFound";
import ElementGrid from "./ElementGrid";
import type {
  TMarketplaceUniversalEntity,
  TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import CategoryTabs from "./categories/CategoryTabs";

export function SearchTools({
  entities,
  EntityElement,
  entityType
}: {
  entities: TMarketplaceUniversalEntity[];
  EntityElement: React.FC<{ entity: TMarketplaceUniversalEntity }>;
  entityType: TMarketplaceUniversalEntityType;
}) {
  return (
    <div>
      <CategoryTabs />
      {entities?.length === 0 ? (
        <EntityNotFound entityType={entityType} />
      ) : (
        <ElementGrid
          marketplaceEntities={entities}
          EntityElement={EntityElement}
        />
      )}
    </div>
  );
}
