import { Button } from "@mui/material";
import React from "react";
import styles from "./disabledHandleButton.module.css";

// hack for now -> can reincorporate later if necessary
const DisabledHandleButton = ({ text }: { text: string }) => {
  return (
    <Button
      fullWidth
      color="error"
      disableTouchRipple
      className={`${styles["no-opacity-imp"]} noDrag cursor-grab`}
      variant="outlined"
    >
      {text}
    </Button>
  );
};

export default DisabledHandleButton;
