import { Stack } from "@mui/material";
import {
  BlockNode,
  type ConstantBlockDataV2,
  type ConstantBlockPropsV2
} from "@toolflow/shared";
import { useMemo } from "react";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import TestToolRun from "../../../../common/TestToolRun";
import TestOutput from "../../../components/testWrapper/TestOutput";
import useFields from "../../../hooks/useFields";
import useTest from "../../../hooks/useTest";
import usePromptFiller from "../../../promptBlocks/usePromptFiller";
import TestFieldsWithPrompt from "../../../components/testWrapper/TestFieldsWithPrompt";

export default function ConstantTestFields({
  data,
  id
}: Omit<ConstantBlockPropsV2, "selected">) {
  const getChipsList = useGetChips();
  const { userInput, setUserInput } = useFields([]);

  const constant = data.constant;
  const fields = useMemo(() => getChipsList(constant), [constant]);

  const { action, label, text, endIcon, showUpgrade, loading, output } =
    useTest<ConstantBlockDataV2>({
      data,
      id,
      fields,
      blockType: BlockNode.ConstantBlockNode,
      userInput
    });

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(constant, userInput),
    [userInput, constant, insertUserInputIntoPrompt]
  );

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFieldsWithPrompt
            fields={fields}
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
          />
          <TestOutput output={output} label={label} />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}
