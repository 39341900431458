import { TEntityTab, marketplaceCategoryTabs } from "../constants";
import type { TEntityFilterByCategoriesFE } from "@toolflow/shared";

export const getCategoryIndex = (
  tabs: TEntityTab[],
  category: TEntityFilterByCategoriesFE
) =>
  // If the category is not found, return 0
  // this can happen when the category is disabled before useSwitchCategory executes
  Math.max(
    0,
    tabs.findIndex((tab) => tab.value === category)
  );
const getCategoryTab = (category: TEntityFilterByCategoriesFE) => {
  return marketplaceCategoryTabs.filter((tab) => tab.value === category)[0];
};
export function isSelectedTabDisabled(
  reduxCategory: TEntityFilterByCategoriesFE,
  selectedTab: string
) {
  const selectedCategoryTab = getCategoryTab(reduxCategory);
  const isTabDisabled = selectedCategoryTab.disabledTabs?.includes(selectedTab);
  return isTabDisabled;
}
