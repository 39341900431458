import type {
  BlockDataTypesWithDefault,
  DecimalString
} from "@toolflow/shared";
import createBlockDict from "./helpers/createBlockDict";
import AnthropicLogo from "../../../../../../globalTheme/icons/logos/AnthropicLogo";
import OpenAILogo from "../../../../../../globalTheme/icons/logos/OpenAILogo";

const getBlockHeaderIcon = (
  type: BlockDataTypesWithDefault["type"],
  version?: DecimalString
): React.ElementType => {
  const dict = createBlockDict<React.ElementType>("icon", version, undefined, {
    Anthropic: AnthropicLogo,
    default: OpenAILogo
  });

  return dict[type] || dict.default;
};

export default getBlockHeaderIcon;
