import type {
  RowUserInputDictType,
  MultiUserInputResponseWithHandledError
} from "@toolflow/shared";

const updateSingleValueInRow = (
  currentRow: RowUserInputDictType,
  fieldName: string,
  newValue: MultiUserInputResponseWithHandledError
) => {
  return {
    ...currentRow,
    [fieldName]: newValue
  };
};
export default updateSingleValueInRow;
