import { createSelector } from "@reduxjs/toolkit";
import { fileApi } from "../fileApi";
import {
  filesAdapter,
  filesInitialState
} from "../../../stores/adapters/filesAdapter";
import { RootState } from "../../../stores/store";

const selectFilesResult = fileApi.endpoints.getFiles.select();

const selectFilesData = createSelector(
  selectFilesResult,
  (filesResult) => filesResult.data
);

export const { selectAll: selectAllFiles, selectById: selectFileById } =
  filesAdapter.getSelectors(
    (state: RootState) => selectFilesData(state) ?? filesInitialState
  );
