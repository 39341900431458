import React from "react";
import FindToolButton from "./FindToolButton";
import { Typography } from "@mui/material";
import TextButtonGray from "./textButtonGray/textButtonGray";
import { ReconnectIcon } from "../../../../../../../globalTheme/icons/icons";
import { useWebsocketContext } from "../../../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";

function ChatWebsocketReconnect() {
  const { reconnect } = useWebsocketContext();
  return (
    <div className="w-100-percent flex justify-space-between align-i-center">
      <Typography variant="chipLabel" color="error">
        Disconnected
      </Typography>
      <TextButtonGray
        onClick={reconnect}
        text="Reconnect"
        Icon={ReconnectIcon}
      />
    </div>
  );
}

function ChatBottomButton({ className = "" }: { className?: string }) {
  const { showReconnectButton } = useWebsocketContext();
  return (
    <div className={className}>
      {showReconnectButton ? <ChatWebsocketReconnect /> : <FindToolButton />}
    </div>
  );
}
export default ChatBottomButton;
