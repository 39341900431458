import React from "react";
import { useAuthStore } from "../../../../../../features/auth/hooks/useAuthStore";
import { useNodeViewContext } from "../../context/NodeViewContext";
import { useGetToolQuery } from "../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import ToolflowSkeletonWrapper from "../../../../loading/ToolflowSkeleton";
import { ToolContextComponent } from "../../../../../../features/tools/contexts/ToolContext";
import ExternalToolCardContainer from "../../../../../../features/pages/workstation/components/entities/externalToolCardContainer/ExternalToolCardContainer";

function SearchedToolContainer({ index }: { index: number }) {
  const { isLoading } = useAuthStore();
  const { node } = useNodeViewContext<{ toolIds: string[] }>();

  const { data } = useGetToolQuery(node.attrs.toolIds[index], {
    skip: isLoading || !node.attrs.toolIds[index]
  });

  return (
    <ToolflowSkeletonWrapper loading={!data?.tool} width="100%">
      <ToolContextComponent tool={data?.tool}>
        <ExternalToolCardContainer />
      </ToolContextComponent>
    </ToolflowSkeletonWrapper>
  );
}

export default SearchedToolContainer;
