import {
  escapeRegExp,
  get as getValueFromObject,
  isArray,
  isBoolean,
  isObject,
  isString
} from "lodash";
import { isFileOrAudioData, UserInputDictType } from "../../types";
import {
  getNestedValues,
  getStringsBetweenBraces,
  validateAndParseJson
} from "../helpers";

export const stringPromptFromUserInput = (
  p: string,
  userInput: UserInputDictType
) => {
  const UIKeys = getStringsBetweenBraces(p);

  return UIKeys.reduce((acc, key) => {
    // we need to escape reg expression characters because
    // otherwise parenthesis etc cant be used in inputs
    const escapedKey = escapeRegExp(key);
    const regex = new RegExp(`{{${escapedKey}}}`, "g");
    const splitted = key.split(".");
    const value = userInput[splitted[0]];

    if (isFileOrAudioData(value)) {
      return acc.replace(regex, value.fileName);
    }

    if (isString(value)) {
      // We still wanna check if input is stringified Json for legacy purpose
      const jsonValue = validateAndParseJson(value);
      if (jsonValue) {
        let nestedValue = getNestedValues(
          jsonValue,
          splitted.slice(1).join(".")
        );
        if (isObject(nestedValue) || isArray(nestedValue)) {
          nestedValue = JSON.stringify(nestedValue);
        }
        return acc.replace(regex, nestedValue);
      }
      return acc.replace(regex, value);
    }

    if (isObject(value)) {
      const valueFromObject = getValueFromObject(userInput, key);
      if (isString(valueFromObject)) {
        return acc.replace(regex, valueFromObject);
      } else {
        return acc.replace(regex, JSON.stringify(valueFromObject));
      }
    }

    if (isBoolean(value)) {
      return acc.replace(regex, value.toString());
    }

    return acc;
  }, p);
};
