export enum TBlocksTagsLabels {
  OpenAI = "OpenAI",
  Deepgram = "Deepgram",
  WebScraper = "Web Scraper",
  DallE = "Dall-E",
  Anthropic = "Anthropic",
  Serp = "Serp"
}

export const BLOCKS_LABELS_ARRAY: readonly TBlocksTagsLabels[] = [
  TBlocksTagsLabels.Anthropic,
  TBlocksTagsLabels.DallE,
  TBlocksTagsLabels.Deepgram,
  TBlocksTagsLabels.OpenAI,
  TBlocksTagsLabels.WebScraper
] as const;

export type TBlockTagsType =
  | "promptBlockNode"
  | "deepgramBlockNode"
  | "scraperBlockNode"
  | "serpBlockNode"
  | "structuredBlockNode"
  | "perplexityBlockNode";

export enum TBlocksTagsKeys {
  ChatGPT = "ChatGPT",
  Anthropic = "Anthropic",
  WebsiteContentCrawler = "WebsiteContentCrawler",
  DeepgramTranscribe = "DeepgramTranscribe",
  DallE2 = "Dall-E2",
  DallE3 = "Dall-E3",
  serp = "serp"
}
