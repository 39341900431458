import type { SaveUpdateSnippetFE, UpdateSnippet } from "@toolflow/shared";
import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { useSaveSnippetMutation } from "../../../../ToolflowAPI/rtkRoutes/snippetsApi";
import { KNOWLEDGE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import { closeSnippetFormDialog } from "../../../snippets/slice/snippetSlice";

const useNavigateOrCloseDialog = () => {
  const { open } = useSelector(
    (state: RootState) => state.snippet.snippetFormDialog
  );
  const dispatch = useDispatch();
  const navigate = useToolflowNavigate();
  const handleNavigate = () => {
    if (!open) {
      navigate(KNOWLEDGE_ROUTE_PATH);
    } else {
      dispatch(closeSnippetFormDialog());
    }
  };
  return handleNavigate;
};

const useSaveSnippet = () => {
  const [saveSnippetMutation, { isLoading }] = useSaveSnippetMutation();
  const handleNavigate = useNavigateOrCloseDialog();
  const { handleSubmit } = useFormContext<UpdateSnippet>();

  const onSubmit: SubmitHandler<SaveUpdateSnippetFE> = async (body) => {
    const result = await saveSnippetMutation({
      body
    });
    if (!("error" in result)) {
      handleNavigate();
    }
  };

  const saveSnippet = () => {
    handleSubmit(onSubmit)();
  };

  return {
    saveSnippet,
    loading: isLoading
  };
};

export default useSaveSnippet;
