import { Paper } from "@mui/material";
import useGetAssetId from "../../../assets/useGetAssetId";
import AssetSwitch from "./AssetSwitch";

function Asset() {
  const assetId = useGetAssetId();

  if (!assetId) {
    return null;
  }

  return (
    <Paper
      variant="workspaceWrapper"
      className="w-100-percent h-100-percent pos-relative bg-color-white flex justify-space-between"
    >
      <AssetSwitch assetId={assetId} />
    </Paper>
  );
}

export default Asset;
