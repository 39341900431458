import React from "react";
import { ReactFlowProvider, Background, BackgroundVariant } from "reactflow";

import "reactflow/dist/style.css";
import "reactflow/dist/base.css";

import NodeSelector from "../nodeSelector/NodeSelector";
import DeleteBlockConfirmationDialog from "../DeleteBlockConfirmationDialog";
import Header from "../Header";
import { Box, Divider } from "@mui/material";

import { InputBox } from "../inputsOutputs/InputBox";
import { OutputBox } from "../inputsOutputs/OutputBox";
import BuilderDrawer from "../builderDrawer/BuilderDrawer";
import Flow from "./Flow";
import useGetElementHeight from "../../../workstation/components/assets/asset/textAsset/hooks/useGetElementHeight";
import { toolflowTextSecondaryColor } from "../../../../../globalTheme/muiUtils/appTheme";

const ToolBuilderInner: React.FC = () => {
  const { ref, height } = useGetElementHeight();
  return (
    <div>
      <Header />
      <Divider />
      <div style={{ display: "flex", position: "relative", left: "1px" }}>
        <Background
          id="1"
          gap={25}
          size={2}
          color={toolflowTextSecondaryColor}
          variant={BackgroundVariant.Dots}
        />

        <Box
          component="main"
          sx={{ flexGrow: 1, position: "relative" }}
          ref={ref}
        >
          <NodeSelector />
          <InputBox />
          <OutputBox />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              height: `calc(100vh - var(--no-tool-header-height) - 1px)` // the 1px is needed or scroll will appear
            }}
          >
            <Flow />
          </div>
        </Box>
        <DeleteBlockConfirmationDialog />

        <BuilderDrawer height={height} />
      </div>
    </div>
  );
};

function ToolBuilder() {
  return (
    <ReactFlowProvider>
      <ToolBuilderInner />
    </ReactFlowProvider>
  );
}

export default ToolBuilder;
