import React from "react";
import { RootState } from "../../../../stores/store";
import { useSelector, useDispatch } from "react-redux";
import { setSearchToolsTab } from "./slice/searchToolsSlice";
import { Divider, Tab, Tabs } from "@mui/material";
import type { TDisabledEntities, TSearchToolsTabType } from "@toolflow/shared";
import { SearchToolsContainer } from "./SearchToolsContainer";
import TabPanel from "../../../../utilities/components/tabs/TabPanel";
import styles from "./marketplaceTabs.module.css";
import { TToolTab } from "./toolTabsArray";
import { a11yProps } from "../../../../utilities/components/tabs/functions/a11yProps";
import { classNames } from "../../../../utilities/functions/parsedClassNames";

const useEntityCategory = (toolTabs: TToolTab[]) => {
  const dispatch = useDispatch();
  const reduxTab = useSelector((state: RootState) => state.searchTools.tab);

  const getTabIndex = (category: TSearchToolsTabType) =>
    toolTabs.findIndex((tab) => tab.value === category);

  const selectedTabIndex = getTabIndex(reduxTab);

  const handleChangeTabIndex = (
    event: React.SyntheticEvent,
    newIndex: number
  ) => {
    const newCategory = toolTabs[newIndex]?.value as TSearchToolsTabType;
    if (newCategory) {
      dispatch(setSearchToolsTab(newCategory));
    }
  };

  return {
    selectedTabIndex,
    handleChangeTabIndex
  };
};

export interface IMarketplaceTabsBaseProps {
  className?: string;
  disabledEntities?: TDisabledEntities;
}

interface IMarketplaceTabsProps extends IMarketplaceTabsBaseProps {
  toolTabs: TToolTab[];
}

const MarketplaceTabs = ({
  className,
  disabledEntities,
  toolTabs
}: IMarketplaceTabsProps) => {
  const { selectedTabIndex, handleChangeTabIndex } =
    useEntityCategory(toolTabs);
  return (
    <div className={classNames("oflow-x-hidden", className)}>
      <Tabs value={selectedTabIndex} onChange={handleChangeTabIndex}>
        {toolTabs.map((tabDetails) => {
          const isTabSelected = selectedTabIndex === tabDetails.indexValue;
          return (
            <Tab
              key={tabDetails.value}
              icon={isTabSelected ? tabDetails.selectedIcon : tabDetails.icon}
              iconPosition="start"
              classes={{
                root: styles["tab-height"]
              }}
              {...a11yProps(tabDetails.indexValue)}
              label={
                <span className={isTabSelected ? styles.selected : ""}>
                  {tabDetails.label}
                </span>
              }
            />
          );
        })}
      </Tabs>
      <Divider />
      {toolTabs.map((tabDetails) => {
        return (
          <TabPanel
            value={selectedTabIndex}
            key={tabDetails.value}
            index={tabDetails.indexValue}
            className="m-t-12px"
          >
            <SearchToolsContainer
              tabDetails={toolTabs[selectedTabIndex]}
              disabledEntities={disabledEntities}
            />
          </TabPanel>
        );
      })}
    </div>
  );
};

export default MarketplaceTabs;
