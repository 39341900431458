import type { TMappedInputs } from "@toolflow/shared";
import useGetSteps from "../../hooks/useGetSteps";
import {
  MAPPED_INPUTS_FIELD,
  MAP_TO_PREVIOUS_INPUT_TYPE
} from "../../../WorkflowBuilderConstants";
import { useEffect } from "react";
import { DEFAULT_MAPPED_INPUT } from "../../hooks/useSetDefaultInputsOnToolChange";

// Assuming you have a particular stepId you want to filter out

const filterMappedInputs = (
  inputs: TMappedInputs,
  validIds: string[]
): TMappedInputs => {
  const filteredInputs: TMappedInputs = {};

  Object.keys(inputs).forEach((key) => {
    const input = inputs[key];
    // Check if input is of type TMapToPrevious and has a previousField
    if (input.type === MAP_TO_PREVIOUS_INPUT_TYPE && input.previousField) {
      // Include the input only if the stepId matches one of the validIds
      if (validIds.includes(input.previousField.stepId)) {
        filteredInputs[key] = input;
      } else {
        filteredInputs[key] = DEFAULT_MAPPED_INPUT; // if the step is invalid, reset it as default
      }
    } else {
      // Include inputs that are not of type TMapToPrevious or don't have a previousField
      filteredInputs[key] = input;
    }
  });

  return filteredInputs;
};

const useValidateStepsOnMove = () => {
  const steps = useGetSteps();

  const validateStep = (stepId: string) => {
    const stepIndex = steps.findIndex((step) => step.id === stepId);
    const stepToCheck = steps[stepIndex];
    const stepsToUpdate = [...steps]; // we can't mutate the original array
    const validIds = stepsToUpdate.splice(0, stepIndex).map((step) => step.id);
    if (stepToCheck.settings && MAPPED_INPUTS_FIELD in stepToCheck.settings) {
      const mappedInputs = filterMappedInputs(
        stepToCheck.settings.mappedInputs,
        validIds
      );
      stepToCheck.settings.mappedInputs = mappedInputs;
    }
    return stepToCheck;
  };

  const validateSteps = () => {
    steps.map((step) => validateStep(step.id));
  };

  const stepIds = JSON.stringify(steps.map((step) => step.id));

  useEffect(() => {
    validateSteps();
  }, [stepIds]);

  return validateSteps;
};

export default useValidateStepsOnMove;
