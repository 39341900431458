import { useResetThreadMutation } from "../../../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import useGetCurrentChatThreadId from "./useGetCurrentChatThreadId";
import useSetThreadId from "./useSetThreadId";

const useResetThread = () => {
  const [resetThread] = useResetThreadMutation();
  const setThreadId = useSetThreadId();
  const currentThread = useGetCurrentChatThreadId();
  async function handleResetThread() {
    await resetThread()
      .unwrap()
      .then((resp) => {
        if (
          currentThread !== resp?.result?.threadId &&
          resp?.result?.threadId
        ) {
          setThreadId(resp.result.threadId);
        }
      });
  }
  return handleResetThread;
};

export default useResetThread;
