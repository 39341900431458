import { IconButton } from "@mui/material";
import useCloseDrawer from "../../../store/hooks/useCloseDrawer";
import { CloseIcon } from "../../../../../../../globalTheme/icons/icons";

const CloseDrawerButton = () => {
  const closeDrawer = useCloseDrawer();
  return (
    <IconButton onClick={closeDrawer}>
      <CloseIcon />
    </IconButton>
  );
};

export default CloseDrawerButton;
