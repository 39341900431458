import { useMemo } from "react";
import useGetCurrentWorkflowVersion from "./useGetCurrentWorkflowVersion";
import {
  DEFAULT_WORKFLOW_NAME,
  VISIBILITY_FIELD_LABEL,
  WORKFLOW_DESCRIPTION_FIELD_LABEL,
  WORKFLOW_NAME_FIELD_LABEL,
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_TAG_FIELD_LABEL,
  ENABLE_FORKING_FIELD_LABEL,
  getWorkflowDefaultStep
} from "../../WorkflowBuilderConstants";
import {
  DEFAULT_TAG,
  VisibilityTypes,
  type TWorkflowDataToSave,
  type TWorkflowVersionDataToSave
} from "@toolflow/shared";
import useGetWorkflowByParams from "../../hooks/useGetWorkflowByParams";

const getDefaultWorkflowFormValues = (
  workflowVersion?: TWorkflowVersionDataToSave,
  workflow?: TWorkflowDataToSave
) => {
  return {
    [WORKFLOW_TAG_FIELD_LABEL]:
      workflow?.[WORKFLOW_TAG_FIELD_LABEL] || DEFAULT_TAG,
    [WORKFLOW_NAME_FIELD_LABEL]:
      workflowVersion?.[WORKFLOW_NAME_FIELD_LABEL] || DEFAULT_WORKFLOW_NAME,
    [WORKFLOW_DESCRIPTION_FIELD_LABEL]:
      workflowVersion?.[WORKFLOW_DESCRIPTION_FIELD_LABEL] || "",
    [VISIBILITY_FIELD_LABEL]:
      workflow?.[VISIBILITY_FIELD_LABEL] || VisibilityTypes.PUBLIC,
    [WORKFLOW_STEPS_FIELD_LABEL]: workflowVersion?.steps || [
      getWorkflowDefaultStep(workflowVersion?.steps.length || 0)
    ],
    [ENABLE_FORKING_FIELD_LABEL]:
      workflow?.[ENABLE_FORKING_FIELD_LABEL] || false
  };
};

const useDefaultWorkflowFormValues = () => {
  const workflowVersion = useGetCurrentWorkflowVersion();
  const { data } = useGetWorkflowByParams();
  return useMemo(
    () => getDefaultWorkflowFormValues(workflowVersion, data?.workflow),
    [workflowVersion]
  );
};

export default useDefaultWorkflowFormValues;
