import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL } from "../../../../helpers/workspaceConstants";

const useSetThreadId = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const setThreadId = (threadId: string) => {
    setValue(WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL, threadId, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  return setThreadId;
};

export default useSetThreadId;
