import type { SheetAsset } from "@toolflow/shared";

export function getNewAssetForSheet(asset: SheetAsset, t: string | null) {
  return {
    ...asset,
    value: {
      ...asset.value,
      selectedToolId: t ?? ""
    }
  };
}
