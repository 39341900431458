import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updatePaths } from "../navigationSlice";
import { setToolflowAlternativeUrl } from "./setToolflowAlternativeUrl";

const useResetToolflowAlternativeUrl = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/") {
      // we keep the toolflow alternative url when we navigate to the workspace
      // because we navigate to the workspace from the useNavigateToWorkspaceWithDefaultObject
      // but in other cases, we want to reset the toolflow alternative url so that users don't login to random places
      setToolflowAlternativeUrl(undefined);
    }
  }, [location]);
};

const useRouterListener = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useResetToolflowAlternativeUrl();

  useEffect(() => {
    dispatch(
      updatePaths({
        path: location.pathname,
        replaced: location.state?.replaced
      })
    );
  }, [location, dispatch]);

  return null;
};

export default useRouterListener;
