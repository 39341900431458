import { TextSelection } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";
import useIsSlashRef from "../useIsSlashRef";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";
import type { RichTextEditorRef } from "mui-tiptap";

function useMouseUp({
  rteRef
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
}) {
  const { isSlash, setShouldRenderBubble, muiTipTapProps } =
    useMuiTipTapContext();
  const { disableFormattingMenu } = muiTipTapProps;
  const isSlashRef = useIsSlashRef(isSlash);

  const mouseUp = (view: EditorView, event: MouseEvent) => {
    if (
      !(event.target as Element).closest(".dontTriggerBubble") &&
      !isSlashRef.current &&
      !rteRef.current?.editor?.state.selection.empty &&
      rteRef.current?.editor?.state.selection instanceof TextSelection
    ) {
      const setValue = !rteRef.current?.editor?.state.selection.empty;

      // we need this so that this doesnt bubble to handleClickOutside
      // in editormenucontrols when clicking an autocomplete option
      if (setValue) {
        event.stopPropagation();
      }
      if (!disableFormattingMenu) {
        setShouldRenderBubble(!rteRef.current?.editor?.state.selection.empty);
      }
    }
  };

  return mouseUp;
}

export default useMouseUp;
