import React from "react";
import LeftPaperWrapper from "../../LeftPaperWrapper";
import { EntityBodyContainer } from "./EntityBodyContainer";

function ToolPaper() {
  return (
    <LeftPaperWrapper
      mainContent={
        <div className="flex flex-column h-100-percent max-h-100-percent">
          <EntityBodyContainer />
        </div>
      }
      headerClassName="w-100-percent flex justify-flex-end"
    />
  );
}

export default ToolPaper;
