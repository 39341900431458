import type {
  TPreviousField,
  TPreviousFieldInputOrOutput
} from "@toolflow/shared";

/**
 * Parses a given menuItem value into a TPreviousField object.
 * @param value The value attribute of a MenuItem, formatted like "Step:{stepId}Id:{fieldId}Type:{type}"
 * @returns TPreviousField
 */
const parsePreviousField = (value: string): TPreviousField => {
  const stepIdMatch = value.match(/Step:([^}]+)Id/);
  const fieldIdMatch = value.match(/Id:([^}]+)Type/);
  const typeMatch = value.match(/Type:([^}]+)/);

  if (!stepIdMatch || !fieldIdMatch || !typeMatch) {
    throw new Error("Invalid format of the value string");
  }

  return {
    stepId: stepIdMatch[1],
    fieldId: fieldIdMatch[1],
    type: typeMatch[1] as TPreviousFieldInputOrOutput
  };
};

export default parsePreviousField;
