import { v4 as uuidv4 } from "uuid";
import { getNewSheetParams } from "./functions/getNewSheetParams";
import { useWorkspaceAssetsFieldArrayContext } from "../contexts/WorkspaceAssetsContext";
import useSetVisibleAsset from "./useSetVisibleAsset";

function useAddNewSheet() {
  const { append } = useWorkspaceAssetsFieldArrayContext();
  const { setVisibleAsset } = useSetVisibleAsset();

  return (selectedToolId = "") => {
    const id = uuidv4();
    const params = getNewSheetParams(selectedToolId);
    append(params);
    setVisibleAsset(id);

    return id;
  };
}

export default useAddNewSheet;
