import type { TDallEAsset } from "@toolflow/shared";
import { useAssetContext } from "../../../../../../../utilities/contexts/AssetContext";
import CopyableImage from "../../../../../../tools/components/editorToolCard/outputs/CopyableImage/CopyableImage";
import useGetVisibleAssetValue from "../../../../hooks/useGetVisibleAssetValue";
import AssetTableOfContents from "../../assetTableOfContents/AssetTableOfContents";
import AssetName from "../AssetName";
import DallEAssetIcons from "./DallEAssetIcons";

const DallEAsset = () => {
  const { asset } = useAssetContext<TDallEAsset>();
  const value = useGetVisibleAssetValue();

  return (
    <>
      <div>
        <div className="flex align-i-center w-100-percent p-24px parent-hover">
          <AssetName text={asset.name} />
          <DallEAssetIcons />
        </div>
        <div className="p-24px">
          <CopyableImage value={value} showLoading={asset.loading} />
        </div>
      </div>
      <AssetTableOfContents />
    </>
  );
};

export default DallEAsset;
