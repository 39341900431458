import { Chip } from "@mui/material";
import { VisibilityTypes } from "@toolflow/shared";
import React from "react";

const VisibilityChip: React.FC<{ visibility: VisibilityTypes }> = ({
  visibility
}) => {
  const isPublic = visibility === VisibilityTypes.PUBLIC;
  if (isPublic) return null;

  return (
    <Chip
      label={visibility.charAt(0).toUpperCase() + visibility.slice(1)}
      variant="outlined"
      size="small"
      className="m-r-8px"
    />
  );
};

export default VisibilityChip;
