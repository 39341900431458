import type { UpdateSnippet } from "@toolflow/shared";

import { type SubmitHandler, useFormContext } from "react-hook-form";
import { useUpdateSnippetMutation } from "../../../../ToolflowAPI/rtkRoutes/snippetsApi";
import { KNOWLEDGE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import { useParams } from "react-router-dom";

const useUpdateSnippet = () => {
  const [updateSnippet, { isLoading }] = useUpdateSnippetMutation();
  const { snippetId } = useParams();

  const { handleSubmit } = useFormContext<UpdateSnippet>();

  const navigate = useToolflowNavigate();

  const onSubmit: SubmitHandler<UpdateSnippet> = async (body) => {
    try {
      if (snippetId) {
        await updateSnippet({
          body,
          snippetId
        });
        navigate(KNOWLEDGE_ROUTE_PATH);
      }
    } catch (error) {
      console.error("Failed to save snippet", error);
    }
  };

  const saveSnippet = () => {
    handleSubmit(onSubmit)();
  };

  return {
    saveSnippet,
    loading: isLoading
  };
};

export default useUpdateSnippet;
