import useGetCurrentUser from "../../user/hooks/useGetCurrentUser";

export const useIsAuth0DatabaseConnection = () => {
  const user = useGetCurrentUser();

  const isAuth0DatabaseConnection = () => {
    return user?.auth0UserId?.includes("auth0");
  };

  return {
    isAuth0DatabaseConnection
  };
};
