import { useGetAllUserWorkflowsAsEntities } from "../../entities/hooks/useGetAllUserWorkflowsAsEntities";
import { useMarketplaceWorkflowEntities } from "./useMarketplaceWorkflowEntities";
import { getUniqueEntity } from "../../entities/functions/getUniqueEntity";

const useGetAvailableWorkflows = () => {
  const marketplaceEntities = useMarketplaceWorkflowEntities();
  const { entities } = useGetAllUserWorkflowsAsEntities();
  return getUniqueEntity([...marketplaceEntities, ...entities]);
};

export default useGetAvailableWorkflows;
