import React from "react";

type ParentBlockProps = {
  data: {
    label: string;
  };
  selected: boolean;
};

// We are deprecating the parentblock right now
// but we are not going to overhaul everything, we are still going to allow
// parents to be created in the case that we need them in the future and
// to be backward compatible
const ParentBlock: React.FC<ParentBlockProps> = ({}) => {
  return (
    <>
      {/* <div className="flex align-i-center" style={{ margin: 32 }}>
        <Typography variant="h3" className="p-r-16px">
          {label}
        </Typography>
      </div>
      <NodeResizer
        color="#673AB6"
        minWidth={150}
        minHeight={120}
        isVisible={selected}
      />
      <Box>
        <Handle
          type="target"
          position={Position.Top}
          id="b"
          style={{ background: "#555" }}
        />
        <Handle type="source" position={Position.Bottom} id="a" />
      </Box> */}
    </>
  );
};

export default ParentBlock;
