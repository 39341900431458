import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";

export default function AssignCategoriesDialog({
  title,
  open,
  onClose,
  children
}: {
  title: string;
  open: boolean;
  onClose: (e: React.SyntheticEvent<Element, Event>) => void;
  children: React.ReactNode;
}) {
  const dialogRef = React.useRef<HTMLDivElement>(null);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      ref={dialogRef}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="flex flex-column">{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
