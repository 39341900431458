import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { toolflowTextDisabledColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import type { RemixiconComponentType } from "@remixicon/react";

function TextButtonGray({
  Icon,
  disabled,
  onClick,
  className = "",
  text
}: {
  Icon: RemixiconComponentType;
  disabled?: boolean;
  onClick: () => void;
  className?: string;
  text: string;
}) {
  return (
    <ButtonBase disabled={disabled} onClick={onClick} className={className}>
      <Icon size={14} color={toolflowTextDisabledColor} />
      <Typography className="text-transform-none m-l-4px" variant="smallButton">
        {text}
      </Typography>
    </ButtonBase>
  );
}

export default TextButtonGray;
