import useFileRoutes from "./routes/useFileRoutes";
import useImageRoutes from "./routes/useImageRoutes";

// we are keeping these for now, because they use axios options
// later we can consolidate with fetch
const useToolflowAPI = () => {
  const fileRoutes = useFileRoutes();
  const imageRoutes = useImageRoutes();

  return {
    ...fileRoutes,
    ...imageRoutes
  };
};

export default useToolflowAPI;
