import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import { useWorkflowContext } from "../../../../../workflow/contexts/WorkflowContext";
import useGetElementHeight from "../../assets/asset/textAsset/hooks/useGetElementHeight";
import EntityHeader from "./EntityHeader";
import RunWorkflowFinish from "./RunWorkflowFinish";
import RunWorkflowStep from "./RunWorkflowStep";
import useGetWorkflowActiveStep from "./hooks/useGetWorkflowActiveStep";

function RunWorkflowCardInner() {
  const { workflowVersion } = useWorkflowContext();
  const activeStep = useGetWorkflowActiveStep();
  const { ref, height } = useGetElementHeight();

  return (
    <Box className="p-h-16px max-h-100-percent">
      <div ref={ref}>
        <EntityHeader />
      </div>
      <div
        className="max-h-100-percent scrollable-content"
        style={{ maxHeight: `calc(100% - ${height}px - 10px)` }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {workflowVersion.steps.map((step, index) => (
            <RunWorkflowStep
              step={step}
              index={index}
              lastStep={index === workflowVersion.steps.length - 1}
              key={index}
            />
          ))}
        </Stepper>
        {activeStep === workflowVersion.steps.length && <RunWorkflowFinish />}
      </div>
    </Box>
  );
}

export default RunWorkflowCardInner;
