import type { Content } from "@tiptap/core";
import useConvertStringToTipTap from "./useConvertStringToJSON";

const useConvertContentToJSON = () => {
  const convertToTipTap = useConvertStringToTipTap();
  const handleChange = (value: Content) => {
    return typeof value === "string" ? convertToTipTap(value) : value;
  };
  return handleChange;
};

export default useConvertContentToJSON;
