import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL } from "../../../../helpers/workspaceConstants";
import { useFormContext } from "react-hook-form";

const useGetWorkflowProgress = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL);
};

export default useGetWorkflowProgress;
