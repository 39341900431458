import type { VisibilityTypes } from "@toolflow/shared";
import React, { FC } from "react";
import SettingsDropdownMenuButton from "../../../../utilities/components/dropdowns/generic/SettingsDropdownMenuButton";
import UpdateWorkspaceVisibilityMenuItem from "../../workstation/components/menuItems/UpdateWorkspaceVisibilityMenuItem";
import DeleteMenuItem from "../../../../utilities/components/dropdowns/menuItems/deleteMenuItem/DeleteMenuItem";
import { Divider } from "@mui/material";
import RenameWorkspaceMenuItem from "./RenameWorkspaceMenuItem";
import { toolflowTextDisabledColor } from "../../../../globalTheme/muiUtils/appTheme";
import { VerticalMoreIcon } from "../../../../globalTheme/icons/icons";

interface IWorkspaceDropdownMenuProps {
  workspaceId: string;
  workspaceVisibility: VisibilityTypes;
  workspaceName: string;
}
const WorkspaceDropdownMenu: FC<IWorkspaceDropdownMenuProps> = ({
  workspaceId,
  workspaceVisibility,
  workspaceName
}) => {
  const menuItems = [
    <RenameWorkspaceMenuItem
      key={"rename workspace"}
      workspaceId={workspaceId}
      workspaceName={workspaceName}
    />,
    <UpdateWorkspaceVisibilityMenuItem
      visibility={workspaceVisibility}
      id={workspaceId}
      name={workspaceName}
      key="updateVisibility"
    />,
    <Divider key={"divider"} />,
    <DeleteMenuItem
      id={workspaceId}
      setDisableCard={() => {}}
      type="workspace"
      key="deleteMenuItem"
      name={workspaceName}
    />
  ];
  return (
    <SettingsDropdownMenuButton
      menuItems={menuItems}
      icon={<VerticalMoreIcon size={16} color={toolflowTextDisabledColor} />}
    />
  );
};

export default WorkspaceDropdownMenu;
