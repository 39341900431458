import React from "react";
import type { ButtonVariantTypes } from "@toolflow/shared";
import {
  CURRENT_SUBSCRIPTION,
  DOWNGRADE,
  ORGANIZATION_UPGRADE,
  PRO_UPGRADE,
  TCurrentSubscription,
  TDowngrade,
  TUpgrade,
  TUpgradeToOrganization,
  TUpgradeToPro,
  UPGRADE
} from "../../../../../billing/helpers/billingConstants";
import { CurrentSubscriptionIcon } from "../../../../../../globalTheme/icons/icons";
import { ALFIE_CALENDLY_URL } from "./constants";

export const tierStatusParameters: {
  [key in
    | TDowngrade
    | TUpgrade
    | TCurrentSubscription
    | TUpgradeToOrganization
    | TUpgradeToPro]: {
    text: string;
    disabled: boolean;
    variant: ButtonVariantTypes;
    icon?: React.ReactNode;
    btnLinkAction?: () => void;
  };
} = {
  [DOWNGRADE]: {
    text: "Downgrade",
    disabled: false,
    variant: "outlined",
    btnLinkAction: () => {
      window.open(ALFIE_CALENDLY_URL, "rel=noopener noreferrer");
    }
  },
  [UPGRADE]: {
    text: "Upgrade",
    disabled: false,
    variant: "contained"
  },
  [CURRENT_SUBSCRIPTION]: {
    text: "Current plan",
    disabled: true,
    variant: "text",
    icon: <CurrentSubscriptionIcon color="green" />
  },
  [ORGANIZATION_UPGRADE]: {
    text: "Contact us",
    disabled: false,
    variant: "outlined",
    btnLinkAction: () => {
      window.open(ALFIE_CALENDLY_URL, "rel=noopener noreferrer");
    }
  },
  [PRO_UPGRADE]: {
    text: "Start now",
    disabled: false,
    variant: "contained"
  }
};
