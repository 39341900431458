import {
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_STEP_TYPE_FIELD_LABEL
} from "../../WorkflowBuilderConstants";
import useGetWorkflowInnerFieldById from "./useGetWorkflowInnerFieldById";

const useGetWorkflowStepTypeFieldFieldById = (
  stepId: string
): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${typeof WORKFLOW_STEP_TYPE_FIELD_LABEL}` => {
  return useGetWorkflowInnerFieldById(stepId, WORKFLOW_STEP_TYPE_FIELD_LABEL);
};

export default useGetWorkflowStepTypeFieldFieldById;
