import { createEntityAdapter } from "@reduxjs/toolkit";
import type { WorkflowVersion, ProfileWorkflow } from "@toolflow/shared";

export const workflowAdapter = createEntityAdapter({
  selectId: (workflow: ProfileWorkflow) => workflow.id
});
export const workflowInitialState = workflowAdapter.getInitialState();

export const workflowVersionAdapter = createEntityAdapter({
  selectId: (workflow: WorkflowVersion) => workflow._id
});

export const workflowVersionInitialState =
  workflowVersionAdapter.getInitialState();
