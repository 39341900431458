import useSendInput from "../../hooks/useSendInputAndAbort";
import useToolStillLoading from "../../hooks/useToolStillLoading";
import useRunButtonBase from "./useRunButtonBase";

const useRunButton = () => {
  const { sendInput, abort } = useSendInput();
  const toolStillLoading = useToolStillLoading();
  return useRunButtonBase({
    send: sendInput,
    abort,
    loading: toolStillLoading
  });
};

export default useRunButton;
