import { TBlocksTagsLabels } from "./blockChips";
import { TCategory } from "./categories";
import { TUseCase } from "./useCases";

export type TTagObject = {
  categories?: TCategory[];
  useCases?: TUseCase[];
  blocks?: TBlocksTagsLabels[];
};

export const DEFAULT_TAG: TTagObject = {
  categories: [],
  useCases: [],
  blocks: []
};
