import React from "react";
import Button from "@mui/material/Button";
import type { ButtonSizeTypes, ButtonVariantTypes } from "@toolflow/shared";
import useNavigateToToolById from "../workstation/hooks/useNavigateToToolById";

function NavigateToToolInWorkspaceButton({
  toolId,
  className,
  variant = "contained",
  text = "Open",
  size = "medium"
}: {
  toolId?: string;
  className?: string;
  variant?: ButtonVariantTypes;
  text?: string;
  size?: ButtonSizeTypes;
}) {
  const handleNavigate = useNavigateToToolById(toolId);

  if (!toolId) {
    return null;
  }

  return (
    <>
      <Button
        size={size}
        onClick={handleNavigate}
        variant={variant}
        className={className}
      >
        {text}
      </Button>
    </>
  );
}

export default NavigateToToolInWorkspaceButton;
