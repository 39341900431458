import React, { useMemo } from "react";
import { useSettingsDropdownContext } from "../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import SettingsMenuItem from "../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import ToolflowHireSplitter from "../../../auth/ToolflowHireGuard";
import { useUpdateMarketplaceFeaturedEntityMutation } from "../../../../ToolflowAPI/rtkRoutes/marketplaceApi";
import useToolIsPopular from "../../../tools/hooks/useToolIsPopular";
import { PopularIcon } from "../../../../globalTheme/icons/icons";
import type { TMarketplaceUniversalEntityType } from "@toolflow/shared";

function TogglePopularObjectMenuItem({
  objectId,
  type
}: {
  objectId: string;
  type: TMarketplaceUniversalEntityType;
}) {
  const [togglePopular] = useUpdateMarketplaceFeaturedEntityMutation();
  const { handleClose } = useSettingsDropdownContext();
  const isPopular = useToolIsPopular(objectId);

  const copy = (e: React.MouseEvent) => {
    const newPopularState = !isPopular;
    togglePopular({
      objectId,
      type,
      popular: newPopularState,
      isFeatured: true,
      successMessage: newPopularState
        ? "Made popular in marketplace"
        : "Disabled popular in marketplace"
    });
    handleClose(e);
  };

  const text = useMemo(() => {
    return isPopular
      ? "Disable popularity in marketplace"
      : "Make popular in marketplace";
  }, [isPopular]);

  return (
    <ToolflowHireSplitter
      component={
        <SettingsMenuItem
          action={copy}
          text={text}
          icon={<PopularIcon size={16} />}
        />
      }
    />
  );
}

export default TogglePopularObjectMenuItem;
