import { Box, Stack, Typography } from "@mui/material";
import {
  OutputConfigOptions,
  type CustomToolOutputField,
  type ToolOutputFieldConfig
} from "@toolflow/shared";
import { Fragment } from "react/jsx-runtime";
import { getLatestBlockConfigByNodeType } from "../../../../blocks/constants/blockConfig";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useGetCurrentToolOutputField from "./customToolOutputFields/hooks/useGetCurrentToolOutputField";
import useGetCurrentToolOutputFieldIndex from "./customToolOutputFields/hooks/useGetCurrentToolOutputFieldIndex";
import { Switch } from "./outputFields/Switch";

function OutputFieldsInner({
  toolOutputField,
  outputConfigOptions
}: {
  toolOutputField: CustomToolOutputField;
  outputConfigOptions: OutputConfigOptions[];
}) {
  const index = useGetCurrentToolOutputFieldIndex();
  const { dispatch } = useToolbuilderContext();

  const handleOutputConfigsUpdate = (
    configs: Partial<ToolOutputFieldConfig>
  ) => {
    dispatch({
      type: "UPDATE_TOOL_OUTPUT_FIELD_CONFIG",
      index,
      configs
    });
  };

  return (
    <Box sx={{ marginTop: 5 }}>
      <Typography variant="subtitleLarge" color="text.primary">
        Output Configuration
      </Typography>

      <Stack spacing={2} sx={{ mt: 2 }}>
        {outputConfigOptions.map((option, i) => {
          return (
            <Fragment key={i}>
              {option === OutputConfigOptions.List && (
                <Switch
                  label="Output results in separate assets"
                  checked={!!toolOutputField.config?.splitOutputs}
                  onChange={(checked) =>
                    handleOutputConfigsUpdate({ splitOutputs: checked })
                  }
                />
              )}
            </Fragment>
          );
        })}
      </Stack>
    </Box>
  );
}

export default function OutputConfigFields() {
  const toolOutputField = useGetCurrentToolOutputField();
  const { state } = useToolbuilderContext();
  const block = state.currentState.blocks.find(
    (b) => b.data.label === toolOutputField.name
  );

  if (!block) return null;

  const blockConfigs = getLatestBlockConfigByNodeType(block?.data.type);
  const outputConfigOptions =
    blockConfigs.getOutputConfigOptions?.({
      id: block.id,
      data: block.data,
      currentState: state.currentState,
      toolOutputField
    }) || [];

  if (!outputConfigOptions.length) return null;

  return (
    <OutputFieldsInner
      toolOutputField={toolOutputField}
      outputConfigOptions={outputConfigOptions}
    />
  );
}
