import React from "react";
import TeamSettingsForm from "./TeamSettingsForm";
import OrganizationMembersCard from "./OrganizationMembersCard";

const TeamSettings = () => {
  return (
    <div>
      <TeamSettingsForm />
      <OrganizationMembersCard />
    </div>
  );
};

export default TeamSettings;
