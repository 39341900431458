import React from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import ThreadMessageContext from "../../../contexts/ThreadMessageContext";
import BottomGradient from "../../BottomGradient";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import AgentMessage from "./AgentMessage";
import LoadingThreadMessage from "./LoadingThreadMessage";
import ThreadMessage from "./ThreadMessage";
import styles from "./chatThread.module.css";
import useChatMessages from "./hooks/useChatMessages";
import { Box } from "@mui/material";
const cx = parsedClassNames.bind(styles);

function ChatThread() {
  const { transformedSocketMessage, threadMessages, isMessageLoading } =
    useChatMessages();
  return (
    <ScrollToBottom
      className={cx("oflow-auto h-100-percent scroll-to-bottom")}
      followButtonClassName="arrow-button"
      initialScrollBehavior="smooth"
      scrollViewClassName="scrollable-content-important"
    >
      <Box className={styles["chat-thread"]}>
        {threadMessages.map((threadMessage) => (
          <ThreadMessageContext.Provider
            value={{ threadMessage }}
            key={threadMessage.id}
          >
            <ThreadMessage />
          </ThreadMessageContext.Provider>
        ))}
        {transformedSocketMessage && (
          <ThreadMessageContext.Provider
            value={{ threadMessage: transformedSocketMessage }}
          >
            <AgentMessage message={transformedSocketMessage} />
          </ThreadMessageContext.Provider>
        )}
        {isMessageLoading && <LoadingThreadMessage />}
        <BottomGradient className={"border-radius-16px"} />
      </Box>
    </ScrollToBottom>
  );
}

export default ChatThread;
