import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";

export const useGetMemoizedEntityFilters = () => {
  const selectedCategory = useSelector(
    (state: RootState) => state.searchTools.category
  );
  const selectedUseCases = useSelector(
    (state: RootState) => state.searchTools.useCases
  );

  return useMemo(
    () => ({ selectedCategory, selectedUseCases }),
    [selectedCategory, selectedUseCases]
  );
};
