import { type Content } from "@tiptap/core";
import { useConvertContentToMarkdown } from "./useConvertContentToMarkdown";
import { useMemo } from "react";
import { stripAllParagraphTags } from "@toolflow/shared";

const useGetThreadContentAsMarkdown = (content: Content) => {
  const convertContentToMarkdown = useConvertContentToMarkdown();

  const markdownString = useMemo(() => {
    const markdownStringWithParagraphs = convertContentToMarkdown(content);
    return stripAllParagraphTags(markdownStringWithParagraphs);
  }, [JSON.stringify(content)]);

  return markdownString;
};

export default useGetThreadContentAsMarkdown;
