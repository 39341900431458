import React from "react";
import useDefaultSnippetFormValues from "./hooks/useDefaultSnippetFormValues";
import SnippetFormCard from "../../../snippets/SnippetForm/SnippetFormCard";

export const SnippetFormCardContainer = () => {
  const defaultSnippet = useDefaultSnippetFormValues();
  return (
    <SnippetFormCard
      initialName={defaultSnippet.name}
      initialValue={defaultSnippet.snippetValue}
      className="m-t-24px"
    />
  );
};
