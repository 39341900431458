import { IconButton } from "@mui/material";
import React from "react";
import { ArrowBackIcon } from "../../../../../globalTheme/icons/icons";
import { useClearAnyEntity } from "../../hooks/entities/useClearAnyEntity";

export function GetStartedBackButton({ className }: { className?: string }) {
  const clearAnyEntity = useClearAnyEntity(true);

  const onClick = () => {
    clearAnyEntity();
  };

  return (
    <IconButton size="small" onClick={onClick} className={className}>
      <ArrowBackIcon fontSize="small" />
    </IconButton>
  );
}
