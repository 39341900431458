import type { TMarketplaceUniversalEntity } from "@toolflow/shared";
import useGetAvailableTools from "../../../../../features/tools/hooks/useGetAvailableTools";

const useGetToolSelectorOptions = () => {
  const availableTools = useGetAvailableTools();

  const options: Array<TMarketplaceUniversalEntity> = availableTools.filter(
    (
      (seenIds) => (item: TMarketplaceUniversalEntity) =>
        !seenIds.has(item.id) && seenIds.add(item.id)
    )(new Set<string>())
  );
  return options;
};

export default useGetToolSelectorOptions;
