import { Button, CircularProgress } from "@mui/material";
import useRunButton from "./hooks/useRunButton";

const RunButton = () => {
  const { action, endIcon, text, loading } = useRunButton();
  return (
    <div className="flex align-i-center">
      {loading && <CircularProgress size={16} className="m-r-8px" />}
      <Button
        onClick={action}
        variant="contained"
        size="small"
        endIcon={endIcon}
        disableRipple
      >
        {text}
      </Button>
    </div>
  );
};

export default RunButton;
