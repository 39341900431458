import React from "react";
import useUpdateField from "../../useUpdateField";
import AddKeywordsSettings from "./AddKeywordsSettings";

function AddKeywordsSettingsToolbuilder({
  id,
  tags
}: {
  id: string;
  tags: string[];
}) {
  const updateField = useUpdateField(id);

  const handleChange = (newTags: string[]) => {
    updateField(newTags, "settings.keywords");
  };

  return (
    <AddKeywordsSettings
      tags={tags}
      placeholder="Add hardcoded keywords"
      handleChange={handleChange}
    />
  );
}

export default AddKeywordsSettingsToolbuilder;
