import React, { FC } from "react";
import { WorkspaceTableData } from "../utlils/tableTypes";
import WorkspaceRow from "./WorkspaceRow";
import { BoldTableCell, StyledTableCell } from "../layout/TableLayouts";
import { getTimeAgoString } from "../../../../utilities/formatters/time/getTimeAgoString";
import CopyLinkMenuItemInHome from "../../../../utilities/components/textFields/workspace/dropdowns/menuItems/CopyLinkMenuItemInHome";
import WorkspaceDropdownMenu from "./WorkspaceDropdownMenu";

interface IWorkspaceTableRowProps {
  row: WorkspaceTableData;
  removeLastRowBorder?: boolean;
}
const WorkspaceTableRow: FC<IWorkspaceTableRowProps> = ({
  row,
  removeLastRowBorder
}) => {
  const labelId = `enhanced-table-checkbox-${row.id}`;
  return (
    <WorkspaceRow
      workspaceId={row.id}
      key={row.id}
      removeLastRowBorder={removeLastRowBorder}
    >
      <BoldTableCell id={labelId} sx={{ width: "40%" }}>
        {row.name}
      </BoldTableCell>
      <StyledTableCell align="left" sx={{ width: "30%" }}>
        {getTimeAgoString(row.date)}
      </StyledTableCell>
      <StyledTableCell align="right">
        <CopyLinkMenuItemInHome objectId={row.id} type="workspace" />

        <WorkspaceDropdownMenu
          workspaceId={row.id}
          workspaceName={row.name}
          workspaceVisibility={row.visibility}
        />
      </StyledTableCell>
    </WorkspaceRow>
  );
};

export default WorkspaceTableRow;
