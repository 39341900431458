import type { EditorOptions } from "@tiptap/core";
import { FloatingMenu } from "@tiptap/extension-floating-menu";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Placeholder } from "@tiptap/extension-placeholder";
import { Document } from "@tiptap/extension-document";
import { History } from "@tiptap/extension-history";
import { Text } from "@tiptap/extension-text";

const baseExtensions = [
  Document,
  Paragraph,
  Text,
  FloatingMenu,
  HardBreak,
  History
];

export const getBaseExtensionsFE = (
  placeholder?: string
): EditorOptions["extensions"] => {
  return [
    ...baseExtensions,
    Placeholder.configure({
      placeholder
    })
  ];
};
