import React from "react";
import ToggleTextField from "./ToggleTextField";

function ToggleSecondaryHeaderTextFieldBase({
  value,
  children
}: {
  value: string;
  children: React.ReactNode;
}) {
  return (
    <ToggleTextField
      variant="body2"
      className="max-w-40-percent"
      wrapperClassName="flex-grow-1 max-w-40-percent"
      disableFlexGrow
      color="GrayText"
      value={value}
    >
      {children}
    </ToggleTextField>
  );
}

export default ToggleSecondaryHeaderTextFieldBase;
