import React, { FC } from "react";
import { StyledTableRow } from "../layout/TableLayouts";
import useNavigateToWorkspaceById from "../../workstation/hooks/useNavigateToWorkspaceById";
interface IWorkspaceRowProps {
  workspaceId: string;
  children: React.ReactNode;
  removeLastRowBorder?: boolean;
}
const WorkspaceRow: FC<IWorkspaceRowProps> = ({
  children,
  workspaceId,
  removeLastRowBorder = false
}) => {
  const navigateToWorkspaceById = useNavigateToWorkspaceById(workspaceId);
  const onClickHandler = (e: React.MouseEvent) => {
    navigateToWorkspaceById(e);
  };
  return (
    <StyledTableRow
      className="cursor-pointer"
      onClick={onClickHandler}
      removeLastRowBorder={removeLastRowBorder}
    >
      {children}
    </StyledTableRow>
  );
};

export default WorkspaceRow;
