import React from "react";
import { UtilityDraggableContextProvider } from "./contexts/UtilityDraggableContext";
import type { TOrientation } from "@toolflow/shared";

const UtilityDraggable = ({
  id,
  index,
  children,
  type,
  isDragDisabled,
  disableDragHandle,
  orientation,
  data
}: {
  id: string;
  index: number;
  children: React.ReactNode;
  type: string;
  isDragDisabled?: boolean;
  disableDragHandle?: boolean;
  orientation?: TOrientation;
  data?: Record<string, $TSAllowedAny>;
}) => {
  return (
    <UtilityDraggableContextProvider
      type={type}
      id={id}
      disabledDrag={isDragDisabled}
      disableDragHandle={disableDragHandle}
      index={index}
      orientation={orientation}
      data={data}
    >
      {children}
    </UtilityDraggableContextProvider>
  );
};

export default UtilityDraggable;
