import { useMemo } from "react";
import { useGetCurrentUserStarredEntities } from "../../tools/hooks/useGetCurrentUserStarredEntities";

export const useStarredEntities = (limit?: number) => {
  const { entities, loading } = useGetCurrentUserStarredEntities();

  const starredEntities = useMemo(() => {
    return entities.sort((a, b) => b.updatedAt - a.updatedAt).slice(0, limit);
  }, [entities, limit]);

  return {
    starredEntities,
    loading
  };
};

export const useOnlyStarredEntities = () => {
  const { starredEntities } = useStarredEntities();
  return starredEntities;
};
