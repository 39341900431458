import { useGetWorkflowQuery } from "../../../ToolflowAPI/rtkRoutes/workflowApi";
import { useAuthStore } from "../../auth/hooks/useAuthStore";

const useGetWorkflowFromQuery = (
  workflowId?: string,
  options?: { skip?: boolean }
) => {
  const { isLoading } = useAuthStore();
  const { data } = useGetWorkflowQuery(workflowId || "", {
    skip: !workflowId || isLoading || options?.skip
  });
  if (!workflowId) return undefined;
  return data?.workflow;
};

export default useGetWorkflowFromQuery;
