import { Editor } from "@tiptap/core";

const deleteSlash = (editor?: Editor | null, isSlash?: boolean) => {
  if (editor && isSlash) {
    editor.commands.deleteRange({
      from: editor.view.state.selection.to - 1,
      to: editor.view.state.selection.to
    });
  }
};

export default deleteSlash;
