import {
  Extension,
  mergeAttributes,
  Node,
  type NodeViewProps,
  ReactNodeViewRenderer
} from "@tiptap/react";
import { type ExtensionConfig, getPlainTextFromHtml } from "@toolflow/shared";

function createNodeFromConfig(
  config: ExtensionConfig,
  reactComponent: (props: NodeViewProps) => JSX.Element
) {
  return Node.create({
    ...config.config,

    addAttributes() {
      return config.attributes;
    },

    renderHTML({
      HTMLAttributes
    }: {
      HTMLAttributes: Record<string, $TSAllowedAny>;
    }) {
      return [
        config.htmlTag,
        mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })
      ];
    },

    parseHTML() {
      return [{ tag: config.htmlTag }];
    },

    renderText({ node }) {
      return getPlainTextFromHtml(node.attrs.textValue || "") || "";
    },

    addNodeView() {
      return ReactNodeViewRenderer(reactComponent);
    }
  }) as Extension;
}

export default createNodeFromConfig;
