import type { RowUserInputDictType } from "@toolflow/shared";
import type { MRT_Cell } from "material-react-table";
import useSelectDeselectCell from "./useSelectDeselectCell";

const useOnClickBody = () => {
  const { selectCell, deselectCell } = useSelectDeselectCell();
  return (cell: MRT_Cell<RowUserInputDictType>) => (e: React.MouseEvent) => {
    // Check if the click event is from a table cell and should trigger cell selection/deselection
    const isTableCell = (e.target as HTMLElement).matches("td");

    if (isTableCell) {
      if (cell.row.original.columnIdSelected === cell.column.id) {
        deselectCell(cell.row.index);
      } else {
        selectCell(cell.row.index, cell.column.id);
      }
    }
  };
};

export default useOnClickBody;
