import { DarkUtilityTooltip } from "../toolTips/DarkUtilityTooltip";
import ToggleFavorite, { IToggleFavoriteTool } from "./ToggleFavorite";

function ToggleFavoriteWithTooltip({
  text,
  ...favoriteProps
}: IToggleFavoriteTool & { text: string }) {
  return (
    <DarkUtilityTooltip title={text}>
      <ToggleFavorite {...favoriteProps} />
    </DarkUtilityTooltip>
  );
}

export default ToggleFavoriteWithTooltip;
