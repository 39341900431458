import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import useNavigateToAgentBuilderById from "./hooks/useNavigateToAgentBuilderById";

function NavigateToAgentBuilderButton({
  agentId,
  providedColor,
  className = "",
  iconButtonClassName = ""
}: {
  agentId: string;
  providedColor?: string;
  className?: string;
  iconButtonClassName?: string;
}) {
  const navigateToAgentBuilder = useNavigateToAgentBuilderById(agentId);

  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={navigateToAgentBuilder}
      disabled={!agentId}
      providedColor={providedColor}
      className={className}
      iconButtonClassName={iconButtonClassName}
    />
  );
}

export default NavigateToAgentBuilderButton;
