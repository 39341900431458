import { type AutocompleteRenderInputParams } from "@mui/material";
import React from "react";
import SearchBox from "./searchBox/SearchBox";
import { SearchIcon } from "../../../../globalTheme/icons/icons";

function SelectorInput({
  params,
  placeholder,
  onClear,
  autoFocus
}: {
  params: AutocompleteRenderInputParams;
  placeholder: string;
  onClear?: () => void;
  autoFocus?: boolean;
}) {
  const [focus, setFocus] = React.useState(false);
  return (
    <SearchBox
      iconStart={<SearchIcon color="primary" />}
      focus={focus}
      setFocus={setFocus}
      placeholder={placeholder}
      params={{ ...params }}
      onClear={onClear}
      autoFocus={autoFocus}
    />
  );
}

export default SelectorInput;
