import useGetWorkflowByParams from "../../hooks/useGetWorkflowByParams";
import useGetWorkflowVersionsByParams from "../../hooks/useGetWorkflowVersionsByParams";

const useWorkflowQueriesFetching = () => {
  const { isFetching } = useGetWorkflowByParams();
  const { isFetching: versionsIsFetching } = useGetWorkflowVersionsByParams();
  return isFetching || versionsIsFetching;
};

export default useWorkflowQueriesFetching;
