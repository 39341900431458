import React from "react";
import { useGetFrequentlyUsedEntities } from "../../../../entities/hooks/useGetFrequentlyUsedEntities";
import useToolsTableData from "../../hooks/useToolsTableData";
import TableSkeleton from "../../layout/TableSkeleton";
import NoRecentData from "../../NoRecentData";
import ToolsPaginatedTable from "../ToolsPaginatedTable";

const FrequentlyUsedEntitiesDetailedContainer = () => {
  const { frequentlyUsedEntities, loading } = useGetFrequentlyUsedEntities();
  const { headCells, rows } = useToolsTableData(frequentlyUsedEntities);
  if (loading) {
    return <TableSkeleton numOfRows={15} className="p-16px" />;
  }
  const isHomePageEntitiesEmpty =
    frequentlyUsedEntities?.length === 0 && !loading;
  if (isHomePageEntitiesEmpty) {
    return (
      <NoRecentData
        header="Your recent tools will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return (
    <ToolsPaginatedTable
      rows={rows}
      headCells={headCells}
      initialOrderBy="entity.runCount"
    />
  );
};

export default FrequentlyUsedEntitiesDetailedContainer;
