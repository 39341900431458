import { useMemo } from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

export default function useFindBlockById(id: string) {
  const {
    state: {
      currentState: { blocks }
    }
  } = useToolbuilderContext();

  return useMemo(() => blocks.find((block) => block.id === id), [id, blocks]);
}
