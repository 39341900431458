import {
  DynamicFieldType,
  InputType,
  type SerpBlockSettingsV1,
  type SerpBlockSettingsV2,
  type TSerpAllowedOptions
} from "@toolflow/shared";
import { isBoolean, isObject } from "lodash";

function getAllowedOutputs(options: Record<string, $TSFixMe>) {
  return Object.entries(options).reduce((acc, [key, value]) => {
    if (isBoolean(value)) {
      acc[key] = {
        value,
        type: DynamicFieldType.Preset
      };
    } else if (isObject(value)) {
      acc[key] = getAllowedOutputs(value as Record<string, $TSFixMe>);
    }
    return acc;
  }, {} as $TSFixMe);
}

export const serpBlockOutputAllowedValuesDict: TSerpAllowedOptions<boolean> = {
  peopleAlsoAsk: false,
  relatedQueries: false,
  organicResults: true,
  paidResults: false,
  resultOptions: {
    title: true,
    url: true,
    description: true,
    displayedUrl: false,
    emphasizedKeywords: false,
    siteLinks: false
  }
};

export const serpSettingsInitialStateV1: {
  settings: SerpBlockSettingsV1;
} = {
  settings: {
    inputType: InputType.FixedInput,
    countryCode: "us",
    languageCode: "en",
    maxPagesPerQuery: 1,
    queries: "",
    userQueriesFieldKey: "",
    resultsPerPage: 10,
    allowedOutputs: serpBlockOutputAllowedValuesDict
  }
};

export const serpSettingsInitialStateV2: {
  settings: SerpBlockSettingsV2;
} = {
  settings: {
    queries: { type: DynamicFieldType.Dynamic, value: "" },
    resultsPerPage: { type: DynamicFieldType.Preset, value: 10 },
    maxPagesPerQuery: { type: DynamicFieldType.Preset, value: 1 },
    countryCode: { type: DynamicFieldType.Preset, value: "us" },
    languageCode: { type: DynamicFieldType.Preset, value: "en" },
    allowedOutputs: getAllowedOutputs(serpBlockOutputAllowedValuesDict)
  }
};
