import convertProfileWorkflowToMarketplaceEntity from "../../workflow/functions/convertProfileWorkflowToMarketplaceEntity";
import { useGetCurrentUserWorkflows } from "../../workflow/hooks/useGetCurrentUserWorkflows";
import { useMemo } from "react";

export const useGetAllUserWorkflowsAsEntities = () => {
  const { workflows, loading, fetching } = useGetCurrentUserWorkflows();
  const entities = useMemo(() => {
    return workflows.map(convertProfileWorkflowToMarketplaceEntity);
  }, [workflows]);
  return {
    entities,
    loading,
    fetching
  };
};
