import {
  AssetSourceType,
  type TransformedAssetValue,
  type AssetBaseIdsBySourceIdentifier
} from "@toolflow/shared";
import { useMemo } from "react";
import useWorkspaceAssets from "./useWorkspaceAssets";

const useTransformWorkspaceAsset = (): AssetBaseIdsBySourceIdentifier => {
  const assets = useWorkspaceAssets();
  return useMemo(
    () =>
      assets.reduce<AssetBaseIdsBySourceIdentifier>((acc, asset) => {
        if (asset.sourceIdentifier) {
          if (!acc[asset.sourceIdentifier]) {
            let valueToSet: TransformedAssetValue;
            if (asset.source === AssetSourceType.Manual) {
              valueToSet = {
                sourceType: AssetSourceType.Manual,
                assets: []
              };
            } else {
              const toolId = asset.data?.toolId || "";

              valueToSet = {
                sourceType: asset.source,
                toolCount: asset.data?.toolCount || 0,
                assets: [],
                toolId: toolId // this should never resolve to ""
              };
            }
            acc[asset.sourceIdentifier] = valueToSet;
          }
          acc[asset.sourceIdentifier].assets.push(asset.id);
        } else if (asset.source === AssetSourceType.Manual) {
          if (!acc.manual) {
            acc.manual = {
              sourceType: AssetSourceType.Manual,
              assets: [asset.id]
            };
          } else {
            acc.manual.assets.push(asset.id);
          }
        }
        return acc;
      }, {}),
    [assets.length]
  );
};

export default useTransformWorkspaceAsset;
