import { useWorkspaceAssetsFieldArrayContext } from "../contexts/WorkspaceAssetsContext";
import useWorkspaceAssets from "./useWorkspaceAssets";

function useExecuteDeleteAsset() {
  const assets = useWorkspaceAssets();
  const { remove } = useWorkspaceAssetsFieldArrayContext();

  return (id: string) => {
    assets.map((asset, index) => {
      if (asset.id === id) {
        remove(index);
      }
    });
  };
}

export default useExecuteDeleteAsset;
