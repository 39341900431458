import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_CHAT_AGENT_ID_FIELD_LABEL } from "../../../../helpers/workspaceConstants";

const useGetCurrentChatAgentId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_CHAT_AGENT_ID_FIELD_LABEL) || "";
};

export default useGetCurrentChatAgentId;
