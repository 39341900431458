import React from "react";
import SaveTooltipWrapper from "../../../../../utilities/components/toolTips/SaveTooltipWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import type { ISaveWorkflowButtonProps } from "@toolflow/shared";
import useHandleSaveWorkflowButtonClick from "./hooks/useHandleSaveWorkflowButtonClick";
import useFormIsDirty from "../../../../../utilities/hooks/useIsDirty";

// This either triggers opening the save dialog or saves it immediately
const SaveWorkflowButton = ({
  tooltip,
  size,
  className
}: Readonly<ISaveWorkflowButtonProps>) => {
  const { handleSave, loading } = useHandleSaveWorkflowButtonClick();
  const { isDirty } = useFormIsDirty();
  return (
    <SaveTooltipWrapper tooltip={tooltip}>
      <LoadingButton
        size={size}
        variant="contained"
        disabled={!isDirty}
        onClick={handleSave}
        className={className}
        loading={loading}
      >
        Save
      </LoadingButton>
    </SaveTooltipWrapper>
  );
};

export default SaveWorkflowButton;
