import { EntityType, type TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import {
  TOOL_TAB_USER_INPUT_FIELD_LABEL,
  WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL,
  WORKSPACE_ENTITY_ID_FIELD_LABEL,
  WORKSPACE_ENTITY_TYPE_FIELD_LABEL
} from "../helpers/workspaceConstants";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { setDisableTabChange } from "../state/workspaceSlice";

const useSetSelectedTool = (disableSave = false) => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const dispatch = useDispatch();

  const setSelectedTool = (toolId: string) => {
    setValue(WORKSPACE_ENTITY_ID_FIELD_LABEL, toolId, {
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL, uuidv4(), {
      // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(WORKSPACE_ENTITY_TYPE_FIELD_LABEL, EntityType.TOOL, {
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(
      TOOL_TAB_USER_INPUT_FIELD_LABEL,
      {},
      {
        shouldDirty: !disableSave,
        shouldTouch: true,
        shouldValidate: true
      }
    );
    dispatch(setDisableTabChange(false));
  };
  return setSelectedTool;
};

export default useSetSelectedTool;
