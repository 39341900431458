import React from "react";
import { Icon } from "@mui/material";
import Logo from "../../../globalTheme/icons/images/OpenAILogo.svg";
function OpenAILogo({
  className = "",
  size = 32
}: {
  className?: string;
  size?: number;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        borderRadius: 3,
        padding: 1,
        width: size,
        height: size
      }}
    >
      <Icon className="flex h-100-percent w-100-percent align-i-center justify-center">
        <img src={Logo} height={size} />
      </Icon>
    </div>
  );
}

export default OpenAILogo;
