import type { CustomToolInputField, UserInputDictType } from "@toolflow/shared";
import { INPUT_TEXTFIELD_ARRAY } from "../inputs/helpers/inputConstants";

const getDefaultUserInput = (
  inputFields?: CustomToolInputField[],
  text?: string
): UserInputDictType => {
  if (!inputFields) {
    return {};
  } else {
    let count = 0;
    let lastField = "";

    for (const inputField of inputFields) {
      if (INPUT_TEXTFIELD_ARRAY.includes(inputField.type)) {
        count++;
        lastField = inputField.name;

        if (count > 1) {
          break; // No need to continue iterating if count is greater than 1
        }
      }
    }
    const defaultUI = count === 1 ? { [lastField]: text ?? "" } : {};
    return defaultUI;
  }
};

export default getDefaultUserInput;
