import { useToolContext } from "../../../contexts/ToolContext";
import useIsCurrentUser from "../../../../user/hooks/useIsCurrentUser";

const useCurrentUserIsToolOwner = () => {
  const { tool } = useToolContext();
  const { creator } = tool;
  return useIsCurrentUser(creator?._id);
};

export default useCurrentUserIsToolOwner;
