import React from "react";
import { BillingPortalCard } from "./BillingPortalCard";
import {
  HYPERLINE_CUSTOMER_ORGANIZATION,
  HYPERLINE_CUSTOMER_USER
} from "../../../../billing/helpers/billingConstants";
import { ContactSalesCard } from "./ContactSalesCard";
import SubscriptionSection from "./SubscriptionSection";
import { CancelAccountCard } from "./DowngradeAccountCard";

const PricingSection = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <SubscriptionSection />
      <ContactSalesCard />
      <CancelAccountCard />
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_ORGANIZATION} />
      <BillingPortalCard customerType={HYPERLINE_CUSTOMER_USER} />
    </div>
  );
};

export default PricingSection;
