import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import useHideResetTool from "./hooks/useHideResetTool";
import type { ButtonSizeTypes } from "@toolflow/shared";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

function ResetToolButton({
  size = "small",
  continueNavigating,
  resetButtonText = "Reset"
}: Readonly<{
  size?: ButtonSizeTypes;
  className?: string;
  continueNavigating?: () => void;
  resetButtonText?: string;
}>) {
  const { dispatch } = useToolbuilderContext();
  const hideReset = useHideResetTool();

  const handleReset = () => {
    if (continueNavigating) {
      continueNavigating();
    }
    dispatch({ type: "RESET_TOOL" });
  };

  if (hideReset) return null;

  return (
    <LoadingButton
      size={size}
      variant="text"
      onClick={handleReset}
      className="m-l-8px"
    >
      {resetButtonText}
    </LoadingButton>
  );
}

export default ResetToolButton;
