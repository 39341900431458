import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useBlockerContext } from "../../../../../navigation/contexts/BlockerContext";
import { closeAgentSaveDialog } from "../../state/agentBuilderSlice";
import useAgentReset from "./useAgentReset";

const useHandleAgentSaveSuccess = (hasSaved: boolean) => {
  const dispatch = useDispatch();
  const { continueNavigating } = useBlockerContext();
  const resetAgent = useAgentReset();
  useEffect(() => {
    if (hasSaved) {
      resetAgent();
      // if the save was successful and there is a blocker to navigate, then navigate to the new location
      // if the save was successful and there is no blocker to navigate, then continueNavigating won't do anything
      continueNavigating();
      dispatch(closeAgentSaveDialog());
    }
  }, [hasSaved]);
};

export default useHandleAgentSaveSuccess;
