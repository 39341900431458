import React from "react";
import CardHeader from "@mui/material/CardHeader";
import type { CardHeaderProps } from "@mui/material/CardHeader";

export default function NoWrapCardHeader(props: CardHeaderProps) {
  return (
    <CardHeader
      {...props}
      sx={{
        display: "flex",
        overflow: "hidden",
        "& .MuiCardHeader-content": {
          overflow: "hidden"
        },
        ...props?.sx
      }}
      titleTypographyProps={{
        noWrap: true,
        ...props?.titleTypographyProps
      }}
      className={`flex align-i-center ${
        props.className ? props.className : ""
      }`}
    />
  );
}
