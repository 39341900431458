import { useToolbuilderContext } from "../../../../../../context/ToolBuilderContext";

const useGetToolOutputFieldIndexById = (toolOutputFieldId: string) => {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  return toolOutputFields.findIndex(
    (toolOutputField) => toolOutputField.id === toolOutputFieldId
  );
};

export default useGetToolOutputFieldIndexById;
