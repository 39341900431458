import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores/store";
import useOpenInputs from "../store/hooks/useOpenInputs";
import { InputOutputPosition } from "./helpers/constants";
import InputOutputBase, { InputType } from "./InputOutputBase";
import { DrawerEnum } from "../store/toolBuilderSlice";
import { InputIcon } from "../../../../../globalTheme/icons/icons";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

export const InputBox = () => {
  const openInputs = useOpenInputs();
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  const { state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;

  return (
    <InputOutputBase
      position={InputOutputPosition.TOP}
      type={InputType.INPUT}
      entityCount={toolInputFields.length}
      onClick={openInputs}
      opened={drawer?.type === DrawerEnum.inputs}
      Icon={InputIcon}
    />
  );
};
