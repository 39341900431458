import { Typography } from "@mui/material";
import React from "react";
import styles from "./assetText.module.css";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function AssetText({ text }: { text: string }) {
  return (
    <Typography className={cx(styles.text, "m-l-4px break-word")} noWrap>
      {text}
    </Typography>
  );
}

export default AssetText;
