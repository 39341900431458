import React from "react";
import { useToolContext } from "../../../../../tools/contexts/ToolContext";
import ExternalToolButton from "./ExternalToolButton";
import useSetSelectedTool from "../../../hooks/useSetSelectedTool";

const ExternalToolCardContainer = () => {
  const { tool } = useToolContext();
  const setSelectedToolById = useSetSelectedTool();

  const onClickHandler = () => {
    setSelectedToolById(tool._id);
  };
  return (
    <ExternalToolButton
      tool={tool}
      onClickHandler={onClickHandler}
      isChatView={true}
    />
  );
};

export default ExternalToolCardContainer;
