import { useGetAllUserEntities } from "./useGetAllUserEntities";

export const useGetFrequentlyUsedEntities = (limit?: number) => {
  const { entities, loading } = useGetAllUserEntities();
  const frequentlyUsedEntities = entities
    .sort((a, b) => {
      const aCount = a?.runCount || 0;
      const bCount = b?.runCount || 0;
      return bCount - aCount;
    })
    .slice(0, limit);
  return { frequentlyUsedEntities, loading };
};
