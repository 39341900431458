import { ElementType } from "react";
import { CustomEdge, TBlock } from "../models";
import { CustomToolOutputField } from "../outputs";
import { DecimalString } from "../primitives";
import { OriginalToolState } from "../types";
import { BlockDataTypes, BlockDataTypesWithDefault } from "./blocks";
import { BlockDataBase, BlockMessageType } from "./blocksBaseTypes";
import { AllBlockTypes } from "./blockTypes";

export type DraggableItem = Omit<
  TBlock,
  "id" | "position" | "data" | "parentNode"
> & {
  data: BlockDataTypes;
};

export enum OutputConfigOptions {
  List = "list-output"
}

export type OutputConfigGetterFunction = (args: {
  id: string;
  data: BlockDataBase;
  toolOutputField: CustomToolOutputField;
  currentState: OriginalToolState;
}) => OutputConfigOptions[];

export interface IValidateProps<T extends BlockDataTypesWithDefault> {
  data: T;
  edges: CustomEdge[];
  id?: string;
}

export type ValidatorFunction<T extends BlockDataTypesWithDefault> = (
  props: IValidateProps<T>
) => BlockMessageType | undefined;

export type BlockInputUpdaterFunction = (
  block: TBlock,
  oldName: string,
  newName?: string,
  deleteBrackets?: boolean
) => TBlock;

export type BlockInputUpdater<T extends AllBlockTypes> = Record<
  T,
  Record<DecimalString, BlockInputUpdaterFunction>
>;

export type BlockConfig = {
  draggableItem: DraggableItem;
  icon: ElementType;
  blockPaperLabel: string;
  drawerComponent: ElementType;
  expandedComponent?: ElementType;
  validate?: ValidatorFunction<BlockDataTypes>;
  updateInput?: BlockInputUpdaterFunction;
  getOutputConfigOptions?: OutputConfigGetterFunction;
};

export type VersionedBlockConfigValue = Omit<BlockConfig, "draggableItem"> & {
  draggableItem: DraggableItem;
};

export type VersionedBlockConfig = {
  [version: string]: VersionedBlockConfigValue;
};

export const DEFAULT_VERSION_VALUE: DecimalString = "1.0";
export const VERSION_2_VALUE: DecimalString = "2.0";
