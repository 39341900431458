import { TextField } from "@mui/material";
import React from "react";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";

function ChangeInputFieldNameTextField({ disabled }: { disabled?: boolean }) {
  const index = useGetCurrentToolInputFieldIndex();
  const name = useGetCurrentToolInputField()?.name;
  const { changeInputName } = useToolInputFieldsContext();

  return (
    <TextField
      fullWidth
      label="Label"
      disabled={disabled}
      className="nowheel nodrag nopan"
      autoComplete="off"
      value={name}
      onChange={(e) => changeInputName(e.target.value, index)}
    />
  );
}

export default ChangeInputFieldNameTextField;
