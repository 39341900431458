import React, { FC } from "react";
import useToolflowNavigate from "../../../features/navigation/hooks/useToolflowNavigate";
import { Typography } from "@mui/material";

interface ITypograpyLinkProps {
  to: string;
  linkText: string;
  color?: string;
}
const TypograpyLink: FC<ITypograpyLinkProps> = ({ to, linkText, color }) => {
  const navigate = useToolflowNavigate();
  const handleClick = () => {
    navigate(to);
  };

  return (
    <Typography
      onClick={handleClick}
      sx={{
        cursor: "pointer",
        color: (theme) => color ?? theme.palette.text.primary
      }}
    >
      {linkText}
    </Typography>
  );
};

export default TypograpyLink;
