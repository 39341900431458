export type TCopyableLargeTextField = "copyableLargeTextField";
export type TCopyableImage = "copyableImage";
export type TCopyableStructured = "copyableStructured";

export enum CopyableField {
  Image = "copyableImage",
  LargeText = "copyableLargeTextField",
  Structured = "copyableStructured"
}

export type TOutputTypePrimitive =
  | {
      type: string;
      description: string;
    }
  | {
      type: "array";
      description: string;
      items: {
        type: "object";
        properties: {
          b64_json: {
            type: "string";
          };
          revised_prompt: {
            type: "string";
          };
          url: {
            type: "string";
          };
        };
      };
    };

export type TOutputTypePrimitiveMap = {
  [key in CopyableField]: TOutputTypePrimitive;
};

const copyableFieldDescriptions = {
  description: "Output this field exactly as it is written"
};

export const OutputTypePrimitiveMap: TOutputTypePrimitiveMap = {
  [CopyableField.LargeText]: {
    type: "string",
    ...copyableFieldDescriptions
  },
  [CopyableField.Image]: {
    type: "array",
    ...copyableFieldDescriptions,
    items: {
      type: "object",
      properties: {
        b64_json: {
          type: "string"
        },
        revised_prompt: {
          type: "string"
        },
        url: {
          type: "string"
        }
      }
    }
  },
  [CopyableField.Structured]: {
    type: "string",
    ...copyableFieldDescriptions
  }
};
