import React, { useEffect, useState } from "react";
import { RootState } from "../../../stores/store";
import { useSelector, useDispatch } from "react-redux";
import { Snackbar } from "@mui/material";
import { clearAlert } from "./alertMessageSlice";
import StyledAlert from "../../../utilities/components/alerts/StyledAlert";

const AlertMessageSnackbar = () => {
  const { message, messageType } = useSelector(
    (state: RootState) => state.layout.alertMessage
  );
  const dispatchRedux = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const clearMessage = () => {
    dispatchRedux(clearAlert());
  };

  const handleClose = () => {
    setSnackbarOpen(false);
    setTimeout(() => {
      clearMessage();
    }, 1000);
  };

  useEffect(() => {
    if (message) {
      setSnackbarOpen(true);
    }
  }, [message]);
  if (!message) return null;

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <StyledAlert severity={messageType || "error"} onClose={handleClose}>
        {message}
      </StyledAlert>
    </Snackbar>
  );
};

export default AlertMessageSnackbar;
