import React from "react";
import WebsiteForm from "../WebsiteForm";
import { useToolContext } from "../../contexts/ToolContext";
import useToolStillLoading from "./hooks/useToolStillLoading";
import { useEditorToolCardContext } from "./EditorToolCardContext";

function EditorToolCardContent() {
  const { tool } = useToolContext();
  const { main } = tool;
  const { toolInputFields } = main;
  const toolStillLoading = useToolStillLoading();
  const { userInput, hasLoaded, setUserInput, defaultUI, fieldsToHide } =
    useEditorToolCardContext();
  if (toolStillLoading) return null;
  return (
    <WebsiteForm
      autofocus={!hasLoaded}
      setFormState={setUserInput}
      formState={userInput}
      toolInputFields={toolInputFields}
      defaultFormState={defaultUI}
      fieldsToHide={fieldsToHide}
    />
  );
}

export default EditorToolCardContent;
