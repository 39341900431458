import React, { useMemo } from "react";
import type { StreamlinedWorkspace } from "@toolflow/shared";
import useGetAllProfileWorkspaces from "../hooks/useGetAllProfileWorkspaces";
import groupByDate from "../../../utilities/formatters/time/groupByDate";
import { SessionHistoryList } from "./SessionHistoryList";

export const SessionHistoryListContainer = ({
  SessionElement,
  className
}: {
  SessionElement: React.FC<{
    workspace: StreamlinedWorkspace;
    className?: string;
  }>;
  className?: string;
}) => {
  const { workspaces } = useGetAllProfileWorkspaces();

  const sortedWorkspaces = useMemo(() => {
    return workspaces.sort((a, b) => b.updatedAt - a.updatedAt);
  }, [workspaces]);

  const groupedWorkspaces = useMemo(
    () => groupByDate(workspaces),
    [workspaces]
  );
  return (
    <SessionHistoryList
      workspaces={sortedWorkspaces}
      groupedWorkspaces={groupedWorkspaces}
      SessionElement={SessionElement}
      className={className}
    />
  );
};
