import type { FileReference } from "@toolflow/shared";
import { isObject } from "lodash";
import { type Accept } from "react-dropzone";
import { CloseIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import FileDropdown from "../FileDropdown";
import CompressFileCTA from "./CompressFileCTA";
import FileDropzone from "./FileDropzone";
import useFileUpload from "./hooks/useFileUpload";
import SelectedFilePaper from "./SelectedFilePaper";

const NoFileSelectedPaper = ({
  accept,
  disabled,
  currentFile,
  showUploadedFiles,
  helperText,
  setCurrentFile
}: {
  accept?: Accept;
  disabled?: boolean;
  helperText?: string;
  currentFile: FileReference | null;
  showUploadedFiles?: boolean;
  setCurrentFile: (file: FileReference | null) => void;
}) => {
  const {
    tmpFile,
    progress,
    abortUpload,
    computedDisabled,
    compressFileCTA,
    handleFileSelect
  } = useFileUpload({ setCurrentFile, accept, disabled });

  if (tmpFile) {
    return (
      <SelectedFilePaper
        action={
          <RemixButton
            icon={CloseIcon}
            size={20}
            onClick={abortUpload}
            className="m-r-8px"
          />
        }
        loading
        progress={progress}
        fileName={tmpFile.name}
        fileSizeInBytes={tmpFile.size}
        fileType={tmpFile.type}
      />
    );
  }

  return (
    <>
      {showUploadedFiles && (
        <FileDropdown
          action={setCurrentFile}
          fileExtensions={
            isObject(accept) ? Object.values(accept).flat() : undefined
          }
          currentFile={currentFile}
          disabled={computedDisabled}
        />
      )}
      <FileDropzone
        acceptedExtensions={accept}
        handleFileSelect={handleFileSelect}
        disabled={computedDisabled}
        helperText={helperText}
      />
      {compressFileCTA && <CompressFileCTA />}
    </>
  );
};

export default NoFileSelectedPaper;
