import CopyLinkMenuItem from "../../../utilities/components/dropdowns/menuItems/CopyLinkMenuItem";
import { EntityType } from "@toolflow/shared";

function CopyWorkflowLinkMenuItem({ workflowId }: { workflowId: string }) {
  return (
    <CopyLinkMenuItem
      objectId={workflowId}
      text="Copy workflow link"
      type={EntityType.WORKFLOW}
    />
  );
}

export default CopyWorkflowLinkMenuItem;
