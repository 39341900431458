import {
  AGENT,
  EntityType,
  type TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import useSetSelectedTool from "../../../../hooks/useSetSelectedTool";
import useSetSelectedWorkspaceWorkflow from "../../../../hooks/useSetSelectedWorkflow";
import useSetSelectedAgent from "../../../../hooks/useSetSelectedAgent";

const useSelectEntityCard = (disableSave = false) => {
  const setSelectedTool = useSetSelectedTool(disableSave);
  const setSelectedWorkflow = useSetSelectedWorkspaceWorkflow(disableSave);
  const setSelectedAgent = useSetSelectedAgent(disableSave);

  const handleSelectEntity = (
    id: string,
    type: TMarketplaceUniversalEntityType
  ) => {
    if (type === EntityType.TOOL) {
      setSelectedTool(id);
    } else if (type === EntityType.WORKFLOW) {
      setSelectedWorkflow(id);
    } else if (type === AGENT) {
      setSelectedAgent(id);
    }
  };

  return {
    handleSelectEntity
  };
};

export default useSelectEntityCard;
