import { useState, useEffect } from "react";

const useHideReset = (isDirty: () => boolean): boolean => {
  const [hideReset, setHideReset] = useState(true);

  useEffect(() => {
    setHideReset(!isDirty());
  }, [isDirty]);

  return hideReset;
};

export default useHideReset;
