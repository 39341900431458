import { Button } from "@mui/material";

function DoneButtonBase({ onClick }: { onClick: () => void }) {
  return (
    <Button variant="outlined" size="small" onClick={onClick}>
      Done
    </Button>
  );
}

export default DoneButtonBase;
