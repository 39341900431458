import { TextSelection } from "@tiptap/pm/state";
import type { Editor } from "@tiptap/core";
import {
  ClickableTypes,
  type TAddChipConfig,
  type ToolAutocomplete
} from "@toolflow/shared";
import deleteSlash from "./deleteSlash";

export function getAllAddChipConfigValues(
  config: TAddChipConfig
): ToolAutocomplete[] {
  return Object.values(config).flatMap((tab) => tab?.values || []);
}

const getNewContent = (chipsToUse: TAddChipConfig, id: string) => {
  const allChips = getAllAddChipConfigValues(chipsToUse);
  return allChips.find((t) => t.id === id)?.secondary || "";
};

const insertText =
  (
    editor: Editor | null,
    chipsToUse?: TAddChipConfig,
    isSlash?: boolean,
    callback?: () => void
  ) =>
  (type: ClickableTypes, id: string) => {
    if (!editor || !chipsToUse) return;
    const newContent = getNewContent(chipsToUse, id);

    deleteSlash(editor, isSlash);
    editor.commands.insertContentAt(
      editor.view.state.selection.to,
      newContent,
      {
        parseOptions: {
          preserveWhitespace: false
        }
      }
    );

    const updatedState = editor.state;
    const newParagraphPos = updatedState.doc.content.size - 1;

    if (newParagraphPos <= updatedState.doc.content.size) {
      const tr = updatedState.tr;
      tr.setSelection(TextSelection.create(updatedState.doc, newParagraphPos));
      editor.view.dispatch(tr);
      editor.commands.focus();
    } else {
      console.error("New position out of range");
    }
    if (callback) {
      callback();
    }
  };

export default insertText;
