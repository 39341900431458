import {
  type TExtensionsConfig,
  type UseExtensionsOptions
} from "@toolflow/shared";
import type { Extension } from "@tiptap/core";
import { formattingMenuExtensions } from "./formattingExtensions";
import { autoLinkExtensions } from "./autoLinkExtensions";
import { DisableEnter } from "./disableEnterExtension";
import { getBaseExtensionsFE } from "./getBaseExtensionsFE";

const extensionMap = {
  formatting: formattingMenuExtensions,
  autolink: autoLinkExtensions,
  disableEnter: [DisableEnter]
};
export const getExtensionsFromConfig = (
  {
    placeholder,
    disableFormattingExtensions,
    enableAutolinkExtension,
    disableDefaultEnter,
    disableCustomExtensions
  }: TExtensionsConfig & UseExtensionsOptions = {},
  customExtensions: Extension[] = []
): Extension[] => {
  const baseExtensions = getBaseExtensionsFE(placeholder);
  const additionalExtensions = [
    ...(!disableFormattingExtensions ? extensionMap.formatting : []),
    ...(enableAutolinkExtension ? extensionMap.autolink : []),
    ...(disableDefaultEnter ? extensionMap.disableEnter : []),
    ...(!disableCustomExtensions ? customExtensions : [])
  ];

  return [...baseExtensions, ...additionalExtensions] as Extension[];
};
