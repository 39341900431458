import React from "react";
import { CircularProgress } from "@mui/material";

function CircularLoading({
  className = "",
  size = 40
}: {
  className?: string;
  size?: number;
}) {
  return (
    <div className={`justify-center align-i-center flex ${className}`}>
      <CircularProgress size={size} />
    </div>
  );
}

export default CircularLoading;
