import React, { FC } from "react";
import NavigateToBuilderMenuItem from "../../NavigateToBuilderMenuItemInHome";
import CopyLinkMenuItemInHome from "../../../../../utilities/components/textFields/workspace/dropdowns/menuItems/CopyLinkMenuItemInHome";
import { BoldTableCell, StyledTableCell } from "../../layout/TableLayouts";
import EntityRow from "../EntityRow";
import HomePageMenuEntityDropdownMenu from "../../../../entities/components/HomePageMenuEntityDropdownMenu";
import { useGetCurrentUserProfileId } from "../../../../user/hooks/useGetCurrentUserProfileId";
import type { TMarketplaceEntity } from "@toolflow/shared";

interface IToolBlockEntityTableRowProps {
  entity: TMarketplaceEntity;
  removeLastRowBorder?: boolean;
}
const ToolBlockEntityTableRow: FC<IToolBlockEntityTableRowProps> = ({
  entity,
  removeLastRowBorder
}) => {
  const profileId = useGetCurrentUserProfileId();
  return (
    <EntityRow
      key={entity.id}
      entityId={entity.id}
      entityType={entity.type}
      removeLastRowBorder={removeLastRowBorder}
    >
      <BoldTableCell>{entity.name}</BoldTableCell>
      <StyledTableCell align="right">
        <CopyLinkMenuItemInHome objectId={entity.id} type={entity.type} />
        {entity.userId === profileId && (
          <NavigateToBuilderMenuItem entityId={entity.id} type={entity.type} />
        )}
        <HomePageMenuEntityDropdownMenu homPageEntity={entity} />
      </StyledTableCell>
    </EntityRow>
  );
};

export default ToolBlockEntityTableRow;
