import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { setDisableTabChange } from "../../../../../features/pages/workstation/state/workspaceSlice";
import { useDispatch } from "react-redux";
import {
  DEFAULT_WORKSPACE_ENTITY,
  TOOL_TAB_USER_INPUT_FIELD_LABEL,
  WORKSPACE_ENTITY_BASE
} from "../../../../../features/pages/workstation/helpers/workspaceConstants";

// THIS NEEDS TO WORK FOR SOMEONE TO RERUN THE TOOL
const useHandleCloseEditorToolCardWorkspace = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(setDisableTabChange(false));
    setValue(
      TOOL_TAB_USER_INPUT_FIELD_LABEL,
      {},
      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      }
    );
    setValue(WORKSPACE_ENTITY_BASE, DEFAULT_WORKSPACE_ENTITY, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };

  return handleClose;
};

export default useHandleCloseEditorToolCardWorkspace;
