import React from "react";
import { Button } from "@mui/material";
import { ArrowDownSmallIcon } from "../../../../../globalTheme/icons/icons";
import { SessionHistoryDialogButton } from "../../../../workspace/components/SessionHistoryDialogButton";
import { cx } from "./GetStarted";

export function SessionHistoryButton({ className }: { className?: string }) {
  return (
    <SessionHistoryDialogButton
      button={
        <Button
          variant="text"
          size="small"
          className={cx("bg-color-transparent", className)}
          endIcon={<ArrowDownSmallIcon size={18} />}
        >
          Session history
        </Button>
      }
    />
  );
}
