import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import useNavigateToToolBuilder from "../../../tools/hooks/useNavigateToToolBuilder";

function NavigateToToolBuilderButton({
  toolId,
  providedColor,
  className = "",
  iconButtonClassName = ""
}: {
  toolId: string;
  providedColor?: string;
  className?: string;
  iconButtonClassName?: string;
}) {
  const navigateToToolBuilder = useNavigateToToolBuilder();

  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={navigateToToolBuilder(toolId)}
      disabled={!toolId}
      providedColor={providedColor}
      className={className}
      iconButtonClassName={iconButtonClassName}
    />
  );
}

export default NavigateToToolBuilderButton;
