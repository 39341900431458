import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import type {
  OptimizationsType,
  TAnthropicOptimizationsType
} from "@toolflow/shared";
interface IUseCurrentAndPrevOptmizationsSyncProps {
  optimizations: OptimizationsType | TAnthropicOptimizationsType;
}

const useCurrentAndPrevOptmizationsSync = (
  props: IUseCurrentAndPrevOptmizationsSyncProps
) => {
  const { optimizations } = props;
  const [prevOptimizations, setPrevOptimizations] = useState(optimizations);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(optimizations, prevOptimizations)) {
      // ToolInputFields have changed, trigger re-render
      // Update the previous toolInputFields value
      setPrevOptimizations(optimizations);
    }
  }, [optimizations]);
  return <div>useCurrentAndPrevOptmizationsSync</div>;
};

export default useCurrentAndPrevOptmizationsSync;
