import { IconButton } from "@mui/material";
import { type RemixiconComponentType } from "@remixicon/react";
import React from "react";
import RemixWrapperIconWithTheme from "./RemixWrapperIconWithTheme";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import { DarkUtilityTooltip } from "../toolTips/DarkUtilityTooltip";

const RemixButton = ({
  text,
  icon,
  onClick,
  disabled,
  className = "",
  providedColor = toolflowTextDisabledColor,
  iconButtonClassName = "",
  size = 16
}: {
  text?: string;
  icon: RemixiconComponentType;
  onClick: (e: React.MouseEvent) => void;
  disabled?: boolean;
  className?: string;
  providedColor?: string;
  iconButtonClassName?: string;
  size?: number;
}) => {
  return (
    <DarkUtilityTooltip title={text} className={className}>
      <IconButton
        onClick={onClick}
        disabled={disabled}
        className={iconButtonClassName}
      >
        <RemixWrapperIconWithTheme
          Icon={icon}
          size={size}
          providedColor={providedColor}
        />
      </IconButton>
    </DarkUtilityTooltip>
  );
};

export default RemixButton;
