import { Drawer } from "@mui/material";
import React from "react";
import {
  DEFAULT_TOOLFLOW_CONSTANTS_FE,
  useGetToolflowConstantsQuery
} from "../../../ToolflowAPI/rtkRoutes/toolflowConstantsApi";
import useResize, { ResizableDiv } from "../../hooks/useResize";

const PlaceholderDiv = ({ large }: { large?: boolean }) => {
  const {
    data: toolflowConstantData = {
      toolflowConstants: DEFAULT_TOOLFLOW_CONSTANTS_FE
    }
  } = useGetToolflowConstantsQuery();
  const toolflowConstants = toolflowConstantData.toolflowConstants;
  const defaultHeight = large ? 0 : 58;
  const hasAppWarningHeight = toolflowConstants.appWarning ? 83 : 0;
  const height = defaultHeight + hasAppWarningHeight;
  return <div style={{ minHeight: height }}></div>;
};

function ClippedDrawer({
  open,
  children,
  drawerId
}: {
  open: boolean;
  large?: boolean;
  children: React.ReactNode;
  drawerId?: string;
}) {
  const { width, enableResize } = useResize({
    minWidth: 200,
    initialWidth: "50%"
  });
  return (
    <Drawer
      open={open}
      anchor="right"
      variant="persistent"
      PaperProps={{ id: drawerId }}
      sx={{
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box"
        },
        transition: (theme) =>
          theme.transitions.create(["width", "right"], {
            // include "left" here
            easing: theme.transitions.easing.sharp,
            duration: !open
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen
          })
      }}
    >
      <PlaceholderDiv />
      <div className="oflow-auto flex-grow-1 flex">
        <ResizableDiv enableResize={enableResize} />
        {children}
      </div>
    </Drawer>
  );
}

export default ClippedDrawer;
