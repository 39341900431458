import React from "react";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import useNavigateToSnippet from "./hooks/useNavigateToSnippet";
import { EditIcon } from "../../../globalTheme/icons/icons";

function EditSnippetButton({ id }: { id: string }) {
  const navigate = useNavigateToSnippet(id);

  const navigateToSnippet = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(e);
  };

  return (
    <RemixButton text="Edit" icon={EditIcon} onClick={navigateToSnippet} />
  );
}

export default EditSnippetButton;
