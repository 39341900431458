import {
  StyledHeaderCell,
  StyledTableSortLabel
} from "../../../home/layout/TableLayouts";
import { toolflowTextDisabledColor } from "../../../../../globalTheme/muiUtils/appTheme";
import React from "react";
import { Order } from "../../../home/utlils/tableTypes";
import { Box } from "@mui/material";
import styles from "./headerSortableCell.module.css";
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon
} from "../../../../../globalTheme/icons/icons";

function HeaderSortableCell({
  sortDirection,
  onClick,
  label
}: {
  sortDirection: Order;
  onClick: () => void;
  label: string;
}) {
  return (
    <StyledHeaderCell
      sortDirection={sortDirection}
      className={styles["knowledge-table-header"]}
    >
      <StyledTableSortLabel
        direction={sortDirection}
        onClick={onClick}
        IconComponent={() =>
          sortDirection === "asc" ? (
            <ArrowUpSmallIcon size={16} color={toolflowTextDisabledColor} />
          ) : (
            <ArrowDownSmallIcon size={16} color={toolflowTextDisabledColor} />
          )
        }
      >
        <Box className="m-r-8px">{label}</Box>
      </StyledTableSortLabel>
    </StyledHeaderCell>
  );
}

export default HeaderSortableCell;
