import { type FieldConfig, type KnowledgeBlockProps } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useUpdateField from "../../useUpdateField";
import useKnowledgeFieldsConfig from "./useKnowledgeFieldsConfig";

export default function KnowledgeBlockInner({
  data,
  id
}: Omit<KnowledgeBlockProps, "selected">) {
  const { label } = data;
  const { state } = useToolbuilderContext();
  const fields = useKnowledgeFieldsConfig(id);
  const { toolOutput } = state;

  const updateField = useUpdateField(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields
  };

  return (
    <div className="m-b-32px m-t-16px">
      <FieldsByType configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}
