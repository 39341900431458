import {
  WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL,
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_STEP_DESCRIPTION_FIELD_LABEL,
  WORKFLOW_STEP_MAPPED_INPUTS_FIELD_LABEL,
  WORKFLOW_STEP_NAME_FIELD_LABEL,
  WORKFLOW_STEP_TOOL_ID_FIELD_LABEL,
  WORKFLOW_STEP_TYPE_FIELD_LABEL
} from "../../WorkflowBuilderConstants";
import useGetWorkflowStepFieldById from "./useGetWorkflowStepFieldById";

type TInnerTypes =
  | typeof WORKFLOW_STEP_DESCRIPTION_FIELD_LABEL
  | typeof WORKFLOW_STEP_NAME_FIELD_LABEL
  | typeof WORKFLOW_STEP_MAPPED_INPUTS_FIELD_LABEL
  | typeof WORKFLOW_STEP_TYPE_FIELD_LABEL
  | typeof WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL
  | typeof WORKFLOW_STEP_TOOL_ID_FIELD_LABEL;

const useGetWorkflowInnerFieldById = <T extends TInnerTypes>(
  stepId: string,
  innerField: T
): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${T}` => {
  const stepField = useGetWorkflowStepFieldById(stepId);
  return `${stepField}.${innerField}`;
};

export default useGetWorkflowInnerFieldById;
