import {
  secondaryClosedMixin,
  secondaryOpenedMixin
} from "./builderDrawerStyles";
import { Drawer, styled } from "@mui/material";
import {
  EXPANED_DRAWER_WIDTH,
  LARGE_DRAWER_WIDTH
} from "../../../../../utilities/constants/widths";
import useBuilderDrawer from "./hooks/useBuilderDrawer";
import { lightButtonBorder } from "../../../../../globalTheme/muiUtils/appTheme";
import BuilderDrawerInner from "./BuilderDrawerInner";
import FullScreenDialog from "../../../../../utilities/components/dialogs/fullScreenDialog/FullScreenDialog";

const StyledBuilderDrawer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "height" && prop !== "expanded"
})<{ open: boolean; height: number; expanded: boolean }>(
  ({ theme, open, height, expanded }) => ({
    flexShrink: 0,
    boxSizing: "border-box",
    height: `calc(${height}px - 32px)`,

    "& .MuiDrawer-root": {
      position: "absolute"
    },
    "& .MuiPaper-root.MuiDrawer-paper": {
      overflow: "hidden",
      height: `calc(${height}px - 32px)`,
      position: "absolute",
      marginRight: "8px",
      marginTop: "8px",
      padding: "0px",
      right: "0px",
      top: "0px",
      background: "#FFF",
      borderRadius: "16px",
      border: open ? `1px solid ${lightButtonBorder}` : "0px"
    },
    ...(open && {
      ...secondaryOpenedMixin(
        theme,
        open,
        expanded ? EXPANED_DRAWER_WIDTH : LARGE_DRAWER_WIDTH
      ),

      boxShadow: "0px 6px 8px 0px rgba(101, 91, 71, 0.06)",
      "& .MuiDrawer-paper": secondaryOpenedMixin(
        theme,
        open,
        expanded ? EXPANED_DRAWER_WIDTH : LARGE_DRAWER_WIDTH
      )
    }),
    ...(!open && {
      ...secondaryClosedMixin(theme, !!open),
      border: "0px",

      boxShadow: "none",
      "& .MuiDrawer-paper": {
        ...secondaryClosedMixin(theme, !!open),
        borderLeft: "0px"
      }
    })
  })
);

function BuilderDrawer({ height }: { height: number }) {
  const { drawer, expanded, dialogOpen, closeDialog } = useBuilderDrawer();
  return (
    <>
      <StyledBuilderDrawer
        open={!!drawer}
        height={height}
        expanded={expanded}
        anchor="right"
        variant="permanent"
      >
        <BuilderDrawerInner />
      </StyledBuilderDrawer>
      <FullScreenDialog
        innerSlot={<BuilderDrawerInner />}
        open={dialogOpen}
        setClosed={closeDialog}
      />
    </>
  );
}

export default BuilderDrawer;
