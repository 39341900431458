import React from "react";
import UpdateTextFieldBase from "../../../../utilities/components/textFields/UpdateTextFieldBase";
import type { IUpdateTextFieldContainerProps } from "@toolflow/shared";
import { WORKFLOW_DESCRIPTION_FIELD_LABEL } from "./WorkflowBuilderConstants";

const UpdateWorkflowDescriptionTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder
}: IUpdateTextFieldContainerProps) => {
  return (
    <UpdateTextFieldBase
      size={size}
      placeholder={placeholder}
      label={label}
      margin={margin}
      fieldLabel={WORKFLOW_DESCRIPTION_FIELD_LABEL}
    />
  );
};

export default UpdateWorkflowDescriptionTextField;
