import { Button, Card, CardHeader } from "@mui/material";
import { ALFIE_CALENDLY_URL } from "./helpers/constants";

export function ContactSalesCard() {
  return (
    <Card variant="outlined" className="m-b-16px m-t-16px">
      <CardHeader
        title="Not sure which plan to choose or need more credits?"
        subheader="Email alfie@toolflow.ai or schedule a call directly"
        action={
          <Button
            onClick={() => {
              window.open(ALFIE_CALENDLY_URL, "rel=noopener noreferrer");
            }}
            variant="outlined"
          >
            Schedule a call
          </Button>
        }
      />
    </Card>
  );
}
