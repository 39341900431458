import { Stack } from "@mui/material";
import {
  BlockNode,
  CopyableField,
  type DallEBlockDataV2,
  type DallEBlockPropsV2
} from "@toolflow/shared";
import { uniq } from "lodash";
import { useMemo } from "react";
import useGetChips from "../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import { textInputContainerExtensionsConfig } from "../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import TestToolRun from "../../common/TestToolRun";
import TestFieldsWithPrompt from "../components/testWrapper/TestFieldsWithPrompt";
import TestOutput from "../components/testWrapper/TestOutput";
import useTest from "../hooks/useTest";
import useTestInputFromFieldsByType from "../hooks/useTestInputFromFieldsByType";
import usePromptFiller from "../promptBlocks/usePromptFiller";
import useDallEFieldsConfig from "./useDallEFieldsConfig";

export default function TestDallE({
  data,
  id
}: Omit<DallEBlockPropsV2, "selected">) {
  const getChipsList = useGetChips();
  const { fields: fieldsByType } = useDallEFieldsConfig({ data, id });

  const prompt = data.settings.prompt;

  const promptFieldsToTest = useMemo(() => getChipsList(prompt), [prompt]);

  const { userInput, setUserInput, fields } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings, data.optimizations]
  );

  const combinedFields = uniq([...promptFieldsToTest, ...fields]);

  const { action, label, text, endIcon, showUpgrade, loading, output } =
    useTest<DallEBlockDataV2>({
      data,
      id,
      blockType: BlockNode.DallEBlockNode,
      fields: combinedFields,
      userInput
    });

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(prompt, userInput),
    [userInput, prompt, insertUserInputIntoPrompt]
  );

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFieldsWithPrompt
            fields={combinedFields}
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
          />
          <TestOutput
            output={output}
            label={label}
            type={CopyableField.Image}
          />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}
