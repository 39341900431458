import AddOutputButton from "../../../outputs/customToolOutputFields/AddOutputButton";
import { OUTPUT_TEXT } from "../helpers/constants";
import EmptyBase from "./EmptyBase";
import EmptyOutputIcon from "./EmptyOutputIcon";

function EmptyOutputs() {
  return (
    <EmptyBase
      title="Outputs"
      firstText={OUTPUT_TEXT}
      secondText="💡If you are unable to choose outputs here, then start by adding nodes to your tool."
      button={<AddOutputButton />}
      icon={<EmptyOutputIcon />}
    />
  );
}

export default EmptyOutputs;
