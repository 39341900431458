import {
  CopyableField,
  type MultiUserInputResponseWithHandledError
} from "@toolflow/shared";
import GroupAccordion from "../../../common/fieldsByType/fieldsGroupComponent/GroupAccordion";
import OutputField from "../../../outputField/OutputField";
import EmptyTestBlock from "../EmptyTestBlock";

function TestOutput({
  output,
  label,
  type = CopyableField.LargeText
}: {
  output: MultiUserInputResponseWithHandledError | null;
  label: string;
  type?: CopyableField;
}) {
  return (
    <GroupAccordion title="Test output" initialValue={true}>
      {output ? (
        <OutputField
          className="p-h-12px m-t-8px"
          toolOutput={output ? { [label]: output } : null}
          toolOutputField={{
            name: label,
            type
          }}
        />
      ) : (
        <EmptyTestBlock
          text="Output preview area"
          subText="Test your node here."
        />
      )}
    </GroupAccordion>
  );
}

export default TestOutput;
