import { useDispatch } from "react-redux";
import { CopyIcon } from "../../../../../../../globalTheme/icons/icons";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { setSuccessMessage } from "../../../../../../../stores/actions";
import useGetCurrentAsset from "../../../../assets/useGetCurrentAsset";

const CopyJsonText = () => {
  const reduxDispatch = useDispatch();
  const input = useGetCurrentAsset();

  const handleCopyClick = () => {
    if (input?.value) {
      const jsonValue = JSON.stringify(input.value);

      navigator.clipboard.write([
        new ClipboardItem({
          "text/plain": new Blob([jsonValue], {
            type: "text/plain"
          })
        })
      ]);
      reduxDispatch(setSuccessMessage("Copied"));
    }
  };

  return <RemixButton text="Copy" icon={CopyIcon} onClick={handleCopyClick} />;
};

export default CopyJsonText;
