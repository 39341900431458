import React from "react";
import { useEntityContext } from "../../context/EntityContext";
import { ClearAnyEntityButton } from "../../../pages/workstation/components/entities/ClearAnyEntityButton";
import CopyEntityLink from "../../../tools/components/searchToolsContainer/CopyEntityLink";
import ToggleFavoriteWithTooltip from "../../../../utilities/components/toggleFavorite/ToggleFavoriteWithTooltip";
import { EditOrDuplicateSplitter } from "./EditOrDuplicateSplitter";

export const EntityActions = () => {
  const { entity } = useEntityContext();
  return (
    <div className="flex align-i-center justify-flex-end">
      <CopyEntityLink entityId={entity.id} entityType={entity.type} />
      <EditOrDuplicateSplitter />
      <ToggleFavoriteWithTooltip
        id={entity.id}
        favorited={entity.userFavorited}
        type={entity.type}
        size={16}
        creatorId={entity.userId}
        inMarketplace={entity.isFeatured}
        disableBorder
        text={entity.userFavorited ? "Unstar" : "Star"}
      />
      <ClearAnyEntityButton />
    </div>
  );
};
