import { type JSONSchemaItem } from "@toolflow/shared";
import { isArray } from "lodash";

interface Path {
  path: string;
  type: JSONSchemaItem["type"];
}

export function getOutputFieldsFromJsonSchema(
  schema: JSONSchemaItem | JSONSchemaItem[],
  prepend?: string
) {
  const paths: Path[] = [];

  if (isArray(schema)) {
    schema.forEach((item) => {
      paths.push(...getOutputFieldsFromJsonSchema(item, prepend));
    });
  } else if (schema.type === "object") {
    schema.items.forEach((item) => {
      paths.push(
        ...getOutputFieldsFromJsonSchema(
          item,
          prepend ? `${prepend}.${schema.fieldLabel}` : schema.fieldLabel
        )
      );
    });
  } else if (schema.type === "array") {
    paths.push({
      path: prepend ? `${prepend}.${schema.fieldLabel}` : schema.fieldLabel,
      type: schema.type
    });

    if (schema.itemType.type === "object") {
      schema.itemType.items.forEach((item) => {
        paths.push(
          ...getOutputFieldsFromJsonSchema(
            item,
            prepend
              ? `${prepend}.${schema.fieldLabel}[]`
              : `${schema.fieldLabel}[]`
          )
        );
      });
    }
  } else {
    paths.push({
      path: prepend ? `${prepend}.${schema.fieldLabel}` : schema.fieldLabel,
      type: prepend?.endsWith("[]") ? "array" : schema.type
    });
  }
  return paths;
}

export function findNestedSchema(
  schema: JSONSchemaItem | JSONSchemaItem[],
  path: string[]
) {
  const key = path.at(0);
  const slicedPath = path.slice(1);

  if (isArray(schema)) {
    const found = schema.find((s) => s.fieldLabel === key);
    if (found) {
      if (!slicedPath.length) return found;
      return findNestedSchema(found, slicedPath);
    }
  } else {
    if (schema.fieldLabel === key) {
      if (!slicedPath.length) {
        return schema;
      } else if (schema.type === "object") {
        return findNestedSchema(schema.items, slicedPath);
      } else if (schema.type === "array" && schema.itemType.type === "object") {
        return findNestedSchema(schema.itemType.items, slicedPath);
      }
    }
  }
}
