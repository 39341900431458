import { useMemo } from "react";
import { useGetToolHistoryQuery } from "../../../../../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";
import { useAuthStore } from "../../../../../../auth/hooks/useAuthStore";
import type { ExternalToolFE } from "@toolflow/shared";
import convertExternalToolFEToMarketplaceEntity from "../../../../../../tools/functions/convertExternalToolFEToMarketplaceEntity";
import { useAllMarketplaceEntities } from "../../../../../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useGetSuggestedEntities = () => {
  const { data, isLoading: toolHistoryLoading } = useGetToolHistoryQuery({
    page: 1,
    limit: 10
  });
  const { toolHistory: history } = data;
  const { isLoading } = useAuthStore();
  const fetchedMarketplaceEntities = useAllMarketplaceEntities();

  const uniqueTools = useMemo(() => {
    const toolIdSet = new Set();
    if (isLoading || toolHistoryLoading) return [];
    const filteredTools = history
      .filter((tvr) => {
        if (
          typeof tvr.toolId !== "string" &&
          tvr?.toolId?._id &&
          !toolIdSet.has(tvr.toolId._id) &&
          tvr.responseDict
        ) {
          toolIdSet.add(tvr.toolId._id);
          return true;
        }
        return false;
      })
      .map((tvr) =>
        convertExternalToolFEToMarketplaceEntity(tvr.toolId as ExternalToolFE)
      );

    const marketplaceTools = (fetchedMarketplaceEntities || []).filter(
      (tool) => {
        const toolId = tool.id;
        if (!toolIdSet.has(toolId)) {
          toolIdSet.add(toolId);
          return true;
        }
        return false;
      }
    );

    const combinedTools = filteredTools.concat(marketplaceTools);

    return combinedTools.slice(0, 3);
  }, [history, fetchedMarketplaceEntities]);

  return uniqueTools;
};

export default useGetSuggestedEntities;
