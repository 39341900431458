export type TVariableType = "Asset";
export enum AutocompleteGroups {
  Workflow = "Workflow",
  BlockOutputs = "Block Outputs",
  Tool = "Tool",
  Snippet = "Snippet",
  Inputs = "Inputs", // Toolbuilder inputs
  VariableType = "VariableType",
  Commands = "Commands",
  Asset = "Asset"
}

export enum MarketplaceGroup {
  TOOL = "Tools",
  WORKFLOW = "Workflows",
  AGENT = "Agents",
  USE_CASE = "Use Cases"
}

export type TSelectAutocomplete = (id: string, type: MarketplaceGroup) => void;

export type ToolAutocomplete = {
  primary: string;
  id: string;
  secondary: string;
  group: AutocompleteGroups;
  favoriteType?: "star";
};
export type MarketplaceEntityAutocomplete = {
  primary: string;
  id: string;
  secondary: string;
  group: MarketplaceGroup;
  favoriteType?: "star";
  useCases: string[];
};
