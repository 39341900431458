import React from "react";
import useGetSelectedWorkspaceWorkflow from "../../../hooks/useGetSelectedWorkspaceWorkflow";
import { WorkflowContextComponent } from "../../../../../workflow/contexts/WorkflowContext";
import RunWorkflowCardInner from "./RunWorkflowCardInner";
import useGetWorkspacePopulatedWorkflow from "../../../hooks/useGetSelectedPopulatedWorkflow";

const RunWorkflowCard = () => {
  const workflow = useGetSelectedWorkspaceWorkflow();
  const workflowVersion = useGetWorkspacePopulatedWorkflow();
  if (!workflow || !workflowVersion) return null;
  return (
    <WorkflowContextComponent
      workflow={workflow}
      workflowVersion={workflowVersion}
    >
      <RunWorkflowCardInner />
    </WorkflowContextComponent>
  );
};

export default RunWorkflowCard;
