import { useMuiTipTapContext } from "../../context/MuiTipTapContext";

const useCloseBubble = () => {
  const { setShouldRenderBubble } = useMuiTipTapContext();
  const closeBubble = () => {
    setShouldRenderBubble(false);
  };
  return closeBubble;
};

export default useCloseBubble;
