import React from "react";
import {
  Drawer as MuiDrawer,
  styled,
  type Theme,
  type CSSObject
} from "@mui/material";
import { LARGE_DRAWER_WIDTH } from "../../../../utilities/constants/widths";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../stores/store";
import { updateToolInInfoDrawer } from "./toolInfoDrawerSlice";
import { MoreInfoCard } from "../MoreInfoCard";

const openedMixin = (theme: Theme, expanded?: boolean): CSSObject => ({
  width: expanded ? "100%" : LARGE_DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

interface DrawerProps {
  open: boolean;
  expanded?: boolean;
}

const Drawer = styled(MuiDrawer)<DrawerProps>(({ theme, open, expanded }) => ({
  width: LARGE_DRAWER_WIDTH,
  color: "red",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, expanded),
    "& .MuiDrawer-paper": openedMixin(theme, expanded)
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

function ToolInfoDrawer() {
  const toolInInfoDrawer = useSelector(
    (state: RootState) => state.toolInfoDrawer.tool
  );
  const dispatch = useDispatch();
  if (!toolInInfoDrawer) return null;
  const { name } = toolInInfoDrawer;

  const close = () => {
    dispatch(updateToolInInfoDrawer(null));
  };

  return (
    <Drawer open={!!toolInInfoDrawer} onClose={close} anchor="right">
      <MoreInfoCard name={name} closeDrawer={close} />
    </Drawer>
  );
}

export default ToolInfoDrawer;
