import React from "react";
import { getHyperlineCheckoutUrl } from "../../../../../billing/functions/getHyperlineCheckoutUrl";
import { useSubscriptionDetails } from "../../../../../billing/hooks/useSubscriptionDetails";
import {
  DOWNGRADE,
  ORGANIZATION_PLAN_FE,
  UPGRADE,
  hyperlineProPlanIdDevOrStaging,
  hyperlineProPlanIdProduction
} from "../../../../../billing/helpers/billingConstants";
import {
  ENVIRONMENT,
  type ButtonVariantTypes,
  type HyperlinePlans,
  type TAvailableTiers,
  type TSubscriptionParams
} from "@toolflow/shared";
import {
  useCancelSubscriptionMutation,
  useUpdateSubscriptionMutation
} from "../../../../../../ToolflowAPI/rtkRoutes/billingApi";
import useGetCurrentUser from "../../../../../user/hooks/useGetCurrentUser";
import useSubscriptionDict from "./useSubscriptionDict";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { tierStatusParameters } from "../helpers/tierStatusParameters";
import SelectSeatsDialog from "../SelectSeatsDialog";
import LoadingButton from "@mui/lab/LoadingButton";

const useGetUpdateSubscriptionButton = ({
  tier
}: {
  tier: TAvailableTiers;
}) => {
  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();
  const [updateSubscription, { isLoading: isUpdating }] =
    useUpdateSubscriptionMutation();
  const user = useGetCurrentUser();

  const { activeTier } = useSubscriptionDetails();
  const subscriptionDict = useSubscriptionDict();

  const handleClick =
    (planId?: HyperlinePlans) => async (planParams?: TSubscriptionParams) => {
      if (user) {
        // if no planId, that means its the free tier
        // that means we want to cancel the subscription
        if (!planId) {
          cancelSubscription();
        } else {
          // we want to redirect if the user is on the free plan
          updateSubscription({ planId, ...planParams });
        }
      }
    };
  const loading = isUpdating || isCanceling;

  const { pendingTier, pendingCheckoutSessionId } = useSubscriptionDetails();
  const getUpdateSubscriptionButton = () => {
    if (pendingTier === tier) {
      const checkoutUrl = getHyperlineCheckoutUrl(pendingCheckoutSessionId);
      return (
        <Link to={checkoutUrl}>
          <Button variant="contained" size="large" fullWidth>
            Continue signing up
          </Button>
        </Link>
      );
    }
    let ctaText = "";
    let ctaButtonVariant: ButtonVariantTypes = "outlined";
    let ctaButtonDisabled = true;
    let ctaButtonIcon: React.ReactNode = null;
    let ctaButtonAction: () => void = () => {};

    const upgradeToPro = () => {
      const planId =
        process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION
          ? hyperlineProPlanIdProduction
          : hyperlineProPlanIdDevOrStaging;
      updateSubscription({ planId });
    };

    if (user) {
      ctaText =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].text;
      ctaButtonVariant =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].variant;
      ctaButtonDisabled =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].disabled;
      ctaButtonIcon =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]].icon;

      ctaButtonAction =
        tierStatusParameters[subscriptionDict[activeTier].ctas[tier]]
          .btnLinkAction || upgradeToPro;

      // With the currently active tier, if the tier of the paper is Downgrade, then show the downgrade dialog
      if (subscriptionDict[activeTier].ctas[tier] === DOWNGRADE) {
        return (
          <LoadingButton
            onClick={ctaButtonAction}
            variant={ctaButtonVariant}
            size="large"
            fullWidth
            loading={loading}
            disabled={ctaButtonDisabled}
          >
            {ctaText}
          </LoadingButton>
        );
        // with the currently active tier, if the tier of the paper is upgrade, and the tier of the paper
        // is organization, then show the select seats dialog
      } else if (
        subscriptionDict[activeTier].ctas[tier] === UPGRADE &&
        tier === ORGANIZATION_PLAN_FE
      ) {
        return (
          <SelectSeatsDialog
            action={handleClick(subscriptionDict[tier].planId)}
            loading={loading}
            buttonText={ctaText}
            buttonVariant={ctaButtonVariant}
            buttonDisabled={ctaButtonDisabled}
          />
        );
      } else {
        return (
          <LoadingButton
            loading={loading}
            variant={ctaButtonVariant}
            disabled={ctaButtonDisabled}
            size="large"
            startIcon={ctaButtonIcon}
            fullWidth
            onClick={ctaButtonAction}
          >
            {ctaText}
          </LoadingButton>
        );
      }
    }
  };
  return {
    getUpdateSubscriptionButton
  };
};

export default useGetUpdateSubscriptionButton;
