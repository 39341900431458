import React, { FC } from "react";
import EntityNotFoundBase from "../../../utilities/components/autocomplete/universalAutocomplete/EntityNotFoundBase";
import NavigateToToolsPageButton from "../toolsPage/NavigateToToolsPageButton";
import IconWithCircle from "../../../globalTheme/icons/logos/IconWithCircle";
import RemixWrapperIconWithTheme from "../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { toolflowPrimaryColor } from "../../../globalTheme/muiUtils/appTheme";
import { ListCheckIcon } from "../../../globalTheme/icons/icons";

interface INoRecentDataProps {
  header?: string;
  subHeader?: string;
}
const NoRecentData: FC<INoRecentDataProps> = ({
  header = "Your tools and recent sessions will be stored here",
  subHeader = "Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
}) => {
  return (
    <EntityNotFoundBase
      className="flex text-align-center align-i-center  justify-center flex-column h-450px m-h-auto max-w-350px"
      icon={
        <IconWithCircle
          icon={
            <RemixWrapperIconWithTheme
              Icon={ListCheckIcon}
              size={40}
              providedColor={toolflowPrimaryColor}
            />
          }
        />
      }
      header={header}
      subHeader={subHeader}
      actionButton={
        <NavigateToToolsPageButton className="border-radius-8px text-transform-none" />
      }
    />
  );
};

export default NoRecentData;
