import type { InitialStateResponseFE } from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import { billingApi } from "./billingApi";
import { apiKeyApi } from "./apiKeyApi";
import { snippetAdapter } from "../../stores/adapters/snippetAdapter";
import {
  filesAdapter,
  filesInitialState
} from "../../stores/adapters/filesAdapter";
import { normalizeSecrets, secretApi } from "./secretApi";
import { snippetApi } from "./snippetsApi";
import { fileApi } from "./fileApi";
import { toolflowConstantApi } from "./toolflowConstantsApi";
import { openAppWarning } from "../../features/layout/sidebar/appWarningSlice";
import { integrationPriceApi } from "./integrationPriceApi";

const initialStateApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getInitialState: builder.query<InitialStateResponseFE, void>({
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: (arg: void) => ({
        url: "",
        method: "GET"
      }),

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // // `onStart` side-effect
        // dispatch(messageCreated("Fetching post..."));
        try {
          const { data } = await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(
            integrationPriceApi.util.upsertQueryData(
              "getIntegrationPrices",
              undefined,
              {
                integrationPrices: data.integrationPrices
              }
            )
          );
          dispatch(
            toolflowConstantApi.util.upsertQueryData(
              "getToolflowConstants",
              undefined,
              {
                toolflowConstants: data.toolflowConstants
              }
            )
          );
          // if the toolflowConstant has an app warning, open the app warning
          if (data?.toolflowConstants.appWarning) {
            dispatch(openAppWarning());
          }
          dispatch(
            billingApi.util.upsertQueryData("getCredits", undefined, {
              credits: data.credits
            })
          );
          dispatch(
            apiKeyApi.util.upsertQueryData("getApiKeys", undefined, {
              apiKeys: data.apiKeys
            })
          );
          dispatch(
            fileApi.util.upsertQueryData(
              "getFiles",
              undefined,
              filesAdapter.upsertMany(filesInitialState, data.files || [])
            )
          );
          dispatch(
            snippetApi.util.upsertQueryData(
              "getSnippets",
              undefined,
              snippetAdapter.upsertMany(
                snippetAdapter.getInitialState(),
                data.snippets || []
              )
            )
          );
          dispatch(
            secretApi.util.upsertQueryData(
              "getSecrets",
              undefined,
              normalizeSecrets(data.secrets)
            )
          );
        } catch (err) {
          // `onError` side-effect
        }
      }
    })
  })
});

export const initialStateApiEndpoints = initialStateApi.endpoints;

export const { useGetInitialStateQuery } = initialStateApi;
