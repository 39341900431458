import React, { createContext, useContext } from "react";
import type { NodeViewProps } from "@tiptap/core";
import type { Attrs, Node } from "@tiptap/pm/model";

const NodeViewContext = createContext<NodeViewProps | undefined>(undefined);

interface GenericNodeViewProps<T> extends Omit<NodeViewProps, "node"> {
  node: Omit<Node, "attrs"> & {
    attrs: T;
  };
}
export function NodeViewContextProvider<T extends Attrs>({
  children,
  nodeViewProps
}: Readonly<{
  children: React.ReactNode;
  nodeViewProps: GenericNodeViewProps<T>;
}>) {
  return (
    <NodeViewContext.Provider value={{ ...nodeViewProps }}>
      {children}
    </NodeViewContext.Provider>
  );
}

export const useNodeViewContext = <T extends Attrs>() => {
  const context = useContext(
    NodeViewContext as React.Context<GenericNodeViewProps<T> | undefined>
  );
  if (context === undefined) {
    throw new Error(
      "useNodeViewContext must be used within a NodeViewContextProvider"
    );
  }
  return context;
};

export default NodeViewContext;
