import { useEffect } from "react";
import { useBlockContext } from "../../BlockContext";
import useUpdateField from "../../useUpdateField";
import setBlockMessage from "../../setBlockMessage";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { type Edge } from "reactflow";
import useGetCurrentBlock from "../../store/hooks/useGetCurrentBlock";
import getDependencyArray from "../functions/getDependencyArray";

const useSetBlockMessage = () => {
  const { blockData: data, id } = useBlockContext();
  const { state } = useToolbuilderContext();
  const { edges } = state.currentState;
  const currentBlock = useGetCurrentBlock();
  const updateField = useUpdateField(id);
  /* tslint:disable-next-line */
  const dependencyArray = getDependencyArray(
    data,
    edges as Edge[],
    currentBlock?.id
  );

  useEffect(() => {
    // we only want to update the block message if the current block is the block that is being updated
    if (currentBlock?.id !== id) {
      return;
    }
    const newBlockMessage = setBlockMessage({
      data,
      edges: edges as Edge[],
      id
    });
    if (newBlockMessage !== data.blockMessage) {
      updateField(newBlockMessage, "blockMessage");
    }
  }, dependencyArray);
};

export default useSetBlockMessage;
