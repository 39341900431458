import { useNavigate } from "react-router-dom";

const useNavigateReplace = () => {
  const navigate = useNavigate();
  const navigateReplace = (route: string) => {
    navigate(route, { replace: true, state: { replaced: true } }); // we need to store state so that we can replace in redux
  };
  return navigateReplace;
};

export default useNavigateReplace;
