import { useEffect, useRef } from "react";
import useGetCurrentChatAgentId from "../../hooks/useGetCurrentAgentId";

const useAgentRef = () => {
  const currentAgentId = useGetCurrentChatAgentId();
  const currentAgentIdRef = useRef<string | null>(currentAgentId);
  useEffect(() => {
    currentAgentIdRef.current = currentAgentId;
  }, [currentAgentId]);
  return currentAgentIdRef;
};

export default useAgentRef;
