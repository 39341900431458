import React from "react";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import { Divider, Typography } from "@mui/material";
import useGetCurrentUser from "../../../../user/hooks/useGetCurrentUser";
import ImageCircle from "../../../../../globalTheme/icons/logos/ImageCircle";
import ProfileInformationForm from "./ProfileInformationForm";
import ChangePasswordOrEmail from "./ChangePasswordOrEmail";

const ProfilePageContent = () => {
  const user = useGetCurrentUser();
  const picture = user?.picture || "";
  return (
    <>
      <FullWidthCard className="p-32px">
        <Typography variant="h7" className="m-b-16px">
          Profile photo
        </Typography>
        <ImageCircle imageSrc={picture} width={150} />
        <Divider className="m-v-24px" />
        <ProfileInformationForm className="m-t-8px" />
      </FullWidthCard>

      <ChangePasswordOrEmail />
    </>
  );
};

export default ProfilePageContent;
