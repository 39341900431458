import { useFormContext } from "react-hook-form";
import useAgentResetDefaultNeedsReset from "./useAgentResetDefaultNeedsReset";
import type { TAgentDataToSave } from "@toolflow/shared";

const useAgentReset = () => {
  const { reset } = useFormContext<TAgentDataToSave>();

  return useAgentResetDefaultNeedsReset(reset);
};

export default useAgentReset;
