import React from "react";
import Box from "@mui/material/Box";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number | string;
  value: number | string;
  className?: string;
  boxClassName?: string;
  style?: React.CSSProperties;
}

function TabPanel(props: TabPanelProps) {
  const {
    children,
    value,
    index,
    className = "",
    boxClassName = "",
    style,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      style={style}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={className}
      {...other}
    >
      {value === index && <Box className={boxClassName}>{children}</Box>}
    </div>
  );
}

export default TabPanel;
