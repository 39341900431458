import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetWorkspaceState } from "../state/workspaceSlice";

/* We don't want to store the workspace state when the worstation gets unmounted
Previously workspaceId and other attributes would stay in the slice. This clears them
so we get a clean state when we navigate back to the workspace */
export const useResetWorspaceOnUnmount = () => {
  const dispatch = useDispatch();

  const componentWillUnmount = useRef(false);

  useEffect(() => {
    return () => {
      if (componentWillUnmount.current) {
        dispatch(resetWorkspaceState());
      }
    };
  }, []);

  // This is componentWillUnmount
  useEffect(() => {
    return () => {
      componentWillUnmount.current = true;
    };
  }, []);
};
