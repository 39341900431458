import { useEffect, useState } from "react";
import { useToolContext } from "../../../../../../../../tools/contexts/ToolContext";
import useGetAssetId from "../../../../../../assets/useGetAssetId";

const useAssetGroup = ({ assets }: { assets: string[] }) => {
  const selectedAssetId = useGetAssetId();
  const { tool } = useToolContext();

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    if (selectedAssetId in assets) {
      setIsOpen(true);
    }
  }, [selectedAssetId, assets]);

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  return { isOpen, onClick, toolId: tool._id };
};

export default useAssetGroup;
