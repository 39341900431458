import React, { useState } from "react";
import { toolflowTextDisabledColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import { UpdateAssetNameFormContainer } from "../../../../UpdateAssetNameForm";
import RenameDialog from "../../../../../../home/layout/RenameDialog";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { RenameIcon } from "../../../../../../../../globalTheme/icons/icons";
import SettingsMenuItem from "../../../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { useSettingsDropdownContext } from "../../../../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import useGetCurrentAsset from "../../../../../assets/useGetCurrentAsset";

const useRenameAssetMenuItem = () => {
  const [open, setOpen] = useState(false);
  const { handleClose } = useSettingsDropdownContext();

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    setOpen(false);
    handleClose(e);
  };

  const input = useGetCurrentAsset();

  return { open, handleOpen, close, input };
};

const RenameAssetMenuItem = () => {
  const { open, handleOpen, close, input } = useRenameAssetMenuItem();

  if (!input) return null;

  return (
    <>
      <SettingsMenuItem
        text="Rename asset"
        icon={
          <RemixWrapperIconWithTheme
            size={16}
            Icon={RenameIcon}
            providedColor={toolflowTextDisabledColor}
          />
        }
        action={handleOpen}
      />
      <RenameDialog open={open} closeDialog={close} title="Rename asset">
        <UpdateAssetNameFormContainer assetId={input.id} closeDialog={close} />
      </RenameDialog>
    </>
  );
};

export default RenameAssetMenuItem;
