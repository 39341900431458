import React from "react";
import { useSettingsDropdownContext } from "../useSettingsDropownContext";
import { IMenuButtonProps } from "./settingsTypes";
import { IconButton } from "@mui/material";
import { DarkUtilityTooltip } from "../../../toolTips/DarkUtilityTooltip";

const WrappedMenuButton = ({
  children,
  MenuButtonProps
}: {
  MenuButtonProps?: IMenuButtonProps;
  children: React.ReactNode;
}) => {
  const { handleClick, disableToolTip } = useSettingsDropdownContext();
  const tooltipLabel = disableToolTip
    ? ""
    : MenuButtonProps?.tooltipLabel || "More";
  return (
    <DarkUtilityTooltip title={tooltipLabel}>
      <IconButton onClick={handleClick}>{children}</IconButton>
    </DarkUtilityTooltip>
  );
};

export default WrappedMenuButton;
