import React from "react";
import DeleteCurrentAssetButton from "../../deleteAssetButtons/DeleteCurrentAssetButton";
import SheetSettingsDropdown from "./SheetSettingsDropdown";

const SheetAssetIcons = () => {
  return (
    <div className="flex align-i-center">
      <DeleteCurrentAssetButton />
      <SheetSettingsDropdown />
    </div>
  );
};

export default SheetAssetIcons;
