import getCSSVariables from "./getCSSVariable";

export function getCSSColor(
  variableName: string,
  defaultValue: string
): string {
  const cssVariable = getCSSVariables(variableName);

  if (cssVariable) {
    return cssVariable;
  } else {
    console.error(`Using default for ${variableName}: ${defaultValue}`);
    return defaultValue;
  }
}
