import type { CustomEdge, EdgeUpdateFunction } from "@toolflow/shared";
import type { EdgeMouseHandler } from "reactflow";

export const useEdgeMouseHandlers = ({
  setEdges
}: {
  setEdges: (
    newState: CustomEdge[] | EdgeUpdateFunction,
    dontUpdateAvailableFields?: boolean
  ) => void;
}) => {
  const handleEdgeMouseEnter: EdgeMouseHandler = (event, newEdge) => {
    setEdges((prevEdges) =>
      prevEdges.map((edge) =>
        edge.id === newEdge.id
          ? { ...edge, data: { ...edge.data, isHovered: true } }
          : edge
      )
    );
  };
  const handleEdgeMouseLeave: EdgeMouseHandler = (event, newEdge) => {
    setEdges((prevEdges) =>
      prevEdges.map((edge) =>
        edge.id === newEdge.id
          ? { ...edge, data: { ...edge.data, isHovered: false } }
          : edge
      )
    );
  };
  return { handleEdgeMouseEnter, handleEdgeMouseLeave };
};
