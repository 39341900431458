import { useSelector } from "react-redux";
import { selectStepId } from "../../workflowBuilderSlice";
import { useFormContext } from "react-hook-form";
import type { EmbeddedStep, TWorkflowBuilderForm } from "@toolflow/shared";
import useGetWorkflowStepFieldById from "./useGetWorkflowStepFieldById";

function useGetSelectedStep<T extends EmbeddedStep>(): T | undefined {
  const selectedStep = useSelector(selectStepId);
  const fieldLabel = useGetWorkflowStepFieldById(selectedStep);
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return (watch(fieldLabel) as T) || undefined;
}

export default useGetSelectedStep;
