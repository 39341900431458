export enum VisibilityTypes {
  PUBLIC = "public",
  PRIVATE = "private",
  RESTRICTED = "restricted"
}

export const VISIBILITY_ARRAY: readonly VisibilityTypes[] = [
  VisibilityTypes.PRIVATE,
  VisibilityTypes.PUBLIC,
  VisibilityTypes.RESTRICTED
] as const;
