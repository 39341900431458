import useUpdateField from "../../useUpdateField";

const useHandleUpdatePrompt = (id: string) => {
  const updateField = useUpdateField(id);
  const handleUpdatePrompt = (str: string) => {
    updateField(str, "prompt");
  };
  return handleUpdatePrompt;
};

export default useHandleUpdatePrompt;
