import { Stack } from "@mui/material";
import { DeleteIcon } from "../../../../../../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import { useToolContext } from "../../../../../../../../tools/contexts/ToolContext";
import ToolContextComponentContainer from "../../../../../../../../tools/contexts/ToolContextContainer";
import NavigateToToolBuilderButton from "../../../../../NavigateToToolBuilderButton";
import styles from "./toolListComponent.module.css";
import RemixButton from "../../../../../../../../../utilities/components/icons/RemixButton";

const cx = parsedClassNames.bind(styles);

interface InputListInnerProps {
  toolIds: string[];
  disableHelperText?: boolean;
  placeholder?: string;
  handleChange: (toolIds: string[]) => void;
}

const ToolListItem = ({
  deleteToolId,
  idx
}: {
  deleteToolId: (idx: number) => void;
  idx: number;
}) => {
  const { tool } = useToolContext();
  return (
    <>
      {tool.name}
      <Stack direction="row" spacing={1}>
        <NavigateToToolBuilderButton toolId={tool._id} />
        <RemixButton
          text="Delete"
          icon={DeleteIcon}
          onClick={() => {
            deleteToolId(idx);
          }}
          className={"tag-delete-button border-radius-6px"}
        />
      </Stack>
    </>
  );
};

export default function ToolListComponent({
  toolIds,
  handleChange
}: InputListInnerProps) {
  const deleteToolId = (idx: number) => {
    const filteredTags = toolIds.filter((_, i) => i !== idx);
    handleChange(filteredTags);
  };
  if (!toolIds.length) return null;

  return (
    <div className={cx("tags-list-container", "m-t-16px")}>
      {toolIds.map((toolId, idx) => {
        return (
          <div key={toolId} className={cx("tags-list-item")}>
            <ToolContextComponentContainer toolId={toolId}>
              <ToolListItem deleteToolId={deleteToolId} idx={idx} />
            </ToolContextComponentContainer>
          </div>
        );
      })}
    </div>
  );
}
