import { createTheme } from "@mui/material";
import appTheme from "./appTheme";
import palette from "./palette";
import toolflowTypography from "./toolflowTypography";

const utilityTheme = createTheme(appTheme, {
  typography: toolflowTypography,
  palette,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "white"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-of-type td": {
            borderBottom: 0
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h7: "div"
        }
      }
    }
  }
});

export default utilityTheme;
