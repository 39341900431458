import { Chip } from "@mui/material";
import React from "react";
import RemixWrapperIconWithTheme from "../icons/RemixWrapperIconWithTheme";
import {
  CloseIcon,
  AssetIcon,
  SnippetIcon,
  InputIcon,
  OutputIcon
} from "../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";
import { ClickableTypes } from "@toolflow/shared";

const iconDict = {
  [ClickableTypes.SNIPPET]: SnippetIcon,
  [ClickableTypes.INPUT]: AssetIcon,
  [ClickableTypes.TOOLBUILDER_INPUT]: InputIcon,
  [ClickableTypes.NODE_OUTPUT]: OutputIcon
};

function SnippetChip({
  text,
  onDelete,
  type,
  className,
  onClick,
  tabIndex
}: {
  text: string;
  onDelete?: () => void;
  type: ClickableTypes;
  className?: string;
  onClick?: () => void;
  tabIndex?: number;
}) {
  return (
    <Chip
      icon={
        <RemixWrapperIconWithTheme
          size={14}
          Icon={iconDict[type]}
          providedColor={toolflowTextDisabledColor}
        />
      }
      label={text}
      contentEditable={false}
      onDelete={onDelete}
      className={className}
      onClick={onClick}
      variant="purple"
      deleteIcon={
        <RemixWrapperIconWithTheme
          size={14}
          Icon={CloseIcon}
          providedColor={toolflowTextDisabledColor}
        />
      }
      size="small"
      tabIndex={tabIndex}
    />
  );
}

export default SnippetChip;
