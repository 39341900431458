import { useEffect, useState } from "react";

function parseHotkey(hotkey: string): Set<string> {
  return new Set(
    hotkey
      .split("+")
      .map((key) => (/^[a-zA-Z]$/.test(key) ? key.toLowerCase() : key))
  );
}

interface HotkeyAction {
  action: (e: React.MouseEvent) => void;
  hotkey?: string;
}

const useHandleHotkeys = (
  hotkeyActions: HotkeyAction[],
  disabled?: boolean
) => {
  const [pressedKeys, setPressedKeys] = useState<Set<string>>(
    new Set<string>()
  );

  // Create a map from hotkey set to corresponding actions
  const hotkeyMap = new Map<Set<string>, (e: React.MouseEvent) => void>();
  hotkeyActions.forEach(({ action, hotkey }) => {
    const keySet = hotkey ? parseHotkey(hotkey) : new Set<string>();
    hotkeyMap.set(keySet, action);
  });

  function isHotkeyPressed(hotkeySet: Set<string>): boolean {
    if (hotkeySet.size === 0) return false;
    return Array.from(hotkeySet).every((key) =>
      key === "Backspace" || key === "Del"
        ? pressedKeys.has("Backspace") || pressedKeys.has("Del")
        : pressedKeys.has(key)
    );
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      pressedKeys.add(e.key);
      setPressedKeys(new Set(pressedKeys));

      hotkeyMap.forEach((action, hotkeySet) => {
        if (isHotkeyPressed(hotkeySet) && !disabled) {
          // Create a synthetic event
          const event = new KeyboardEvent(e.type, e);
          action(event as unknown as React.MouseEvent);
          e.preventDefault();
        }
      });
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      pressedKeys.delete(e.key);
      setPressedKeys(new Set(pressedKeys));
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [pressedKeys, disabled]); // You might need to ensure this dependency array is correctly handled to avoid excessive re-renders or stale closures

  return null;
};

export default useHandleHotkeys;
