import React, { FC } from "react";
import BaseSettingsDropdown from "../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import { Table, TableBody, TableContainer } from "@mui/material";
import EntityTableRow from "./EntityTableRow";
import { createToolsData } from "../utlils/tableUtils";
import type { TMarketplaceEntity } from "@toolflow/shared";

interface IToolsTableProps {
  homePageEntities: TMarketplaceEntity[];
}
const ToolsTable: FC<IToolsTableProps> = (props) => {
  const { homePageEntities } = props;
  if (!homePageEntities?.length) {
    return null;
  }
  return (
    <BaseSettingsDropdown>
      <div className="p-h-16px">
        <TableContainer>
          <Table sx={{ minWidth: 900 }}>
            <TableBody>
              {homePageEntities.map((entity) => (
                <EntityTableRow
                  key={entity.id}
                  tableRow={createToolsData({ entity })}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </BaseSettingsDropdown>
  );
};

export default ToolsTable;
