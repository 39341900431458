import {
  BlockNode,
  type DeepgramBlockDataV1,
  type DeepgramBlockPropsV1
} from "@toolflow/shared";
import TestDeepgramFields from "../../TestDeepgramFields";
import { FILE_UPLOAD } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { v4 as uuidv4 } from "uuid";
import TestToolRun from "../../../../common/TestToolRun";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestBlockOutput from "../../../components/TestBlockOutput";
import useTest from "../../../hooks/useTest";
import useFields from "../../../hooks/useFields";

const TestDeepgramV1 = ({
  data,
  id
}: Omit<DeepgramBlockPropsV1, "selected">) => {
  const { userInput, customFields, setUserInput, fields } = useFields([]);

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<DeepgramBlockDataV1>({
      data,
      id,
      customFields: [
        {
          name: data.settings.file,
          id: uuidv4(),
          type: FILE_UPLOAD
        },
        ...customFields
      ],
      blockType: BlockNode.DeepgramBlockNode,
      fields,
      userInput
    });
  return (
    <DrawerBottom
      mainContent={
        <>
          <TestDeepgramFields
            fields={fields}
            uI={userInput}
            setUI={setUserInput}
            file={data.settings.file}
          />
          <TestBlockOutput output={output} label={label} />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
};

export default TestDeepgramV1;
