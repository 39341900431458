import React, { FC } from "react";
import { Typography } from "@mui/material";

interface IEntityNotFoundBaseProps {
  className?: string;
  header: string;
  subHeader?: string;
  icon?: React.ReactNode;
  actionButton?: React.ReactNode;
}
const EntityNotFoundBase: FC<IEntityNotFoundBaseProps> = (props) => {
  const { className, header, subHeader, icon, actionButton } = props;
  return (
    <div className={className}>
      <div className="m-b-16px">{icon}</div>
      <Typography variant="h6" className="off-black m-b-8px">
        {header}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        fontWeight={400}
        className="m-b-16px"
      >
        {subHeader}
      </Typography>
      <div className="m-16px">{actionButton}</div>
    </div>
  );
};

export default EntityNotFoundBase;
