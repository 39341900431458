import React from "react";
import { TextAssetSizeSwitch } from "./TextAssetSizeSwitch";
import AssetDialogInner from "../../assetDialog/AssetDialogInner";
import AssetDialog from "../../assetDialog/AssetDialog";

// We need to have the OpenAssetDialog be separate from the Text
// tab so we don't have infinite dialogs when expanding TextTabs
function TextAsset() {
  return (
    <>
      <TextAssetSizeSwitch />
      <AssetDialog innerSlot={<AssetDialogInner />} />
    </>
  );
}

export default TextAsset;
