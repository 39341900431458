import { type FileReference } from "@toolflow/shared";
import { isNumber } from "lodash";

export enum FileType {
  Audio = "audio",
  Text = "text"
}

function isFile(file: $TSAllowedAny): file is File {
  return !!(
    file.type &&
    file.name &&
    isNumber(file.size) &&
    !!file.arrayBuffer
  );
}

export function getFileType(file: File | FileReference) {
  if (isFile(file)) {
    if (file.type.includes("audio")) {
      return FileType.Audio;
    } else {
      return FileType.Text;
    }
  } else {
    if (["mp3", "wav", "m4a", "aac"].includes(file.fileExtension)) {
      return FileType.Audio;
    } else {
      return FileType.Text;
    }
  }
}
