import React from "react";
import ChatHeader from "./chatHeader/ChatHeader";
import ChatThread from "./chatbox/ChatThread";
import useChatSocket from "./chatbox/hooks/useChatSocket";
import LeftPaperWrapper from "../LeftPaperWrapper";
import WorkspaceAgentContextContainer from "../../../../agents/contexts/WorkspaceAgentContextContainer";

function ChatBoxPage() {
  useChatSocket();
  return (
    <WorkspaceAgentContextContainer>
      <LeftPaperWrapper
        header={<ChatHeader />}
        mainContent={<ChatThread />}
        className="bg-color-right-side-content"
      />
    </WorkspaceAgentContextContainer>
  );
}

export default ChatBoxPage;
