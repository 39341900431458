import type { TWorkflowBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { VISIBILITY_FIELD_LABEL } from "../WorkflowBuilderConstants";

function useGetWorkflowVisibility() {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return watch(VISIBILITY_FIELD_LABEL);
}

export default useGetWorkflowVisibility;
