import React from "react";
import usePersonalInformation from "../profile/hooks/usePersonalInformation";
import CompanyInformationForm from "../profile/CompanyInformationForm";
import { Box, Grid, Stack, Typography } from "@mui/material";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import LoadingButton from "@mui/lab/LoadingButton";

const TeamSettingsForm = () => {
  const {
    updateFields,
    setVal,
    setSelectVal,
    handleSave,
    isFormSubmissionDisabled,
    isLoading,
    resetForm
  } = usePersonalInformation();
  return (
    <FullWidthCard className="p-32px m-t-24px">
      <Typography variant="h7" className="m-b-8px">
        Team
      </Typography>
      <Grid
        container
        xs={12}
        spacing={2}
        justifyContent="center"
        display="flex"
        width="100%"
        className={"className"}
      >
        <CompanyInformationForm
          companyName={updateFields.company?.name}
          employeCount={updateFields.company?.employee_count}
          changeField={setVal}
          changeSelectField={setSelectVal}
        />
      </Grid>
      <Box>
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          sx={{ mr: 2, mt: 2 }}
          spacing={2}
        >
          <LoadingButton
            variant="outlined"
            size="large"
            loading={isLoading}
            disabled={isFormSubmissionDisabled}
            onClick={resetForm}
          >
            Reset
          </LoadingButton>
          <LoadingButton
            variant="contained"
            size="large"
            loading={isLoading}
            disabled={isFormSubmissionDisabled}
            onClick={handleSave}
          >
            Save Details
          </LoadingButton>
        </Stack>
      </Box>
    </FullWidthCard>
  );
};

export default TeamSettingsForm;
