import { Box, useMediaQuery, type Theme } from "@mui/material";
import type { SheetAsset } from "@toolflow/shared";
import React, { useMemo } from "react";
import { useGetToolQuery } from "../../../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useAssetContext } from "../../../../../../../../utilities/contexts/AssetContext";
import { useWorkspaceAssetsFieldArrayContext } from "../../../../../contexts/WorkspaceAssetsContext";
import { classNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import AssetTableOfContents from "../../../assetTableOfContents/AssetTableOfContents";
import SheetAssetHeader from "./SheetAssetHeader";
import SheetContainer from "./SheetContainer";
import useUpdateFormWithSheetData from "./utils/hooks/useUpdateFormWithSheetData";
import useSheetData from "./utils/hooksWithoutContext/useSheetData";
import useGetWorkspaceAssetIndexById from "../../../../../assets/useGetWorkspaceAssetIndexById";
import { getNewAssetForSheet } from "../../../../../assets/functions/getNewAssetForSheet";

const SheetWorkspaceContainer = () => {
  const { asset } = useAssetContext<SheetAsset>();
  const { data } = useGetToolQuery(asset.value.selectedToolId, {
    skip: !asset.value.selectedToolId
  });

  const tool = useMemo(() => {
    if (asset.value.selectedToolId) {
      return data?.tool || null;
    } else {
      return null;
    }
  }, [asset.value.selectedToolId, data?.tool]);

  const { update } = useWorkspaceAssetsFieldArrayContext();
  const getInputIndex = useGetWorkspaceAssetIndexById();
  const setTool = (t: string | null) => {
    const newAsset = getNewAssetForSheet(asset, t);
    update(getInputIndex(asset.id), newAsset);
  };

  const { sheetData, setSheetData, sheetDataRef } = useSheetData(
    tool,
    asset.value.sheetData
  );
  useUpdateFormWithSheetData({ sheetData });
  const isMdDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box className="flex w-100-percent">
      <Box
        className={classNames("parent-hover w-100-percent", {
          "p-r-70px": isMdDown,
          "p-r-16px": !isMdDown
        })}
        sx={{ maxWidth: "680px" }}
      >
        <SheetAssetHeader />
        <SheetContainer
          selectedTool={tool}
          setSelectedTool={setTool}
          sheetData={sheetData}
          setSheetData={setSheetData}
          sheetDataRef={sheetDataRef}
        />
      </Box>
      <Box>
        <AssetTableOfContents />
      </Box>
    </Box>
  );
};

export default SheetWorkspaceContainer;
