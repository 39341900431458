import { Avatar, styled } from "@mui/material";
import {
  lightButtonBorder,
  toolflowTextSecondaryColor
} from "../../../../globalTheme/muiUtils/appTheme";
export const StyledBlockTagAvatar = styled(Avatar)({
  width: 20,
  height: 20,
  borderRadius: 8,
  border: `1px solid ${lightButtonBorder} !important`,
  color: toolflowTextSecondaryColor,
  padding: 2,
  fontSize: 12,
  background: "#fff",
  ".MuiAvatar-img": {
    width: 16,
    height: 16
  }
});
