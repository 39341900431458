import React from "react";
import Greetings from "../../../../../utilities/components/Greetings";
import JoinSlackCard from "../../joinSlack/JoinSlackCard";
import RecentData from "../../RecentData";
import ToolsBlockData from "./ToolsBlockData";

const HomePageContent = () => {
  return (
    <>
      <div className="p-h-16px">
        <Greetings />
        <JoinSlackCard />
      </div>
      <div className="p-h-16px m-t-16px">
        <RecentData />
      </div>
      <div className="p-h-16px m-t-16px">
        <ToolsBlockData />
      </div>
    </>
  );
};

export default HomePageContent;
