import React from "react";
import { useDeleteSnippetMutation } from "../../../../../ToolflowAPI/rtkRoutes/snippetsApi";
import RemixButton from "../../../../../utilities/components/icons/RemixButton";
import { DeleteIcon } from "../../../../../globalTheme/icons/icons";

function DeleteSnippetButton({ id }: { id: string }) {
  const [deleteSnippet, { isLoading }] = useDeleteSnippetMutation();

  const handleDeleteSnippet = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    deleteSnippet(id);
  };
  return (
    <RemixButton
      text="Delete"
      icon={DeleteIcon}
      onClick={handleDeleteSnippet}
      disabled={isLoading}
    />
  );
}

export default DeleteSnippetButton;
