import { useNavigate } from "react-router-dom";
import { WORKSPACE } from "../constants/constants";
import { useAuthStore } from "../../features/auth/hooks/useAuthStore";
import { useCopyWorkspaceMutation } from "../../ToolflowAPI/rtkRoutes/workspaceApi";
import { useCopyToolMutation } from "../../ToolflowAPI/rtkRoutes/toolsApi";
import { EXTERNAL_WORKSPACE_BASE_ROUTE_PATH } from "../../features/navigation/helpers/routePaths";
import { useLoginBackToPage } from "../../features/auth/hooks/useLoginBackToPage";
import useNavigateToToolBuilder from "../../features/tools/hooks/useNavigateToToolBuilder";
import { EntityType } from "@toolflow/shared";

type TCopyTypes = typeof EntityType.TOOL | typeof WORKSPACE;

// Custom Hook
export const useDuplicateObject = () => {
  const { loginBackToPage } = useLoginBackToPage();
  const { isAuthenticated } = useAuthStore();
  const navigateToToolBuilder = useNavigateToToolBuilder();
  const navigate = useNavigate();
  const [copyTool, { isLoading }] = useCopyToolMutation();
  const [copyWorkspace, { isLoading: workspaceLoading }] =
    useCopyWorkspaceMutation();
  const loading = isLoading || workspaceLoading;

  const executeCopyWithIdAndType = async (
    copyId: string,
    copyType: TCopyTypes
  ) => {
    if (copyId) {
      if (copyType === EntityType.TOOL) {
        await copyTool(copyId)
          .unwrap()
          .then((resp) => {
            navigateToToolBuilder(resp.toolId)();
          });
      } else if (copyType === WORKSPACE) {
        await copyWorkspace(copyId)
          .unwrap()
          .then((resp) => {
            navigate(
              `${EXTERNAL_WORKSPACE_BASE_ROUTE_PATH}${resp.workspaceId}`
            );
          });
      }
    }
  };

  // gets called in App
  // may want to check security of this at some point
  const duplicateObject = (id: string, type: TCopyTypes) => {
    if (!isAuthenticated) {
      localStorage.setItem("justAuthenticated", "true");
      localStorage.setItem(
        "callbackFunction",
        JSON.stringify({ function: "handleCopy", id, type })
      );
      loginBackToPage();
    } else {
      executeCopyWithIdAndType(id, type);
    }
  };

  return { duplicateObject, loading };
};
