import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect } from "react";
import SnippetChip from "../../../chips/SnippetChip";
import { useToolbuilderContext } from "../../../../../features/pages/builder/toolBuilder/context/ToolBuilderContext";
import { ClickableTypes, ChipNodeHtmlTags } from "@toolflow/shared";

const ToolbuilderInputViewerComponent = (props: NodeViewProps) => {
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  const { toolOutputOptions } = currentState;

  const toolbuilderInput = toolOutputOptions.inputs.find(
    (option) => option.value === props.node.attrs.name
  );

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode
    queueMicrotask(() => {
      try {
        const toolbuilderInputExists = !!toolbuilderInput;
        if (toolbuilderInputExists) {
          props.updateAttributes({
            textValue: "",
            name: toolbuilderInput?.value || ""
          });
        } else {
          props.deleteNode(); // when an toolbuilderInput gets deleted (like if the tool was deleted), we want to delete the toolbuilderInputs
        }
      } catch {}
    });
  }, [toolbuilderInput?.value]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.ToolbuilderInputComponent} dontTriggerBubble`}
    >
      {toolbuilderInput && (
        <SnippetChip
          text={toolbuilderInput.value}
          onDelete={props.deleteNode}
          type={ClickableTypes.TOOLBUILDER_INPUT}
          tabIndex={-1}
        />
      )}
    </NodeViewWrapper>
  );
};

export default ToolbuilderInputViewerComponent;
