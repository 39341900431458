import React from "react";
import { useDuplicateObject } from "../../../utilities/hooks/useDuplicateObject";
import { ForkIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import { EntityType } from "@toolflow/shared";

function DuplicateToolButton({
  id,
  className = "",
  size = 16,
  enableForking = false
}: {
  id: string;
  className?: string;
  size?: number;
  enableForking?: boolean;
}) {
  const { duplicateObject } = useDuplicateObject();

  const duplicate = () => {
    duplicateObject(id, EntityType.TOOL);
  };

  if (!enableForking) return null;

  return (
    <RemixButton
      icon={ForkIcon}
      onClick={duplicate}
      className={className}
      size={size}
      text="Use as template"
    />
  );
}

export default DuplicateToolButton;
