import React from "react";
import {
  List,
  Card,
  CardHeader,
  CardContent,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@mui/material";
import type { CustomToolOutputField } from "@toolflow/shared";
import { outputIconDict } from "../../../utilities/constants/iconDict";

function ToolOutputsCard({
  toolOutputs,
  className = "",
  variant = "elevation"
}: {
  toolOutputs: CustomToolOutputField[];
  className?: string;
  variant?: "elevation" | "outlined";
}) {
  return (
    <Card className={className} variant={variant}>
      <CardHeader title="Outputs" />
      <CardContent>
        <List disablePadding dense>
          {toolOutputs.map((output) => (
            <ListItem key={output.id}>
              <ListItemIcon>{outputIconDict[output.type]}</ListItemIcon>
              <ListItemText primary={output.name} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

export default ToolOutputsCard;
