import React, { FC } from "react";
import RightSideContainer from "../../containers/RightSideContainer";
import UniversalAutocompleteContainer from "../../autocomplete/universalAutocomplete/UniversalAutocompleteContainer";
import ProfileDropdownMenu from "../../../../features/pages/profile/dropdown/ProfileDropdownMenu";
import PageHeaderTypography from "./PageHeaderTypography";
import UniversalAutocompletePositionWrapper from "../../autocomplete/universalAutocomplete/UniversalAutocompletePositionWrapper";

interface IToolflowHeaderProps {
  title: string;
  children: React.ReactNode;
  action?: React.ReactNode;
}
const ToolflowHeader: FC<IToolflowHeaderProps> = ({
  title,
  children,
  action
}) => {
  return (
    <RightSideContainer
      topHeaderSection={<PageHeaderTypography title={title} />}
      headerMidSection={
        <UniversalAutocompletePositionWrapper>
          <UniversalAutocompleteContainer />
        </UniversalAutocompletePositionWrapper>
      }
      actionSection={action ? action : <ProfileDropdownMenu />}
    >
      {children}
    </RightSideContainer>
  );
};

export default ToolflowHeader;
