import { createEntityAdapter } from "@reduxjs/toolkit";
import type { StreamlinedWorkspace, Workspace } from "@toolflow/shared";

export const workspaceAdapter = createEntityAdapter({
  selectId: (workspace: Workspace) => workspace._id
});

export const workspaceInitialState = workspaceAdapter.getInitialState();

export const streamlinedWorkspaceAdapter = createEntityAdapter({
  selectId: (workspace: StreamlinedWorkspace) => workspace._id
});

export const streamlinedWorkspaceInitialState =
  streamlinedWorkspaceAdapter.getInitialState();
