import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import useSaveAndClose from "./hooks/useSaveAndClose";

function SaveWorkflowButtonFromDialog() {
  const { saveAndClose, loading } = useSaveAndClose();
  return (
    <LoadingButton variant="contained" onClick={saveAndClose} loading={loading}>
      Save
    </LoadingButton>
  );
}

export default SaveWorkflowButtonFromDialog;
