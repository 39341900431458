import { useParams } from "react-router-dom";
import { selectWorkflowVersionsById } from "../../../../../../ToolflowAPI/rtkRoutes/selectors/workflowSelectors";
import { useSelector } from "react-redux";
import { selectWorkflowVersionId } from "../../workflowBuilderSlice";

const useGetCurrentWorkflowVersion = () => {
  const { workflowId = "" } = useParams();
  const workflowVersionId = useSelector(selectWorkflowVersionId);
  const workflowVersion = useSelector(
    selectWorkflowVersionsById(workflowId, workflowVersionId ?? "")
  );
  return workflowVersion;
};

export default useGetCurrentWorkflowVersion;
