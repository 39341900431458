import { useFormContext } from "react-hook-form";
import { AGENT_TAG_FIELD_LABEL } from "../../constants/fields";
import type { TAgentBuilderForm } from "@toolflow/shared";

const useGetAgentCategories = () => {
  const { watch } = useFormContext<TAgentBuilderForm>();
  return watch(AGENT_TAG_FIELD_LABEL)?.categories || [];
};

export default useGetAgentCategories;
