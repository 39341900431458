import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useGetToolInputFieldColumn from "./useGetToolInputFieldColumn";
import { useSheetContext } from "../../SheetContext";
import useGetToolOutputFieldColumn from "./useGetToolOutputFieldColumn";
import SheetHeader from "../sheetHeader";

const useColumns = () => {
  const { selectedTool } = useSheetContext();

  const getToolInputFieldColumn = useGetToolInputFieldColumn();
  const getToolOutputFieldColumn = useGetToolOutputFieldColumn();

  const columns = useMemo(() => {
    return [
      {
        grow: 1,
        Header: <SheetHeader />,
        enableSorting: false,
        enableColumnFilter: false,
        header: selectedTool?.name || "Tool",
        accessorKey: selectedTool?._id || uuidv4(),
        columns: [
          ...(selectedTool?.main.toolInputFields || []).map(
            getToolInputFieldColumn
          ),
          ...(selectedTool?.main.toolOutputFields || []).map(
            getToolOutputFieldColumn
          )
        ]
      }
    ];
  }, [selectedTool?._id]);

  const [columnOrder, setColumnOrder] = useState(
    [
      "mrt-row-select",
      "mrt-row-actions",
      ...columns[0].columns.map((c) => c.accessorKey)
    ] //array of column ids (Initializing is optional as of v2.10.0)
  );
  const [columnMap, setColumnMap] = useState(
    new Map(columns[0].columns.map((c) => [c.meta.value.name, c]))
  );

  // because of nested columns, we need to manage the columns ourselves
  // update it when a new tool is selected
  useEffect(() => {
    setColumnOrder([
      "mrt-row-select",
      "mrt-row-actions",
      ...columns[0].columns.map((c) => c.accessorKey)
    ]);
    const newColumnMap = new Map(
      columns[0].columns.map((c) => [c.meta.value.name, c])
    );
    setColumnMap(newColumnMap);
  }, [columns]);

  return { columns, columnMap, columnOrder, setColumnOrder };
};

export default useColumns;
