import RemixWrapperIconWithTheme from "./RemixWrapperIconWithTheme";
import styles from "./circleButton.module.css";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

const CircleIcon = ({
  size,
  Icon
}: {
  size: number;
  Icon: React.ElementType;
}) => {
  return (
    <div
      className={cx(
        "flex align-i-center justify-center",
        styles["folder-upload-icon"]
      )}
    >
      <RemixWrapperIconWithTheme Icon={Icon} size={size} />
    </div>
  );
};

export default CircleIcon;
