import { useNavigate } from "react-router-dom";
import { HOME_ROUTE_PATH_BASE } from "../../../navigation/helpers/routePaths";

const useNavigateHome = () => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate(HOME_ROUTE_PATH_BASE);
  };
  return navigateHome;
};

export default useNavigateHome;
