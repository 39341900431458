import React from "react";
import ToggleWorkspaceName from "../ToggleWorkspaceName";
import WorkspaceSettingsDropdown from "./WorkspaceSettingsDropdown";
import { useHasSelectedEntity } from "../../hooks/useHasSelectedEntity";
import useWorkspaceId from "../../hooks/useWorkspaceId";
import { GetStartedBackButton } from "../getStarted/GetStartedBackButton";

const useWorkspaceTitle = () => {
  const hasSelectedEntity = useHasSelectedEntity();
  const workspaceId = useWorkspaceId();
  return { hasSelectedEntity, workspaceId };
};

const WorkspaceTitle = () => {
  const { hasSelectedEntity, workspaceId } = useWorkspaceTitle();

  if (hasSelectedEntity && !workspaceId) {
    return <GetStartedBackButton />;
  }

  if (!workspaceId) return null;

  return (
    <div className="flex align-i-center">
      <ToggleWorkspaceName />
      <WorkspaceSettingsDropdown />
    </div>
  );
};

export default WorkspaceTitle;
