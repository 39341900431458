import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import React, { FC } from "react";
import { useSubscriptionDetails } from "../../../../billing/hooks/useSubscriptionDetails";
import { ORGANIZATION_PLAN_FE } from "../../../../billing/helpers/billingConstants";
import {
  DEFAULT_EMPLOYEE_COUNT,
  employeCountValues
} from "./helpers/constants";
import { TSetSelectValFunction, TSetValFunction } from "./helpers/types";

interface ICompanyInformationFormProps {
  companyName?: string;
  changeField: TSetValFunction;
  changeSelectField: TSetSelectValFunction;
  employeCount?: string;
}

const CompanyInformationForm: FC<ICompanyInformationFormProps> = ({
  companyName = "",
  changeField,
  changeSelectField,
  employeCount = DEFAULT_EMPLOYEE_COUNT
}) => {
  const { activeTier } = useSubscriptionDetails();
  if (activeTier === ORGANIZATION_PLAN_FE) return null;
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          label="Company Name"
          value={companyName}
          size="medium"
          onChange={changeField("company.name")}
          fullWidth
          margin="normal"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl
          margin="normal"
          size="medium"
          className="m-r-8px"
          fullWidth
        >
          <InputLabel>{"Company Size"}</InputLabel>
          <Select
            name={"Company Size"}
            label={"Company Size"}
            id={"Company Size"}
            value={employeCount}
            inputProps={{ backgroundColor: "yellow" }}
            onChange={changeSelectField("company.employee_count")}
          >
            {employeCountValues.map((o, idx) => (
              <MenuItem value={o} key={idx}>
                {o}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default CompanyInformationForm;
