import React from "react";
import ToggleSecondaryHeaderTextFieldBase from "../../../../utilities/components/textFields/ToggleSecondaryHeaderTextFieldBase";
import { useFormContext } from "react-hook-form";
import UpdateWorkflowDescriptionTextField from "./UpdateWorkflowDescriptionTextfield";
import { WORKFLOW_DESCRIPTION_FIELD_LABEL } from "./WorkflowBuilderConstants";
import { ADD_DESCRIPTION_TEXT } from "../../../../utilities/constants/fieldConstants";

const ToggleWorkflowDescriptionTextField = () => {
  const { watch } = useFormContext();
  const workflowDescription = watch(WORKFLOW_DESCRIPTION_FIELD_LABEL);

  return (
    <ToggleSecondaryHeaderTextFieldBase
      value={workflowDescription || ADD_DESCRIPTION_TEXT}
    >
      <UpdateWorkflowDescriptionTextField />
    </ToggleSecondaryHeaderTextFieldBase>
  );
};

export default ToggleWorkflowDescriptionTextField;
