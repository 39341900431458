import React from "react";
import { Fab } from "@mui/material";
import useAddStep from "./hooks/useAddStep";
import { AddIcon } from "../../../../../globalTheme/icons/icons";

function AddWorklfowStepButton() {
  const addStep = useAddStep();

  return (
    <Fab onClick={addStep} color="primary" size="small">
      <AddIcon />
    </Fab>
  );
}

export default AddWorklfowStepButton;
