import { type EntityState, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";
import { userApi } from "../userApi";
import type { ProfileWorkflow, TMarketplaceEntity } from "@toolflow/shared";
import { workflowAdapter } from "../../../stores/adapters/workflowAdapter";
import { toolsApi } from "../toolsApi";
import { workflowsApi } from "../workflowApi";
import { marketplaceEntityAdapter } from "../../../stores/adapters/marketplaceEntityAdapter";

const initialCurrentTools: EntityState<TMarketplaceEntity, string> = {
  entities: {},
  ids: []
};

export const selectCurrentUserData = userApi.endpoints.getCurrentUser.select();

export const getCurrentUserIdInSelector = (state: RootState): string => {
  // Use the generated selector to get the current user data
  const { data } = selectCurrentUserData(state);
  // Extract and return the user ID from the current user data
  return data?.user?._id || "";
};

export const selectCurrentUserId = createSelector(
  (state: RootState) => selectCurrentUserData(state),
  (currentUserData) => currentUserData?.data?.user?._id || ""
);

export const selectCurrentUserProfileTools = createSelector(
  [getCurrentUserIdInSelector, (state: RootState) => state],
  (currentUserId, state) => {
    const {
      data = initialCurrentTools, // Ensure initialCurrentTools is defined/imported
      ...rest
    } = toolsApi.endpoints.getProfileTools.select(currentUserId ?? "")(state);

    return {
      tools: marketplaceEntityAdapter.getSelectors().selectAll(data),
      ...rest
    };
  }
);

const initialCurrentWorkflows: EntityState<ProfileWorkflow, string> = {
  entities: {},
  ids: []
};

export const selectCurrentUserProfileWorkflows = createSelector(
  [getCurrentUserIdInSelector, (state: RootState) => state],
  (currentUserId, state) => {
    const {
      data = initialCurrentWorkflows, // Ensure initialCurrentTools is defined/imported
      ...rest
    } = workflowsApi.endpoints.getProfileWorkflows.select(currentUserId ?? "")(
      state
    );

    return {
      workflows: workflowAdapter.getSelectors().selectAll(data),
      ...rest
    };
  }
);
