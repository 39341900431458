import { SNIPPET_FORM_PATH } from "../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";

const useNagivateToNewSnippet = () => {
  const navigate = useToolflowNavigate();

  const navigateToNewSnippet = (e?: React.SyntheticEvent<Element, Event>) => {
    navigate(SNIPPET_FORM_PATH, e);
  };

  return navigateToNewSnippet;
};

export default useNagivateToNewSnippet;
