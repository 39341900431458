import React from "react";
import type { CustomToolInputField } from "@toolflow/shared";
import ToolInputFieldDragHandle from "./ToolInputFieldDragHandle";
import { Chip } from "@mui/material";
import DraggingWrapper from "../../../../../../../../../utilities/components/draggables/DraggingWrapper";
import useIsDragging from "../../../../../../../../../utilities/components/draggables/hooks/useIsDragging";
import { useCustomToolInputFieldContext } from "../../../../../../../../../utilities/contexts/CustomToolInputFieldContext";
import DraggableToolInputFieldWrapper from "./DraggableToolInputFieldWrapper";
import useSelectInput from "./hooks/useSelectInput";
import ClickableInputOutput from "./ClickableInputOutput";
import iconDict from "../../../../../../../../../utilities/constants/iconDict";
import DeleteInputButtonContainer from "./DeleteInputButton";

const useDraggableToolInputField = () => {
  const { input: toolInputField } = useCustomToolInputFieldContext();
  const selectInput = useSelectInput();
  const isDragging = useIsDragging();
  const icon = iconDict[toolInputField.type];
  const name = toolInputField.name;
  const id = toolInputField.id;

  return {
    icon,
    name,
    id,
    selectInput,
    isDragging
  };
};

const DraggableToolInputFieldInner = () => {
  const { icon, name, id, selectInput, isDragging } =
    useDraggableToolInputField();

  return (
    <DraggingWrapper
      className="flex align-i-center m-b-8px"
      gap="8px"
      preview={<Chip label={name || "Input"} variant="outlined" size="small" />}
    >
      <ToolInputFieldDragHandle className="m-r-8px" />
      <ClickableInputOutput
        icon={icon}
        name={name}
        id={id}
        onClick={selectInput}
        disabled={isDragging}
        SecondaryAction={DeleteInputButtonContainer}
      />
    </DraggingWrapper>
  );
};

const DraggableToolInputField = ({
  toolInputField,
  index
}: {
  toolInputField: CustomToolInputField;
  index: number;
}) => {
  return (
    <DraggableToolInputFieldWrapper
      index={index}
      toolInputField={toolInputField}
    >
      <DraggableToolInputFieldInner />
    </DraggableToolInputFieldWrapper>
  );
};

export default DraggableToolInputField;
