// ToolDetailsButton.tsx
import React from "react";
import type { ExternalToolFE } from "@toolflow/shared"; // Adjust import path as necessary
import ToolDetailsButtonInner from "./ToolDetailsButtonInner";

interface ToolDetailsButtonProps {
  tool: ExternalToolFE;
  secondaryAction?: React.ReactNode;
  disabled?: boolean;
  icon?: React.ReactNode;
  avatar?: React.ReactNode;
  onClickHandler?: () => void;
  disableMaxWidth?: boolean;
  disableInfoTags?: boolean;
}

const ToolDetailsButton: React.FC<ToolDetailsButtonProps> = (props) => {
  const { tool, ...rest } = props;

  return (
    <ToolDetailsButtonInner
      name={tool.name}
      about={tool.about}
      visibility={tool.visibility}
      id={tool._id}
      runCount={tool.runCount}
      estimatedCreditCost={tool.estimatedCreditCost}
      {...rest}
    />
  );
};

export default ToolDetailsButton;
