import React, { createContext, useContext, useState } from "react";
import { WorkspaceProps } from "../helpers/workspaceTypes";

interface TMuiTipTapContext {
  muiTipTapProps: WorkspaceProps;
  setIsSlash: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRenderBubble: React.Dispatch<React.SetStateAction<boolean>>;
  isSlash: boolean;
  shouldRenderBubble: boolean;
}

const MuiTipTapContext = createContext<TMuiTipTapContext | undefined>(
  undefined
);

export function MuiTipTapContextComponent({
  children,
  routeType,
  initialContent = "",
  focusOnMount,
  className = "",
  placeholder = `Press "/" for commands`,
  onUpdate,
  disableAutofocus,
  container,
  page,
  disableFormattingMenu,
  disableEditing,
  variant = "outlined",
  assetNodeType = "node",
  richTextFieldClassName = "default-tiptap-textfield-p",
  enableHover,
  saveWorkspaceType = "html",
  disableWhite,
  overrideKeydown,
  BoxProps,
  id,
  disableBubble,
  chipsToUse,
  extensionsConfig = {}
}: {
  children: React.ReactNode;
} & WorkspaceProps) {
  const [shouldRenderBubble, setShouldRenderBubble] = useState(false);
  const [isSlash, setIsSlash] = useState(false);

  return (
    <MuiTipTapContext.Provider
      value={{
        setIsSlash,
        isSlash: disableBubble ? false : isSlash,
        shouldRenderBubble: disableBubble ? false : shouldRenderBubble,
        setShouldRenderBubble,
        muiTipTapProps: {
          routeType,
          initialContent,
          focusOnMount,
          className,
          extensionsConfig,
          placeholder,
          onUpdate,
          disableAutofocus,
          container,
          page,
          disableFormattingMenu,
          disableBubble,
          disableEditing,
          variant,
          assetNodeType,
          BoxProps,
          richTextFieldClassName,
          enableHover,
          saveWorkspaceType,
          disableWhite,
          overrideKeydown,
          id,
          chipsToUse
        }
      }}
    >
      {children}
    </MuiTipTapContext.Provider>
  );
}

export const useMuiTipTapContext = (): TMuiTipTapContext => {
  const context = useContext(MuiTipTapContext);

  if (context === undefined) {
    throw new Error(
      "useMuiTipTapContext must be used within a MuiTipTapContextType"
    );
  }

  return context;
};

export default MuiTipTapContext;
