import type { TAgentBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { AGENT_VISIBILITY_FIELD_LABEL } from "../../constants/fields";

function useGetAgentVisibility() {
  const { watch } = useFormContext<TAgentBuilderForm>();
  return watch(AGENT_VISIBILITY_FIELD_LABEL);
}

export default useGetAgentVisibility;
