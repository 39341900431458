import { ChipNodeComponents } from "../ChipNodeComponents";
import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";
import { ExtensionContent, ExtensionGroup } from "./configTypes";

export const FlowThinkingExtensionConfig = {
  config: {
    name: ChipNodeComponents.FlowThinkingComponent,

    // group: "block",
    group: ExtensionGroup.Inline,
    content: ExtensionContent.Inline,
    inline: true,
    draggable: false,
    selectable: false, // needed so that we don't delete it when typing things if you click the tool

    atom: true
  },
  attributes: {},
  htmlTag: ChipNodeHtmlTags.FlowThinkingComponent
};
