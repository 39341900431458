import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import useSaveAgentAndClose from "./hooks/useSaveAgentAndClose";

function SaveAgentButtonFromDialog() {
  const { saveAndClose, loading } = useSaveAgentAndClose();
  return (
    <LoadingButton variant="contained" onClick={saveAndClose} loading={loading}>
      Save
    </LoadingButton>
  );
}

export default SaveAgentButtonFromDialog;
