import { useEffect } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import type { RichTextEditorRef } from "mui-tiptap";

const useFocusEditorOnMount = ({
  rteRef
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
}) => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { focusOnMount } = muiTipTapProps;
  useEffect(() => {
    if (rteRef.current?.editor && focusOnMount) {
      rteRef.current.editor.commands.focus();
    }
  }, [rteRef.current?.editor]);
};

export default useFocusEditorOnMount;
