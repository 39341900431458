import React from "react";
import { EditIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import useNavigateToToolBuilder from "../hooks/useNavigateToToolBuilder";

function EditToolButton({
  toolId,
  className = "",
  disabled
}: {
  toolId: string;
  className?: string;
  disabled?: boolean;
}) {
  const navigateToToolBuilder = useNavigateToToolBuilder();
  if (!toolId) return null;

  return (
    <RemixButton
      text="Edit"
      onClick={navigateToToolBuilder(toolId)}
      className={className}
      disabled={disabled}
      icon={EditIcon}
    />
  );
}

export default EditToolButton;
