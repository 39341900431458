import { useMemo } from "react";
import getData from "../functions/getData";
import type { RowUserInputDictType, TSelectedTool } from "@toolflow/shared";

const useGetInitialData = ({
  selectedTool,
  savedData = []
}: {
  selectedTool: TSelectedTool;
  savedData?: RowUserInputDictType[];
}) => {
  return useMemo(() => {
    if (savedData.length > 0) {
      return savedData;
    } else if (selectedTool) {
      return getData(1, selectedTool);
    } else {
      return [];
    }
  }, [selectedTool?._id]);
};

export default useGetInitialData;
