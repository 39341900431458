import type { TBlock } from "@toolflow/shared";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useGetDrawerNodeId from "./useGetDrawerNodeId";

const useGetCurrentBlock = (): TBlock | null => {
  const nodeId = useGetDrawerNodeId();
  const { state } = useToolbuilderContext();
  const { blocks } = state.currentState;
  return blocks.filter((block) => block.id === nodeId)[0] ?? null;
};

export default useGetCurrentBlock;
