import React from "react";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import { PROFILE_NESTED_MAIN_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import SettingsMenuItemWithClose from "../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { ProfileIcon } from "../../../../globalTheme/icons/icons";

const NavigateToProfileMenuItem = () => {
  const navigate = useToolflowNavigate();

  const navigateToSettings = () => {
    navigate(PROFILE_NESTED_MAIN_ROUTE_PATH);
  };
  return (
    <SettingsMenuItemWithClose
      icon={<ProfileIcon size={18} />}
      text="Profile"
      action={navigateToSettings}
    />
  );
};

export default NavigateToProfileMenuItem;
