import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { selectSnippetById } from "../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import NameAndActionsRow from "../uI/NameAndActionRow";
import SnippetRowActions from "./snippetRowActions/SnippetRowActions";
import useNavigateToSnippet from "../hooks/useNavigateToSnippet";

function SnippetRowContainer({ snippetId }: { snippetId: string }) {
  const snippet = useSelector((state: RootState) =>
    selectSnippetById(state, snippetId)
  );
  const navigateToSnippet = useNavigateToSnippet(snippetId);

  return (
    <NameAndActionsRow
      name={snippet.name}
      actions={<SnippetRowActions id={snippetId} />}
      onClick={navigateToSnippet}
      className="cursor-pointer"
    />
  );
}

export default SnippetRowContainer;
