import { Divider, Typography } from "@mui/material";
import React from "react";
import ClickableChip from "../../../../../../../../utilities/components/chips/ClickableChip";
import type { ToolAutocomplete } from "@toolflow/shared";
import { ClickableTypes } from "@toolflow/shared";

import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import styles from "./chipGroup.module.css";

const cx = parsedClassNames.bind(styles);

export const ChipGroup = ({
  name,
  entities = [],
  type,
  onClickChip,
  action,
  className = ""
}: {
  name: string;
  entities?: ToolAutocomplete[];
  type: ClickableTypes;
  onClickChip: (type: ClickableTypes, id: string) => void;
  action?: React.ReactNode;
  className?: string;
}) => {
  return (
    <>
      <div
        className={cx(
          styles["height-35px"],
          "flex align-i-center justify-space-between",
          className
        )}
      >
        <Typography variant="subtitle2" className="capitalize">
          {name}
        </Typography>
        {action}
      </div>
      <Divider />
      {entities.map((entity) => (
        <ClickableChip
          key={entity.id}
          text={entity.primary}
          type={type}
          onClick={onClickChip}
          id={entity.id}
        />
      ))}
    </>
  );
};
