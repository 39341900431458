import {
  type AllBlockTypes,
  type BlockDataTypesWithDefault,
  type BlockMessageType,
  type CustomEdge
} from "@toolflow/shared";
import { getBlockValidatorByVersion } from "./blocks/constants/blockConfigFns/blockValidators";
import { convertToBlockMessageType } from "./blocks/constants/convertToBlockMessageType";
import { getLatestBlockVersionByType } from "./blocks/constants/helpers/getLatestBlockVersion";

function setBlockMessage({
  data,
  edges,
  id
}: {
  data?: BlockDataTypesWithDefault;
  edges: CustomEdge[];
  id?: string;
}): BlockMessageType | undefined {
  if (!data) return undefined;
  let blockMessageType: BlockMessageType | undefined = undefined;

  if (data.version !== getLatestBlockVersionByType(data.type)?.data.version) {
    blockMessageType = convertToBlockMessageType(
      `There is a newer version of this block. Drag the latest version from the sidebar to upgrade.`,
      "warning"
    );
  }

  const validator = getBlockValidatorByVersion(data.type as AllBlockTypes);
  if (validator) {
    return validator({ data, edges, id });
  }

  // If no errors were found, return null
  return blockMessageType;
}

export default setBlockMessage;
