import type { ExternalToolFE, MapperObject } from "@toolflow/shared";

const getInitialInputMap = (tool: ExternalToolFE) => {
  return tool.main.toolInputFields.reduce<MapperObject>(
    (accumulator, currentField) => {
      accumulator[currentField.name] = "";
      return accumulator;
    },
    {}
  );
};

export default getInitialInputMap;
