import type {
  TMarketplaceEntityOptionTypeWithNull,
  TSelectAutocomplete
} from "@toolflow/shared";
import React from "react";
import { useDispatch } from "react-redux";
import { useCaseTags } from "../../../../../features/tools/components/editorToolCard/toolCategorization/constants";
import { setCategoryWithUseCases } from "../../../../../features/tools/components/searchToolsContainer/slice/searchToolsSlice";

interface IUseMarketplaceSearchProps {
  handleSelectEntity: TSelectAutocomplete;
}
const useMarketplaceSearch = (props: IUseMarketplaceSearchProps) => {
  const { handleSelectEntity } = props;
  const dispatch = useDispatch();

  const [userSearchValue, setUserSearchValue] = React.useState<string>("");
  const [userSelectedValue, setUserSelectedValue] =
    React.useState<TMarketplaceEntityOptionTypeWithNull>(null);
  const [open, setOpen] = React.useState(false);

  const handleChangeUserSelectedValue = (
    event: React.SyntheticEvent,
    value: TMarketplaceEntityOptionTypeWithNull,
    reason: string
  ) => {
    if (reason === "selectOption" && value) {
      handleSelectEntity(value.id, value.group);
    }
    const selectedUseCase = useCaseTags.find(
      (useCase) => useCase.value === value?.primary
    );
    if (selectedUseCase) {
      dispatch(
        setCategoryWithUseCases({
          category: "all",
          useCases: [selectedUseCase.value]
        })
      );
      setUserSelectedValue(null);
      setUserSearchValue("");
    } else {
      setUserSelectedValue(value);
    }
  };

  const handleClearSearch = () => {
    setUserSearchValue("");
    setUserSelectedValue(null);
    setOpen(false);
  };
  return {
    handleChangeUserSelectedValue,
    userSearchValue,
    setUserSearchValue,
    userSelectedValue,
    setUserSelectedValue,
    open,
    setOpen,
    handleClearSearch
  };
};

export default useMarketplaceSearch;
