import React from "react";
import useUnfavoriteObject from "./hooks/useUnfavoriteObject";
import type { ButtonSizeTypes } from "@toolflow/shared";
import IconButtonWithBorder from "../buttons/IconButtonWithBorder";
import { StarFillIcon } from "../../../globalTheme/icons/icons";
import { IconButton } from "@mui/material";

function FavoritedObject({
  className = "",
  size = 20,
  disableBorder,
  iconButtonSize = "small"
}: {
  className: string;
  size: number;
  disableBorder?: boolean;
  iconButtonSize?: ButtonSizeTypes;
}) {
  const unFavorite = useUnfavoriteObject();
  const Component = disableBorder ? IconButton : IconButtonWithBorder;

  return (
    <Component onClick={unFavorite} className={className} size={iconButtonSize}>
      <StarFillIcon size={size} color="#FFB400" />
    </Component>
  );
}

export default FavoritedObject;
