import type { RowUserInputDictType } from "@toolflow/shared";

const updateSheetDataWithRow = (
  rows: RowUserInputDictType[],
  updatedRow: RowUserInputDictType,
  rowIndex: number
) => {
  const newRows = [...rows];
  newRows[rowIndex] = updatedRow;
  return newRows;
};

export default updateSheetDataWithRow;
