import React from "react";
import { useSelector } from "react-redux";
import ExpandedAssetList from "./expandedAssetList/ExpandedAssetList";
import { RootState } from "../../../../../../stores/store";
import SmallAssetTOC from "./smallAssetTOC/SmallAssetTOC";

function AssetTableOfContents() {
  const assetListBig = useSelector(
    (state: RootState) => state.workspace.assetListBig
  );
  return assetListBig ? <ExpandedAssetList /> : <SmallAssetTOC />;
}

export default AssetTableOfContents;
