import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import {
  WORKSPACE_VISIBLE_ASSET_ID,
  WORKSPACE_VISIBLE_SUB_ASSET_ID
} from "../helpers/workspaceConstants";

const useSetVisibleAsset = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const setVisibleAsset = (assetId: string) => {
    setValue(WORKSPACE_VISIBLE_ASSET_ID, assetId, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  const setVisibleSubAsset = (subAssetId: string) => {
    setValue(WORKSPACE_VISIBLE_SUB_ASSET_ID, subAssetId, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    });
  };

  return { setVisibleAsset, setVisibleSubAsset };
};

export default useSetVisibleAsset;
