import React, { useRef, useEffect, useState } from "react";
import {
  AGENT,
  EntityType,
  type ValidateTypeWithAgent
} from "@toolflow/shared";
import { useSettingsDropdownContext } from "../../generic/useSettingsDropownContext";
import useNavigateHome from "../../../../../features/pages/home/hooks/useNavigateHome";
import { WORKSPACE } from "../../../../constants/constants";
import SettingsMenuItem from "../../generic/SettingsMenuItem";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { useDeleteWorkspaceMutation } from "../../../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { useDeleteToolMutation } from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useDeleteWorkflowMutation } from "../../../../../ToolflowAPI/rtkRoutes/workflowApi";
import { toolflowErrorColor } from "../../../../../globalTheme/muiUtils/appTheme";
import { DeleteIcon } from "../../../../../globalTheme/icons/icons";
import { useDeleteAgentMutation } from "../../../../../ToolflowAPI/rtkRoutes/agentApi";

function DeleteMenuItem({
  setDisableCard = () => {},
  id,
  shouldNavigateHome,
  type,
  name = ""
}: {
  setDisableCard?: (val: boolean) => void;
  id: string;
  shouldNavigateHome?: boolean;
  type: ValidateTypeWithAgent;
  name?: string;
}) {
  const { handleClose } = useSettingsDropdownContext();
  const [open, setOpen] = useState(false);
  const [deleteWorkspace] = useDeleteWorkspaceMutation();
  const [deleteWorkflow] = useDeleteWorkflowMutation();
  const [deleteTool] = useDeleteToolMutation();
  const [deleteAgent] = useDeleteAgentMutation();
  const isMounted = useRef(false);
  const navigateHome = useNavigateHome();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const executeDeleteTool = async (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    setDisableCard(true);
    try {
      handleClose(e);
      if (type === WORKSPACE) {
        await deleteWorkspace(id);
      } else if (type === EntityType.TOOL) {
        await deleteTool(id);
      } else if (type === EntityType.WORKFLOW) {
        await deleteWorkflow(id);
      } else if (type === AGENT) {
        await deleteAgent(id);
      }
    } catch (error) {
      console.error(`Error while deleting ${type}:`, error);
    } finally {
      if (isMounted.current) {
        setDisableCard(false);
      }
      if (shouldNavigateHome) {
        navigateHome();
      }
    }
  };

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleCloseDialog = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
    handleClose(e);
  };

  return (
    <>
      <SettingsMenuItem
        action={handleOpen}
        text="Delete"
        textColor="error"
        hide={!id}
        icon={<DeleteIcon size={16} color={toolflowErrorColor} />}
      />
      <DeleteConfirmationDialog
        open={open}
        handleClose={handleCloseDialog}
        deleteObject={executeDeleteTool}
        type={type}
        name={name}
      />
    </>
  );
}

export default DeleteMenuItem;
