import React, { FC } from "react";
import { Link } from "react-router-dom";
import { HOME_NESTED_HISTORY_ROUTE_PATH } from "../../navigation/helpers/routePaths";
import { Button } from "@mui/material";

interface INavigateToHistoryActionProps {
  btnText?: string;
  variant?: "contained" | "outlined";
}
const NavigateToHistoryAction: FC<INavigateToHistoryActionProps> = ({
  btnText = "Show all",
  variant = "contained"
}) => {
  return (
    <Link to={HOME_NESTED_HISTORY_ROUTE_PATH}>
      <Button size="small" variant={variant} className="m-t-8px">
        {btnText}
      </Button>
    </Link>
  );
};

export default NavigateToHistoryAction;
