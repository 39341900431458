import { Card, CardHeader } from "@mui/material";
import { useCancelSubscriptionMutation } from "../../../../../ToolflowAPI/rtkRoutes/billingApi";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSubscriptionDetails } from "../../../../billing/hooks/useSubscriptionDetails";
import { FREE_PLAN_FE } from "../../../../billing/helpers/billingConstants";

export function CancelAccountCard() {
  const [cancelSubscription, { isLoading: isCanceling }] =
    useCancelSubscriptionMutation();

  const { activeTier } = useSubscriptionDetails();

  if (activeTier === FREE_PLAN_FE) {
    return null;
  }

  return (
    <Card variant="outlined" className="m-b-16px m-t-16px">
      <CardHeader
        title="Cancel account"
        subheader="Cancel your account. You will lose access to all paid features."
        action={
          <LoadingButton
            onClick={() => cancelSubscription()}
            variant="outlined"
            loading={isCanceling}
          >
            Cancel
          </LoadingButton>
        }
      />
    </Card>
  );
}
