import type {
  CustomToolInputField,
  CustomToolInputFieldTypes
} from "@toolflow/shared";
import React, { createContext, useContext } from "react";
import useAddInputOption from "./useAddInputOption";

type TAddToolInputOption = (i: CustomToolInputField) => void;
type TChangeInputFieldDescription = (
  description: string,
  index: number
) => void;
type TChangeInputName = (name: string, index: number) => void;
type TDeleteInputField = (index: number) => void;
type TChangeInputType = (
  type: CustomToolInputFieldTypes,
  index: number
) => void;
type TDeleteOption = (
  toolInputField: CustomToolInputField | null,
  optionIndex: number
) => void;
type TChangeOptionName = (
  toolInputField: CustomToolInputField | null,
  optionIndex: number,
  val: string,
  index: number
) => void;
export type TSetToolInputFields = (
  updatedToolInputFields: CustomToolInputField[]
) => void;

type TCustomToolInputFieldsContext = {
  toolInputFields: CustomToolInputField[];
  addToolInputField: () => void;
  addToolInputOption: TAddToolInputOption;
  changeInputDescription: TChangeInputFieldDescription;
  changeInputName: TChangeInputName;
  deleteInputField: TDeleteInputField;
  changeInputType: TChangeInputType;
  deleteOption: TDeleteOption;
  changeOptionName: TChangeOptionName;
  setToolInputFields: TSetToolInputFields;
};

const ToolInputFieldsContext = createContext<
  TCustomToolInputFieldsContext | undefined
>(undefined);

export const useToolInputFieldsContext = () => {
  const context = useContext(ToolInputFieldsContext);
  if (context === undefined) {
    throw new Error(
      "useToolInputFieldsContext must be used within a ToolInputFieldsContext"
    );
  }
  return context;
};

export const ToolInputFieldsContextProvider = ({
  children,
  toolInputFields,
  addToolInputField,
  changeInputDescription,
  changeInputName,
  deleteInputField,
  changeInputType,
  deleteOption,
  changeOptionName,
  setToolInputFields
}: {
  children: React.ReactNode;
  toolInputFields: CustomToolInputField[];
  addToolInputField: () => void;
  changeInputDescription: TChangeInputFieldDescription;
  changeInputName: TChangeInputName;
  deleteInputField: TDeleteInputField;
  changeInputType: TChangeInputType;
  deleteOption: TDeleteOption;
  changeOptionName: TChangeOptionName;
  setToolInputFields: TSetToolInputFields;
}) => {
  const addToolInputOption = useAddInputOption({
    setToolInputFields,
    inputs: toolInputFields
  });

  return (
    <ToolInputFieldsContext.Provider
      value={{
        toolInputFields,
        addToolInputField,
        addToolInputOption,
        changeInputDescription,
        changeInputName,
        deleteInputField,
        changeInputType,
        deleteOption,
        changeOptionName,
        setToolInputFields
      }}
    >
      {children}
    </ToolInputFieldsContext.Provider>
  );
};
