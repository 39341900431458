import type { TBlock, CustomEdge, TSetEdges } from "@toolflow/shared";
import useUpdateAvailableFields from "./useUpdateAvailableFields";
import { Action } from "../toolBuilderReducer";

const useHandleEdgeDelete = ({
  edges,
  blocks,
  setEdges,
  dispatch
}: {
  edges: CustomEdge[];
  blocks: TBlock[];
  setEdges: TSetEdges;
  dispatch: React.Dispatch<Action>;
}) => {
  const updateAvailableFields = useUpdateAvailableFields(blocks, dispatch);
  const handleEdgeDelete = (id: string) => {
    const e = edges.filter((edge) => edge.id === id)[0];
    const newEdges = edges.filter((edge) => edge.id !== id);
    setEdges(newEdges, true);

    newEdges.forEach((edge) => {
      if (edge.id.includes("->")) {
        updateAvailableFields(edge.target, newEdges);
      }
    });

    updateAvailableFields(e.target, newEdges);
  };
  return handleEdgeDelete;
};

export default useHandleEdgeDelete;
