import type { ToolOutputOption } from "@toolflow/shared";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../layout/alertMessageSnackbar/alertMessageSlice";

const useIsUniqueField = () => {
  const reduxDispatch = useDispatch();

  const isUniqueField = (
    value: string,
    {
      inputs,
      blocks
    }: { inputs: ToolOutputOption[]; blocks: ToolOutputOption[] }
  ) => {
    const combinedArray = [...inputs, ...blocks];

    const isValueUnique = !combinedArray.some(
      (element) => value === element.value
    );

    if (!isValueUnique) {
      reduxDispatch(
        setErrorMessage(
          `Another field already labeled: ${value}. All field labels need to be unique.`
        )
      );
    }

    return isValueUnique;
  };

  return isUniqueField;
};

export default useIsUniqueField;
