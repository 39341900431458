import React from "react";
import CustomToolInputFieldToolbuilderProvider from "./customToolInputFields/CustomToolInputFieldToolbuilderProvider";
import { InputIcon } from "../../../../../../../../globalTheme/icons/icons";
import { InputType } from "../../../../inputsOutputs/InputOutputBase";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import InputOutputBase from "../utilities/InputOutputBase";

function ToolInputDrawer() {
  const { state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;

  return (
    <InputOutputBase
      Component={InputIcon}
      type={InputType.INPUT}
      entityCount={toolInputFields.length}
      content={<CustomToolInputFieldToolbuilderProvider />}
    />
  );
}

export default ToolInputDrawer;
