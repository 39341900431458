import React from "react";
import { Typography } from "@mui/material";

function AssetName({ text }: { text: string }) {
  return (
    <Typography variant="h5" component="span" className={"break-words"}>
      {text}
    </Typography>
  );
}

export default AssetName;
