import { Stack, Typography } from "@mui/material";
import { StructuredOutputAccordion } from "./StructuredOutputAccordion";

export function StructuredListOfSingleValues({
  name,
  value
}: {
  name: string;
  value: (string | number | boolean)[];
}) {
  return (
    <StructuredOutputAccordion name={name}>
      <Stack spacing={1} sx={{ padding: 1 }}>
        {value.map((item, index) => {
          return <Typography key={index}>{`${index + 1}. ${item}`}</Typography>;
        })}
      </Stack>
    </StructuredOutputAccordion>
  );
}
