import { Paper, Tooltip, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import {
  toolflowDarkBackground,
  toolflowWhiteColor
} from "../../../globalTheme/muiUtils/appTheme";

export const DarkUtilityTooltip = ({
  children,
  title,
  placement = "top",
  className = ""
}: {
  children: ReactElement;
  title: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  className?: string;
}) => {
  if (!title) return <>{children}</>;
  return (
    <Tooltip
      arrow
      placement={placement}
      classes={{
        popper: `p-0px ${className} border-radius-8px`,
        tooltip: `p-0px ${className} border-radius-8px`
      }}
      title={
        <Paper
          className={`p-h-8px p-v-4px ${className}`}
          sx={{
            backgroundColor: toolflowDarkBackground,
            borderRadius: "8px",
            border: `1px solid ${toolflowDarkBackground}`,
            color: toolflowWhiteColor
          }}
        >
          {typeof title === "string" ? (
            <Typography variant="caption" fontWeight={500}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Paper>
      }
    >
      {/* We add span so that disabled buttons dont throw console errors */}
      <span>{children}</span>
    </Tooltip>
  );
};
