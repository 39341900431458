import React from "react";
import styles from "./textAndBadge.module.css";
import { Typography } from "@mui/material";

function TextAndBadge({
  text,
  badgeCount,
  typographyVariant = "h5",
  bgBadgeColorWhite
}: {
  text?: string;
  badgeCount?: number;
  typographyVariant?: "h5" | "subtitle2";
  bgBadgeColorWhite?: boolean;
}) {
  return (
    <>
      <Typography
        variant={typographyVariant}
        component="span"
        className="break-words"
      >
        {text}
      </Typography>
      {!!(badgeCount && badgeCount > 0) && (
        <div className="inline-flex m-l-4px">
          <div
            className={`${styles["badge-wrapper"]} ${
              bgBadgeColorWhite ? "bg-color-white" : "icon-background"
            }`}
          >
            <Typography
              className={styles.badge}
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              {badgeCount}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

export default TextAndBadge;
