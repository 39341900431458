import { useGetAllUserEntities } from "./useGetAllUserEntities";

export const useLimitedHomePageEntities = (limit?: number) => {
  const { entities, loading } = useGetAllUserEntities();
  const limitedEntities = entities
    .sort((a, b) => b.updatedAt - a.updatedAt)
    .slice(0, limit);

  return { limitedEntities, loading };
};
