import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import EmptyOutputs from "../inputs/inputBuilders/emptyInputs/EmptyOutputs";
import OutputListOrDetailsSplitter from "./OutputListOrDetailsSplitter";

function OutputDrawerInner() {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;

  if (toolOutputFields.length === 0) {
    return <EmptyOutputs />;
  }

  return <OutputListOrDetailsSplitter />;
}

export default OutputDrawerInner;
