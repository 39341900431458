import {
  FieldType,
  type FieldConfig,
  AnthropicModel,
  BlockType,
  type TextGenerationPropsV2
} from "@toolflow/shared";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { getTextGenerationTechOptions } from "../../../../helpers/priceDict";
import useUpdateField from "../../../../useUpdateField";
import { useCallback } from "react";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";

function PromptBlockInnerv2({
  data,
  id
}: Omit<TextGenerationPropsV2, "selected">) {
  const updateField = useUpdateField(id);

  const updateBlockType = useCallback(
    (value: string) => {
      if (Object.values(AnthropicModel).includes(value as AnthropicModel)) {
        updateField(BlockType.Anthropic, "type");
        if (data.settings.temperature > 50) {
          updateField(50, "settings.temperature");
        }
      } else {
        updateField(BlockType.ChatGPT, "type");
      }
    },
    [updateField]
  );

  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        type: FieldType.NewSelectInput,
        label: "Model",
        subtitle: "Select text generation technology",
        config: {
          fieldKey: `settings.llmModel`,
          options: getTextGenerationTechOptions(),
          callback: updateBlockType
        }
      },
      {
        type: FieldType.PromptInputV2,
        label: "LLM Prompt",
        subtitle: "Set and preview AI instructions for response generation.",
        config: {
          fieldKey: "settings.prompt",
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          autofocus: true,
          textFieldClassName: "min-h-246px",
          chipsToUse
        }
      },
      {
        type: FieldType.Slider,
        label: "Temperature",
        subtitle:
          "Adjust temperature of response. Higher is more random, and lower is more deterministic. Anthropic has a max temperature of 1 and OpenAI has a max temperature of 2.",
        config: {
          fieldKey: `settings.temperature`,
          marksMax: data.type === BlockType.Anthropic ? 50 : undefined, // The Anthropic API maxes out at a temperature of 1. And we divide things by 50
          className: "m-v-16px"
        }
      }
    ]
  };

  return (
    <>
      <FieldsByType configs={configs} />
    </>
  );
}

export default PromptBlockInnerv2;
