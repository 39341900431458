import { createSlice } from "@reduxjs/toolkit";

interface IKnowledgeInitialStateBase {
  ctaDismissed: boolean;
  snippetNameSortAToZ: boolean;
}

const initialState: IKnowledgeInitialStateBase = {
  ctaDismissed: false,
  snippetNameSortAToZ: true
};
export type TKnowledgeReducerName = "knowledge";
export const KNOWLEDGE_REDUCER_NAME: TKnowledgeReducerName = "knowledge";

const knowledgeSlice = createSlice({
  name: KNOWLEDGE_REDUCER_NAME,
  initialState,
  reducers: {
    dismissKnowledgeCTA(state) {
      state.ctaDismissed = true;
    },
    toggleSnippetNameSort(state) {
      state.snippetNameSortAToZ = !state.snippetNameSortAToZ;
    }
  }
});

export const { dismissKnowledgeCTA, toggleSnippetNameSort } =
  knowledgeSlice.actions;

export default knowledgeSlice.reducer;
