/**
 * Parses a string environment variable as a boolean.
 * Returns true if the value is "true" (case-insensitive), else false.
 *
 * @param value - The environment variable value to parse.
 * @returns A boolean representation of the value.
 */
export const parseBooleanEnv = (value?: string): boolean => {
  return value?.toLowerCase() === "true";
};
