import React from "react";
import { Controller } from "react-hook-form";
import { WORKSPACE_ENTITY_ID_FIELD_LABEL } from "../../../helpers/workspaceConstants";
import useGetSelectedEntity from "../../../hooks/useGetSelectedEntity";
import { EntityInfoContainer } from "../../../../../entities/components/entityInfo/EntityInfo";

function EntityHeader() {
  const selectedEntity = useGetSelectedEntity();
  if (!selectedEntity) return null;
  return (
    <Controller
      key={WORKSPACE_ENTITY_ID_FIELD_LABEL}
      name={WORKSPACE_ENTITY_ID_FIELD_LABEL}
      render={() => <EntityInfoContainer entity={selectedEntity} />}
    />
  );
}

export default EntityHeader;
