import { v4 as uuidv4 } from "uuid";
import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";
import { TEXTFIELD } from "../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";

const useHandleAddToolInputFieldWorkflow = () => {
  const { append } = useInputsToCollectFieldArrayContext();

  const addToolInputFieldWorkflow = () => {
    append({ name: "", type: TEXTFIELD, id: uuidv4() });
  };

  return addToolInputFieldWorkflow;
};

export default useHandleAddToolInputFieldWorkflow;
