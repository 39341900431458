import type { RunToolBody } from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import { CREDITS_TAG_TYPE } from "../cacheTagConstants";

const toolVersionApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getToolVersions: builder.query({
      query: (toolId: string) => ({
        url: `toolVersion/${toolId}`,
        method: "GET"
      })
    }),
    runToolVersion: builder.mutation({
      query: ({
        toolVersionId,
        toolData
      }: {
        toolVersionId: string;
        toolData: RunToolBody;
      }) => ({
        url: `toolVersion/${toolVersionId}`,
        method: "POST",
        body: toolData
      }),
      invalidatesTags: [CREDITS_TAG_TYPE]
    })
  })
});

export const {
  useRunToolVersionMutation,

  useGetToolVersionsQuery,
  useLazyGetToolVersionsQuery
} = toolVersionApi;
