import React from "react";

type OutputBlockProps = {
  type: string;
  selected: boolean;
  id: string;
};

// going to hide this for now,
// we may want them back in the future
// don't want to kill it totally
const OutputBlock: React.FC<OutputBlockProps> = () => {
  return <></>;
};

export default OutputBlock;
