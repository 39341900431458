import type { JSONValue } from "@toolflow/shared";

export function validateAndParseJson(str: unknown): JSONValue | null {
  if (!str || typeof str !== "string") {
    return null;
  }
  try {
    const o = JSON.parse(str);
    if (!!o && typeof o === "object") {
      return o;
    }
  } catch (error) {}
  return null;
}
