import React from "react";
import BlockPaperHeader from "./blockPaperHeader/BlockPaperHeader";
import type { BlockDataTypes } from "@toolflow/shared";
import { BlockContextProvider } from "../../BlockContext";
import InHandle from "./inOutNodeHandle/InHandle";
import OutHandle from "./inOutNodeHandle/OutHandle";

type BlockProps = {
  data: BlockDataTypes;
  selected: boolean;
  id: string;
};

const BlockWrapper: React.FC<BlockProps> = ({ data, selected, id }) => {
  return (
    <BlockContextProvider id={id} data={data} selected={selected}>
      <div
        className="parent-hover"
        style={{
          width: "256px"
        }}
      >
        <InHandle />
        <BlockPaperHeader />
        <OutHandle />
      </div>
    </BlockContextProvider>
  );
};

export default BlockWrapper;
