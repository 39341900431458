import React, { createContext, useContext } from "react";
import useToolflowExternalWebsocket from "../hooks/useToolflowExternalWebsocket";

export interface WebsocketContextType {
  sendMessage?: (message: string) => void;
  resetButton?: React.ReactNode;
  readyState?: number;
  reconnect: () => void;
  showReconnectButton: boolean;
}

const WebsocketContext = createContext<WebsocketContextType | undefined>(
  undefined
);

export function WebsocketContextComponent({
  children
}: {
  children: React.ReactNode;
}) {
  // we want to have one websocket connection per application, so we allow people to access it here
  const {
    sendMessage,
    resetButton,
    readyState,
    reconnect,
    showReconnectButton
  } = useToolflowExternalWebsocket();

  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
        resetButton,
        readyState,
        reconnect,
        showReconnectButton
      }}
    >
      {children}
    </WebsocketContext.Provider>
  );
}

export const useWebsocketContext = (): WebsocketContextType => {
  const context = useContext(WebsocketContext);

  if (context === undefined) {
    throw new Error(
      "useWebsocketContext must be used within a WebsocketContextProvider"
    );
  }

  return context;
};

export default WebsocketContext;
