import { useGetAgentQuery } from "../../../ToolflowAPI/rtkRoutes/agentApi";
import { AgentContextComponent } from "./AgentContext";
import React from "react";

function AgentContextComponentContainer({
  agentId,
  children,
  isDefault
}: {
  agentId?: string;
  children: React.ReactNode;
  isDefault?: boolean;
}) {
  const { data } = useGetAgentQuery(agentId || "", {
    skip: !agentId
  });
  if (!data?.result) {
    return null;
  }
  return (
    <AgentContextComponent
      agent={{
        ...data.result.agent,
        name: isDefault ? "Toolflow" : data.result.agent.name
      }}
      isDefault={isDefault}
    >
      {children}
    </AgentContextComponent>
  );
}

export default AgentContextComponentContainer;
