import type {
  SaveWorkflowVersionResponseFE,
  TWorkflowVersionRequestBody,
  UpdateToolResponseFE,
  GetWorkflowResponse,
  UpdateWorkflowDataToSend,
  GetWorkflowVersionsResponseFE,
  GetWorkflowsResponse
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";

import { RootState } from "../../stores/store";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import {
  workflowAdapter,
  workflowVersionAdapter,
  workflowVersionInitialState
} from "../../stores/adapters/workflowAdapter";
import invalidateWorkflowTags from "./helpers/invalidateWorkflowTags";
import {
  WORKFLOW_TAG_TYPE,
  WORKFLOW_VERSION_TAG_TYPE
} from "../cacheTagConstants";
import { updateWorkflowVersionId } from "../../features/pages/builder/workflowBuilder/workflowBuilderSlice";

export const workflowsApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowVersions: builder.query({
      query: (workflowId: string) => ({
        url: `workflow/${workflowId}/versions`,
        method: "GET"
      }),
      providesTags: (result, error, workflowId) => {
        return [{ type: WORKFLOW_VERSION_TAG_TYPE, id: workflowId }];
      },
      transformResponse: (response: GetWorkflowVersionsResponseFE) => {
        return workflowVersionAdapter.upsertMany(
          workflowVersionInitialState,
          response.workflowVersions
        );
      }
    }),
    getWorkflow: builder.query<GetWorkflowResponse, string>({
      query: (workflowId: string) => ({
        url: `workflow/${workflowId}`,
        method: "GET"
      }),
      providesTags: (result, error, workflowId) => {
        return [{ type: WORKFLOW_TAG_TYPE, id: workflowId }];
      },
      async onQueryStarted(workflowId, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          const { workflow } = data;
          dispatch(updateWorkflowVersionId(workflow.mainId));
        } catch {}
      }
    }),
    deleteWorkflow: builder.mutation({
      query: (workflowId: string) => ({
        url: `workflow/${workflowId}`,
        method: "DELETE"
      }),
      async onQueryStarted(workflowId, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateWorkflowTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Workflow deleted"));
        } catch {
          dispatch(setErrorMessage("Error deleting workflow"));
        }
      }
    }),
    updateWorkflow: builder.mutation<
      UpdateToolResponseFE,
      {
        workflowId: string;
        body: UpdateWorkflowDataToSend;
      }
    >({
      query: ({ workflowId, body }) => ({
        url: `workflow/${workflowId}`,
        method: "PUT",
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateWorkflowTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Workflow saved"));
        } catch {
          dispatch(setErrorMessage("Error updating workflow"));
        }
      },
      invalidatesTags: (result, error, args) => [
        { type: WORKFLOW_TAG_TYPE, id: args.workflowId },
        { type: WORKFLOW_VERSION_TAG_TYPE, id: args.workflowId }
      ]
    }),
    saveWorkflow: builder.mutation<
      SaveWorkflowVersionResponseFE,
      {
        workflowId?: string;
        body: TWorkflowVersionRequestBody;
      }
    >({
      query: ({ workflowId = "", body }) => ({
        url: `workflow/${workflowId}`,
        method: "POST",
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          invalidateWorkflowTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Workflow saved"));
          dispatch(updateWorkflowVersionId(data.workflowVersion._id));
        } catch {
          dispatch(setErrorMessage("Error updating workflow"));
        }
      },
      invalidatesTags: (result, error, args) => [
        { type: WORKFLOW_TAG_TYPE, id: args.workflowId },
        { type: WORKFLOW_VERSION_TAG_TYPE, id: args.workflowId }
      ]
    }),
    getProfileWorkflows: builder.query({
      query: (profileId: string) => ({
        url: `workflow/p/${profileId}`,
        method: "GET"
      }),
      transformResponse: (response: GetWorkflowsResponse) => {
        return workflowAdapter.upsertMany(
          workflowAdapter.getInitialState(),
          response.workflows
        );
      },
      providesTags: (result, error, args) => {
        const profileId = args;
        return [{ type: WORKFLOW_TAG_TYPE, id: profileId }];
      }
    })
  })
});

export const {
  useGetProfileWorkflowsQuery,
  useGetWorkflowVersionsQuery,
  useGetWorkflowQuery,
  useUpdateWorkflowMutation,
  useSaveWorkflowMutation,
  useDeleteWorkflowMutation
} = workflowsApi;
