import {
  DynamicFieldType,
  type IterationStartBlockSettings
} from "@toolflow/shared";

export const iterationStartSettingsInitialState: {
  settings: IterationStartBlockSettings;
} = {
  settings: {
    input: "",
    fieldType: DynamicFieldType.Dynamic
  }
};
