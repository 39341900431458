import React from "react";
import { OutputIcon } from "../../../../../../../../globalTheme/icons/icons";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import { InputType } from "../../../../inputsOutputs/InputOutputBase";
import InputOutputBase from "../utilities/InputOutputBase";
import OutputDrawerInner from "./OutputDrawerInner";

function ToolOutputDrawer() {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  return (
    <InputOutputBase
      Component={OutputIcon}
      type={InputType.OUTPUT}
      entityCount={toolOutputFields.length}
      content={<OutputDrawerInner />}
    />
  );
}

export default ToolOutputDrawer;
