import { useDispatch } from "react-redux";
import {
  DrawerEnum,
  setDrawer,
  setDrawerExpandedOpen
} from "../toolBuilderSlice";

const useOpenOutputs = () => {
  const dispatch = useDispatch();
  const openOutputs = () => {
    dispatch(setDrawer({ type: DrawerEnum.outputs, outputId: "" }));
    dispatch(setDrawerExpandedOpen(false));
  };
  return openOutputs;
};

export default useOpenOutputs;
