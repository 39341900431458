import React from "react";
import ToolflowHeader from "../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import LearnMoreAboutKnowledge from "./LearnMoreAboutKnowledge/LearnMoreAboutKnowledge";
import SnippetsCard from "./snippetsCard/SnippetsCard";
import CenterContentContainer from "../../../utilities/components/containers/CenterContentContainer";

function KnowledgePageInner() {
  return (
    <ToolflowHeader title="Knowledge">
      <CenterContentContainer>
        <div className="p-h-16px m-t-16px">
          <LearnMoreAboutKnowledge />
        </div>
        <div className="p-h-16px m-t-16px">
          <SnippetsCard />
        </div>
      </CenterContentContainer>
    </ToolflowHeader>
  );
}

export default KnowledgePageInner;
