import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";

function LinkTextButton({
  to,
  text,
  typographyClassName = ""
}: {
  to: string;
  text: string;
  typographyClassName?: string;
}) {
  return (
    <Link
      to={to}
      target="_blank"
      rel="noopener noreferrer"
      className="text-d-none"
    >
      <Typography variant="caption" className={typographyClassName}>
        {text}
      </Typography>
    </Link>
  );
}

export default LinkTextButton;
