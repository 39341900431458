import type {
  TBlock,
  NodeUpdateFunction,
  TPayloadType
} from "@toolflow/shared";
import { useCallback } from "react";
import { Action } from "../toolBuilderReducer";

const useSetNodes = ({
  dispatch,
  blocks,
  payload
}: {
  dispatch: React.Dispatch<Action>;
  blocks: TBlock[];
  payload: TPayloadType;
}) => {
  return useCallback(
    (
      newState: TBlock[] | NodeUpdateFunction,
      changedNode?: TBlock | boolean
    ) => {
      dispatch({
        type: "SET_STATE",
        key: "blocks",
        value: newState,
        changedNode,
        payload
      });
    },
    [dispatch, blocks]
  );
};

export default useSetNodes;
