import { Typography } from "@mui/material";
import { useAgentContext } from "../../../../agents/contexts/AgentContext";
import ChatInputPaper from "../chat/chatbox/ChatInputPaper/ChatInputPaper";
import FindAgentButton from "../chat/chatbox/ChatInputPaper/FindAgentButton";

function GetStartedChatInner() {
  const { agent } = useAgentContext();
  return (
    <div className="flex align-i-center m-b-16px">
      <Typography variant="h7" className="m-r-8px">
        Or chat with {agent.name}
      </Typography>
      <FindAgentButton />
    </div>
  );
}

function GetStartedChat() {
  return (
    <>
      <GetStartedChatInner />
      <ChatInputPaper />
    </>
  );
}

export default GetStartedChat;
