import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0Client, createAuth0Client } from "@auth0/auth0-spa-js";
import React, { useEffect } from "react";
import { getFEUrl } from "../../utilities/formatters/strings/getFEUrl";

let auth0Client: Promise<Auth0Client> | null = null;

const initializeAuth0Client = async () => {
  if (!auth0Client) {
    auth0Client = createAuth0Client({
      domain: `${process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN}`,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || ""
    });
  }

  return auth0Client;
};

// Remember, if you are trying to
// sign someone up, you need to have
// ngrok on local running
const useInitializeAuth0Client = () => {
  useEffect(() => {
    initializeAuth0Client();
  }, []);
};

function InitializedAuthProvider({ children }: { children: React.ReactNode }) {
  useInitializeAuth0Client();

  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_CUSTOM_DOMAIN}`}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      authorizationParams={{
        redirect_uri: `${getFEUrl()}/callback`,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE
      }}
    >
      {children}
    </Auth0Provider>
  );
}

export default InitializedAuthProvider;
