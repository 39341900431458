import { FormControl, Grid, TextField } from "@mui/material";
import type { SerpBlockSettingsV1 } from "@toolflow/shared";
import React, { FC } from "react";
import useUpdateField from "../../useUpdateField";

interface IModelPaginationSettingsProps {
  id: string;
  settings: SerpBlockSettingsV1;
}
const ModelPaginationSettings: FC<IModelPaginationSettingsProps> = (props) => {
  const { id, settings } = props;
  const updateField = useUpdateField(id);
  return (
    <>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Results per page"
            type="number"
            variant="outlined"
            size="medium"
            value={settings.resultsPerPage}
            margin="dense"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateField(event.target.value, "settings.resultsPerPage");
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            label="Max pages per query"
            type="number"
            variant="outlined"
            size="medium"
            value={settings.maxPagesPerQuery}
            margin="dense"
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              updateField(event.target.value, "settings.maxPagesPerQuery");
            }}
          />
        </FormControl>
      </Grid>
    </>
  );
};

export default ModelPaginationSettings;
