import React from "react";
import { ButtonGroup, Typography } from "@mui/material";
import DeleteSelectedRowsButton from "./DeleteSelectedRowsButton";
import ClearSelectionButton from "./ClearSelectionButton";
import RunSelectedRowsButton from "./RunSelectedRowsButton";
import ExportSelectedRowsButton from "./ExportSelectedRowsButton";
import { useSheetContext } from "../SheetContext";
import { TMaterialRow, TMaterialTable } from "../sheetTypes";
import AddRowsSelect from "./AddRowsSelect";

export const BottomToolbarCustomActions = ({
  handleRunMultipleRows,
  table
}: {
  handleRunMultipleRows: (rows: TMaterialRow[]) => void;
  table: TMaterialTable;
}) => {
  const { sheetData } = useSheetContext();
  return (
    <div className="flex align-i-center">
      <AddRowsSelect />
      {table.getSelectedRowModel().rows.length !== 0 && (
        <>
          <Typography variant="subtitle1" className="m-l-16px">
            {table.getSelectedRowModel().rows.length} of {sheetData.length} row
            {table.getSelectedRowModel().rows.length === 1 ? "" : "s"} selected
          </Typography>
          <ButtonGroup className="m-l-16px" size="small" variant="outlined">
            <DeleteSelectedRowsButton table={table} />
            <ExportSelectedRowsButton table={table} />
            <ClearSelectionButton table={table} />
          </ButtonGroup>
          <RunSelectedRowsButton
            table={table}
            handleRunMultipleRows={handleRunMultipleRows}
          />
        </>
      )}
    </div>
  );
};
