import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import {
  WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL,
  WORKSPACE_ENTITY_ID_FIELD_LABEL,
  WORKSPACE_ENTITY_TYPE_FIELD_LABEL
} from "../../helpers/workspaceConstants";
import { useDispatch } from "react-redux";
import { setDisableTabChange } from "../../state/workspaceSlice";

export const useClearSelectedEntityBase = (disableSave = false) => {
  const dispatch = useDispatch();
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const clearSelectedEntity = () => {
    setValue(WORKSPACE_ENTITY_ID_FIELD_LABEL, "", {
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL, "", {
      // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(WORKSPACE_ENTITY_TYPE_FIELD_LABEL, "", {
      // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    dispatch(setDisableTabChange(false));
  };
  return clearSelectedEntity;
};
