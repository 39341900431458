import type { RowUserInputDictType } from "@toolflow/shared";
import type { MRT_Row } from "material-react-table";
import useSetRowLoading from "./useSetRowLoading";
import { useSheetContext } from "../../SheetContext";
import { TColumnMap } from "../../sheetTypes";
import useRunToolFromSheet from "./useRunToolFromSheet";

const useRunSingleTool = ({ columnMap }: { columnMap: TColumnMap }) => {
  const runToolFromSheet = useRunToolFromSheet({
    columnMap
  });
  const { selectedTool } = useSheetContext();
  const setRowLoading = useSetRowLoading();
  const runSingleTool = async (row: MRT_Row<RowUserInputDictType>) => {
    if (!selectedTool) {
      return;
    }
    setRowLoading(row, true);
    runToolFromSheet(row);
  };
  return runSingleTool;
};

export default useRunSingleTool;
