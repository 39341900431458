import { Extension, mergeAttributes, Node } from "@tiptap/core";
import { $TSAllowedAny } from "../../../types";
import { ExtensionConfig } from ".";
import { getPlainTextFromHtml } from "../../formatters";

function createExtensionFromConfigBackend(config: ExtensionConfig) {
  return Node.create({
    ...config.config,

    addAttributes() {
      return config.attributes;
    },

    renderHTML({
      HTMLAttributes
    }: {
      HTMLAttributes: Record<string, $TSAllowedAny>;
    }) {
      return [
        config.htmlTag,
        mergeAttributes(HTMLAttributes, { "data-type": "draggable-item" })
      ];
    },

    renderText({ node }) {
      return getPlainTextFromHtml(node.attrs.textValue || "") || "";
    }
  }) as Extension;
}

export default createExtensionFromConfigBackend;
