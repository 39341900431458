// import type { Auth0Client } from "@auth0/auth0-spa-js";
import { useAuth0 } from "@auth0/auth0-react";
import { useRef } from "react";

import axios, { isCancel } from "axios";
import type {
  AxiosResponse,
  AxiosProgressEvent,
  AxiosRequestConfig
} from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setErrorMessage } from "../../features/layout/alertMessageSnackbar/alertMessageSlice";
import { RootState } from "../../stores/store";
import { isLoginOrConsentRequiredError } from "../../features/auth/functions/isLoginOrConsentRequiredError";
import { useLoginBackToPage } from "../../features/auth/hooks/useLoginBackToPage";
import { getErrorMessage } from "../errors/getErrorMessage";
import { getBackendUrl } from "../../utilities/formatters/strings/getBackendUrl";

type AxiosOptions = Record<string, string | string[] | number>;

const useCancelableApiHandler = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const reduxDispatch = useDispatch();

  const apiHandler = async ({
    endpoint,
    method = "GET",
    data = null,
    headers,
    errorCallback,
    baseUrl,
    onUploadProgress,
    otherOptions = {}
  }: {
    endpoint: string;
    method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH";
    data?: $TSAllowedAny;
    headers?: Record<string, string | string[] | number>;
    errorCallback?: (e?: unknown) => void;
    baseUrl?: string;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    otherOptions?: AxiosOptions;
  }) => {
    abortControllerRef.current = new AbortController();
    const axiosOptions: AxiosRequestConfig = {
      url: `${baseUrl || getBackendUrl() || "https://api.toolflow.ai/"}${endpoint}`,
      method,
      data,
      headers,
      signal: abortControllerRef.current.signal,
      ...otherOptions
    };

    if (onUploadProgress) {
      axiosOptions.onUploadProgress = onUploadProgress;
    }

    try {
      let maxSize = 100 * 1024;
      // if a route has a larger maxBodyLength,
      // use that as the maxSize.
      // make sure the route in initParser has the appropriate size limit
      if (
        otherOptions?.maxBodyLength &&
        typeof otherOptions.maxBodyLength === "number"
      ) {
        maxSize = otherOptions.maxBodyLength;
      }

      function payloadSizeUnderMaxSize(payload: unknown) {
        const payloadSize = new Blob([JSON.stringify(payload)]).size;
        const payloadUnderMaxSize = payloadSize <= maxSize;
        if (!payloadUnderMaxSize) {
          console.log("payloadSize", payloadSize);
          throw new Error(
            "Payload too large, please reduce the size of your document"
          );
        }
      }
      payloadSizeUnderMaxSize(data);

      const response: AxiosResponse = await axios(axiosOptions);
      if (response.status === 204) {
        return null;
      }

      return response.data;
    } catch (error: unknown) {
      if (isCancel(error)) {
      } else {
        console.error("API request error:", error);
        const errorMessage = getErrorMessage(error);
        if (errorCallback) {
          errorCallback(error);
        } else {
          reduxDispatch(setErrorMessage(errorMessage));
        }
      }

      throw error;
    }
  };

  const cancel = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  return { apiHandler, cancel };
};

// We only use this for routes that need axios specific functionality, such as ability to check progress of file upload
const useApiHandlerWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { loginBackToPage } = useLoginBackToPage();
  const { apiHandler, cancel } = useCancelableApiHandler();
  const clientId = useSelector((state: RootState) => state.auth.clientId);

  const apiHandlerWithAuth = async ({
    endpoint,
    method = "GET",
    data = null,
    errorCallback,
    onUploadProgress,
    axiosOptions
  }: {
    endpoint: string;
    method: "GET" | "PUT" | "POST" | "DELETE" | "PATCH";
    data?: $TSAllowedAny;
    errorCallback?: (e?: unknown) => void;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    axiosOptions?: AxiosOptions;
  }) => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }
      });

      const authHeaders: Record<string, string> = {
        Authorization: `Bearer ${token}`
      };
      const allHeaders: Record<string, string> = {
        ...authHeaders,
        "x-client-id": clientId
      };

      const response = await apiHandler({
        endpoint,
        method,
        data,
        headers: allHeaders,
        errorCallback,
        onUploadProgress,
        otherOptions: axiosOptions
      });
      return response;
    } catch (error) {
      if (isLoginOrConsentRequiredError(error)) {
        loginBackToPage();
      } else {
        console.error("API request error:", error);
        throw error;
      }
    }
  };

  return { apiHandlerWithAuth, cancel };
};

export default useApiHandlerWithAuth;
