import React, { createContext, useContext } from "react";
import type {
  TMarketplaceUniversalEntity,
  TMarketplaceUniversalEntityType
} from "@toolflow/shared";

interface SearchToolsContextType {
  EntityElement: React.FC<{ entity: TMarketplaceUniversalEntity }>;
  entityType: TMarketplaceUniversalEntityType;
}

const SearchToolsContext = createContext<SearchToolsContextType | undefined>(
  undefined
);

export function SearchToolsContextComponent({
  children,
  EntityElement,
  entityType
}: Readonly<{
  children: React.ReactNode;
  EntityElement?: React.FC<{ entity: TMarketplaceUniversalEntity }>;
  entityType: TMarketplaceUniversalEntityType;
}>) {
  if (!EntityElement || !entityType) {
    return null;
  }
  return (
    <SearchToolsContext.Provider value={{ EntityElement, entityType }}>
      {children}
    </SearchToolsContext.Provider>
  );
}

export const useSearchToolsContext = () => {
  const context = useContext(SearchToolsContext);
  if (context === undefined) {
    throw new Error(
      "useSearchToolsContext must be used within a SearchToolsProvider"
    );
  }
  return context;
};

export default SearchToolsContext;
