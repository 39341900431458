import React from "react";
import SuggestedEntityCard from "../../../../entities/components/suggestedEntityCard/SuggestedEntityCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import useGetSuggestedEntities from "../entities/toolPaper/hooks/useGetSuggestedEntities";
import useFindToolButton from "../chat/chatbox/ChatInputPaper/hooks/useFindToolButton";

export function SuggestedEntityCards({ className }: { className?: string }) {
  const entities = useGetSuggestedEntities();
  const { selectTool } = useFindToolButton();
  return (
    <Grid2 container spacing={2} className={className}>
      {entities.map((entity) => (
        <Grid2 xs={4} key={entity.id}>
          <SuggestedEntityCard entity={entity} onClick={selectTool} />
        </Grid2>
      ))}
    </Grid2>
  );
}
