import { EditorView } from "@tiptap/pm/view";
import type { RichTextEditorRef } from "mui-tiptap";

const executeSlash = ({
  view,
  isLoadingRef,
  rteRef,
  setIsSlash,
  setShouldRenderBubble
}: {
  view: EditorView;
  isLoadingRef: React.MutableRefObject<boolean>;
  rteRef: React.RefObject<RichTextEditorRef>;
  setIsSlash: React.Dispatch<React.SetStateAction<boolean>>;
  setShouldRenderBubble: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // if it isLoading, we don't want to render it, because
  // the tool menu won't delete it and two slashed will appear
  // keep the slash in the doc when typing
  const { $from } = view.state.selection;
  const textBefore = $from.nodeBefore?.text ?? "";
  let hasHttpWord = false;
  const textBeforeArray = textBefore.split(" ");
  let latestWord = "";
  if (textBeforeArray.length > 1) {
    latestWord = textBeforeArray[textBeforeArray.length - 1];
  } else {
    hasHttpWord =
      textBefore.slice(-6).includes("http") ||
      textBefore.slice(-7).includes("https");
  }
  const isUrl =
    latestWord === "http:" ||
    latestWord === "https:" ||
    latestWord === "http:/" ||
    latestWord === "https:/" ||
    hasHttpWord;

  if (!isUrl) {
    if (!isLoadingRef.current) {
      rteRef.current?.editor?.commands.insertContent("/");
    }
    setIsSlash(true);
    setShouldRenderBubble(true);
  }
};

export default executeSlash;
