import React, { useEffect, useState } from "react";
import { useUpdateCurrentUserMutation } from "../../../../../../ToolflowAPI/rtkRoutes/userApi";
import type { SelectChangeEvent } from "@mui/material";
import type {
  CompanySize,
  EmployeeFunction,
  EmployeeResponsibility,
  UpdateUserBody,
  PopulatedUser
} from "@toolflow/shared";
import useGetCurrentUser from "../../../../../user/hooks/useGetCurrentUser";
import {
  DEFAULT_EMPLOYEE_COUNT,
  DEFAULT_FUNCTION,
  DEFAULT_RESPONSIBILITY
} from "../helpers/constants";
import { isEqual } from "lodash";

const usePersonalInformation = () => {
  const [updateUser, { isLoading }] = useUpdateCurrentUserMutation();
  const user = useGetCurrentUser();
  const [originalFields, setOriginalFields] = useState<UpdateUserBody>({}); // [TODO] - this is a hack to get around the fact that the user object is not updated when the user updates their profile. We need to fix this in the backend and then remove this hack
  const [updateFields, setUpdateFields] = useState<UpdateUserBody>({
    firstName: "",
    lastName: "",
    company: {
      name: "",
      employee_count: DEFAULT_EMPLOYEE_COUNT
    },
    employeeFunction: DEFAULT_FUNCTION,
    employeeResponsibility: DEFAULT_RESPONSIBILITY
  });

  const getOriginalUserFields = (u: PopulatedUser) => {
    return {
      firstName: u.firstName,
      lastName: u.lastName,
      employeeFunction: u.employeeFunction,
      employeeResponsibility: u.employeeResponsibility,
      company: {
        name: u.company?.name,
        employee_count: u.company?.employee_count
      }
    };
  };

  useEffect(() => {
    if (user) {
      const userFields = getOriginalUserFields(user);
      setUpdateFields(userFields);
      setOriginalFields(userFields);
    }
  }, [user]);

  const setVal =
    (type: "firstName" | "lastName" | "company.name") =>
    (e: React.ChangeEvent<{ value: string }>) => {
      if (type === "company.name") {
        setUpdateFields({
          ...updateFields,
          company: { ...updateFields.company, name: e.target.value }
        });
      } else {
        setUpdateFields({ ...updateFields, [type]: e.target.value });
      }
    };

  const setSelectVal =
    (
      type:
        | "company.employee_count"
        | "employeeFunction"
        | "employeeResponsibility"
    ) =>
    (
      e: SelectChangeEvent<
        CompanySize | EmployeeResponsibility | EmployeeFunction
      >
    ) => {
      if (type === "company.employee_count") {
        setUpdateFields({
          ...updateFields,
          company: {
            ...updateFields.company,
            employee_count: e.target.value as CompanySize
          }
        });
      } else {
        setUpdateFields({ ...updateFields, [type]: e.target.value });
      }
    };

  const handleSave = () => {
    updateUser({
      updateFields,
      successMessage: "Your profile has been saved"
    });
  };

  const resetForm = () => {
    setUpdateFields(originalFields);
  };

  return {
    updateFields,
    setVal,
    setSelectVal,
    handleSave,
    isLoading,
    isFormSubmissionDisabled:
      isLoading || isEqual(originalFields, updateFields),
    resetForm
  };
};

export default usePersonalInformation;
