import React from "react";
import useToolsTableData from "../hooks/useToolsTableData";
import ToolsPaginatedTable from "./ToolsPaginatedTable";
import TableSkeleton from "../layout/TableSkeleton";
import NoRecentData from "../NoRecentData";
import { useGetAllUserEntities } from "../../../entities/hooks/useGetAllUserEntities";

const ToolsDetailedTableContainer = () => {
  const { entities, loading } = useGetAllUserEntities();
  const { headCells, rows } = useToolsTableData(entities);
  if (loading) {
    return <TableSkeleton numOfRows={15} className="p-16px" />;
  }
  const isHomePageEntitiesEmpty = entities?.length === 0 && !loading;
  if (isHomePageEntitiesEmpty) {
    return (
      <NoRecentData
        header="Your recent tools will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <ToolsPaginatedTable rows={rows} headCells={headCells} />;
};

export default ToolsDetailedTableContainer;
