import type { PopulatedToolVersionResponseFE } from "@toolflow/shared";
import { useEditorToolCardContext } from "../EditorToolCardContext";

const useSetResponse = () => {
  const { setTVRId } = useEditorToolCardContext();
  const setResponse = (resp: PopulatedToolVersionResponseFE) => {
    setTVRId(resp._id);
  };
  return setResponse;
};

export default useSetResponse;
