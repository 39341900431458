import useGetDrawerOutputId from "../../../../store/hooks/useGetDrawerOutputId";
import OutputListWrapper from "./customToolOutputFields/OutputListWrapper";
import OutputDetails from "./OutputDetails";

const OutputListOrDetailsSplitter = () => {
  const outputId = useGetDrawerOutputId();
  if (outputId) return <OutputDetails outputId={outputId} />;

  return <OutputListWrapper />;
};

export default OutputListOrDetailsSplitter;
