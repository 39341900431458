import { createFilterOptions, type FilterOptionsState } from "@mui/material";
import {
  MarketplaceGroup,
  type MarketplaceEntityAutocomplete
} from "@toolflow/shared";

const filter = createFilterOptions<MarketplaceEntityAutocomplete>();

const getFilterToolOptions = (
  options: MarketplaceEntityAutocomplete[],
  params: FilterOptionsState<MarketplaceEntityAutocomplete>
) => {
  const filtered = filter(options, params);

  const filteredBasedOnUseCases = options.filter((option) =>
    option?.useCases?.join(" ").includes(params.inputValue)
  );
  const uniqueFiltered = Array.from(
    new Set([...filtered, ...filteredBasedOnUseCases])
  ).sort((a, b) => {
    const groupOrder = {
      [MarketplaceGroup.AGENT]: 1,
      [MarketplaceGroup.TOOL]: 2,
      [MarketplaceGroup.WORKFLOW]: 3,
      [MarketplaceGroup.USE_CASE]: 4
    };
    return (
      (groupOrder[a.group] || Infinity) - (groupOrder[b.group] || Infinity)
    );
  });
  return uniqueFiltered;
};

export default getFilterToolOptions;
