import React from "react";
import { Typography } from "@mui/material";

export const GetStartedHeader = ({ text }: { text: string }) => {
  return (
    <Typography variant="h7" className="m-b-16px">
      {text}
    </Typography>
  );
};
