import EditorMenuControls from "./EditorMenuControls";
import ToolflowSkeletonWrapper from "../../../../loading/ToolflowSkeleton";
import CustomBubbleMenu from "../customBubbleMenu.tsx/CustomBubbleMenu";

function ToolBubbleMenu() {
  return (
    <CustomBubbleMenu>
      {/* div necessary for anchorEl of dropdowns */}
      <div id="dontCloseOnClickId">
        <ToolflowSkeletonWrapper width={500} height={40}>
          <EditorMenuControls />
        </ToolflowSkeletonWrapper>
      </div>
    </CustomBubbleMenu>
  );
}

export default ToolBubbleMenu;
