import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function PoweredByToolflow({ className = "" }: { className?: string }) {
  return (
    <Link
      to="https://www.toolflow.ai"
      rel="noopener noreferrer"
      className={className}
    >
      <Button variant="text" className="bg-color-transparent">
        Powered by Toolflow
      </Button>
    </Link>
  );
}

export default PoweredByToolflow;
