import React, { createContext, useContext } from "react";
import type { CustomToolInputField } from "@toolflow/shared";

interface CustomToolInputFieldContextType {
  input: CustomToolInputField;
}

const CustomToolInputFieldContext = createContext<
  CustomToolInputFieldContextType | undefined
>(undefined);

export function CustomToolInputFieldContextComponent({
  children,
  customToolInputField
}: Readonly<{
  children: React.ReactNode;
  customToolInputField: CustomToolInputField;
}>) {
  return (
    <CustomToolInputFieldContext.Provider
      value={{ input: customToolInputField }}
    >
      {children}
    </CustomToolInputFieldContext.Provider>
  );
}

export const useCustomToolInputFieldContext = () => {
  const context = useContext(CustomToolInputFieldContext);
  if (context === undefined) {
    throw new Error(
      "useCustomToolInputFieldContext must be used within a CustomToolInputFieldProvider"
    );
  }
  return context;
};

export default CustomToolInputFieldContext;
