import pluralize from "../../../../../../utilities/formatters/strings/pluralize";
import { InputType } from "../InputOutputBase";
import getHasEntities from "./getHasEntities";

const entityText = (type: InputType, entityCount: number) =>
  `${entityCount} ${pluralize(type, entityCount)}`;

const noEntitiesText = (type: InputType) =>
  type === InputType.INPUT ? "Collect inputs" : "Choose outputs";

const getEntitiesText = (type: InputType, entityCount: number) => {
  if (getHasEntities(entityCount)) {
    return entityText(type, entityCount);
  } else {
    return noEntitiesText(type);
  }
};

export default getEntitiesText;
