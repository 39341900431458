import { Card } from "@mui/material";
import SaveOrUpdateSnippetButton from "./Buttons/SaveOrUpdateSnippetButton";
import SnippetField from "./SnippetField";
import SnippetFileField from "./SnippetFileField";
import SnippetFormProvider from "./SnippetFormProvider";
import SnippetNameTextField from "./SnippetNameTextField";
import SnippetValueTextField from "./SnippetValueTextField";

const SnippetFormCard = ({
  initialName,
  initialValue,
  className = "",
  initialStateIsDirty
}: {
  initialName?: string;
  initialValue?: string;
  className?: string;
  initialStateIsDirty?: boolean;
}) => {
  return (
    <SnippetFormProvider
      initialName={initialName}
      initialValue={initialValue}
      initialStateIsDirty={initialStateIsDirty}
    >
      <Card
        sx={{ pt: 4, pb: 4, pr: 5, pl: 5 }}
        variant="outlined"
        className={className}
      >
        <SnippetField
          title="Name"
          subheader="Choose a unique, descriptive name that captures the essence of the source."
          textField={<SnippetNameTextField />}
        />
        <SnippetField
          title="Content"
          subheader="Upload a file or type your content here. You can type it manually, copy it from another source or upload a document file."
          textField={
            <div className="m-t-8px">
              <SnippetFileField />
              <SnippetValueTextField />
            </div>
          }
        />
        <SaveOrUpdateSnippetButton />
      </Card>
    </SnippetFormProvider>
  );
};

export default SnippetFormCard;
