import { createContext, useContext } from "react";

interface BlockerContextType {
  resetBlocker: () => void;
  continueNavigating: () => void;
  isOpen: boolean;
}

export const BlockerContext = createContext<BlockerContextType | undefined>(
  undefined
);

export const useBlockerContext = (): BlockerContextType => {
  const context = useContext(BlockerContext);

  if (context === undefined) {
    throw new Error(
      "useBlockerContext must be used within a BlockerContextProvider"
    );
  }

  return context;
};
