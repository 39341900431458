import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { InputType, type BlockSettingsWithInputType } from "@toolflow/shared";
import { Fragment, ReactNode } from "react";
import useSelectWithInput from "./useSelectWithInput";
import { CREATE_USER_INPUT_LABEL } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import {
  SELECT,
  TEXTFIELD
} from "../../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import AddInputDialog from "../../../../addInput/AddInputDialog";

export default function SelectWithInput({
  id,
  label,
  inputKey,
  settings,
  userFieldKey,
  isArrayInput,
  inputTypeValue,
  selectId = "inputType",
  children
}: {
  id: string;
  label: string;
  settings: BlockSettingsWithInputType;
  inputTypeValue?: string;
  inputKey: string;
  userFieldKey?: string;
  isArrayInput: boolean;
  selectId?: string;
  children?: ReactNode;
}) {
  const inputType = settings.inputType || "";
  const {
    selectOptions,
    openInputModal,
    setOpenInputModal,
    updateInputType,
    updateFromAdd
  } = useSelectWithInput({
    id,
    inputKey,
    userFieldKey,
    hasTextInput: !!children,
    isArrayInput
  });

  return (
    <Fragment>
      <FormControl fullWidth margin="dense">
        <InputLabel id="user-select-input">{label}</InputLabel>
        <Select
          fullWidth
          labelId="user-select-input"
          id={selectId}
          label={label}
          value={inputTypeValue || inputType}
          className="nowheel nodrag nopan"
          onChange={(event) => updateInputType(event.target.value as InputType)}
        >
          <MenuItem
            value={InputType.UserInput}
            onClick={() => setOpenInputModal(true)}
          >
            {CREATE_USER_INPUT_LABEL}
          </MenuItem>
          {selectOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(inputType === InputType.FixedInput ||
        inputType === InputType.UserInput) && (
        <FormControl fullWidth margin="normal" sx={{ marginBottom: 0 }}>
          {children}
        </FormControl>
      )}

      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={updateFromAdd}
        initialType={TEXTFIELD}
        allowedFields={[TEXTFIELD, SELECT]}
      />
    </Fragment>
  );
}
