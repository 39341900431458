import React from "react";
import type { IUpdateTextFieldContainerProps } from "@toolflow/shared";
import { WORKSPACE_NAME_FIELD_LABEL } from "../helpers/workspaceConstants";
import UpdateTextFieldOnUnmount from "../../../../utilities/components/textFields/UpdateTextFieldOnUnmount";

const UpdateWorkspaceNameTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder,
  showErrors
}: IUpdateTextFieldContainerProps) => {
  return (
    <UpdateTextFieldOnUnmount
      size={size}
      label={label}
      margin={margin}
      placeholder={placeholder}
      fieldLabel={WORKSPACE_NAME_FIELD_LABEL}
      registerOptions={{ required: WORKSPACE_NAME_FIELD_LABEL }}
      showErrors={showErrors}
      className="bg-color-white"
    />
  );
};

export default UpdateWorkspaceNameTextField;
