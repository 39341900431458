import { useDispatch } from "react-redux";
import {
  DrawerEnum,
  setDrawer,
  setDrawerExpandedOpen
} from "../toolBuilderSlice";

const useOpenInputs = () => {
  const dispatch = useDispatch();
  const openInputs = () => {
    dispatch(setDrawer({ type: DrawerEnum.inputs, inputId: "" }));
    dispatch(setDrawerExpandedOpen(false));
  };
  return openInputs;
};

export default useOpenInputs;
