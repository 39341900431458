import { type ScraperBlockPropsV2 } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../../../useUpdateField";
import useScraperFieldsConfigs from "./useScraperFieldsConfigs";

function ScraperBlockInnerV2({
  data,
  id
}: Omit<ScraperBlockPropsV2, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const updateField = useUpdateField(id);
  const fields = useScraperFieldsConfigs(id);

  return (
    <div className="m-t-16px">
      <FieldsByType configs={{ data, fields, id, updateField }} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}

export default ScraperBlockInnerV2;
