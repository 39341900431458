import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { useRunAgentMutation } from "../../../../../../../../ToolflowAPI/rtkRoutes/agentApi";
import {
  setThreadMessagesLoading,
  updateMessagesWithNewUserMessage
} from "../../chatSlice";
import useSetThreadId from "../../hooks/useSetThreadId";
import useThreadRef from "./useThreadRef";
import type { JSONContent } from "@tiptap/core";
import { EMessageRole, EMessageStatus } from "@toolflow/shared";
import useSetAgentId from "../../hooks/useSetAgentId";

const useHandleRunAgent = (
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>
) => {
  const [runAgent] = useRunAgentMutation();
  const setThreadId = useSetThreadId();
  const setAgentId = useSetAgentId();
  const dispatch = useDispatch();
  const currentThreadIdRef = useThreadRef();
  const handleRunAgent = async ({
    threadId,
    message,
    agentId
  }: {
    threadId: string;
    message: string;
    agentId: string;
  }) => {
    const userMessageId = v4();
    dispatch(setThreadMessagesLoading(true));
    dispatch(
      updateMessagesWithNewUserMessage({
        id: userMessageId,
        message,
        role: EMessageRole.USER,
        settings: {
          status: EMessageStatus.COMPLETED,
          incompleteDetails: null,
          runId: null
        }
      })
    );
    setUserTextMessage({});
    await runAgent({
      threadId,
      message,
      agentId
    })
      .unwrap()
      .then((resp) => {
        if (currentThreadIdRef.current !== resp.result.dbThreadId) {
          setThreadId(resp.result.dbThreadId);
        }
        if (agentId !== resp.result.agentId) {
          setAgentId(resp.result.agentId);
        }
      });
  };
  return handleRunAgent;
};

export default useHandleRunAgent;
