import { Button, IconButton } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { AddIcon } from "../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import { useAuthStore } from "../../auth/hooks/useAuthStore";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import useResetWorkstation from "../../pages/workstation/hooks/useResetWorkstation";
import styles from "./sidebarButton.module.css";
import { selectSidebarOpen } from "./sidebarSlice";

const cx = parsedClassNames.bind(styles);

interface ISidebarActionButtonProps {
  className?: string;
}
const SidebarActionButton: FC<ISidebarActionButtonProps> = ({ className }) => {
  const { isAuthenticated } = useAuthStore();
  // get functionally from New Workstation button and navigate also
  // return null;
  const navigate = useToolflowNavigate();
  const resetWorkstation = useResetWorkstation();
  const open = useSelector(selectSidebarOpen);

  const newTool = (e: React.MouseEvent) => {
    resetWorkstation();
    navigate("/", e);
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div className={className}>
      {open ? (
        <Button
          variant="action"
          fullWidth
          startIcon={<AddIcon size={20} />}
          onClick={newTool}
        >
          New session
        </Button>
      ) : (
        <div className="flex justify-center align-center">
          <IconButton className={cx("sidebar-action-button")} onClick={newTool}>
            <AddIcon size={20} color="white" />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default SidebarActionButton;
