import { environmentToAssistantIdMap } from "@toolflow/shared";
import useGetCurrentChatAgentId from "./useGetCurrentAgentId";

const useGetCurrentChatAgentIdOrDefault = () => {
  const agentId = useGetCurrentChatAgentId();
  const defaultAgentId =
    environmentToAssistantIdMap[process.env.REACT_APP_ENVIRONMENT || ""];
  return {
    agentId: agentId || defaultAgentId || "",
    isDefault: !agentId && !!defaultAgentId
  };
};

export default useGetCurrentChatAgentIdOrDefault;
