import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL } from "../helpers/workspaceConstants";

const useGetSelectedWorkspaceToolComponentId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_ENTITY_COMPONENT_ID_FIELD_LABEL);
};

export default useGetSelectedWorkspaceToolComponentId;
