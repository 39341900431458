import { Button } from "@mui/material";
import { CopyableField, type CustomToolOutputField } from "@toolflow/shared";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { AddIcon } from "../../../../../../../../../globalTheme/icons/icons";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import { DrawerEnum, setDrawer } from "../../../../../store/toolBuilderSlice";

const useNoOutputFields = () => {
  const { state } = useToolbuilderContext();
  const { toolOutputOptions } = state.currentState;
  return (
    toolOutputOptions.inputs.length + toolOutputOptions.blocks.length === 0
  );
};

function AddOutputButton() {
  const { dispatch, state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  const noOutputFields = useNoOutputFields();
  const reduxDispatch = useDispatch();

  const handleAddToolOutputField = () => {
    const id = uuidv4();
    const updatedFields: CustomToolOutputField[] = [
      ...toolOutputFields,
      {
        name: "",
        type: CopyableField.LargeText,
        id
      }
    ];
    dispatch({
      type: "SET_TOOL_OUTPUT_FIELDS",
      toolOutputFields: updatedFields
    });
    reduxDispatch(
      setDrawer({
        type: DrawerEnum.outputs,
        outputId: id
      })
    );
  };
  return (
    <Button
      startIcon={<AddIcon size={20} />}
      variant="contained"
      onClick={handleAddToolOutputField}
      disabled={noOutputFields}
    >
      Choose outputs
    </Button>
  );
}

export default AddOutputButton;
