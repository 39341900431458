import { useEffect } from "react";
import { usePromptEditorContext } from "../context/PromptEditorContext";

const useSetMenuFocus = () => {
  const { textFieldRef, setMenuIndex, contextMenu } = usePromptEditorContext();
  useEffect(() => {
    // Delay refocusing because state update is async
    setTimeout(() => {
      if (textFieldRef.current && contextMenu) {
        textFieldRef?.current?.focus();
      }
    }, 0);
    if (!contextMenu) {
      setMenuIndex(0);
    }
  }, [contextMenu]);
};

export default useSetMenuFocus;
