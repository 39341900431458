import { EntityType } from "@toolflow/shared";
import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";

const useNavigateToWorkflowById = (workflowId = "") => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject({
    type: EntityType.WORKFLOW,
    id: workflowId
  });
  const handleNavigate = (e: React.MouseEvent) => {
    if (!workflowId) return;
    navigateToWorkspace(e);
  };
  return handleNavigate;
};

export default useNavigateToWorkflowById;
