import { useClearSelectedEntityBase } from "./useClearSelectedEntityBase";
import { useClearSelectedToolFields } from "./useClearSelectedToolFields";
import { useClearSelectedWorkflowFields } from "./useClearSelectedWorkflowFields";

export const useClearAnyEntity = (disableSave = false) => {
  const clearSelectedToolFields = useClearSelectedToolFields(disableSave);
  const clearSelectedWorkflowFields =
    useClearSelectedWorkflowFields(disableSave);
  const clearSelectedEntity = useClearSelectedEntityBase(disableSave);

  const clearAnyEntity = () => {
    clearSelectedToolFields();
    clearSelectedWorkflowFields();
    clearSelectedEntity();
  };

  return clearAnyEntity;
};
