import { IconButton } from "@mui/material";
import {
  CollapseIcon,
  ExpandIcon
} from "../../../../../../../../globalTheme/icons/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  setDialogOpen,
  setDrawerExpandedOpen
} from "../../../../store/toolBuilderSlice";
import { RootState } from "../../../../../../../../stores/store";

const ExpandDrawerDialogButton = () => {
  const dispatch = useDispatch();
  const dialogOpen = useSelector(
    (state: RootState) => state.toolbuilder.dialogOpen
  );

  const openExpanded = () => {
    if (dialogOpen) {
      dispatch(setDialogOpen(false));
    } else {
      dispatch(setDrawerExpandedOpen(true));
      dispatch(setDialogOpen(true));
    }
  };

  return (
    <>
      <IconButton onClick={openExpanded}>
        {dialogOpen ? <CollapseIcon size={24} /> : <ExpandIcon size={24} />}
      </IconButton>
    </>
  );
};

export default ExpandDrawerDialogButton;
