import { Content } from "@tiptap/core";
import { $TSAllowedAny } from "../../../../types";
import { ChipNodeComponents } from "../ChipNodeComponents";
import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";

type BaseConfig = {
  name: ChipNodeComponents;
  group: ExtensionGroup;
  content: ExtensionContent;
  inline?: boolean;
  draggable: boolean;
  selectable: boolean;
  atom: boolean;
};

export enum ExtensionGroup {
  Block = "block",
  Inline = "inline"
}

export enum ExtensionContent {
  Inline = "inline*",
  Block = "block*"
}

type BaseAttributes = {
  [key: string]: {
    default: $TSAllowedAny;
  };
};

export type ExtensionConfig = {
  config: BaseConfig;
  attributes: BaseAttributes;
  htmlTag: ChipNodeHtmlTags;
};

export type TContent = Content;
