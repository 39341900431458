import React from "react";
import { HeadCell, Order } from "./tableTypes";
import { TableHead } from "@mui/material";
import {
  StyledHeaderCell,
  StyledTableRow,
  StyledTableSortLabel
} from "../layout/TableLayouts";
import { toolflowTextDisabledColor } from "../../../../globalTheme/muiUtils/appTheme";
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon
} from "../../../../globalTheme/icons/icons";

interface HomePagePaginatedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: Order;
  orderBy: string;
  headCells: readonly HeadCell[];
}
function HomePagePaginatedTableHead(props: HomePagePaginatedTableHeadProps) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <StyledTableRow className="m-t-16px m-b-4px">
        {headCells.map((headCell) => (
          <StyledHeaderCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ width: headCell.width }}
          >
            {headCell.sortable ? (
              <StyledTableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={() =>
                  orderBy === headCell.id && order === "asc" ? (
                    <ArrowUpSmallIcon
                      size={16}
                      color={toolflowTextDisabledColor}
                    />
                  ) : (
                    <ArrowDownSmallIcon
                      size={16}
                      color={toolflowTextDisabledColor}
                    />
                  )
                }
              >
                {headCell.label}
              </StyledTableSortLabel>
            ) : (
              headCell.label
            )}
          </StyledHeaderCell>
        ))}
      </StyledTableRow>
    </TableHead>
  );
}

export default HomePagePaginatedTableHead;
