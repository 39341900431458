import React from "react";
import { AddIcon, SnippetIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import { type Content } from "@tiptap/core";
import { useDispatch } from "react-redux";
import useGetThreadContentAsMarkdown from "../../../utilities/formatters/strings/tiptap/hooks/useGetThreadContentAsMarkdown";
import { openSnippetFormDialog } from "../slice/snippetSlice";
import { Button } from "@mui/material";

const useSetOpenSnippet = () => {
  const dispatch = useDispatch();

  const setOpen =
    (markdownString = "") =>
    () => {
      dispatch(
        openSnippetFormDialog({
          initialName: "New Snippet",
          initialValue: markdownString
        })
      );
    };
  return setOpen;
};

const useAddSnippetFormDialogButton = (content: Content) => {
  const markdownString = useGetThreadContentAsMarkdown(content);
  const setOpen = useSetOpenSnippet();
  return setOpen(markdownString);
};

export const AddSnippetFromButton = () => {
  const setOpen = useSetOpenSnippet();
  return (
    <Button
      variant="text"
      className="bg-color-white grey"
      onClick={setOpen()}
      size="small"
      startIcon={<AddIcon size={14} />}
    >
      Create snippet
    </Button>
  );
};

const AddSnippetFromContentButton = ({ content }: { content: Content }) => {
  const setOpen = useAddSnippetFormDialogButton(content);
  if (!content) return null;
  return (
    <RemixButton text="Add as snippet" icon={SnippetIcon} onClick={setOpen} />
  );
};

export default AddSnippetFromContentButton;
