import React from "react";
import TermsOfService from "../../legal/TermsOfService";
import Privacy from "../../legal/Privacy";
import Guidelines from "../../legal/Guidelines";

function LoggedOutLinks({}) {
  return (
    <div className="flex align-i-center justify-center m-t-8px">
      <TermsOfService typographyClassName="off-black" />
      <Privacy typographyClassName="m-h-16px off-black" />
      <Guidelines typographyClassName="off-black" />
    </div>
  );
}

export default LoggedOutLinks;
