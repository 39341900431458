import React from "react";
import { useEntityContext } from "../../context/EntityContext";
import EditWorkflowButton from "../../../workflow/EditWorkflowButton";
import EditToolButton from "../../../tools/components/EditToolButton";
import { EntityType } from "@toolflow/shared";

export const EditEntityButton = ({ className }: { className?: string }) => {
  const { entity } = useEntityContext();

  if (entity.type === EntityType.TOOL) {
    return <EditToolButton toolId={entity.id} className={className} />;
  } else if (entity.type === EntityType.WORKFLOW) {
    return <EditWorkflowButton workflowId={entity.id} className={className} />;
  }
  return null;
};
