import { EntityType, type TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import {
  WORKSPACE_ENTITY_TYPE_FIELD_LABEL,
  WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL
} from "../../helpers/workspaceConstants";

export const useClearSelectedWorkflowFields = (disableSave = false) => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const clearSelectedWorkflowFields = () => {
    setValue(WORKSPACE_ENTITY_TYPE_FIELD_LABEL, EntityType.WORKFLOW, {
      // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
      shouldDirty: !disableSave,
      shouldTouch: !disableSave,
      shouldValidate: !disableSave
    });
    setValue(
      WORKSPACE_WORKFLOW_PROGRESS_FIELD_LABEL,
      {},
      {
        // we want each tool instance to have a unique component id so on recalls, it doesn't put all the assets on the workspace
        shouldDirty: !disableSave,
        shouldTouch: !disableSave,
        shouldValidate: !disableSave
      }
    );
  };
  return clearSelectedWorkflowFields;
};
