import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useEffect } from "react";
import { TOnDrop } from "../helpers/draggableConstants";

function useMonitor({
  onDrop,
  disabled
}: {
  onDrop: TOnDrop;
  disabled?: boolean;
}) {
  useEffect(() => {
    return monitorForElements({
      onDrop,
      canMonitor: () => !disabled
    });
  }, [onDrop]);
}

export default useMonitor;
