import { isArray } from "lodash";
import { useAssetContext } from "../../../../../../../../../utilities/contexts/AssetContext";
import useGetAssetId from "../../../../../../assets/useGetAssetId";
import useIsLastAsset from "../../../../../../assets/useIsLastAsset";
import useSetVisibleAsset from "../../../../../../assets/useSetVisibleAsset";
import useGetSubAssetId from "../../../../../../assets/useGetSubAssetId";
import type { Asset } from "@toolflow/shared";
import { useGetToolQuery } from "../../../../../../../../../ToolflowAPI/rtkRoutes/toolsApi";

const useSplitOutputs = (asset: Asset) => {
  const toolId = asset.data?.toolId;
  const { data } = useGetToolQuery(toolId || "", { skip: !toolId });
  const tool = data?.tool;
  const toolOutputField = tool?.main.toolOutputFields.find(
    (field) => field.id === asset.data?.outputId
  );
  return !!toolOutputField?.config?.splitOutputs;
};

const useAssetListItem = () => {
  const { asset } = useAssetContext();
  const splitOutputs = useSplitOutputs(asset);
  const { setVisibleAsset, setVisibleSubAsset } = useSetVisibleAsset();
  const selectedAsset = useGetAssetId();
  const selectedSubAsset = useGetSubAssetId();
  const isLastButton = useIsLastAsset(asset.id);

  const assetText = asset.label || asset.name;

  const configs = {
    splitOutputs,
    splittedOutputs: isArray(asset.value)
      ? asset.value.map((v, i) => `Result ${i + 1}`)
      : [assetText]
  };

  return {
    configs,
    isLastButton,
    selectedSubAsset,
    isSelected: selectedAsset === asset.id,
    loading: !!asset.loading,
    assetText: asset.label || asset.name,
    assetId: asset.id,
    assetType: asset.valueType,
    onClick: () => {
      setVisibleAsset(asset.id);
      setVisibleSubAsset("");
    },
    onSubAssetClick: (subAssetId: string) => {
      setVisibleAsset(asset.id);
      setVisibleSubAsset(subAssetId);
    }
  };
};

export default useAssetListItem;
