import { useGetToolQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import { ToolContextComponent } from "./ToolContext";
import React from "react";

function ToolContextComponentContainer({
  toolId,
  children
}: {
  toolId?: string;
  children: React.ReactNode;
}) {
  const { data } = useGetToolQuery(toolId || "", { skip: !toolId });
  if (!data?.tool) {
    return null;
  }
  return (
    <ToolContextComponent tool={data.tool}>{children}</ToolContextComponent>
  );
}

export default ToolContextComponentContainer;
