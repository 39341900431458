import type { TOpenAIMessageSettings } from "@toolflow/shared";
import { useGetThreadRunStepsByIdQuery } from "../../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import ToolContextComponentContainer from "../../../../../tools/contexts/ToolContextContainer";
import useGetCurrentChatThreadId from "./hooks/useGetCurrentChatThreadId";
import ToolDetails from "./ToolDetails";
import { useEffect, useState } from "react";
import LoadingToolDetails from "./LoadingToolDetails";
import useIsMessageLoading from "./SendThreadTextField/hooks/useIsMessageLoading";

const ToolRunDetails = ({ runId }: { runId?: string | null }) => {
  const currentThreadId = useGetCurrentChatThreadId();
  const isLoading = useIsMessageLoading();
  const { data } = useGetThreadRunStepsByIdQuery(
    {
      threadId: currentThreadId,
      runId: runId || ""
    },
    {
      skip: !runId || !currentThreadId || isLoading,
      refetchOnMountOrArgChange: true
    }
  );

  if (!data || !data.result || !data.success) return null;

  const runSteps = data.result.runSteps;

  if (runSteps.length === 0) return null;

  return (
    <>
      {runSteps.map((step) => (
        <>
          {step.step_details.transformedToolCalls.map((toolCall, idx) => (
            <ToolContextComponentContainer
              toolId={toolCall.toolId}
              key={`${toolCall.toolId}-${idx}`}
            >
              <ToolDetails inputs={toolCall.inputs} output={toolCall.outputs} />
            </ToolContextComponentContainer>
          ))}
        </>
      ))}
    </>
  );
};

const StepDetails = ({ settings }: { settings: TOpenAIMessageSettings }) => {
  const [toolCallsStatus, setToolCallsStatus] = useState<string[] | null>(
    settings?.toolCallsStatus || null
  );

  useEffect(() => {
    if (settings?.toolCallsStatus) {
      setToolCallsStatus((prev) => [
        ...(prev || []),
        ...(settings?.toolCallsStatus || [])
      ]);
    } else {
      setToolCallsStatus(null);
    }
  }, [settings?.toolCallsStatus]);

  if (!toolCallsStatus) return <ToolRunDetails runId={settings?.runId} />;

  return (
    <>
      {Object.values(toolCallsStatus).map((toolId, idx) => (
        <ToolContextComponentContainer toolId={toolId} key={idx}>
          <LoadingToolDetails />
        </ToolContextComponentContainer>
      ))}
    </>
  );
};

export default StepDetails;
