import { Divider } from "@mui/material";

function BackHeader({ button }: { button: React.ReactNode }) {
  return (
    <>
      <div className="p-v-8px p-h-16px">{button}</div>
      <Divider className="m-h-neg-8px" />
    </>
  );
}

export default BackHeader;
