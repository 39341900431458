import React from "react";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { ArrowUpIcon } from "../../../../../../../globalTheme/icons/icons";
import useGetAssetId from "../../../../assets/useGetAssetId";
import useGetCurrentAssetIndex from "../../../../assets/useGetAssetIndex";
import useSetVisibleAsset from "../../../../assets/useSetVisibleAsset";
import useWorkspaceAssets from "../../../../assets/useWorkspaceAssets";

function MoveUpAnAssetButton() {
  const inputs = useWorkspaceAssets();
  const selectedAsset = useGetAssetId();
  const assetIndex = useGetCurrentAssetIndex();

  const isFirstAsset = inputs[0].id === selectedAsset;

  const { setVisibleAsset } = useSetVisibleAsset();

  const setSelectedAssetUp = () => {
    setVisibleAsset(inputs[assetIndex - 1].id);
  };

  return (
    <RemixButton
      icon={ArrowUpIcon}
      onClick={setSelectedAssetUp}
      disabled={isFirstAsset}
      providedColor={!isFirstAsset ? "black" : undefined}
    />
  );
}

export default MoveUpAnAssetButton;
