import { useEditorToolCardContext } from "../EditorToolCardContext";

const useUpdatePercentCompleted = () => {
  const { percentCompleted, setPercentCompleted } = useEditorToolCardContext();

  const updatePercentCompleted = (percent: number) => {
    if (percentCompleted !== 100) {
      setPercentCompleted(percent);
    } else {
      setPercentCompleted(100);
    }
  };
  return updatePercentCompleted;
};

export default useUpdatePercentCompleted;
