import { Typography } from "@mui/material";
import React from "react";
import { AGENT, type TMarketplaceUniversalEntity } from "@toolflow/shared";
import ToggleFavorite from "../../../../utilities/components/toggleFavorite/ToggleFavorite";

const EntityCardHeader = ({
  entity,
  disableToggleFavorite
}: {
  entity: TMarketplaceUniversalEntity;
  disableToggleFavorite?: boolean;
}) => {
  return (
    <div className="flex justify-space-between align-i-center max-w-100-percent">
      <Typography variant="h6" noWrap>
        {entity.name}
      </Typography>
      {!disableToggleFavorite && entity.type !== AGENT && (
        <ToggleFavorite
          id={entity.id}
          type={entity.type}
          favorited={entity.userFavorited}
          creatorId={entity.userId}
          inMarketplace={entity.isFeatured}
        />
      )}
    </div>
  );
};

export default EntityCardHeader;
