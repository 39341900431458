import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton
} from "material-react-table";
import React from "react";
import RunAllRowsButton from "./RunAllRowsButton";
import ExportAllRowsButton from "./ExportAllRowsButton";
import { TMaterialRow, TMaterialTable } from "../sheetTypes";

const ToolbarInternalActions = ({
  table,
  handleRunMultipleRows
}: {
  table: TMaterialTable;
  handleRunMultipleRows: (rows: TMaterialRow[]) => void;
}) => {
  return (
    <>
      <RunAllRowsButton
        handleRunMultipleRows={handleRunMultipleRows}
        table={table}
      />
      {/* <MRT_ToggleGlobalFilterButton table={table} /> */}
      <ExportAllRowsButton table={table} />
      <MRT_ShowHideColumnsButton table={table} />
      {/* <MRT_ToggleFiltersButton table={table} /> */}
      <MRT_ToggleFullScreenButton table={table} />
    </>
  );
};

export default ToolbarInternalActions;
