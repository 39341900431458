import React from "react";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import UtilityDragHandle from "../../../../../../../../../utilities/components/draggables/UtilityDragHandle";

function ToolInputFieldDragHandle({ className }: { className?: string }) {
  const { toolInputFields } = useToolInputFieldsContext();
  return (
    <UtilityDragHandle
      disabled={toolInputFields.length <= 1}
      className={className}
    />
  );
}

export default ToolInputFieldDragHandle;
