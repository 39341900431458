import { useToolInputFieldsContext } from "../inputBuilders/contexts/ToolInputFieldsContext";
import InputList from "../inputBuilders/InputList";
import { INPUT_TEXT } from "../inputBuilders/helpers/constants";
import InputOutputListWrapper from "./InputOutputListWrapper";
import AddInputButton from "../inputBuilders/AddInputButton";

const InputListWrapper = () => {
  const { toolInputFields } = useToolInputFieldsContext();
  return (
    <InputOutputListWrapper
      rightSlot={<AddInputButton />}
      list={<InputList toolInputFields={toolInputFields} />}
      title="Inputs"
      secondaryText={INPUT_TEXT}
    />
  );
};

export default InputListWrapper;
