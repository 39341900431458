import React, { useState } from "react";
import { useSettingsDropdownContext } from "../../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import SettingsMenuItem from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import AssignCategoriesDialog from "../../../../../tools/components/editorToolCard/toolCategorization/AssignCategoriesDialog";
import AssignCategoriesFromToolBuilder from "../../toolCategorization/AssignCategoriesFromToolBuilder";
import AssignCategoriesToolContainer from "../../../../../tools/components/editorToolCard/toolCategorization/AssignCategoriesToolContainer";
import { InfoIcon } from "../../../../../../globalTheme/icons/icons";

function AddToolCategoriesMenuItem({
  toolId,
  toolBuilder
}: Readonly<{
  toolId: string;
  toolBuilder?: boolean;
}>) {
  const { handleClose } = useSettingsDropdownContext();

  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };

  return (
    <>
      <SettingsMenuItem
        text={`Assign categories`}
        hotkey="Meta+I"
        icon={<InfoIcon fontSize="small" />}
        action={handleOpen}
      />
      <AssignCategoriesDialog
        title="Assign categories"
        onClose={close}
        open={open}
      >
        {toolBuilder ? (
          <>
            <AssignCategoriesFromToolBuilder />
          </>
        ) : (
          <>
            <AssignCategoriesToolContainer
              toolId={toolId}
              key={`${toolId}-categories`}
            />
          </>
        )}
      </AssignCategoriesDialog>
    </>
  );
}

export default AddToolCategoriesMenuItem;
