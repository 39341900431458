import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import React, { useEffect, useState } from "react";
import AccordionWrapper from "../../../../../../../utilities/components/accordions/AccordionWrapper";

import type { SelectChangeEvent } from "@mui/material/Select";
import {
  OpenAiImageModel,
  type DallE2OptimizationsType,
  type DallE3OptimizationsType,
  type DallEOptimizationsType,
  type UpdateArrayItem
} from "@toolflow/shared";
import { isEqual } from "lodash";
import {
  dallE2InitialOptimizations,
  dallE3InitialOptimizations
} from "../../../context/initialStates";
import useUpdateField, { useBatchUpdateField } from "../../../useUpdateField";

function DallE3Optimizations({
  id,
  optimizations
}: {
  id: string;
  optimizations: DallE3OptimizationsType;
}) {
  const updateField = useUpdateField(id);

  const [prevOptimizations, setPrevOptimizations] = useState(optimizations);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(optimizations, prevOptimizations)) {
      // ToolInputFields have changed, trigger re-render
      // Update the previous toolInputFields value
      setPrevOptimizations(optimizations);
    }
  }, [optimizations]);

  const { style, quality, size } = optimizations;

  const updateStateValue =
    (kString: keyof DallE3OptimizationsType) =>
    (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `optimizations.${kString}`);
    };

  return (
    <>
      <Grid xs={12}>
        <FormControl margin="normal" fullWidth>
          <InputLabel id="size-label">Size</InputLabel>
          <Select
            labelId="size-label"
            id="size"
            value={size}
            fullWidth
            label="Size"
            onChange={updateStateValue("size")}
            className="capitalize nowheel nodrag nopan"
          >
            {["1024x1024", "1792x1024", "1024x1792"].map((s) => (
              <MenuItem value={s} key={s} className="capitalize">
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel id="quality-label">Quality</InputLabel>
          <Select
            labelId="quality-label"
            id="quality"
            value={quality}
            fullWidth
            label="Quality"
            onChange={updateStateValue("quality")}
            className="capitalize nowheel nodrag nopan"
          >
            {["standard", "hd"].map((s) => (
              <MenuItem value={s} key={s} className="capitalize">
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel id="style-label">Style</InputLabel>
          <Select
            labelId="style-label"
            id="style"
            value={style}
            fullWidth
            label="Style"
            onChange={updateStateValue("style")}
            className="capitalize nowheel nodrag nopan"
          >
            {["vivid", "natural"].map((s) => (
              <MenuItem value={s} key={s} className="capitalize">
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
}

function DallE2Optimizations({
  id,
  optimizations
}: {
  id: string;
  optimizations: DallE2OptimizationsType;
}) {
  const updateField = useUpdateField(id);

  const [prevOptimizations, setPrevOptimizations] = useState(optimizations);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(optimizations, prevOptimizations)) {
      // ToolInputFields have changed, trigger re-render
      // Update the previous toolInputFields value
      setPrevOptimizations(optimizations);
    }
  }, [optimizations]);

  const { size, n } = optimizations;

  const handleImageNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateField(parseInt(event.target.value), "optimizations.n");
  };

  const updateStateValue =
    (kString: keyof DallEOptimizationsType) =>
    (event: SelectChangeEvent<string>) => {
      updateField(event.target.value, `optimizations.${kString}`);
    };

  return (
    <>
      <Grid xs={6}>
        <FormControl margin="normal" fullWidth>
          <InputLabel id="size-label">Size</InputLabel>
          <Select
            labelId="size-label"
            id="size"
            value={size}
            fullWidth
            label="Size"
            onChange={updateStateValue("size")}
            className="capitalize nowheel nodrag nopan"
          >
            {["256x256", "512x512", "1024x1024"].map((s) => (
              <MenuItem value={s} key={s} className="capitalize">
                {s}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid xs={6}>
        <TextField
          fullWidth
          label="Count"
          variant="outlined"
          margin="normal"
          value={n}
          onChange={handleImageNumber}
          inputProps={{ type: "number", min: 1, max: 10 }}
        />
      </Grid>
    </>
  );
}

function DallEOptimizations({
  id,
  optimizations
}: {
  id: string;
  optimizations: DallEOptimizationsType;
}) {
  const batchUpdateField = useBatchUpdateField(id);

  const [prevOptimizations, setPrevOptimizations] = useState(optimizations);
  useEffect(() => {
    // Perform deep equality check
    if (!isEqual(optimizations, prevOptimizations)) {
      // ToolInputFields have changed, trigger re-render
      // Update the previous toolInputFields value
      setPrevOptimizations(optimizations);
    }
  }, [optimizations]);

  const { model } = optimizations;

  const updateModelValue = (event: SelectChangeEvent<string>) => {
    const newModel = event.target.value;
    console.log(newModel);
    let updateFields: UpdateArrayItem[] = [
      {
        updateValue: event.target.value,
        dataProperty: "optimizations.model"
      }
    ];
    if (newModel === OpenAiImageModel.DALLE2) {
      updateFields = updateFields.concat([
        {
          updateValue: undefined,
          dataProperty: "optimizations.style"
        },
        {
          updateValue: undefined,
          dataProperty: "optimizations.quality"
        },
        {
          updateValue: dallE2InitialOptimizations.size,
          dataProperty: "optimizations.size"
        }
      ]);
    }
    if (newModel === "dall-e-3") {
      updateFields = updateFields.concat([
        {
          updateValue: dallE3InitialOptimizations.n,
          dataProperty: "optimizations.n"
        },
        {
          updateValue: dallE3InitialOptimizations.size,
          dataProperty: "optimizations.size"
        },
        {
          updateValue: dallE3InitialOptimizations.style,
          dataProperty: "optimizations.style"
        },
        {
          updateValue: dallE3InitialOptimizations.quality,
          dataProperty: "optimizations.quality"
        }
      ]);
    }
    batchUpdateField(updateFields);
  };

  return (
    <>
      <AccordionWrapper title="Settings" elevation={0}>
        <Grid container xs={12} spacing={2}>
          <Grid xs={12}>
            <FormControl margin="normal" fullWidth>
              <InputLabel id="model-label">Model</InputLabel>
              <Select
                labelId="model-label"
                id="model"
                value={model}
                fullWidth
                label="Model"
                onChange={updateModelValue}
                className="capitalize nowheel nodrag nopan"
              >
                {[OpenAiImageModel.DALLE2, OpenAiImageModel.DALLE3].map((s) => (
                  <MenuItem value={s} key={s} className="capitalize">
                    {s}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {model === OpenAiImageModel.DALLE3 && (
            <DallE3Optimizations id={id} optimizations={optimizations} />
          )}
          {model === OpenAiImageModel.DALLE2 && (
            <DallE2Optimizations id={id} optimizations={optimizations} />
          )}
        </Grid>
      </AccordionWrapper>
    </>
  );
}

export default DallEOptimizations;
