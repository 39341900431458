import type { BlockNode, TBlock } from "@toolflow/shared";

const isRunnableBlock = (
  block?: TBlock | null
): block is TBlock & { type: BlockNode } => {
  if (block == null || typeof block !== "object" || !("type" in block)) {
    return false;
  }
  const nonRunnableTypes = [
    "inputBlockNode",
    "outputBlockNode",
    "parentBlockNode"
  ];
  return !nonRunnableTypes.includes(block.type);
};

export default isRunnableBlock;
