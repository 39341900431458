import type { RowUserInputDictType, TSelectedTool } from "@toolflow/shared";
import { createContext, useContext } from "react";
import { TSelectedCell } from "./sheetTypes";
import type { MRT_TableInstance } from "material-react-table";

interface SheetContextType {
  selectedTool: TSelectedTool;
  setSelectedTool: (t: string | null) => void;
  sheetData: RowUserInputDictType[];
  setSheetData: React.Dispatch<React.SetStateAction<RowUserInputDictType[]>>;
  selectedCell: TSelectedCell;
  setSelectedCell: React.Dispatch<React.SetStateAction<TSelectedCell>>;
  tableInstanceRef: React.MutableRefObject<MRT_TableInstance<RowUserInputDictType> | null>;
  sheetDataRef: React.MutableRefObject<RowUserInputDictType[]>;
  rowsRunning: boolean;
  setRowsRunning: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SheetContext = createContext<SheetContextType | undefined>(
  undefined
);

export const useSheetContext = () => {
  const context = useContext(SheetContext);
  if (context === undefined) {
    throw new Error("useSheetContext must be used within a SheetProvider");
  }
  return context;
};
