import { useEffect } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";

// this helps control whether we show the menu or not
const useSetIsSlashWhenBubble = () => {
  const { isSlash, setIsSlash, shouldRenderBubble } = useMuiTipTapContext();
  useEffect(() => {
    if (shouldRenderBubble) {
      setIsSlash(isSlash);
    }
    return () => setIsSlash(false);
  }, [shouldRenderBubble]);
};

export default useSetIsSlashWhenBubble;
