import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeWorkflowSaveDialog } from "../../workflowBuilderSlice";
import { useBlockerContext } from "../../../../../navigation/contexts/BlockerContext";
import useWorkflowReset from "./useWorkflowReset";

const useHandleWorkflowSaveSuccess = (hasSaved: boolean) => {
  const dispatch = useDispatch();
  const { continueNavigating } = useBlockerContext();
  const resetWorkflow = useWorkflowReset();
  useEffect(() => {
    if (hasSaved) {
      resetWorkflow();
      // if the save was successful and there is a blocker to navigate, then navigate to the new location
      // if the save was successful and there is no blocker to navigate, then continueNavigating won't do anything
      continueNavigating();
      dispatch(closeWorkflowSaveDialog());
    }
  }, [hasSaved]);
};

export default useHandleWorkflowSaveSuccess;
