import type { DallEOutputType } from "@toolflow/shared";

function isOpenAIImageArray(input: $TSFixMe): input is DallEOutputType {
  return (
    Array.isArray(input) &&
    input.every(
      (item) =>
        typeof item === "object" &&
        item !== null &&
        typeof item.url === "string"
    )
  );
}

export default isOpenAIImageArray;
