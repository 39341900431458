import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

const DeleteBlockConfirmationDialog = () => {
  const { reactflowUtility } = useToolbuilderContext();

  const {
    isConfirmationDialogOpen: open,
    handleClose,
    handleConfirm
  } = reactflowUtility;
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete Block?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this block? This action cannot be
          undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} variant="contained" autoFocus>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBlockConfirmationDialog;
