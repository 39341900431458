import { useBlocker } from "react-router-dom";
import type { TWorkflowBuilderForm } from "@toolflow/shared";
import useFormIsDirty from "../../../../../utilities/hooks/useIsDirty";

const useWorkflowBlocker = () => {
  const { isDirtyFunc } = useFormIsDirty<TWorkflowBuilderForm>();

  const blocker = useBlocker(isDirtyFunc);

  return blocker;
};

export default useWorkflowBlocker;
