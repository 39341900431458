import { DateTime } from "luxon";

export function getTimeAgoString(timestamp: number): string {
  const time = DateTime.fromMillis(timestamp * 1000);
  const now = DateTime.now();
  const diff = now
    .diff(time, ["years", "months", "days", "hours", "minutes", "seconds"])
    .toObject();

  if (diff.years && diff.years >= 1) {
    return `${Math.floor(diff.years)} year${
      Math.floor(diff.years) > 1 ? "s" : ""
    } ago`;
  } else if (diff.months && diff.months >= 1) {
    return `${Math.floor(diff.months)} month${
      Math.floor(diff.months) > 1 ? "s" : ""
    } ago`;
  } else if (diff.days && diff.days >= 1) {
    return `${Math.floor(diff.days)} day${
      Math.floor(diff.days) > 1 ? "s" : ""
    } ago`;
  } else if (diff.hours && diff.hours >= 1) {
    return `${Math.floor(diff.hours)} hour${
      Math.floor(diff.hours) > 1 ? "s" : ""
    } ago`;
  } else if (diff.minutes && diff.minutes >= 1) {
    return `${Math.floor(diff.minutes)} minute${
      Math.floor(diff.minutes) > 1 ? "s" : ""
    } ago`;
  } else {
    return `${Math.floor(diff.seconds || 0)} second${
      Math.floor(diff.seconds || 0) > 1 ? "s" : ""
    } ago`;
  }
}
