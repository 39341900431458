import { AudioData, FileData } from "./fileTypes";

export function isFileOrAudioData(
  input: unknown
): input is FileData | AudioData {
  if (typeof input === "object" && input !== null) {
    return "fileName" in input;
  }
  return false;
}
