import React from "react";
import { ThemeProvider } from "@mui/material";
import homePageTheme from "./theme/homePageTheme";
import AuthenticationSplitter from "../../auth/AuthenticationSplitter";
import HomePageInner from "./HomePageInner";
// import HomePageLoading from "./HomePageLoading";
import LoginButton from "../../auth/LoginButton";
import HomePageLoading from "./homePageLoading/HomePageLoading";

const HomePage = () => {
  return (
    <ThemeProvider theme={homePageTheme}>
      <AuthenticationSplitter
        loadingComponent={<HomePageLoading />}
        authenticatedComponent={<HomePageInner />}
        externalComponent={
          <HomePageInner
            action={
              <div className="flex align-i-center">
                <LoginButton variant="outlined" />
              </div>
            }
          />
        }
      />
    </ThemeProvider>
  );
};

export default HomePage;
