import type { TMarketplaceUniversalEntity } from "@toolflow/shared";
import useGetAvailableAgents from "../../../../../features/tools/hooks/useGetAvailableAgents";

const useGetAgentSelectorOptions = () => {
  const availableAgents = useGetAvailableAgents();

  const options: Array<TMarketplaceUniversalEntity> = availableAgents.filter(
    (
      (seenIds) => (item: TMarketplaceUniversalEntity) =>
        !seenIds.has(item.id) && seenIds.add(item.id)
    )(new Set<string>())
  );
  return options;
};

export default useGetAgentSelectorOptions;
