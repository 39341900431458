import type { ThemeOptions } from "@mui/material";
import { toolflowPrimaryColor } from "./appTheme";

const palette: ThemeOptions["palette"] = {
  mode: "light",
  primary: {
    main: toolflowPrimaryColor
  },
  secondary: {
    main: "#000"
  },
  background: {
    paper: "#FAF8F7"
  }
};

export default palette;
