import { useTheme, useMediaQuery } from "@mui/material";
import { useMemo } from "react";

/**
 * A hook for providing a default set of useful extensions for the MUI-Tiptap
 * editor.
 */
export const usePlaceholder = (placeholder?: string) => {
  const theme = useTheme();
  // this is directionally correct. Hard to deal with the css psuedo selectors
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const size = isMediumScreen ? 20 : 40;
  return useMemo(() => {
    return `${placeholder?.substring(0, size)}${(placeholder?.length || 0) > size ? "..." : ""}`;
  }, [placeholder, size]);
};
