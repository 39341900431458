import React from "react";
import CircularLoading from "../../../../utilities/components/loading/CircularLoading";

// To update later
const WorkflowBuilderPageLoading = () => {
  return (
    <div className="flex align-i-center justify-center h-100-percent">
      <CircularLoading />
    </div>
  );
};

export default WorkflowBuilderPageLoading;
