import { Grid, Typography, type TypographyProps } from "@mui/material";
import { ReactNode } from "react";
import styles from "./groupAccordionHeader.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

export interface IGroupAccordionHeaderProps {
  titleVariant?: TypographyProps["variant"];
  titleClassName?: string;
  title: string;
  initialValue?: boolean;
  subtitle?: string;
  className?: string;
}

interface IGroupAccordionHeader extends IGroupAccordionHeaderProps {
  onClick?: () => void;
  actionIcon: ReactNode;
  className?: string;
}

export function GroupAccordionHeader({
  titleVariant = "h8",
  titleClassName,
  title,
  subtitle,
  onClick = () => {},
  actionIcon,
  className = ""
}: IGroupAccordionHeader) {
  return (
    <Grid
      container
      className={cx(className, styles["group-accordion-header"])}
      onClick={onClick}
    >
      <Grid item xs={11}>
        <Typography variant={titleVariant} className={titleClassName}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </Grid>
      <Grid item xs={1} className="flex justify-center align-i-center">
        {actionIcon}
      </Grid>
    </Grid>
  );
}
