import { get as getValueFromObject } from "lodash";
import { JSONValue } from "../../types";

export function getNestedValues(json: JSONValue, nestedKey: string) {
  const hasArray = nestedKey.includes("[].");

  if (hasArray) {
    const keyParts = nestedKey.split("[].");
    const arr = getValueFromObject(json, keyParts[0]) as JSONValue[];
    return arr.map((a) => getValueFromObject(a, keyParts[1]));
  } else {
    return getValueFromObject(json, nestedKey);
  }
}
