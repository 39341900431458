import React from "react";
import CopyThreadText from "./CopyThreadText";
import { type Content } from "@tiptap/core";
import styles from "./threadActions.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import AddThreadAsAssetButton from "./AddThreadAsAssetButton";
import AddSnippetFromContentButton from "../../../../../../snippets/SnippetForm/AddSnippetFromContentButton";

const cx = parsedClassNames.bind(styles);

function ThreadActions({
  content,
  className = ""
}: {
  content: Content;
  className?: string;
}) {
  return (
    <div className={cx(className, styles.wrapper, "m-l-auto")}>
      <CopyThreadText content={content} />
      <AddThreadAsAssetButton content={content} />
      <AddSnippetFromContentButton content={content} />
    </div>
  );
}

export default ThreadActions;
