import { Duration } from "luxon";

export function formatDuration(duration: Duration) {
  // Rounding off the seconds
  const seconds = Math.round(duration.seconds);

  // You can use the toFormat function to format the duration
  // This will display years, months, days, hours, minutes, and seconds, but only if their value is greater than 0
  let durationStr = "";
  if (duration.years > 0) {
    durationStr += `${duration.years} year${duration.years === 1 ? "" : "s"}, `;
  }
  if (duration.months > 0) {
    durationStr += `${duration.months} month${
      duration.months === 1 ? "" : "s"
    }, `;
  }
  if (duration.days > 0) {
    durationStr += `${duration.days} day${duration.days === 1 ? "" : "s"}, `;
  }
  if (duration.hours > 0) {
    durationStr += `${duration.hours} hour${duration.hours === 1 ? "" : "s"}, `;
  }
  if (duration.minutes > 0) {
    durationStr += `${duration.minutes} minute${
      duration.minutes === 1 ? "" : "s"
    }, `;
  }
  if (seconds > 0) {
    durationStr += `${seconds} second${seconds === 1 ? "" : "s"}, `;
  }

  // Remove the trailing comma and space
  durationStr = durationStr.trimEnd().slice(0, -1);

  return durationStr;
}
