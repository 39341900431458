import React from "react";
import useHideResetTool from "../../hooks/useHideResetTool";
import SettingsMenuItemWithClose from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { ResetIcon } from "../../../../../../globalTheme/icons/icons";

function ResetToolMenuItem() {
  const { dispatch } = useToolbuilderContext();
  const hideReset = useHideResetTool();

  const handleReset = () => {
    dispatch({ type: "RESET_TOOL" });
  };

  return (
    <SettingsMenuItemWithClose
      action={handleReset}
      text="Reset tool"
      hotkey="Meta+Shift+R"
      icon={<ResetIcon fontSize="small" />}
      hide={hideReset}
    />
  );
}

export default ResetToolMenuItem;
