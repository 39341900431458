import React from "react";
import LinkTextButton from "../../utilities/components/buttons/LinkTextButton";

export const TERMS_OF_SERVICES_URL =
  "https://sour-track-509.notion.site/Terms-of-Service-19651bf73cc842839e27901ed72421db";

function TermsOfService({
  typographyClassName = ""
}: {
  typographyClassName?: string;
}) {
  return (
    <LinkTextButton
      to={TERMS_OF_SERVICES_URL}
      text="Terms"
      typographyClassName={typographyClassName}
    />
  );
}

export default TermsOfService;
