import { useGetCurrentUserQuery } from "../../../ToolflowAPI/rtkRoutes/userApi";
import { useAuthStore } from "../../auth/hooks/useAuthStore";

const useGetCurrentUser = () => {
  const { isAuthenticated, isLoading } = useAuthStore();
  const { user } = useGetCurrentUserQuery(undefined, {
    skip: !isAuthenticated || isLoading,
    selectFromResult: ({ data }) => {
      return {
        user: data?.user ?? null
      };
    }
  });
  return user;
};

export default useGetCurrentUser;
