import React from "react";
import ToolflowLogoName from "../../../../../../globalTheme/icons/logos/toolflowLogo/ToolflowLogoName";
import ResetThreadButton from "./ResetThreadButton";
import styles from "./chatHeader.module.css";

import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import { useAgentContext } from "../../../../../agents/contexts/AgentContext";
import { Typography } from "@mui/material";
import FindAgentButton from "../chatbox/ChatInputPaper/FindAgentButton";

const cx = parsedClassNames.bind(styles);

function ChatName() {
  const { isDefault, agent } = useAgentContext();

  if (isDefault) {
    return <ToolflowLogoName height={20} />;
  }

  return <Typography variant="h7">{agent.name}</Typography>;
}

function ChatHeader() {
  return (
    <div
      className={cx(
        "flex justify-space-between p-16px align-i-center",
        styles.header
      )}
    >
      <ChatName />
      <div className="flex align-i-center">
        <FindAgentButton name="Select agent" className="m-r-8px" />
        <ResetThreadButton />
      </div>
    </div>
  );
}

export default ChatHeader;
