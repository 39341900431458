import React from "react";
import Box from "@mui/material/Box";
import type {
  UserInputDictType,
  CustomToolInputField,
  TSetFormState,
  TAddChipConfig
} from "@toolflow/shared";
import { InputTextField } from "./editorToolCard/inputs/InputTextField";
import { InputCheckbox } from "./editorToolCard/inputs/InputCheckbox";
import { InputSelect } from "./editorToolCard/inputs/InputSelect";
import { InputMultiSelect } from "./editorToolCard/inputs/InputMultiSelect";
import { InputFileUpload } from "./editorToolCard/inputs/InputFileUpload";
import { InputTags } from "./editorToolCard/inputs/InputsTags";
import { InputDoubleTextField } from "./editorToolCard/inputs/InputDoubleTextField";
import useInputChipsToUse from "../../../utilities/components/textFields/tipTapTextField/hooks/useInputChipsToUse";

type TBaseProps = {
  toolInputField: CustomToolInputField;
  setFormState: TSetFormState;
  formState: UserInputDictType;
  useIds?: boolean;
};
type TExtraProps = {
  autofocus?: boolean;
  defaultFormState?: UserInputDictType;
  chipsToUse: TAddChipConfig;
  defaultExpanded?: boolean;
};

interface InputComponentConfig {
  component: React.ElementType;
  propFilter?: (
    baseProps: TBaseProps,
    extraProps: TExtraProps
  ) => TBaseProps & Partial<TExtraProps>; // Optional, for additional props
}

// Base props that are common across all components
export const baseProps = ({
  toolInputField,
  setFormState,
  formState,
  useIds
}: TBaseProps) => ({
  toolInputField,
  setFormState,
  formState,
  useIds
});

export const inputComponentMap: { [key: string]: InputComponentConfig } = {
  textField: {
    component: InputTextField,
    propFilter: (
      bProps,
      { defaultExpanded, defaultFormState, chipsToUse }
    ) => ({
      ...bProps,
      defaultExpanded,
      defaultFormState,
      chipsToUse
    })
  },
  largeTextField: {
    component: InputTextField,
    propFilter: (
      bProps,
      { defaultExpanded, defaultFormState, chipsToUse }
    ) => ({
      ...bProps,
      defaultExpanded,
      defaultFormState,
      chipsToUse
    })
  },
  checkbox: {
    component: InputCheckbox,
    propFilter: (bProps) => ({ ...bProps })
  },
  select: {
    component: InputSelect,
    propFilter: (bProps, { autofocus }) => ({
      ...bProps,
      autofocus
    })
  },
  multiSelect: { component: InputMultiSelect },
  csvFileUpload: {
    component: InputFileUpload,
    propFilter: (bProps) => ({ ...bProps })
  },
  fileUpload: {
    component: InputFileUpload,
    propFilter: (bProps) => ({ ...bProps })
  },
  tagsInput: { component: InputTags },
  doubleTextfield: { component: InputDoubleTextField }
};

const WebsiteForm = ({
  formState,
  setFormState,
  toolInputFields,
  defaultFormState,
  autofocus,
  useIds,
  fieldsToHide = []
}: {
  formState: UserInputDictType;
  defaultFormState?: UserInputDictType;
  setFormState: TSetFormState;
  toolInputFields: CustomToolInputField[];
  autofocus?: boolean;
  useIds?: boolean;
  fieldsToHide?: string[];
}) => {
  const chipsToUse = useInputChipsToUse();
  if (toolInputFields.length === 0) return null;

  return (
    <Box>
      {toolInputFields
        .filter((input) => !fieldsToHide.includes(input.id))
        .map((toolInputField, index) => {
          const config = inputComponentMap[toolInputField.type];
          if (!config) return null; // Skip if no matching component is found

          const InputComponent = config.component;
          const commonProps = baseProps({
            toolInputField,
            setFormState,
            formState,
            useIds
          });
          const extraProps = {
            autofocus: index === 0 && autofocus,
            defaultFormState,
            defaultExpanded: true,
            chipsToUse
          };

          // Apply propFilter if it exists, otherwise pass common props directly
          const props = config.propFilter
            ? config.propFilter(commonProps, extraProps)
            : commonProps;

          return (
            <div key={index} className="p-v-32px">
              <InputComponent {...props} />
            </div>
          );
        })}
    </Box>
  );
};

export default WebsiteForm;
