import { FormLabel, MenuItem, Select, type SelectProps } from "@mui/material";
import { Fragment, useId } from "react";

export default function SelectWithLabel({
  label,
  disableComplexProps,
  options,
  children,
  ...props
}: SelectProps & {
  disableComplexProps?: boolean;
  options?: { label: string; value: string }[];
}) {
  const id = useId();

  return (
    <Fragment>
      <FormLabel
        htmlFor={props.id || id}
        sx={{ fontWeight: 500, color: "rgba(18, 23, 41, 0.60)" }}
      >
        {label}
      </FormLabel>
      <Select
        id={props.id || id}
        labelId={props.id || id}
        className="nowheel nodrag nopan"
        sx={{ backgroundColor: "white" }}
        {...props}
      >
        {options?.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </Fragment>
  );
}
