import {
  FieldType,
  type SavetoKnowledgeBlockProps,
  type TFieldsByType
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../useUpdateField";

export default function SavetoKnowledgeBlockInner({
  data,
  id
}: Omit<SavetoKnowledgeBlockProps, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const updateField = useUpdateField(id);
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  const fields: TFieldsByType = [
    {
      type: FieldType.DynamicInputField,
      label: "Source",
      subtitle:
        "Choose a unique, descriptive name that captures the essence of the source.",
      config: {
        fieldKey: "settings.name.value",
        typeKey: "settings.name.type"
      }
    },
    {
      type: FieldType.PromptInputV2,
      label: "Content",
      subtitle: "Add content to save into the knowledge base.",
      config: {
        fieldKey: "settings.content",
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        autofocus: true,
        textFieldClassName: "min-h-246px",
        chipsToUse
      }
    }
  ];

  return (
    <div className="m-t-16px">
      <FieldsByType configs={{ data, fields, id, updateField }} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}
