import { Stack } from "@mui/material";
import { useMemo } from "react";
import useIterationHelpers from "../../../../../hooks/useIterationHelpers";
import IteratedChip from "./IteratedChips";

export default function NodeInfoChips() {
  const { isIterationChild } = useIterationHelpers();

  const chips = useMemo(() => {
    const chipsToShow = [];

    if (isIterationChild) chipsToShow.push(IteratedChip);

    return chipsToShow;
  }, [isIterationChild]);

  if (!chips.length) return null;

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        marginTop: 2,
        marginInline: 2
      }}
    >
      {chips.map((Chip, i) => (
        <Chip key={i} />
      ))}
    </Stack>
  );
}
