import { createSelector } from "@reduxjs/toolkit";
import { marketplaceApi } from "../marketplaceApi";
import {
  marketplaceAgentEntityAdapter,
  marketplaceAgentEntityAdapterInitialState
} from "../../../stores/adapters/marketplaceEntityAdapter";
import { RootState } from "../../../stores/store";
import { agentApi } from "../agentApi";

const selectMarketplaceAgentsResult =
  marketplaceApi.endpoints.getMarketplaceAgents.select();

const selectMarketplaceAgentsData = createSelector(
  selectMarketplaceAgentsResult,
  (marketplaceAgentsResult) => marketplaceAgentsResult.data
);

const selectProfileAgentResults = (profileId: string) =>
  agentApi.endpoints.getProfileAgents.select(profileId);

const selectProfileAgentData = (profileId: string) =>
  createSelector(
    selectProfileAgentResults(profileId),
    (profileToolResult) =>
      profileToolResult.data ?? marketplaceAgentEntityAdapterInitialState
  );

export const {
  selectAll: selectAllMarketplaceAgents,
  selectById: selectMarketplaceAgentById
} = marketplaceAgentEntityAdapter.getSelectors(
  (state: RootState) =>
    selectMarketplaceAgentsData(state) ??
    marketplaceAgentEntityAdapterInitialState
);

export const selectAllProfileAgents = (profileId: string) =>
  createSelector(
    (state: RootState) => selectProfileAgentData(profileId)(state),
    (agentData) =>
      marketplaceAgentEntityAdapter.getSelectors().selectAll(agentData)
  );

export const selectProfileAgentsById = (profileId: string, agentId: string) =>
  createSelector(
    (state: RootState) => selectProfileAgentData(profileId)(state),
    (agentData) =>
      marketplaceAgentEntityAdapter
        .getSelectors()
        .selectById(agentData, agentId)
  );

// export const { selectAll: selectAllAgents, selectById: selectAgentById } =
//   marketplaceAgentEntityAdapter.getSelectors(
//     (state: RootState) =>
//       selectProfileAgentData(state) ?? marketplaceAgentEntityAdapterInitialState
//   );
