import { useEffect, useRef, useState } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import { useRichTextEditorContext } from "mui-tiptap";

const useOpenOrCloseToolMenu = () => {
  const slashHasRenderedRef = useRef<boolean>(false);
  const [slashHasRendered, setSlashHasRendered] = useState(false);

  useEffect(() => {
    slashHasRenderedRef.current = slashHasRendered;
  }, [slashHasRendered]);

  const editor = useRichTextEditorContext();
  const { setShouldRenderBubble } = useMuiTipTapContext();
  const registerGlobalKeyListener = (handler: (e: KeyboardEvent) => void) => {
    document.addEventListener("keydown", handler);
  };

  const deregisterGlobalKeyListener = (handler: (e: KeyboardEvent) => void) => {
    document.removeEventListener("keydown", handler);
  };

  const onKeyDown = (e: KeyboardEvent) => {
    const charAtCursor = e.key;
    if (charAtCursor === "Escape") {
      setShouldRenderBubble(false);
      if (editor) {
        editor.commands.focus();
      }
    } else if (charAtCursor === "/" && !slashHasRenderedRef.current) {
      setSlashHasRendered(true);
      e.preventDefault();
      return true;
    }
  };
  useEffect(() => {
    registerGlobalKeyListener(onKeyDown);

    return () => {
      deregisterGlobalKeyListener(onKeyDown);
    };
  }, []);
};

export default useOpenOrCloseToolMenu;
