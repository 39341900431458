import React from "react";
import ToggleSecondaryHeaderTextFieldBase from "../../../../utilities/components/textFields/ToggleSecondaryHeaderTextFieldBase";
import { useFormContext } from "react-hook-form";
import UpdateAgentDescriptionTextField from "./UpdateAgentDescriptionTextfield";
import { ADD_DESCRIPTION_TEXT } from "../../../../utilities/constants/fieldConstants";
import { AGENT_DESCRIPTION_FIELD_LABEL } from "./constants/fields";

const ToggleAgentDescriptionTextField = () => {
  const { watch } = useFormContext();
  const agentDescription = watch(AGENT_DESCRIPTION_FIELD_LABEL);

  return (
    <ToggleSecondaryHeaderTextFieldBase
      value={agentDescription || ADD_DESCRIPTION_TEXT}
    >
      <UpdateAgentDescriptionTextField />
    </ToggleSecondaryHeaderTextFieldBase>
  );
};

export default ToggleAgentDescriptionTextField;
