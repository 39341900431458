import { JSONContent } from "@tiptap/core";
import { HydratedDocument } from "mongoose";
import { AgentSchemaId, UserSchemaId } from "../schemaTypes";
import { LeanDocument, TTagObject, VisibilityTypes } from "./utilities";

export enum EAgentType {
  OpenAIAssistant = "OpenAIAssistant"
}

export type TInstructions = JSONContent | string;

export type TAgentDataToSaveBase = {
  name: string;
  description: string;
  visibility: VisibilityTypes;
  tag: TTagObject;
  isFeatured: boolean;
  isPopular: boolean;
  userId: string;
  externalId: string;
  type: EAgentType;
  enableForking: boolean;
  config: {
    instructions: TInstructions;
  };
};

export type TAgentDataToSave = Omit<TAgentDataToSaveBase, "config"> & {
  config: TUniversalAgentConfig;
};

export type TAgentDataToUpdate = Partial<TAgentDataToSave>;

export type TAgentReference = TAgentDataToSaveBase & {
  _id: string;
  createdAt: number;
  updatedAt: number;
};

export type TUniversalAgentConfig = {
  model: string;
  temperature?: number;
  toolIds: string[];
  instructions: TInstructions;
};

export type TUniversalAgent = Omit<TAgentReference, "config"> & {
  config: TUniversalAgentConfig;
};

type PropsWithSchemaId = {
  _id: AgentSchemaId;
  userId?: UserSchemaId;
};

// apiKeys are toolflow API keys, not api keys to other integrations
// other integration API keys are handled with secrets
export type TAgentReferenceDocument = HydratedDocument<
  TAgentReference,
  PropsWithSchemaId
>;

export type LeanAgentReferenceDocument = LeanDocument<
  TAgentReference,
  PropsWithSchemaId
>;
