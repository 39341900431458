import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";

const useChangeInputDescriptionWorkflow = () => {
  const { update } = useInputsToCollectFieldArrayContext();
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const handleToolInputChangeDescription = (value: string, index: number) => {
    update(index, { ...inputsToCollect[index], description: value });
  };
  return handleToolInputChangeDescription;
};

export default useChangeInputDescriptionWorkflow;
