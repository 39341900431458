import React, { useMemo, useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, IconButton, Typography } from "@mui/material";
import ViewCurrrentVersionAndMakeLive from "../../ViewCurrentVersionAndMakeLive";
import MakeLiveButton from "../../MakeLiveButton";
import { useSettingsDropdownContext } from "../../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import SettingsMenuItem from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import {
  CloseIcon,
  HistoryIcon
} from "../../../../../../globalTheme/icons/icons";

export const UpdateVersionDialog = ({
  open,
  closeDialog
}: {
  open: boolean;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) => {
  const { state } = useToolbuilderContext();
  const { toolId, toolVersionId } = state;

  const currentVersionIsLive = useMemo(() => {
    return !toolId || toolVersionId === state.main?._id;
  }, [toolId, toolVersionId, state.main?._id]);

  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="xs">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="inherit" className="flex-grow-1" noWrap>
            Version
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!currentVersionIsLive && (
            <Typography>
              You are viewing a version that is not the published version.
            </Typography>
          )}

          <ViewCurrrentVersionAndMakeLive selectMargin="normal" />
        </DialogContent>
        <DialogActions className="flex align-i-center justify-space-between">
          <MakeLiveButton />
          <Button variant="contained" size="small" onClick={closeDialog}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const UpdateVersionMenuItem = () => {
  const { handleClose } = useSettingsDropdownContext();
  const [open, setOpen] = useState(false);
  const closeDialog = (e: React.SyntheticEvent<Element, Event>) => {
    handleClose(e);
    setOpen(false);
  };
  return (
    <>
      <UpdateVersionDialog open={open} closeDialog={closeDialog} />
      <SettingsMenuItem
        action={() => setOpen(true)}
        text="Change Version"
        hotkey="Meta+I"
        icon={<HistoryIcon />}
      />
    </>
  );
};

export default UpdateVersionMenuItem;
