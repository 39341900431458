import React from "react";
import useToolsTableData from "../../hooks/useToolsTableData";
import TableSkeleton from "../../layout/TableSkeleton";
import NoRecentData from "../../NoRecentData";
import ToolsPaginatedTable from "../ToolsPaginatedTable";
import { useStarredEntities } from "../../../../entities/hooks/useStarredEntities";

const StarredEntitiesDetailedContainer = () => {
  const { starredEntities, loading } = useStarredEntities();
  const { headCells, rows } = useToolsTableData(starredEntities);
  if (loading) {
    return <TableSkeleton numOfRows={15} className="p-16px" />;
  }
  const isHomePageEntitiesEmpty = starredEntities?.length === 0 && !loading;
  if (isHomePageEntitiesEmpty) {
    return (
      <NoRecentData
        header="Your recent tools will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <ToolsPaginatedTable rows={rows} headCells={headCells} />;
};

export default StarredEntitiesDetailedContainer;
