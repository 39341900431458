import React, { useEffect, useRef, useState } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
  type SelectChangeEvent
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  WORKFLOW_GET_ASSETS_STEP,
  WORKFLOW_RUN_TOOL_STEP,
  WORKFLOW_TEXT_STEP,
  settingsDefaults
} from "../WorkflowBuilderConstants";
import useGetSelectedStepTypeFieldLabel from "./hooks/useGetSelectedStepTypeFieldLabel";
import useGetSelectedWorkflowStepField from "./hooks/useGetSelectedWorkflowStepField";
import type { TWorkflowBuilderForm, TWorkflowStep } from "@toolflow/shared";
import { mergeRefs } from "react-merge-refs";

const stepTypesDict: {
  [key in TWorkflowStep]: { text: string; secondary: string };
} = {
  [WORKFLOW_RUN_TOOL_STEP]: {
    text: "Run tool",
    secondary: "Collect inputs from a user and run a tool"
  },
  [WORKFLOW_TEXT_STEP]: {
    text: "Text",
    secondary:
      "Free text field that lets you add notes or instructions to your workflow that may need to happen outside of Toolflow"
  },
  [WORKFLOW_GET_ASSETS_STEP]: {
    text: "Request inputs",
    secondary:
      "Use this step to collect a portion of the inputs you need to finish a workflow. This is great when a tool needs a lot of inputs, and you want to decrease overwhelm"
  }
};

function SelectStepType() {
  const mappedInputFieldName = useGetSelectedStepTypeFieldLabel();
  const { resetField } = useFormContext<TWorkflowBuilderForm>();
  const fieldLabel = useGetSelectedWorkflowStepField();
  const selectRef = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined);
  useEffect(() => {
    if (selectRef.current) {
      setMenuWidth(selectRef.current.clientWidth);
    }
  }, []);
  return (
    <FormControl size="small" fullWidth margin="normal">
      <InputLabel>Type</InputLabel>
      <Controller
        name={mappedInputFieldName}
        render={({ field }) => {
          const onChange = (e: SelectChangeEvent) => {
            const selectedStepType = e.target.value as TWorkflowStep;
            resetField(`${fieldLabel}.settings`, {
              defaultValue: settingsDefaults[selectedStepType]
            });
            field.onChange(selectedStepType);
          };
          return (
            <Select
              {...field}
              fullWidth
              onChange={onChange}
              className="max-w-100-percent"
              sx={{ maxWidth: menuWidth }}
              renderValue={(value: TWorkflowStep) => {
                return <Typography>{stepTypesDict[value].text}</Typography>;
              }}
              label="Type"
              ref={mergeRefs([field.ref, selectRef])}
              MenuProps={{
                PaperProps: {
                  style: {
                    width: menuWidth
                  }
                }
              }}
            >
              {Object.entries(stepTypesDict).map(([key, value]) => (
                <MenuItem value={key} key={key}>
                  <ListItemText
                    primary={value.text}
                    secondary={value.secondary}
                    secondaryTypographyProps={{
                      style: { whiteSpace: "normal" }
                    }}
                  />
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    </FormControl>
  );
}

export default SelectStepType;
