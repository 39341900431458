import type { CustomToolOutputField } from "@toolflow/shared";
import React, { createContext, useContext } from "react";

type TDraggableContexts = { toolOutputField: CustomToolOutputField };

const ToolbuilderOutputFieldContext = createContext<
  TDraggableContexts | undefined
>(undefined);

export const useToolbuilderOutputFieldContext = () => {
  const context = useContext(ToolbuilderOutputFieldContext);
  if (context === undefined) {
    throw new Error(
      "useToolbuilderOutputFieldContext must be used within a ToolbuilderOutputFieldContext"
    );
  }
  return context;
};

export const ToolbuilderOutputFieldContextProvider = ({
  children,
  toolOutputField
}: {
  children: React.ReactNode;
  toolOutputField: CustomToolOutputField;
}) => {
  return (
    <ToolbuilderOutputFieldContext.Provider value={{ toolOutputField }}>
      {children}
    </ToolbuilderOutputFieldContext.Provider>
  );
};
