import {
  DynamicFieldType,
  type SavetoKnowledgeBlockSettings
} from "@toolflow/shared";

export const saveToKnowledgeInitialState: {
  settings: SavetoKnowledgeBlockSettings;
} = {
  settings: {
    name: { type: DynamicFieldType.Preset, value: "" },
    content: ""
  }
};
