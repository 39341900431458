import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IKnowledgeInitialStateBase {
  snippetFormDialog: {
    open: boolean;
    initialName: string;
    initialValue: string;
  };
}

const initialState: IKnowledgeInitialStateBase = {
  snippetFormDialog: {
    open: false,
    initialName: "",
    initialValue: ""
  }
};
export type TSnippetReducerName = "snippet";
export const SNIPPET_REDUCER_NAME: TSnippetReducerName = "snippet";

const knowledgeSlice = createSlice({
  name: SNIPPET_REDUCER_NAME,
  initialState,
  reducers: {
    openSnippetFormDialog(
      state,
      action: PayloadAction<{ initialName?: string; initialValue?: string }>
    ) {
      state.snippetFormDialog = {
        open: true,
        initialName: action.payload.initialName ?? "",
        initialValue: action.payload.initialValue ?? ""
      };
    },
    closeSnippetFormDialog(state) {
      state.snippetFormDialog = {
        open: false,
        initialName: "",
        initialValue: ""
      };
    }
  }
});

export const { openSnippetFormDialog, closeSnippetFormDialog } =
  knowledgeSlice.actions;

export default knowledgeSlice.reducer;
