import { type Content } from "@tiptap/core";
import React from "react";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { CopyIcon } from "../../../../../../../globalTheme/icons/icons";
import useCopyContent from "./hooks/useCopyContent";

function CopyThreadText({ content }: { content: Content }) {
  const copyContent = useCopyContent();

  return (
    <RemixButton
      text="Copy"
      icon={CopyIcon}
      onClick={() => copyContent(content)}
      disabled={!content}
    />
  );
}

export default CopyThreadText;
