export type TGoogleSerpCountry = {
  label: string;
  value: string;
};

export type TGoogleSerpLanguage = { label: string; value: string };
export const googleSerpCountries: TGoogleSerpCountry[] = [
  { label: "Afghanistan", value: "af" },
  { label: "Albania", value: "al" },
  { label: "Algeria", value: "dz" },
  { label: "American Samoa", value: "as" },
  { label: "Andorra", value: "ad" },
  { label: "Angola", value: "ao" },
  { label: "Anguilla", value: "ai" },
  { label: "Antarctica", value: "aq" },
  { label: "Antigua and Barbuda", value: "ag" },
  { label: "Argentina", value: "ar" },
  { label: "Armenia", value: "am" },
  { label: "Aruba", value: "aw" },
  { label: "Australia", value: "au" },
  { label: "Austria", value: "at" },
  { label: "Azerbaijan", value: "az" },
  { label: "Bahamas", value: "bs" },
  { label: "Bahrain", value: "bh" },
  { label: "Bangladesh", value: "bd" },
  { label: "Barbados", value: "bb" },
  { label: "Belarus", value: "by" },
  { label: "Belgium", value: "be" },
  { label: "Belize", value: "bz" },
  { label: "Benin", value: "bj" },
  { label: "Bermuda", value: "bm" },
  { label: "Bhutan", value: "bt" },
  { label: "Bolivia", value: "bo" },
  { label: "Bosnia and Herzegovina", value: "ba" },
  { label: "Botswana", value: "bw" },
  { label: "Bouvet Island", value: "bv" },
  { label: "Brazil", value: "br" },
  { label: "British Indian Ocean Territory", value: "io" },
  { label: "Brunei Darussalam", value: "bn" },
  { label: "Bulgaria", value: "bg" },
  { label: "Burkina Faso", value: "bf" },
  { label: "Burundi", value: "bi" },
  { label: "Cambodia", value: "kh" },
  { label: "Cameroon", value: "cm" },
  { label: "Canada", value: "ca" },
  { label: "Cape Verde", value: "cv" },
  { label: "Cayman Islands", value: "ky" },
  { label: "Central African Republic", value: "cf" },
  { label: "Chad", value: "td" },
  { label: "Chile", value: "cl" },
  { label: "China", value: "cn" },
  { label: "Christmas Island", value: "cx" },
  { label: "Cocos (Keeling) Islands", value: "cc" },
  { label: "Colombia", value: "co" },
  { label: "Comoros", value: "km" },
  { label: "Congo", value: "cg" },
  { label: "Congo, The Democratic Republic of the", value: "cd" },
  { label: "Cook Islands", value: "ck" },
  { label: "Costa Rica", value: "cr" },
  { label: "Côte d'Ivoire", value: "ci" },
  { label: "Croatia", value: "hr" },
  { label: "Cuba", value: "cu" },
  { label: "Cyprus", value: "cy" },
  { label: "Czech Republic", value: "cz" },
  { label: "Denmark", value: "dk" },
  { label: "Djibouti", value: "dj" },
  { label: "Dominica", value: "dm" },
  { label: "Dominican Republic", value: "do" },
  { label: "Ecuador", value: "ec" },
  { label: "Egypt", value: "eg" },
  { label: "El Salvador", value: "sv" },
  { label: "Equatorial Guinea", value: "gq" },
  { label: "Eritrea", value: "er" },
  { label: "Estonia", value: "ee" },
  { label: "Ethiopia", value: "et" },
  { label: "Falkland Islands (Malvinas)", value: "fk" },
  { label: "Faroe Islands", value: "fo" },
  { label: "Fiji", value: "fj" },
  { label: "Finland", value: "fi" },
  { label: "France", value: "fr" },
  { label: "French Guiana", value: "gf" },
  { label: "French Polynesia", value: "pf" },
  { label: "French Southern Territories", value: "tf" },
  { label: "Gabon", value: "ga" },
  { label: "Gambia", value: "gm" },
  { label: "Georgia", value: "ge" },
  { label: "Germany", value: "de" },
  { label: "Ghana", value: "gh" },
  { label: "Gibraltar", value: "gi" },
  { label: "Greece", value: "gr" },
  { label: "Greenland", value: "gl" },
  { label: "Grenada", value: "gd" },
  { label: "Guadeloupe", value: "gp" },
  { label: "Guam", value: "gu" },
  { label: "Guatemala", value: "gt" },
  { label: "Guinea", value: "gn" },
  { label: "Guinea-Bissau", value: "gw" },
  { label: "Guyana", value: "gy" },
  { label: "Haiti", value: "ht" },
  { label: "Heard Island and McDonald Islands", value: "hm" },
  { label: "Holy See (Vatican City State)", value: "va" },
  { label: "Honduras", value: "hn" },
  { label: "Hong Kong", value: "hk" },
  { label: "Hungary", value: "hu" },
  { label: "Iceland", value: "is" },
  { label: "India", value: "in" },
  { label: "Indonesia", value: "id" },
  { label: "Iran, Islamic Republic of", value: "ir" },
  { label: "Iraq", value: "iq" },
  { label: "Ireland", value: "ie" },
  { label: "Israel", value: "il" },
  { label: "Italy", value: "it" },
  { label: "Jamaica", value: "jm" },
  { label: "Japan", value: "jp" },
  { label: "Jordan", value: "jo" },
  { label: "Kazakhstan", value: "kz" },
  { label: "Kenya", value: "ke" },
  { label: "Kiribati", value: "ki" },
  { label: "Korea, Democratic People's Republic of", value: "kp" },
  { label: "Korea, Republic of", value: "kr" },
  { label: "Kuwait", value: "kw" },
  { label: "Kyrgyzstan", value: "kg" },
  { label: "Lao People's Democratic Republic", value: "la" },
  { label: "Latvia", value: "lv" },
  { label: "Lebanon", value: "lb" },
  { label: "Lesotho", value: "ls" },
  { label: "Liberia", value: "lr" },
  { label: "Libya", value: "ly" },
  { label: "Liechtenstein", value: "li" },
  { label: "Lithuania", value: "lt" },
  { label: "Luxembourg", value: "lu" },
  { label: "Macao", value: "mo" },
  { label: "Macedonia, The Former Yugoslav Republic of", value: "mk" },
  { label: "Madagascar", value: "mg" },
  { label: "Malawi", value: "mw" },
  { label: "Malaysia", value: "my" },
  { label: "Maldives", value: "mv" },
  { label: "Mali", value: "ml" },
  { label: "Malta", value: "mt" },
  { label: "Marshall Islands", value: "mh" },
  { label: "Martinique", value: "mq" },
  { label: "Mauritania", value: "mr" },
  { label: "Mauritius", value: "mu" },
  { label: "Mayotte", value: "yt" },
  { label: "Mexico", value: "mx" },
  { label: "Micronesia, Federated States of", value: "fm" },
  { label: "Moldova, Republic of", value: "md" },
  { label: "Monaco", value: "mc" },
  { label: "Mongolia", value: "mn" },
  { label: "Montserrat", value: "ms" },
  { label: "Morocco", value: "ma" },
  { label: "Mozambique", value: "mz" },
  { label: "Myanmar", value: "mm" },
  { label: "Namibia", value: "na" },
  { label: "Nauru", value: "nr" },
  { label: "Nepal", value: "np" },
  { label: "Netherlands", value: "nl" },
  { label: "Netherlands Antilles", value: "an" },
  { label: "New Caledonia", value: "nc" },
  { label: "New Zealand", value: "nz" },
  { label: "Nicaragua", value: "ni" },
  { label: "Niger", value: "ne" },
  { label: "Nigeria", value: "ng" },
  { label: "Niue", value: "nu" },
  { label: "Norfolk Island", value: "nf" },
  { label: "Northern Mariana Islands", value: "mp" },
  { label: "Norway", value: "no" },
  { label: "Oman", value: "om" },
  { label: "Pakistan", value: "pk" },
  { label: "Palau", value: "pw" },
  { label: "Palestinian Territory, Occupied", value: "ps" },
  { label: "Panama", value: "pa" },
  { label: "Papua New Guinea", value: "pg" },
  { label: "Paraguay", value: "py" },
  { label: "Peru", value: "pe" },
  { label: "Philippines", value: "ph" },
  { label: "Pitcairn", value: "pn" },
  { label: "Poland", value: "pl" },
  { label: "Portugal", value: "pt" },
  { label: "Puerto Rico", value: "pr" },
  { label: "Qatar", value: "qa" },
  { label: "Réunion", value: "re" },
  { label: "Romania", value: "ro" },
  { label: "Russian Federation", value: "ru" },
  { label: "Rwanda", value: "rw" },
  { label: "Saint Helena", value: "sh" },
  { label: "Saint Kitts and Nevis", value: "kn" },
  { label: "Saint Lucia", value: "lc" },
  { label: "Saint Pierre and Miquelon", value: "pm" },
  { label: "Saint Vincent and the Grenadines", value: "vc" },
  { label: "Samoa", value: "ws" },
  { label: "San Marino", value: "sm" },
  { label: "Sao Tome and Principe", value: "st" },
  { label: "Saudi Arabia", value: "sa" },
  { label: "Senegal", value: "sn" },
  { label: "Serbia and Montenegro", value: "cs" },
  { label: "Seychelles", value: "sc" },
  { label: "Sierra Leone", value: "sl" },
  { label: "Singapore", value: "sg" },
  { label: "Slovakia", value: "sk" },
  { label: "Slovenia", value: "si" },
  { label: "Solomon Islands", value: "sb" },
  { label: "Somalia", value: "so" },
  { label: "South Africa", value: "za" },
  { label: "South Georgia and the South Sandwich Islands", value: "gs" },
  { label: "Spain", value: "es" },
  { label: "Sri Lanka", value: "lk" },
  { label: "Sudan", value: "sd" },
  { label: "Suriname", value: "sr" },
  { label: "Svalbard and Jan Mayen", value: "sj" },
  { label: "Swaziland", value: "sz" },
  { label: "Sweden", value: "se" },
  { label: "Switzerland", value: "ch" },
  { label: "Syrian Arab Republic", value: "sy" },
  { label: "Taiwan, Province of China", value: "tw" },
  { label: "Tajikistan", value: "tj" },
  { label: "Tanzania, United Republic of", value: "tz" },
  { label: "Thailand", value: "th" },
  { label: "Timor-Leste", value: "tl" },
  { label: "Togo", value: "tg" },
  { label: "Tokelau", value: "tk" },
  { label: "Tonga", value: "to" },
  { label: "Trinidad and Tobago", value: "tt" },
  { label: "Tunisia", value: "tn" },
  { label: "Turkey", value: "tr" },
  { label: "Turkmenistan", value: "tm" },
  { label: "Turks and Caicos Islands", value: "tc" },
  { label: "Tuvalu", value: "tv" },
  { label: "Uganda", value: "ug" },
  { label: "Ukraine", value: "ua" },
  { label: "United Arab Emirates", value: "ae" },
  { label: "United Kingdom", value: "gb" },
  { label: "United States", value: "us" },
  { label: "United States Minor Outlying Islands", value: "um" },
  { label: "Uruguay", value: "uy" },
  { label: "Uzbekistan", value: "uz" },
  { label: "Vanuatu", value: "vu" },
  { label: "Venezuela", value: "ve" },
  { label: "Viet Nam", value: "vn" },
  { label: "Virgin Islands, British", value: "vg" },
  { label: "Virgin Islands, U.S.", value: "vi" },
  { label: "Wallis and Futuna", value: "wf" },
  { label: "Western Sahara", value: "eh" },
  { label: "Yemen", value: "ye" },
  { label: "Zambia", value: "zm" },
  { label: "Zimbabwe", value: "zw" }
];

export const googleSerpLanguages: TGoogleSerpLanguage[] = [
  { label: "Afrikaans", value: "af" },
  { label: "Albanian", value: "sq" },
  { label: "Samoan", value: "sm" },
  { label: "Arabic", value: "ar" },
  { label: "Azerbaijani", value: "az" },
  { label: "Basque", value: "eu" },
  { label: "Belarusian", value: "be" },
  { label: "Bengali", value: "bn" },
  { label: "Bihari", value: "bh" },
  { label: "Bosnian", value: "bs" },
  { label: "Bulgarian", value: "bg" },
  { label: "Catalan", value: "ca" },
  { label: "Chinese (Simplified)", value: "zh-CN" },
  { label: "Chinese (Traditional)", value: "zh-TW" },
  { label: "Croatian", value: "hr" },
  { label: "Czech", value: "cs" },
  { label: "Danish", value: "da" },
  { label: "Dutch", value: "nl" },
  { label: "English", value: "en" },
  { label: "Esperanto", value: "eo" },
  { label: "Estonian", value: "et" },
  { label: "Faroese", value: "fo" },
  { label: "Finnish", value: "fi" },
  { label: "French", value: "fr" },
  { label: "Frisian", value: "fy" },
  { label: "Galician", value: "gl" },
  { label: "Georgian", value: "ka" },
  { label: "German", value: "de" },
  { label: "Greek", value: "el" },
  { label: "Gujarati", value: "gu" },
  { label: "Hebrew", value: "iw" },
  { label: "Hindi", value: "hi" },
  { label: "Hungarian", value: "hu" },
  { label: "Icelandic", value: "is" },
  { label: "Indonesian", value: "id" },
  { label: "Interlingua", value: "ia" },
  { label: "Irish", value: "ga" },
  { label: "Italian", value: "it" },
  { label: "Japanese", value: "ja" },
  { label: "Javanese", value: "jw" },
  { label: "Kannada", value: "kn" },
  { label: "Korean", value: "ko" },
  { label: "Latin", value: "la" },
  { label: "Latvian", value: "lv" },
  { label: "Lithuanian", value: "lt" },
  { label: "Macedonian", value: "mk" },
  { label: "Malay", value: "ms" },
  { label: "Malayalam", value: "ml" },
  { label: "Maltese", value: "mt" },
  { label: "Marathi", value: "mr" },
  { label: "Nepali", value: "ne" },
  { label: "Norwegian", value: "no" },
  { label: "Norwegian Nynorsk", value: "nn" },
  { label: "Occitan", value: "oc" },
  { label: "Persian", value: "fa" },
  { label: "Polish", value: "pl" },
  { label: "Portuguese (Brazil)", value: "pt-BR" },
  { label: "Portuguese (Portugal)", value: "pt-PT" },
  { label: "Punjabi", value: "pa" },
  { label: "Romanian", value: "ro" },
  { label: "Russian", value: "ru" },
  { label: "Scottish Gaelic", value: "gd" },
  { label: "Serbian", value: "sr" },
  { label: "Sinhala", value: "si" },
  { label: "Slovak", value: "sk" },
  { label: "Slovenian", value: "sl" },
  { label: "Spanish", value: "es" },
  { label: "Sundanese", value: "su" },
  { label: "Swahili", value: "sw" },
  { label: "Swedish", value: "sv" },
  { label: "Tagalog", value: "tl" },
  { label: "Tamil", value: "ta" },
  { label: "Telugu", value: "te" },
  { label: "Thai", value: "th" },
  { label: "Tigrinya", value: "ti" },
  { label: "Turkish", value: "tr" },
  { label: "Ukrainian", value: "uk" },
  { label: "Urdu", value: "ur" },
  { label: "Uzbek", value: "uz" },
  { label: "Vietnamese", value: "vi" },
  { label: "Welsh", value: "cy" },
  { label: "Xhosa", value: "xh" },
  { label: "Zulu", value: "zu" }
];
