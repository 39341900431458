import React from "react";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Typography
} from "@mui/material";
import useSubscriptionDict from "./hooks/useSubscriptionDict";
import type { TAvailableTiers } from "@toolflow/shared";
import RemixWrapperIconWithTheme from "../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { toolflowPrimaryColor } from "../../../../../globalTheme/muiUtils/appTheme";
import useGetUpdateSubscriptionButton from "./hooks/useGetUpdateSubscriptionButton";
import { CheckIcon } from "../../../../../globalTheme/icons/icons";

const SubscriptionTierCard = ({ tier }: { tier: TAvailableTiers }) => {
  const { getUpdateSubscriptionButton } = useGetUpdateSubscriptionButton({
    tier
  });
  const subscriptionDict = useSubscriptionDict();

  return (
    <FullWidthCard className="p-32px max-w-350px m-b-16px w-100-percent">
      <div>
        <Typography variant="h5">{subscriptionDict[tier].name}</Typography>
        <Stack direction="row" spacing={1} className="m-t-16px">
          <Typography variant="h5">{`${subscriptionDict[tier].priceAmountString}`}</Typography>
          <Typography
            variant="caption"
            sx={{ top: 10, position: "relative", color: "text.disabled" }}
          >{`${subscriptionDict[tier].priceDurationString}`}</Typography>
        </Stack>
        <Typography variant="subtitle2" className="m-t-8px">
          {subscriptionDict[tier].tagline}
        </Typography>
        <List sx={{ height: 400, background: "transparent" }}>
          {subscriptionDict[tier].benefits.map((benefit, idx) => (
            <ListItem key={idx} disablePadding>
              <ListItemButton disabled>
                <RemixWrapperIconWithTheme
                  Icon={CheckIcon}
                  size={16}
                  providedColor={toolflowPrimaryColor}
                  className="m-t-8px"
                />
              </ListItemButton>
              <Typography variant="body2" width="100%" className="p-t-8px">
                {benefit}
              </Typography>
            </ListItem>
          ))}
        </List>
      </div>
      {getUpdateSubscriptionButton()}
      <Typography variant="caption" className="m-t-8px" color="grey">
        7 day money back guarantee
      </Typography>
    </FullWidthCard>
  );
};

export default SubscriptionTierCard;
