import React, { createContext, useContext, ReactNode } from "react";
import type { Asset } from "@toolflow/shared";

interface IAssetContext<T extends Asset> {
  asset: T;
}

const AssetContext = createContext<IAssetContext<Asset> | undefined>(undefined);

interface IAssetContextComponentProps<T extends Asset> {
  children: ReactNode;
  asset?: T;
}

export function AssetContextComponent<T extends Asset>({
  children,
  asset
}: Readonly<IAssetContextComponentProps<T>>) {
  if (!asset) {
    return null;
  }
  return (
    <AssetContext.Provider value={{ asset }}>{children}</AssetContext.Provider>
  );
}

export const useAssetContext = <T extends Asset>() => {
  const context = useContext(AssetContext) as IAssetContext<T> | undefined;
  if (context === undefined) {
    throw new Error(
      "useAssetContext must be used within a AssetContextComponent"
    );
  }
  return context;
};

export default AssetContext;
