import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { LinkIcon } from "../../../../globalTheme/icons/icons";
import { useHandleCopy } from "../../../../utilities/components/copyContent/hooks/useHandleCopy";
import type { ValidateTypeWithAgent } from "@toolflow/shared";

const CopyEntityLink = ({
  entityId,
  entityType
}: {
  entityId: string;
  entityType: ValidateTypeWithAgent;
}) => {
  const handleCopyToolLink = useHandleCopy(entityId, entityType);
  const copy = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCopyToolLink();
  };
  return (
    <RemixButton
      text="Copy tool link to clipboard"
      icon={LinkIcon}
      onClick={copy}
    />
  );
};

export default CopyEntityLink;
