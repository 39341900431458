import { isRejectedWithValue, type Middleware } from "@reduxjs/toolkit";
import { security } from "../ToolflowAPI/security";
import { isLoginOrConsentRequiredError } from "../features/auth/functions/isLoginOrConsentRequiredError";

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const message =
      "data" in action.error
        ? (action.error.data as { message: string }).message
        : action.error.message || "An error occurred";
    console.warn(
      `An error occurred with action: ${JSON.stringify(
        action
      )} and had message: ${message}`
    );
  }

  return next(action);
};

export const rtkQueryErrorNavigation: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (isLoginOrConsentRequiredError(action.error)) {
      const loginFunc = security.getLoginBackToPage();
      if (loginFunc) {
        security.getLoginBackToPage();
      }
    }
  }

  return next(action);
};
