import React, { FC } from "react";
import type { TBlocksTagsLabels } from "@toolflow/shared";
import { getBlockIconImageSvg } from "../../../blocks/getBlockIcon";
import { AvatarGroup } from "@mui/material";
import { StyledBlockTagAvatar } from "./StyledBlockTagAvatar";

interface IModelsAvatarGroupProps {
  blockLabels: TBlocksTagsLabels[];
  maxBlocksToShow: number;
}
const ModelsAvatarGroup: FC<IModelsAvatarGroupProps> = ({
  blockLabels,
  maxBlocksToShow
}) => {
  return (
    <AvatarGroup spacing={4}>
      {blockLabels.slice(0, maxBlocksToShow).map((blockLabel) => (
        <StyledBlockTagAvatar
          key={blockLabel}
          src={getBlockIconImageSvg(blockLabel)}
          variant="square"
        />
      ))}
      {blockLabels.length > maxBlocksToShow && (
        <StyledBlockTagAvatar>
          {`+${blockLabels.length - maxBlocksToShow}`}
        </StyledBlockTagAvatar>
      )}
    </AvatarGroup>
  );
};

export default ModelsAvatarGroup;
