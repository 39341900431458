import LoadingButton from "@mui/lab/LoadingButton";
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import { useHandleCopyText } from "../../../../../utilities/components/copyContent/hooks/useHandleCopy";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../layout/alertMessageSnackbar/alertMessageSlice";
import { useCreateApiKeyMutation } from "../../../../../ToolflowAPI/rtkRoutes/apiKeyApi";
import { CloseIcon, CopyIcon } from "../../../../../globalTheme/icons/icons";
import { useCreateAPIKeyButton } from "./hooks/useCreateAPIKeyButton";

const DEFAULT_DESCRIPTION = "New API Key";
const DEFAULT_SAVED_API_KEY = "";
const DEFAULT_COPIED = false;

export const CreateAPIKeyButtonDialogBase = ({
  open,
  closeDialog
}: {
  open: boolean;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) => {
  const [createAPIKey, { isLoading }] = useCreateApiKeyMutation();
  const [description, setDescription] = useState(DEFAULT_DESCRIPTION);
  const [savedAPIKey, setSavedAPIKey] = useState(DEFAULT_SAVED_API_KEY);
  const [hasCopied, setHasCopied] = useState(DEFAULT_COPIED);
  const reduxDispatch = useDispatch();

  const handleSaveSecret = async () => {
    await createAPIKey({ description })
      .unwrap()
      .then((resp) => {
        setSavedAPIKey(resp.newAPIKey);
      });
  };

  const handleCopy = useHandleCopyText();

  const handleCopyClick = () => {
    setHasCopied(true);
    handleCopy(savedAPIKey);
  };

  const handleClose = (e: React.SyntheticEvent<Element, Event>) => {
    if (savedAPIKey && !hasCopied) {
      reduxDispatch(setErrorMessage("Please copy your API key before closing"));
    } else {
      closeDialog(e);
      setHasCopied(DEFAULT_COPIED);
      setSavedAPIKey(DEFAULT_SAVED_API_KEY);
      setDescription(DEFAULT_DESCRIPTION);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="h7" className="flex-grow-1" noWrap>
            Create API Key
          </Typography>
          <IconButton
            onClick={handleClose}
            disabled={!!(savedAPIKey && !hasCopied)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {savedAPIKey ? (
            <>
              <Typography variant="caption" className="m-b-16px">
                Please copy your API key. You will not be able to see this API
                key again
              </Typography>
              <div className="flex align-i-center">
                <Typography className="m-r-8px">{savedAPIKey}</Typography>
                <IconButton onClick={handleCopyClick} size="small">
                  <CopyIcon />
                </IconButton>
              </div>
            </>
          ) : (
            <TextField
              label="API Key Description"
              value={description}
              size="medium"
              onChange={(e) => setDescription(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
        </DialogContent>
        <DialogActions>
          {!savedAPIKey && (
            <LoadingButton
              variant="contained"
              size="large"
              onClick={handleSaveSecret}
              loading={isLoading}
              className="m-l-auto m-r-16px m-b-16px"
            >
              Create API Key
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

const CreateAPIKeyButton = () => {
  const { open, setOpen, disabled } = useCreateAPIKeyButton();
  return (
    <>
      <CreateAPIKeyButtonDialogBase
        open={open}
        closeDialog={() => setOpen(false)}
      />
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        size="large"
        disabled={disabled}
      >
        Create API Key
      </Button>
    </>
  );
};

export default CreateAPIKeyButton;
