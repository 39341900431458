import React from "react";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { toolflowTextDisabledColor } from "../../../../../../../../../globalTheme/muiUtils/appTheme";
import useOpenInputs from "../../../../../store/hooks/useOpenInputs";
import DeleteIconButtonBase from "../../../../../blocks/components/deleteIconButtonBase/DeleteIconButtonBase";

export function DeleteInputButton({
  disabled,
  index,
  size = 20,
  color = toolflowTextDisabledColor,
  className
}: {
  disabled?: boolean;
  index: number;
  size?: number;
  color?: string;
  className?: string;
}) {
  const { deleteInputField } = useToolInputFieldsContext();
  const openInputs = useOpenInputs();

  const deleteInput = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    deleteInputField(index);
    openInputs();
  };

  return (
    <DeleteIconButtonBase
      disabled={disabled}
      className={className}
      size={size}
      color={color}
      onClick={deleteInput}
    />
  );
}
function DeleteInputButtonContainer({
  disabled,
  className
}: {
  disabled?: boolean;
  className?: string;
}) {
  const index = useGetCurrentToolInputFieldIndex();

  return (
    <DeleteInputButton
      disabled={disabled}
      index={index}
      className={className}
    />
  );
}

export default DeleteInputButtonContainer;
