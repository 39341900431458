import {
  AssetSourceType,
  type MultiUserInputResponseWithHandledError,
  type PopulatedToolVersionResponseFE,
  type ToolAsset,
  type ToolVersionRespResponseFE,
  type UserInputDictType
} from "@toolflow/shared";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { getValueTypeByAssetValue } from "../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
const createAsset = (
  name: string,
  value: MultiUserInputResponseWithHandledError,
  toolId: string,
  source: AssetSourceType
): ToolAsset => {
  const { value: valueToSet, valueType } = getValueTypeByAssetValue(value);

  return {
    name,
    value: valueToSet,
    id: uuidv4(),
    source,
    data: { toolId },
    sourceIdentifier: toolId,
    valueType
  } as ToolAsset;
};

const convertToolVersionResponseToOutput = (
  tvr: PopulatedToolVersionResponseFE
): ToolAsset[] => {
  const outputs = tvr.toolVersionId.toolOutputFields;
  const toolId = tvr.toolId._id.toString();

  return outputs.map((output) => {
    return createAsset(
      output.config?.label || output.name,
      output.config?.label
        ? (tvr.responseDict[output.name] as UserInputDictType)[
            output.config.nestedOutputKey
          ]
        : tvr.responseDict[output.name],
      toolId,
      AssetSourceType.ToolOutput
    );
  });
};

export function useTVRAssetObject(data: ToolVersionRespResponseFE | undefined) {
  return useMemo(() => {
    if (!data?.toolVersionResponse) return [];

    const nestedFields = convertToolVersionResponseToOutput(
      data.toolVersionResponse
    );

    return nestedFields;
  }, [data?.toolVersionResponse?.responseDict]);
}
