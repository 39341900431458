import { useEffect, useRef } from "react";
import useWorkspaceAssets from "./useWorkspaceAssets";

const useCurrentAssetsRef = () => {
  const inputs = useWorkspaceAssets();
  const currentAssets = useRef(inputs);

  useEffect(() => {
    currentAssets.current = inputs;
  }, [inputs]);

  return currentAssets;
};

export default useCurrentAssetsRef;
