import { useGetCurrentUserProfileId } from "../../user/hooks/useGetCurrentUserProfileId";
import { useGetAllUserEntities } from "./useGetAllUserEntities";

export const useGetUserCreatedEntities = (limit?: number) => {
  const { entities, loading } = useGetAllUserEntities();
  const profileId = useGetCurrentUserProfileId();
  return {
    userCreatedEntities: entities
      .filter((tool) => tool.userId === profileId)
      .sort((a, b) => b.updatedAt - a.updatedAt)
      .slice(0, limit),
    loading
  };
};
