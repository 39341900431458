import { Button, FormHelperText } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWebSocket, { ReadyState, SendMessage } from "react-use-websocket";
import { updateClientId } from "../../../features/auth/authSlice";
import { setErrorMessage } from "../../../features/layout/alertMessageSnackbar/alertMessageSlice";
import { globalEventEmitterFE } from "../../../utilities/eventEmitter/globalEventEmitterFE";

import { RootState } from "../../../stores/store";
type THandleErrors = "HANDLE_ERRORS";
const HANDLE_ERRORS: THandleErrors = "HANDLE_ERRORS";

const HEARTBEAT_RETURN_MESSAGE = "pong";

function isStringifiedJSON(str: string): boolean {
  try {
    JSON.parse(str);
    return true;
  } catch {
    return false;
  }
}

const getWsUrlBase = () => {
  return process.env.IS_PULL_REQUEST === "true"
    ? `wss://${process.env.REACT_APP_PREVIEW_RENDER_WS_URL}`
    : process.env.REACT_APP_WS_URL;
};

// only available on tool context for dispatch purposes
const useToolflowExternalWebsocket = (): {
  sendMessage: SendMessage;
  resetButton: React.ReactNode;
  readyState?: number;
  reconnect: () => void;
  showReconnectButton: boolean;
} => {
  const reduxDispatch = useDispatch();
  const clientId = useSelector((s: RootState) => s.auth.clientId);
  const url = `${getWsUrlBase()}?clientId=${clientId}`;
  // used to toggle reconnections on
  const [connect, setConnect] = useState(true);
  const { sendMessage, readyState, getWebSocket } = useWebSocket(
    url,
    {
      onMessage: (event: WebSocketEventMap["message"]) => {
        // I think we need to exclude this to let heartbeat work correctly
        if (event.data === HEARTBEAT_RETURN_MESSAGE) {
          return;
        }
        if (isStringifiedJSON(event.data)) {
          const data = JSON.parse(event.data);
          if (data.clientId) {
            reduxDispatch(updateClientId(data.clientId));
          }
          if (data.type === HANDLE_ERRORS && data.errorMessage) {
            reduxDispatch(setErrorMessage(data.errorMessage));
          }
          globalEventEmitterFE.emit("ws_message", JSON.stringify(data));
        } else {
          globalEventEmitterFE.emit("ws_message", event.data);
        }
      },
      onOpen() {
        console.log("Connected to WebSocket");
      },
      reconnectAttempts: 10,
      retryOnError: true,
      reconnectInterval: 3000,
      // share: true, <- this reuses the websocket if we want to call it multiple times with the same url, but close is protected on the getWebSocket()
      shouldReconnect: (closeEvent) => {
        console.log("shouldReconnect", closeEvent);
        /*
      useWebSocket will handle unmounting for you, but this is an example of a 
      case in which you would not want it to automatically reconnect
    */
        return true;
      },
      onClose() {
        console.log("Disconnected from WebSocket");
      },
      onError(event) {
        console.error("WebSocket error:", event);
      },
      onReconnectStop() {
        console.error("Max reconnection attempts reached");
        setConnect(false);
        reduxDispatch(
          setErrorMessage("Can't reconnect to websocket, please reload.")
        );
      }
      // heartbeat: {
      //   message: "ping",
      //   returnMessage: HEARTBEAT_RETURN_MESSAGE, // this will not show in lastMessage. Currently handling everything onmessage though
      //   timeout: 60000, // 1 minute timeout, if not received in 1 minute, close
      //   interval: 10000 // ping every 10 seconds
      // }
    },
    connect
  );

  const reconnect = () => {
    if (connect) {
      // in the case that it is true, we need to toggle it off and then on to reconnect it
      setConnect(false);
      queueMicrotask(() => setConnect(true));
    } else {
      setConnect(true);
    }
  };

  useEffect(() => {
    console.log(readyState, "readyState");
    if (readyState === ReadyState.OPEN) {
      console.log("Reconnected");
      sendMessage(JSON.stringify({ type: "reconnected", clientId }));
    }
  }, [readyState]);

  const ws = getWebSocket();
  // In your main file or a separate file
  if (
    ["development", "staging"].includes(process.env.REACT_APP_ENVIRONMENT || "")
  ) {
    window.ws = ws;
  }

  const showReconnectButton = !!(readyState && readyState !== ReadyState.OPEN);

  function WebsocketReset({}) {
    return (
      <>
        {/* double check if things are undefined -> if they are, we may need to allow a flicker on the FE...
  right now we are requiring it to be defined*/}
        {showReconnectButton && (
          <>
            <FormHelperText className="m-l-8px" error>
              Websocket disconnected
            </FormHelperText>
            <Button onClick={reconnect} variant="text">
              Reconnect
            </Button>
          </>
        )}
      </>
    );
  }

  return {
    sendMessage,
    resetButton: <WebsocketReset />,
    readyState,
    reconnect,
    showReconnectButton
  };
};

export default useToolflowExternalWebsocket;
