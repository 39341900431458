import React from "react";
import type { SvgIconProps } from "@mui/material/SvgIcon";
export interface IconWrapperProps extends SvgIconProps {
  Icon: React.ElementType | null;
  size: number;
  providedColor?: string;
}

const RemixWrapperIconWithTheme: React.FC<IconWrapperProps> = ({
  Icon,
  providedColor,
  ...props
}) => {
  if (!Icon) {
    return null;
  }
  return <Icon {...props} color={providedColor || props.color} />;
};

export default RemixWrapperIconWithTheme;
