import { useMemo } from "react";
import { useGetAllUserWorkflowsAsEntities } from "./useGetAllUserWorkflowsAsEntities";
import { useGetCurrentUserTools } from "../../tools/hooks/useGetCurrentUserTools";

export const useGetAllUserEntities = () => {
  const { entities: workflowEntities, loading: workflowsLoading } =
    useGetAllUserWorkflowsAsEntities();
  const { tools: toolEntities, loading: toolsLoading } =
    useGetCurrentUserTools();

  const loading = toolsLoading || workflowsLoading;

  return {
    entities: loading ? [] : [...workflowEntities, ...toolEntities],
    loading
  };
};

export const useOnlyUserEntities = () => {
  const { entities } = useGetAllUserEntities();
  return useMemo(() => entities, [entities]);
};
