import React from "react";
import UpdateSelectedStepNameTextField from "./UpdateSelectedStepNameTextField";
import CloseStepButton from "./CloseStepButton";
import UpdateSelectedStepDescriptionTextField from "./UpdateSelectedStepDescriptionTextField";
import RemoveStepButton from "./RemoveStepButton";
import useGetSelectedStep from "./hooks/useGetSelectedStep";
import {
  WORKFLOW_GET_ASSETS_STEP,
  WORKFLOW_RUN_TOOL_STEP,
  WORKFLOW_TEXT_STEP
} from "../WorkflowBuilderConstants";
import SelectStepType from "./SelectStepType";
import GetAssetsSettings from "./getAssets/GetAssetsSettings";
import ToolSelectorForStep from "./runTool/ToolSelectorForStep";
import ToolStepDetails from "./ToolStepDetails";
import useSetDefaultNameOnToolChange from "./hooks/useSetDefaultNameOnToolChange";
import useSetDefaultDescriptionOnToolChange from "./hooks/useSetDefaultDescriptionOnToolChange";
import useSetDefaultInputsOnToolChange from "./hooks/useSetDefaultInputsOnToolChange";

const settingsMapper = {
  [WORKFLOW_RUN_TOOL_STEP]: <ToolStepDetails />,
  [WORKFLOW_TEXT_STEP]: null,
  [WORKFLOW_GET_ASSETS_STEP]: <GetAssetsSettings />
};

const topSettingsMapper = {
  [WORKFLOW_RUN_TOOL_STEP]: <ToolSelectorForStep />,
  [WORKFLOW_TEXT_STEP]: null,
  [WORKFLOW_GET_ASSETS_STEP]: null
};

function StepSidebarInner() {
  const step = useGetSelectedStep();
  useSetDefaultNameOnToolChange();
  useSetDefaultDescriptionOnToolChange();
  useSetDefaultInputsOnToolChange();
  if (!step) return null;
  return (
    <div className="p-h-16px p-b-16px flex justify-space-between flex-column flex-grow-1">
      <div className="flex-grow-1">
        <div className="flex align-i-center">
          <SelectStepType />
          <CloseStepButton className="m-l-8px" />
        </div>
        {topSettingsMapper[step.type]}
        <UpdateSelectedStepNameTextField margin="normal" label="Step name" />
        <UpdateSelectedStepDescriptionTextField
          margin="normal"
          label="Step description"
        />
        {settingsMapper[step.type]}
      </div>
      <RemoveStepButton stepId={step.id} />
    </div>
  );
}

export default StepSidebarInner;
