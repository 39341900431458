import useApiHandlerWithAuth from "../axios/useApiHandlerWithAuth";

const useImageRoutes = () => {
  const { apiHandlerWithAuth } = useApiHandlerWithAuth();

  const getImageProxy = async (
    imageUrl: string,
    parseResponse: (resp: Blob) => void
  ) => {
    try {
      // Encoding the image URL so it can be safely included as a query parameter
      const encodedUrl = encodeURIComponent(imageUrl);

      // Fetching the image through the proxy
      const result: Blob = await apiHandlerWithAuth({
        endpoint: `/imageProxy?url=${encodedUrl}`,
        method: "GET",
        axiosOptions: { responseType: "blob" }
      });

      // Assume apiHandlerWithAuth returns a blob in this case
      parseResponse(result);
    } catch (error) {
      console.error("Error getting image:", error);
    }
  };

  return {
    getImageProxy
  };
};

export default useImageRoutes;
