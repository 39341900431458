import type { ValidatedInput } from "@toolflow/shared";
import useToolInputFieldsAndAvailableFields from "../useToolInputFieldsAndAvailableFields";

const useGetInputNames = (id: string) => {
  const toolInputFields = useToolInputFieldsAndAvailableFields(id);
  // we only want fields that are actually available to the block
  return toolInputFields.map((i: ValidatedInput) => i.name);
};

const useGetFieldsToTestString = (input: string, id: string): string[] => {
  const inputNames = useGetInputNames(id);
  const regex = /{{(.*?)}}/g;
  let match;
  const tags: Set<string> = new Set();

  while ((match = regex.exec(input)) !== null) {
    if (inputNames.includes(match[1])) {
      tags.add(match[1]);
    }
  }

  // Convert Set back to array for returning
  return Array.from(tags);
};

export default useGetFieldsToTestString;
