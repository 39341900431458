import { useEffect } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";

const useHandleClickOutsideMenuControls = (
  ref: React.RefObject<HTMLDivElement>
) => {
  const { setShouldRenderBubble } = useMuiTipTapContext();
  // to close if you click outside of the editor menu controls
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }
      // this is necessary so that you can click listitems in the tool menu with your mouse
      const isInsideListItem =
        event.target.closest("#toolMenuListItem") !== null ||
        event.target.closest(".MuiButtonBase-root") !== null || //  this is for the select buttons (heading size etc)
        event.target.closest("#dontCloseOnClickId") !== null || // for the color pickers
        event.target.closest(".toolMenuTextField") !== null;
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !isInsideListItem
      ) {
        setShouldRenderBubble(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
};

export default useHandleClickOutsideMenuControls;
