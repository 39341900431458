import React from "react";
import InputLabel from "@mui/material/InputLabel";
import { useIsAuth0DatabaseConnection } from "../../../../auth/hooks/useIsAuth0DatabaseConnection";

function ProfileAction({
  title,
  children,
  className,
  isSocial
}: {
  title?: string;
  children: React.ReactNode;
  className?: string;
  isSocial?: boolean;
}) {
  const { isAuth0DatabaseConnection } = useIsAuth0DatabaseConnection();

  if (isSocial && !isAuth0DatabaseConnection()) return null;

  return (
    <>
      {title && (
        <InputLabel shrink className={className}>
          {title}
        </InputLabel>
      )}
      {children}
    </>
  );
}

export default ProfileAction;
