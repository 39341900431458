import { type TWorkspaceDataToSave } from "@toolflow/shared";
import { isArray } from "lodash";
import { useFormContext } from "react-hook-form";
import { useAssetContext } from "../../../../utilities/contexts/AssetContext";
import {
  WORKSPACE_VISIBLE_ASSET_ID,
  WORKSPACE_VISIBLE_SUB_ASSET_ID
} from "../helpers/workspaceConstants";

export default function useGetVisibleAssetValue() {
  const { asset } = useAssetContext();
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  const visibleAsset = watch(WORKSPACE_VISIBLE_ASSET_ID);
  const visibleSubAsset = watch(WORKSPACE_VISIBLE_SUB_ASSET_ID);

  let value = asset.value;
  if (
    visibleSubAsset &&
    visibleSubAsset.includes(visibleAsset) &&
    isArray(value)
  ) {
    const index = Number(visibleSubAsset.split("/").at(1) || 0);
    value = value.at(index);
  }

  return value;
}
