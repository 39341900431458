import useGetCurrentChatAgentIdOrDefault from "../../pages/workstation/components/chat/chatbox/hooks/useGetCurrentAgentIdOrDefault";
import AgentContextComponentContainer from "./AgentContextContainer";

function WorkspaceAgentContextContainer({
  children
}: {
  children: React.ReactNode;
}) {
  const { agentId, isDefault } = useGetCurrentChatAgentIdOrDefault();
  return (
    <AgentContextComponentContainer agentId={agentId} isDefault={isDefault}>
      {children}
    </AgentContextComponentContainer>
  );
}

export default WorkspaceAgentContextContainer;
