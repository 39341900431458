import type { RowUserInputDictType } from "@toolflow/shared";
import useValidateUserInput from "../../../../../../../../../../utilities/components/textFields/workspace/hooks/useValidateUserInput";
import { useSheetContext } from "../../SheetContext";

const useGetValidatedUserInputFromRow = () => {
  const { selectedTool } = useSheetContext();
  const validateUserInput = useValidateUserInput();

  const getValidatedUserInputFromRow = (original: RowUserInputDictType) => {
    const { componentId, rowLoading, columnIdSelected, ...rest } = original;
    const inputsAndOutputs = [
      ...(selectedTool?.main.toolInputFields || []),
      ...(selectedTool?.main.toolOutputFields || [])
    ];
    return validateUserInput(rest, inputsAndOutputs);
  };
  return getValidatedUserInputFromRow;
};

export default useGetValidatedUserInputFromRow;
