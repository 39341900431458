import { Box, useMediaQuery, type Theme } from "@mui/material";
import { ReactNode } from "react";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";
import AssetTOCPositioningWrapper from "../../assetTableOfContents/AssetTOCPositioningWrapper";
import useGetElementHeight from "../textAsset/hooks/useGetElementHeight";

function SmAssetInner({
  header,
  className,
  children
}: {
  header: ReactNode;
  className?: string;
  children: ReactNode;
}) {
  const { ref, height } = useGetElementHeight();
  return (
    <div className={classNames("w-100-percent", className)}>
      <div ref={ref}>{header}</div>
      <Box
        className="flex pos-relative h-100-percent"
        sx={{ maxHeight: `calc(100% - ${height}px)` }}
      >
        <Box className="pos-relative p-v-16px">{children}</Box>
        <AssetTOCPositioningWrapper />
      </Box>
    </div>
  );
}

function LgAssetInner({
  header,
  className,
  children
}: {
  header: ReactNode;
  className?: string;
  children: ReactNode;
}) {
  const { ref, height } = useGetElementHeight();
  return (
    <div className={classNames("w-100-percent flex", className)}>
      <div className={"w-100-percent m-h-auto parent-hover"}>
        <div ref={ref}>{header}</div>
        <Box
          className="pos-relative p-v-16px h-100-percent h-m-auto"
          sx={{ maxHeight: `calc(100% - ${height}px)` }}
        >
          {children}
        </Box>
      </div>
      <AssetTOCPositioningWrapper />
    </div>
  );
}

export default function AssetSizeSwitch({
  header,
  className,
  children
}: {
  header: ReactNode;
  className?: string;
  children: ReactNode;
}) {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return isSmall ? (
    <SmAssetInner className={className} header={header}>
      {children}
    </SmAssetInner>
  ) : (
    <LgAssetInner className={className} header={header}>
      {children}
    </LgAssetInner>
  );
}
