import InputOutputBase, { InputType } from "./InputOutputBase";
import useOpenOutputs from "../store/hooks/useOpenOutputs";
import { InputOutputPosition } from "./helpers/constants";
import { DrawerEnum } from "../store/toolBuilderSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores/store";
import { OutputIcon } from "../../../../../globalTheme/icons/icons";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

export const OutputBox = () => {
  const openOutputs = useOpenOutputs();
  const drawer = useSelector((state: RootState) => state.toolbuilder.drawer);
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  return (
    <InputOutputBase
      position={InputOutputPosition.BOTTOM}
      type={InputType.OUTPUT}
      entityCount={toolOutputFields.length}
      onClick={openOutputs}
      opened={drawer?.type === DrawerEnum.outputs}
      Icon={OutputIcon}
    />
  );
};
