import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";

const useNavigateToWorkspaceById = (workspaceId = "") => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject({
    type: "workspace",
    id: workspaceId
  });
  const handleNavigate = (e: React.MouseEvent) => {
    if (!workspaceId) return;
    navigateToWorkspace(e);
  };
  return handleNavigate;
};

export default useNavigateToWorkspaceById;
