import { FREE_PLAN_FE } from "../../../billing/helpers/billingConstants";
import { useAuthorizedTier } from "../../../billing/hooks/useAuthorizedTier";
import useGetMBLimit from "../hooks/useGetMBLimit";

export enum FileUploadError {
  EXTENSION = "extension",
  FILE_SIZE = "fileSize",
  GENERIC = "generic"
}

export const useGetFileErrorMessage = () => {
  const authorizedTier = useAuthorizedTier();
  const mbLimit = useGetMBLimit();

  return (errorType: FileUploadError) => {
    const messages = {
      [FileUploadError.GENERIC]: "Error on upload",
      [FileUploadError.EXTENSION]: "Unsupported file type",
      [FileUploadError.FILE_SIZE]: `File too big. Please keep files below ${mbLimit}MB.${
        authorizedTier === FREE_PLAN_FE
          ? " Upgrade to a paid plan for larger file sizes."
          : ""
      }`
    };

    return messages[errorType] || "";
  };
};
