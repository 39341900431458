import {
  BlockType,
  type BlockDataTypesWithDefault,
  type DecimalString
} from "@toolflow/shared";
import createBlockDict from "./helpers/createBlockDict";

const getDrawerComponent = (
  type: BlockDataTypesWithDefault["type"],
  version?: DecimalString
) => {
  const dict = createBlockDict<React.ElementType>("drawerComponent", version);

  if (type === BlockType.Anthropic) {
    return dict[BlockType.ChatGPT];
  }
  return dict[type] || dict.default;
};

export default getDrawerComponent;
