import React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import type { DallEOutputType, Image } from "@toolflow/shared";
import { Typography, ImageListItemBar } from "@mui/material";
import OutputLoadingWrapper from "../../../../../../utilities/components/loading/HideWhenLoadingWrapper";
import CopyImageBlobButton from "./CopyImageBlobButton";
import InsertImageBlobButton from "./InsertImageBlobButton";

const CopyableImage = ({
  showLoading,
  value = [],
  name,
  workspace,
  tvrId
}: {
  showLoading?: boolean;
  value: DallEOutputType;
  name?: string;
  workspace?: boolean;
  tvrId?: string;
}) => {
  try {
    return (
      <OutputLoadingWrapper showLoading={showLoading}>
        <div>
          {name && (
            <Typography variant="caption" color="GrayText" className="m-t-16px">
              {name}
            </Typography>
          )}

          <ImageList cols={3}>
            {value.map((obj: Image) => {
              if (!obj.url) return null;
              return (
                <ImageListItem key={obj.url}>
                  <ImageListItemBar
                    sx={{
                      background:
                        "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                        "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                    }}
                    position="top"
                    actionIcon={
                      <>
                        {workspace && (
                          <InsertImageBlobButton
                            url={obj.url}
                            tvrId={tvrId}
                            sx={{ color: "white" }}
                          />
                        )}
                        <CopyImageBlobButton
                          url={obj.url}
                          sx={{ color: "white" }}
                          tvrId={tvrId}
                        />
                      </>
                    }
                  />
                  <img src={obj.url} loading="lazy" />
                </ImageListItem>
              );
            })}
          </ImageList>
        </div>
      </OutputLoadingWrapper>
    );
  } catch (error) {
    console.error("Error in CopyableImage", { error, value, tvrId });
    return <></>;
  }
};

export default CopyableImage;
