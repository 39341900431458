import { BlockNode, BlockType, type TBlock } from "@toolflow/shared";
import { useBlockContext } from "../BlockContext";
import { useToolbuilderContext } from "../context/ToolBuilderContext";

export function checkIsIterationChild(
  parentBlockNames: string[],
  blocks: TBlock[]
) {
  if (!parentBlockNames) return false;
  const parentBlocks = parentBlockNames
    .map((name) => blocks?.find((b) => b.data.label === name))
    .filter((b) => !!b);

  let hasStartIteration = false;
  let hasExitIteration = false;
  parentBlocks.forEach((b) => {
    if (b.type === BlockNode.IterationStartBlockNode) hasStartIteration = true;
    if (b.type === BlockNode.IterationExitBlockNode) hasExitIteration = true;
  });

  return hasStartIteration && !hasExitIteration;
}

export default function useIterationHelpers() {
  const { blockData, id } = useBlockContext();
  const { state } = useToolbuilderContext();
  const {
    currentState: { availableFields, blocks }
  } = state;

  const isIterationBlock = [
    BlockType.IterationStart,
    BlockType.IterationExit
  ].includes(blockData.type as BlockType);

  const isIterationChild =
    !isIterationBlock && checkIsIterationChild(availableFields[id], blocks);

  return {
    isIterationChild,
    isIterationBlock
  };
}
