import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { DateTime } from "luxon";
import {
  useDeleteApiKeyMutation,
  useGetApiKeysQuery
} from "../../../../../ToolflowAPI/rtkRoutes/apiKeyApi";
import { BoldTableCell } from "../../../home/layout/TableLayouts";

function ManageAPIKeys() {
  const { data } = useGetApiKeysQuery();
  const [deleteApiKey, { isLoading }] = useDeleteApiKeyMutation();

  const handleDeleteApiKey = (apiKeyId: string) => {
    deleteApiKey(apiKeyId);
  };

  if (!data?.apiKeys) return null;
  const apiKeys = data.apiKeys;

  return (
    <>
      {apiKeys.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  Description
                </TableCell>
                <TableCell></TableCell>
                <TableCell
                  align="right"
                  sx={{ color: (theme) => theme.palette.text.secondary }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiKeys.map((apiKey) => (
                <TableRow key={apiKey._id}>
                  <BoldTableCell>
                    {apiKey.description || "No description"}
                  </BoldTableCell>
                  <TableCell>
                    {DateTime.fromSeconds(apiKey.createdAt).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <LoadingButton
                      size="small"
                      loading={isLoading}
                      onClick={() => handleDeleteApiKey(apiKey._id)}
                    >
                      Delete
                    </LoadingButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default ManageAPIKeys;
