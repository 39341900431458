import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { BASE_ROUTE_PATH } from "../../navigation/helpers/routePaths";

const useLoginRedirectToGetStarted = ({ signUp }: { signUp?: boolean }) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: signUp ? { screen_hint: "signup" } : undefined,
      appState: {
        returnTo: BASE_ROUTE_PATH
      }
    });
  }, [loginWithRedirect]);
};

export default useLoginRedirectToGetStarted;
