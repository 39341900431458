import { useCallback, useEffect } from "react";
import updateSheetDataWithSingleValue from "../functions/updateSheetDataWithSingleValue";
import getDefaultRow from "../functions/getDefaultRow";
import type { MultiUserInputResponseWithHandledError } from "@toolflow/shared";
import useConvertStringToTipTap from "../../../../../../../../../../utilities/formatters/strings/tiptap/hooks/useConvertStringToJSON";
import { TBulkSheetColumn, TColumnMap } from "../../sheetTypes";
import { useSheetContext } from "../../SheetContext";
import { textFieldTypeArray } from "../../../../../../../../../tools/components/editorToolCard/inputs/helpers/textFieldTypes";

const useHandleSheetPaste = ({
  columns,
  columnMap
}: {
  columns: TBulkSheetColumn[];
  columnMap: TColumnMap;
}) => {
  const { sheetData, setSheetData, selectedTool, selectedCell, sheetDataRef } =
    useSheetContext();
  const convertTextToTipTap = useConvertStringToTipTap();

  const convertValueToTipTap = (
    columnKey: string,
    value: MultiUserInputResponseWithHandledError
  ) => {
    const column = columnMap.get(columnKey);
    let newValue = value;
    if (column) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (textFieldTypeArray.includes(column.meta!.value.type)) {
        newValue = convertTextToTipTap(value as string);
      }
    }
    return newValue;
  };

  // Assuming `columns`, `selectedCell`, `sheetData`, `getDefaultRow`, and `setSheetData` are defined elsewhere
  const handleSheetPaste = useCallback(
    (event: ClipboardEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const clipboardData = event.clipboardData?.getData("text/html");
      if (!clipboardData || !selectedCell) return;

      // Create a DOM parser to parse the HTML string
      const parser = new DOMParser();
      const doc = parser.parseFromString(clipboardData, "text/html");

      // Extract table rows and cells
      const rows = Array.from(doc.querySelectorAll("table tr"));

      // if its not a csv type data, we want to paste it as a single value
      if (rows.length === 0) {
        const clipboardText = event.clipboardData?.getData("Text");
        if (!clipboardText) return;
        updateSheetDataWithSingleValue(
          selectedCell.rowIndex,
          selectedCell.columnId,
          convertValueToTipTap(selectedCell.columnId, clipboardText),
          sheetDataRef,
          setSheetData
        );
        // we don't use papaparse, because sometimes the delimiters can be weird. Easier to use the html and parse the table data
      } else {
        const toolColumns = columns[0].columns!; // eslint-disable-line @typescript-eslint/no-non-null-assertion
        const columnIndex = toolColumns.findIndex(
          (column) => column.accessorKey === selectedCell.columnId
        );
        if (columnIndex === -1) return;

        const updatedData = [...sheetData];
        rows.forEach((row, rowIndex) => {
          // Assert that each row is an HTMLTableRowElement to access its cells
          const rowElement = row as HTMLTableRowElement;
          const dataIndex = selectedCell.rowIndex + rowIndex;

          // Ensure the target row in updatedData exists
          if (!updatedData[dataIndex]) {
            updatedData[dataIndex] = getDefaultRow(selectedTool);
          }

          // Iterate over each cell in the current row
          Array.from(rowElement.cells).forEach((cell, cellIndex) => {
            const columnName =
              toolColumns[columnIndex + cellIndex]?.accessorKey;

            // Directly update the cell in updatedData with converted value
            if (columnName) {
              updatedData[dataIndex][columnName] = convertValueToTipTap(
                columnName,
                cell.innerText
              );
            }
          });
        });
        setSheetData(updatedData);
      }
    },
    [sheetData, selectedCell]
  );

  useEffect(() => {
    // Assuming the whole table is the target, adjust as needed
    document.addEventListener("paste", handleSheetPaste);

    return () => {
      document.removeEventListener("paste", handleSheetPaste);
    };
  }, [handleSheetPaste]);
};

export default useHandleSheetPaste;
