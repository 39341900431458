import { type DallEBlockPropsV2 } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useDallEFieldsConfig from "./useDallEFieldsConfig";

function DallEBlockInner({ data, id }: Omit<DallEBlockPropsV2, "selected">) {
  const { label } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const configs = useDallEFieldsConfig({ data, id });

  return (
    <div className="m-b-32px m-t-16px">
      <FieldsByType configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}

export default DallEBlockInner;
