import { FormControl, IconButton } from "@mui/material";
import { DeleteIcon } from "../../../../../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";

function DeleteIconButtonBase({
  disabled,
  className,
  size = 20,
  color = toolflowTextDisabledColor,
  onClick
}: {
  disabled?: boolean;
  className?: string;
  size?: number;
  color?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <FormControl disabled={disabled} className={className}>
      <IconButton onClick={onClick} disabled={disabled} disableTouchRipple>
        <DeleteIcon size={size} color={color} />
      </IconButton>
    </FormControl>
  );
}

export default DeleteIconButtonBase;
