import React from "react";
import { useSettingsDropdownContext } from "../useSettingsDropownContext";
import WrappedIconButton from "./WrappedIconButton";
import { IIconButtonProps, TTooltipSettingsProps } from "./settingsTypes";

function SettingsButtonComponent({
  IconButtonProps,
  TooltipProps,
  icon
}: {
  IconButtonProps?: IIconButtonProps;
  TooltipProps?: TTooltipSettingsProps;
  icon: React.ReactNode;
}) {
  const { open, handleClick } = useSettingsDropdownContext();
  return (
    <WrappedIconButton
      {...IconButtonProps}
      TooltipProps={TooltipProps}
      handleClick={handleClick}
      open={open}
    >
      {icon}
    </WrappedIconButton>
  );
}

export default SettingsButtonComponent;
