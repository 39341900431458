import React from "react";
import { Icon } from "@mui/material";

function ImageCircle({
  className = "",
  width = 92,
  imageSrc
}: {
  width?: number;
  className?: string;
  imageSrc: string;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        background: "transparent",
        borderRadius: 3,
        padding: 1,
        height: width,
        width: width
      }}
    >
      <Icon
        className="flex h-100-percent w-100-percent align-i-center justify-center"
        sx={{ borderRadius: "50%" }}
      >
        <img src={imageSrc} height={width} />
      </Icon>
    </div>
  );
}

export default ImageCircle;
