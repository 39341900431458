import { ListItemText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { type SelectInputProps } from "@mui/material/Select/SelectInput";
import { type SelectFieldV1Props, type SelectOption } from "@toolflow/shared";
import { isString } from "lodash";
import useFieldsByTypeHelper from "../useFieldsByTypeHelper";
import InputLabelWrapper from "./helpers/InputLabelWrapper";
import { useFieldsByTypeContext } from "../FieldsByTypeContext";

interface Props {
  id: string;
  value: string;
  fieldKey: string;
  label: string;
  subtitle?: string;
  options: SelectOption[];
  callback?: (value: string) => void;
}

function NewSelectField({
  value,
  fieldKey,
  label,
  options,
  subtitle,
  callback
}: Props) {
  const { updateField } = useFieldsByTypeContext();

  const onChange: SelectInputProps["onChange"] = (event) => {
    updateField(event.target.value as string, fieldKey);
    if (callback) {
      callback(event.target.value as string);
    }
  };

  return (
    <FormControl fullWidth margin="normal">
      <InputLabelWrapper label={label} subtitle={subtitle}>
        <Select
          size="small"
          value={value}
          className="nowheel nodrag nopan"
          onChange={onChange}
          renderValue={(val) => {
            const option = options.find((opt) =>
              isString(opt) ? opt === val : opt.value === val
            );
            return (
              <ListItemText
                primary={isString(option) ? option : option?.label}
              />
            );
          }}
        >
          {options.map((option, i) => {
            if (isString(option)) {
              return (
                <MenuItem value={option} key={i}>
                  <ListItemText primary={option} />
                </MenuItem>
              );
            }

            return (
              <MenuItem value={option.value} key={option.value}>
                <ListItemText
                  primary={option.label}
                  secondary={option.subText}
                />
              </MenuItem>
            );
          })}
        </Select>
      </InputLabelWrapper>
    </FormControl>
  );
}

export default function NewSelectFieldWrapper({
  field,
  settings,
  id
}: SelectFieldV1Props) {
  const { fieldKey, options, callback } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  return (
    <NewSelectField
      id={id}
      label={field.label}
      subtitle={field.subtitle}
      options={options}
      value={getFieldValue(fieldKey, 0)}
      fieldKey={fieldKey}
      callback={callback}
    />
  );
}
