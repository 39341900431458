import { useEffect } from "react";
import updateSheetDataWithSingleValue from "../functions/updateSheetDataWithSingleValue";
import { useSheetContext } from "../../SheetContext";

const useUnhighlightCell = () => {
  const { sheetDataRef, setSheetData, setSelectedCell } = useSheetContext();
  // This unhighlights a cell when not clicking on it
  useEffect(() => {
    const handleGlobalClick = (event: MouseEvent) => {
      const path = event.composedPath();
      if (path) {
        try {
          const isClickInsideCell = path.some(
            (el) =>
              (el as HTMLElement).className &&
              (el as HTMLElement).className?.includes("muiTableBodyCellClick")
          );
          if (!isClickInsideCell) {
            setSelectedCell(null);
            sheetDataRef.current.forEach((row, index) => {
              updateSheetDataWithSingleValue(
                index,
                "columnIdSelected",
                "",
                sheetDataRef,
                setSheetData
              );
            });
          }
        } catch {}
      }
    };

    document.addEventListener("mousedown", handleGlobalClick);

    return () => {
      document.removeEventListener("mousedown", handleGlobalClick);
    };
  }, []);
};

export default useUnhighlightCell;
