import { useSheetContext } from "../../SheetContext";
import updateSheetDataWithSingleValue from "../functions/updateSheetDataWithSingleValue";

const useSelectDeselectCell = () => {
  const { sheetDataRef, setSheetData, setSelectedCell } = useSheetContext();
  const selectCell = (rowIndex: number, columnId: string) => {
    setSelectedCell({ rowIndex, columnId });
    updateSheetDataWithSingleValue(
      rowIndex,
      "columnIdSelected",
      columnId,
      sheetDataRef,
      setSheetData
    );
  };

  const deselectCell = (rowIndex: number) => {
    setSelectedCell(null);
    updateSheetDataWithSingleValue(
      rowIndex,
      "columnIdSelected",
      "",
      sheetDataRef,
      setSheetData
    );
  };
  return { selectCell, deselectCell };
};

export default useSelectDeselectCell;
