import type { TBlock, CustomEdge } from "@toolflow/shared";
import { useCallback } from "react";
import {
  type Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers
} from "reactflow";
import useUpdateAvailableFields from "./useUpdateAvailableFields";
import { Action } from "../toolBuilderReducer";

interface UseOnNodesDeleteHookArgs {
  blocks: TBlock[];
  edges: CustomEdge[];
  setEdges: (edges: CustomEdge[]) => void;
  dispatch: React.Dispatch<Action>;
}

const useOnNodesDelete = ({
  blocks,
  edges,
  setEdges,
  dispatch
}: UseOnNodesDeleteHookArgs) => {
  const updateAvailableFields = useUpdateAvailableFields(blocks, dispatch);
  return useCallback(
    (deleted: TBlock[]) => {
      setEdges(
        deleted.reduce((acc: CustomEdge[], block: TBlock) => {
          const incomers = getIncomers(block, blocks, edges as Edge[]);
          const outgoers = getOutgoers(block, blocks, edges as Edge[]);
          const connectedEdges = getConnectedEdges([block], edges as Edge[]);

          const remainingEdges = acc.filter(
            (edge) => !connectedEdges.includes(edge as Edge)
          );

          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoers.map(({ id: target }) => ({
              id: `e${source}->${target}`, // must include -> for updateAvailableFields
              source,
              target,
              data: { deletable: true }
            }))
          );
          const nS = [...remainingEdges, ...createdEdges];
          createdEdges.map((edge) => updateAvailableFields(edge.target, nS));

          dispatch({
            type: "DELETE_AVAILABLE_FIELDS",
            id: block.id
          });

          return nS;
        }, edges)
      );
    },
    [blocks, edges, setEdges]
  );
};

export default useOnNodesDelete;
