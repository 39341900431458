import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { getNestedComparator, stableSort } from "../utlils/tableUtils";
import {
  HeadCell,
  Order,
  TSortOptions,
  ToolsTableData
} from "../utlils/tableTypes";
import { StyledTableCell, StyledTableRow } from "../layout/TableLayouts";
import BaseSettingsDropdown from "../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import useTablePagination from "../hooks/useTablePagination";
import HomePagePaginatedTableHead from "../utlils/HomePagePaginatedTableHead";
import EntityTableRow from "./EntityTableRow";

function ToolsPaginatedTable({
  rows,
  headCells,
  initialOrderBy = "entity.updatedAt",
  initialOrderDirection = "desc"
}: {
  rows: ToolsTableData[];
  headCells: readonly HeadCell[];
  initialOrderBy?: TSortOptions;
  initialOrderDirection?: Order;
}) {
  const [order, setOrder] = React.useState<Order>(initialOrderDirection);
  const [orderBy, setOrderBy] = React.useState<TSortOptions>(initialOrderBy);

  const {
    handleChangePage,
    handleChangeRowsPerPage,
    page,
    rowsPerPage,
    rowsPerPageOptions
  } = useTablePagination();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof ToolsTableData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getNestedComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    // we use JSON.stringify(rows) to re-render the visibleRows when the rows change, like when a tool is deleted
    [order, orderBy, page, rowsPerPage, JSON.stringify(rows)]
  );

  return (
    <BaseSettingsDropdown>
      <div className="p-h-16px">
        <TableContainer>
          <Table sx={{ minWidth: 900 }} aria-labelledby="tableTitle">
            <HomePagePaginatedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={(event, property) =>
                handleRequestSort(event, property as keyof ToolsTableData)
              }
              headCells={headCells}
            />
            <TableBody>
              {visibleRows.map((row) => (
                <EntityTableRow key={row.entity.id} tableRow={row} />
              ))}
              {emptyRows > 0 && (
                <StyledTableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <StyledTableCell colSpan={6} />
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </BaseSettingsDropdown>
  );
}

export default ToolsPaginatedTable;
