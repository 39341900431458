import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../../stores/store";
import CloseTestPanelButton from "./CloseTestPanelButton";
import OpenTestPanelButton from "./OpenTestPanelButton";

function TestAndPreviewButton() {
  const drawerExpanded = useSelector(
    (state: RootState) => state.toolbuilder.drawerExpanded
  );

  if (drawerExpanded) {
    return <CloseTestPanelButton />;
  } else {
    return <OpenTestPanelButton />;
  }
}

export default TestAndPreviewButton;
