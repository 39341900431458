import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_ENTITY_TYPE_FIELD_LABEL } from "../../helpers/workspaceConstants";

export const useClearSelectedToolFields = (disableSave = false) => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const clearSelectedTool = () => {
    setValue(WORKSPACE_ENTITY_TYPE_FIELD_LABEL, "", {
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
  };
  return clearSelectedTool;
};
