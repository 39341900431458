import { TextField } from "@mui/material";
import {
  type CustomSelectInputWrapperProps,
  InputType,
  type BlockSettingsWithInputType,
  CustomSelectInputValueType
} from "@toolflow/shared";
import { isArray } from "lodash";
import SelectWithInput from "../selectWithInput/SelectWithInput";
import useUpdateField from "../../../../useUpdateField";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../../globalTheme/muiUtils/appTheme";

export default function InputTypeSelectWrapper({
  id,
  field,
  settings
}: CustomSelectInputWrapperProps) {
  const { inputType } = settings;
  const updateField = useUpdateField(id);
  const { fieldKey, inputValueType, sourceLabel, userFieldKey } = field.config;
  const isArrayInput = inputValueType === CustomSelectInputValueType.Array;
  const inputKeyValue = settings[fieldKey as keyof BlockSettingsWithInputType];
  const stringifiedInputValue =
    isArrayInput && isArray(inputKeyValue)
      ? inputKeyValue.join(",")
      : inputKeyValue;

  return (
    <SelectWithInput
      id={id}
      label={sourceLabel}
      settings={settings}
      inputTypeValue={
        inputType === InputType.FixedInput
          ? InputType.FixedInput
          : inputType === InputType.BlockInput
            ? stringifiedInputValue.replace("{{", "").replace("}}", "")
            : inputType === InputType.UserInput
              ? settings[userFieldKey as keyof BlockSettingsWithInputType]
              : inputType
      }
      inputKey={fieldKey}
      userFieldKey={userFieldKey}
      isArrayInput={isArrayInput}
    >
      <TextField
        label={field.label}
        multiline
        maxRows={MAX_ROWS_LARGE_TEXTFIELD}
        value={stringifiedInputValue}
        required={inputType === InputType.FixedInput}
        onChange={(e) => {
          const value = isArrayInput
            ? e.target.value.split(",")
            : e.target.value;
          updateField(value, `settings.${fieldKey}`);
        }}
      />
    </SelectWithInput>
  );
}
