import { ResolvedPos } from "@tiptap/pm/model";
import { EditorView } from "@tiptap/pm/view";

const executeBackspace = (view: EditorView, event: KeyboardEvent) => {
  // node may be nested, so we need to find the base node
  const findPrevNode = ($pos: ResolvedPos) => {
    let depth = $pos.depth;
    while (depth > 0) {
      const before = $pos.before(depth);

      if (before) {
        return $pos.doc.resolve(before).nodeBefore;
      }
      depth--;
    }
    return null;
  };

  const { $from, $to } = view.state.selection;
  const prevNode = findPrevNode($from);
  const isTextSelected = $from.pos !== $to.pos;
  // we don't want people pressing delete to delete a tool
  if (
    prevNode &&
    ["toolResponseComponent", "toolComponent"].includes(prevNode.type.name) &&
    $from.parentOffset === 0 &&
    !isTextSelected
  ) {
    event.preventDefault();
    return true;
  }

  return false;
};

export default executeBackspace;
