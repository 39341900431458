import useGetAssetIndexById from "./useGetAssetIndexById";
import useWorkspaceAssets from "./useWorkspaceAssets";

const useIsLastAsset = (assetId: string) => {
  const assetIndex = useGetAssetIndexById(assetId);
  const assetsLength = useWorkspaceAssets().length;
  return assetIndex === assetsLength - 1;
};

export default useIsLastAsset;
