import { Chip } from "@mui/material";
import type { TBlocksTagsLabels } from "@toolflow/shared";
import React, { FC } from "react";
import { lightButtonBorder } from "../../../globalTheme/muiUtils/appTheme";

interface IBlockTagProps {
  blockLabel: TBlocksTagsLabels | string;
  icon?: JSX.Element;
  index?: number;
}
const BlockTag: FC<IBlockTagProps> = ({ blockLabel, icon }) => {
  return (
    <Chip
      label={blockLabel}
      variant="outlined"
      style={{
        background: "transparent",
        borderColor: lightButtonBorder,
        padding: "4px, 6px, 4px, 8px",
        borderRadius: 8,
        marginRight: 8
      }}
      icon={icon}
    />
  );
};

export default BlockTag;
