import React from "react";
import WrappedMenuButton from "./WrappedMenuButton";
import { IMenuButtonProps } from "./settingsTypes";

function SettingsMenuButtonComponent({
  MenuButtonProps,
  icon
}: {
  MenuButtonProps?: IMenuButtonProps;
  icon: React.ReactNode;
}) {
  return (
    <WrappedMenuButton MenuButtonProps={MenuButtonProps}>
      {icon}
    </WrappedMenuButton>
  );
}

export default SettingsMenuButtonComponent;
