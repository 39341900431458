import React, { useState } from "react";
import { EntityType, type VisibilityTypes } from "@toolflow/shared";
import SettingsMenuItem from "../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { ShareDialogBase } from "../../../utilities/components/sharing/ShareDialog";
import { UpdateVisibilitySelectBase } from "../../../utilities/components/sharing/updateVisibilitySelect/UpdateVisibilitySelectBase";
import { useSettingsDropdownContext } from "../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import type { SelectChangeEvent } from "@mui/material";
import { useUpdateWorkflowMutation } from "../../../ToolflowAPI/rtkRoutes/workflowApi";
import { ShareIcon } from "../../../globalTheme/icons/icons";
import { UpdateEnableForkingBase } from "../../../utilities/components/sharing/updateEnableForking/UpdateEnableForkingBase";

function UpdateWorkflowVisibilityMenuItem({
  id,
  visibility,
  enableForking,
  name
}: {
  id: string;
  visibility: VisibilityTypes;
  name: string;
  enableForking: boolean;
}) {
  const { handleClose } = useSettingsDropdownContext();
  const [updateWorkflow, { isLoading }] = useUpdateWorkflowMutation();

  const handlePrivacy = (e: SelectChangeEvent<VisibilityTypes>) => {
    updateWorkflow({
      body: { visibility: e.target.value as VisibilityTypes },
      workflowId: id
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const handleEnableForking = (value: boolean) => {
    updateWorkflow({
      body: { enableForking: value },
      workflowId: id
    });
  };

  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };

  return (
    <>
      <SettingsMenuItem
        disabled={isLoading}
        action={handleOpen}
        text={"Share"}
        hotkey="Meta+Shift+V"
        icon={<ShareIcon fontSize="small" />}
        hide={!id}
      />
      <ShareDialogBase
        id={id}
        open={open}
        closeDialog={close}
        loading={isLoading}
        name={name}
        type={EntityType.WORKFLOW}
      >
        <UpdateVisibilitySelectBase
          handleSelectChange={handlePrivacy}
          visibility={visibility}
          formProps={{ fullWidth: true }}
        />
        <UpdateEnableForkingBase
          handleEnableForking={handleEnableForking}
          enableForking={enableForking}
          formProps={{ fullWidth: true }}
        />
      </ShareDialogBase>
    </>
  );
}

export default UpdateWorkflowVisibilityMenuItem;
