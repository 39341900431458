import React from "react";
import { UtiltityTooltip } from "../../../../../../../../../utilities/components/toolTips/UtiltityTooltip";
import { useAuthorizedTier } from "../../../../../../../../billing/hooks/useAuthorizedTier";
import { FREE_PLAN_FE } from "../../../../../../../../billing/helpers/billingConstants";
import { PLEASE_UPGRADE_TEXT } from "./sheetConstants";
import { Button } from "@mui/material";
import ButtonLinkToBilling from "../../../../../../../builder/toolBuilder/common/ButtonLinkToBilling";
import { TMaterialRow, TMaterialTable } from "../sheetTypes";

const RunSelectedRowsButton = ({
  table,
  handleRunMultipleRows
}: {
  table: TMaterialTable;
  handleRunMultipleRows: (rows: TMaterialRow[]) => void;
}) => {
  const authorizedTier = useAuthorizedTier();
  return (
    <>
      <UtiltityTooltip
        title={
          authorizedTier === FREE_PLAN_FE ? PLEASE_UPGRADE_TEXT : undefined
        }
      >
        <div className="m-l-8px">
          <Button
            onClick={() =>
              handleRunMultipleRows(table.getSelectedRowModel().rows)
            }
            variant="contained"
            disabled={authorizedTier === FREE_PLAN_FE}
          >
            Run {table.getSelectedRowModel().rows.length} row
            {table.getSelectedRowModel().rows.length === 1 ? "" : "s"}
          </Button>
        </div>
      </UtiltityTooltip>
      {authorizedTier === FREE_PLAN_FE && (
        <ButtonLinkToBilling className="m-h-8px" />
      )}
    </>
  );
};

export default RunSelectedRowsButton;
