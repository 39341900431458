import { useCallback, useState } from "react";

type UpdateFunction<S> = (currentState: S) => S;

export const useUpdateQueue = <S>() => {
  const [queue, setQueue] = useState<UpdateFunction<S>[]>([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const queueUpdate = useCallback((updateFunction: UpdateFunction<S>) => {
    setQueue((currentQueue) => [...currentQueue, updateFunction]);
  }, []);

  // Expose method to process next update and return it for external execution
  const processNextUpdate = useCallback((): UpdateFunction<S> | undefined => {
    if (!isUpdating && queue.length > 0) {
      setIsUpdating(true); // Mark as updating
      const nextUpdate = queue.shift(); // Get the next update function
      setQueue((currentQueue) => [...currentQueue]); // Trigger re-render
      setIsUpdating(false); // Reset updating flag
      return nextUpdate; // Return the next update function
    }
  }, [queue, isUpdating]);

  return { queueUpdate, processNextUpdate };
};
