import useCopyFromHtml from "../../../../../../../../utilities/components/copyContent/hooks/useCopyFromHtml";
import { type Content } from "@tiptap/core";
import useConvertContentToHtml from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useConvertContentToHtml";

const useCopyContent = () => {
  const copyFromHtml = useCopyFromHtml();
  const convertContentToHtml = useConvertContentToHtml();
  const copyContent = (content: Content) => {
    if (!content) return;
    const htmlContent = convertContentToHtml(content);
    copyFromHtml(htmlContent);
  };
  return copyContent;
};

export default useCopyContent;
