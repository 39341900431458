import { useDispatch } from "react-redux";
import { setDialogOpen, setDrawer } from "../toolBuilderSlice";

const useCloseDrawer = () => {
  const dispatch = useDispatch();
  const closeDrawer = () => {
    dispatch(setDrawer(null));
    dispatch(setDialogOpen(false));
  };
  return closeDrawer;
};

export default useCloseDrawer;
