import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAbortComponentMutation } from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";

const useInnerBlockState = () => {
  const [componentId, setComponentId] = useState(uuidv4());
  const [abortComponent] = useAbortComponentMutation();
  const abort = () => {
    abortComponent(componentId);
    // if (sendMessage) {
    //   sendMessage(
    //     JSON.stringify({
    //       type: "abortStream",
    //       payload: { componentId }
    //     })
    //   );
    // }
    if (setComponentId) {
      setComponentId(uuidv4());
    }
  };

  return {
    componentId,
    abort
  };
};

export default useInnerBlockState;
