import type {
  BlockSettingsBase,
  FieldTypeConfigs,
  UpdateField
} from "@toolflow/shared";
import React, { createContext, useContext } from "react";

interface FieldsByTypeContextType<T = FieldTypeConfigs, D = BlockSettingsBase> {
  id: string;
  field: T;
  data: D;
  updateField: UpdateField;
}

interface Props {
  configs: {
    id: string;
    field: FieldTypeConfigs;
    data: BlockSettingsBase;
    updateField: UpdateField;
  };
  children: React.ReactNode;
}

const FieldsByTypeContext = createContext<FieldsByTypeContextType | undefined>(
  undefined
);

export function FieldsByTypeContextComponent({
  configs,
  children
}: Readonly<Props>) {
  if (!configs) {
    return null;
  }
  return (
    <FieldsByTypeContext.Provider value={configs}>
      {children}
    </FieldsByTypeContext.Provider>
  );
}

export function useFieldsByTypeContext<
  T = FieldTypeConfigs,
  D = BlockSettingsBase
>() {
  const context = useContext(FieldsByTypeContext);
  if (context === undefined) {
    throw new Error(
      "useFieldsByTypeContext must be used within a FieldsByTypeProvider"
    );
  }
  return context as FieldsByTypeContextType<T, D>;
}

export default FieldsByTypeContext;
