import { useMemo } from "react";
import {
  useAllMarketplaceAgentEntities,
  useAllMarketplaceEntities
} from "../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useToolIsPopular = (objectId: string) => {
  const allMarketplaceEntities = useAllMarketplaceEntities();
  const allMarketplaceAgents = useAllMarketplaceAgentEntities();
  return useMemo(() => {
    return (
      !!allMarketplaceEntities.find(
        (entity) => entity.id === objectId && entity.isPopular
      ) ||
      !!allMarketplaceAgents.find(
        (agent) => agent.id === objectId && agent.isPopular
      )
    );
  }, [allMarketplaceEntities, allMarketplaceAgents, objectId]);
};

export default useToolIsPopular;
