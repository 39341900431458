import { type UseFormReset } from "react-hook-form";
import type { TAgentDataToSave } from "@toolflow/shared";
import useDefaultAgentFormValues from "../../hooks/useDefaultAgentFormValues";

const useAgentResetDefaultNeedsReset = (
  reset: UseFormReset<TAgentDataToSave>
) => {
  const defaultValues = useDefaultAgentFormValues();
  const agentResetDefault = () => {
    reset(defaultValues);
  };

  return agentResetDefault;
};

export default useAgentResetDefaultNeedsReset;
