import React from "react";
import AssetContextComponentContainer from "../../../../../../../../utilities/contexts/AssetContextContainer";
import AssetListItem from "../assetListItem/AssetListItem";

function AssetIterator({ assets }: { assets: string[] }) {
  return (
    <>
      {assets.map((assetId) => (
        <AssetContextComponentContainer key={assetId} assetId={assetId}>
          <AssetListItem />
        </AssetContextComponentContainer>
      ))}
    </>
  );
}

export default AssetIterator;
