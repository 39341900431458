import {
  DynamicFieldType,
  FieldType,
  OpenAiImageModel,
  type DallE3BlockOptimization,
  type DallEBlockPropsV2,
  type FieldConfig,
  type FieldTypeConfigs
} from "@toolflow/shared";
import { useEffect } from "react";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";
import useUpdateField from "../../useUpdateField";

const dallECharObject = {
  [OpenAiImageModel.DALLE2]: 1000,
  [OpenAiImageModel.DALLE3]: 4000
};

export default function useDallEFieldsConfig({
  data,
  id
}: Omit<DallEBlockPropsV2, "selected">) {
  const updateField = useUpdateField(id);
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);
  const forceDynamic =
    data.optimizations.model.type === DynamicFieldType.Dynamic;

  const model =
    data.optimizations.model.type === DynamicFieldType.Preset
      ? data.optimizations.model.value
      : null;
  const dallECharCount =
    data.optimizations.model.type === DynamicFieldType.Preset
      ? dallECharObject[data.optimizations.model.value]
      : 1000;

  const dallE2Fields: FieldTypeConfigs[] = [
    {
      type: FieldType.DynamicSelectField,
      label: "Size",
      config: {
        typeKey: "optimizations.size.type",
        fieldKey: "optimizations.size.value",
        options: ["256x256", "512x512", "1024x1024"],
        forceDynamic
      }
    },
    {
      type: FieldType.DynamicInputField,
      label: "Count",
      config: {
        typeKey: "optimizations.n.type",
        fieldKey: "optimizations.n.value",
        htmlInputType: "number",
        forceDynamic
      }
    }
  ];

  const dallE3Fields: FieldTypeConfigs[] = [
    {
      type: FieldType.DynamicSelectField,
      label: "Size",
      config: {
        typeKey: "optimizations.size.type",
        fieldKey: "optimizations.size.value",
        options: ["1024x1024", "1792x1024", "1024x1792"],
        forceDynamic
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Quality",
      config: {
        typeKey: "optimizations.quality.type",
        fieldKey: "optimizations.quality.value",
        options: [
          { label: "Standard", value: "standard" },
          { label: "HD", value: "hd" }
        ],
        forceDynamic
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Style",
      config: {
        typeKey: "optimizations.style.type",
        fieldKey: "optimizations.style.value",
        options: [
          { label: "Vivid", value: "vivid" },
          { label: "Natural", value: "natural" }
        ],
        forceDynamic
      }
    }
  ];

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        type: FieldType.PromptInputV2,
        label: "Prompt",
        subtitle: dallECharCount
          ? `Max ${dallECharCount} characters. If you are piping in a response from another prompt, you may want to summarize the response to be fewer than ${dallECharCount} characters.`
          : undefined,
        config: {
          fieldKey: "settings.prompt",
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          autofocus: true,
          textFieldClassName: "min-h-246px",
          chipsToUse
        }
      },
      {
        type: FieldType.DynamicSelectField,
        label: "Model",
        subtitle: "Select image generation technology.",
        config: {
          typeKey: "optimizations.model.type",
          fieldKey: "optimizations.model.value",
          options: [
            { label: "Dall-E-2", value: OpenAiImageModel.DALLE2 },
            { label: "Dall-E-3", value: OpenAiImageModel.DALLE3 }
          ]
        }
      },
      {
        type: FieldType.Group,
        label: "Model settings",
        fields: model === OpenAiImageModel.DALLE3 ? dallE3Fields : dallE2Fields // In case of dynamic model input, and we don't know the model, fallback to dalle2 fields
      }
    ]
  };

  useEffect(() => {
    // On model change need to reset values and make sure proper values are selected for current selected model.
    if (data.optimizations.model.value === OpenAiImageModel.DALLE3) {
      const optimizations = data.optimizations as DallE3BlockOptimization;
      if (optimizations.size.type !== DynamicFieldType.Dynamic) {
        updateField("1024x1024", "optimizations.size.value");
      }

      if (optimizations.n.type !== DynamicFieldType.Dynamic) {
        updateField(1, "optimizations.n.value");
      }

      if (optimizations.quality?.type !== DynamicFieldType.Dynamic) {
        updateField(DynamicFieldType.Preset, "optimizations.quality.type");
        updateField("standard", "optimizations.quality.value");
      }

      if (optimizations.style?.type !== DynamicFieldType.Dynamic) {
        updateField(DynamicFieldType.Preset, "optimizations.style.type");
        updateField("vivid", "optimizations.style.value");
      }
    } else {
      if (data.optimizations.size.type !== DynamicFieldType.Dynamic) {
        updateField("512x512", "optimizations.size.value");
      }

      if (data.optimizations.n.type !== DynamicFieldType.Dynamic) {
        updateField(1, "optimizations.n.value");
      }
    }
  }, [data.optimizations.model.value]);

  return configs;
}
