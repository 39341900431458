import { Button } from "@mui/material";
import { ArrowBackIcon } from "../../../../../../../../globalTheme/icons/icons";

function BackButtonBase({ onClick }: { onClick: () => void }) {
  return (
    <Button
      variant="text"
      size="small"
      onClick={onClick}
      className="bg-color-white"
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
}

export default BackButtonBase;
