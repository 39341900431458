import { useEffect, useState } from "react";
import { useSheetContext } from "../../SheetContext";

const useColumnVisibility = () => {
  const { selectedTool } = useSheetContext();
  const [columnVisibility, setColumnVisibility] = useState({});

  useEffect(() => {
    if (selectedTool) {
      setColumnVisibility({ "mrt-row-actions": true, "mrt-row-select": true }); //programmatically show firstName column
    } else {
      setColumnVisibility({
        "mrt-row-actions": false,
        "mrt-row-select": false,
        "mrt-row-numbers": false
      }); //programmatically show firstName column
    }
  }, [selectedTool]);

  return columnVisibility;
};

export default useColumnVisibility;
