import { IdBase, TSelect } from ".";
import { TCheckbox } from "./checkbox";
import {
  CompatibilityOptionWithOptions,
  CompatibilityOptionWithOutOptions
} from "./compatibilityOptions";
import { TCSVFileUpload } from "./csvFileUpload";
import { TDoubleTextfield } from "./doubleTextField";
import { TFileUpload } from "./fileUpload";
import { TMultiSelect } from "./multiSelect";
import { TTagsInput } from "./tags";
import { TTextFieldTypes } from "./textField";

// CustomToolInputFields are compatibility options with ids

export type CustomToolInputFieldTypes =
  | TSelect
  | TCheckbox
  | TTextFieldTypes
  | TCSVFileUpload
  | TFileUpload
  | TMultiSelect
  | TTagsInput
  | TDoubleTextfield;

// TODO: we should combine CustomeToolInputFieldTypes with Enum
export enum CustomToolInputFieldTypesEnum {
  TEXTFIELD = "textField",
  LARGE_TEXTFIELD = "largeTextField",
  SELECT = "select",
  CHECKBOX = "checkbox",
  FILE_UPLOAD = "fileUpload",
  MULTI_SELECT = "multiSelect",
  TAGS_INPUT = "tagsInput",
  DOUBLE_TEXTFIELD = "doubleTextfield",
  CSV_FILE_UPLOAD = "csvFileUpload",
  BLOCK_OUTPUT = "blockOutput"
}

export type TInputTypePrimitive = {
  type: string;
  items?: { type: string };
  description?: string;
  required?: boolean;
};
export type TInputTypePrimitiveMap = {
  [key in CustomToolInputFieldTypesEnum]: TInputTypePrimitive;
};

export const inputTypePrimitiveMap: TInputTypePrimitiveMap = {
  [CustomToolInputFieldTypesEnum.TEXTFIELD]: { type: "string" },
  [CustomToolInputFieldTypesEnum.LARGE_TEXTFIELD]: { type: "string" },
  [CustomToolInputFieldTypesEnum.SELECT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.CHECKBOX]: { type: "boolean" },
  [CustomToolInputFieldTypesEnum.FILE_UPLOAD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.MULTI_SELECT]: { type: "string" },
  [CustomToolInputFieldTypesEnum.TAGS_INPUT]: {
    type: "array",
    items: { type: "string" }
  },
  [CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.CSV_FILE_UPLOAD]: { type: "string" }, // TODO: this is not right
  [CustomToolInputFieldTypesEnum.BLOCK_OUTPUT]: { type: "string" }
};

export type TBlockOutput = "blockOutput";

export type ValidatedInputTypes = CustomToolInputFieldTypes | TBlockOutput;

export type CustomToolInputFieldWithOptions = CompatibilityOptionWithOptions &
  IdBase;

export type CustomToolInputFieldWithOutOptions =
  CompatibilityOptionWithOutOptions & IdBase;

export type CustomToolInputField =
  | CustomToolInputFieldWithOptions
  | CustomToolInputFieldWithOutOptions;
