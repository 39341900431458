import React from "react";
import RowWrapper from "./RowWrapper";
import { UtiltityTooltip } from "../../../../../../../../../utilities/components/toolTips/UtiltityTooltip";
import { IconButton } from "@mui/material";
import { useAuthorizedTier } from "../../../../../../../../billing/hooks/useAuthorizedTier";
import { FREE_PLAN_FE } from "../../../../../../../../billing/helpers/billingConstants";
import { PLEASE_UPGRADE_TEXT } from "./sheetConstants";
import ButtonLinkToBilling from "../../../../../../../builder/toolBuilder/common/ButtonLinkToBilling";
import { TMaterialRow } from "../sheetTypes";
import { PlayIcon } from "../../../../../../../../../globalTheme/icons/icons";

const RunSingleRowButton = ({
  runSingleTool,
  row
}: {
  row: TMaterialRow;
  runSingleTool: (row: TMaterialRow) => Promise<void>;
}) => {
  const authorizedTier = useAuthorizedTier();
  return (
    <RowWrapper row={row}>
      <UtiltityTooltip
        title={authorizedTier === FREE_PLAN_FE ? PLEASE_UPGRADE_TEXT : ""}
      >
        <div>
          <IconButton
            color="primary"
            size="small"
            className="m-r-8px"
            onClick={() => runSingleTool(row)}
            disabled={authorizedTier === FREE_PLAN_FE}
          >
            <PlayIcon fontSize="small" />
          </IconButton>
        </div>
      </UtiltityTooltip>
      {authorizedTier === FREE_PLAN_FE && (
        <ButtonLinkToBilling className="m-h-8px" />
      )}
    </RowWrapper>
  );
};

export default RunSingleRowButton;
