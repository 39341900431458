import React from "react";
import type { StreamlinedWorkspace } from "@toolflow/shared";
import { Paper, Typography } from "@mui/material";
import { formatLocalTimeMed } from "../../../utilities/formatters/time/formatLocalTimeMed";
import { classNames } from "../../../utilities/functions/parsedClassNames";
import { useDispatch } from "react-redux";
import {
  setAllowReset,
  setWorkspaceId
} from "../../pages/workstation/state/workspaceSlice";

const useSessionElementCard = ({
  workspace,
  onClick
}: {
  workspace: StreamlinedWorkspace;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  const dispatch = useDispatch();
  const handleClick = (e: React.MouseEvent) => {
    dispatch(setAllowReset(true));
    dispatch(setWorkspaceId(workspace._id));
    if (onClick) {
      onClick(e);
    }
  };
  return { handleClick };
};
export function SessionElementCard({
  workspace,
  className = "",
  onClick
}: {
  workspace: StreamlinedWorkspace;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
}) {
  const { handleClick } = useSessionElementCard({ workspace, onClick });

  return (
    <Paper
      onClick={handleClick}
      variant="outlined"
      className={classNames(
        "border-radius-8px m-b-8px flex align-i-center justify-space-between p-v-8px p-h-16px cursor-pointer",
        className
      )}
      sx={{
        "&:hover": {
          backgroundColor: (theme) => theme.palette.background.paper
        }
      }}
    >
      <Typography variant="subtitle2">{workspace.name}</Typography>
      <Typography
        variant="caption"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        {formatLocalTimeMed(workspace.updatedAt)}
      </Typography>
    </Paper>
  );
}
