import { useLocation } from "react-router-dom";
import { useAuthStore } from "./useAuthStore";
import {
  externalToolMatcher,
  externalWorkflowMatcher,
  homeMatcher,
  loginMatcher,
  findToolsPageMatcher,
  signUpMatcher,
  tvrMatcher,
  workspaceMatcher
} from "../../navigation/functions/matchers";

function useRouteAuthentication() {
  const { isAuthenticated, isLoading } = useAuthStore();
  const location = useLocation();
  const matches = [
    signUpMatcher,
    loginMatcher,
    findToolsPageMatcher,
    externalToolMatcher,
    tvrMatcher,
    workspaceMatcher,
    externalWorkflowMatcher,
    homeMatcher
  ].some((matchFunc) => matchFunc(location.pathname));

  return isAuthenticated || matches || isLoading;
}

export default useRouteAuthentication;
