import { Button } from "@mui/material";
import React from "react";
import useHandleRemoveStep from "./hooks/useHandleRemoveStep";
import { DeleteIcon } from "../../../../../globalTheme/icons/icons";

function RemoveStepButton({ stepId }: { stepId: string }) {
  const handleRemove = useHandleRemoveStep(stepId);

  return (
    <Button
      size="small"
      onClick={handleRemove}
      variant="outlined"
      color="error"
      fullWidth
      startIcon={<DeleteIcon fontSize="small" />}
    >
      Delete step
    </Button>
  );
}

export default RemoveStepButton;
