import React from "react";
import useGetSelectedStepTool from "./hooks/useGetSelectedStepTool";
import WorkflowToolInfoCard from "../../WorkflowToolInfoCard";
import WorkflowToolSelectorContainer from "./WorkflowToolSelectorContainer";

function WorkflowToolSelectorSplitter() {
  const selectedTool = useGetSelectedStepTool();
  if (selectedTool) {
    return <WorkflowToolInfoCard />;
  }
  return (
    <>
      <WorkflowToolSelectorContainer />
    </>
  );
}

export default WorkflowToolSelectorSplitter;
