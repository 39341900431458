import React from "react";
import { RootState } from "../../../../../stores/store";
import { useSelector, useDispatch } from "react-redux";
import type { THomeTabType } from "@toolflow/shared";
import { updateHomeTab } from "../../slice/homePageSlice";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import { Divider, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../../../utilities/components/tabs/TabPanel";
import { a11yProps } from "../../../../../utilities/components/tabs/functions/a11yProps";
import WorkspaceDetailedTableContainer from "../../workspace/WorkspaceDetailedTableContainer";
import ToolsDetailedTableContainer from "../../tools/ToolsDetailedTableContainer";

type THomeTabDict = {
  [key in THomeTabType]: {
    value: number;
    component: React.ReactNode;
    label: string;
  };
};
const homeTabDict: THomeTabDict = {
  workspace: {
    value: 0,
    component: <WorkspaceDetailedTableContainer />,
    label: "Sessions"
  },
  tool: {
    value: 1,
    component: <ToolsDetailedTableContainer />,
    label: "Tools"
  }
};
const HistoryData = () => {
  const homeTab = useSelector((state: RootState) => state.home.tab);
  const dispatch = useDispatch();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: THomeTabType
  ) => {
    dispatch(updateHomeTab(newValue));
  };
  return (
    <div className="p-h-16px m-t-24px">
      <FullWidthCard className="p-t-16px">
        <Tabs value={homeTab} onChange={handleChange} textColor="secondary">
          {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
            <Tab
              label={tabDetails.label}
              key={tabName}
              value={tabName}
              {...a11yProps(tabDetails.value)}
              className="m-l-12px"
            />
          ))}
        </Tabs>
        <Divider variant="middle" />
        {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
          <TabPanel value={homeTab} key={tabName} index={tabName}>
            {tabDetails.component}
          </TabPanel>
        ))}
      </FullWidthCard>
    </div>
  );
};

export default HistoryData;
