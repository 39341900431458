import useIsCurrentUser from "../../user/hooks/useIsCurrentUser";
import { useEntityContext } from "../context/EntityContext";

const useCurrentUserIsEntityOwner = () => {
  const { entity } = useEntityContext();
  const { userId } = entity;
  return useIsCurrentUser(userId);
};

export default useCurrentUserIsEntityOwner;
