import type { TBlocksTagsLabels } from "@toolflow/shared";
import React, { FC } from "react";
import getBlockIcon from "../../../blocks/getBlockIcon";
import BlockTag from "../../../../utilities/components/chips/BlockTag";
import ModelsAvatarGroup from "./ModelsAvatarGroup";

interface IMarketplaceBlockTagsProps {
  blockLabels: TBlocksTagsLabels[];
  className?: string;
  iconOnly?: boolean;
  maxBlocks?: number;
}

const MarketplaceBlockTags: FC<IMarketplaceBlockTagsProps> = ({
  blockLabels,
  className,
  iconOnly = false,
  maxBlocks = 3
}) => {
  const maxBlocksToShow = maxBlocks;
  return (
    <div className={`flex align-i-center ${className}`}>
      {!iconOnly ? (
        <>
          {blockLabels.slice(0, maxBlocksToShow).map((blockLabel, index) => (
            <BlockTag
              key={`${blockLabel}-${index}`}
              blockLabel={blockLabel}
              icon={getBlockIcon(blockLabel)}
              index={index}
            />
          ))}
          {blockLabels.length > maxBlocksToShow && (
            <BlockTag
              blockLabel={`+ ${blockLabels.length - maxBlocksToShow}`}
            />
          )}
        </>
      ) : (
        <ModelsAvatarGroup
          blockLabels={blockLabels}
          maxBlocksToShow={maxBlocks}
        />
      )}
    </div>
  );
};

export default MarketplaceBlockTags;
