import type { JSONSchemaItem } from "@toolflow/shared";
import useFindBlockById from "../../../../../useFindBlockById";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import { structureJsonSchemaInitialState } from "../../../../../context/initialStates/structuredInitialState";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";

export default function useJsonSchemaConstructor({
  id,
  schema
}: {
  id: string;
  schema: JSONSchemaItem[];
}) {
  const { updateField, field } = useFieldsByTypeContext();
  const { fieldKey } = field.config;
  const block = useFindBlockById(id);
  const { dispatch } = useToolbuilderContext();

  function addSchemaItem() {
    const newSchema = [...schema];
    newSchema.push({ ...structureJsonSchemaInitialState });
    updateField(newSchema, fieldKey);
  }

  function removeIndex(index: number) {
    const newSchema = schema.filter((_, i) => i !== index);
    updateField(newSchema, fieldKey);
  }

  function updateFieldNameInTOF(prevValue: string, newValue: string) {
    if (block?.data.label) {
      dispatch({
        type: "RENAME_NESTED_SCHEMA_ITEM_FIELD",
        name: block.data.label,
        newValue,
        prevValue
      });
    }
  }

  function updateSchemaItem(index: number, schemaItem: JSONSchemaItem) {
    const newSchema = [...schema];
    newSchema[index] = schemaItem;
    updateField(newSchema, fieldKey);
  }

  return {
    removeIndex,
    addSchemaItem,
    updateSchemaItem,
    updateFieldNameInTOF
  };
}
