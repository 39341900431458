import { Grid, TextField } from "@mui/material";
import React, { FC } from "react";
import { TSetValFunction } from "./helpers/types";

interface IPersonalInformationFormProps {
  firstName?: string;
  lastName?: string;
  changeField: TSetValFunction;
}
const PersonalInformationForm: FC<IPersonalInformationFormProps> = ({
  firstName = "",
  lastName = "",
  changeField
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <TextField
          label="First Name"
          value={firstName}
          size="medium"
          onChange={changeField("firstName")}
          fullWidth
          margin="normal"
          inputProps={{ backgroundColor: "yellow" }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          label="Last Name"
          value={lastName}
          size="medium"
          onChange={changeField("lastName")}
          fullWidth
          margin="normal"
        />
      </Grid>
    </>
  );
};

export default PersonalInformationForm;
