import { useEditorToolCardContext } from "../EditorToolCardContext";

const useToolCompleted = () => {
  const { toolOutputState, percentCompleted } = useEditorToolCardContext();
  return (
    typeof percentCompleted === "number" &&
    percentCompleted === 100 &&
    !!toolOutputState
  );
};

export default useToolCompleted;
