import MarketplaceTabs, { IMarketplaceTabsBaseProps } from "./MarketplaceTabs";
import { toolTabs } from "./toolTabsArray";

function ToolTabs({ className, disabledEntities }: IMarketplaceTabsBaseProps) {
  return (
    <MarketplaceTabs
      toolTabs={toolTabs}
      className={className}
      disabledEntities={disabledEntities}
    />
  );
}

export default ToolTabs;
