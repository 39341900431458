import React from "react";

function CenterContentContainer({
  children,
  className = ""
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <div className={`max-w-1200px m-h-auto m-b-page ${className}`}>
      {children}
    </div>
  );
}

export default CenterContentContainer;
