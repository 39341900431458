import { useEffect } from "react";
import LogRocket from "logrocket";
import type { TUser } from "@toolflow/shared";

const useLogRocket = (user: TUser | null) => {
  useEffect(() => {
    if (process.env.environment !== "development" && user?._id) {
      LogRocket.identify(user._id, {
        name: user.name || "Anonymous",
        email: user.email || "No email"
      });
    }
  }, [user]);
};

export default useLogRocket;
