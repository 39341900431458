import type { TAgentBuilderForm } from "@toolflow/shared";

import { useState } from "react";
import { useParams } from "react-router-dom";
import { type SubmitHandler, useFormContext } from "react-hook-form";
import useShowValidationErrors from "../../../../../../utilities/validation/zod/useShowValidationErrors";
import useNavigateToNewAgent from "./useNavigateToNewAgent";
import useHandleAgentSaveSuccess from "./useHandleAgentSaveSuccess";
import useAgentQueriesFetching from "./useAgentQueriesFetching";
import { useSaveAgentMutation } from "../../../../../../ToolflowAPI/rtkRoutes/agentApi";

const useGetAgentId = () => {
  const { agentId: agentIdParams } = useParams();
  const [agentId, setAgentId] = useState<string | undefined>(agentIdParams);
  return { agentId, setAgentId };
};
const useSaveAgentAndClose = () => {
  const [saveAgent, { isLoading, isSuccess }] = useSaveAgentMutation();

  const agentQueriesLoading = useAgentQueriesFetching();
  const loading = isLoading || agentQueriesLoading;
  const { agentId, setAgentId } = useGetAgentId();
  const hasSaved = !loading && isSuccess;

  useNavigateToNewAgent(hasSaved, agentId);
  useHandleAgentSaveSuccess(hasSaved);
  useShowValidationErrors();

  const { handleSubmit } = useFormContext<TAgentBuilderForm>();

  const onSubmit: SubmitHandler<TAgentBuilderForm> = async (
    agentBuilderForm
  ) => {
    try {
      await saveAgent({
        body: { agent: agentBuilderForm },
        agentId
      })
        .unwrap()
        .then((resp) => {
          if (!agentId) {
            setAgentId(resp.result.agent._id);
          }
        });
    } catch (error) {
      console.error("Failed to save agent", error);
    }
  };

  const saveAndClose = () => {
    handleSubmit(onSubmit)();
  };

  return {
    saveAndClose,
    loading
  };
};

export default useSaveAgentAndClose;
