import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import AuthenticationSplitter from "../../../../auth/AuthenticationSplitter";
import LoggedOutCTA from "../../../external/LoggedOutCTA";
import NoWrapCardHeader from "../../../../../utilities/components/cards/NoWrapCardHeader";
import ToggleSidebarButton from "../../../../layout/sidebar/ToggleSidebarButton";
import { selectSidebarOpen } from "../../../../layout/sidebar/sidebarSlice";
import ProfileDropdownMenu from "../../../profile/dropdown/ProfileDropdownMenu";
import { parsedClassNames } from "../../../../../utilities/functions/parsedClassNames";
import styles from "./workspaceHeader.module.css";
import WorkspaceTitle from "./WorkspaceTitle";

const cx = parsedClassNames.bind(styles);

function WorkspaceHeader() {
  const open = useSelector(selectSidebarOpen);

  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      className={cx("flex align-i-center pos-relative", styles.height)}
    >
      {!open && <ToggleSidebarButton />}
      <NoWrapCardHeader
        title={<WorkspaceTitle />}
        action={
          <AuthenticationSplitter
            externalComponent={<LoggedOutCTA />}
            authenticatedComponent={<ProfileDropdownMenu size={32} />}
          />
        }
        classes={{ action: "m-0px" }} // this is to make the logged out cta be correctly placed
        disableTypography
        className="w-100-percent p-v-0px p-h-24px"
      />
    </Box>
  );
}

export default WorkspaceHeader;
