import LoadingButton from "@mui/lab/LoadingButton";
import { useBlockerContext } from "../../../navigation/contexts/BlockerContext";

function ResetAgentButton() {
  const { continueNavigating } = useBlockerContext();
  const handleReset = () => {
    continueNavigating();
  };

  return (
    <LoadingButton
      size="small"
      variant="text"
      onClick={handleReset}
      className="m-l-8px"
    >
      Discard changes
    </LoadingButton>
  );
}

export default ResetAgentButton;
