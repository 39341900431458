import { useDispatch } from "react-redux";
import React from "react";
import { setAssetListBig } from "../../../../state/workspaceSlice";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { AssetIcon } from "../../../../../../../globalTheme/icons/icons";

function MakeAssetListBigButton() {
  const dispatch = useDispatch();
  const makeSmall = () => {
    dispatch(setAssetListBig(true));
  };
  return (
    <RemixButton
      icon={AssetIcon}
      text="Expand"
      onClick={makeSmall}
      providedColor="black"
    />
  );
}

export default MakeAssetListBigButton;
