export const DEFAULT_EMPLOYEE_COUNT = "1-10";
export const DEFAULT_FUNCTION = "Sales";
export const DEFAULT_RESPONSIBILITY = "Manager";
export const employeCountValues = [
  "1-10",
  "11-50",
  "51-200",
  "201-500",
  "501-1000",
  "1001-5000",
  "5001+"
];
