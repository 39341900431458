import type { UserInputDictType } from "@toolflow/shared";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import useUpdatePercentCompleted from "./useUpdatePercentCompleted";

const useResetToolOutput = () => {
  const { setToolOutputState } = useEditorToolCardContext();
  const updatePercentCompleted = useUpdatePercentCompleted();
  const resetToolOutput = (response: UserInputDictType | null) => {
    setToolOutputState(response);
    updatePercentCompleted(0);
  };
  return resetToolOutput;
};

export default useResetToolOutput;
