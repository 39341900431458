import useGetWorkflowFromQuery from "../../../workflow/hooks/useGetWorkflowFromQuery";
import { useGetSelectedEntityType } from "./useGetSelectedEntityType";
import { useGetSelectedEntityId } from "./useGetSelectedEntityId";
import { EntityType } from "@toolflow/shared";

const useGetSelectedWorkspaceWorkflow = () => {
  const entityId = useGetSelectedEntityId();
  const type = useGetSelectedEntityType();
  return useGetWorkflowFromQuery(entityId, {
    skip: type !== EntityType.WORKFLOW
  });
};

export default useGetSelectedWorkspaceWorkflow;
