import type {
  HyperlinePlans,
  TActiveTier,
  TAvailableTiers
} from "@toolflow/shared";
import { DateTime } from "luxon";
import { useMemo } from "react";
import useGetCurrentUser from "../../user/hooks/useGetCurrentUser";
import {
  ORGANIZATION_PLAN_FE,
  FREE_PLAN_FE,
  PRO_PLAN_FE
} from "../helpers/billingConstants";

export function useSubscriptionDetails() {
  const user = useGetCurrentUser();
  return useMemo(() => {
    let activeTier: TAvailableTiers = "free";
    let activeSubscriptionId: HyperlinePlans | undefined;
    let pendingTier: TActiveTier | undefined;
    let pendingCheckoutSessionId: string | null = null;
    let renewalDate = DateTime.now()
      .plus({ months: 1 })
      .startOf("month")
      .toLocaleString();

    const orgSubscriptions =
      user?.organization?.billing?.hyperlineCustomer.subscriptions || [];
    const userSubscriptions =
      user?.billing?.hyperlineCustomer.subscriptions || [];

    for (const subscription of orgSubscriptions) {
      if (subscription.status === "active") {
        activeTier = ORGANIZATION_PLAN_FE;
        activeSubscriptionId = subscription.plan_id;
        renewalDate = subscription.current_period_ends_at
          ? DateTime.fromISO(
              subscription.current_period_ends_at
            ).toLocaleString()
          : renewalDate;
        break;
      } else if (subscription.status === "pending") {
        pendingTier = ORGANIZATION_PLAN_FE;
        pendingCheckoutSessionId = subscription.checkout_session_id;
      }
    }

    if (activeTier === FREE_PLAN_FE) {
      for (const subscription of userSubscriptions) {
        if (subscription.status === "active") {
          activeTier = PRO_PLAN_FE;
          activeSubscriptionId = subscription.plan_id;
          renewalDate = subscription.current_period_ends_at
            ? DateTime.fromISO(
                subscription.current_period_ends_at
              ).toLocaleString()
            : renewalDate;
          break;
        } else if (subscription.status === "pending") {
          pendingTier = pendingTier || PRO_PLAN_FE;
          pendingCheckoutSessionId = subscription.checkout_session_id;
        }
      }
    }

    return {
      activeTier,
      activeSubscriptionId,
      pendingTier,
      pendingCheckoutSessionId,
      renewalDate
    };
  }, [user]);
}
