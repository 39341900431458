import { Menu } from "@mui/material";
import React from "react";
import { useSettingsDropdownContext } from "../useSettingsDropownContext";

function WrappedMenu({ children }: { children: React.ReactNode }) {
  const { anchorEl, open, handleClose } = useSettingsDropdownContext();

  return (
    <Menu
      id="basic-menu"
      // anchorEl={anchorEl}
      anchorReference="anchorPosition"
      anchorPosition={
        anchorEl !== null
          ? { top: anchorEl.mouseY, left: anchorEl.mouseX }
          : undefined
      }
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button"
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left"
      }}
    >
      {children}
    </Menu>
  );
}

export default WrappedMenu;
