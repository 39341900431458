import React from "react";
import type {
  CustomToolInputField,
  RowUserInputDictType
} from "@toolflow/shared";
import type { MRT_Cell } from "material-react-table";
import type { Theme } from "@mui/material";
import InputCell from "../InputCell";
import useOnClickBody from "./useOnClickBody";
import { CELL_WIDTH } from "../sheetConstants";

const useGetToolInputFieldColumn = () => {
  const onClick = useOnClickBody();
  const getToolInputFieldColumn = (toolInputField: CustomToolInputField) => {
    return {
      minSize: CELL_WIDTH,
      size: CELL_WIDTH,
      grow: false,
      header: toolInputField.name,
      accessorKey: toolInputField.name,
      meta: { value: toolInputField, type: "input" },
      muiTableBodyCellProps: ({
        cell
      }: {
        cell: MRT_Cell<RowUserInputDictType>;
      }) => {
        return {
          onClick: onClick(cell),
          className: "muiTableBodyCellClick p-0px",
          sx: (theme: Theme) => ({
            border:
              cell.row.original.columnIdSelected === cell.column.id
                ? `2px solid ${theme.palette.primary.main}`
                : undefined
          })
        };
      },
      muiTableHeadCellProps: {
        sx: (theme: Theme) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "& .MuiSvgIcon-root": {
            color: `${theme.palette.primary.contrastText} !important`
          }
        })
      },
      Cell: ({ cell }: { cell: MRT_Cell<RowUserInputDictType> }) => {
        return <InputCell cell={cell} toolInputField={toolInputField} />;
      }
    };
  };

  return getToolInputFieldColumn;
};

export default useGetToolInputFieldColumn;
