import DrawerContent from "./components/drawerContent/DrawerContent";
import DrawerExpandedContent from "./components/drawerContent/DrawerExpandedContent";

function BuilderDrawerInner() {
  return (
    <div className="flex w-100-percent h-100-percent">
      <div className="flex-column w-100-percent h-100-percent">
        <DrawerContent />
      </div>
      <DrawerExpandedContent />
    </div>
  );
}

export default BuilderDrawerInner;
