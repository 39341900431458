import type {
  CustomToolOutputField,
  UserInputDictType,
  Asset
} from "@toolflow/shared";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useToolContext } from "../../../tools/contexts/ToolContext";
import useAddToolAssetsToWorkspace from "../../../tools/components/editorToolCard/hooks/useAddToolAssetsToWorkspace";
import { toggleRefreshInputs } from "../state/workspaceSlice";
import useSetVisibleAsset from "../assets/useSetVisibleAsset";

const useUpdateAssetsOnToolOutputChange = ({
  currentAssets,
  toolOutput,
  componentId,
  addInputsCallback
}: {
  currentAssets: React.MutableRefObject<Asset[]>;
  toolOutput: UserInputDictType | null;
  componentId: string;
  addInputsCallback?: (id: string, t: CustomToolOutputField) => void;
}) => {
  const dispatch = useDispatch();
  const { setVisibleAsset } = useSetVisibleAsset();
  const { tool } = useToolContext();
  const addToolInputsToWorkspace = useAddToolAssetsToWorkspace();

  const addedAssets = useRef(new Set());

  useEffect(() => {
    if (toolOutput) {
      const assetsToUpdate = currentAssets.current.filter(
        (asset) =>
          (asset.data?.outputId || "" in (toolOutput || {})) && asset.loading // we only want to update inputs that are loading
      );
      addToolInputsToWorkspace({ componentId, tool, addInputsCallback });

      assetsToUpdate.map((i) => {
        if (!!i.value && !addedAssets.current.has(i.id)) {
          setVisibleAsset(i.id);
          // we only want to add inputs once, because people may drag and drop them elsewhere
          addedAssets.current.add(i.id);
        }
        dispatch(toggleRefreshInputs());
      });
    } else {
      addedAssets.current = new Set();
    }
  }, [toolOutput]);
};

export default useUpdateAssetsOnToolOutputChange;
