import { FileUploadError } from "../errors/fileErrors";
import useGetMBLimit from "./useGetMBLimit";

const useCheckFileSize = () => {
  const mbLimit = useGetMBLimit();
  const fileTooBig = (fSize: number) => fSize > mbLimit * 1024 * 1024;
  return fileTooBig;
};

const isValidExtension = (
  fileType: string,
  fileTypes: string[] = ["mp3", "mp4", "mpeg", "mpga", "m4a", "wav", "webm"]
) => {
  return fileTypes.some((extension) => fileType.includes(extension));
};

const useValidateFile = (validFileTypes?: string[]) => {
  const fileTooBig = useCheckFileSize();
  const validateFile = (file: File) => {
    const { type: fileType } = file;
    if (!isValidExtension(fileType, validFileTypes)) {
      return FileUploadError.EXTENSION;
    }
    if (fileTooBig(file.size)) {
      return FileUploadError.FILE_SIZE;
    }

    return "";
  };
  return validateFile;
};

export default useValidateFile;
