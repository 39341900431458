import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import React from "react";
import SaveWorkflowButtonFromDialog from "./SaveAgentButtonFromDialog";
import UpdateVisibilitySelectForm from "../../../../../utilities/components/sharing/updateVisibilitySelect/UpdateVisibilitySelectForm";
import AssignCategoriesFromAgentBuilder from "./AssignCategoriesFromAgentBuilder";
import type { SaveAgentDialogInnerProps } from "@toolflow/shared";
import UpdateAgentNameTextField from "../UpdateAgentNameTextfield";
import UpdateAgentDescriptionTextField from "../UpdateAgentDescriptionTextfield";
import UpdateEnableForkingForm from "../../../../../utilities/components/sharing/updateEnableForking/UpdateEnableForkingForm";

function SaveAgentDialogInner({
  open,
  handleClose
}: Readonly<SaveAgentDialogInnerProps>) {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Save Agent</DialogTitle>
      <DialogContent className="flex flex-column">
        <UpdateAgentNameTextField
          margin="normal"
          label="Agent name"
          showErrors
        />
        <UpdateAgentDescriptionTextField
          margin="normal"
          label="Agent description"
          placeholder="What does your agent do..."
        />
        <UpdateVisibilitySelectForm margin="normal" />
        <UpdateEnableForkingForm margin="normal" />
        <AssignCategoriesFromAgentBuilder />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <SaveWorkflowButtonFromDialog />
      </DialogActions>
    </Dialog>
  );
}

export default SaveAgentDialogInner;
