import { useCallback } from "react";
import type { Content, JSONContent } from "@tiptap/core";
import {
  ChipNodeComponents,
  chipsWithVariableInputsArray
} from "@toolflow/shared";

/**
 * Hook to retrieve all chip strings from TipTap editor content.
 * It returns a traverse function that takes Content as input.
 *
 * @returns A function that takes Content and returns an array of chip strings.
 */
const useGetChips = () => {
  /**
   * Traverse the Content to find and extract chip strings.
   *
   * @param content - The Content from the TipTap editor.
   * @returns An array of chip strings.
   */
  const traverse = useCallback((content: Content): string[] => {
    const chips: string[] = [];

    /**
     * Recursively traverse each node in the content.
     *
     * @param node - The current node, which can be JSONContent or string (HTMLContent).
     */
    const traverseNodes = (node: JSONContent | string | null) => {
      if (!node) return;

      // If the node is a string (HTMLContent), you might need to parse it to JSONContent.
      // TipTap typically works with JSONContent, so we'll skip HTMLContent strings.
      if (typeof node === "string") {
        // Optionally: Parse HTML to JSONContent if needed
        return;
      }

      // Check if the node is a custom chip node
      if (
        chipsWithVariableInputsArray.includes(node.type as ChipNodeComponents)
      ) {
        const chipText = node.attrs?.name || node.attrs?.textValue || "";
        if (chipText) {
          chips.push(chipText);
        }
      }

      // If the node has children, traverse them recursively
      if (Array.isArray(node.content)) {
        node.content.forEach((childNode) => traverseNodes(childNode));
      }
    };

    // Handle different Content types
    if (Array.isArray(content)) {
      content.forEach((item) => traverseNodes(item));
    } else {
      traverseNodes(content);
    }

    return chips;
  }, []);

  return traverse;
};

export default useGetChips;
