import React from "react";
import WorkspacePaginatedTable from "./WorkspacePaginatedTable";
import useWorkspaceTableData from "../hooks/useWorkspaceTableData";
import TableSkeleton from "../layout/TableSkeleton";
import NoRecentData from "../NoRecentData";
import useGetAllProfileWorkspaces from "../../../workspace/hooks/useGetAllProfileWorkspaces";

const WorkspaceDetailedTableContainer = () => {
  const { workspaces, loading } = useGetAllProfileWorkspaces();
  const { headCells, rows } = useWorkspaceTableData(workspaces);
  if (loading) {
    return <TableSkeleton numOfRows={15} className="p-16px" />;
  }
  const isWorkspaceDataEmpty = workspaces?.length === 0;

  if (isWorkspaceDataEmpty) {
    return (
      <NoRecentData
        header="Your recent work sessions will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <WorkspacePaginatedTable rows={rows} headCells={headCells} />;
};

export default WorkspaceDetailedTableContainer;
