import React from "react";
import DeleteRowButton from "./DeleteRowButton";
import RunSingleRowButton from "./RunSingleToolButton";
// import { useSheetContext } from "../SheetContext";
import { TMaterialRow } from "../sheetTypes";

const RowActions = ({
  row,
  runSingleTool
}: {
  row: TMaterialRow;
  runSingleTool: (row: TMaterialRow) => Promise<void>;
}) => {
  // const { selectedTool } = useSheetContext();
  return (
    <div className="flex align-i-center max-w-140px">
      <RunSingleRowButton runSingleTool={runSingleTool} row={row} />
      {/* <CreditCostDetails
        estimatedCreditCost={selectedTool?.main.estimatedCreditCost}
      /> */}
      <DeleteRowButton row={row} />
    </div>
  );
};

export default RowActions;
