import { AGENT_BUILDER_BASE_ROUTE_PATH } from "../../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../../navigation/hooks/useToolflowNavigate";

const useNavigateToAgentBuilderById = (agentId?: string) => {
  const navigate = useToolflowNavigate();

  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation(); // necessary for ContentActionArea on ProfileToolCard
    navigate(`${AGENT_BUILDER_BASE_ROUTE_PATH}${agentId}`, e);
  };

  return handleNavigate;
};

export default useNavigateToAgentBuilderById;
