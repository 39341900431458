import { useMemo } from "react";
import { useEditorToolCardContext } from "../EditorToolCardContext";

const useToolStillLoading = () => {
  const { toolOutputState, percentCompleted } = useEditorToolCardContext();
  return useMemo(() => {
    return (
      typeof percentCompleted === "number" &&
      percentCompleted !== 100 &&
      !!toolOutputState
    );
  }, [toolOutputState, percentCompleted]);
};

export default useToolStillLoading;
