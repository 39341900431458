import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ButtonLinkToBilling = ({
  className = "m-r-8px"
}: {
  className?: string;
}) => {
  return (
    <Link to="/settings/billing">
      <Button variant="text" className={className}>
        Upgrade
      </Button>
    </Link>
  );
};
export default ButtonLinkToBilling;
