import { Button } from "@mui/material";
import { ArrowRightDoubleIcon } from "../../../../../../../../../globalTheme/icons/icons";
import { setDrawerExpandedOpen } from "../../../../../store/toolBuilderSlice";
import { useDispatch } from "react-redux";

const OpenTestPanelButton = () => {
  const dispatch = useDispatch();
  const openTestPanel = () => {
    dispatch(setDrawerExpandedOpen(true));
  };
  return (
    <Button
      endIcon={<ArrowRightDoubleIcon />}
      size="small"
      onClick={openTestPanel}
      disableRipple
      variant="contained"
    >
      Open test panel
    </Button>
  );
};

export default OpenTestPanelButton;
