import { useDispatch, useSelector } from "react-redux";
import { selectStepId, updateStepId } from "../../workflowBuilderSlice";

const useOpenStepById = (stepId: string) => {
  const dispatch = useDispatch();
  const selectedStepId = useSelector(selectStepId);
  const openStep = () => {
    if (selectedStepId === stepId) return;
    dispatch(updateStepId(stepId));
  };
  return openStep;
};

export default useOpenStepById;
