import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useSaveAgentAndClose from "./useSaveAgentAndClose";
import { openAgentSaveDialog } from "../../state/agentBuilderSlice";

const useHandleSaveAgentButtonClick = () => {
  const dispatch = useDispatch();
  const { agentId } = useParams();
  const { saveAndClose, loading } = useSaveAgentAndClose();
  const handleSave = () => {
    // if the workflow is already saved, we don't need to clarify with an open dialog
    if (agentId) {
      saveAndClose();
    } else {
      dispatch(openAgentSaveDialog());
    }
  };

  return { handleSave, loading };
};

export default useHandleSaveAgentButtonClick;
