import {
  AssetValueType,
  BlockType,
  CopyableField,
  validateAndParseJson,
  type AssetValue,
  type UserInputResponseWithHandledError
} from "@toolflow/shared";
import { isHandledChatStackError } from "../../../../../pages/builder/toolBuilder/typeCheckers";
import isOpenAIImageArray from "../../functions/isOpenAIImageArray";

export const PromptTypeToOutputFieldMap = {
  [BlockType.DallE]: CopyableField.Image,
  [BlockType.ChatGPT]: CopyableField.LargeText,
  [BlockType.Anthropic]: CopyableField.LargeText,
  [BlockType.Structured]: CopyableField.Structured,
  [BlockType.Perplexity]: CopyableField.LargeText
};

export const OutputFieldToValueTypeMap = {
  [CopyableField.Image]: AssetValueType.DallE,
  [CopyableField.LargeText]: AssetValueType.String,
  [CopyableField.Structured]: AssetValueType.Structured
};

/**
 * Returns AssetValueType based on CopyableField.
 * @default AssetValueType.String
 */
export function getValueTypeFromOutputField(copyableField: CopyableField) {
  return OutputFieldToValueTypeMap[copyableField] || AssetValueType.String;
}

/**
 * Returns CopyableField based on BlockType.
 * @default CopyableField.LargeText
 */
export function getOutputFieldValueFromPromptType(promptType: BlockType) {
  return (
    PromptTypeToOutputFieldMap[
      promptType as keyof typeof PromptTypeToOutputFieldMap
    ] || CopyableField.LargeText
  );
}

export function getValueTypeByAssetValue(
  assetValue: AssetValue | UserInputResponseWithHandledError
) {
  let value: AssetValue = "";
  let valueType: AssetValueType = AssetValueType.Structured;

  if (typeof assetValue === "string") {
    const jsonValue = validateAndParseJson(assetValue);
    if (jsonValue) {
      value = jsonValue;
    } else {
      value = assetValue;
      valueType = AssetValueType.String;
    }
  } else if (isHandledChatStackError(assetValue)) {
    value = assetValue.data.message;
    valueType = AssetValueType.String;
  } else if (isOpenAIImageArray(assetValue)) {
    value = assetValue;
    valueType = AssetValueType.DallE;
  } else if (typeof assetValue === "boolean") {
    value = assetValue.toString();
    valueType = AssetValueType.String;
  } else if (typeof assetValue === "object") {
    value = assetValue;
    valueType = AssetValueType.Structured;
  }

  return { value, valueType };
}
