import { Editor, type JSONContent } from "@tiptap/react";
import { getTipTapValue } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useGetTipTapValue";
import useHandleRunAgent from "./useHandleRunAgent";
import useIsMessageLoading from "./useIsMessageLoading";
import useThreadRef from "./useThreadRef";
import useSendChatDisabled from "../runButton/hooks/useSendChatDisabled";
import useAgentRef from "./useAgentRef";

const useHandleChatMessageKeyDown = (
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>
) => {
  const handleRunAgent = useHandleRunAgent(setUserTextMessage);
  const isMessageLoading = useIsMessageLoading();
  const currentThreadIdRef = useThreadRef();
  const currentAgentIdRef = useAgentRef();
  const disabled = useSendChatDisabled();

  const handleKeyDown = (event: KeyboardEvent, editor?: Editor) => {
    if (editor) {
      if (event.shiftKey === false && !disabled) {
        const value = getTipTapValue({ editor, saveWorkspaceType: "text" });
        if (event.key === "Enter" && !isMessageLoading) {
          event.preventDefault();
          event.stopPropagation();
          // we get the value when enter is pressed. Usually we update the value on blur, so we need
          // to get the value directly from the input here
          handleRunAgent({
            threadId: currentThreadIdRef?.current ?? "",
            agentId: currentAgentIdRef?.current ?? "",
            message: value as string
          });
          editor.commands.focus();
        }
        if (!isMessageLoading) {
          // the userTextMessage might still be "" because onBlur hasn't been called
          // in which case, initialValue won't have changed, so this wont rerender. In which case
          // clear the content
          editor.commands.clearContent();
        }
      }
    }
  };
  return handleKeyDown;
};

export default useHandleChatMessageKeyDown;
