// sidebarSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";

interface ISidebarInitialState {
  open: boolean;
}

const initialState: ISidebarInitialState = {
  open: true
};

export type TSidebarReducerName = "sidebar";
export const SIDEBAR_REDUCER_NAME: TSidebarReducerName = "sidebar";

const sidebarSlice = createSlice({
  name: SIDEBAR_REDUCER_NAME,
  initialState,
  reducers: {
    toggleSidebar(state) {
      state.open = !state.open;
    },
    setSidebarOpen(state) {
      state.open = true;
    },
    setSidebarClose(state) {
      state.open = false;
    }
  }
});

export const selectSidebarOpen = (state: RootState) =>
  state.layout[SIDEBAR_REDUCER_NAME].open;

export const { toggleSidebar, setSidebarOpen, setSidebarClose } =
  sidebarSlice.actions;

export default sidebarSlice.reducer;
