import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import type { TTextGenerationBlockType } from "@toolflow/shared";
import React, { FC } from "react";
import useUpdateField from "../../useUpdateField";

interface ISelectTextGenerationTechProps {
  id: string;
  blockType: TTextGenerationBlockType;
}

const OPEN_AI = "Open AI";
const ANTHROPIC = "Anthropic";
const SelectTextGenerationTech: FC<ISelectTextGenerationTechProps> = (
  props
) => {
  const { id, blockType } = props;
  const updateField = useUpdateField(id);
  const handleChange = (blockTypeValue: TTextGenerationBlockType) => {
    updateField(blockTypeValue, "type");
  };

  return (
    <FormControl margin="normal" fullWidth className="m-b-16px">
      <InputLabel id="text-generation-technology">
        Select Text Generation Technology
      </InputLabel>
      <Select
        labelId="text-generation-technology"
        id="userKeywords"
        fullWidth
        label="Select Text Generation Technology"
        onChange={(e) =>
          handleChange(e.target.value as TTextGenerationBlockType)
        }
        className="capitalize nowheel nodrag nopan"
        value={blockType}
      >
        <MenuItem value={"ChatGPT"} key={OPEN_AI} className="capitalize">
          {OPEN_AI}
        </MenuItem>
        <MenuItem value={"Anthropic"} key={ANTHROPIC} className="capitalize">
          {ANTHROPIC}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default SelectTextGenerationTech;
