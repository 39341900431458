import {
  DynamicFieldType,
  OpenAiImageModel,
  OpenAiTextModel,
  type DallEBlockOptimizations,
  type DallEBlockSettingsV2
} from "@toolflow/shared";
import { anthropicInitialOptimizations } from "./anthropicInitialState";

export const promptInitialState = {
  prompt: "",
  optimizations: {
    llmModel: OpenAiTextModel.GPT4o,
    temperature: 50
  },
  anthropicOptimizations: anthropicInitialOptimizations
};

export const dallEInitialOptimizations = {
  size: "512x512",
  model: OpenAiImageModel.DALLE2,
  n: 1
};
export const dallE2InitialOptimizations = {
  size: "512x512",
  model: OpenAiImageModel.DALLE2,
  n: 1
};

export const dallE3InitialOptimizations = {
  size: "1024x1024",
  model: OpenAiImageModel.DALLE3,
  n: 1,
  style: "vivid",
  quality: "standard"
};

export const dallEPromptInitialState = {
  prompt: "",
  optimizations: dallEInitialOptimizations
};

export const dallE3PromptInitialState = {
  prompt: "",
  optimizations: dallE3InitialOptimizations
};

export const dallESettingsV2: DallEBlockSettingsV2 = {
  prompt: ""
};

export const dallEInitialOptimizationsV2: DallEBlockOptimizations = {
  model: { type: DynamicFieldType.Preset, value: OpenAiImageModel.DALLE2 },
  size: { type: DynamicFieldType.Preset, value: "512x512" },
  n: { type: DynamicFieldType.Preset, value: 1 }
};

export const dallEInitialStateV2 = {
  settings: dallESettingsV2,
  optimizations: dallEInitialOptimizationsV2
};
