import type {
  PrimitiveOrObjectOrArray,
  StreamlinedWorkspace,
  TMarketplaceEntity,
  VisibilityTypes
} from "@toolflow/shared";
import { WorkspaceTableData, Order, ToolsTableData } from "./tableTypes";
import { getTruncatedWords } from "../../../../utilities/formatters/strings/getTruncatedWords";

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getNestedValue(obj: $TSAllowedAny, path: string): $TSAllowedAny {
  return path.split(".").reduce((acc, part) => acc && acc[part], obj);
}

function descendingNestedComparator<T>(a: T, b: T, orderBy: string): number {
  const aValue = getNestedValue(a, orderBy);
  const bValue = getNestedValue(b, orderBy);

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof $TSAllowedAny>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: PrimitiveOrObjectOrArray },
  b: { [key in Key]: PrimitiveOrObjectOrArray }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function getNestedComparator<T>(
  order: Order,
  orderBy: string
): (a: T, b: T) => number {
  return order === "desc"
    ? (a, b) => descendingNestedComparator(a, b, orderBy)
    : (a, b) => -descendingNestedComparator(a, b, orderBy);
}

export function createWorkspaceData(
  id: string,
  name: string,
  date: number,
  visibility: VisibilityTypes,
  actions: string
): WorkspaceTableData {
  return {
    id,
    name,
    date,
    visibility,
    actions
  };
}

export const convertWorkspaceTableData = (
  workspace: StreamlinedWorkspace
): WorkspaceTableData => {
  return {
    actions: "Actions",
    date: workspace.updatedAt,
    id: workspace._id,
    name: workspace.name,
    visibility: workspace.visibility
  };
};

export function createToolsData({
  entity
}: {
  entity: TMarketplaceEntity;
}): ToolsTableData {
  const blocks = entity?.tag?.blocks || [];
  const useCases = entity?.tag?.useCases || [];
  const categories = entity?.tag?.categories || [];
  return {
    name: getTruncatedWords(entity.name, 10),
    entity,
    useCases,
    categories,
    blocks,
    actions: "Actions"
  };
}
