import {
  toolflowPrimaryColor,
  toolflowTextPrimaryColorLight
} from "../../../../../../globalTheme/muiUtils/appTheme";
import styles from "./markerStyles.module.css";

function EndMarker({ isHovered }: { isHovered: boolean }) {
  return (
    <svg className={styles["marker-position"]}>
      <defs>
        <marker
          className="react-flow__arrowhead"
          id="marker-end"
          markerWidth="40"
          markerHeight="40"
          viewBox="-10 -10 20 20"
          markerUnits="strokeWidth"
          orient="auto-start-reverse"
          refX="0"
          refY="0"
        >
          <polyline
            style={{
              stroke: isHovered
                ? toolflowPrimaryColor
                : toolflowTextPrimaryColorLight,
              strokeWidth: 0.75
            }}
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
            points="-2,-2 0,0 -2,2"
          />
        </marker>
      </defs>
    </svg>
  );
}

export default EndMarker;
