import { useToolbuilderContext } from "../../../../../../context/ToolBuilderContext";
import useGetCurrentToolOutputFieldIndex from "./useGetCurrentToolOutputFieldIndex";

const useGetCurrentToolOutputField = () => {
  const currentToolOutputFieldIndex = useGetCurrentToolOutputFieldIndex();
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;
  return toolOutputFields[currentToolOutputFieldIndex];
};

export default useGetCurrentToolOutputField;
