import React from "react";
import InputTypeSelect from "./InputTypeSelect";
import type { CustomToolInputFieldTypes } from "@toolflow/shared";
import useGetCurrentToolInputFieldIndex from "./hooks/useGetCurrentToolInputFieldIndex";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";

function InputTypeSelectInDraggable({ disabled }: { disabled?: boolean }) {
  const index = useGetCurrentToolInputFieldIndex();
  const type = useGetCurrentToolInputField()?.type as CustomToolInputFieldTypes;
  const { changeInputType } = useToolInputFieldsContext();

  return (
    <InputTypeSelect
      disabled={disabled}
      type={type}
      onChange={(e) =>
        changeInputType(e.target.value as CustomToolInputFieldTypes, index)
      }
    />
  );
}

export default InputTypeSelectInDraggable;
