import React from "react";
import ToolsBlockTable from "./ToolsBlockTable";
import { useStarredEntities } from "../../../../entities/hooks/useStarredEntities";

const StarredEntitiesContainer = () => {
  const { starredEntities } = useStarredEntities(6);
  return <ToolsBlockTable toolsBlockEntities={starredEntities} />;
};

export default StarredEntitiesContainer;
