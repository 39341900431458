import {
  AGENT,
  EntityType,
  type TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import {
  StarFillIcon,
  NoToolsIcon
} from "../../../../../globalTheme/icons/icons";

const singleWorkdMap = {
  [EntityType.TOOL]: "a tool",
  [AGENT]: "an agent",
  [EntityType.WORKFLOW]: "a tool"
};

const pluralWordMap = {
  [EntityType.TOOL]: "tools",
  [AGENT]: "agents",
  [EntityType.WORKFLOW]: "tools"
};

export const getTabsContentDict = (
  type: TMarketplaceUniversalEntityType
): Record<string, $TSFixMe> => {
  const entityWord = singleWorkdMap[type];
  const pluralEntityWord = pluralWordMap[type];
  return {
    userTools: {
      header: `No ${pluralEntityWord} found`,
      subheader: `Build your own ${pluralEntityWord} and they will appear here`,
      actionButtonText: `Build ${entityWord}`,
      icon: NoToolsIcon,
      className: "primary-purple"
    },
    starred: {
      header: `All your starred ${pluralEntityWord} will appear here`,
      subheader: `Check out the marketplace to discover some of the best ${pluralEntityWord}`,
      icon: StarFillIcon,
      actionButtonText: `Explore the ${pluralEntityWord} marketplace`,
      className: "primary-purple"
    },
    marketplace: {
      header: `No ${pluralEntityWord} found`,
      subheader: `Check out the "All" section to discover some of the best ${pluralEntityWord}`,
      actionButtonText: `Explore all ${pluralEntityWord}`,
      icon: NoToolsIcon
    }
  };
};
export const getDefaultContentValues = (
  type: TMarketplaceUniversalEntityType
) => {
  const entityWord = singleWorkdMap[type];
  const pluralEntityWord = pluralWordMap[type];
  return {
    header: `No ${pluralEntityWord} found`,
    subheader: `Build your own ${pluralEntityWord} and they will appear here`,
    actionButtonText: `Build ${entityWord}`,
    icon: NoToolsIcon,
    className: "primary-purple"
  };
};

export const getTabsContent = (
  tab: string,
  type: TMarketplaceUniversalEntityType
) => {
  return getTabsContentDict(type)[tab] || getDefaultContentValues(type);
};
