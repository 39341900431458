import React from "react";
import { Navigate } from "react-router-dom";
import AuthenticationSplitter from "../../../auth/AuthenticationSplitter";
import WorkflowBuilderPageLoading from "../workflowBuilder/WorkflowBuilderPageLoading";
import useGetAgentByParams from "./hooks/useGetAgentByParams";
import AgentFormProvider from "./state/AgentFormProvider";
import AgentNavigationProvider from "./AgentNavigationProvider";
import AgentHeader from "./AgentHeader";
import AgentBuilder from "./AgentBuilder";

const AgentBuilderPageInner = () => {
  return (
    <AgentFormProvider>
      <AgentNavigationProvider>
        <AgentHeader />
        <AgentBuilder />
      </AgentNavigationProvider>
    </AgentFormProvider>
  );
};

const AgentBuilderPageContainer = () => {
  const { isLoading, isError } = useGetAgentByParams();

  if (isLoading) {
    return <WorkflowBuilderPageLoading />;
  }
  if (isError) {
    return <Navigate to="/" replace />;
  }
  return <AgentBuilderPageInner />;
};

const AgentBuilderPage = () => {
  return (
    <AuthenticationSplitter
      loadingComponent={<WorkflowBuilderPageLoading />}
      authenticatedComponent={<AgentBuilderPageContainer />}
      externalComponent={<Navigate to="/" replace />}
    />
  );
};

export default AgentBuilderPage;
