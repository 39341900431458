import AddOutputButton from "./AddOutputButton";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import OutputList from "./OutputList";
import InputOutputListWrapper from "../../inputs/inputList/InputOutputListWrapper";
import { OUTPUT_TEXT } from "../../inputs/inputBuilders/helpers/constants";

const OutputListWrapper = () => {
  const { state } = useToolbuilderContext();
  const { toolOutputFields } = state.currentState;

  return (
    <InputOutputListWrapper
      title="Outputs"
      rightSlot={<AddOutputButton />}
      list={<OutputList toolOutputFields={toolOutputFields} />}
      secondaryText={OUTPUT_TEXT}
    />
  );
};

export default OutputListWrapper;
