import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SaveAgentButton from "./SaveAgentButton";
import type { ISaveWorkflowButtonProps } from "@toolflow/shared";
import {
  AGENT_BUILDER_REDUCER_NAME,
  closeAgentSaveDialog
} from "../state/agentBuilderSlice";
import { RootState } from "../../../../../stores/store";
import SaveAgentDialogInner from "./SaveAgentDialogInner";

function SaveAgentDialogContainer() {
  const open = useSelector(
    (state: RootState) => state[AGENT_BUILDER_REDUCER_NAME].open
  );
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(closeAgentSaveDialog());
  };

  return <SaveAgentDialogInner open={open} handleClose={handleClose} />;
}

function SaveAgentDialog(props: Readonly<ISaveWorkflowButtonProps>) {
  return (
    <>
      <SaveAgentButton {...props} />
      <SaveAgentDialogContainer />
    </>
  );
}

export default SaveAgentDialog;
