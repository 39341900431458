import type { CustomToolInputField } from "@toolflow/shared";
import { TEXTFIELD } from "../../../../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { v4 as uuidv4 } from "uuid";
import { useToolbuilderContext } from "../../../../../../context/ToolBuilderContext";
import { useDispatch } from "react-redux";
import {
  DrawerEnum,
  setDrawer
} from "../../../../../../store/toolBuilderSlice";

const useHandleAddToolInputFieldToolbuilder = () => {
  const { dispatch, state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;
  const reduxDispatch = useDispatch();

  const addToolInputFieldToolbuilder = () => {
    const id = uuidv4();
    const updatedToolInputFields: CustomToolInputField[] = [
      ...toolInputFields,
      {
        name: "",
        type: TEXTFIELD,
        id
      }
    ];
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
    reduxDispatch(
      setDrawer({
        type: DrawerEnum.inputs,
        inputId: id
      })
    );
  };

  return addToolInputFieldToolbuilder;
};

export default useHandleAddToolInputFieldToolbuilder;
