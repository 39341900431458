import { useGetToolQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useAuthStore } from "../../auth/hooks/useAuthStore";

const useGetToolFromQuery = (
  toolId?: string,
  options?: { refetchOnMountOrArgChange?: boolean; skip?: boolean }
) => {
  const { isLoading } = useAuthStore();
  const { data } = useGetToolQuery(toolId || "", {
    skip: !toolId || isLoading || options?.skip,
    refetchOnMountOrArgChange: options?.refetchOnMountOrArgChange
  });
  return toolId ? data?.tool : undefined; // data stays the same if toolId is undefined, so
};

export default useGetToolFromQuery;
