import {
  type TWorkspaceDataToSave,
  type UserInputDictType,
  type Asset
} from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { useToolContext } from "../../../tools/contexts/ToolContext";
import { setAssets } from "../assets/functions/setAssets";

const useSetToolOutput = ({
  currentAssets,
  userInput,
  componentId,
  tvrId,
  setToolOutputBase
}: {
  currentAssets: React.MutableRefObject<Asset[]>;
  userInput: UserInputDictType;
  componentId: string;
  tvrId: string;
  setToolOutputBase: (output: UserInputDictType | null) => void;
}) => {
  const { tool } = useToolContext();
  const { creator, _id: toolId } = tool;
  const { setValue } = useFormContext<TWorkspaceDataToSave>();

  const setToolOutput = (output: UserInputDictType | null) => {
    const savedInputs = [...currentAssets.current];
    if (output) {
      // Step 1: Update existing inputs and populate the inputMap with the updates
      setAssets(
        savedInputs,
        componentId,
        output,
        toolId,
        setValue,
        tvrId,
        creator,
        userInput
      );
    }

    // we need this because the tool may be closed, in which case
    // setAttributes will throw an error. But we don't care, because
    // the rest of the tool should work.
    try {
      setToolOutputBase(output);
    } catch {
      console.log("tool deleted");
    }
  };

  return setToolOutput;
};

export default useSetToolOutput;
