import type { TAvailableTiers } from "@toolflow/shared";
import useSubscriptionDict from "./hooks/useSubscriptionDict";
import { Grid } from "@mui/material";
import SubscriptionTierCard from "./SubscriptionTierCard";
import { FREE_PLAN_FE } from "../../../../billing/helpers/billingConstants";

const SubscriptionSection = () => {
  const subscriptionDict = useSubscriptionDict();
  return (
    <Grid container columnSpacing={3} rowSpacing={2} className="m-t-12px">
      {(Object.keys(subscriptionDict) as TAvailableTiers[]).map((tier) => (
        <>
          {tier !== FREE_PLAN_FE && (
            <Grid
              xs={12}
              md={6}
              item
              key={tier}
              className="flex justify-center"
            >
              <SubscriptionTierCard tier={tier} />
            </Grid>
          )}
        </>
      ))}
    </Grid>
  );
};

export default SubscriptionSection;
