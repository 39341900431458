import { IconButton, type SxProps } from "@mui/material";
import React from "react";
import { getLocationTrackProperties } from "../../../../../../utilities/functions/getLocationTrackProperties";
import { useLocation } from "react-router-dom";
import useToolflowAPI from "../../../../../../ToolflowAPI/useToolflowAPI";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../../../layout/alertMessageSnackbar/alertMessageSlice";
import { useTrackEventMutation } from "../../../../../../ToolflowAPI/rtkRoutes/trackEventApi";
import { CopyIcon } from "../../../../../../globalTheme/icons/icons";

function CopyImageBlobButton({
  sx,
  url,
  tvrId
}: Readonly<{
  sx?: SxProps;
  url: string;
  tvrId?: string;
}>) {
  const { getImageProxy } = useToolflowAPI();
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();
  const reduxDispatch = useDispatch();

  const copyImageToClipboard = async (blob: Blob) => {
    const blobUrl = URL.createObjectURL(blob);

    const imageHtml = `<img src="${blobUrl}" alt="copied image"/>`;
    const clipboardItem = new ClipboardItem({
      "image/png": blob,
      "text/html": new Blob([imageHtml], { type: "text/html" })
    });
    await navigator.clipboard.write([clipboardItem]);
    reduxDispatch(setSuccessMessage("Copied"));
  };

  const handleImage = (imageBlob: Blob) => {
    copyImageToClipboard(imageBlob);
  };

  const handleCopyClick = async (imageUrl: string) => {
    trackEvent({
      eventString: "Used output",
      properties: {
        ...getLocationTrackProperties(location.pathname),
        action_outcome_type: "copy_tool_output",
        output_type: "image"
      },
      toolVersionResponseId: tvrId ?? ""
    });
    await getImageProxy(imageUrl, handleImage);
  };

  return (
    <IconButton sx={sx} onClick={() => handleCopyClick(url)}>
      <CopyIcon />
    </IconButton>
  );
}

export default CopyImageBlobButton;
