import { parseBooleanEnv } from "./parseBooleanEnv";

export const getBackendUrl = () => {
  const isPullRequest = parseBooleanEnv(
    process.env.REACT_APP_PREVIEW_IS_PULL_REQUEST
  );
  if (isPullRequest) {
    return process.env.REACT_APP_PREVIEW_RENDER_API_URL;
  }
  return process.env.REACT_APP_API_URL;
};
