import { useRef } from "react";
import { type Content } from "@tiptap/core";
import { getNewStringAsset } from "./functions/getNewStringAsset";
import { useWorkspaceAssetsFieldArrayContext } from "../contexts/WorkspaceAssetsContext";
import useSetVisibleAsset from "./useSetVisibleAsset";
import {
  type Asset,
  AssetValueType,
  type DallEOutputType
} from "@toolflow/shared";
import { getNewDallEAsset } from "./functions/getNewDallEAsset";
import { getNewStructuredAsset } from "./functions/getNewStructuredAsset";
import useConvertContentToHtml from "../../../../utilities/formatters/strings/tiptap/hooks/useConvertContentToHtml";

function useAddNewAsset() {
  const { append } = useWorkspaceAssetsFieldArrayContext();
  const { setVisibleAsset } = useSetVisibleAsset();
  const convertContentToHtml = useConvertContentToHtml();

  // Buffer to accumulate streaming JSON data
  const jsonBufferRef = useRef<string>("");

  const isJsonComplete = (jsonString: string): boolean => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch {
      return false;
    }
  };

  const addNewAsset = ({
    value,
    valueType,
    id,
    name,
    version
  }: {
    value?: Content | DallEOutputType;
    valueType?: AssetValueType;
    id?: string;
    name?: string;
    version?: number;
  }): string | null => {
    if (!valueType || !value) return null;

    let asset: Asset | null = null;

    if (valueType === AssetValueType.String) {
      const stringContent = convertContentToHtml(value as Content);
      asset = getNewStringAsset({ value: stringContent, id, name, version });
    } else if (
      valueType === AssetValueType.DallE ||
      valueType === AssetValueType.Structured
    ) {
      // Accumulate streaming JSON data
      jsonBufferRef.current += value as string;

      // Check if the accumulated JSON is complete
      if (isJsonComplete(jsonBufferRef.current)) {
        try {
          const parsedValue =
            valueType === AssetValueType.DallE
              ? (JSON.parse(jsonBufferRef.current) as DallEOutputType)
              : JSON.parse(jsonBufferRef.current);

          asset =
            valueType === AssetValueType.DallE
              ? getNewDallEAsset({
                  value: parsedValue,
                  id,
                  name,
                  version
                })
              : getNewStructuredAsset({
                  value: parsedValue,
                  id,
                  name,
                  version
                });

          // Clear the buffer after successful parsing
          jsonBufferRef.current = "";
        } catch (parseError) {
          console.error("Failed to parse JSON:", parseError);
          return null;
        }
      } else {
        // JSON is incomplete; wait for more data
        return null;
      }
    }

    if (asset) {
      append(asset);
      setVisibleAsset(asset.id);
      return asset.id;
    }

    return null;
  };

  return addNewAsset;
}

export default useAddNewAsset;
