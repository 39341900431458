import React from "react";
import { AssetContextComponent } from "./AssetContext";
import useWorkspaceAssetById from "../../features/pages/workstation/assets/useWorkspaceAssetById";

function AssetContextComponentContainer({
  assetId,
  children
}: {
  assetId: string;
  children: React.ReactNode;
}) {
  const asset = useWorkspaceAssetById({ id: assetId });
  return (
    <AssetContextComponent asset={asset}>{children}</AssetContextComponent>
  );
}

export default AssetContextComponentContainer;
