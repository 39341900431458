import { Icon } from "@mui/material";
import Logo from "../../../globalTheme/icons/images/googleSVG.svg";

function GoogleLogo({
  size = 32,
  className = ""
}: {
  size?: number;
  className?: string;
}) {
  return (
    <div
      className={`flex align-i-center justify-center ${className}`}
      style={{
        background: "transparent",
        borderRadius: 3,
        padding: 1,
        height: size,
        width: size
      }}
    >
      <Icon className="flex h-100-percent w-100-percent align-i-center justify-center">
        <img src={Logo} height={size} />
      </Icon>
    </div>
  );
}

export default GoogleLogo;
