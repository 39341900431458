import React from "react";
import BaseSettingsDropdown from "./base/BaseSettingsDropdown";
import SettingsButtonComponent from "./base/SettingsButtonComponent";
import type { SxProps, Theme, TooltipProps } from "@mui/material";
import { VerticalMoreIcon } from "../../../../globalTheme/icons/icons";

function SettingsDropdown({
  menuItems,
  icon = <VerticalMoreIcon />,
  IconButtonProps,
  TooltipProps
}: {
  children?: React.ReactNode;
  menuItems: React.ReactNode[];
  icon?: React.ReactNode;
  IconButtonProps?: {
    disabled?: boolean;
    draggable?: boolean;
    "data-drag-handle"?: boolean;
    sx?: SxProps<Theme>;
    className?: string;
  };
  TooltipProps?: Omit<TooltipProps, "children">;
}) {
  return (
    <BaseSettingsDropdown menuItems={menuItems}>
      <SettingsButtonComponent
        icon={icon}
        IconButtonProps={IconButtonProps}
        TooltipProps={TooltipProps}
      />
    </BaseSettingsDropdown>
  );
}

export default SettingsDropdown;
