import { useEffect } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";

const useResetSlash = () => {
  const { setIsSlash, shouldRenderBubble } = useMuiTipTapContext();

  useEffect(() => {
    if (!shouldRenderBubble) {
      setIsSlash(false);
    }
  }, [shouldRenderBubble]);
};

export default useResetSlash;
