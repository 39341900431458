import React from "react";
import SettingsMenuItemWithClose from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import useDuplicateObjectToolbuilderToolbuilder from "../../hooks/useDuplicateObjectToolbuilder";
import { ForkIcon } from "../../../../../../globalTheme/icons/icons";

// Note - only use this for personal tools
// because we are passing the isPersonal flag as true
// to useDuplicateObject so that you can copy private
// tools
function DuplicateToolMenuItemToolbuilder({
  id,
  useLastSaveText
}: {
  id: string;
  useLastSaveText?: boolean;
}) {
  const { loading, duplicate } = useDuplicateObjectToolbuilderToolbuilder(id);

  return (
    <SettingsMenuItemWithClose
      disabled={loading}
      hide={!id}
      action={duplicate}
      hotkey="Meta+D"
      text={`Duplicate tool${useLastSaveText ? " last save" : ""}`}
      icon={<ForkIcon fontSize="small" />}
    />
  );
}

export default DuplicateToolMenuItemToolbuilder;
