import { EAddChipTab } from "@toolflow/shared";

let tabId = 0;

export const ALL = "all";

export type TChipTab = {
  label: string;
  value: EAddChipTab | typeof ALL;
  indexValue: number;
};

export const addChipTabs: TChipTab[] = [
  {
    label: "All",
    value: ALL,
    indexValue: tabId++
  },
  {
    label: "Inputs",
    value: EAddChipTab.NODES,
    indexValue: tabId++
  },
  {
    label: "Node Outputs",
    value: EAddChipTab.NODE_OUTPUTS,
    indexValue: tabId++
  },
  {
    label: "Assets",
    value: EAddChipTab.ASSETS,
    indexValue: tabId++
  },
  {
    label: "Snippets",
    value: EAddChipTab.SNIPPETS,
    indexValue: tabId++
  }
];
