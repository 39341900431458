import {
  AutocompleteGroups,
  type Snippet,
  type ToolAutocomplete
} from "@toolflow/shared";

function formatSnippetsForAutocomplete(
  snippets: Snippet[]
): ToolAutocomplete[] {
  return snippets.map((snippet) => {
    return {
      primary: snippet.name,
      id: snippet._id,
      secondary: snippet.snippetValue,
      group: AutocompleteGroups.Snippet
    };
  });
}

export default formatSnippetsForAutocomplete;
