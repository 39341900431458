import useFavoriteContext from "./useFavoriteContext";
import {
  TAvailableTags,
  TInvalidateTag
} from "../../../../ToolflowAPI/rtkRoutes/favoritesApi";
import { useParams } from "react-router-dom";
import useGetCurrentUser from "../../../../features/user/hooks/useGetCurrentUser";

import { useCallback } from "react";
import {
  FAVORITE_TAG_TYPE,
  TOOL_TAG_TYPE,
  WORKFLOW_TAG_TYPE
} from "../../../../ToolflowAPI/cacheTagConstants";
import { EntityType } from "@toolflow/shared";

const useGetInvalidateCacheIdForFavorites = (type: EntityType) => {
  const { id, inMarketplace, creatorId } = useFavoriteContext();
  const user = useGetCurrentUser();
  const { profileId = "" } = useParams();

  const getInvalidateCacheIdForFavorites = useCallback((): TInvalidateTag => {
    if (!id) return [];

    const cacheBase: { type: TAvailableTags; id: string }[] = [
      {
        type: type === EntityType.TOOL ? TOOL_TAG_TYPE : WORKFLOW_TAG_TYPE,
        id: creatorId
      },
      {
        type: FAVORITE_TAG_TYPE,
        id: profileId ? profileId : user?._id || ""
      }
    ];

    if (inMarketplace) {
      return ["MarketplaceTools", ...cacheBase];
    } else return cacheBase;
  }, [id, inMarketplace, creatorId, profileId, user]);

  return getInvalidateCacheIdForFavorites;
};

export default useGetInvalidateCacheIdForFavorites;
