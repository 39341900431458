import { useHandleLogout } from "../layout/sidebar/useHandleLogout";
import { Button } from "@mui/material";

const LogoutButton = () => {
  const handleLogout = useHandleLogout();

  return (
    <Button variant="outlined" onClick={handleLogout}>
      Log out
    </Button>
  );
};

export default LogoutButton;
