import { useToolInputFieldsContext } from "./inputBuilders/contexts/ToolInputFieldsContext";
import EmptyInputs from "./inputBuilders/emptyInputs/EmptyInputs";
import InputListOrDetailsSplitter from "./InputListOrDetailsSplitter";

function InputDrawerInner() {
  const { toolInputFields } = useToolInputFieldsContext();

  if (toolInputFields.length === 0) {
    return <EmptyInputs />;
  }

  return <InputListOrDetailsSplitter />;
}

export default InputDrawerInner;
