import { Stack } from "@mui/material";
import { type JSONValue } from "@toolflow/shared";
import { isObject } from "lodash";
import { Fragment } from "react";
import OutputLoadingWrapper from "../../../../../../../utilities/components/loading/HideWhenLoadingWrapper";
import { isHandledChatStackError } from "../../../typeCheckers";
import {
  StructuredListOfObjects,
  StructuredListOfSingleValues,
  StructuredObject,
  StructuredSingleValue
} from "./components";
import {
  isArrayOfObjects,
  isArrayOfSingleValues,
  isObjectType,
  isSingleLineValue
} from "./helpers/structuredOutputHelpers";

export default function StructuredOutput({
  value,
  showLoading
}: {
  value: JSONValue;
  showLoading?: boolean;
}) {
  if (!isObject(value)) return null;

  return (
    <OutputLoadingWrapper showLoading={showLoading}>
      <Stack spacing={1}>
        {Object.entries(value).map(([key, val], index) => {
          return (
            <Fragment key={index}>
              {isHandledChatStackError(val) ? (
                <StructuredSingleValue name={key} value={val.data.message} />
              ) : isSingleLineValue(val) ? (
                <StructuredSingleValue name={key} value={val} />
              ) : isArrayOfSingleValues(val) ? (
                <StructuredListOfSingleValues name={key} value={val} />
              ) : isArrayOfObjects(val) ? (
                <StructuredListOfObjects name={key} value={val} />
              ) : isObjectType(val) ? (
                <StructuredObject name={key} value={val} />
              ) : (
                <></>
              )}
            </Fragment>
          );
        })}
      </Stack>
    </OutputLoadingWrapper>
  );
}
