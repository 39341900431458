import { ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import { ChipNodeHtmlTags } from "@toolflow/shared";
import { AssetIcon } from "../../../../../globalTheme/icons/icons";
import useAddNewAsset from "../../../../../features/pages/workstation/assets/useAddNewAsset";
import useWorkspaceAssets from "../../../../../features/pages/workstation/assets/useWorkspaceAssets";
import { useEffect, useMemo } from "react";
import { useUpdateAsset } from "../../../../../features/pages/workstation/components/assets/asset/textAsset/hooks/useAssetTextField";
import useSetVisibleAsset from "../../../../../features/pages/workstation/assets/useSetVisibleAsset";

const FlowArtifactViewerComponent = (props: NodeViewProps) => {
  const addAssetContent = useAddNewAsset();

  const assets = useWorkspaceAssets();

  const asset = useMemo(() => {
    return assets.filter((i) => i.id === props.node.attrs.identifier)[0];
  }, [props.node.attrs.identifier, assets]);
  const onUpdate = useUpdateAsset(asset);
  const { setVisibleAsset } = useSetVisibleAsset();

  const handleClick = () => {
    if (!asset) {
      addAssetContent({
        value: props.node.textContent,
        valueType: props.node.attrs.type,
        name: props.node.attrs.title,
        id: props.node.attrs.identifier
      });
    } else if (asset.data?.version !== props.node.attrs.version) {
      onUpdate(
        props.node.textContent,
        props.node.attrs.title,
        props.node.attrs.version
      );
      setVisibleAsset(asset.id);
    }
  };

  // useEffect(() => {
  //   // switched from timer to microtask because it is cleaner
  //   // added an empty catch block because we get an out of range error in strict mode
  //   queueMicrotask(() => {
  //     try {
  //       if (!!asset) {
  //         props.updateAttributes({
  //           textValue: asset.value,
  //           name: asset.name
  //         });
  //       } else {
  //         handleClick();
  //       }
  //     } catch {}
  //   });
  // }, [asset?.name, asset?.value]);

  useEffect(() => {
    if (props.node.textContent) {
      if (!asset) {
        handleClick();
      } else {
        onUpdate(props.node.textContent);
      }
    }
  }, [props.node.textContent]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.FlowArtifactComponent} dontTriggerBubble w-100-percent`}
    >
      <Paper
        variant="outlined"
        className="cursor-pointer w-100-percent m-v-16px border-radius-8px bg-color-right-side-content"
        onClick={handleClick}
      >
        <ListItem>
          <ListItemIcon>
            <AssetIcon />
          </ListItemIcon>
          <ListItemText
            primary={props.node.attrs.title}
            secondary="Click to open"
          />
        </ListItem>
      </Paper>
    </NodeViewWrapper>
  );
};

export default FlowArtifactViewerComponent;
