import { BlockSettingsWithInputType } from "../../blocks";
import {
  BaseInputFieldProps,
  FieldsByTypePropsBase,
  FieldType
} from "../fieldTypes";

export enum CustomSelectInputValueType {
  String = "string",
  Array = "array"
}

export type CustomSelectInputFieldConfig = {
  sourceLabel: string;
  fieldKey: string;
  inputValueType?: CustomSelectInputValueType;
  userFieldKey?: string;
};

export interface CustomSelectInputFieldType extends BaseInputFieldProps {
  type: FieldType.CustomSelectInput;
  config: CustomSelectInputFieldConfig;
}

export interface CustomSelectInputWrapperProps extends FieldsByTypePropsBase {
  field: CustomSelectInputFieldType;
  settings: BlockSettingsWithInputType;
}
