import { DateTime } from "luxon";

const groupByDate = <T extends { updatedAt: number }>(items: T[]) => {
  const groups: Record<
    | "Today"
    | "Yesterday"
    | "This Week"
    | "Last Week"
    | "Last Month"
    | "Previous",
    T[]
  > = {
    Today: [],
    Yesterday: [],
    "This Week": [],
    "Last Week": [],
    "Last Month": [],
    Previous: []
  };

  const now = DateTime.local();

  items.forEach((item) => {
    const date = DateTime.fromSeconds(item.updatedAt);
    if (date.hasSame(now, "day")) {
      groups.Today.push(item);
    } else if (date.hasSame(now.minus({ days: 1 }), "day")) {
      groups.Yesterday.push(item);
    } else if (date.hasSame(now, "week")) {
      groups["This Week"].push(item);
    } else if (date >= now.minus({ weeks: 1 }) && date < now.startOf("week")) {
      groups["Last Week"].push(item);
    } else if (
      date >= now.minus({ months: 1 }) &&
      date < now.startOf("week").minus({ weeks: 1 })
    ) {
      groups["Last Month"].push(item);
    } else {
      groups.Previous.push(item);
    }
  });

  return groups;
};

export default groupByDate;
