import { useMemo } from "react";
import useGetAllEntityOptions from "./useGetAllEntityOptions";
import useGetUseCaseOptions from "./useGetUseCaseOptions";
import {
  formatMarketplaceEntitiesForAutocomplete,
  formatUseCasesForAutocomplete
} from "../../functions/formatEntitiesForAutocomplete";
import type { TDisabledFields } from "@toolflow/shared";

const useGetFilteredOptionsForAutocomplete = (
  disabledFields?: TDisabledFields
) => {
  const allEntityOptions = useGetAllEntityOptions({
    disableWorkflowsOptions: disabledFields?.workflows,
    disableToolsOptions: disabledFields?.tools,
    disableAgentsOptions: disabledFields?.agents
  });
  const useCaseOptions = useGetUseCaseOptions();
  const formattedOptions = useMemo(
    () => [
      ...formatMarketplaceEntitiesForAutocomplete(allEntityOptions),
      ...(disabledFields?.useCases
        ? []
        : formatUseCasesForAutocomplete(useCaseOptions))
    ],
    [allEntityOptions, useCaseOptions]
  );

  return formattedOptions;
};

export default useGetFilteredOptionsForAutocomplete;
