import { selectAllStarredTools } from "../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import { useSelector } from "react-redux";
import { useGetCurrentUserProfileId } from "../../user/hooks/useGetCurrentUserProfileId";
import { useGetProfileFavoriteEntitiesQuery } from "../../../ToolflowAPI/rtkRoutes/favoritesApi";

export const useGetCurrentUserStarredEntities = () => {
  const profileId = useGetCurrentUserProfileId();
  const { isFetching, isLoading } = useGetProfileFavoriteEntitiesQuery(
    profileId,
    {
      skip: !profileId
    }
  ); // we need this to stay subscribed to the query
  const entities = useSelector(selectAllStarredTools(profileId));

  return {
    entities,
    loading: isLoading || isFetching
  };
};
