import {
  BlockNode,
  OutputType,
  type DynamicFieldValue,
  type IterationStartBlockData,
  type JSONSchemaItem,
  type SerpBlockData,
  type StructuredBlockData,
  type TSerpAllowedOptions,
  type ValidatedInput
} from "@toolflow/shared";
import { isArray, isBoolean } from "lodash";
import { useMemo } from "react";
import { BLOCK_OUTPUT } from "../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import {
  findNestedSchema,
  getOutputFieldsFromJsonSchema
} from "./helpers/jsonSchemaHelpers";

const useToolInputFieldsAndAvailableFields = (id: string) => {
  const { state } = useToolbuilderContext();
  const { toolInputFields, availableFields, blocks, toolOutputOptions } =
    state.currentState;

  const fieldNames: ValidatedInput[] = toolInputFields;

  // toolInputFields.forEach((tag: ValidatedInput) => {
  //   fieldNames.push(tag); // Always add the tag name

  //   // If the tag type is "fileUpload" and there are options,
  //   // split them by "," and add them to the array
  //   if (tag.type === "csvFileUpload") {
  //     const additionalFields = tag?.options || [];
  //     fieldNames = [...fieldNames, ...additionalFields];
  //   }
  // });

  const availableFieldsById: ValidatedInput[] = useMemo(() => {
    // we don't want some of the block nodes to show up in prompts
    const blockNodesToRemove = blocks
      .filter((block) =>
        [BlockNode.LogicBlockNode, BlockNode.IterationExitBlockNode].includes(
          block.type as BlockNode
        )
      )
      .map((block) => block.data.label);

    const fields = availableFields[id] || [];

    return fields.reduce((acc, field) => {
      if (blockNodesToRemove.includes(field)) return acc;

      acc.push({ name: field, type: BLOCK_OUTPUT });

      const block = blocks.find((b) => b.data.label === field);
      if (block?.type === BlockNode.StructuredBlockNode) {
        const data = block.data as StructuredBlockData;
        const availableJsonOutputs = getOutputFieldsFromJsonSchema(
          data.settings.schema
        );
        availableJsonOutputs.forEach((jo) => {
          if (jo.path) {
            acc.push({
              name: field,
              type: BLOCK_OUTPUT,
              config: {
                type: OutputType.JsonString,
                label: `${field}.${jo.path}`,
                nestedOutputKey: jo.path,
                valueType: jo.type
              }
            });
          }
        });
      } else if (block?.type === BlockNode.SerpBlockNode) {
        const data = block.data as SerpBlockData;

        const allowedOutputKeys = [
          "peopleAlsoAsk",
          "relatedQueries",
          "organicResults",
          "paidResults"
        ] as (keyof TSerpAllowedOptions)[];

        allowedOutputKeys.forEach((key) => {
          const value = isBoolean(data.settings.allowedOutputs[key])
            ? data.settings.allowedOutputs[key]
            : (data.settings.allowedOutputs[key] as DynamicFieldValue).value;

          if (!!value) {
            acc.push({
              name: field,
              type: BLOCK_OUTPUT,
              config: {
                type: OutputType.JsonObject,
                label: `${field}.${key}`,
                nestedOutputKey: key,
                valueType: "array"
              }
            });
          }
        });
      } else if (block?.type === BlockNode.IterationStartBlockNode) {
        const blockData = block.data as IterationStartBlockData;
        const inputValue = blockData.settings.input;

        if (!isArray(inputValue)) {
          const nestedKeys = inputValue
            .replace("{{", "")
            .replace("}}", "")
            .split(".");
          const sourceName = nestedKeys.at(0);

          const sourceBlock = blocks.find((b) => b.data.label === sourceName);
          const schema = sourceBlock?.data?.settings
            ?.schema as JSONSchemaItem[];

          if (schema) {
            const nestedPath = nestedKeys.slice(1);

            if (nestedPath.length) {
              const nestedValue = findNestedSchema(schema, nestedPath);

              if (
                nestedValue?.type === "array" &&
                nestedValue.itemType.type === "object"
              ) {
                const availableJsonOutputs = getOutputFieldsFromJsonSchema(
                  nestedValue.itemType.items
                );

                availableJsonOutputs.forEach((jo) => {
                  if (jo.path) {
                    acc.push({
                      name: field,
                      type: BLOCK_OUTPUT,
                      config: {
                        type: OutputType.JsonString,
                        label: `${field}.${jo.path}`,
                        nestedOutputKey: jo.path,
                        valueType: jo.type
                      }
                    });
                  }
                });
              }
            }
          }
        }
      }

      return acc;
    }, [] as ValidatedInput[]);
  }, [id, availableFields, toolOutputOptions]);

  return fieldNames.concat(availableFieldsById);
};

export default useToolInputFieldsAndAvailableFields;
