import React from "react";
import NoWrapCardHeader from "../../../utilities/components/cards/NoWrapCardHeader";
import ToolflowSkeletonWrapper, {
  ToolsLoading
} from "../../../utilities/components/loading/ToolflowSkeleton";

const ToolsPageLoading = () => {
  return (
    <>
      <NoWrapCardHeader
        title={
          <ToolflowSkeletonWrapper sx={{ maxWidth: "80%" }}>
            Welcome to the Marketplace!
          </ToolflowSkeletonWrapper>
        }
        subheader={
          <ToolflowSkeletonWrapper sx={{ maxWidth: "80%" }}>
            The place to find and discover new Toolflow tools
          </ToolflowSkeletonWrapper>
        }
      />
      <ToolsLoading />
    </>
  );
};

export default ToolsPageLoading;
