import { Box, Typography } from "@mui/material";
import { type IterationExitBlockProps } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

export default function IterationExitBlockInner({
  data
}: Omit<IterationExitBlockProps, "selected">) {
  const { label } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  return (
    <>
      <Box sx={{ marginTop: 2, textAlign: "center" }}>
        <Typography variant="subtitleMedium">
          Exit the iteration. All nodes after this one will not be iterated.
        </Typography>
      </Box>
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}
