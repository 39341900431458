import {
  DeepgramModel,
  FieldType,
  type DeepgramBlockPropsV2,
  type FieldConfig,
  type FieldTypeConfigs
} from "@toolflow/shared";
import {
  deepgramLanguages,
  deepgramModels
} from "../../../../settings/deepgram/deepgramUtils";
import useUpdateField from "../../../../useUpdateField";
import { filterAvailableFieldsByArray } from "../../../helpers/filterAvailableFields";
import { deepgramTranscribePromptInitialStateV2 } from "../../../../context/initialStates";

export default function useDeepgramFieldConfigs({
  id,
  data
}: Omit<DeepgramBlockPropsV2, "selected">) {
  const updateField = useUpdateField(id);

  const model = data.settings.model.value;
  const isWhisperSelected = model === DeepgramModel.WhisperMedium;

  const { settings } = deepgramTranscribePromptInitialStateV2;

  const configFields: FieldTypeConfigs[] = isWhisperSelected
    ? []
    : [
        {
          type: FieldType.DynamicListField,
          label: "Keywords",
          subtitle: "Add keywords to improve transcription accuracy.",
          config: {
            typeKey: "settings.keywords.type",
            fieldKey: "settings.keywords.value",
            filterAvailableFields: filterAvailableFieldsByArray,
            defaultValue: settings.keywords.value
          }
        },
        {
          type: FieldType.WordReplaceField,
          label: "Find and replace",
          subtitle:
            "Replace specific terms in transcription (case-sensitive, excludes speaker names).",
          config: {
            fieldKey: "settings.wordsToReplace"
          }
        }
      ];

  const config: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        type: FieldType.FileField,
        label: "Audio file",
        config: {
          fieldKey: "settings.file"
        }
      },
      {
        type: FieldType.DynamicSelectField,
        label: "Model",
        subtitle: "Select audio transcription technology.",
        config: {
          typeKey: "settings.model.type",
          fieldKey: "settings.model.value",
          options: deepgramModels,
          defaultValue: settings.model.value
        }
      },
      ...configFields,
      {
        type: FieldType.Group,
        label: "Processing options",
        fields: [
          {
            type: FieldType.DynamicSelectField,
            label: "Audio language",
            config: {
              typeKey: "settings.language.type",
              fieldKey: "settings.language.value",
              options: deepgramLanguages[model] || [],
              defaultValue: settings.language.value
            }
          },
          {
            type: FieldType.DynamicSwitchField,
            label: "Diarize",
            subtitle: "Separate speakers in audio",
            config: {
              typeKey: "settings.diarize.type",
              fieldKey: "settings.diarize.value",
              defaultValue: settings.diarize.value
            }
          },
          {
            type: FieldType.DynamicSwitchField,
            label: "Timestamps",
            subtitle: "Include time markers in the transcript",
            config: {
              typeKey: "settings.addTimestamps.type",
              fieldKey: "settings.addTimestamps.value"
            }
          },
          {
            type: FieldType.DynamicSwitchField,
            label: "Summarize",
            subtitle: "Generate a summary of the content",
            config: {
              typeKey: "settings.addSummary.type",
              fieldKey: "settings.addSummary.value",
              defaultValue: settings.addSummary.value
            }
          },
          {
            type: FieldType.DynamicSwitchField,
            label: "Dictation",
            subtitle:
              "Convert spoken dictation commands to their corresponding punctuation marks.",
            config: {
              typeKey: "settings.isFileDictation.type",
              fieldKey: "settings.isFileDictation.value",
              defaultValue: settings.isFileDictation.value
            }
          },
          {
            type: FieldType.DynamicSwitchField,
            label: "Convert measurements",
            subtitle:
              "Convert measurements to abbreviations (e.g. milligram(s) to mg).",
            config: {
              typeKey: "settings.convertMeasurementsValuesToAbbreviations.type",
              fieldKey:
                "settings.convertMeasurementsValuesToAbbreviations.value",
              defaultValue:
                settings.convertMeasurementsValuesToAbbreviations.value
            }
          }
        ]
      }
    ]
  };

  return config;
}
