import { IconButton } from "@mui/material";
import React from "react";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";
import { useClearAnyEntityButton } from "./hooks/useClearAnyEntityButton";

export function ClearAnyEntityButton({ className }: { className?: string }) {
  const { clearAnyEntity, hideButton } = useClearAnyEntityButton();

  if (hideButton) {
    return null;
  }

  return (
    <IconButton size="small" onClick={clearAnyEntity} className={className}>
      <CloseIcon size={16} />
    </IconButton>
  );
}
