import type { TAgentBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { AGENT_ENABLE_FORKING_FIELD_LABEL } from "../../constants/fields";

function useGetAgentEnableForking() {
  const { watch } = useFormContext<TAgentBuilderForm>();
  return watch(AGENT_ENABLE_FORKING_FIELD_LABEL);
}

export default useGetAgentEnableForking;
