import type {
  CopyWorkspaceResponseFE,
  SaveWorkspaceResponseFE,
  TWorkspaceDataToSave,
  UpdateWorkspaceDataToSend,
  WorkspaceResponseFE,
  WorkspacesResponseFE
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";
import { streamlinedWorkspaceAdapter } from "../../stores/adapters/workspaceAdapter";
import type { RootState } from "../../stores/store";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import invalidateWorkspaceTags from "./helpers/invalidateWorkspaceTags";
import { WORKSPACE_TAG_TYPE } from "../cacheTagConstants";
import { trackEventApi } from "./trackEventApi";
import { getLocationTrackProperties } from "../../utilities/functions/getLocationTrackProperties";
import { setWorkspaceId } from "../../features/pages/workstation/state/workspaceSlice";

export const workspaceApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkspace: builder.query<WorkspaceResponseFE, string>({
      query: (workspaceId: string) => ({
        url: `workspace/${workspaceId}`,
        method: "GET"
      }),
      async onQueryStarted(
        workspaceId,
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          await queryFulfilled;
          const state = getState() as RootState;
          if (!state.auth.auth0Content.isAuthenticated)
            dispatch(
              trackEventApi.endpoints.trackEvent.initiate({
                eventString: "Viewed workspace",
                properties: {
                  ...getLocationTrackProperties(location.pathname),
                  logged_in: false
                },
                workspaceId
              })
            );
        } catch {}
      }
    }),
    saveWorkspace: builder.mutation<
      SaveWorkspaceResponseFE,
      {
        workspaceId?: string;
        body: TWorkspaceDataToSave;
      }
    >({
      query: ({ body, workspaceId = "" }) => ({
        url: `/workspace/${workspaceId}`,
        method: "POST",
        body: {
          ...body,
          workspaceId: workspaceId ? workspaceId : undefined,
          _id: workspaceId
        }
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setWorkspaceId(data.workspace._id));
          invalidateWorkspaceTags(dispatch, getState() as RootState);
        } catch {
          console.error("Error saving workspace");
        }
      }
    }),
    copyWorkspace: builder.mutation<CopyWorkspaceResponseFE, string>({
      query: (workspaceId: string) => ({
        url: `/workspace/${workspaceId}/copy`,
        method: "POST"
      }),
      async onQueryStarted(
        workspaceId,
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          await queryFulfilled;
          invalidateWorkspaceTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Workspace copied"));
        } catch {
          dispatch(setErrorMessage("Error copying workspace"));
        }
      }
    }),
    updateWorkspace: builder.mutation({
      query: ({
        workspaceId,
        body
      }: {
        workspaceId: string;
        body: UpdateWorkspaceDataToSend;
      }) => ({
        url: `workspace/${workspaceId}`,
        method: "PUT",
        body
      }),
      async onQueryStarted(params, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          invalidateWorkspaceTags(dispatch, getState() as RootState);
          dispatch(setSuccessMessage("Workspace saved"));
        } catch {
          dispatch(setErrorMessage("Error updating workspace"));
        }
      }
    }),
    deleteWorkspace: builder.mutation({
      query: (workspaceId: string) => ({
        url: `workspace/${workspaceId}`,
        method: "DELETE"
      }),
      async onQueryStarted(
        workspaceId,
        { dispatch, queryFulfilled, getState }
      ) {
        try {
          await queryFulfilled;
          const state = getState() as RootState;
          invalidateWorkspaceTags(dispatch, state);
          if (state.workspace.workspaceId === workspaceId) {
            dispatch(setWorkspaceId(""));
          }
          dispatch(setSuccessMessage("Workspace deleted"));
        } catch {
          dispatch(setErrorMessage("Error deleting workspace"));
        }
      }
    }),
    getProfileWorkspaces: builder.query({
      query: (profileId: string) => ({
        url: `workspace/p/${profileId}`,
        method: "GET"
      }),
      transformResponse: (response: WorkspacesResponseFE) => {
        return streamlinedWorkspaceAdapter.upsertMany(
          streamlinedWorkspaceAdapter.getInitialState(),
          response.workspaces
        );
      },
      providesTags: (result, error, args) => {
        const profileId = args;
        return [{ type: WORKSPACE_TAG_TYPE, id: profileId }];
      }
    })
  })
});

export const {
  useGetWorkspaceQuery,
  useGetProfileWorkspacesQuery,
  useSaveWorkspaceMutation,
  useUpdateWorkspaceMutation,
  useCopyWorkspaceMutation,
  useDeleteWorkspaceMutation
} = workspaceApi;
