import React from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import BottomGradient from "../../../BottomGradient";
import AssetTextFieldContainer from "./AssetTextField";
import useScroller from "./hooks/useScroller";

const AssetTextFieldScrollWrapper = () => {
  const scroller = useScroller();
  return (
    <>
      <ScrollToBottom
        followButtonClassName="arrow-button"
        initialScrollBehavior="smooth"
        className="h-100-percent scroll-to-bottom"
        scrollViewClassName="scrollable-content-important"
        scroller={scroller}
      >
        <AssetTextFieldContainer />
        {/* We add margin here because the Bottom gradient overlaps the border radius of the paper */}
        <BottomGradient className="m-l-16px" />
      </ScrollToBottom>
    </>
  );
};

export default AssetTextFieldScrollWrapper;
