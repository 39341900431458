import {
  CATEGORIES_ARRAY,
  TCategory,
  type TUseCase,
  USE_CASES_ARRAY
} from "@toolflow/shared";
import { z } from "zod";

const tagSchema = z.object({
  categories: z.array(z.enum(CATEGORIES_ARRAY as [TCategory, ...TCategory[]])),
  useCases: z.array(z.enum(USE_CASES_ARRAY as [TUseCase, ...TUseCase[]]))
});

export default tagSchema;
