import { useState, useEffect } from "react";
import useGetCurrentUser from "../../../user/hooks/useGetCurrentUser";

const useGreeting = (): string => {
  const [greeting, setGreeting] = useState<string>("");
  const currentUser = useGetCurrentUser();
  const userName = currentUser?.firstName || "";
  useEffect(() => {
    const getCurrentGreeting = (): string => {
      const userNameText =
        userName && userName !== "Anonymous" ? `, ${userName}` : "";
      const currentHour = new Date().getHours();
      if (currentHour < 12) {
        return `Good morning${userNameText} ☀️`;
      } else if (currentHour < 17) {
        return `Good afternoon${userNameText} ☀️`;
      } else {
        return `Good evening${userNameText} 🌙`;
      }
    };

    setGreeting(getCurrentGreeting());

    const intervalId = setInterval(() => {
      setGreeting(getCurrentGreeting());
    }, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, [userName]);

  return `${greeting} `;
};

export default useGreeting;
