import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { getToolTipText } from "../../formatters/strings/getToolTipText";
import { UtiltityTooltip } from "./UtiltityTooltip";
import { InfoIcon } from "../../../globalTheme/icons/icons";
import { toolflowTextDisabledColor } from "../../../globalTheme/muiUtils/appTheme";

// ToolflowInfo with specific logic
interface ToolflowInfoProps {
  text?: string | ReactNode;
  className?: string;
  noCharLimit?: boolean;
  placement?: "top" | "bottom" | "left" | "right";
}

const InfoIconWithTooltip = ({
  text,
  className = "",
  noCharLimit,
  placement = "top"
}: ToolflowInfoProps) => {
  if (!text) return null;

  const noCharLimitClass =
    typeof text !== "string" || noCharLimit ? "max-w-600px" : "maxWidth300px";
  const finalClassName = `${noCharLimitClass} ${className}`;

  const content =
    typeof text === "string" ? (
      <Typography>{noCharLimit ? text : getToolTipText(text)}</Typography>
    ) : (
      <>{text}</>
    );

  return (
    <UtiltityTooltip
      title={content}
      placement={placement}
      className={finalClassName}
    >
      <InfoIcon fontSize="small" style={{ color: toolflowTextDisabledColor }} />
    </UtiltityTooltip>
  );
};

export default InfoIconWithTooltip;
