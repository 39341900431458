import type { EditorProps } from "@tiptap/pm/view";
import { Editor } from "@tiptap/react";
import { useHandleKeydown } from "./useHandleKeydown";
import useMouseUp from "./useMouseUp";
import useCloseBubble from "./useCloseBubble";
import type { RichTextEditorRef } from "mui-tiptap";

const useGetEditorProps = ({
  rteRef,
  overrideKeydown
}: {
  rteRef: React.RefObject<RichTextEditorRef>;
  overrideKeydown?: (event: KeyboardEvent, e?: Editor) => void;
}): EditorProps => {
  const mouseUp = useMouseUp({ rteRef });
  const closeBubble = useCloseBubble();
  const handleKeydown = useHandleKeydown({
    overrideKeydown,
    rteRef
  });
  return {
    handleDOMEvents: {
      keydown: handleKeydown,
      mousedown: closeBubble,
      mouseup: mouseUp,
      handleClick: closeBubble
    }
  };
};

export default useGetEditorProps;
