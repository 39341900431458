import type {
  TMarketplace,
  TUserTools,
  TStarredTab,
  TPopular,
  TAll
} from "@toolflow/shared";

export const MARKETPLACE_TAB: TMarketplace = "marketplace";
export const USER_TOOLS_TAB: TUserTools = "userTools";
export const STARRED_TAB: TStarredTab = "starred";

export const ALL_CATEGORY_TAB: TAll = "all";
export const POPULAR_CATEGORY_TAB: TPopular = "popular";
