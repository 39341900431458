import { useToolbuilderContext } from "../../../../features/pages/builder/toolBuilder/context/ToolBuilderContext";
import type { FormControlProps } from "@mui/material/FormControl";
import { useUpdateToolMutation } from "../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { UpdateEnableForkingBase } from "./UpdateEnableForkingBase";

function UpdateEnableForking({
  noMargin,
  toolId,
  formProps
}: {
  noMargin?: boolean;
  toolId: string;
  formProps?: FormControlProps;
}) {
  const { dispatch, state } = useToolbuilderContext();
  const [updateTool, { isLoading }] = useUpdateToolMutation();
  const { enableForking } = state.currentState;

  const handleEnableForking = async (value: boolean) => {
    await updateTool({
      body: { enableForking: value },
      toolId
    })
      .unwrap()
      .then((resp) => {
        dispatch({
          type: "SAVE_ENABLE_FORKING",
          enableForking: !!resp.tool.enableForking
        });
      });
  };

  return (
    <UpdateEnableForkingBase
      noMargin={noMargin}
      formProps={formProps}
      disabled={!!isLoading}
      handleEnableForking={handleEnableForking}
      enableForking={enableForking}
    />
  );
}

export default UpdateEnableForking;
