import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { toggleSidebar } from "./sidebarSlice";
import { toolflowTextSecondaryColor } from "../../../globalTheme/muiUtils/appTheme";
import { SidebarIcon } from "../../../globalTheme/icons/icons";

const ToggleSidebarButton = ({ className = "" }: { className?: string }) => {
  const dispatch = useDispatch();
  const handleToggle = () => {
    dispatch(toggleSidebar());
  };
  return (
    <IconButton onClick={handleToggle} className={className}>
      <SidebarIcon size={20} color={toolflowTextSecondaryColor} />
    </IconButton>
  );
};

export default ToggleSidebarButton;
