import React from "react";
import useSetSelectedWorkflowTool from "./hooks/useSetSelectedWorkflowTool";
import UniversalAutocomplete from "../../../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocomplete";
import { MarketplaceGroup, type TSelectAutocomplete } from "@toolflow/shared";

function WorkflowToolSelectorContainer() {
  const setSelectedTool = useSetSelectedWorkflowTool();
  const handleSelectEntity: TSelectAutocomplete = (id, type) => {
    if (type === MarketplaceGroup.TOOL) {
      setSelectedTool(id);
    }
  };
  return (
    <UniversalAutocomplete
      margin="normal"
      handleSelectEntity={handleSelectEntity}
      disabledFields={{ workflows: true, useCases: true }}
    />
  );
}

export default WorkflowToolSelectorContainer;
