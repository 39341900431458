import useWorkspaceId from "../../../../../../features/pages/workstation/hooks/useWorkspaceId";
import useWorkspaceName from "../../../../../../features/pages/workstation/hooks/useWorkspaceName";
import useWorkspaceVisibility from "../../../../../../features/pages/workstation/hooks/useWorkspaceVisibility";

const useWorkspaceSettingsDropdown = () => {
  const workspaceId = useWorkspaceId();
  const workspaceName = useWorkspaceName();
  const workspaceVisibility = useWorkspaceVisibility();

  return {
    workspaceId,
    workspaceName,
    workspaceVisibility
  };
};

export default useWorkspaceSettingsDropdown;
