import { Button, Card, CardHeader } from "@mui/material";
import type { THyperlineCustomerType } from "@toolflow/shared";
import React from "react";
import { useGetCustomerPortalQuery } from "../../../../../ToolflowAPI/rtkRoutes/billingApi";
import InfoIconWithTooltip from "../../../../../utilities/components/toolTips/InfoIconWithTooltip";
import ToolflowSkeletonWrapper from "../../../../../utilities/components/loading/ToolflowSkeleton";
import {
  HYPERLINE_CUSTOMER_ORGANIZATION,
  HYPERLINE_CUSTOMER_USER
} from "../../../../billing/helpers/billingConstants";

const PortalDict: { [key in THyperlineCustomerType]: string } = {
  [HYPERLINE_CUSTOMER_ORGANIZATION]: "Team Billing Details",
  [HYPERLINE_CUSTOMER_USER]: "Personal Billing Details"
};

export function BillingPortalCard({
  customerType
}: {
  customerType: THyperlineCustomerType;
}) {
  const { data, isLoading } = useGetCustomerPortalQuery(customerType);
  const goToPortal = () => {
    if (data?.url) {
      window.location.href = data.url;
    }
  };

  if (!data?.url) {
    return null;
  }
  return (
    <Card variant="outlined" className="m-b-16px">
      <CardHeader
        title={
          <div className="flex align-i-center">
            {PortalDict[customerType]}
            <div className="m-l-8px flex align-i-center">
              <InfoIconWithTooltip
                placement="bottom"
                noCharLimit
                text="Update your address, update payment methods, and view billing history"
              />
            </div>
          </div>
        }
        action={
          <ToolflowSkeletonWrapper
            loading={isLoading}
            variant="rounded"
            width={120}
            height={30}
          >
            <Button onClick={goToPortal} variant="outlined">
              Manage info
            </Button>
          </ToolflowSkeletonWrapper>
        }
      />
    </Card>
  );
}
