import { createEntityAdapter } from "@reduxjs/toolkit";
import type {
  TMarketplaceAgentEntity,
  TMarketplaceEntity
} from "@toolflow/shared";

export const marketplaceEntityAdapter = createEntityAdapter({
  selectId: (marketplaceEntity: TMarketplaceEntity) => marketplaceEntity.id
});

export const marketplaceEntityAdapterInitialState =
  marketplaceEntityAdapter.getInitialState();

export const marketplaceAgentEntityAdapter = createEntityAdapter({
  selectId: (marketplaceEntity: TMarketplaceAgentEntity) => marketplaceEntity.id
});

export const marketplaceAgentEntityAdapterInitialState =
  marketplaceAgentEntityAdapter.getInitialState();
