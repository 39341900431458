import type { TAgentBuilderForm, TCategory } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { AGENT_TAG_CATEGORIES_FIELD_LABEL } from "../../constants/fields";

const useSetAgentCategories = () => {
  const { setValue, register } = useFormContext<TAgentBuilderForm>();
  const setAgentCategories = (categories: TCategory[]) => {
    register(AGENT_TAG_CATEGORIES_FIELD_LABEL);
    setValue(AGENT_TAG_CATEGORIES_FIELD_LABEL, categories, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };
  return setAgentCategories;
};

export default useSetAgentCategories;
