import type { RowUserInputDictType, SheetAsset } from "@toolflow/shared";
import { useEffect } from "react";
import { useAssetContext } from "../../../../../../../../../../utilities/contexts/AssetContext";
import { useWorkspaceAssetsFieldArrayContext } from "../../../../../../../contexts/WorkspaceAssetsContext";
import useGetWorkspaceAssetIndexById from "../../../../../../../assets/useGetWorkspaceAssetIndexById";

const useUpdateFormWithSheetData = ({
  sheetData
}: {
  sheetData: RowUserInputDictType[];
}) => {
  const { asset: input } = useAssetContext<SheetAsset>();
  const getInputIndex = useGetWorkspaceAssetIndexById();
  const { update } = useWorkspaceAssetsFieldArrayContext();
  const updateSheetDataInForm = (t: RowUserInputDictType[]) => {
    const newInput = {
      ...input,
      value: {
        ...input.value,
        sheetData: t
      }
    };
    update(getInputIndex(input.id), newInput);
  };

  useEffect(() => {
    updateSheetDataInForm(sheetData);
  }, [sheetData]);
};

export default useUpdateFormWithSheetData;
