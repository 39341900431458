import { FormControl, Slider } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  type DynamicSliderFieldType
} from "@toolflow/shared";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import DynamicFieldWrapper from "../../dynamicFieldWrapper/DynamicFieldWrapper";

export default function DynamicSliderField() {
  const { field, id, data, updateField } =
    useFieldsByTypeContext<DynamicSliderFieldType>();
  const { config, label, subtitle } = field;
  const {
    fieldKey,
    typeKey,
    marksMax = 100,
    defaultValue = 0,
    filterAvailableFields
  } = config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings: data });

  const dynamicFieldValue = getFieldValue(typeKey, "");
  const fieldValue = getFieldValue(fieldKey, defaultValue);

  return (
    <FormControl margin="normal" fullWidth>
      <DynamicFieldWrapper
        id={id}
        type={dynamicFieldValue}
        label={label}
        subtitle={subtitle}
        configs={config}
        fieldValue={fieldValue}
        filterOptions={filterAvailableFields}
        addInputType={CustomToolInputFieldTypesEnum.TEXTFIELD}
        onTypeChangeCallback={() => updateField(defaultValue, fieldKey)}
      >
        <Slider
          className="nowheel nodrag nopan m-t-16px"
          value={fieldValue}
          onChange={(_, value) => updateField(value, fieldKey)}
          valueLabelDisplay="auto"
          step={marksMax ? null : undefined} // If max marks exist, we want steps to be limited to the marks max array
          scale={(value) => value / 50}
          marks={Array.from({ length: marksMax + 1 }, (_, i) => ({
            value: i
          }))}
        />
      </DynamicFieldWrapper>
    </FormControl>
  );
}
