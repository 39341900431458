import { getUniqueEntity } from "../../entities/functions/getUniqueEntity";
import { useGetCurrentUserAgents } from "../../agents/useGetCurrentUserAgents";
import { useAllMarketplaceAgentEntities } from "../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useGetAvailableAgents = () => {
  const { agents } = useGetCurrentUserAgents();
  const marketplaceEntities = useAllMarketplaceAgentEntities();
  return getUniqueEntity([...marketplaceEntities, ...agents]);
};

export default useGetAvailableAgents;
