import useGetElementHeight from "./assets/asset/textAsset/hooks/useGetElementHeight";
import { useHasSelectedEntity } from "../hooks/useHasSelectedEntity";
import useWorkspaceId from "../hooks/useWorkspaceId";

export const useSavedWorkstationDrawer = () => {
  const { ref, height } = useGetElementHeight();
  const hasSelectedEntity = useHasSelectedEntity();
  const workspaceId = useWorkspaceId();
  return {
    ref,
    height,
    hasSelectedEntity,
    // always show chat if the session has been saved. If the session hasn't been saved,
    // only show if the user hasnt selected a tool.
    // We only want to show the tool in this case
    showChat: workspaceId || (!workspaceId && !hasSelectedEntity)
  };
};
