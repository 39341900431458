import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { Button, IconButton, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import type { ButtonVariantTypes, TSubscriptionParams } from "@toolflow/shared";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";

const SelectSeatsDialogBase = ({
  open,
  closeDialog,
  action
}: {
  open: boolean;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
  action: (params?: TSubscriptionParams) => void;
}) => {
  const [seats, setSeats] = useState(3);
  const handleUpgrade = (e: React.SyntheticEvent<Element, Event>) => {
    action({ seats });
    closeDialog(e);
  };

  const handleClose = (e: React.SyntheticEvent<Element, Event>) => {
    setSeats(3);
    closeDialog(e);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="inherit" className="flex-grow-1" noWrap>
            Upgrade Subscription
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            How many seats do you want to add to your subscription?
          </Typography>
          <TextField
            type="number"
            margin="normal"
            value={seats}
            label="Seats"
            size="small"
            onChange={(e) => {
              const value = parseInt(e.target.value, 10);
              if (!isNaN(value) && value >= 1) {
                setSeats(value);
              }
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            size="small"
            onClick={closeDialog}
            className="m-l-auto"
          >
            Close
          </Button>
          <Button variant="contained" size="small" onClick={handleUpgrade}>
            Upgrade
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const SelectSeatsDialog = ({
  action,
  loading,
  buttonText,
  buttonVariant,
  buttonDisabled
}: {
  action: (params?: TSubscriptionParams) => void;
  loading: boolean;
  buttonText: string;
  buttonVariant: ButtonVariantTypes;
  buttonDisabled: boolean;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <SelectSeatsDialogBase
        open={open}
        closeDialog={() => setOpen(false)}
        action={action}
      />
      <LoadingButton
        onClick={() => setOpen(true)}
        variant={buttonVariant}
        loading={loading}
        disabled={buttonDisabled}
        size="large"
      >
        {buttonText}
      </LoadingButton>
    </>
  );
};

export default SelectSeatsDialog;
