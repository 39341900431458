import React from "react";
import { SnippetIcon } from "../../globalTheme/icons/icons";
import { useDispatch } from "react-redux";
import useGetThreadContentAsMarkdown from "../../utilities/formatters/strings/tiptap/hooks/useGetThreadContentAsMarkdown";
import { openSnippetFormDialog } from "./slice/snippetSlice";
import { toolflowTextDisabledColor } from "../../globalTheme/muiUtils/appTheme";
import RemixWrapperIconWithTheme from "../../utilities/components/icons/RemixWrapperIconWithTheme";
import SettingsMenuItemWithClose from "../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import useGetCurrentAsset from "../pages/workstation/assets/useGetCurrentAsset";

const useAddSnippetFromMenuItem = () => {
  const asset = useGetCurrentAsset();
  const markdownString = useGetThreadContentAsMarkdown(asset?.value ?? "");

  const dispatch = useDispatch();

  const setOpen = () => {
    dispatch(
      openSnippetFormDialog({
        initialName: asset?.name,
        initialValue: markdownString
      })
    );
  };

  return setOpen;
};
const AddSnippetFromMenuItem = () => {
  const setOpen = useAddSnippetFromMenuItem();
  return (
    <SettingsMenuItemWithClose
      text="Add as snippet"
      icon={
        <RemixWrapperIconWithTheme
          size={16}
          Icon={SnippetIcon}
          providedColor={toolflowTextDisabledColor}
        />
      }
      action={setOpen}
    />
  );
};

export default AddSnippetFromMenuItem;
