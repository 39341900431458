import { ClickableTypes } from "@toolflow/shared";
import useRteRef from "../hooks/useRteRef";
import deleteSlash from "./deleteSlash";
import {
  getBlockOutputNodes,
  getInputNodes,
  getSnippetNodes,
  getToolbuilderInputNodes
} from "./getToolNodes";
import { Editor } from "@tiptap/core";

const functionMap = {
  [ClickableTypes.INPUT]: getInputNodes,
  [ClickableTypes.SNIPPET]: getSnippetNodes,
  [ClickableTypes.TOOLBUILDER_INPUT]: getToolbuilderInputNodes,
  [ClickableTypes.NODE_OUTPUT]: getBlockOutputNodes
};

export const handleAddChip =
  (editor?: Editor | null, isSlash?: boolean, callback?: () => void) =>
  (type: ClickableTypes, id: string) => {
    const newContent = functionMap[type](id);

    if (!editor) return;
    // delete the slash when inserting text
    deleteSlash(editor, isSlash);
    editor.commands.insertContentAt(
      editor.view.state.selection.to,
      newContent,
      {
        parseOptions: {
          preserveWhitespace: true
        }
      }
    );
    editor.commands.focus();
    if (callback) {
      callback();
    }
  };

function useHandleAddChipContainer() {
  const editorRef = useRteRef();

  return {
    handleAddChip: handleAddChip(editorRef.current?.editor),
    ref: editorRef
  };
}

export default useHandleAddChipContainer;
