import { Button, TextField } from "@mui/material";
import type { Asset } from "@toolflow/shared";
import React, { useState } from "react";
import useHandleAssetNameChange from "../assets/useHandleAssetNameChange";
import useWorkspaceAssetById from "../assets/useWorkspaceAssetById";

function useUpdateAssetNameForm({
  asset,
  closeDialog
}: {
  asset: Asset;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) {
  const [name, setName] = useState(asset.name);
  const handleNameChange = useHandleAssetNameChange({ asset });

  const handleResetName = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    setName(asset.name);
    closeDialog(e);
  };

  const updateName = (e: React.SyntheticEvent<Element, Event>) => {
    handleNameChange(name);
    closeDialog(e);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  return {
    name,
    handleResetName,
    updateName,
    onChange
  };
}

export function UpdateAssetNameForm({
  asset,
  closeDialog
}: {
  asset: Asset;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) {
  const { name, handleResetName, updateName, onChange } =
    useUpdateAssetNameForm({
      asset,
      closeDialog
    });

  return (
    <>
      <TextField
        fullWidth
        autoFocus
        size="small"
        margin="normal"
        onChange={onChange}
        value={name}
        placeholder="Asset name"
      />
      <div className="flex">
        <Button
          aria-label="edit-name"
          onClick={handleResetName}
          size="small"
          variant="text"
          className="m-l-auto"
          fullWidth={false}
        >
          Cancel
        </Button>
        <Button
          aria-label="edit-name"
          onClick={updateName}
          size="small"
          className="m-l-8px"
          variant="contained"
          fullWidth={false}
        >
          Save
        </Button>
      </div>
    </>
  );
}

export function UpdateAssetNameFormContainer({
  assetId,
  closeDialog
}: {
  assetId: string;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) {
  const asset = useWorkspaceAssetById({ id: assetId });

  if (!asset) return null;

  return <UpdateAssetNameForm asset={asset} closeDialog={closeDialog} />;
}

export default UpdateAssetNameForm;
