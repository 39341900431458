import { DecimalString } from "../../types";

export const sortSemanticVersions = (
  versions: string[],
  ascending?: boolean
): DecimalString[] => {
  return versions.sort((a, b) => {
    const aVersionNumbers = a.split(".").map(Number);
    const bVersionNumbers = b.split(".").map(Number);
    return (
      aVersionNumbers
        .map((aVersion, i) => {
          const bVersion = bVersionNumbers[i] || 0;
          if (ascending) {
            return (aVersion || 0) - bVersion;
          }
          return bVersion - (aVersion || 0);
        })
        .find((diff) => diff !== 0) || 0
    );
  }) as DecimalString[];
};
