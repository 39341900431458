import { AddSnippetFromButton } from "../../../../../features/snippets/SnippetForm/AddSnippetFromContentButton";
import useAutocompleteSnippets from "../../workspace/hooks/useAutocompleteSnippets";
import { EAddChipTab, type TAddChipConfig } from "@toolflow/shared";

// we always want snippets to be used as a chip
const useChipsToUse = (chipsToUse: TAddChipConfig = {}) => {
  const snippets = useAutocompleteSnippets();
  return {
    [EAddChipTab.SNIPPETS]: {
      values: snippets,
      action: <AddSnippetFromButton />
    },
    ...chipsToUse
  };
};

export default useChipsToUse;
