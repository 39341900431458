import { ThemeProvider } from "@mui/material";
import React from "react";
import SendMessageOnDirtyMonitor from "../monitors/SendMessageOnDirtyMonitor";
import WorkspaceFormProvider from "../../state/components/WorkspaceFormProvider";
import workspaceTheme from "../../theme/workspaceTheme";
import WorkspaceHeader from "../workspaceHeader/WorkspaceHeader";

import WorkspaceMainContent from "./WorkspaceMainContent";
import { useGetStarted } from "../getStarted/hooks/useGetStarted";
import AuthenticationSplitter from "../../../../auth/AuthenticationSplitter";
import { LoggedOutGroup } from "../../../external/LoggedOut";
import GetStarted from "../getStarted/GetStarted";
import SavedWorkstationDrawer from "../SavedWorkstationDrawer";

const Workspace = () => {
  const getStarted = useGetStarted();

  if (getStarted) {
    return (
      <>
        <AuthenticationSplitter
          authenticatedComponent={
            <>
              <WorkspaceHeader />
              <WorkspaceMainContent>
                <GetStarted />
              </WorkspaceMainContent>
            </>
          }
          externalComponent={
            <div className="w-100-percent justify-center align-i-center flex h-100vh flex-column">
              <LoggedOutGroup />
            </div>
          }
        />
      </>
    );
  }
  return (
    <>
      <WorkspaceHeader />
      <WorkspaceMainContent>
        <SavedWorkstationDrawer />
      </WorkspaceMainContent>
    </>
  );
};

function NewWorkspacePage() {
  return (
    <ThemeProvider theme={workspaceTheme}>
      <WorkspaceFormProvider>
        <SendMessageOnDirtyMonitor>
          <Workspace />
        </SendMessageOnDirtyMonitor>
      </WorkspaceFormProvider>
    </ThemeProvider>
  );
}

export default NewWorkspacePage;
