import type { TWorkspaceDataToSave, Workspace } from "@toolflow/shared";
import type { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { useEffect } from "react";

// when a session is clicked on the get started page,
// we need to reset the form to the workspace fields
// we have to wait for the workspace to be fetched before resetting
export const useResetFromSessionClick = (
  methods: UseFormReturn<Workspace, $TSAllowedAny, undefined>,
  workspace: TWorkspaceDataToSave,
  isFetching: boolean
) => {
  const allowReset = useSelector(
    (state: RootState) => state.workspace.allowReset
  );

  useEffect(() => {
    if (allowReset && !isFetching) {
      methods.reset(workspace);
    }
  }, [JSON.stringify(workspace), isFetching]);
};
