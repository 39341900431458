import React from "react";
import { SearchTools } from "./SearchTools";
import { useFilteredEntities } from "../../../entities/hooks/useMarketplaceEntities/useFilteredMarketplaceEntities";
import { useSearchToolsContext } from "./context/SearchToolsContext";
import type { TDisabledEntities } from "@toolflow/shared";
import { TToolTab } from "./toolTabsArray";

export const SearchToolsContainer = ({
  tabDetails,
  disabledEntities
}: {
  tabDetails: TToolTab;
  disabledEntities?: TDisabledEntities;
}) => {
  const entities = tabDetails.getEntities();
  const filteredEntities = useFilteredEntities(entities, disabledEntities);
  const { EntityElement, entityType } = useSearchToolsContext();

  return (
    <SearchTools
      entities={filteredEntities}
      EntityElement={EntityElement}
      entityType={entityType}
    />
  );
};
