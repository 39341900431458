import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_WORKFLOW_ACTIVE_STEP_FIELD_LABEL } from "../../../../helpers/workspaceConstants";
import { useFormContext } from "react-hook-form";

const useSetActiveStep = () => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const setActiveStep = (activeStep: number) => {
    setValue(WORKSPACE_WORKFLOW_ACTIVE_STEP_FIELD_LABEL, activeStep, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  };
  return setActiveStep;
};

export default useSetActiveStep;
