import { authenticatedApi } from "../authenticatedAPI";
import { setErrorMessage, setSuccessMessage } from "../../stores/actions";
import { getErrorMessage } from "../errors/getErrorMessage";
import type {
  GetToolAnalyticsResponseFE,
  IGetToolHistoryQueryParams,
  ToolHistoryResponseFE,
  ToolVersionRespResponseFE
} from "@toolflow/shared";
import { getLocationTrackProperties } from "../../utilities/functions/getLocationTrackProperties";
import { trackEventApi } from "./trackEventApi";
import { RootState } from "../../stores/store";
import { useAuthStore } from "../../features/auth/hooks/useAuthStore";

export const toolVersionResponseApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteToolVersionResponse: builder.mutation({
      query: (id: string) => ({
        url: `toolVersionResponse/${id}`,
        method: "DELETE"
      }),
      invalidatesTags: [{ type: "ToolVersionResponse", id: "LIST" }],
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        // // `onStart` side-effect
        // dispatch(messageCreated("Fetching post..."));
        try {
          await queryFulfilled;
          // `onSuccess` side-effect
          dispatch(setSuccessMessage("Deleted tool response"));
        } catch (err) {
          // `onError` side-effect
          dispatch(
            setErrorMessage(
              getErrorMessage(err, "Error deleting tool response")
            )
          );
        }
      }
    }),
    getToolHistory: builder.query<
      ToolHistoryResponseFE,
      IGetToolHistoryQueryParams
    >({
      query: ({ page, limit, toolId }) => ({
        url: `toolHistory?page=${page}&limit=${limit}${
          !!toolId ? `&toolId=${toolId}` : ""
        }`,
        method: "GET"
      })
    }),
    getToolAnalytics: builder.query<GetToolAnalyticsResponseFE, string>({
      query: (id: string) => ({
        url: `/toolAnalytics/${id}`,
        method: "GET"
      })
    }),
    getToolVersionResponse: builder.query<ToolVersionRespResponseFE, string>({
      query: (id) => ({
        url: `toolVersionResponse/${id}`,
        method: "GET"
      }),
      providesTags: (result, error, args) => [
        { type: "ToolVersionResponse", id: args }
      ],
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled;
          const state = getState() as RootState;
          dispatch(
            trackEventApi.endpoints.trackEvent.initiate({
              eventString: "Viewed tool version response",
              properties: {
                ...getLocationTrackProperties(location.pathname),
                logged_in: state.auth.auth0Content.isAuthenticated
              },
              toolVersionResponseId: id
            })
          );
        } catch (e) {
          dispatch(
            setErrorMessage(getErrorMessage(e, "Unable to get tool response"))
          );
        }
      }
    })
  })
});

export const toolVersionResponseApiEndpoints = toolVersionResponseApi.endpoints;

export const {
  useGetToolAnalyticsQuery,
  useGetToolHistoryQuery: useGetToolHistoryQueryInner,
  useDeleteToolVersionResponseMutation,
  useGetToolVersionResponseQuery: useGetToolVersionResponseQueryInner
} = toolVersionResponseApi;

const defaultToolHistory = {
  toolHistory: [],
  page: 1,
  limit: 10,
  hasNextPage: false
};

const useGetToolHistoryQuery = ({
  page,
  limit,
  toolId
}: IGetToolHistoryQueryParams) => {
  const { isLoading, isAuthenticated } = useAuthStore();
  const { data = defaultToolHistory, ...rest } = useGetToolHistoryQueryInner(
    { page, limit, toolId },
    { skip: isLoading || !isAuthenticated }
  );
  return { data, ...rest };
};

const useGetToolVersionResponseQuery = (id: string, skip?: boolean) => {
  const { isLoading } = useAuthStore();
  const { data, ...rest } = useGetToolVersionResponseQueryInner(id, {
    skip: isLoading || skip
  });
  return { data, ...rest };
};

export { useGetToolHistoryQuery, useGetToolVersionResponseQuery };
