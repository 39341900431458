import { SNIPPET_FORM_PATH } from "../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";

const useNavigateToSnippet = (id: string) => {
  const navigate = useToolflowNavigate();

  const navigateToSnippet = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${SNIPPET_FORM_PATH}${id}`, e);
  };

  return navigateToSnippet;
};

export default useNavigateToSnippet;
