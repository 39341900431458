import React from "react";
import SettingsMenuItem from "../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { EditIcon } from "../../../../../globalTheme/icons/icons";
import useNavigateToToolBuilder from "../../../../tools/hooks/useNavigateToToolBuilder";

function NavigateToToolBuilderMenuItem({ toolId }: { toolId: string }) {
  const navigateToToolBuilder = useNavigateToToolBuilder();

  return (
    <SettingsMenuItem
      action={navigateToToolBuilder(toolId)}
      text="Edit tool"
      hotkey="Meta+E"
      hide={!toolId}
      icon={<EditIcon fontSize="small" />}
    />
  );
}

export default NavigateToToolBuilderMenuItem;
