import { useDispatch } from "react-redux";
import { DrawerEnum, setDrawer } from "../toolBuilderSlice";

const useSetNodeDrawer = () => {
  const dispatch = useDispatch();
  const setNodeDrawer = (nodeId: string) => {
    dispatch(setDrawer({ type: DrawerEnum.node, nodeId }));
  };
  return setNodeDrawer;
};

export default useSetNodeDrawer;
