import React, {
  useReducer,
  createContext,
  Dispatch,
  useMemo,
  useContext
} from "react";
import toolBuilderReducer, {
  createToolReducerInitialState,
  Action
} from "./toolBuilderReducer";
import type { ToolReducerState, ReactFlowUtilityType } from "@toolflow/shared";
import ConfirmToolNavigationDialog from "./ConfirmToolNavigationDialog";
import useToolflowBlocker from "./useToolflowBlocker";
import { useGetIntegrationPricesQuery } from "../../../../../ToolflowAPI/rtkRoutes/integrationPriceApi";
import useIsToolDirty from "./helpers/useIsToolDirty";
import useGetReactflowUtilty from "./helpers/useGetReactflowUtility";
import useCloseDrawer from "../store/hooks/useCloseDrawer";

export interface ToolBuilderContextType {
  state: ToolReducerState;
  dispatch: Dispatch<Action>;
  isToolDirty: () => boolean;
  reactflowUtility: ReactFlowUtilityType;
}

const ToolBuilderContext = createContext<ToolBuilderContextType | undefined>(
  undefined
);

export function ToolBuilderContextComponent({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [state, dispatch] = useReducer<React.Reducer<ToolReducerState, Action>>(
    toolBuilderReducer,
    createToolReducerInitialState()
  );

  const { blocks, edges } = state.currentState;
  const { data = { integrationPrices: [] } } = useGetIntegrationPricesQuery();
  const integrationPrices = data.integrationPrices;
  const payload = { integrationPrices };

  const closeDrawer = useCloseDrawer();

  const resetFunction = () => {
    dispatch({ type: "RESET_TOOL" });
    closeDrawer();
  };

  const isToolDirty = useIsToolDirty({
    blocks,
    currentState: state.currentState,
    originalState: state.originalState
  });

  const blocker = useToolflowBlocker(
    isToolDirty,
    state.originalState,
    state.currentState,
    resetFunction
  );

  const reactflowUtility = useGetReactflowUtilty({
    payload,
    blocks,
    edges,
    dispatch
  });

  const value = useMemo(
    () => ({
      state,
      dispatch,
      isToolDirty,
      reactflowUtility
    }),
    [state, dispatch, isToolDirty, reactflowUtility]
  );

  return (
    <ToolBuilderContext.Provider value={value}>
      {children}
      {blocker && <ConfirmToolNavigationDialog blocker={blocker} />}
    </ToolBuilderContext.Provider>
  );
}

export const useToolbuilderContext = (): ToolBuilderContextType => {
  const context = useContext(ToolBuilderContext);

  if (context === undefined) {
    throw new Error(
      "userToolbuilderContext must be used within a ToolbuilderContextType"
    );
  }

  return context;
};

export default ToolBuilderContext;
