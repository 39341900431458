import type { TWorkspaceDataToSave, Workspace } from "@toolflow/shared";
import type { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { useEffect, useState } from "react";
import { DEFAULT_WORKSPACE } from "../helpers/workspaceConstants";

// when a workspace gets reset via external means, we increment the resetKey
// this is to force a rerender of the form to the DEFAULT_WORKSPACE
export const useResetFromSidebar = (
  methods: UseFormReturn<Workspace, $TSAllowedAny, undefined>,
  workspace: TWorkspaceDataToSave,
  isFetching: boolean
) => {
  const resetKey = useSelector((state: RootState) => state.workspace.resetKey);

  const [originalResetKey, setOriginalResetKey] = useState(resetKey);

  useEffect(() => {
    if (originalResetKey && resetKey !== originalResetKey) {
      methods.reset(DEFAULT_WORKSPACE);
    }
    setOriginalResetKey(resetKey);
  }, [resetKey]);

  const allowReset = useSelector(
    (state: RootState) => state.workspace.allowReset
  );

  useEffect(() => {
    if (allowReset && !isFetching) {
      methods.reset(workspace);
    }
  }, [JSON.stringify(workspace), isFetching]);
};
