// import React from "react";
import { ToolAutocomplete } from "../toolMenuTypes";

export enum EAddChipTab {
  ASSETS = "assets",
  SNIPPETS = "snippets",
  NODES = "toolbuilderInputs",
  NODE_OUTPUTS = "nodeOutputs"
}

export type TAddChipConfig = Partial<
  Record<EAddChipTab, { values: ToolAutocomplete[]; action?: React.ReactNode }>
>;

export type TAddChipTab = Record<
  EAddChipTab,
  {
    entities: ToolAutocomplete[];
    type: ClickableTypes;
    action?: React.ReactNode;
  }
>;

export enum ClickableTypes {
  INPUT = "input",
  SNIPPET = "snippet",
  TOOLBUILDER_INPUT = "toolbuilderInput",
  NODE_OUTPUT = "nodeOutput"
}
