import React, { FC } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { IconButton, Typography } from "@mui/material";
import { CloseIcon } from "../../../../globalTheme/icons/icons";
import stopPropagationForTab from "../../../../utilities/functions/keyHandlers/stopPropagationForTab";

interface IRenameDialogProps {
  open: boolean;
  children: React.ReactNode;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
  title: string;
}
const RenameDialog: FC<IRenameDialogProps> = ({
  open,
  closeDialog,
  children,
  title
}) => {
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="sm"
      onKeyDown={stopPropagationForTab}
    >
      <div className="p-b-16px p-t-16px">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="inherit" className="flex-grow-1" noWrap>
            {title}
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
      </div>
    </Dialog>
  );
};

export default RenameDialog;
