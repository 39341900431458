import React from "react";
import { Controller } from "react-hook-form";
import useGetSelectedStepToolIdField from "./hooks/useGetSelectedStepToolIdField";
import WorkflowToolSelectorSplitter from "./WorkflowToolSelectorSplitter";

function ToolSelectorForStep() {
  const fieldLabel = useGetSelectedStepToolIdField();

  return (
    <Controller
      key={fieldLabel}
      name={fieldLabel}
      render={() => <WorkflowToolSelectorSplitter />}
    />
  );
}

export default ToolSelectorForStep;
