import { useState } from "react";
import { useAuthorizedTier } from "../../../../../billing/hooks/useAuthorizedTier";
import { ORGANIZATION_PLAN_FE } from "../../../../../billing/helpers/billingConstants";

export const useCreateAPIKeyButton = () => {
  const [open, setOpen] = useState(false);
  const authorizedTier = useAuthorizedTier();
  const isAuthorized = authorizedTier === ORGANIZATION_PLAN_FE;
  return {
    open,
    setOpen,
    disabled: !isAuthorized
  };
};
