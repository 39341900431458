import React from "react";
import useFormIsDirty from "../../../../utilities/hooks/useIsDirty";
import SaveSnippetButtonBase from "../../../pages/knowledge/uI/SaveSnippetButtonBase";
import useUpdateSnippet from "../../../pages/knowledge/hooks/useUpdateSnippet";

const UpdateSnippetButton = () => {
  const { saveSnippet, loading } = useUpdateSnippet();
  const { isDirty } = useFormIsDirty();
  return (
    <SaveSnippetButtonBase
      disabled={!isDirty}
      loading={loading}
      onClick={saveSnippet}
    />
  );
};

export default UpdateSnippetButton;
