import { Stack } from "@mui/material";
import {
  BlockNode,
  CopyableField,
  type KnowledgeBlockData,
  type KnowledgeBlockProps
} from "@toolflow/shared";
import { useMemo } from "react";
import DrawerBottom from "../../builderDrawer/components/drawerBottom/DrawerBottom";
import { textInputContainerExtensionsConfig } from "../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import TestToolRun from "../../common/TestToolRun";
import TestFieldsWithPrompt from "../components/testWrapper/TestFieldsWithPrompt";
import TestOutput from "../components/testWrapper/TestOutput";
import useTest from "../hooks/useTest";
import useTestInputFromFieldsByType from "../hooks/useTestInputFromFieldsByType";
import usePromptFiller from "../promptBlocks/usePromptFiller";
import useKnowledgeFieldsConfig from "./useKnowledgeFieldsConfig";

function TestKnowledge({ data, id }: Omit<KnowledgeBlockProps, "selected">) {
  const fieldsByType = useKnowledgeFieldsConfig(id);

  const constant = data.settings.query;
  const { fields, userInput, setUserInput } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings]
  );

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<KnowledgeBlockData>({
      data,
      id,
      blockType: BlockNode.KnowledgeNode,
      fields,
      userInput
    });

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(constant, userInput),
    [userInput, constant, insertUserInputIntoPrompt]
  );

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFieldsWithPrompt
            fields={fields}
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
          />
          <TestOutput
            output={output}
            label={label}
            type={CopyableField.Structured}
          />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}

export default TestKnowledge;
