import { Button } from "@mui/material";
import React from "react";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import { AddIcon } from "../../../../../../../../../globalTheme/icons/icons";

function AddInputButton({ className = "" }: { className?: string }) {
  const { addToolInputField } = useToolInputFieldsContext();

  return (
    <Button
      startIcon={<AddIcon size={20} />}
      variant="contained"
      onClick={addToolInputField}
      className={className}
    >
      Add input field
    </Button>
  );
}

export default AddInputButton;
