import { useSelector } from "react-redux";
import { selectAllSnippets } from "../../../../../../ToolflowAPI/rtkRoutes/selectors/snippetsSelectors";
import { usePromptEditorContext } from "../context/PromptEditorContext";

const useHandleKeyDown = () => {
  const {
    contextMenu,
    menuFilteredFields,
    handleToolInputFieldClickWithConfigs,
    handleMenuClick,
    setContextMenu,
    setMenuIndex,
    typedChars,
    menuIndex,
    setOpenInputModal
  } = usePromptEditorContext();
  const snippets = useSelector(selectAllSnippets);
  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => {
    const charAtCursor = e.key;
    if (contextMenu !== null) {
      if (charAtCursor === "Escape") {
        setContextMenu(null);
      } else if (charAtCursor === "Tab" || charAtCursor === "Enter") {
        if (menuFilteredFields.length === menuIndex) {
          setOpenInputModal(true);
        } else if (menuIndex < menuFilteredFields.length) {
          handleToolInputFieldClickWithConfigs(menuFilteredFields[menuIndex]);
        } else {
          handleMenuClick(
            snippets[menuIndex - menuFilteredFields.length - 1].snippetValue
          );
        }
      } else if (charAtCursor === "Backspace" && typedChars === "") {
        setContextMenu(null);
      } else if (charAtCursor === "ArrowUp") {
        setMenuIndex(Math.max(0, menuIndex - 1));
        e.preventDefault();
      } else if (charAtCursor === "ArrowDown") {
        setMenuIndex(
          Math.min(menuFilteredFields.length + snippets.length, menuIndex + 1)
        );
        e.preventDefault();
      } else if (charAtCursor === " " && menuFilteredFields.length == 0) {
        setContextMenu(null);
      }
    }
  };
  return onKeyDown;
};

export default useHandleKeyDown;
