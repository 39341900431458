import {
  BlockNode,
  type CustomToolInputField,
  type PromptBlockData,
  type PromptBlockProps,
  type UserInputDictType
} from "@toolflow/shared";
import { PromptTypeToOutputFieldMap } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../../../common/TestToolRun";
import TestOutput from "../../../components/testWrapper/TestOutput";
import useTest from "../../../hooks/useTest";

const TestPromptBase = ({
  data,
  id,
  userInput,
  fields,
  testFields,
  customFields
}: Omit<PromptBlockProps, "selected"> & {
  userInput: UserInputDictType;
  fields: string[];
  testFields: React.ReactNode;
  customFields?: CustomToolInputField[];
}) => {
  const { type } = data;
  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<PromptBlockData>({
      userInput,
      data,
      customFields,
      id,
      fields,
      blockType: BlockNode.PromptBlockNode
    });

  return (
    <DrawerBottom
      mainContent={
        <>
          {testFields}
          <TestOutput
            output={output}
            label={label}
            type={PromptTypeToOutputFieldMap[type]}
          />
        </>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
};

export default TestPromptBase;
