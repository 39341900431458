import { Button, type ButtonProps } from "@mui/material";
import React from "react";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import { TOOLS_PAGE_ROUTE_PATH } from "../../navigation/helpers/routePaths";

function VisitToolsPageButton({ fullWidth }: ButtonProps) {
  const navigate = useToolflowNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigate(TOOLS_PAGE_ROUTE_PATH, e);
  };

  return (
    <Button onClick={handleClick} fullWidth={fullWidth}>
      Visit the marketplace
    </Button>
  );
}

export default VisitToolsPageButton;
