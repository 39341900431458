import React from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import ToolVersionDropdown from "./ToolVersionDropdown";
import { type FormControlProps } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../layout/alertMessageSnackbar/alertMessageSlice";

const ViewCurrrentVersionAndMakeLive = ({
  selectMargin = "none"
}: {
  selectMargin?: FormControlProps["margin"];
}) => {
  const { state, dispatch } = useToolbuilderContext();
  const reduxDispatch = useDispatch();
  const updateToolVersionId = (newVersion: string) => {
    dispatch({ type: "UPDATE_TOOL_VERSION_ID", toolVersionId: newVersion });
    reduxDispatch(setSuccessMessage("Updated version"));
  };
  return (
    <div className="flex align-i-center">
      <ToolVersionDropdown
        value={state.toolVersionId}
        action={updateToolVersionId}
        updatedLabel={"Current Version"}
        margin={selectMargin}
      />
    </div>
  );
};

export default ViewCurrrentVersionAndMakeLive;
