import { Content, JSONContent } from "@tiptap/core";
import { LeanSnippetDocument, Snippet, UserInputDictType } from "../../types";
import { stringPromptFromUserInput } from "../formatters";
import { ChipNodeComponents } from "../tiptap";

export const getFieldValueForPrompt = (
  node: JSONContent,
  userInput: UserInputDictType,
  snippets: Snippet[] | LeanSnippetDocument[]
): Content | null => {
  const nodeAttributes = node.attrs;
  if (!nodeAttributes) {
    return null;
  }

  if (
    node.type === ChipNodeComponents.ToolbuilderInputComponent ||
    node.type === ChipNodeComponents.NodeOutputViewerComponent
  ) {
    return stringPromptFromUserInput(
      `{{${nodeAttributes.textValue || nodeAttributes.name}}}`, // stringPromptFromUserInput only works with curly braces, so we wrap key value inside {{}}
      userInput
    );
  }
  if (node.type === ChipNodeComponents.SnippetChipComponent) {
    const foundSnippet: Snippet | LeanSnippetDocument | undefined =
      snippets.find(
        (snippet) => snippet._id.toString() === nodeAttributes.snippetId // we need toString to compare object ids
      );
    if (foundSnippet) {
      return foundSnippet.snippetValue;
    }
  }
  return null;
};

export const getPromptFillerBase = (
  cb: (content: JSONContent) => string,
  snippets: Snippet[] | LeanSnippetDocument[]
) => {
  return (prompt: Content, userInput: UserInputDictType): string => {
    if (!prompt) {
      return " ";
    }

    if (typeof prompt === "string") {
      return prompt;
    }

    const replaceChipContent = (node: JSONContent): JSONContent => {
      const field = getFieldValueForPrompt(node, userInput, snippets);
      if (field) {
        if (!field) {
          return {
            type: "text",
            text: " " // No empty strings allowed
          };
        }
        if (typeof field === "string") {
          return {
            type: "text",
            text: field || " " // No empty strings allowed
          };
        }

        return {
          type: "text",
          text: cb(replaceChipContent(field)) || " " // No empty strings allowed
        };
      }
      if (Array.isArray(node.content)) {
        return {
          ...node,
          content: node.content.map(replaceChipContent)
        };
      }
      return node;
    };

    const updatedContent = Array.isArray(prompt)
      ? prompt.map(replaceChipContent)
      : replaceChipContent(prompt);

    return cb(updatedContent);
  };
};
