import React from "react";
import { useToolbuilderContext } from "../context/ToolBuilderContext";
import type { TCategory } from "@toolflow/shared";
import AssignCategoriesNoSaveBase from "../../../../tools/components/editorToolCard/toolCategorization/AssignCategoriesNoSaveBase";
const AssignCategoriesFromToolBuilder = () => {
  const { dispatch, state } = useToolbuilderContext();
  const initialSelectedTags = state.currentState.tag?.categories || [];

  const handleUpdate = (s: TCategory[]) => {
    dispatch({
      type: "SAVE_TOOL_CATEGORIES",
      categories: s
    });
  };

  return (
    <AssignCategoriesNoSaveBase
      handleUpdate={handleUpdate}
      initialSelectedTags={initialSelectedTags}
    />
  );
};

export default AssignCategoriesFromToolBuilder;
