import type { TSetToolOutput, UserInputDictType } from "@toolflow/shared";
import { useEffect, useState } from "react";

const useToolOutputState = (
  toolOutput: UserInputDictType | null,
  setToolOutput: TSetToolOutput
) => {
  const [toolOutputState, setToolOutputState] =
    useState<UserInputDictType | null>(toolOutput);

  // we need to have attributes so that nodes that get deleted get their state back
  // when the editor rerenders if other nodes get deleted.
  // However, updateAttributes doesn't take a function as a parameter
  // and quick changes to state will overwrite the editor.
  // So we store the state in state and then use changes to the state to update the
  // node attributes.
  // timeouts are used to make sure react doesn't have a flushState issue
  useEffect(() => {
    queueMicrotask(() => {
      setToolOutput(toolOutputState);
    });
  }, [toolOutputState]);

  return { toolOutputState, setToolOutputState };
};

export default useToolOutputState;
