import { generateText, type JSONContent } from "@tiptap/core";
import type { UserInputDictType } from "@toolflow/shared";
import { customExtensions } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/customExtensions";
import { getExtensionsFromConfig } from "../../../../../../../../utilities/components/textFields/workspace/hooks/useExtensions/getExtensionsFromConfig";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import GroupAccordion from "../../../../common/fieldsByType/fieldsGroupComponent/GroupAccordion";
import TestInputTab from "../../../promptBlocks/testFields/TestInputTab";

const TestSerpFields = ({
  fields,
  uI,
  setUI
}: {
  fields: string[];
  uI: UserInputDictType;
  setUI: React.Dispatch<React.SetStateAction<UserInputDictType>>;
}) => {
  const extensions = getExtensionsFromConfig(
    textInputContainerExtensionsConfig,
    customExtensions
  );

  const handleUpdate = (field: string, e: string | JSONContent) => {
    setUI({
      ...uI,
      [field]: typeof e === "string" ? e : generateText(e, extensions)
    });
  };

  if (!fields.length) return null;

  return (
    <GroupAccordion
      title="Test fields"
      subtitle="All the available fields are listed below. Enter your desired test values to substitute the placeholders"
      initialValue={true}
    >
      <TestInputTab
        className="p-h-12px m-t-8px p-b-8px"
        fields={fields}
        uI={uI}
        handleUpdate={handleUpdate}
      />
    </GroupAccordion>
  );
};

export default TestSerpFields;
