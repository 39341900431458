import React from "react";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import SettingsDropdownMenuButton from "../../../../../../../utilities/components/dropdowns/generic/SettingsDropdownMenuButton";
import BaseSettingsDropdown from "../../../../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import RemixWrapperIconWithTheme from "../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { MoreIcon } from "../../../../../../../globalTheme/icons/icons";
import RenameAssetMenuItem from "../textAsset/textAssetIcons/RenameAssetMenuItem";

function SheetSettingsDropdown() {
  const menuItems = [<RenameAssetMenuItem key="renameAsset" />];

  return (
    <BaseSettingsDropdown>
      <SettingsDropdownMenuButton
        menuItems={menuItems}
        icon={
          <RemixWrapperIconWithTheme
            Icon={MoreIcon}
            size={16}
            providedColor={toolflowTextDisabledColor}
          />
        }
        MenuButtonProps={{
          tooltipLabel: "More options"
        }}
      />
    </BaseSettingsDropdown>
  );
}

export default SheetSettingsDropdown;
