import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { DrawerEnum } from "../toolBuilderSlice";

const useGetDrawerOutputId = () => {
  const drawer = useSelector((s: RootState) => s.toolbuilder.drawer);
  const outputId = drawer?.type === DrawerEnum.outputs ? drawer?.outputId : "";
  return outputId;
};

export default useGetDrawerOutputId;
