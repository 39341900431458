import {
  FieldType,
  type FieldConfig,
  type IterationStartBlockProps
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import useUpdateField from "../../useUpdateField";
import { filterAvailableFieldsByArray } from "../helpers/filterAvailableFields";

export default function IterationStartBlockInner({
  data,
  id
}: Omit<IterationStartBlockProps, "selected">) {
  const { label } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const updateField = useUpdateField(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        type: FieldType.DynamicListField,
        label: "Choose list",
        subtitle:
          "The Start Iteration node begins a loop over a selected list. Choose the list to iterate through from the dropdown. Subsequent connected nodes will process each item in this list until encountering the Exit Iteration node.",
        config: {
          typeKey: "settings.fieldType",
          fieldKey: "settings.input",
          filterAvailableFields: filterAvailableFieldsByArray
        }
      }
    ]
  };

  return (
    <>
      <FieldsByType configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}
