import {
  WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL,
  WORKFLOW_STEPS_FIELD_LABEL
} from "../../../WorkflowBuilderConstants";
import useGetSelectedStep from "../../hooks/useGetSelectedStep";
import useGetWorkflowInnerFieldById from "../../hooks/useGetWorkflowInnerFieldById";

const useGetSelectedStepInputsToCollectFieldLabel =
  (): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${typeof WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL}` => {
    const step = useGetSelectedStep();
    return useGetWorkflowInnerFieldById(
      step?.id || "",
      WORKFLOW_INPUTS_TO_COLLECT_FIELD_LABEL
    );
  };

export default useGetSelectedStepInputsToCollectFieldLabel;
