import React from "react";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Grid, TextField } from "@mui/material";
import type { TUser } from "@toolflow/shared";
import { get } from "lodash";
import useProfileFieldUpdater from "./hooks/useProfileFieldUpdater";

function UpdateProfileField({
  type,
  successMessage,
  label,
  className
}: {
  type: keyof TUser | ["company", "name"];
  successMessage: string;
  label: string;
  className?: string;
}) {
  const { user, val, setVal, loading, updateType } = useProfileFieldUpdater(
    type,
    successMessage
  );

  return (
    <FormControl margin="normal" fullWidth className={className}>
      <Grid container spacing={2} xs={12} md={6}>
        <Grid item xs={12}>
          <TextField
            label={label}
            value={val}
            size="medium"
            onChange={(e) => setVal(e.target.value)}
            className="m-r-8px"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            size="large"
            variant="contained"
            loading={loading}
            disabled={
              get(user, Array.isArray(type) ? type.join(".") : type) === val
            }
            onClick={updateType}
          >
            Update
          </LoadingButton>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default UpdateProfileField;
