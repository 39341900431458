import {
  type FetchArgs,
  createApi,
  fetchBaseQuery,
  type BaseQueryFn,
  type FetchBaseQueryError
} from "@reduxjs/toolkit/query/react";
import { security } from "./security";
import { RootState } from "../stores/store";
import { TAGS_ARRAY } from "./cacheTagConstants";
import { isLoginOrConsentRequiredError } from "../features/auth/functions/isLoginOrConsentRequiredError";
import { getBackendUrl } from "../utilities/formatters/strings/getBackendUrl";

const API_REDUCER_NAME: TApiReducerName = "api";
type TApiReducerName = "api";

const rawBaseQuery = fetchBaseQuery({
  baseUrl: getBackendUrl(),
  prepareHeaders: async (headers, { getState }) => {
    try {
      const getTokenFunction = security.getAccessTokenSilently();
      const state: RootState = getState() as RootState;
      const { clientId, auth0Content } = state.auth;
      const { isAuthenticated } = auth0Content || {};
      headers.set("x-client-id", clientId);
      if (getTokenFunction && isAuthenticated) {
        const accessToken = await getTokenFunction({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          }
        });
        if (accessToken) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        }
      }

      return headers;
    } catch (error) {
      if (isLoginOrConsentRequiredError(error)) {
        const getLoginFunction = security.getLoginBackToPage();
        if (getLoginFunction) {
          getLoginFunction();
        }
      } else {
        console.error("Error in prepareHeaders", error);
      }
    }
    return headers;
  }
});

// look into https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-retries
// for automatic retries. However this might mess up error handling
const staggeredBaseQueryWithBailOut: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: string | FetchArgs, api, extraOptions) => {
  const state: RootState = api.getState() as RootState;

  const urlEnd = typeof args === "string" ? args : args.url;
  const { isAuthenticated } = state.auth.auth0Content;
  const adjustedUrl = `${isAuthenticated ? "" : "external/"}${urlEnd}`;
  const adjustedArgs =
    typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };

  const result = await rawBaseQuery(adjustedArgs, api, extraOptions);

  return result;
};

export const authenticatedApi = createApi({
  reducerPath: API_REDUCER_NAME,
  tagTypes: TAGS_ARRAY,
  invalidationBehavior: "immediately", // https://redux-toolkit.js.org/usage/migrating-rtk-2#rtk-query-behaviour-changes
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: () => ({})
});
