import React from "react";
import Box from "@mui/material/Box";
import { FormHelperText } from "@mui/material";
import DragToolInputMonitor from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/DragToolInputMonitor";
import DraggableToolInputField from "./DeprecatedDraggableToolInputField";
import { useToolInputFieldsContext } from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/contexts/ToolInputFieldsContext";
import AddInputButton from "../../../../../pages/builder/toolBuilder/builderDrawer/components/drawerContent/inputs/inputBuilders/AddInputButton";

const DeprecatedCustomToolInputFields = ({
  className = ""
}: {
  className?: string;
}) => {
  const { toolInputFields } = useToolInputFieldsContext();
  return (
    <Box className={className}>
      <FormHelperText>Drag input to reorganize</FormHelperText>
      <DragToolInputMonitor>
        {toolInputFields.map((toolInputField, index) => {
          return (
            <DraggableToolInputField
              key={toolInputField.id}
              index={index}
              toolInputField={toolInputField}
            />
          );
        })}
      </DragToolInputMonitor>
      <div className="flex justify-flex-end">
        <AddInputButton />
      </div>
    </Box>
  );
};

export default DeprecatedCustomToolInputFields;
