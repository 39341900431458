import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useToolflowNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = useCallback(() => {
    navigate(-1);
  }, []);
  return { navigateBack };
};

export default useToolflowNavigateBack;
