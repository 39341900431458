import React from "react";
import { CardContent, Container, IconButton, Typography } from "@mui/material";
import { CloseIcon, ToolIcon } from "../../../globalTheme/icons/icons";
import { DrawerHeader } from "../../pages/builder/toolBuilder/builderDrawer/components/drawerHeader/DrawerHeaderContainer";

export function MoreInfoCard({
  name,
  children,
  closeDrawer
}: Readonly<{
  name: string;
  children?: React.ReactNode;
  closeDrawer: () => void;
}>) {
  return (
    <>
      <DrawerHeader
        title={<Typography variant="h8">{name}</Typography>}
        BlockIcon={<ToolIcon />}
        rightSlot={
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Container maxWidth={"md"} disableGutters>
        <CardContent className="scrollable-content h-100-percent">
          {children}
        </CardContent>
      </Container>
    </>
  );
}
