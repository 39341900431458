import { BlockNode } from "@toolflow/shared";
import graphlib from "graphlib";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import type { Connection, Edge, OnConnect } from "reactflow";
import { setErrorMessage } from "../../../../../../stores/actions";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";

const getId = (sourceNode: string, targetNode: string) => {
  return `e${sourceNode}->${targetNode}`;
};

const useOnConnect = () => {
  const { reactflowUtility, state } = useToolbuilderContext();
  const reduxDispatch = useDispatch();
  const { setEdges } = reactflowUtility;
  const { blocks } = state.currentState;

  function isIterationNode(id: string) {
    return blocks.some(
      (block) =>
        block.id === id && block.type === BlockNode.IterationStartBlockNode
    );
  }

  function isPartOfIteration(target: string, edges: Edge[]) {
    // find which node has this target
    const foundEdge = edges.find((edge) => edge.target === target);
    // if not found, it means it has no parents, so return false
    if (!foundEdge) return false;
    // else check if source is IterationNode
    // if so, it means target block is a part of iteration and so return true
    if (isIterationNode(foundEdge.source)) return true;
    // else return a call to itself with source as next target for upper level checking
    return isPartOfIteration(foundEdge.source, edges);
  }

  // Trying to limit the functions in context for now
  // maybe should add this to context later
  const addEdge = (params: Connection | Edge, eds: Edge[]) => {
    const sourceNode = params.source;
    const targetNode = params.target;

    if (!sourceNode || !targetNode) {
      return eds;
    }

    if (
      (isIterationNode(sourceNode) || isPartOfIteration(sourceNode, eds)) &&
      isIterationNode(targetNode)
    ) {
      reduxDispatch(
        setErrorMessage(
          "An iteration block cannot be connected to another iteration"
        )
      );
      // Edge would create a cycle, do not add it
      return eds; // return the existing edges without adding the new one
    }

    const graphToUse = new graphlib.Graph({ directed: true });
    eds.forEach((edge) => {
      graphToUse.setEdge(edge.source, edge.target);
    });

    if (graphToUse.hasEdge(sourceNode, targetNode)) {
      return eds;
    }

    if (isPartOfIteration(targetNode, eds)) {
      reduxDispatch(
        setErrorMessage(
          "A block cannot be a part of multiple iterations. Please remove it from other iteration before making this connection."
        )
      );
      // Edge would create a cycle, do not add it
      return eds; // return the existing edges without adding the new one
    }

    graphToUse.setEdge(sourceNode, targetNode);

    // Use the Graph utility to check if adding the edge would create a cycle
    // if it does, we remove the edge and return the existing edges
    if (!graphlib.alg.isAcyclic(graphToUse)) {
      graphToUse.removeEdge(sourceNode, targetNode);
      reduxDispatch(
        setErrorMessage(
          "Creating this connection would cause a cycle in your diagram. Please try again."
        )
      );
      // Edge would create a cycle, do not add it
      return eds; // return the existing edges without adding the new one
    }

    // The edge is valid and does not exist, add it to the existing edges
    const newEdge = {
      id: getId(sourceNode, targetNode), // must include -> for updateAvailableFields
      source: sourceNode,
      target: targetNode,
      data: { deletable: true }
    };
    return eds.concat(newEdge);
  };

  const onConnect: OnConnect = useCallback(
    (connection: Connection) => {
      setEdges((eds) => {
        const newEdges = addEdge(connection, eds as Edge[]);

        return newEdges;
      });
    },
    [setEdges, addEdge] // eslint-disable-line react-hooks/exhaustive-deps
  );
  return onConnect;
};

export default useOnConnect;
