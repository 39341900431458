import React from "react";

import {
  useGetToolQuery,
  useUpdateToolMutation
} from "../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { v4 } from "uuid";
import { EntityType, type TCategory } from "@toolflow/shared";
import AssignCategories from "./AssignCategories";

const AssignCategoriesToolContainer = ({
  toolId,
  className = ""
}: {
  toolId: string;
  className?: string;
}) => {
  const { data, isLoading } = useGetToolQuery(toolId, {
    refetchOnMountOrArgChange: true,
    skip: !toolId
  });
  const [updateTool] = useUpdateToolMutation();
  const fetchedCategoriesTags =
    data?.tool?.tag?.categories?.map((category) => ({
      id: v4(),
      selected: true,
      value: category
    })) || [];

  const handleSaveTool = (categories: TCategory[]) => {
    updateTool({
      toolId,
      body: { tag: { categories } }
    });
  };

  return (
    <AssignCategories
      disabled={isLoading}
      handleSave={handleSaveTool}
      fetchedCategoriesTags={fetchedCategoriesTags}
      type={EntityType.TOOL}
      className={className}
    />
  );
};

export default AssignCategoriesToolContainer;
