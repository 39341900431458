import type {
  CustomToolInputField,
  CustomToolInputFieldTypes,
  InputTypeMap,
  ValidatedInput,
  ValidatedInputTypes
} from "@toolflow/shared";
import {
  commonFieldTypes,
  isInputWithOptions
} from "../../../tools/components/editorToolCard/inputs/helpers/constants";
import {
  BLOCK_OUTPUT,
  CHECKBOX,
  FILE_UPLOAD
} from "../../../tools/components/editorToolCard/inputs/helpers/inputConstants";

const genericEquality = (
  field: ValidatedInput,
  fieldType?: ValidatedInputTypes
) => {
  return field.type.toLowerCase() === fieldType?.toLowerCase();
};

const textFieldEquality = (field: ValidatedInput) => {
  return ["textfield", "largetextfield", "blockoutput"].includes(
    field.type.toLowerCase()
  );
};

const lookupDict: {
  [key in ValidatedInputTypes]: (
    f: ValidatedInput,
    fT?: ValidatedInputTypes
  ) => boolean;
} = {
  select: genericEquality,
  checkbox: genericEquality,
  csvFileUpload: genericEquality,
  fileUpload: genericEquality,
  multiSelect: genericEquality,
  tagsInput: genericEquality,
  doubleTextfield: genericEquality,
  textField: textFieldEquality,
  largeTextField: textFieldEquality,
  blockOutput: textFieldEquality
};

const getCommonAvailableOptions = (
  fieldType: CustomToolInputFieldTypes,
  availableOptions: ValidatedInput[]
) => {
  return availableOptions
    .filter((field) => {
      return lookupDict[fieldType](field, fieldType);
    })
    .map((field) => {
      return { name: field.name, type: field.type };
    });
};

function createInputTypeMap(
  inputTypeMap: { [key: string]: CustomToolInputField },
  allAvailableOptions: ValidatedInput[]
): InputTypeMap {
  const result: InputTypeMap = {};
  // Create a set of names from availableOptions for faster lookup
  const fieldNamesSet = new Set(allAvailableOptions.map((field) => field.name));

  for (const key in inputTypeMap) {
    const input = inputTypeMap[key];
    const fieldType = input.type;
    if (commonFieldTypes.includes(fieldType)) {
      const availableOptions = getCommonAvailableOptions(
        fieldType,
        allAvailableOptions
      );
      result[key] = {
        availableOptions,
        type: fieldType
      };
    } else {
      const otherNames = allAvailableOptions
        .filter(
          (field) =>
            !([FILE_UPLOAD, CHECKBOX] as ValidatedInputTypes[]).includes(
              field.type
            )
        )
        .map((field) => {
          return {
            name: field.name,
            type: field.type,
            options: isInputWithOptions(field) ? field.options : undefined
          };
        });

      // Add availableOptions strings that do not exist in the availableOptions
      // this only works because all the block outputs that we allow to go into
      // this block are strings
      const additionalNames: ValidatedInput[] = allAvailableOptions
        .filter((option) => !fieldNamesSet.has(option.name))
        .map((f) => {
          return { name: f.name, type: BLOCK_OUTPUT };
        });

      result[key] = {
        availableOptions: [...otherNames, ...additionalNames],
        type: input.type,
        dropdownOptions: isInputWithOptions(input) ? input.options : undefined
      };
    }
  }

  return result;
}

export default createInputTypeMap;
