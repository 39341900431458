import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useSaveAndClose from "./useSaveAndClose";
import { openWorkflowSaveDialog } from "../../workflowBuilderSlice";

const useHandleSaveWorkflowButtonClick = () => {
  const dispatch = useDispatch();
  const { workflowId } = useParams();
  const { saveAndClose, loading } = useSaveAndClose();
  const handleSave = () => {
    // if the workflow is already saved, we don't need to clarify with an open dialog
    if (workflowId) {
      saveAndClose();
    } else {
      dispatch(openWorkflowSaveDialog());
    }
  };

  return { handleSave, loading };
};

export default useHandleSaveWorkflowButtonClick;
