import React from "react";
import type { ExternalToolFE } from "@toolflow/shared";
import { useSettingsDropdownContext } from "../../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import SettingsMenuItem from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { updateToolInInfoDrawer } from "../../../../../tools/components/toolInfoDrawer/toolInfoDrawerSlice";
import { useDispatch } from "react-redux";
import { InfoIcon } from "../../../../../../globalTheme/icons/icons";

function MoreToolInfoMenuItem({ tool }: Readonly<{ tool: ExternalToolFE }>) {
  const { handleClose } = useSettingsDropdownContext();
  const dispatch = useDispatch();
  const setTool = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(updateToolInInfoDrawer(tool));

    handleClose(e);
  };

  return (
    <SettingsMenuItem
      text="More info"
      hotkey="Meta+I"
      icon={<InfoIcon fontSize="small" />}
      action={setTool}
    />
  );
}

export default MoreToolInfoMenuItem;
