import type { TBlock } from "@toolflow/shared";
import { useCallback } from "react";

const useOnDeleteNode = ({
  blocks,
  setBlocksToDelete,
  setIsConfirmationDialogOpen
}: {
  blocks: TBlock[];
  setBlocksToDelete: React.Dispatch<React.SetStateAction<TBlock[]>>;
  setIsConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // Define what happens when the block's delete button is clicked
  return useCallback(
    (id: string) => {
      const deletedNode = blocks.find((block) => block.id === id);
      if (deletedNode) {
        setBlocksToDelete([deletedNode]);
        setIsConfirmationDialogOpen(true);
      }
    },
    [blocks]
  );
};

export default useOnDeleteNode;
