import { FC } from "react";
import { GenericRichContent } from "../../../../../../utilities/components/textFields/workspace/GenericRichContent";
import styles from "./agentMessage.module.css";
import threadStyles from "./threadMessage.module.css";
import ThreadActions from "./threadActions/ThreadActions";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";
import type { TTransformedMessage } from "@toolflow/shared";
import StepDetails from "./StepDetails";
interface IAgentMessageProps {
  message: TTransformedMessage;
}

const cx = parsedClassNames.bind(threadStyles);

const AgentMessage: FC<IAgentMessageProps> = (props) => {
  const { message } = props;
  const { content, settings } = message;
  return (
    <div className="w-100-percent m-t-20px">
      <StepDetails settings={settings} />
      <div className="w-100-percent pos-relative parent-hover">
        <GenericRichContent
          content={content}
          className={styles["agent-message"]}
          rerender={true}
          key="agent-message"
        />
        <ThreadActions
          content={content}
          className={cx(
            "m-t-8px pos-absolute show-on-hover",
            threadStyles["thread-actions"]
          )}
        />
      </div>
    </div>
  );
};

export default AgentMessage;
