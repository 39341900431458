import { useEffect, useRef } from "react";
import useGetCurrentChatThreadId from "../../hooks/useGetCurrentChatThreadId";

const useThreadRef = () => {
  const currentThreadId = useGetCurrentChatThreadId();
  const currentThreadIdRef = useRef<string | null>(currentThreadId);
  useEffect(() => {
    currentThreadIdRef.current = currentThreadId;
  }, [currentThreadId]);
  return currentThreadIdRef;
};

export default useThreadRef;
