import { IconButton } from "@mui/material";
import React from "react";
import { toolflowTextDisabledColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon
} from "../../../../../../../../globalTheme/icons/icons";

const OpenAssetGroupButton = ({
  onClick,
  open
}: {
  open: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton onClick={onClick} className="asset-button">
      <RemixWrapperIconWithTheme
        Icon={open ? ArrowUpSmallIcon : ArrowDownSmallIcon}
        size={16}
        providedColor={toolflowTextDisabledColor}
      />
    </IconButton>
  );
};

export default OpenAssetGroupButton;
