import { Typography } from "@mui/material";
import React from "react";

function PageHeaderTypography({ title }: { title: string }) {
  return (
    <Typography className="m-16px" variant="h5">
      {title}
    </Typography>
  );
}

export default PageHeaderTypography;
