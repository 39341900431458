import { Container } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import React from "react";
import { parsedClassNames } from "../../../../../utilities/functions/parsedClassNames";
import Asset from "../assets/asset/Asset";
import styles from "./workspaceMainContent.module.css";
import useGetAssetId from "../../assets/useGetAssetId";

const cx = parsedClassNames.bind(styles);

const WorkspaceMainContent = ({ children }: { children: React.ReactNode }) => {
  const assetId = useGetAssetId();
  return (
    <Grid2
      container
      xs={12}
      spacing={"16px"}
      disableEqualOverflow
      sx={{
        minWidth: { xs: "100%", md: 900 }
      }}
      className={cx(
        "oflow-y-auto oflow-x-auto h-100-percent ",
        styles["grid-wrapper"]
      )}
    >
      <Grid2
        xs={assetId ? 6 : 12}
        lg={assetId ? 5 : 12}
        xl={assetId ? 4 : 12}
        className={cx(styles["grid-transition"], "h-100-percent")}
      >
        <Container
          className="h-100-percent max-h-100-percent p-0px"
          maxWidth="md"
        >
          {children}
        </Container>
      </Grid2>
      <Grid2
        xs={assetId ? 6 : 0}
        lg={assetId ? 7 : 0}
        xl={assetId ? 8 : 0}
        className={cx(styles["grid-transition"], "h-100-percent", {
          hidden: !assetId
        })}
      >
        <Asset />
      </Grid2>
    </Grid2>
  );
};

export default WorkspaceMainContent;
