import {
  Block,
  type VersionedBlockConfig,
  type BlockDataTypesWithDefault,
  type DecimalString,
  type DraggableItem,
  BlockType
} from "@toolflow/shared";
import {
  blockConfig,
  getBlockConfig,
  getLatestBlockConfig
} from "../blockConfig";

const createBlockDict = <T extends React.ElementType | string | DraggableItem>(
  valueKey: keyof VersionedBlockConfig[string],
  version?: DecimalString,
  getLatestVersion?: boolean,
  additionalEntries: Record<string, T> = {}
): Record<BlockDataTypesWithDefault["type"], T> => {
  const tempBlockDict = {
    ...Object.fromEntries(
      Object.entries(blockConfig).map(([blockKey]) => {
        const config = getLatestVersion
          ? getLatestBlockConfig(blockKey as Block)
          : getBlockConfig(blockKey as Block, version);
        return [config.draggableItem.data.type, config[valueKey]];
      })
    ),
    ...additionalEntries
  } as Record<BlockDataTypesWithDefault["type"], T>;

  // Anthropic and ChatGPT have the same settings
  // This is an anachronism that should be fixed
  // For now, we allow people to add additional entries to the block dict where
  // ChatGPT and Anthropic are different (see getBlockHeaderIcon)
  // Most of the time, Anthropic and ChatGPT are the same
  // so add the ChatGPT settings to the Anthropic settings
  if (!tempBlockDict[BlockType.Anthropic]) {
    tempBlockDict[BlockType.Anthropic] = tempBlockDict[BlockType.ChatGPT];
  }

  return tempBlockDict;
};

export default createBlockDict;
