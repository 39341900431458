import {
  FieldType,
  type FieldConfig,
  type PerplexityBlockProps
} from "@toolflow/shared";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import { getPerplexityModelOptions } from "../../../../helpers/priceDict";
import useSyncCurrentAndPrevSettings from "../../../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../../../useUpdateField";

function PerplexityBlockInnerV1({
  data,
  id
}: Omit<PerplexityBlockProps, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  const updateField = useUpdateField(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        type: FieldType.NewSelectInput,
        label: "Model",
        subtitle: "Select text generation technology",
        config: {
          fieldKey: "optimizations.llmModel",
          options: getPerplexityModelOptions()
        }
      },
      {
        type: FieldType.PromptInput,
        label: "Prompt",
        config: {
          fieldKey: "settings.input",
          maxRows: MAX_ROWS_LARGE_TEXTFIELD,
          minRows: MAX_ROWS_LARGE_TEXTFIELD
        }
      },
      {
        type: FieldType.Slider,
        label: "Temperature",
        subtitle:
          "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
        config: {
          fieldKey: "optimizations.temperature",
          className: "m-v-16px"
        }
      }
    ]
  };

  return (
    <>
      <FieldsByType configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default PerplexityBlockInnerV1;
