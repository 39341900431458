import FormControl from "@mui/material/FormControl";
import type { FormControlProps } from "@mui/material/FormControl";
import { Switch } from "../../../../features/pages/builder/toolBuilder/builderDrawer/components/drawerContent/outputs/outputFields/Switch";

export function UpdateEnableForkingBase({
  noMargin,
  formProps,
  handleEnableForking,
  enableForking
}: {
  noMargin?: boolean;
  formProps?: FormControlProps;
  disabled?: boolean;
  handleEnableForking: (value: boolean) => void;
  enableForking: boolean;
}) {
  return (
    <FormControl
      margin={noMargin ? "none" : "normal"}
      size="small"
      {...formProps}
    >
      <Switch
        checked={enableForking}
        label={"Enable users to copy template"}
        onChange={handleEnableForking}
      />
    </FormControl>
  );
}
