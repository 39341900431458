import type { TWorkflowBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { ENABLE_FORKING_FIELD_LABEL } from "../WorkflowBuilderConstants";

function useGetWorkflowEnableForking() {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return watch(ENABLE_FORKING_FIELD_LABEL);
}

export default useGetWorkflowEnableForking;
