import { Divider } from "@mui/material";
import React from "react";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import Dots from "./Dots";
import MakeAssetListBigButton from "./MakeAssetListBigButton";
import MoveDownAnAssetButton from "./MoveDownAnAssetButton";
import MoveUpAnAssetButton from "./MoveUpAnAssetButton";
import styles from "./smallAssetTOC.module.css";

const cx = parsedClassNames.bind(styles);

function SmallAssetTOC() {
  return (
    <div
      className={
        "flex flex-column justify-space-between align-i-flex-end h-100-percent max-h-100-percent"
      }
    >
      <div
        className={cx(
          styles["small-asset-button-group"],
          "flex align-i-center flex-column asset-toc-box-shadow"
        )}
      >
        <MakeAssetListBigButton />
        <Divider orientation="horizontal" className="m-v-16px" flexItem />
        <MoveUpAnAssetButton />
        <MoveDownAnAssetButton />
      </div>
      <Dots />
      <div />
    </div>
  );
}

export default SmallAssetTOC;
