import React from "react";
import { Button } from "@mui/material";
import type { ButtonSizeTypes, ButtonVariantTypes } from "@toolflow/shared";
import { useAuth0 } from "@auth0/auth0-react";

function CreateAFreeAccount({
  variant,
  size,
  text = "Create account",
  className = ""
}: {
  variant?: ButtonVariantTypes;
  size?: ButtonSizeTypes;
  text?: string;
  className?: string;
}) {
  const { loginWithRedirect } = useAuth0();

  const handleSignup = () => {
    loginWithRedirect({
      authorizationParams: { screen_hint: "signup" },
      appState: {
        returnTo: window.location.pathname
      }
    });
  };

  return (
    <Button
      variant={variant}
      size={size}
      className={className}
      onClick={handleSignup}
    >
      {text}
    </Button>
  );
}

export default CreateAFreeAccount;
