import {
  isArray,
  isBoolean,
  isFunction,
  isNull,
  isNumber,
  isObject,
  isString
} from "lodash";

export const isSingleLineValue = (val: $TSAllowedAny) =>
  isString(val) || isNumber(val) || isBoolean(val);

export const isArrayOfSingleValues = (val: $TSAllowedAny) =>
  isArray(val) && val.every((item) => isSingleLineValue(item));

export const isObjectType = (val: $TSAllowedAny) =>
  isObject(val) && !isArray(val) && !isNull(val) && !isFunction(val);

export const isArrayOfObjects = (val: $TSAllowedAny) =>
  isArray(val) && val.every((item) => isObjectType(item));
