import { useState, useRef, useEffect } from "react";

const useShouldWrap = (breakpoint = 600) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [shouldWrap, setShouldWrap] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        setShouldWrap(ref.current.offsetWidth >= breakpoint);
      }
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [breakpoint]);

  return [shouldWrap, ref] as const;
};

export default useShouldWrap;
