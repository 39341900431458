import { useMemo } from "react";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import formatAssetsForAutocomplete from "../../../autocomplete/functions/formatInputsForAutocomplete";
import type { AssetNodeTypes } from "@toolflow/shared";
import useWorkspaceAssets from "../../../../../features/pages/workstation/assets/useWorkspaceAssets";

export const useFilteredAssetsForAutocompleteBase = ({
  assetNodeType = "node",
  id
}: {
  assetNodeType?: AssetNodeTypes;
  id?: string;
}) => {
  const inputs = useWorkspaceAssets();
  return useMemo(() => {
    return formatAssetsForAutocomplete(inputs, assetNodeType, id);
  }, [inputs]);
};

const useFilteredAssetsForAutocomplete = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { assetNodeType, id } = muiTipTapProps;
  return useFilteredAssetsForAutocompleteBase({
    assetNodeType,
    id
  });
};

export default useFilteredAssetsForAutocomplete;
