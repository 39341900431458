import { useEffect } from "react";
import { useSheetContext } from "../../SheetContext";
import { TMaterialTable } from "../../sheetTypes";

const useUpdateTableInstanceRef = (materialTable: TMaterialTable) => {
  const { tableInstanceRef } = useSheetContext();
  useEffect(() => {
    tableInstanceRef.current = materialTable;
  }, [materialTable]);
};

export default useUpdateTableInstanceRef;
