import React, { ElementType } from "react";
import { Typography } from "@mui/material";
import { toolflowPrimaryColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import DrawerHeaderContainer from "../../drawerHeader/DrawerHeaderContainer";
import { InputType } from "../../../../inputsOutputs/InputOutputBase";
import getEntitiesText from "../../../../inputsOutputs/helpers/getEntitiesText";

function InputOutputBase({
  Component,
  type,
  entityCount,
  content
}: {
  Component: ElementType;
  type: InputType;
  entityCount: number;
  content: React.ReactNode;
}) {
  const text = getEntitiesText(type, entityCount);

  return (
    <>
      <div>
        <DrawerHeaderContainer
          BlockIcon={<Component color={toolflowPrimaryColor} />}
          blockIconClassName="bg-color-secondary-color"
          title={<Typography variant="h8">{text}</Typography>}
        />
      </div>
      {content}
    </>
  );
}

export default InputOutputBase;
