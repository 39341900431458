import { Document } from "mongoose";
import { JSONContent } from "@tiptap/core";
import { UserSchemaId, WorkspaceSchemaId } from "../schemaTypes";
import {
  $TSFixMe,
  DallEOutputType,
  EntityType,
  JSONValue,
  RowUserInputDictType,
  UserInputDictType
} from "../types";
import { SanitizedUserType } from "./UserDocument";
import { LeanDocument, VisibilityTypes } from "./utilities";

export type SheetAssetValue = {
  sheetData: RowUserInputDictType[];
  selectedToolId: string;
};

export type AssetValue = string | DallEOutputType | JSONValue | SheetAssetValue;

// dallE2 should be migrated to generic dallE at some point
export enum AssetValueType {
  String = "string",
  DallE = "dallE2",
  Structured = "structured",
  Sheet = "sheet"
}

export enum AssetSourceType {
  Manual = "manual",
  ToolOutput = "toolOutput",
  ToolOutputPlaceholder = "toolOutputPlaceholder"
}

export type StringAsset = {
  value: string | JSONContent;
  valueType: AssetValueType.String;
} & AssetBase;

export type TDallEAsset = {
  value: DallEOutputType;
  valueType: AssetValueType.DallE;
} & AssetBase;

export type TStructuredAsset = {
  value: $TSFixMe;
  valueType: AssetValueType.Structured;
} & AssetBase;

export type SheetAsset = {
  value: SheetAssetValue;
  valueType: AssetValueType.Sheet;
} & AssetBase;

export type ToolAsset = StringAsset | TDallEAsset | TStructuredAsset;

export type Asset = ToolAsset | SheetAsset;

export type AssetBase = {
  id: string;
  name: string;
  label: string;
  source: AssetSourceType;
  sourceIdentifier?: string;
  loading?: boolean;
  valueType?: AssetValueType;
  data?: {
    version?: number;
    toolId?: string;
    tvrId?: string;
    creatorId?: string;
    userInput?: UserInputDictType;
    outputId?: string;
    toolCount?: number;
  };
};

export type UpdateWorkspaceDataToSend = {
  name?: string;
  visibility?: VisibilityTypes;
  about?: string;
};

export type ContentType = $TSFixMe;

export type TWorkflowProgress = {
  inputs: UserInputDictType;
  outputs: { [key: string]: string };
};

export type TWorkflowProgressDict = {
  [key: string]: TWorkflowProgress;
};

export type TUseWorkflow = {
  activeStep: number;
  workflowProgress: TWorkflowProgressDict;
};

export type TChatTab = {
  currentThreadId: string;
  agentId?: string;
};

export type TWorkspaceDataToSave = {
  _id: string;
  name: string;
  about: string;

  visibility: VisibilityTypes;
  workflow: TUseWorkflow;

  inputs: Asset[];
  visibleAssetId: string;
  visibleSubAssetId: string;
  chatTab: TChatTab;
  toolTab: {
    userInput: UserInputDictType;
  };
  entity: {
    entityId: string;
    componentId: string;
    entityType: EntityType | "";
  };
};

export type StreamlinedWorkspace = {
  _id: string;
  name: string;
  about: string;
  visibility: VisibilityTypes;
  updatedAt: number;
  createdAt: number;
};

export type Workspace = {
  creator: SanitizedUserType;
  userId: string;
  isDeleted: boolean;
  updatedAt: number;
  createdAt: number;
} & TWorkspaceDataToSave;

export type PopulatedWorkspace = Omit<Workspace, "userId" | "_id"> &
  Document & {
    userId: UserSchemaId;
    _id: WorkspaceSchemaId;
  };

export type TWorkspacePartial = Workspace | Partial<TWorkspaceDataToSave>;

export type WorkspaceDocument = Omit<PopulatedWorkspace, "creator"> & Document;
export type LeanPopulatedWorkspaceDocument = LeanDocument<PopulatedWorkspace>;
export type LeanWorkspaceDocument = LeanDocument<WorkspaceDocument>;
