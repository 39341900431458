import React from "react";
import AssignCategoriesNoSaveBase from "../../../../tools/components/editorToolCard/toolCategorization/AssignCategoriesNoSaveBase";
import useGetAgentCategories from "../state/hooks/useGetAgentCategories";
import useSetAgentCategories from "../state/hooks/useSetAgentCategories";

const AssignCategoriesFromAgentBuilder = () => {
  const initialSelectedTags = useGetAgentCategories();
  const setAgentCategories = useSetAgentCategories();

  return (
    <AssignCategoriesNoSaveBase
      handleUpdate={setAgentCategories}
      initialSelectedTags={initialSelectedTags}
    />
  );
};

export default AssignCategoriesFromAgentBuilder;
