import React from "react";
import { useParams } from "react-router-dom";
import SaveSnippetButton from "./SaveSnippetButton";
import UpdateSnippetButton from "./UpdateSnippetButton";

function SaveOrUpdateSnippetButton() {
  const { snippetId = "" } = useParams();
  return !!snippetId ? <UpdateSnippetButton /> : <SaveSnippetButton />;
}

export default SaveOrUpdateSnippetButton;
