import { useDispatch } from "react-redux";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import useUpdatePercentCompleted from "./useUpdatePercentCompleted";
import { setDisableTabChange } from "../../../../pages/workstation/state/workspaceSlice";

const useSetToolStillLoading = () => {
  const { setToolOutputState } = useEditorToolCardContext();
  const updatePercentCompleted = useUpdatePercentCompleted();
  const dispatch = useDispatch();
  const setToolStillLoading = () => {
    updatePercentCompleted(0);
    setToolOutputState({});
    dispatch(setDisableTabChange(true));
  };
  return setToolStillLoading;
};

export default useSetToolStillLoading;
