import React from "react";
import { SearchIcon } from "../../../../../../../globalTheme/icons/icons";
import useFindToolButton from "./hooks/useFindToolButton";
import TextButtonGray from "./textButtonGray/textButtonGray";
import {
  SearchEntitiesDialog,
  SearchEntitiesDialogType
} from "../../../../../../tools/components/searchEntitiesDialog/SearchEntitiesDialog";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { EFeatureFlags } from "@toolflow/shared";

function FindAgentButton({
  className = "",
  name = "Find agent"
}: {
  className?: string;
  name?: string;
}) {
  const { selectTool } = useFindToolButton();
  const addAgentEnabled = useFeatureFlagEnabled(EFeatureFlags.AGENT_BUILDER);

  if (!addAgentEnabled) {
    return null;
  }
  return (
    <SearchEntitiesDialog
      onClickEntity={selectTool}
      title={name}
      type={SearchEntitiesDialogType.AGENTS}
      disabledEntities={{ tools: true, workflows: true }}
      button={
        <TextButtonGray
          onClick={() => {}}
          className={className}
          text={name}
          Icon={SearchIcon}
        />
      }
    />
  );
}

export default FindAgentButton;
