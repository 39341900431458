import type { BlockMessageType } from "@toolflow/shared";

export const convertToBlockMessageType = (
  message: string,
  severity: "error" | "warning"
): BlockMessageType => {
  return {
    severity,
    message
  };
};
