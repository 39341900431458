import { useFormContext } from "react-hook-form";
import UpdateTextFieldBase from "../../../utilities/components/textFields/UpdateTextFieldBase";
import { SNIPPET_VALUE_FIELD_LABEL } from "./snippetFields";
import { SNIPPET_VALUE_REQUIRED_ERROR } from "./SnippetFormProvider";
import { isNull, isString } from "lodash";

const SnippetValueTextField = () => {
  const { watch } = useFormContext();
  const filedValues = watch();

  const value = filedValues[SNIPPET_VALUE_FIELD_LABEL];
  if (!isString(value) && !isNull(value)) {
    return null;
  }

  return (
    <UpdateTextFieldBase
      fullWidth
      size="medium"
      multiline
      rows={20}
      className="m-t-16px"
      fieldLabel={SNIPPET_VALUE_FIELD_LABEL}
      registerOptions={{ required: SNIPPET_VALUE_REQUIRED_ERROR }}
      showErrors
    />
  );
};

export default SnippetValueTextField;
