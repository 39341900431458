import React from "react";
import useGetSelectedStepToolId from "./runTool/hooks/useGetSelectedStepToolId";
import useGetToolFromQuery from "../../../../tools/hooks/useGetToolFromQuery";
import ToolOutputsCard from "../../../../tools/components/ToolOutputsCard";

function WorkflowStepOutputs() {
  const toolId = useGetSelectedStepToolId();
  const tool = useGetToolFromQuery(toolId);
  if (!tool) return null;
  return (
    <ToolOutputsCard
      toolOutputs={tool.main.toolOutputFields}
      variant="outlined"
      className="m-t-8px m-b-16px"
    />
  );
}

export default WorkflowStepOutputs;
