import { Stack } from "@mui/material";
import { type JSONValue } from "@toolflow/shared";
import { Fragment } from "react";
import { StructuredOutputAccordion } from "./StructuredOutputAccordion";
import { StructuredObject } from "./StructuredObject";

export function StructuredListOfObjects({
  name,
  value
}: {
  name: string;
  value: JSONValue[];
}) {
  return (
    <StructuredOutputAccordion name={name} transparentBg>
      <Stack spacing={1} sx={{ padding: 1 }}>
        {value.map((item, index) => {
          return (
            <Fragment key={index}>
              <StructuredObject
                name={`${index + 1}`}
                value={item}
                transparentBg
              />
            </Fragment>
          );
        })}
      </Stack>
    </StructuredOutputAccordion>
  );
}
