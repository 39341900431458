import { Typography } from "@mui/material";
import DrawerBottom from "../../../drawerBottom/DrawerBottom";
import { ReactNode } from "react";

const InputOutputListWrapper = ({
  list,
  title,
  secondaryText,
  rightSlot
}: {
  list: ReactNode;
  title: string;
  secondaryText: string;
  rightSlot: ReactNode;
}) => {
  return (
    <DrawerBottom
      rightSlot={rightSlot}
      mainContent={
        <div className="p-16px">
          <Typography variant="h7" className="m-t-8px" component="div">
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="m-b-24px m-t-10px"
          >
            {secondaryText}
          </Typography>
          {list}
        </div>
      }
    />
  );
};

export default InputOutputListWrapper;
