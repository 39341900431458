import { useDispatch } from "react-redux";
import { CloseIcon } from "../../../../../../../globalTheme/icons/icons";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { setAssetListBig } from "../../../../state/workspaceSlice";
import React from "react";

function CondenseAssetListButton() {
  const dispatch = useDispatch();
  const makeSmall = () => {
    dispatch(setAssetListBig(false));
  };
  return <RemixButton icon={CloseIcon} text="Condense" onClick={makeSmall} />;
}

export default CondenseAssetListButton;
