import type { UpdateSnippet } from "@toolflow/shared";
import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";
import {
  SNIPPET_NAME_FIELD_LABEL,
  SNIPPET_VALUE_FIELD_LABEL
} from "../snippetFields";

const useResetDefaultValuesAndRerender = (
  defaultValues: UpdateSnippet,
  methods: UseFormReturn<UpdateSnippet, $TSAllowedAny, undefined>
) => {
  useEffect(() => {
    methods.setValue(SNIPPET_NAME_FIELD_LABEL, defaultValues.name, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
    methods.setValue(SNIPPET_VALUE_FIELD_LABEL, defaultValues.snippetValue, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true
    });
  }, [JSON.stringify(defaultValues), methods]);
};

export default useResetDefaultValuesAndRerender;
