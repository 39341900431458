import { IconButton } from "@mui/material";
import { ReactNode } from "react";
import { Handle, Position } from "reactflow";
import { useToolbuilderContext } from "../../../context/ToolBuilderContext";
import useGetDrawerNodeId from "../../../store/hooks/useGetDrawerNodeId";
import styles from "./inOutNodeHandle.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

const InOutNodeHandle = ({
  handleType,
  icon,
  id
}: {
  handleType: "source" | "target";
  icon: ReactNode;
  id: string;
}) => {
  const { reactflowUtility } = useToolbuilderContext();
  const { dragParams } = reactflowUtility;
  const disabledHandleType = handleType === "source" ? "target" : "source";
  const position = handleType === "source" ? Position.Bottom : Position.Top;
  const idType = handleType === "source" ? "a" : "b";
  const openNodeId = useGetDrawerNodeId();

  // if not dragging, and not open node, hide
  const hideWhenNotDragging = !dragParams && openNodeId !== id;
  const hideWhenDraggingAndDisabled =
    dragParams && dragParams?.handleType !== disabledHandleType;
  return (
    <div className={cx("flex justify-center")}>
      <IconButton
        className={cx(
          styles["outer-div"],
          "flex align-i-center justify-center"
        )}
        disableRipple
      >
        <div
          className={cx(
            "noDrag cursor-crosshair border-radius-50",
            styles["circle-outline"],
            {
              "show-on-hover":
                hideWhenNotDragging || hideWhenDraggingAndDisabled
            }
          )}
        >
          <div className={styles["close-icon"]}>{icon}</div>
          <Handle
            type={handleType}
            className={cx("custom", styles.handle)}
            position={position}
            id={idType}
          />
        </div>
      </IconButton>
    </div>
  );
};

export default InOutNodeHandle;
