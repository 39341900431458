import { Typography } from "@mui/material";
import { StructuredOutputAccordion } from "./StructuredOutputAccordion";

export function StructuredSingleValue({
  name,
  value
}: {
  name: string;
  value: string | number | boolean;
}) {
  return (
    <StructuredOutputAccordion name={name}>
      <Typography>{`${value}`}</Typography>
    </StructuredOutputAccordion>
  );
}
