import React from "react";
import DeleteCurrentAssetButton from "../../deleteAssetButtons/DeleteCurrentAssetButton";
import DallESettingsDropdown from "./DallESettingsDropdown";

const DallEAssetIcons = () => {
  return (
    <div className="flex align-i-center show-on-hover">
      <DeleteCurrentAssetButton />
      <DallESettingsDropdown />
    </div>
  );
};

export default DallEAssetIcons;
