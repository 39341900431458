import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../../../stores/store";

interface IAgentBuilderInitialState {
  open: boolean;
}

const initialState: IAgentBuilderInitialState = {
  open: false
};

export type TAgentBuilderReducerName = "agentBuilder";
export const AGENT_BUILDER_REDUCER_NAME: TAgentBuilderReducerName =
  "agentBuilder";

const agentBuilderSlice = createSlice({
  name: AGENT_BUILDER_REDUCER_NAME,
  initialState,
  reducers: {
    openAgentSaveDialog(state) {
      state.open = true;
    },
    closeAgentSaveDialog(state) {
      state.open = false;
    }
  }
});

export const selectAgentSaveDialogOpen = (state: RootState) =>
  state[AGENT_BUILDER_REDUCER_NAME].open;

export const { openAgentSaveDialog, closeAgentSaveDialog } =
  agentBuilderSlice.actions;

export default agentBuilderSlice.reducer;
