import AssetSizeSwitch from "../assetSizeSwitch/AssetSizeSwitch";
import AssetHeaderContainer from "./AssetHeader";
import AssetTextFieldScrollWrapper from "./AssetTextFieldScrollWrapper";

export function TextAssetSizeSwitch({ className }: { className?: string }) {
  return (
    <AssetSizeSwitch className={className} header={<AssetHeaderContainer />}>
      <AssetTextFieldScrollWrapper />
    </AssetSizeSwitch>
  );
}
