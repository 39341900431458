import { type TMarketplaceUniversalEntity } from "@toolflow/shared";
import React, { FC } from "react";
import EntityCard from "../../entities/components/entityCard/EntityCard";
import useNavigateToAgentById from "../workstation/hooks/useNavigateToAgentById";

interface IEntityCardProps {
  entity: TMarketplaceUniversalEntity;
}
const AgentEntityCardContainer: FC<IEntityCardProps> = ({ entity }) => {
  const agentClick = useNavigateToAgentById(entity.id);
  return <EntityCard entity={entity} onClick={agentClick} />;
};

export default AgentEntityCardContainer;
