import getCaretCoordinates from "textarea-caret";
import { usePromptEditorContext } from "../context/PromptEditorContext";

const useHandleInputChange = () => {
  const {
    handleUpdatePrompt,
    setTypedChars,
    textFieldRef,
    setContextMenu,
    contextMenu
  } = usePromptEditorContext();
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputElement = e.target as HTMLInputElement;
    handleUpdatePrompt(inputElement.value);

    const cursorPosition = (inputElement.selectionStart || 0) - 1;
    const charAtCursor = inputElement.value.charAt(cursorPosition);

    if (charAtCursor === "/") {
      setTypedChars("");
      if (textFieldRef.current) {
        const rect = textFieldRef.current.getBoundingClientRect();

        const off = getCaretCoordinates(
          textFieldRef.current,
          textFieldRef.current.selectionStart || 0
        );
        const scrollLeft = textFieldRef.current.scrollLeft || 0;
        const scrollTop = textFieldRef.current.scrollTop
          ? textFieldRef.current.scrollTop + 6
          : 0;
        setContextMenu({
          mouseX: off.left + rect.left - scrollLeft,
          mouseY: off.top + rect.top + 24 - scrollTop
        });
      }
    }
    if (contextMenu !== null) {
      const charsAfterSlash = inputElement.value.slice(
        inputElement.value.lastIndexOf("/") + 1,
        inputElement.selectionStart || 0
      );
      if (!["Tab", "Enter"].includes(charAtCursor)) {
        setTypedChars(charsAfterSlash);
      }
    }
    setTimeout(() => {
      if (textFieldRef.current && contextMenu) {
        textFieldRef?.current?.focus();
      }
    }, 0);
  };
  return handleInputChange;
};

export default useHandleInputChange;
