import { useDispatch } from "react-redux";
import { useSettingsDropdownContext } from "../../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import { DrawerEnum, setDrawer } from "../../store/toolBuilderSlice";
import SettingsMenuItem from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { InfoIcon } from "../../../../../../globalTheme/icons/icons";

export function ToolbuilderMoreInfoMenuItem() {
  const dispatch = useDispatch();
  const { handleClose } = useSettingsDropdownContext();
  const setTool = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(setDrawer({ type: DrawerEnum.toolInfo }));

    handleClose(e);
  };

  return (
    <SettingsMenuItem
      text="More info"
      icon={<InfoIcon fontSize="small" />}
      action={setTool}
    />
  );
}
