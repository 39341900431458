import {
  type SerpBlockPropsV1,
  type SerpBlockSettingsV1
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import SerpBlockSettingsComponent from "../../../../settings/serp/SerpBlockSettings";

function SerpBlockInnerV1({ data, id }: Omit<SerpBlockPropsV1, "selected">) {
  const { label, settings } = data;
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;

  return (
    <>
      <SerpBlockSettingsComponent
        id={id}
        settings={settings as SerpBlockSettingsV1}
      />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default SerpBlockInnerV1;
