import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";
import useGetSelectedStepInputsToCollect from "./useGetSelectedStepInputToCollect";

const useChangeInputNameWorkflow = () => {
  const { update } = useInputsToCollectFieldArrayContext();
  const inputsToCollect = useGetSelectedStepInputsToCollect();
  const handleToolInputFieldChangeName = (value: string, index: number) => {
    update(index, { ...inputsToCollect[index], name: value });
  };

  return handleToolInputFieldChangeName;
};

export default useChangeInputNameWorkflow;
