import useGetCurrentUser from "../../user/hooks/useGetCurrentUser";
import { ORGANIZATION_PLAN_FE } from "../helpers/billingConstants";
import { useSubscriptionDetails } from "./useSubscriptionDetails";

// all toolflowEmployees are on the top plan

export const useAuthorizedTier = () => {
  const { activeTier } = useSubscriptionDetails();
  const user = useGetCurrentUser();
  if (!!user?.toolflowEmployeeType) {
    return ORGANIZATION_PLAN_FE;
  } else return activeTier;
};
