import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate
} from "react-router-dom";
import App from "../layout/App";
import Callback from "./components/Callback";
import * as Sentry from "@sentry/react";
import ToolflowBoundary from "../../utilities/components/errors/ToolflowBoundary";
import populatedRouteArray from "./helpers/routeArray";
import NewWorkspacePage from "../pages/workstation/components/newWorkspacePage/NewWorkspacePage";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        path="/"
        element={
          <Sentry.ErrorBoundary fallback={<ToolflowBoundary />} showDialog>
            <App />
          </Sentry.ErrorBoundary>
        }
      >
        <Route index element={<NewWorkspacePage />} />
        {populatedRouteArray.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Route>
      <Route
        path="/callback"
        element={
          <Sentry.ErrorBoundary fallback={<ToolflowBoundary />} showDialog>
            <Callback />
          </Sentry.ErrorBoundary>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  )
);

export default router;
