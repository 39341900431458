import React, { ReactNode } from "react";
import { addChipTabs, ALL } from "../helpers/constants";

import { matchSorter } from "match-sorter";
import {
  type ToolAutocomplete,
  EAddChipTab,
  ClickableTypes,
  type TAddChipConfig,
  type TAddChipTab
} from "@toolflow/shared";

const configMap = {
  [EAddChipTab.ASSETS]: ClickableTypes.INPUT,
  [EAddChipTab.SNIPPETS]: ClickableTypes.SNIPPET,
  [EAddChipTab.NODES]: ClickableTypes.TOOLBUILDER_INPUT,
  [EAddChipTab.NODE_OUTPUTS]: ClickableTypes.NODE_OUTPUT
};

const createConfig = (
  name: EAddChipTab,
  config: {
    values: ToolAutocomplete[];
    action?: ReactNode;
  }
) => {
  return {
    name,
    type: configMap[name],
    autocompleteItems: config.values,
    action: config.action
  };
};

const useAddChip = ({ chipsToUse = {} }: { chipsToUse?: TAddChipConfig }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [filteredString, setFilteredString] = React.useState("");

  const handleChangeTabIndex = (
    e: React.SyntheticEvent,
    newSelectedTabIndex: number
  ) => {
    setSelectedTabIndex(newSelectedTabIndex);
  };

  const getFilteredObject = ({
    name,
    type,
    autocompleteItems,
    action
  }: {
    name: string;
    type: ClickableTypes;
    autocompleteItems: ToolAutocomplete[];
    action?: ReactNode;
  }) => {
    const sortedAutocompleteItems = matchSorter(
      autocompleteItems,
      filteredString,
      {
        keys: ["primary"]
      }
    );
    const currentTab = addChipTabs[selectedTabIndex];
    return {
      [name]: {
        entities:
          currentTab.value === ALL || currentTab.value === name
            ? sortedAutocompleteItems
            : [],
        type,
        action
      }
    };
  };

  const entitiesObject = Object.entries(chipsToUse)
    .map(([key, value]) => createConfig(key as EAddChipTab, value))
    .reduce((acc, tab) => {
      return {
        ...acc,
        ...getFilteredObject({
          name: tab.name,
          type: tab.type,
          autocompleteItems: tab.autocompleteItems,
          action: tab.action
        })
      };
    }, {} as TAddChipTab);

  return {
    selectedTabIndex,
    handleChangeTabIndex,
    tabs: addChipTabs,
    entitiesObject,
    setFilteredString,
    filteredString
  };
};

export default useAddChip;
