import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { DrawerEnum } from "../toolBuilderSlice";

const useGetDrawerInputId = () => {
  const drawer = useSelector((s: RootState) => s.toolbuilder.drawer);
  const inputId = drawer?.type === DrawerEnum.inputs ? drawer?.inputId : "";
  return inputId;
};

export default useGetDrawerInputId;
