import { Paper } from "@mui/material";
import React from "react";
import SendThreadTextField from "../SendThreadTextField/SendThreadTextField";
import styles from "./chatInputPaper.module.css";
import ChatBottomButton from "./ChatBottomButton";

function ChatInputPaper() {
  return (
    <div style={{ position: "relative" }}>
      <Paper
        variant="workspaceWrapper"
        className={styles["custom-elevation"]}
        sx={{ backgroundColor: (theme) => theme.palette.background.paper }}
      >
        <SendThreadTextField />
        <ChatBottomButton className={"m-h-16px m-b-8px m-t-8px"} />
      </Paper>
      <div className={styles["border-overlay"]} />
    </div>
  );
}

export default ChatInputPaper;
