import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../stores/store";
import { BlockContextProvider } from "../../../../BlockContext";
import { useBlockIcon } from "../../../../blocks/components/blockIcons/BlockIcon";
import DeleteNodeIconButton from "../../../../blocks/components/deleteNodeButton/DeleteNodeIconButton";
import getDrawerComponent from "../../../../blocks/constants/getDrawerComponent";
import getExpandedComponent from "../../../../blocks/constants/getExpandedComponent";
import useGetCurrentBlock from "../../../../store/hooks/useGetCurrentBlock";
import UpdateLabelTextField from "../../../../UpdateLabelTextField";
import isRunnableBlock from "../../../functions/isRunnableBlock";
import DrawerBottom from "../../drawerBottom/DrawerBottom";
import CloseDrawerButton from "../../drawerHeader/CloseDrawerButton";
import { DrawerHeader } from "../../drawerHeader/DrawerHeaderContainer";
import ExpandDrawerDialogButton from "../expandDrawerDialog/ExpandDrawerDialogButton";
import TestAndPreviewButton from "../utilities/testAndPreviewButton/TestAndPreviewButton";
import NodeInfoChips from "./nodeInfoChips/NodeInfoChips";

const NodeInfoContent = () => {
  const drawerExpanded = useSelector(
    (state: RootState) => state.toolbuilder.drawerExpanded
  );
  const currentBlock = useGetCurrentBlock();
  const BlockIcon = useBlockIcon(currentBlock?.data);
  if (!isRunnableBlock(currentBlock)) {
    return <></>;
  }

  const Component = getDrawerComponent(
    currentBlock.data.type,
    currentBlock.data.version
  );
  const ExpandedComponent = getExpandedComponent(
    currentBlock.data.type,
    currentBlock.data.version
  );
  return (
    <BlockContextProvider
      id={currentBlock.id}
      data={currentBlock.data}
      selected={true}
    >
      <DrawerHeader
        BlockIcon={BlockIcon && <BlockIcon />}
        title={<UpdateLabelTextField />}
        rightSlot={
          <>
            {!drawerExpanded && <ExpandDrawerDialogButton />}
            <CloseDrawerButton />
          </>
        }
      />
      <DrawerBottom
        mainContent={
          <>
            <NodeInfoChips />
            <Component data={currentBlock.data} id={currentBlock.id} />
          </>
        }
        leftSlot={<DeleteNodeIconButton />}
        rightSlot={ExpandedComponent && <TestAndPreviewButton />}
      />
    </BlockContextProvider>
  );
};

export default NodeInfoContent;
