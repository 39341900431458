import { Alert } from "@mui/material";
import React, { FC } from "react";

interface IToolflowHireAlertProps {
  text: string;
  className?: string;
}
const ToolflowHireAlert: FC<IToolflowHireAlertProps> = ({
  text,
  className
}) => {
  return (
    <Alert
      severity="info"
      className={className}
      sx={{
        border: "1px solid #BBDEFB",
        borderRadius: "8px",
        height: "60px"
      }}
    >
      {text}
    </Alert>
  );
};

export default ToolflowHireAlert;
