import React from "react";
import useHandleAddToolInputFieldToolbuilder from "./hooks/useHandleAddToolInputFieldToolbuilder";
import useChangeInputDescriptionToolbuiler from "./hooks/useChangeInputFieldDescriptionToolbuilder";
import useChangeInputNameToolbuilder from "./hooks/useChangeInputNameToolbuilder";
import useDeleteToolInputFieldToolbuilder from "./hooks/useDeleteToolInputFieldToolbuilder";
import useChangeInputTypeToolbuilder from "./hooks/useChangeInputTypeToolbuilder";
import useDeleteOptionToolbuilder from "./hooks/useDeleteOptionToolbuilder";
import useChangeOptionNameToolbuilder from "./hooks/useChangeOptionNameToolbuilder";
import useSetToolInputFieldsToolbuilder from "./hooks/useSetToolInputFieldsToolbuilder";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import InputDrawerInner from "../InputDrawerInner";
import { ToolInputFieldsContextProvider } from "../inputBuilders/contexts/ToolInputFieldsContext";

function CustomToolInputFieldToolbuilderProvider() {
  const { state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;
  const addToolInputFieldToolbuilder = useHandleAddToolInputFieldToolbuilder();
  const changeInputDescription = useChangeInputDescriptionToolbuiler();
  const changeInputName = useChangeInputNameToolbuilder();
  const deleteInputField = useDeleteToolInputFieldToolbuilder();
  const changeInputType = useChangeInputTypeToolbuilder();
  const deleteOption = useDeleteOptionToolbuilder();
  const changeOptionName = useChangeOptionNameToolbuilder();
  const setToolInputFields = useSetToolInputFieldsToolbuilder();

  return (
    <ToolInputFieldsContextProvider
      toolInputFields={toolInputFields}
      addToolInputField={addToolInputFieldToolbuilder}
      changeInputDescription={changeInputDescription}
      changeInputName={changeInputName}
      deleteInputField={deleteInputField}
      changeInputType={changeInputType}
      deleteOption={deleteOption}
      changeOptionName={changeOptionName}
      setToolInputFields={setToolInputFields}
    >
      <InputDrawerInner />
    </ToolInputFieldsContextProvider>
  );
}

export default CustomToolInputFieldToolbuilderProvider;
