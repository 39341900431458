import { useState, useEffect, useCallback } from "react";
import { set, get } from "lodash";
import type { TUser } from "@toolflow/shared";
import useGetCurrentUser from "../../../../../user/hooks/useGetCurrentUser";
import { useUpdateCurrentUserMutation } from "../../../../../../ToolflowAPI/rtkRoutes/userApi";

type ProfileFieldUpdaterReturnType = {
  user: TUser | null;
  val: string;
  setVal: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  updateType: () => void;
};

function useProfileFieldUpdater(
  type: keyof TUser | ["company", "name"] | ["company", "employee_count"],
  successMessage: string
): ProfileFieldUpdaterReturnType {
  const user = useGetCurrentUser();
  const [val, setVal] = useState("");
  const [updateUser, { isLoading }] = useUpdateCurrentUserMutation();

  useEffect(() => {
    const fieldPath = Array.isArray(type) ? type.join(".") : type;
    const currentValue = get(user, fieldPath);
    if (currentValue !== val) {
      setVal((currentValue as string) || "");
    }
  }, [get(user, Array.isArray(type) ? type.join(".") : type)]);

  const updateType = useCallback(async () => {
    const updateData = Array.isArray(type)
      ? set({}, type.join("."), val)
      : { [type]: val };
    updateUser({ updateFields: updateData, successMessage });
  }, [val, type, successMessage, updateUser]);

  return {
    user,
    val,
    setVal,
    loading: isLoading,
    updateType
  };
}
export default useProfileFieldUpdater;
