import React, { FC } from "react";
import BaseSettingsDropdown from "../../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import { Grid, Table, TableBody, TableContainer } from "@mui/material";
import ToolBlockEntityTableRow from "./ToolBlockEntityTableRow";
import type { TMarketplaceEntity } from "@toolflow/shared";

interface IToolsBlockTableProps {
  toolsBlockEntities: TMarketplaceEntity[];
}
const ToolsBlockTable: FC<IToolsBlockTableProps> = (props) => {
  const { toolsBlockEntities } = props;
  if (!toolsBlockEntities?.length) {
    return null;
  }
  const firstHalfEntities = toolsBlockEntities.slice(
    0,
    Math.ceil(toolsBlockEntities.length / 2)
  );
  const secondHalfEntities = toolsBlockEntities.slice(
    Math.ceil(toolsBlockEntities.length / 2)
  );
  return (
    <BaseSettingsDropdown>
      <Grid container columnSpacing={3} className="p-h-16px" rowSpacing={0}>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table>
              <TableBody>
                {firstHalfEntities.map((entity) => (
                  <ToolBlockEntityTableRow
                    key={entity.id}
                    entity={entity}
                    removeLastRowBorder
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer>
            <Table>
              <TableBody>
                {secondHalfEntities.map((entity) => (
                  <ToolBlockEntityTableRow
                    key={entity.id}
                    entity={entity}
                    removeLastRowBorder
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </BaseSettingsDropdown>
  );
};

export default ToolsBlockTable;
