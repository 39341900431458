import React from "react";
import Dot from "./Dot";
import styles from "./dots.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import useWorkspaceAssets from "../../../../assets/useWorkspaceAssets";

const cx = parsedClassNames.bind(styles);

function Dots() {
  const assets = useWorkspaceAssets();
  return (
    <div
      className={cx(
        styles["dots-width"],
        "flex h-100-percent max-h-100-percent scrollable-content justify-center"
      )}
    >
      <div
        className={cx(
          styles["dots-container"],
          "flex align-i-center flex-column justify-center"
        )}
      >
        {assets.map((asset) => {
          return <Dot key={asset.id} assetId={asset.id} />;
        })}
      </div>
    </div>
  );
}

export default Dots;
