import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateStepId } from "../../workflowBuilderSlice";

const useCloseStepOnClick = () => {
  const dispatch = useDispatch();
  const handleClickOutside = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) {
      return;
    }
    const closeOnClickId = event.target.closest("#closeOnClickId");
    const dontCloseOnClick = event.target.closest("#dontCloseOnClick");
    if (dontCloseOnClick) return;
    if (closeOnClickId) {
      dispatch(updateStepId(undefined));
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
};

export default useCloseStepOnClick;
