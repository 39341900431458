import {
  AGENT,
  EntityType,
  MarketplaceGroup,
  type TMarketplaceUniversalEntity,
  type AutocompleteGroups,
  type MarketplaceEntityAutocomplete,
  type TMarketplaceEntity,
  type ToolAutocomplete
} from "@toolflow/shared";

function formatEntitiesForAutocomplete(
  tools: TMarketplaceEntity[]
): ToolAutocomplete[] {
  return tools.map((ft) => {
    return {
      primary: ft.name,
      id: ft.id,
      secondary: ft.description ?? "",
      group: (ft.type[0].toUpperCase() + ft.type.slice(1)) as
        | AutocompleteGroups.Tool
        | AutocompleteGroups.Workflow,
      favoriteType: "star"
    };
  });
}

export function formatMarketplaceEntitiesForAutocomplete(
  tools: TMarketplaceUniversalEntity[]
): MarketplaceEntityAutocomplete[] {
  const entityMap = {
    [EntityType.TOOL]: MarketplaceGroup.TOOL,
    [EntityType.WORKFLOW]: MarketplaceGroup.WORKFLOW,
    [AGENT]: MarketplaceGroup.AGENT
  };

  return tools.map((ft) => {
    return {
      primary: ft.name,
      id: ft.id,
      secondary: ft.description ?? "",
      group: entityMap[ft.type],
      favoriteType: "star",
      useCases: ft?.tag?.useCases as string[]
    };
  });
}

export function formatUseCasesForAutocomplete(
  tools: { name: string; id: string; description: string }[]
): MarketplaceEntityAutocomplete[] {
  return tools.map((ft) => {
    return {
      primary: ft.name,
      id: ft.id,
      secondary: ft.description ?? "",
      group: MarketplaceGroup.USE_CASE,
      favoriteType: "star",
      useCases: [ft.name]
    };
  });
}
export default formatEntitiesForAutocomplete;
