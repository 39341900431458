import { useMemo } from "react";

import type { CustomToolInputField, ExternalToolFE } from "@toolflow/shared";
import createInputTypeMap from "../createInputTypeMap";
import useToolInputFieldsAndAvailableFields from "../useToolInputFieldsAndAvailableFields";

const useCompatibilityMap = ({
  id,
  tool
}: {
  id: string;
  tool: ExternalToolFE;
}) => {
  const availableFieldsAndInputs = useToolInputFieldsAndAvailableFields(id);

  const inputTypeMap = useMemo(
    () =>
      tool.main.toolInputFields.reduce<{
        [key: string]: CustomToolInputField;
      }>((accumulator, currentField) => {
        accumulator[currentField.name] = currentField;
        return accumulator;
      }, {}),
    [JSON.stringify(tool.main.toolInputFields)]
  );

  const compatibilityMap = useMemo(() => {
    return createInputTypeMap(inputTypeMap, availableFieldsAndInputs);
  }, [JSON.stringify(inputTypeMap), JSON.stringify(availableFieldsAndInputs)]);
  return compatibilityMap;
};

export default useCompatibilityMap;
