import React from "react";
import useSettingsDropdown from "../../hooks/useSettingsDropdown";
import SettingsDropdownContext from "./SettingsDropdownContext";
import WrappedMenu from "./WrappedMenu";

function BaseSettingsDropdown({
  children,
  menuItems,
  disableToolTip
}: {
  children?: React.ReactNode;
  menuItems?: React.ReactNode;
  disableToolTip?: boolean;
}) {
  const { anchorEl, open, handleClick, handleClose } = useSettingsDropdown();

  return (
    <SettingsDropdownContext.Provider
      value={{
        handleClose,
        anchorEl,
        open,
        handleClick,
        disableToolTip
      }}
    >
      {children}
      <WrappedMenu>{menuItems}</WrappedMenu>
    </SettingsDropdownContext.Provider>
  );
}

export default BaseSettingsDropdown;
