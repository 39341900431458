import React from "react";

import NavigateToToolInWorkspaceButton from "../NavigateToToolInWorkspaceButton";
import CurrentToolVersion from "./CurrentToolVersion";
import SaveVersionDialog from "./SaveVersionDialog";
import useHideResetTool from "./hooks/useHideResetTool";
// import CreditCostDetails from "../components/CreditCostDetails";
import ToolBuilderHeaderSettingsDropdown from "./dropdowns/ToolBuilderHeaderSettingsDropdown";
import ToggleToolNameTextField from "./ToggleToolNameTextField";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import ToggleSidebarButton from "../../../layout/sidebar/ToggleSidebarButton";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../../../layout/sidebar/sidebarSlice";
import AuthenticationSplitter from "../../../auth/AuthenticationSplitter";
import LoggedOutCTA from "../../external/LoggedOutCTA";
import ProfileDropdownMenu from "../../profile/dropdown/ProfileDropdownMenu";

function Header() {
  const { state } = useToolbuilderContext();
  const { toolId } = state;
  // const { estimatedCreditCost } = currentState;
  const hideReset = useHideResetTool();
  const open = useSelector(selectSidebarOpen);

  return (
    <div className="flex align-i-center m-h-24px p-v-8px">
      {!open && <ToggleSidebarButton className="m-l-8px" />}
      <div className="w-100-percent flex align-i-center justify-space-between">
        <div className="flex align-i-center w-100-percent">
          <ToggleToolNameTextField />
          <ToolBuilderHeaderSettingsDropdown />
        </div>
        <div className="flex align-i-center">
          {state.toolVersionId !== state.main?._id && (
            <CurrentToolVersion className="m-r-8px" />
          )}
          {/* {estimatedCreditCost !== 0 && (
              <CreditCostDetails
                estimatedCreditCost={estimatedCreditCost}
                className="m-l-8px"
              />
            )} */}
          <SaveVersionDialog text className="m-r-8px" />
          <NavigateToToolInWorkspaceButton
            toolId={toolId}
            className="m-r-8px"
            size="small"
            variant={hideReset ? "contained" : "outlined"}
          />
          <AuthenticationSplitter
            externalComponent={<LoggedOutCTA />}
            authenticatedComponent={<ProfileDropdownMenu size={32} />}
          />
        </div>
      </div>
    </div>
  );
}

export default Header;
