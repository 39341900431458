import {
  AssetSourceType,
  type ToolAssetValue,
  type TransformedAssetValue
} from "@toolflow/shared";

function isToolAsset(value: TransformedAssetValue): value is ToolAssetValue {
  return (
    value.sourceType === AssetSourceType.ToolOutput ||
    value.sourceType === AssetSourceType.ToolOutputPlaceholder
  );
}

export default isToolAsset;
