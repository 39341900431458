import useGetDrawerInputId from "../../../../store/hooks/useGetDrawerInputId";
import InputDetails from "./inputDetails/InputDetails";
import InputListWrapper from "./inputList/InputListWrapper";

const InputListOrDetailsSplitter = () => {
  const inputId = useGetDrawerInputId();
  if (inputId) return <InputDetails inputId={inputId} />;

  return <InputListWrapper />;
};

export default InputListOrDetailsSplitter;
