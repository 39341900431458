import React from "react";
import RemixWrapperIconWithTheme from "../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { Avatar } from "@mui/material";
import { DarkUtilityTooltip } from "../../../../../../../utilities/components/toolTips/DarkUtilityTooltip";

import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import styles from "./draggableBlock.module.css";

const cx = parsedClassNames.bind(styles);

function DraggableBlock({
  onDragStart,
  icon,
  text
}: {
  onDragStart?: React.DragEventHandler<HTMLDivElement>;
  icon: React.ElementType;
  text: string;
}) {
  return (
    <DarkUtilityTooltip title={text} placement="right">
      <Avatar
        onDragStart={onDragStart}
        draggable
        className={cx(
          styles["block-draggable-block"],
          "cursor-grab flex align-i-center justify-center"
        )}
      >
        <RemixWrapperIconWithTheme Icon={icon} size={20} />
      </Avatar>
    </DarkUtilityTooltip>
  );
}
export default DraggableBlock;
