// THESE ARE DUPLICATED ON THE BACKEND
// we need to escape reg expression characters because

import type { TContractor, TEmployee, TUser } from "@toolflow/shared";

export const EMPLOYEE_FE: TEmployee = "employee";
export const CONTRACTOR_FE: TContractor = "contractor";

export function isToolflowHireFE(user?: TUser | null) {
  return (
    user?.toolflowEmployeeType === EMPLOYEE_FE ||
    user?.toolflowEmployeeType === CONTRACTOR_FE
  );
}
