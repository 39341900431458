import React from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import { FormHelperText, TextField } from "@mui/material";
import type { TextFieldVariants } from "@mui/material/TextField";

import { ABOUT_CHARACTER_LENGTH } from "../../../tools/components/editorToolCard/inputs/helpers/constants";
import type { TMarginTypes, TextFieldSizeTypes } from "@toolflow/shared";
import ToggleSecondaryHeaderTextFieldBase from "../../../../utilities/components/textFields/ToggleSecondaryHeaderTextFieldBase";
import { ADD_DESCRIPTION_TEXT } from "../../../../utilities/constants/fieldConstants";

function UpdateToolAboutTextField({
  label = "About",
  variant = "outlined",
  size = "small",
  margin = "normal",
  fullWidth = true
}: {
  label?: string;
  variant?: TextFieldVariants;
  size?: TextFieldSizeTypes;
  margin?: TMarginTypes;
  fullWidth?: boolean;
}) {
  const { state, dispatch: tDispatch } = useToolbuilderContext();
  const { currentState } = state;
  const { toolAbout } = currentState;

  const handleUpdateAbout = (e: React.ChangeEvent<HTMLInputElement>) => {
    tDispatch({ type: "UPDATE_TOOL_ABOUT", toolAbout: e.target.value });
  };

  return (
    <TextField
      size={size}
      margin={margin}
      value={toolAbout}
      label={label}
      variant={variant}
      fullWidth={fullWidth}
      // helperText={`(${toolAbout.length}/${ABOUT_CHARACTER_LENGTH})`}
      inputProps={{
        maxLength: ABOUT_CHARACTER_LENGTH
      }}
      onChange={handleUpdateAbout}
    />
  );
}

export function ToggleToolAboutTextField() {
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  return (
    <ToggleSecondaryHeaderTextFieldBase
      value={
        currentState.toolAbout ? currentState.toolAbout : ADD_DESCRIPTION_TEXT
      }
    >
      <div className="flex align-i-center max-w-40-percent">
        <UpdateToolAboutTextField
          size="small"
          margin="none"
          label="Description"
        />
        <FormHelperText className="m-l-8px">
          {currentState.toolAbout.length}/{ABOUT_CHARACTER_LENGTH}
        </FormHelperText>
      </div>
    </ToggleSecondaryHeaderTextFieldBase>
  );
}

export default UpdateToolAboutTextField;
