import {
  type DeepgramBlockSettingsV1,
  type DeepgramBlockPropsV1
} from "@toolflow/shared";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";

import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import DeepgramSettings from "../../../../settings/deepgram/DeepgramSettings";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";

function DeepgramBlockInnerV1({
  data,
  id
}: Omit<DeepgramBlockPropsV1, "selected">) {
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const { label, settings } = data;

  return (
    <>
      <DeepgramSettings
        id={id}
        settings={settings as DeepgramBlockSettingsV1}
      />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default DeepgramBlockInnerV1;
