import { Button } from "@mui/material";
import React from "react";
import { dismissKnowledgeCTA } from "../slice/knowledgeSlice";
import { useDispatch } from "react-redux";

function DismissKnowledgeCTAButton({ className = "" }: { className?: string }) {
  const dispatch = useDispatch();

  const dismissCTA = () => {
    dispatch(dismissKnowledgeCTA());
  };

  return (
    <Button
      onClick={dismissCTA}
      className={className}
      variant="outlined"
      size="medium"
    >
      Dismiss
    </Button>
  );
}

export default DismissKnowledgeCTAButton;
