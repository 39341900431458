import type { TMarketplaceEntity } from "@toolflow/shared";
import { useAllMarketplaceEntities } from "../../../../../features/entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useGetMarketplaceSelectorOptions = () => {
  const allMarketplaceEntities = useAllMarketplaceEntities();
  const options: Array<TMarketplaceEntity> = allMarketplaceEntities.filter(
    (
      (seenIds) => (item: TMarketplaceEntity) =>
        !seenIds.has(item.id) && seenIds.add(item.id)
    )(new Set<string>())
  );
  return options;
};

export default useGetMarketplaceSelectorOptions;
