import React, { FC } from "react";
import { StyledTableRow } from "../layout/TableLayouts";
import useNavigateToToolById from "../../workstation/hooks/useNavigateToToolById";
import useNavigateToWorkflowById from "../../workstation/hooks/useNavigateToWorkflowById";
import { EntityType } from "@toolflow/shared";
interface IEntityRowProps {
  entityId: string;
  children: React.ReactNode;
  entityType: EntityType;
  removeLastRowBorder?: boolean;
  className?: string;
}
const EntityRow: FC<IEntityRowProps> = ({
  children,
  entityId,
  entityType,
  removeLastRowBorder,
  className
}) => {
  const toolClick = useNavigateToToolById(entityId);
  const workflowClick = useNavigateToWorkflowById(entityId);
  const onClickHandler = (e: React.MouseEvent) => {
    if (entityType === EntityType.TOOL) {
      toolClick(e);
    }
    if (entityType === EntityType.WORKFLOW) {
      workflowClick(e);
    }
  };
  return (
    <StyledTableRow
      className={`cursor-pointer ${className}`}
      onClick={onClickHandler}
      removeLastRowBorder={removeLastRowBorder}
    >
      {children}
    </StyledTableRow>
  );
};

export default EntityRow;
