import { CircularProgress, LinearProgress, Typography } from "@mui/material";
import { formatSizeInMB } from "@toolflow/shared";
import { useMemo } from "react";
import {
  FileTextFillIcon,
  QuestionFillIcon,
  SpeechToTextFillIcon
} from "../../../globalTheme/icons/icons";
import CircleIcon from "../../../utilities/components/icons/CircleButton";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import { FileType } from "./helpers/fileHelpers";
import styles from "./selectedFilePaper.module.css";

const cx = parsedClassNames.bind(styles);

const FileIconMap = {
  [FileType.Audio]: SpeechToTextFillIcon,
  [FileType.Text]: FileTextFillIcon
};

const LoadingCircle = () => {
  return (
    <>
      <Typography
        variant="body2"
        className="m-h-4px"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        ·
      </Typography>
      <CircularProgress variant="indeterminate" size={14} className="m-h-4px" />
      <Typography
        variant="body2"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        Uploading...
      </Typography>
    </>
  );
};

const SelectedFilePaper = ({
  action,
  loading,
  progress = 0,
  fileType,
  fileName,
  fileSizeInBytes
}: {
  action: React.ReactNode;
  loading?: boolean;
  progress?: number;
  fileName: string;
  fileSizeInBytes: number;
  fileType: FileType;
}) => {
  const formattedSize = useMemo(() => {
    return formatSizeInMB(fileSizeInBytes);
  }, [fileSizeInBytes]);

  const loadingFormattedSize = useMemo(() => {
    return formatSizeInMB((fileSizeInBytes / 100) * progress);
  }, [fileSizeInBytes, progress]);

  return (
    <div className={cx("flex align-i-center", styles["selected-file-paper"])}>
      <CircleIcon size={20} Icon={FileIconMap[fileType] || QuestionFillIcon} />
      <div className="flex flex-column w-100-percent">
        <div className="flex justify-space-between w-100-percent">
          <div className="flex flex-column">
            <Typography variant="h8">{fileName}</Typography>
            <div className="flex w-100-percent align-i-center">
              <Typography
                variant="body2"
                sx={{ color: (theme) => theme.palette.text.secondary }}
              >
                {`${loading ? `${loadingFormattedSize} of ` : ""}${formattedSize}`}
              </Typography>
              {loading && <LoadingCircle />}
            </div>
          </div>
          <div className="flex align-i-center">{action}</div>
        </div>
        {loading && (
          <LinearProgress
            className={cx(styles["linear-progress"], "m-t-8px")}
            variant="determinate"
            value={progress}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedFilePaper;
