import { EntityType } from "@toolflow/shared";
import useNavigateToWorkspaceWithDefaultObject from "./useNavigateToWorkspaceWithDefaultObject";

const useNavigateToToolById = (toolId = "") => {
  const navigateToWorkspace = useNavigateToWorkspaceWithDefaultObject({
    type: EntityType.TOOL,
    id: toolId
  });
  const handleNavigate = (e: React.MouseEvent) => {
    if (!toolId) return;
    navigateToWorkspace(e);
  };
  return handleNavigate;
};

export default useNavigateToToolById;
