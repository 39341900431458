import React from "react";
import type { ExternalToolFE } from "@toolflow/shared";
import useIsCurrentUser from "../../user/hooks/useIsCurrentUser";
import SettingsDropdown from "../../../utilities/components/dropdowns/generic/SettingsDropdown";
import MoreToolInfoMenuItem from "../../pages/builder/toolBuilder/dropdowns/menuItems/MoreToolInfoMenuItem";
import NavigateToToolBuilderMenuItem from "../../pages/builder/toolBuilder/menuItems/NavigateToToolBuilderMenuItem";
import CopyToolLinkMenuItem from "./editorToolCard/menuItems/CopyToolLinkMenuItem";
import DuplicateToolMenuItem from "../../pages/builder/toolBuilder/dropdowns/menuItems/DuplicateToolMenuItem";

function ToolSettingsDropdown({ tool }: { tool: ExternalToolFE }) {
  const { _id: toolId, creator } = tool;
  const currentUserIsToolOwner = useIsCurrentUser(creator?._id);

  const menuItems = [
    <MoreToolInfoMenuItem tool={tool} key="moreInfo" />,
    currentUserIsToolOwner ? (
      <NavigateToToolBuilderMenuItem toolId={toolId} key="navigateToTool" />
    ) : null,
    <CopyToolLinkMenuItem toolId={toolId} key="copyToolLink" />,
    <DuplicateToolMenuItem
      id={toolId}
      key="duplicateTool"
      enableForking={tool.enableForking}
    />
  ];

  return <SettingsDropdown menuItems={menuItems} />;
}

export default ToolSettingsDropdown;
