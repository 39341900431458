import type { CustomToolInputField } from "@toolflow/shared";
import { useToolInputFieldsContext } from "../contexts/ToolInputFieldsContext";

export const getToolInputFieldIndexById = (
  toolInputFields: CustomToolInputField[],
  toolInputFieldId: string
) => {
  return toolInputFields.findIndex(
    (toolInputField) => toolInputField.id === toolInputFieldId
  );
};

const useGetToolInputFieldIndexById = (toolInputFieldId: string) => {
  const { toolInputFields } = useToolInputFieldsContext();
  return getToolInputFieldIndexById(toolInputFields, toolInputFieldId);
};

export default useGetToolInputFieldIndexById;
