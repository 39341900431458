import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import Sentry from "../../sentry/sentry";

function initializeLogRocket() {
  // after calling LogRocket.init()
  setupLogRocketReact(LogRocket);

  LogRocket.init("oxub8k/toolflow-production", {
    release: process.env.REACT_APP_ENVIRONMENT
  });
  LogRocket.getSessionURL((sessionURL) => {
    Sentry.configureScope((scope) => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });
}

export default initializeLogRocket;
