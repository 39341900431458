import type { TWorkflowBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKFLOW_TAG_FIELD_LABEL } from "../WorkflowBuilderConstants";

const useGetWorkflowCategories = () => {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return watch(WORKFLOW_TAG_FIELD_LABEL)?.categories || [];
};

export default useGetWorkflowCategories;
