import { Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import type { THomeEntityBlockType } from "@toolflow/shared";
import { RootState } from "../../../../../stores/store";
import useAllHomePageMainData from "../../hooks/useAllHomePageMainData";
import { updateToolsBlockTab } from "../../slice/homePageSlice";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import TabPanel from "../../../../../utilities/components/tabs/TabPanel";
import { a11yProps } from "../../../../../utilities/components/tabs/functions/a11yProps";
import FrequentlyUsedEntitiesDetailedContainer from "../../tools/toolsBlock/FrequentlyUsedEntitiesDetailedContainer";
import StarredEntitiesDetailedContainer from "../../tools/toolsBlock/StarredEntitiesDetailedContainer";
import UserCreatedEntitiesDetailedContainer from "../../tools/toolsBlock/UserCreatedEntitiesDetailedContainer";

type THomeToolsBlockTabDict = {
  [key in THomeEntityBlockType]: {
    value: number;
    component: React.ReactNode;
    label: string;
  };
};

const homeTabDict: THomeToolsBlockTabDict = {
  "frequently-used": {
    value: 0,
    component: <FrequentlyUsedEntitiesDetailedContainer />,
    label: "Frequently used"
  },
  starred: {
    value: 1,
    component: <StarredEntitiesDetailedContainer />,
    label: "Starred"
  },
  "created-by-user": {
    value: 2,
    component: <UserCreatedEntitiesDetailedContainer />,
    label: "Created by you"
  }
};
const ToolsDetailedBlockData = () => {
  const homeTab = useSelector((state: RootState) => state.home.toolsBlockTab);
  const dispatch = useDispatch();
  const { homePageDataLength, isLoading } = useAllHomePageMainData();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: THomeEntityBlockType
  ) => {
    dispatch(updateToolsBlockTab(newValue));
  };

  const isDataEmpty = homePageDataLength === 0 && !isLoading;
  if (isDataEmpty) {
    return null;
  }
  return (
    <div className="p-h-16px m-t-24px">
      <FullWidthCard title="" icon={null} className="p-h-8px p-t-8px">
        <Tabs value={homeTab} onChange={handleChange} textColor="secondary">
          {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
            <Tab
              label={tabDetails.label}
              key={tabName}
              value={tabName}
              {...a11yProps(tabDetails.value)}
              className="m-l-12px"
            />
          ))}
        </Tabs>
        <Divider variant="middle" />
        {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
          <TabPanel value={homeTab} key={tabName} index={tabName}>
            {tabDetails.component}
          </TabPanel>
        ))}
      </FullWidthCard>
    </div>
  );
};

export default ToolsDetailedBlockData;
