import { type TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import {
  WORKSPACE_CHAT_AGENT_ID_FIELD_LABEL,
  WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL
} from "../helpers/workspaceConstants";
import { useDispatch } from "react-redux";
import { setDisableTabChange } from "../state/workspaceSlice";

const useSetSelectedAgent = (disableSave = false) => {
  const { setValue } = useFormContext<TWorkspaceDataToSave>();
  const dispatch = useDispatch();

  const setSelectedTool = (agentId: string) => {
    setValue(WORKSPACE_CHAT_THREAD_ID_FIELD_LABEL, "", {
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    setValue(WORKSPACE_CHAT_AGENT_ID_FIELD_LABEL, agentId, {
      shouldDirty: !disableSave,
      shouldTouch: true,
      shouldValidate: true
    });
    dispatch(setDisableTabChange(false));
  };
  return setSelectedTool;
};

export default useSetSelectedAgent;
