import { useGetToolflowConstantsQuery } from "../../../../../../ToolflowAPI/rtkRoutes/toolflowConstantsApi";
import {
  ENVIRONMENT,
  type HyperlinePlans,
  type TAvailableTiers
} from "@toolflow/shared";
import {
  CURRENT_SUBSCRIPTION,
  DOWNGRADE,
  hyperlineOrganizationPlanIdDevOrStaging,
  hyperlineOrganizationPlanIdProduction,
  hyperlineProPlanIdDevOrStaging,
  hyperlineProPlanIdProduction,
  ORGANIZATION_UPGRADE,
  PRO_UPGRADE,
  TCurrentSubscription,
  TDowngrade,
  TUpgrade,
  TUpgradeToOrganization,
  TUpgradeToPro
} from "../../../../../billing/helpers/billingConstants";

const useSubscriptionDict = () => {
  const { data } = useGetToolflowConstantsQuery();
  const toolflowConstants = data.toolflowConstants;
  const subscriptionDict: {
    [key in TAvailableTiers]: {
      name: string;
      price: string;
      priceAmountString: string;
      priceDurationString: string;
      planId?: HyperlinePlans;
      benefits: string[];
      tagline: string;
      ctas: {
        [ctaKey in TAvailableTiers]:
          | TDowngrade
          | TUpgrade
          | TCurrentSubscription
          | TUpgradeToOrganization
          | TUpgradeToPro;
      };
    };
  } = {
    free: {
      name: "Free",
      price: "$0 / month",
      priceAmountString: "$0",
      priceDurationString: "/ month",
      tagline: "Great for individuals getting started",
      benefits: [
        "1 user",
        "100 monthly credits included",
        "Marketplace tools",
        "No-code tool builder",
        "Full access to the latest LLMs and web scraping",
        "Bulk runs",
        "Slack community support"
      ],
      ctas: {
        free: CURRENT_SUBSCRIPTION,
        pro: PRO_UPGRADE,
        organization: ORGANIZATION_UPGRADE
      }
    },
    pro: {
      name: "Pro",
      price: `$${toolflowConstants.proPrice} / month`,
      priceAmountString: `$${toolflowConstants.proPrice}`,
      priceDurationString: "/ month",
      planId:
        process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION
          ? hyperlineProPlanIdProduction
          : hyperlineProPlanIdDevOrStaging,
      tagline: "Great for individuals",
      benefits: [
        "1 user",
        "1,500 monthly credits included",
        "Slack community support",
        "Marketplace",
        "No-code tool builder",
        "Full access to the latest LLMs",
        "Web scraping, Google search, and more integrations",
        "Hosted vector database with RAG",
        "AI agents (coming soon)"
      ],
      ctas: {
        free: DOWNGRADE,
        pro: CURRENT_SUBSCRIPTION,
        organization: ORGANIZATION_UPGRADE
      }
    },
    organization: {
      name: "Team",
      price: `Contact us`,
      priceAmountString: "Contact us",
      priceDurationString: "",
      planId:
        process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.PRODUCTION
          ? hyperlineOrganizationPlanIdProduction
          : hyperlineOrganizationPlanIdDevOrStaging,
      tagline: "Everything in Pro, plus",
      benefits: [
        "Custom number of users",
        "Custom number of credits",
        "Dedicated customer support",
        "Zapier integration",
        "Trigger via API"
      ],
      ctas: {
        free: DOWNGRADE,
        pro: DOWNGRADE,
        organization: CURRENT_SUBSCRIPTION
      }
    }
  };
  return subscriptionDict;
};

export default useSubscriptionDict;
