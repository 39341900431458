import React from "react";
import type { CustomToolInputFieldTypes, TMarginTypes } from "@toolflow/shared";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  Typography,
  ListItemText
} from "@mui/material";
import type { SelectChangeEvent } from "@mui/material/Select";
import { inputTypeArray } from "../../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import iconDict, {
  labelDict
} from "../../../../../../../../../utilities/constants/iconDict";

function InputMenuItem({ type }: { type: CustomToolInputFieldTypes }) {
  return (
    <>
      <ListItemIcon>{iconDict[type]}</ListItemIcon>
      <ListItemText>{labelDict[type]}</ListItemText>
    </>
  );
}

function InputTypeSelect({
  type,
  onChange,
  margin = "none",
  size = "medium",
  allowedFields = inputTypeArray,
  disabled,
  className
}: {
  type: CustomToolInputFieldTypes;
  margin?: TMarginTypes;
  size?: "medium" | "small";
  onChange: (event: SelectChangeEvent<CustomToolInputFieldTypes>) => void;
  allowedFields?: CustomToolInputFieldTypes[];
  disabled?: boolean;
  className?: string;
}) {
  return (
    <FormControl
      fullWidth
      margin={margin}
      disabled={disabled}
      className={className}
    >
      <Typography variant="subtitle2" color="text.secondary">
        Input type
      </Typography>
      <Select
        size={size}
        value={type}
        className="nowheel nodrag nopan"
        onChange={onChange}
        startAdornment={iconDict[type]}
        renderValue={() => (
          <Typography className="m-l-8px">{labelDict[type]}</Typography>
        )}
      >
        {allowedFields.map((field: CustomToolInputFieldTypes, idx) => (
          <MenuItem value={field} dense key={idx}>
            <InputMenuItem type={field} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default InputTypeSelect;
