import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import type { Editor } from "@tiptap/core";
import useGetTipTapValue from "./useGetTipTapValue";

const useOnBlur = () => {
  const { muiTipTapProps } = useMuiTipTapContext();
  const { onUpdate } = muiTipTapProps;
  const getTipTapValue = useGetTipTapValue();

  const onBlur = ({ editor }: { editor: Editor }) => {
    const json = editor.getJSON();
    if (
      ["development", "staging"].includes(
        process.env.REACT_APP_ENVIRONMENT || ""
      )
    ) {
      window.json = json;
    }
    // this is necessary to update the description
    if (onUpdate) {
      onUpdate(getTipTapValue({ editor }));
    }
  };
  return onBlur;
};

export default useOnBlur;
