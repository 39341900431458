import {
  FormHelperText,
  FormLabel,
  TextField,
  type TextFieldProps
} from "@mui/material";
import { useId } from "react";

export default function TextFieldWithLabel({
  label,
  helperText,
  ...props
}: TextFieldProps) {
  const id = useId();

  return (
    <>
      <FormLabel
        htmlFor={props.id || id}
        sx={{ fontWeight: 500, color: "rgba(18, 23, 41, 0.60)" }}
      >
        {label}
      </FormLabel>
      <TextField
        id={props.id || id}
        sx={{ backgroundColor: "white" }}
        {...props}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </>
  );
}
