import { createTheme } from "@mui/material";
import utilityTheme from "../../../../globalTheme/muiUtils/utilityTheme";

const homePageTheme = createTheme(utilityTheme, {
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: "center",
          marginRight: 0
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px"
        }
      },
      variants: [
        {
          props: { size: "large" },
          style: {
            borderRadius: "8px",
            padding: "8px 22px"
          }
        },

        {
          props: { size: "small" },
          style: {
            padding: "4px 10px"
          }
        },
        {
          props: { variant: "text" },
          style: {
            backgroundColor: "rgba(138, 45, 255, 0.04)"
          }
        }
      ]
    }
  }
});

export default homePageTheme;
