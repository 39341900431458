import React, { createContext, useContext } from "react";
import type { TMarketplaceEntity } from "@toolflow/shared";

interface EntityContextType {
  entity: TMarketplaceEntity;
}

const EntityContext = createContext<EntityContextType | undefined>(undefined);

export function EntityContextComponent({
  children,
  entity
}: Readonly<{
  children: React.ReactNode;
  entity?: TMarketplaceEntity | null;
}>) {
  if (!entity) {
    return null;
  }
  return (
    <EntityContext.Provider value={{ entity }}>
      {children}
    </EntityContext.Provider>
  );
}

export const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (context === undefined) {
    throw new Error("useEntityContext must be used within a EntityProvider");
  }
  return context;
};

export default EntityContext;
