//security.ts
import { useAuth0, type GetTokenSilentlyOptions } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLoginBackToPage } from "../features/auth/hooks/useLoginBackToPage";

let getAccessTokenSilently:
  | ((options?: GetTokenSilentlyOptions | undefined) => Promise<string>)
  | null = null;

let loginBackToPage: (() => void) | null = null;

export const security = {
  getLoginBackToPage: () => loginBackToPage,
  setLoginBackToPage: (func: () => void) => (loginBackToPage = func),
  getAccessTokenSilently: () => getAccessTokenSilently,
  setAccessTokenSilently: (
    func: (options?: GetTokenSilentlyOptions | undefined) => Promise<string>
  ) => (getAccessTokenSilently = func)
};

export const useSetSecurityFunctions = () => {
  const { getAccessTokenSilently: accessFunc } = useAuth0();
  const { loginBackToPage: loginFunc } = useLoginBackToPage();

  useEffect(() => {
    security.setAccessTokenSilently(accessFunc);
    security.setLoginBackToPage(loginFunc);
  }, []);
};

export const securityIsSet = () => {
  return security.getAccessTokenSilently() && security.getLoginBackToPage();
};
