import useDeleteNodeButton from "./useDeleteNodeButton";
import { toolflowTextDisabledColor } from "../../../../../../../globalTheme/muiUtils/appTheme";
import DeleteIconButtonBase from "../deleteIconButtonBase/DeleteIconButtonBase";

function DeleteNodeIconButton({
  disabled,
  className,
  size = 20,
  color = toolflowTextDisabledColor
}: {
  disabled?: boolean;
  className?: string;
  size?: number;
  color?: string;
}) {
  const deleteNode = useDeleteNodeButton();
  return (
    <DeleteIconButtonBase
      disabled={disabled}
      className={className}
      size={size}
      color={color}
      onClick={deleteNode}
    />
  );
}

export default DeleteNodeIconButton;
