import {
  InputType,
  type JSONSchemaItem,
  type OptimizationsType,
  type StructuredBlockSettings
} from "@toolflow/shared";

const initialOptimizations: OptimizationsType = {
  llmModel: "gpt-4o-2024-08-06",
  temperature: 50
};

export const structureJsonSchemaInitialState: JSONSchemaItem = {
  type: "string",
  fieldLabel: "",
  guideline: ""
};

export const structuredSettingsInitialState: StructuredBlockSettings = {
  input: "",
  inputType: InputType.FixedInput,
  schema: [structureJsonSchemaInitialState]
};

export const structuredInitialState = {
  settings: structuredSettingsInitialState,
  optimizations: initialOptimizations
};
