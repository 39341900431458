import { isAuthError } from "./isAuthError";

export function isLoginOrConsentRequiredError(error: unknown): boolean {
  return (
    isAuthError(error) &&
    (error.error === "login_required" ||
      error.error === "interaction_required" ||
      error.error === "consent_required")
  );
}
