import React from "react";
import UniversalAutocomplete from "./UniversalAutocomplete";
import { MarketplaceGroup, type TSelectAutocomplete } from "@toolflow/shared";
import { useNavigate } from "react-router-dom";
import {
  EXTERNAL_AGENT_BASE_ROUTE_PATH,
  EXTERNAL_TOOL_BASE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_BASE_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH
} from "../../../../features/navigation/helpers/routePaths";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";

const UniversalAutocompleteContainer = () => {
  const navigate = useNavigate();
  const currentPath = useSelector(
    (state: RootState) => state.navigation.currentPath
  );

  const handleOpenMarketplaceEntity: TSelectAutocomplete = (id, type) => {
    const isCurrentPageMarketplace = currentPath === TOOLS_PAGE_ROUTE_PATH;
    if (type === MarketplaceGroup.TOOL) {
      navigate(`${EXTERNAL_TOOL_BASE_ROUTE_PATH}${id}`);
    } else if (type === MarketplaceGroup.WORKFLOW) {
      navigate(`${EXTERNAL_WORKFLOW_BASE_ROUTE_PATH}${id}`);
    } else if (
      type === MarketplaceGroup.USE_CASE &&
      !isCurrentPageMarketplace
    ) {
      navigate(TOOLS_PAGE_ROUTE_PATH);
    } else if (type === MarketplaceGroup.AGENT) {
      navigate(`${EXTERNAL_AGENT_BASE_ROUTE_PATH}${id}`);
    }
  };
  return (
    <UniversalAutocomplete
      handleSelectEntity={handleOpenMarketplaceEntity}
      className="max-w-530px"
    />
  );
};

export default UniversalAutocompleteContainer;
