import React from "react";
import CircularLoading from "../../../../../../../../../utilities/components/loading/CircularLoading";

const LoadingBase = ({
  loading,
  children
}: {
  loading: boolean;
  children: React.ReactNode;
}) => {
  if (loading) return <CircularLoading size={20} />;
  return <>{children}</>;
};

export default LoadingBase;
