import React from "react";
import ButtonLinkToBilling from "../../../../pages/builder/toolBuilder/common/ButtonLinkToBilling";
import RunButton from "./RunButton";
import { useEditorToolCardContext } from "../EditorToolCardContext";

const AuthenticatedActions = () => {
  const { showUpgrade, additionalActions } = useEditorToolCardContext();
  return (
    <>
      <div className="flex align-i-center justify-space-between w-100-percent m-v-24px">
        {additionalActions ? additionalActions : <div />}
        <div className="flex align-i-center">
          {showUpgrade && <ButtonLinkToBilling />}
          <RunButton />
        </div>
      </div>
    </>
  );
};

export default AuthenticatedActions;
