import React from "react";
import SettingsMenuItemWithClose from "../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { useHandleLogout } from "../../../layout/sidebar/useHandleLogout";
import { LogoutIcon } from "../../../../globalTheme/icons/icons";

const LogoutMenuItem = () => {
  const handleLogout = useHandleLogout();

  return (
    <SettingsMenuItemWithClose
      icon={<LogoutIcon size={18} />}
      text="Log out"
      action={handleLogout}
    />
  );
};

export default LogoutMenuItem;
