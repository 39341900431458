export const TOOL_TIP_CHAR_LENGTH = 80;

const getBaseText = (s: string, stringLength: number) => {
  return `${s.substring(0, stringLength)}${
    s.length > stringLength ? "..." : ""
  }`;
};

export const getToolTipText = (s: string) => {
  return getBaseText(s, TOOL_TIP_CHAR_LENGTH);
};
