import React from "react";
import { useSettingsDropdownContext } from "./useSettingsDropownContext";
import SettingsMenuItem from "./SettingsMenuItem";
import { ISettingsMenuItemWithCloseProps } from "./base/settingsTypes";

const SettingsMenuItemWithClose = ({
  action,
  ...rest
}: ISettingsMenuItemWithCloseProps) => {
  const { handleClose } = useSettingsDropdownContext();
  const actionWithClose = (e: React.SyntheticEvent<Element, Event>) => {
    action();
    handleClose(e);
  };
  return <SettingsMenuItem action={actionWithClose} {...rest} />;
};

export default SettingsMenuItemWithClose;
