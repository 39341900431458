import {
  type BlockDataTypesWithDefault,
  type DecimalString
} from "@toolflow/shared";
import createBlockDict from "./helpers/createBlockDict";
import React from "react";

const getExpandedComponent = (
  type: BlockDataTypesWithDefault["type"],
  version?: DecimalString
) => {
  const dict = createBlockDict<React.ElementType>("expandedComponent", version);

  return dict[type] || dict.default;
};

export default getExpandedComponent;
