import React, { FC } from "react";
import NavigateToToolBuilderButton from "../builder/toolBuilder/NavigateToToolBuilderButton";
import NavigateToWorkflowBuilderButton from "../builder/workflowBuilder/NavigateToWorkflowBuilderButton";
import {
  AGENT,
  EntityType,
  type TMarketplaceUniversalEntityType
} from "@toolflow/shared";
import NavigateToAgentBuilderButton from "../builder/agentBuilder/NavigateToAgentBuilderButton";

interface INavigateToBuilderMenuItemProps {
  entityId: string;
  type: TMarketplaceUniversalEntityType;
}

const NavigateToBuilderMenuItem: FC<INavigateToBuilderMenuItemProps> = ({
  entityId,
  type
}) => {
  if (type === EntityType.WORKFLOW) {
    return <NavigateToWorkflowBuilderButton workflowId={entityId} />;
  } else if (type === EntityType.TOOL) {
    return <NavigateToToolBuilderButton toolId={entityId} />;
  } else if (type === AGENT) {
    return <NavigateToAgentBuilderButton agentId={entityId} />;
  }
};

export default NavigateToBuilderMenuItem;
