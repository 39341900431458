import type { CustomToolOutputField } from "@toolflow/shared";
import ToolOutputField from "./ToolOutputField";

const OutputList = ({
  toolOutputFields
}: {
  toolOutputFields: CustomToolOutputField[];
}) => {
  return (
    <>
      {toolOutputFields.map((toolOutputField) => (
        <ToolOutputField
          key={toolOutputField.id}
          toolOutputField={toolOutputField}
        />
      ))}
    </>
  );
};

export default OutputList;
