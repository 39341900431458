import React from "react";
import LinkTextButton from "../../utilities/components/buttons/LinkTextButton";

export const PRIVACY_URL =
  "https://sour-track-509.notion.site/Privacy-Policy-b5bf06ad0d0c4b0ba8e66a9e342061a3";

function Privacy({
  typographyClassName = ""
}: {
  typographyClassName?: string;
}) {
  return (
    <LinkTextButton
      to={PRIVACY_URL}
      text="Privacy"
      typographyClassName={typographyClassName}
    />
  );
}

export default Privacy;
