import {
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_STEP_TYPE_FIELD_LABEL
} from "../../WorkflowBuilderConstants";
import useGetSelectedStep from "./useGetSelectedStep";
import useGetWorkflowStepTypeFieldFieldById from "./useGetWorkflowStepTypeFieldById";

const useGetSelectedStepTypeFieldLabel =
  (): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${typeof WORKFLOW_STEP_TYPE_FIELD_LABEL}` => {
    const step = useGetSelectedStep();
    return useGetWorkflowStepTypeFieldFieldById(step?.id || "");
  };

export default useGetSelectedStepTypeFieldLabel;
