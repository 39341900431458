import { createSelector } from "@reduxjs/toolkit";
import { workspaceApi } from "../workspaceApi";
import {
  streamlinedWorkspaceAdapter,
  workspaceInitialState
} from "../../../stores/adapters/workspaceAdapter";
import { RootState } from "../../../stores/store";
import { getCurrentUserIdInSelector } from "./dynamicSelectors";

const selectProfileWorkspacesResults = (profileId: string) =>
  workspaceApi.endpoints.getProfileWorkspaces.select(profileId);

const selectProfileWorkspaceData = (profileId: string) =>
  createSelector(
    selectProfileWorkspacesResults(profileId),
    (workspaceResult) => workspaceResult.data ?? workspaceInitialState
  );

export const selectAllProfileWorkspaces = (profileId: string) =>
  createSelector(
    (state: RootState) => selectProfileWorkspaceData(profileId)(state),
    (workspaceData) =>
      streamlinedWorkspaceAdapter.getSelectors().selectAll(workspaceData)
  );
export const selectLimitedProfileWorkspaces = (
  profileId: string,
  limit: number
) =>
  createSelector(
    (state: RootState) => selectProfileWorkspaceData(profileId)(state),
    (workspaceData) =>
      streamlinedWorkspaceAdapter
        .getSelectors()
        .selectAll(workspaceData)
        .sort((a, b) => b.createdAt - a.createdAt)
        .slice(0, limit)
  );

export const selectProfileWorkspaceById =
  (workspaceId: string) => (profileId: string) =>
    createSelector(
      (state: RootState) => selectProfileWorkspaceData(profileId)(state),
      (workspaceData) =>
        streamlinedWorkspaceAdapter
          .getSelectors()
          .selectById(workspaceData, workspaceId)
    );

export const selectCurrentUserWorkspaceById = (workspaceId = "") =>
  createSelector(
    [getCurrentUserIdInSelector, (state: RootState) => state],
    (currentUserId, state) => {
      return selectProfileWorkspaceById(workspaceId)(currentUserId)(state);
    }
  );
