import { ChipNodeComponents } from "../ChipNodeComponents";
import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";
import { ExtensionContent, ExtensionGroup } from "./configTypes";

export const SearchToolResponseExtensionConfig = {
  config: {
    name: ChipNodeComponents.SearchToolResponseComponent,

    group: ExtensionGroup.Block,
    content: ExtensionContent.Inline,
    draggable: true,
    selectable: false, // needed so that we don't delete it when typing things if you click the tool

    atom: true
  },
  htmlTag: ChipNodeHtmlTags.SearchToolResponseComponent,
  attributes: {
    toolIds: {
      default: []
    }
  }
};
