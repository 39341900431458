import React from "react";
import { MenuButtonTooltip } from "mui-tiptap";

function SaveTooltipWrapper({
  tooltip,
  children
}: Readonly<{
  tooltip?: boolean;
  children: React.ReactNode;
}>) {
  return (
    <>
      {tooltip ? (
        <MenuButtonTooltip label="Save">{children}</MenuButtonTooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
}

export default SaveTooltipWrapper;
