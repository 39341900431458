import React from "react";

import RemixButton from "../../../../../../utilities/components/icons/RemixButton";

import { useDispatch } from "react-redux";
import { setAssetExpanded } from "../../../state/workspaceSlice";
import { CloseIcon } from "../../../../../../globalTheme/icons/icons";

const useCloseAssetDialog = () => {
  const dispatch = useDispatch();

  const setClose = () => {
    dispatch(setAssetExpanded(false));
  };
  return setClose;
};

const CloseAssetDialogButton = ({ className = "" }: { className?: string }) => {
  const setClose = useCloseAssetDialog();
  return (
    <RemixButton
      text="Close"
      icon={CloseIcon}
      onClick={setClose}
      className={className}
    />
  );
};

export default CloseAssetDialogButton;
