import { useDispatch } from "react-redux";
import {
  DrawerEnum,
  setDrawer
} from "../../../../../../store/toolBuilderSlice";

const useSelectInput = () => {
  const dispatch = useDispatch();
  const selectInput = (inputId: string) => {
    dispatch(setDrawer({ type: DrawerEnum.inputs, inputId }));
  };
  return selectInput;
};

export default useSelectInput;
