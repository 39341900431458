import { Stack } from "@mui/material";
import { type UserInputDictType } from "@toolflow/shared";
import TestFieldsWithPrompt from "../../components/testWrapper/TestFieldsWithPrompt";
import { TSetTestUserInput } from "../../hooks/useFields";

function TestFieldsV2({
  uI,
  setUI,
  fields,
  filledOutPrompt
}: {
  uI: UserInputDictType;
  setUI: TSetTestUserInput;
  fields: string[];
  filledOutPrompt: string;
}) {
  return (
    <Stack spacing={2} className="m-t-32px">
      <TestFieldsWithPrompt
        fields={fields}
        uI={uI}
        setUI={setUI}
        filledOutPrompt={filledOutPrompt}
      />
    </Stack>
  );
}

export default TestFieldsV2;
