async function handleAudioDuration(f: File) {
  let audioDuration = 0; // in seconds
  const audio = document.createElement("audio");
  audio.src = URL.createObjectURL(f);

  // Wrap the duration load in a promise
  const loadAudioDuration = new Promise<void>((resolve) => {
    audio.addEventListener("loadedmetadata", () => {
      audioDuration = audio.duration;
      resolve(); // Resolve the promise once duration is loaded
    });
  });

  // Wait for audio duration to be loaded
  await loadAudioDuration;
  return audioDuration;
}

export default handleAudioDuration;
