import React, { FC } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { type SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import TagsInput from "react-tagsinput";
import { TChipTag } from "./hooks/useCategoryTags";
import { FormHelperText } from "@mui/material";

interface IAssignTagsProps {
  chipTags: TChipTag[];
  initialTags: TChipTag[];
  selectedTags: string[];
  disableHelperText?: boolean;
  label?: string;
  placeholder?: string;
  helperText?: string;
  className?: string;
  handleChangeTags: ({
    event,
    tagToBeRemoved
  }: {
    event?: SelectChangeEvent<string[]>;
    tagToBeRemoved?: TChipTag;
  }) => void;
  disabled?: boolean;
}

const AssignTags: FC<IAssignTagsProps> = (props) => {
  const {
    handleChangeTags,
    initialTags,
    selectedTags,
    chipTags,
    disableHelperText = true,
    label = "Add Tags",
    placeholder = "Select Tags",
    helperText = "",
    disabled = false,
    className = ""
  } = props;

  return (
    <TagsInput
      className={className}
      value={chipTags}
      renderInput={() => {
        return (
          <>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel id="demo-multiple-chip-labell">{label}</InputLabel>
              <Select
                labelId="demo-multiple-chip-labell"
                id="demo-multiple-chip-labell"
                className="w-100-percent"
                label={label}
                disabled={disabled}
                multiple
                value={selectedTags}
                onChange={(event) => handleChangeTags({ event })}
                renderValue={(selected) => (
                  <span className="capitalize">
                    {selected[selected.length - 1]}
                  </span>
                )}
                maxRows={1}
                placeholder={placeholder}
              >
                {initialTags.map((tag) => (
                  <MenuItem
                    key={tag.value}
                    value={tag.value}
                    className="capitalize"
                  >
                    {tag.value}
                  </MenuItem>
                ))}
              </Select>
              {!disableHelperText && helperText && (
                <FormHelperText>{helperText}</FormHelperText>
              )}
            </FormControl>
          </>
        );
      }}
      onChange={() => {}}
      renderTag={(renderTagProps) => {
        const { tag, key, onRemove, onClick } = renderTagProps;
        return (
          tag.selected && (
            <Chip
              className="m-r-8px m-b-8px capitalize"
              label={tag.value}
              variant="outlined"
              key={key}
              onClick={onClick}
              color="primary"
              onDelete={() => {
                handleChangeTags({ tagToBeRemoved: tag });
                onRemove(renderTagProps.key);
              }}
            />
          )
        );
      }}
    />
  );
};

export default AssignTags;
