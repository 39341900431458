export default function deepEqualAndDiff(obj1: $TSFixMe, obj2: $TSFixMe) {
  if (obj1 === obj2) return { equal: true, diff: {} };

  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 === null ||
    obj2 === null
  ) {
    return { equal: false, diff: { obj1, obj2 } };
  }

  const diffs: $TSFixMe = {};
  const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  for (const key of keys) {
    if (!deepEqualAndDiff(obj1[key], obj2[key]).equal) {
      diffs[key] = { obj1: obj1[key], obj2: obj2[key] };
    }
  }

  return {
    equal: Object.keys(diffs).length === 0,
    diff: diffs
  };
}
