import React from "react";
import useGetCredits from "../../billing/hooks/useGetCredits";
import SidebarCard from "./SidebarCard";

export function SidebarCredits() {
  const { data, isLoading } = useGetCredits();

  if (isLoading || !data) return null;
  return <SidebarCard credits={data.credits} />;
}
