import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores/store";
import { DrawerEnum } from "../toolBuilderSlice";

const useGetDrawerNodeId = () => {
  const drawer = useSelector((s: RootState) => s.toolbuilder.drawer);
  const openNodeId = drawer?.type === DrawerEnum.node ? drawer?.nodeId : "";
  return openNodeId;
};

export default useGetDrawerNodeId;
