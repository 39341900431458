import { ChipNodeHtmlTags } from "../ChipNodeHtmlTags";
import { ChipNodeComponents } from "../ChipNodeComponents";
import { ExtensionContent, ExtensionGroup } from "./configTypes";

export const SnippetExtensionConfig = {
  config: {
    name: ChipNodeComponents.SnippetChipComponent,

    // group: "block",
    group: ExtensionGroup.Inline,
    content: ExtensionContent.Inline,
    inline: true,
    draggable: true,
    selectable: true, // needed so that we don't delete it when typing things if you click the tool

    atom: true
  },
  attributes: {
    snippetId: {
      default: 0
    },
    textValue: {
      default: ""
    },
    name: {
      default: ""
    }
  },
  htmlTag: ChipNodeHtmlTags.SnippetChipComponent
};
