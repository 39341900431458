import React from "react";
import RemixButton from "../../../../../../../utilities/components/icons/RemixButton";
import { ArrowDownIcon } from "../../../../../../../globalTheme/icons/icons";
import useGetAssetId from "../../../../assets/useGetAssetId";
import useGetCurrentAssetIndex from "../../../../assets/useGetAssetIndex";
import useWorkspaceAssets from "../../../../assets/useWorkspaceAssets";
import useSetVisibleAsset from "../../../../assets/useSetVisibleAsset";

function MoveDownAnAssetButton() {
  const inputs = useWorkspaceAssets();
  const selectedAsset = useGetAssetId();
  const assetIndex = useGetCurrentAssetIndex();

  const isLastAsset = inputs[inputs.length - 1].id === selectedAsset;

  const { setVisibleAsset } = useSetVisibleAsset();

  const setSelectedAssetDown = () => {
    setVisibleAsset(inputs[assetIndex + 1].id);
  };

  return (
    <RemixButton
      icon={ArrowDownIcon}
      onClick={setSelectedAssetDown}
      providedColor={!isLastAsset ? "black" : undefined}
      disabled={isLastAsset}
    />
  );
}

export default MoveDownAnAssetButton;
