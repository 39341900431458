import { useMemo } from "react";
import {
  useAllMarketplaceAgentEntities,
  useAllMarketplaceEntities
} from "../../entities/hooks/useMarketplaceEntities/useAllMarketplaceEntities";

const useToolIsFeatured = (objectId: string) => {
  const allMarketplaceEntities = useAllMarketplaceEntities();
  const allMarketplaceAgents = useAllMarketplaceAgentEntities();
  return useMemo(() => {
    return (
      !!allMarketplaceEntities.find((entity) => entity.id === objectId) ||
      !!allMarketplaceAgents.find((agent) => agent.id === objectId)
    );
  }, [allMarketplaceEntities, objectId]);
};

export default useToolIsFeatured;
