import React from "react";
import ClippedDrawer from "../../../../../utilities/components/drawers/ClippedDrawer";
import { useSelector } from "react-redux";
import { selectStepId } from "../workflowBuilderSlice";
import useCloseStepOnClick from "./hooks/useCloseStepOnClick";
import StepSidebarInner from "./StepSidebarInner";

function StepSidebar() {
  useCloseStepOnClick();
  const stepId = useSelector(selectStepId);
  return (
    <ClippedDrawer open={!!stepId}>
      <StepSidebarInner />
    </ClippedDrawer>
  );
}

export default StepSidebar;
