import React from "react";
import SaveTooltipWrapper from "../../../../../utilities/components/toolTips/SaveTooltipWrapper";
import LoadingButton from "@mui/lab/LoadingButton";
import type { ISaveAgentButtonProps } from "@toolflow/shared";
import useFormIsDirty from "../../../../../utilities/hooks/useIsDirty";
import useHandleSaveAgentButtonClick from "./hooks/useHandleSaveAgentButtonClick";

// This either triggers opening the save dialog or saves it immediately
const SaveAgentButton = ({
  tooltip,
  size,
  className
}: Readonly<ISaveAgentButtonProps>) => {
  const { handleSave, loading } = useHandleSaveAgentButtonClick();
  const { isDirty } = useFormIsDirty();
  return (
    <SaveTooltipWrapper tooltip={tooltip}>
      <LoadingButton
        size={size}
        variant="contained"
        disabled={!isDirty}
        onClick={handleSave}
        className={className}
        loading={loading}
      >
        Save
      </LoadingButton>
    </SaveTooltipWrapper>
  );
};

export default SaveAgentButton;
