import { FormHelperText, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { DeleteIcon } from "../../../../globalTheme/icons/icons";
import { parsedClassNames } from "../../../../utilities/functions/parsedClassNames";
import styles from "./inputListComponent.module.css";
import { isArray } from "lodash";

const cx = parsedClassNames.bind(styles);

interface InputListInnerProps {
  valuesList: string[];
  disableHelperText?: boolean;
  placeholder?: string;
  handleChange: (valuesList: string[]) => void;
}

export default function InputListComponent({
  valuesList,
  handleChange,
  disableHelperText,
  placeholder
}: InputListInnerProps) {
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | null>(null);

  return (
    <Stack>
      <div>
        <TextField
          fullWidth
          size="small"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setError(null);
          }}
          placeholder={placeholder}
          onKeyDown={(e) => {
            const charAtCursor = e.key;
            if (charAtCursor === "Enter") {
              if (!value.length) {
                return;
              }

              const list = isArray(valuesList) ? valuesList : [];
              const tagsCopy = [...list];
              const newTags = value.split(",").map((item) => item.trim());
              const filteredTags = newTags.filter(
                (item) => item.length && !tagsCopy.includes(item)
              );

              tagsCopy.push(...filteredTags);
              setValue("");
              handleChange(tagsCopy);
            }
          }}
        />
        {!disableHelperText && (
          <FormHelperText error={!!error}>
            {error || "Press Enter to add. Use commas for multiple items."}
          </FormHelperText>
        )}
      </div>

      {isArray(valuesList) && !!valuesList.length && (
        <div className={cx("tags-list-container", "m-t-16px")}>
          {valuesList.map((tag, idx) => {
            return (
              <div key={tag} className={cx("tags-list-item")}>
                {tag}
                <div
                  onClick={() => {
                    const filteredTags = valuesList.filter((_, i) => i !== idx);
                    handleChange(filteredTags);
                  }}
                  className={cx(
                    "tag-delete-button",
                    "border-radius-6px flex align-i-center justify-center"
                  )}
                >
                  <DeleteIcon size={14} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Stack>
  );
}
