import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import type { TCredits } from "@toolflow/shared";
import React, { FC } from "react";
import useGetCurrentUser from "../../../../user/hooks/useGetCurrentUser";
import { useSubscriptionDetails } from "../../../../billing/hooks/useSubscriptionDetails";
import { isToolflowHireFE } from "../../../builder/toolBuilder/common/utils";
import ToolflowHireAlert from "./ToolflowHireAlert";

interface IBillingCreditUsageCardProps {
  credits: TCredits;
}
const BillingCreditUsageCard: FC<IBillingCreditUsageCardProps> = ({
  credits
}) => {
  const user = useGetCurrentUser();
  const { usedCredits, tierCredits } = credits;
  const usedCreditsString = `${Math.round(usedCredits)}`;

  const remianingCreditsString = ` / ${tierCredits} credits used`;
  const { renewalDate } = useSubscriptionDetails();
  return (
    <Box className="p-h-16px m-v-8px p-b-16px">
      <Stack direction={"row"}>
        <Typography>{usedCreditsString}</Typography>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontWeight: 500
          }}
        >
          &nbsp;{remianingCreditsString}
        </Typography>
      </Stack>

      <LinearProgress
        variant="determinate"
        value={Math.min((usedCredits / tierCredits) * 100, 100)} // value has to be between 0 and 100
        sx={{
          height: "6px",
          borderRadius: 100,
          marginTop: "8px"
        }}
      />

      <Stack direction={"row"} className="m-t-16px" spacing={1}>
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.disabled }}
        >
          Renews
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: (theme) => theme.palette.text.secondary }}
        >
          {renewalDate}
        </Typography>
      </Stack>
      {isToolflowHireFE(user) ? (
        <ToolflowHireAlert
          text="As an employee, you have unlimited credits"
          className="m-t-16px flex align-i-center"
        />
      ) : null}
    </Box>
  );
};

export default BillingCreditUsageCard;
