import DraggableToolInputField from "./DraggableToolInputField";
import DragToolInputMonitor from "./DragToolInputMonitor";
import type { CustomToolInputField } from "@toolflow/shared";

const InputList = ({
  toolInputFields
}: {
  toolInputFields: CustomToolInputField[];
}) => {
  return (
    <DragToolInputMonitor>
      {toolInputFields.map((toolInputField, index) => {
        return (
          <DraggableToolInputField
            key={toolInputField.id}
            index={index}
            toolInputField={toolInputField}
          />
        );
      })}
    </DragToolInputMonitor>
  );
};

export default InputList;
