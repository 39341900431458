import {
  EXTERNAL_AGENT_BASE_ROUTE_PATH,
  EXTERNAL_TOOL_BASE_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_BASE_ROUTE_PATH,
  EXTERNAL_WORKSPACE_BASE_ROUTE_PATH
} from "../../../features/navigation/helpers/routePaths";
import { getFEUrl } from "./getFEUrl";

const getBaseLink = (path: string, id: string) => `${getFEUrl()}${path}${id}`;

export const getTVRLink = (id: string) =>
  getBaseLink(EXTERNAL_TOOL_VERSION_RESPONSE_BASE_ROUTE_PATH, id);

export const getToolLink = (id: string) =>
  getBaseLink(EXTERNAL_TOOL_BASE_ROUTE_PATH, id);

export const getWorkspaceLink = (id: string) =>
  getBaseLink(EXTERNAL_WORKSPACE_BASE_ROUTE_PATH, id);

export const getAgentLink = (id: string) =>
  getBaseLink(EXTERNAL_AGENT_BASE_ROUTE_PATH, id);

export const getWorkflowLink = (id: string) =>
  getBaseLink(EXTERNAL_WORKFLOW_BASE_ROUTE_PATH, id);
