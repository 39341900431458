import type { TWorkspaceDataToSave, Workspace } from "@toolflow/shared";
import useFormIsDirty from "../../../../utilities/hooks/useIsDirty";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import useGetCurrentUser from "../../../user/hooks/useGetCurrentUser";
import { useFormContext } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../stores/store";
import {
  useGetWorkspaceQuery,
  useSaveWorkspaceMutation,
  workspaceApi
} from "../../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { debounce } from "lodash";
import { setWorkspaceId } from "../state/workspaceSlice";
import { globalEventEmitterFE } from "../../../../utilities/eventEmitter/globalEventEmitterFE";
import { WORKSPACE_TAG_TYPE } from "../../../../ToolflowAPI/cacheTagConstants";
import { selectCurrentUserId } from "../../../../ToolflowAPI/rtkRoutes/selectors/dynamicSelectors";
import { authenticatedApi } from "../../../../ToolflowAPI/authenticatedAPI";
import { WORKSPACE_NAME_FIELD_LABEL } from "../helpers/workspaceConstants";
import { ReadyState } from "react-use-websocket";
import { useWebsocketContext } from "../../../../ToolflowAPI/websocket/contexts/WebsocketContext";

const useUnmount = () => {
  const [saveWorkspace] = useSaveWorkspaceMutation();
  const unmountingComponent = useRef(false);
  const { getValues } = useFormContext<TWorkspaceDataToSave>();
  const workspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );
  const lastValidWorkspaceId = useRef<string | null>(null);

  useEffect(() => {
    if (workspaceId) {
      lastValidWorkspaceId.current = workspaceId;
    }
    return () => {
      unmountingComponent.current = true;
    };
  }, [workspaceId]);

  useEffect(() => {
    return () => {
      if (lastValidWorkspaceId.current && unmountingComponent.current) {
        saveWorkspace({
          workspaceId: lastValidWorkspaceId.current,
          body: getValues()
        });
      }
    };
  }, []);
};

const useCurrentUserIdRef = () => {
  const currentUserId = useSelector(selectCurrentUserId);

  const [currentUserIdState, setCurrentUserIdState] = useState(currentUserId); // eslint-disable-line
  const currentUserIdRef = useRef(currentUserId);

  useEffect(() => {
    if (currentUserId !== currentUserIdRef.current) {
      setCurrentUserIdState(currentUserId);
      currentUserIdRef.current = currentUserId;
    }
  }, [currentUserId]);

  return currentUserIdRef;
};

const useWatchWebsocketResponse = () => {
  const { getValues, reset } = useFormContext<TWorkspaceDataToSave>();
  const workspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );
  const dispatch = useDispatch();
  const { data } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
  const workspace = data?.workspace;
  const currentUserIdRef = useCurrentUserIdRef();

  useEffect(() => {
    const handleWsMessage = async (emittedString: string) => {
      const emittedMessage = JSON.parse(emittedString);
      if (emittedMessage.type === "savedNewWorkspace" && !workspaceId) {
        const newValues = {
          ...getValues(),
          [WORKSPACE_NAME_FIELD_LABEL]: emittedMessage.name
        };
        // we have to await the data being put in the cache before we can set the workspaceId
        // otherwise there is a flash because we fetch the endpoint in WorkspaceFormProvider
        await dispatch(
          workspaceApi.util.upsertQueryData(
            "getWorkspace",
            emittedMessage.workspaceId,
            {
              workspace: { ...workspace, ...newValues } as Workspace
            }
          ) as $TSFixMe
        );
        dispatch(setWorkspaceId(emittedMessage.workspaceId));
        reset(newValues);
        dispatch(
          authenticatedApi.util.invalidateTags([
            { type: WORKSPACE_TAG_TYPE, id: currentUserIdRef.current }
          ])
        );
      }
    };

    globalEventEmitterFE.on("ws_message", handleWsMessage);

    return () => {
      globalEventEmitterFE.off("ws_message", handleWsMessage);
    };
  }, [dispatch, authenticatedApi, workspaceId]);
};

/* We trigger updates to the backend when the form gets dirty,
Note that if the form is dirty, and it stays dirty, it wont trigger again. This can happen when typing into an input and they are trying
to register it on each key stroke. its better to use unmount for that reason. */
const useSendMessageOnDirtyInternal = () => {
  const {
    isDirty: toolflowDirty,
    dirtyFields,
    formStateIsDirty
  } = useFormIsDirty<TWorkspaceDataToSave>();
  const isDirty = toolflowDirty || formStateIsDirty;
  const { sendMessage, readyState } = useWebsocketContext();
  const currentUser = useGetCurrentUser();
  const { getValues, reset } = useFormContext<TWorkspaceDataToSave>();
  const workspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );
  const dispatch = useDispatch();
  const { data } = useGetWorkspaceQuery(workspaceId, { skip: !workspaceId });
  const workspace = data?.workspace;
  const [initialMessageSent, setInitialMessageSent] = useState(false);

  useEffect(() => {
    if (!workspaceId) {
      setInitialMessageSent(false);
    }
  }, [workspaceId]);

  const currentUserIdRef = useCurrentUserIdRef();
  useEffect(() => {
    const sendMessageDebounced = debounce(() => {
      if (sendMessage && currentUser) {
        const timeZone = DateTime.now().zoneName;
        const allValues = getValues();

        const propertiesToSave = Object.fromEntries(
          Object.entries(allValues).filter(([k]) => k in dirtyFields)
        );

        const message = {
          type: "saveWorkstation",
          payload: {
            userId: currentUser._id,
            propertiesToSave,
            timeZone,
            workspaceId
          }
        };

        if (workspaceId) {
          // console.log("Sending follow up message", message);
          // Send subsequent messages only if workspaceId is available
          sendMessage(JSON.stringify(message));
          reset(allValues);
          dispatch(
            workspaceApi.util.updateQueryData(
              "getWorkspace",
              workspaceId,
              () => {
                return {
                  workspace: { ...workspace, ...allValues } as Workspace
                };
              }
            ) as $TSFixMe
          );
          if (allValues.name || allValues.about) {
            dispatch(
              authenticatedApi.util.invalidateTags([
                { type: WORKSPACE_TAG_TYPE, id: currentUserIdRef.current }
              ])
            );
          }
          // we don't want to send multiple messages without a workspaceId
          // because that would create a lot of new workspaces
          // instead, we hold off until we get the workspaceId from the backend
        } else if (!initialMessageSent) {
          if (!workspaceId) {
            // console.log("Sending initial message to create workspace", message);
            // Send initial message to create workspace
            sendMessage(JSON.stringify(message));
            setInitialMessageSent(true);
          }
        }
      }
    }, 300);

    // in the future we can deal with the situation in which websockets are totally closed
    // but in the short term, this should only send if the websocket is open
    // and resend when the ready state is good

    // maybe save via API when ReadyState is not open
    if (isDirty && readyState === ReadyState.OPEN) {
      sendMessageDebounced();
    }
    return () => {
      sendMessageDebounced.cancel();
    };
  }, [isDirty, readyState]);
};

const useSendMessageOnDirty = () => {
  useSendMessageOnDirtyInternal();

  useWatchWebsocketResponse();

  useUnmount();
};

export default useSendMessageOnDirty;
