import React from "react";

import {
  WORKFLOW_GET_ASSETS_STEP,
  WORKFLOW_RUN_TOOL_STEP,
  WORKFLOW_TEXT_STEP
} from "../../../../builder/workflowBuilder/WorkflowBuilderConstants";
import WorkflowGetAssetsStep from "./steps/WorkflowGetAssetsStep";
import WorkflowRunStep from "./steps/WorkflowRunStep";
import { useWorkflowStepContext } from "../../../../../workflow/contexts/WorkflowStepContext";

function RunWorkflowStepContent() {
  const { step } = useWorkflowStepContext();
  switch (step.type) {
    case WORKFLOW_GET_ASSETS_STEP:
      return <WorkflowGetAssetsStep />;
    case WORKFLOW_RUN_TOOL_STEP:
      return <WorkflowRunStep />;
    case WORKFLOW_TEXT_STEP:
      return null;
    default:
      return null;
  }
}

export default RunWorkflowStepContent;
