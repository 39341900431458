import { IconButton } from "@mui/material";
import React from "react";
import { toolflowWhiteColor } from "../../../../../../../../globalTheme/muiUtils/appTheme";
import RemixWrapperIconWithTheme from "../../../../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { ArrowUpIcon } from "../../../../../../../../globalTheme/icons/icons";
import useSendChatMessageButton from "./hooks/useSendChatMessageButton";
import type { JSONContent } from "@tiptap/core";
import useGenerateTextFromJSON from "../../../../../../../../utilities/components/textFields/workspace/hooks/useGenerateTextFromJSON";

function SendChatMessageButton({
  userTextMessage,
  setUserTextMessage
}: {
  userTextMessage: JSONContent;
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>;
}) {
  const { handleRunAgent, currentThreadIdRef, currentAgentIdRef, disabled } =
    useSendChatMessageButton(setUserTextMessage);
  const generateTextFromJSON = useGenerateTextFromJSON();
  return (
    <IconButton
      size="small"
      onClick={() =>
        handleRunAgent({
          threadId: currentThreadIdRef?.current ?? "",
          message: generateTextFromJSON(userTextMessage),
          agentId: currentAgentIdRef?.current ?? ""
        })
      }
      className="m-l-8px primary-button"
      sx={{
        "&:disabled": {
          borderColor: (theme) => theme.palette.grey[300],
          backgroundColor: (theme) => theme.palette.grey[300]
        }
      }}
      disabled={disabled}
    >
      <RemixWrapperIconWithTheme
        Icon={ArrowUpIcon}
        size={16}
        providedColor={toolflowWhiteColor}
      />
    </IconButton>
  );
}

export default SendChatMessageButton;
