import React from "react";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import { PROFILE_NESTED_BILLING_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import SettingsMenuItemWithClose from "../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { BillingPageIcon } from "../../../../globalTheme/icons/icons";

const NavigateToBillingMenuItem = () => {
  const navigate = useToolflowNavigate();

  const navigateToBilling = () => {
    navigate(PROFILE_NESTED_BILLING_ROUTE_PATH);
  };
  return (
    <SettingsMenuItemWithClose
      icon={<BillingPageIcon size={18} />}
      text="Billing"
      action={navigateToBilling}
    />
  );
};

export default NavigateToBillingMenuItem;
