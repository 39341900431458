function arrayToObject(fields: string[]): {
  [key: string]: string | { [key: string]: string };
} {
  const obj: { [key: string]: string | { [key: string]: string } } = {};

  for (const field of fields) {
    const splitted = field.split(".");
    obj[field] = "";
    obj[splitted[0]] = { [splitted[1]]: "" };
  }

  return obj;
}

export default arrayToObject;
