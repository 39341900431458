import {
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_STEP_DESCRIPTION_FIELD_LABEL
} from "../../WorkflowBuilderConstants";
import useGetWorkflowInnerFieldById from "./useGetWorkflowInnerFieldById";

const useGetWorkflowStepDescriptionFieldById = (
  stepId: string
): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${typeof WORKFLOW_STEP_DESCRIPTION_FIELD_LABEL}` => {
  return useGetWorkflowInnerFieldById(
    stepId,
    WORKFLOW_STEP_DESCRIPTION_FIELD_LABEL
  );
};

export default useGetWorkflowStepDescriptionFieldById;
