import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  setDialogOpen,
  setDrawerExpandedOpen
} from "../../../../../store/toolBuilderSlice";
import { ArrowLeftDoubleIcon } from "../../../../../../../../../globalTheme/icons/icons";

const CloseTestPanelButton = () => {
  const dispatch = useDispatch();
  const closeTestPanel = () => {
    dispatch(setDrawerExpandedOpen(false));
    dispatch(setDialogOpen(false));
  };
  return (
    <Button
      endIcon={<ArrowLeftDoubleIcon />}
      variant="text"
      size="small"
      className="bg-color-white"
      onClick={closeTestPanel}
      disableRipple
    >
      Close test panel
    </Button>
  );
};

export default CloseTestPanelButton;
