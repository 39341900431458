import React from "react";
import Papa from "papaparse";
import { Button, IconButton } from "@mui/material";
import type { ButtonVariantTypes } from "@toolflow/shared";
import { DownloadIcon } from "../../globalTheme/icons/icons";

// Define a generic type for the component props
interface ExportCSVButtonProps<T> {
  data?: T[];
  dynamicData?: () => T[];
  fileName: string;
  className?: string;
  variant?: ButtonVariantTypes | "icon";
  name?: string;
}

const preprocessData = (data: $TSFixMe) => {
  return data.map((row: $TSFixMe) => {
    return Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        // Convert complex objects into stringified versions, if necessary
        if (typeof value === "object" && value !== null) {
          return [key, JSON.stringify(value)];
        }
        return [key, value];
      })
    );
  });
};

// Use the generic type T to define the shape of data items
const ExportCSVButton = <T,>({
  data,
  fileName,
  className = "",
  variant = "outlined",
  name = "Export to CSV",
  dynamicData
}: ExportCSVButtonProps<T>) => {
  const exportToCSV = () => {
    let csv = "";
    if (data) {
      const preprocessedData = preprocessData(data);
      csv = Papa.unparse(preprocessedData);
    } else if (dynamicData) {
      const preprocessedDynamicData = preprocessData(dynamicData());
      csv = Papa.unparse(preprocessedDynamicData);
    }
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (variant === "icon") {
    return (
      <IconButton onClick={exportToCSV} className={className}>
        <DownloadIcon size={14} />
      </IconButton>
    );
  }

  return (
    <Button variant={variant} onClick={exportToCSV} className={className}>
      {name}
    </Button>
  );
};

export default ExportCSVButton;
