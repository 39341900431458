import useWorkspaceAssets from "./useWorkspaceAssets";

const useGetWorkspaceAssetIndexById = () => {
  const inputs = useWorkspaceAssets();

  const getWorkspaceAssetIndexById = (id: string) => {
    return inputs.findIndex((input) => input.id === id);
  };
  return getWorkspaceAssetIndexById;
};

export default useGetWorkspaceAssetIndexById;
