import type { StreamlinedWorkspace } from "@toolflow/shared";
import { WorkspaceTableData, HeadCell } from "../utlils/tableTypes";
import { createWorkspaceData } from "../utlils/tableUtils";

const useWorkspaceTableData = (workspaces: StreamlinedWorkspace[]) => {
  const rows: WorkspaceTableData[] = [
    ...workspaces.map((workspace) =>
      createWorkspaceData(
        workspace._id,
        workspace.name,
        workspace.updatedAt,
        workspace.visibility,
        "Actions"
      )
    )
  ];
  const headCells: readonly HeadCell[] = [
    {
      id: "name",
      align: "left",
      disablePadding: true,
      label: "Name",
      width: "40%",
      sortable: true
    },
    {
      id: "date",
      align: "left",
      disablePadding: false,
      label: "Date",
      width: "30%",
      sortable: true
    },
    {
      id: "actions",
      align: "right",
      disablePadding: false,
      label: "Actions",
      sortable: false
    }
  ];
  return {
    headCells,
    rows
  };
};

export default useWorkspaceTableData;
