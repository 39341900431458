import { DeepgramModel } from "@toolflow/shared";
import {
  baseModelLanguages,
  enhancedModelLanguages,
  nova2Languages,
  novaLanguages,
  whisperMediumLanguages
} from "./dgSupportedLanguages";

export const deepgramModels: { label: string; value: DeepgramModel }[] = [
  {
    label: "Nova 2",
    value: DeepgramModel.Nova2
  },
  {
    label: "Nova",
    value: DeepgramModel.Nova
  },
  {
    label: "Base",
    value: DeepgramModel.Base
  },
  {
    label: "Enhanced",
    value: DeepgramModel.Enhanced
  },
  {
    label: "Whisper",
    value: DeepgramModel.WhisperMedium
  }
];

export const deepgramLanguages: {
  [key: string]: { label: string; value: string }[];
} = {
  [DeepgramModel.Nova2]: nova2Languages,
  [DeepgramModel.Nova]: novaLanguages,
  [DeepgramModel.Base]: baseModelLanguages,
  [DeepgramModel.Enhanced]: enhancedModelLanguages,
  [DeepgramModel.WhisperMedium]: whisperMediumLanguages
};
