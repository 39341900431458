import { useCallback } from "react";
import type { TBlock } from "@toolflow/shared";
import useCloseDrawer from "../../store/hooks/useCloseDrawer";

const useHandleConfirm = ({
  onNodesDelete,
  blocksToDelete,
  setIsConfirmationDialogOpen,
  setBlocksToDelete,
  handleNodeDelete
}: {
  onNodesDelete: (deleted: TBlock[]) => void;
  blocksToDelete: TBlock[];
  setIsConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setBlocksToDelete: React.Dispatch<React.SetStateAction<TBlock[]>>;
  handleNodeDelete: (id: string) => void;
}) => {
  const closeDrawer = useCloseDrawer();
  return useCallback(() => {
    closeDrawer();
    onNodesDelete(blocksToDelete); // manage edges before deleting blocks
    blocksToDelete.forEach((block) => handleNodeDelete(block.id));
    setIsConfirmationDialogOpen(false);
    setBlocksToDelete([]);
  }, [onNodesDelete, blocksToDelete]);
};

export default useHandleConfirm;
