import React from "react";
import { InputsToCollectFormProvider } from "./InputsToCollectContext";
import CustomToolInputFieldWorkflowProvider from "./CustomToolInputFieldWorkflowProvider";

function GetAssetsSettings() {
  return (
    <InputsToCollectFormProvider>
      <CustomToolInputFieldWorkflowProvider />
    </InputsToCollectFormProvider>
  );
}

export default GetAssetsSettings;
