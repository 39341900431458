import React from "react";
import type { ButtonSizeTypes } from "@toolflow/shared";
import useFavoriteObject from "./hooks/useFavoriteObject";
import IconButtonWithBorder from "../buttons/IconButtonWithBorder";
import { StarIcon } from "../../../globalTheme/icons/icons";
import { IconButton } from "@mui/material";

function UnfavoritedObject({
  className = "",
  iconButtonSize = "small",
  size = 20,

  disableBorder = false
}: {
  className?: string;
  iconButtonSize?: ButtonSizeTypes;
  size?: number;
  disableBorder?: boolean;
}) {
  const favorite = useFavoriteObject();
  const Component = disableBorder ? IconButton : IconButtonWithBorder;

  return (
    <Component onClick={favorite} className={className} size={iconButtonSize}>
      <StarIcon size={size} color="#12172961" />
    </Component>
  );
}

export default UnfavoritedObject;
