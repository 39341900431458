import { Stack } from "@mui/material";
import {
  BlockNode,
  type PerplexityBlockData,
  type PerplexityBlockPropsV2
} from "@toolflow/shared";
import { uniq } from "lodash";
import { useMemo } from "react";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import TestToolRun from "../../../../common/TestToolRun";
import TestOutput from "../../../components/testWrapper/TestOutput";
import useTest from "../../../hooks/useTest";
import useTestInputFromFieldsByType from "../../../hooks/useTestInputFromFieldsByType";
import usePromptFiller from "../../../promptBlocks/usePromptFiller";
import TestFieldsWithPrompt from "../../../components/testWrapper/TestFieldsWithPrompt";
import usePerplexityFieldConfigs from "./usePerplexityFieldConfigs";

export default function TestPerplexityV2({
  data,
  id
}: Omit<PerplexityBlockPropsV2, "selected">) {
  const getChipsList = useGetChips();
  const fieldsByType = usePerplexityFieldConfigs(id);
  const prompt = data.settings.input;

  const promptFieldsToTest = useMemo(() => getChipsList(prompt), [prompt]);

  const { userInput, setUserInput, fields } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings, data.optimizations]
  );

  const combinedFields = uniq([...promptFieldsToTest, ...fields]);

  const { action, label, text, endIcon, showUpgrade, loading, output } =
    useTest<PerplexityBlockData>({
      data,
      id,
      blockType: BlockNode.PerplexityBlockNode,
      fields: combinedFields,
      userInput
    });

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(prompt, userInput),
    [userInput, prompt, insertUserInputIntoPrompt]
  );

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFieldsWithPrompt
            fields={combinedFields}
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
          />
          <TestOutput output={output} label={label} />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}
