import React from "react";
import PoweredByToolflow from "./PoweredByToolflow";
import LoginButton from "../../auth/LoginButton";
import { useAuthStore } from "../../auth/hooks/useAuthStore";

function LoggedOutCTA() {
  const { isAuthenticated, isLoading } = useAuthStore();
  if (isAuthenticated || isLoading) return null;
  return (
    <>
      <PoweredByToolflow className="m-r-8px" />
      <LoginButton variant="outlined" size="small" />
    </>
  );
}
export default LoggedOutCTA;
