import { parsedClassNames } from "../../../../../../../../../utilities/functions/parsedClassNames";
import styles from "./clickableInput.module.css";
import { Paper, Typography } from "@mui/material";
import { ElementType } from "react";

const cx = parsedClassNames.bind(styles);

const ClickableInputOutput = ({
  icon,
  name,
  id,
  onClick,
  disabled,
  SecondaryAction,
  className
}: {
  icon: React.ReactNode;
  name: string;
  id: string;
  onClick?: (id: string) => void;
  disabled?: boolean;
  SecondaryAction?: ElementType;
  className?: string;
}) => {
  const handleClick = () => {
    if (onClick) {
      onClick(id);
    }
  };
  return (
    <Paper
      variant="outlined"
      onClick={handleClick}
      className={cx(
        "pos-relative w-100-percent parent-hover flex align-i-center",
        styles["input-field-container"],
        {
          [styles["clickable-input-field-container"]]: onClick,
          "cursor-pointer": onClick
        },
        className
      )}
    >
      <div className="m-r-4px">{icon}</div>
      <Typography variant="body1">{name}</Typography>
      {SecondaryAction && (
        <SecondaryAction
          disabled={disabled}
          className={cx(
            "pos-absolute show-on-hover m-r-12px",
            styles["delete-button"]
          )}
        />
      )}
    </Paper>
  );
};

export default ClickableInputOutput;
