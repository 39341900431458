import { Typography } from "@mui/material";
import React from "react";

const EmptyRowsFallback = () => {
  return (
    <Typography align="center" className="p-16px">
      Select a tool to run in bulk
    </Typography>
  );
};

export default EmptyRowsFallback;
