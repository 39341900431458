import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import useSetActiveStep from "./hooks/useSetActiveStep";
import useClearWorkflowProgress from "./hooks/useClearWorkflowProgress";
import HandlePreviousButton from "./steps/HandlePreviousButton";

function RunWorkflowFinish() {
  const setActiveStep = useSetActiveStep();
  const clearWorkflowProgress = useClearWorkflowProgress();
  const handleReset = () => {
    clearWorkflowProgress();
    setActiveStep(0);
  };
  return (
    <Paper square elevation={0} sx={{ p: 3 }}>
      <Typography>All steps completed - you&apos;re finished</Typography>
      <div className="flex align-i-center justify-space-between">
        <HandlePreviousButton sx={{ mt: 1, mr: 1 }} />

        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
          Reset
        </Button>
      </div>
    </Paper>
  );
}

export default RunWorkflowFinish;
