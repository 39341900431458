import type { CustomToolInputField } from "@toolflow/shared";
import { isInputWithOptions } from "../../../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";
import { useToolbuilderContext } from "../../../../../../context/ToolBuilderContext";

const useChangeOptionNameToolbuilder = () => {
  const { state } = useToolbuilderContext();
  const { toolInputFields } = state.currentState;
  const { dispatch } = useToolbuilderContext();

  const setToolInputFields = (
    updatedToolInputFields: CustomToolInputField[]
  ) => {
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };

  const handleOptionChange = (
    toolInputField: CustomToolInputField | null,
    optionIndex: number,
    value: string,
    index: number
  ) => {
    if (!isInputWithOptions(toolInputField)) {
      return;
    }
    const options = toolInputField.options || [];
    const parameterValue = options[optionIndex];
    options[optionIndex] = value;
    const updatedToolInputField = {
      ...toolInputField,
      options
    };
    const updatedToolInputFields = [...toolInputFields];
    updatedToolInputFields[index] = updatedToolInputField;
    dispatch({
      type: "REPLACE_LOGIC_OPTION",
      input: toolInputField?.name || "",
      newValue: value,
      parameterValue
    });
    setToolInputFields(updatedToolInputFields);
  };

  return handleOptionChange;
};

export default useChangeOptionNameToolbuilder;
