import React, { useState } from "react";
import type { VisibilityTypes } from "@toolflow/shared";
import SettingsMenuItem from "../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { ShareDialogBase } from "../../../../../utilities/components/sharing/ShareDialog";
import { UpdateVisibilitySelectBase } from "../../../../../utilities/components/sharing/updateVisibilitySelect/UpdateVisibilitySelectBase";
import { useSettingsDropdownContext } from "../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import { IconButton, type SelectChangeEvent } from "@mui/material";
import { useUpdateWorkspaceMutation } from "../../../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { toolflowTextDisabledColor } from "../../../../../globalTheme/muiUtils/appTheme";
import { DarkUtilityTooltip } from "../../../../../utilities/components/toolTips/DarkUtilityTooltip";
import { ShareIcon } from "../../../../../globalTheme/icons/icons";

function UpdateWorkspaceVisibilityMenuItem({
  id,
  visibility,
  name,
  iconOnly = false
}: {
  id: string;
  visibility: VisibilityTypes;
  name: string;
  iconOnly?: boolean;
}) {
  const { handleClose } = useSettingsDropdownContext();
  const [updateWorkspace, { isLoading }] = useUpdateWorkspaceMutation();

  const handlePrivacy = (e: SelectChangeEvent<VisibilityTypes>) => {
    updateWorkspace({
      body: { visibility: e.target.value as VisibilityTypes },
      workspaceId: id
    });
    e.stopPropagation();
    e.preventDefault();
  };

  const [open, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const close = (e: React.SyntheticEvent<Element, Event>) => {
    e.stopPropagation();
    handleClose(e);
    setOpen(false);
  };

  return !iconOnly ? (
    <>
      <SettingsMenuItem
        disabled={isLoading}
        action={handleOpen}
        text={"Share"}
        icon={<ShareIcon size={16} color={toolflowTextDisabledColor} />}
        hide={!id}
      />
      <ShareDialogBase
        id={id}
        open={open}
        closeDialog={close}
        loading={isLoading}
        name={name}
        type="workspace"
      >
        <UpdateVisibilitySelectBase
          handleSelectChange={handlePrivacy}
          visibility={visibility}
          formProps={{ fullWidth: true }}
        />
      </ShareDialogBase>
    </>
  ) : (
    <DarkUtilityTooltip title="Share">
      <IconButton onClick={handleOpen}>
        <ShareIcon color={toolflowTextDisabledColor} size={16} />
        <ShareDialogBase
          id={id}
          open={open}
          closeDialog={close}
          loading={isLoading}
          name={name}
          type="workspace"
        >
          <UpdateVisibilitySelectBase
            handleSelectChange={handlePrivacy}
            visibility={visibility}
            formProps={{ fullWidth: true }}
          />
        </ShareDialogBase>
      </IconButton>
    </DarkUtilityTooltip>
  );
}

export default UpdateWorkspaceVisibilityMenuItem;
