import { MenuItem } from "@mui/material";
import { VisibilityTypes } from "@toolflow/shared";
import React from "react";
const visibilityMenuItemsArray = [
  <MenuItem value={VisibilityTypes.PRIVATE} key={VisibilityTypes.PRIVATE}>
    Private - Only you can use
  </MenuItem>,
  <MenuItem value={VisibilityTypes.RESTRICTED} key={VisibilityTypes.RESTRICTED}>
    Restricted - Anyone with the link can use
  </MenuItem>,
  <MenuItem value={VisibilityTypes.PUBLIC} key={VisibilityTypes.PUBLIC}>
    Public - On your profile
  </MenuItem>
];

export default visibilityMenuItemsArray;
