import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { THomeEntityBlockType, THomeTabType } from "@toolflow/shared";

interface IHomeInitialStateBase {
  tab: THomeTabType;
  toolsBlockTab: THomeEntityBlockType;
}

const initialState: IHomeInitialStateBase = {
  tab: "workspace",
  toolsBlockTab: "frequently-used"
};

export type THomeReducerName = "home";
export const HOME_REDUCER_NAME: THomeReducerName = "home";

const homePageSlice = createSlice({
  name: HOME_REDUCER_NAME,
  initialState,
  reducers: {
    updateHomeTab(state, action: PayloadAction<THomeTabType>) {
      state.tab = action.payload;
    },
    updateToolsBlockTab(state, action: PayloadAction<THomeEntityBlockType>) {
      state.toolsBlockTab = action.payload;
    }
  }
});

export const { updateHomeTab, updateToolsBlockTab } = homePageSlice.actions;

export default homePageSlice.reducer;
