import { TableCell, TableRow } from "@mui/material";
import React, { MouseEventHandler } from "react";
import { BoldTableCell } from "../../home/layout/TableLayouts";

function NameAndActionsRow({
  name,
  actions,
  onClick,
  className
}: {
  name: string;
  actions: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  className?: string;
}) {
  const handleClick: MouseEventHandler<HTMLTableRowElement> = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <TableRow onClick={handleClick} className={className}>
      <BoldTableCell className="max-w-600px line-h-24px">{name}</BoldTableCell>
      <TableCell align="right" className="p-v-0px">
        {actions}
      </TableCell>
    </TableRow>
  );
}

export default NameAndActionsRow;
