import { useLocation, useParams } from "react-router-dom";
import { useAuthStore } from "../../features/auth/hooks/useAuthStore";
import { useGetValidateQuery } from "../../ToolflowAPI/rtkRoutes/validateApi";
import {
  externalAgentMatcher,
  externalToolMatcher,
  externalWorkflowMatcher,
  tvrMatcher
} from "../../features/navigation/functions/matchers";
import {
  AGENT,
  EntityType,
  type ValidateTypeWithAgent
} from "@toolflow/shared";

const useValidateObject = () => {
  const { isLoading } = useAuthStore();
  const { externalId = "" } = useParams();
  const location = useLocation();
  const pathname = location.pathname;
  let type: ValidateTypeWithAgent = "workspace";

  if (externalToolMatcher(pathname)) {
    type = EntityType.TOOL;
  } else if (tvrMatcher(pathname)) {
    type = "toolVersionResponse";
  } else if (externalWorkflowMatcher(pathname)) {
    type = EntityType.WORKFLOW;
  } else if (externalAgentMatcher(pathname)) {
    type = AGENT;
  }

  const methods = useGetValidateQuery(
    { id: externalId, type },
    { skip: isLoading || !externalId }
  );
  return { ...methods, type, externalId };
};

export default useValidateObject;
