import { setInitialObject, setWorkspaceId } from "../state/workspaceSlice";
import { useDispatch } from "react-redux";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";
import type { ValidateTypeWithAgent } from "@toolflow/shared";
import {
  getAgentLink,
  getTVRLink,
  getToolLink,
  getWorkflowLink,
  getWorkspaceLink
} from "../../../../utilities/formatters/strings/links";
import { setSidebarClose } from "../../../layout/sidebar/sidebarSlice";
// import { getPublicToolUrl, getPublicWorkspaceUrl, getTVRUrl } fro

const alternativeUrlDict = {
  workspace: getWorkspaceLink,
  tool: getToolLink,
  workflow: getWorkflowLink,
  toolVersionResponse: getTVRLink,
  agent: getAgentLink
};

const useNavigateToWorkspaceWithDefaultObject = ({
  id,
  type
}: {
  id: string;
  type: ValidateTypeWithAgent;
}) => {
  const navigate = useToolflowNavigate();
  const dispatch = useDispatch();

  const navigateToWorkspace = (e?: React.SyntheticEvent<Element, Event>) => {
    if (type === "workspace") {
      dispatch(setWorkspaceId(id));
    } else {
      dispatch(setWorkspaceId(""));
      dispatch(setInitialObject({ type, id }));
    }
    navigate("/", e, {
      // replace: true,
      alternativeUrl: alternativeUrlDict[type](id)
    });
    dispatch(setSidebarClose());
  };

  return navigateToWorkspace;
};

export default useNavigateToWorkspaceWithDefaultObject;
