import React from "react";
import { useThreadMessageContext } from "../../../contexts/ThreadMessageContext";
import AgentMessage from "./AgentMessage";
import UserMessage from "./UserMessage";
import { EMessageRole } from "@toolflow/shared";

const ThreadMessage = () => {
  const { threadMessage } = useThreadMessageContext();
  const { role, content } = threadMessage;
  const isUserMessage = role === EMessageRole.USER;

  if (!content) return null;

  if (isUserMessage) {
    return <UserMessage messageContent={content} />;
  }
  return <AgentMessage message={threadMessage} />;
};

export default ThreadMessage;
