import React from "react";
import MainArea from "../layout/MainArea";
import LoggedOut from "../pages/external/LoggedOut";
import useRouteAuthentication from "./hooks/useRouteAuthentication";

function LoggedOutWrapper() {
  const shouldRenderMainArea = useRouteAuthentication();

  return <>{shouldRenderMainArea ? <MainArea /> : <LoggedOut />}</>;
}

export default LoggedOutWrapper;
