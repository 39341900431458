import { useEffect, useRef, useState } from "react";

export const useInfiniteScrollHeight = () => {
  const [scrollHeight, setScrollHeight] = useState<string>(
    "calc(100vh - 200px)"
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const calculateScrollHeight = () => {
      if (scrollRef.current) {
        const offsetTop = scrollRef.current.offsetTop;
        setScrollHeight(`calc(100vh - ${offsetTop}px)`);
      }
    };

    calculateScrollHeight();
    window.addEventListener("resize", calculateScrollHeight);

    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, []);

  return { scrollHeight, scrollRef };
};
