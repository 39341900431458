import type {
  FileReference,
  TSetFormState,
  UserInputDictType
} from "@toolflow/shared";

const useCurrentFileFromFormState = ({
  formState,
  setFormState,
  useIds,
  id = "",
  name
}: {
  formState: UserInputDictType;
  setFormState: TSetFormState;
  useIds?: boolean;
  id?: string;
  name: string;
}) => {
  const formStateKey = useIds ? id : name;
  const currentFile = formState[formStateKey] as FileReference | null;
  const deleteData = () => {
    const newFormState = { ...formState };
    delete newFormState[formStateKey];
    setFormState(newFormState);
  };
  const setCurrentFile = (fileToSave: FileReference | null) => {
    // save is disabled if fileToSave doesn't exist
    if (fileToSave) {
      setFormState({ ...formState, [formStateKey]: { ...fileToSave } });
    } else {
      deleteData();
    }
  };
  return { currentFile, setCurrentFile };
};

export default useCurrentFileFromFormState;
