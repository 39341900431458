import AddInputButton from "../AddInputButton";
import { INPUT_TEXT } from "../helpers/constants";
import EmptyBase from "./EmptyBase";
import EmptyInputIcon from "./EmptyInputIcon";

function EmptyInputs() {
  return (
    <EmptyBase
      title="Inputs"
      firstText={INPUT_TEXT}
      secondText="💡 You can also create inputs directly inside node. When you do, they will be automatically shown here."
      button={<AddInputButton />}
      icon={<EmptyInputIcon />}
    />
  );
}

export default EmptyInputs;
