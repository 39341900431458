import { Editor } from "@tiptap/react";

const useHandleTextFieldKeyDown = () => {
  const handleKeyDown = (event: KeyboardEvent, editor?: Editor) => {
    if (editor) {
      if (event.shiftKey === false) {
        const target = event.target as HTMLInputElement;
        const focusableElements =
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
        // Get all focusable elements
        const allFocusableElements = Array.from(
          document.querySelectorAll(focusableElements)
        ) as HTMLElement[];

        // Find the index of the current element
        const currentIndex = allFocusableElements.indexOf(target);

        // Find the next focusable element
        const nextFocusableElement =
          allFocusableElements[currentIndex + 1] || allFocusableElements[0];

        if (nextFocusableElement) {
          nextFocusableElement.focus();
        }
      }
    }
  };
  return handleKeyDown;
};

export default useHandleTextFieldKeyDown;
