import type { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";
import invalidateTags from "./invalidateTags";
import { TOOL_DETAIL_TAG_TYPE, TOOL_TAG_TYPE } from "../../cacheTagConstants";
import { authenticatedApi } from "../../authenticatedAPI";

const invalidateToolTags = (
  dispatch: ThunkDispatch<$TSAllowedAny, $TSAllowedAny, UnknownAction>,
  state: RootState,
  toolId?: string
) => {
  invalidateTags(dispatch, state, TOOL_TAG_TYPE);
  if (toolId) {
    dispatch(
      authenticatedApi.util.invalidateTags([
        {
          type: TOOL_DETAIL_TAG_TYPE,
          id: toolId
        }
      ])
    );
  }
};

export default invalidateToolTags;
