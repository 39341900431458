import React from "react";
import PromptEditor from "./PromptEditor";
import useHandleUpdatePrompt from "./hooks/useHandleUpdatePrompt";

function PromptEditorContainer({
  id,
  prompt,
  maxLength,
  helperText
}: {
  id: string;
  prompt: string;
  maxLength?: number;
  helperText?: string;
}) {
  const handleUpdatePrompt = useHandleUpdatePrompt(id);
  return (
    <PromptEditor
      id={id}
      prompt={prompt}
      helperText={helperText}
      maxLength={maxLength}
      handleUpdatePrompt={handleUpdatePrompt}
    />
  );
}

export default PromptEditorContainer;
