import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useBlockContext } from "../../BlockContext";
import { InfoIcon } from "../../../../../../globalTheme/icons/icons";
import { toolflowTextSecondaryColor } from "../../../../../../globalTheme/muiUtils/appTheme";

function BlockAlert() {
  const { blockData } = useBlockContext();
  // this stops the resize observer error
  // https://chat.openai.com/share/fa100126-28a7-4b08-9749-b2036db1f3ea
  const [shouldRenderAlert, setShouldRenderAlert] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShouldRenderAlert(true), 0);
    return () => clearTimeout(timer);
  }, []);
  const { blockMessage } = blockData;
  if (!shouldRenderAlert) return null;
  if (!blockMessage) return null;

  return (
    <div className="flex align-i-center p-v-4px p-h-8px">
      <div className="flex align-i-center justify-center">
        <InfoIcon
          size={13}
          color={toolflowTextSecondaryColor}
          className="m-r-4px"
        />
      </div>
      <Typography
        variant="chipSmall"
        className="text-wrap"
        color="text.secondary"
      >
        {blockMessage.message}
      </Typography>
    </div>
  );
}

export default BlockAlert;
