import { useDispatch } from "react-redux";
import { useStepsFieldArrayContext } from "../StepsContext";
import { getWorkflowDefaultStep } from "../../WorkflowBuilderConstants";
import { updateStepId } from "../../workflowBuilderSlice";
import useGetSteps from "./useGetSteps";

const useAddStep = () => {
  const { append } = useStepsFieldArrayContext();
  const dispatch = useDispatch();
  const steps = useGetSteps();
  const addStep = () => {
    const defaultStep = getWorkflowDefaultStep(steps?.length);
    append(defaultStep);
    dispatch(updateStepId(defaultStep.id));
  };
  return addStep;
};

export default useAddStep;
