import ButtonLinkToBilling from "./ButtonLinkToBilling";
import { ReactNode } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";

const TestToolRun = ({
  action,
  endIcon,
  text,
  showUpgrade,
  disabled,
  loading
}: {
  action: () => void;
  endIcon?: ReactNode;
  text: string;
  showUpgrade?: boolean;
  disabled?: boolean;
  loading?: boolean;
}) => {
  return (
    <div className="flex align-i-center">
      {showUpgrade && <ButtonLinkToBilling />}
      {loading && <CircularProgress size={16} className="m-r-8px" />}
      <LoadingButton
        onClick={action}
        variant="contained"
        size="small"
        endIcon={endIcon}
        disableRipple
        disabled={disabled}
      >
        {text}
      </LoadingButton>
    </div>
  );
};

export default TestToolRun;
