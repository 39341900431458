import React from "react";
import { MenuItem, ListItemIcon, ListItemText } from "@mui/material";
import { ISettingsMenuItemProps } from "./base/settingsTypes";
import useHandleHotkeys from "../../../hooks/useHandleHotkeys";

function SettingsMenuItem({
  action = () => {},
  icon,
  text,
  disabled,
  hide,
  hotkey,
  textColor
}: ISettingsMenuItemProps) {
  useHandleHotkeys([{ action, hotkey }]);
  if (hide) return null;

  return (
    <MenuItem onClick={action} disabled={disabled} className="flex">
      <ListItemIcon>{icon}</ListItemIcon>

      <ListItemText
        className="flex-grow-1"
        primaryTypographyProps={{
          fontSize: 14,
          color: textColor
        }}
      >
        {text}
      </ListItemText>
      {hotkey && (
        <ListItemText
          primaryTypographyProps={{ variant: "caption", color: "GrayText" }}
          primary={hotkey.replace("Meta", "⌘")}
          color="GrayText"
          className="flex-grow-0 m-l-16px"
        />
      )}
    </MenuItem>
  );
}

export default SettingsMenuItem;
