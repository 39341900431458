import {
  type Asset,
  type UserInputDictType,
  type SanitizedUserType,
  AssetSourceType,
  type ToolAsset,
  type TWorkspaceDataToSave
} from "@toolflow/shared";
import type { UseFormSetValue } from "react-hook-form";
import { WORKSPACE_ASSETS_FIELD_LABEL } from "../../helpers/workspaceConstants";
import { getValueTypeByAssetValue } from "../../../../tools/components/editorToolCard/inputs/helpers/outputFieldHelpers";

export function setAssets(
  savedAssets: Asset[],
  componentId: string,
  output: UserInputDictType,
  toolId: string,
  setValue: UseFormSetValue<TWorkspaceDataToSave>,
  tvrId: string,
  creator: SanitizedUserType,
  userInput: UserInputDictType
) {
  savedAssets.map((asset, idx) => {
    // if its not the source identifier, return the old version
    // this keeps the order of the assets static
    if (asset.sourceIdentifier !== componentId) {
      return asset;
    }

    const outputKey = asset.data?.outputId || "";
    const tempValue = output[outputKey];
    const { value, valueType } = getValueTypeByAssetValue(tempValue);

    const existingToolCounts = savedAssets.filter((i) => {
      if (!i.data) {
        return false;
      } else {
        return i.data.toolId === toolId && i.sourceIdentifier !== componentId;
      }
    }).length;

    // can't use update because dirty has to be false so we
    // don't overload the backend
    // we  queueMicrotask, because the asset changes come in rapid succession
    // and sometimes the last asset doesn't get set
    queueMicrotask(() => {
      setValue(
        `${WORKSPACE_ASSETS_FIELD_LABEL}.${idx}`,
        {
          ...asset,
          loading: true,
          source: AssetSourceType.ToolOutput,
          data: {
            toolId,
            tvrId,
            creatorId: creator._id,
            userInput,
            outputId: asset.data?.outputId,
            toolCount: existingToolCounts
          },
          value,
          valueType
        } as ToolAsset,
        { shouldValidate: false, shouldDirty: false, shouldTouch: false }
      );
    });
  });
}
