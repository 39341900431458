import { Icon } from "@mui/material";
import React from "react";

function ToolflowLogoBase({
  className = "",
  height = 24,
  icon
}: {
  height?: number;
  className?: string;
  icon: string;
}) {
  return (
    <div className={`flex align-i-center justify-center ${className}`}>
      <Icon className="flex h-100-percent w-100-percent">
        <img src={icon} height={height} />
      </Icon>
    </div>
  );
}

export default ToolflowLogoBase;
