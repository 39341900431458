import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useId } from "react";
import type { SelectFieldV1Props } from "@toolflow/shared";
import useFieldsByTypeHelper from "../../../useFieldsByTypeHelper";
import { useFieldsByTypeContext } from "../../../FieldsByTypeContext";

interface SelectFieldProps {
  value: string;
  fieldKey: string;
  label: string;
  options: string[];
}

function SelectFieldInner({
  value,
  fieldKey,
  options,
  label
}: SelectFieldProps) {
  const fieldId = useId();
  const { updateField } = useFieldsByTypeContext();

  return (
    <FormControl margin="normal" fullWidth>
      <InputLabel id={fieldId}>{label}</InputLabel>
      <Select
        labelId={fieldId}
        id={fieldKey}
        value={value}
        fullWidth
        label={label}
        onChange={(event) =>
          updateField(event.target.value, `settings.${fieldKey}`)
        }
        className="capitalize nowheel nodrag nopan"
      >
        {options.map((option) => (
          <MenuItem value={option} key={option} className="capitalize">
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export function SelectFieldV1({ field, settings }: SelectFieldV1Props) {
  const { fieldKey, options } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });

  return (
    <SelectFieldInner
      label={field.label}
      fieldKey={fieldKey}
      options={options}
      value={getFieldValue(fieldKey, "")}
    />
  );
}
