import {
  type CustomToolOutputField,
  type ExternalToolFE,
  type ToolAsset
} from "@toolflow/shared";
import useCurrentAssetsRef from "../../../../pages/workstation/assets/useCurrentAssetsRef";
import useReplaceAssetArray from "./useReplaceAssetArray";
import { getUpdatedAssets } from "../../../../pages/workstation/assets/functions/getUpdatedAssets";

const useAddToolAssetsToWorkspace = () => {
  const currentAssets = useCurrentAssetsRef();
  const replaceAssetArray = useReplaceAssetArray();

  const addToolInputsToWorkspace = ({
    componentId,
    tool,
    addInputsCallback
  }: {
    componentId: string;
    tool: ExternalToolFE;
    addInputsCallback?: (
      id: string,
      outputField: CustomToolOutputField
    ) => void;
  }) => {
    const { _id: toolId, main, creator } = tool;
    const { toolOutputFields } = main;
    const oldInputs = currentAssets.current.filter(
      (i) => i.sourceIdentifier !== componentId
    );
    const savedInputs = currentAssets.current.filter(
      (i) => i.sourceIdentifier === componentId
    );

    const updatedInputs: ToolAsset[] = getUpdatedAssets(
      toolOutputFields,
      savedInputs,
      componentId,
      addInputsCallback,
      toolId,
      creator
    );
    const replacedArray = [...oldInputs, ...updatedInputs];
    replaceAssetArray(replacedArray);
  };
  return addToolInputsToWorkspace;
};

export default useAddToolAssetsToWorkspace;
