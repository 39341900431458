import React from "react";
import useWorkflowBlocker from "./hooks/useWorkflowBlocker";
import ResetWorkflowButton from "./ResetWorkflowButton";
import SaveWorkflowVersionDialog from "./SaveWorkflowVersionDialog/SaveWorkflowVersionDialog";
import NavigationProviderBlocker from "../../../navigation/components/NavigationProviderBlocker";
import { EntityType } from "@toolflow/shared";

function WorkflowNavigationProvider({
  children
}: {
  children: React.ReactNode;
}) {
  const blocker = useWorkflowBlocker();

  return (
    <NavigationProviderBlocker
      blocker={blocker}
      type={EntityType.WORKFLOW}
      actions={
        <>
          <ResetWorkflowButton />
          <SaveWorkflowVersionDialog />
        </>
      }
    >
      {children}
    </NavigationProviderBlocker>
  );
}
export default WorkflowNavigationProvider;
