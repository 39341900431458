import type { JSONContent } from "@tiptap/core";
import useConvertContentToJSON from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useConvertContentToJSON";

const useHandleChatMessageChange = (
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>
) => {
  const convertContentToJSON = useConvertContentToJSON();
  const handleChange = (value: string | JSONContent) => {
    const jsonValue = convertContentToJSON(value);
    if (!jsonValue) return;
    setUserTextMessage(jsonValue);
  };
  return handleChange;
};

export default useHandleChatMessageChange;
