import {
  WORKFLOW_STEPS_FIELD_LABEL,
  WORKFLOW_STEP_MAPPED_INPUTS_FIELD_LABEL
} from "../../../WorkflowBuilderConstants";
import useGetSelectedStep from "../../hooks/useGetSelectedStep";
import useGetWorkflowInnerFieldById from "../../hooks/useGetWorkflowInnerFieldById";

const useGetSelectedStepMappedInputsField =
  (): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}.${typeof WORKFLOW_STEP_MAPPED_INPUTS_FIELD_LABEL}` => {
    const step = useGetSelectedStep();
    return useGetWorkflowInnerFieldById(
      step?.id || "",
      WORKFLOW_STEP_MAPPED_INPUTS_FIELD_LABEL
    );
  };

export default useGetSelectedStepMappedInputsField;
