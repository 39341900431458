import { useDispatch } from "react-redux";
import { setSidebarClose } from "../../layout/sidebar/sidebarSlice";
import { TOOLBUILDER_BASE_ROUTE_PATH } from "../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import useCloseDrawer from "../../pages/builder/toolBuilder/store/hooks/useCloseDrawer";

const useNavigateToToolBuilder = () => {
  const navigate = useToolflowNavigate();
  const dispatch = useDispatch();
  const closeDrawer = useCloseDrawer();
  const handleNavigate = (toolId: string) => (e?: React.MouseEvent) => {
    dispatch(setSidebarClose());
    closeDrawer();
    e?.stopPropagation();
    navigate(`${TOOLBUILDER_BASE_ROUTE_PATH}${toolId}`, e);
  };
  return handleNavigate;
};

export default useNavigateToToolBuilder;
