import { createSelector } from "@reduxjs/toolkit";
import { workflowsApi } from "../workflowApi";
import {
  workflowAdapter,
  workflowInitialState,
  workflowVersionAdapter,
  workflowVersionInitialState
} from "../../../stores/adapters/workflowAdapter";
import { RootState } from "../../../stores/store";

const selectProfileWorkflowsResults = (profileId: string) =>
  workflowsApi.endpoints.getProfileWorkflows.select(profileId);

const selectProfileWorkflowData = (profileId: string) =>
  createSelector(
    selectProfileWorkflowsResults(profileId),
    (profileWorkflowResult) =>
      profileWorkflowResult.data ?? workflowInitialState
  );

export const selectAllProfileWorkflows = (profileId: string) =>
  createSelector(
    (state: RootState) => selectProfileWorkflowData(profileId)(state),
    (workflowData) => workflowAdapter.getSelectors().selectAll(workflowData)
  );

export const selectProfileWorkflowById = (
  profileId: string,
  workflowId: string
) =>
  createSelector(
    (state: RootState) => selectProfileWorkflowData(profileId)(state),
    (workflowData) =>
      workflowAdapter.getSelectors().selectById(workflowData, workflowId)
  );

const selectWorkflowVersionResults = (workflowId: string) =>
  workflowsApi.endpoints.getWorkflowVersions.select(workflowId);

const selectWorkflowVersionData = (workflowId: string) =>
  createSelector(
    selectWorkflowVersionResults(workflowId),
    (workflowVersionResult) =>
      workflowVersionResult.data ?? workflowVersionInitialState
  );

export const selectAllWorkflowVersions = (workflowId: string) =>
  createSelector(
    (state: RootState) => selectWorkflowVersionData(workflowId)(state),
    (workflowVersionData) =>
      workflowVersionAdapter.getSelectors().selectAll(workflowVersionData)
  );

export const selectWorkflowVersionsById = (
  workflowId: string,
  workflowVersionId: string
) =>
  createSelector(
    (state: RootState) => selectWorkflowVersionData(workflowId)(state),
    (workflowVersionData) =>
      workflowVersionAdapter
        .getSelectors()
        .selectById(workflowVersionData, workflowVersionId)
  );

const selectWorkflowResults = (workflowId: string) =>
  workflowsApi.endpoints.getWorkflow.select(workflowId);

export const selectWorkflow = (workflowId: string) =>
  createSelector(
    selectWorkflowResults(workflowId),
    (workflowResult) => workflowResult.data?.workflow
  );
