import React from "react";
import SettingsMenuItemWithClose from "../../../../utilities/components/dropdowns/generic/SettingsMenuItemWithClose";
import { useFormContext } from "react-hook-form";
import type { TAgentBuilderForm } from "@toolflow/shared";
import useFormIsDirty from "../../../../utilities/hooks/useIsDirty";
import { ResetIcon } from "../../../../globalTheme/icons/icons";

function ResetAgentMenuItem() {
  const { reset } = useFormContext<TAgentBuilderForm>();
  const { isDirty } = useFormIsDirty();

  const handleReset = () => {
    reset();
  };

  return (
    <SettingsMenuItemWithClose
      action={handleReset}
      text="Reset agent"
      hotkey="Meta+Shift+R"
      icon={<ResetIcon fontSize="small" />}
      hide={!isDirty}
    />
  );
}

export default ResetAgentMenuItem;
