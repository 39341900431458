import { Button } from "@mui/material";
import React, { FC } from "react";
import useToolflowNavigate from "../../navigation/hooks/useToolflowNavigate";
import { TOOLS_PAGE_ROUTE_PATH } from "../../navigation/helpers/routePaths";

interface INavigateToToolsPageButtonProps {
  className?: string;
  variant?: "contained" | "outlined" | "text";
  buttonText?: string;
}
const NavigateToToolsPageButton: FC<INavigateToToolsPageButtonProps> = ({
  className = "",
  variant = "contained",
  buttonText = "Visit marketplace"
}) => {
  const navigate = useToolflowNavigate();
  const navigateToToolsPage = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(TOOLS_PAGE_ROUTE_PATH);
  };
  return (
    <Button
      variant={variant}
      className={`${className}`}
      onClick={navigateToToolsPage}
    >
      {buttonText}
    </Button>
  );
};

export default NavigateToToolsPageButton;
