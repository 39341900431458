import React, { useState } from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography
} from "@mui/material";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";

export const AddMemberDialogBase = ({
  open,
  closeDialog
}: {
  open: boolean;
  closeDialog: (e: React.SyntheticEvent<Element, Event>) => void;
}) => {
  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle className="flex align-i-center justify-space-between">
          <Typography variant="inherit" className="flex-grow-1" noWrap>
            Add member
          </Typography>
          <IconButton onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Please email alfie@toolflow.ai to add your team members.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            size="small"
            onClick={closeDialog}
            className="m-l-auto"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddMemberDialog = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <AddMemberDialogBase open={open} closeDialog={() => setOpen(false)} />
      <Divider className="m-v-24px" />
      <Box>
        <Stack direction="row" spacing={2} justifyContent={"flex-end"}>
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            size="large"
          >
            Add member
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default AddMemberDialog;
