import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React, { useEffect } from "react";
import SnippetChip from "../../../chips/SnippetChip";
import { useToolbuilderContext } from "../../../../../features/pages/builder/toolBuilder/context/ToolBuilderContext";
import { ClickableTypes, ChipNodeHtmlTags } from "@toolflow/shared";

const NodeOutputViewerComponent = (props: NodeViewProps) => {
  const { state } = useToolbuilderContext();
  const { currentState } = state;
  const { toolOutputOptions } = currentState;

  const attrName = props.node.attrs.name as string;

  const toolbuilderInput = toolOutputOptions.blocks.find(
    (option) =>
      option.value === attrName || option.value === attrName.split(".").at(0) // For nested Block/key pairs, first part of '.' split string is always a block name
  );

  useEffect(() => {
    // switched from timer to microtask because it is cleaner
    // added an empty catch block because we get an out of range error in strict mode.
    queueMicrotask(() => {
      try {
        const toolbuilderInputExists = !!toolbuilderInput;
        if (toolbuilderInputExists) {
          props.updateAttributes({
            textValue: attrName,
            name: attrName || ""
          });
        } else {
          props.deleteNode(); // when an toolbuilderInput gets deleted (like if the tool was deleted), we want to delete the toolbuilderInputs
        }
      } catch {}
    });
  }, [toolbuilderInput?.value]);

  return (
    <NodeViewWrapper
      className={`inline-flex ${ChipNodeHtmlTags.NodeOutputViewerComponent} dontTriggerBubble`}
    >
      {toolbuilderInput && (
        <SnippetChip
          text={attrName}
          onDelete={props.deleteNode}
          type={ClickableTypes.NODE_OUTPUT}
          tabIndex={-1}
        />
      )}
    </NodeViewWrapper>
  );
};

export default NodeOutputViewerComponent;
