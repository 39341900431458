import { Typography, type TypographyProps } from "@mui/material";
import React from "react";

export const LabelAndInfo = ({
  name,
  info,
  variant = "h8",
  className = "m-b-8px",
  infoClassName = "m-t-4px m-b-16px"
}: {
  name: string;
  info?: string;
  variant?: TypographyProps["variant"];
  className?: string;
  infoClassName?: string;
}) => {
  return (
    <div className={`${className}`}>
      <Typography variant={variant} className="m-r-8px">
        {name}
      </Typography>
      {info && (
        <Typography
          variant="body1"
          sx={{ color: (theme) => theme.palette.text.secondary }}
          className={infoClassName}
        >
          {info}
        </Typography>
      )}
    </div>
  );
};
