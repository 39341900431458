import { useInputsToCollectFieldArrayContext } from "../InputsToCollectContext";

const useDeleteToolInputFieldWorkflow = () => {
  const { remove } = useInputsToCollectFieldArrayContext();
  const handleDeleteToolInputField = (index: number) => {
    remove(index);
  };
  return handleDeleteToolInputField;
};

export default useDeleteToolInputFieldWorkflow;
