import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function SidebarCreditsUpgradeButton() {
  return (
    <Link to="/profile/billing">
      <Button
        fullWidth
        size="medium"
        color="secondary"
        variant="secondaryContained"
        className="m-t-16px"
      >
        🚀 Get more credits
      </Button>
    </Link>
  );
}

export default SidebarCreditsUpgradeButton;
