import {
  DynamicFieldType,
  PerplexityModel,
  type PerplexityBlockDataV1,
  type PerplexityBlockDataV2,
  type PerplexityBlockLLMSettingsV1,
  type PerplexityBlockLLMSettingsV2,
  type PerplexityBlockSettingsV1,
  type PerplexityBlockSettingsV2
} from "@toolflow/shared";

export const perplexitySettingsInitialStateV1: PerplexityBlockSettingsV1 = {
  input: ""
};

export const perplexityLLMInitialStateV1: PerplexityBlockLLMSettingsV1 = {
  llmModel: PerplexityModel.SonarHgOnline,
  temperature: 50
};

export const perplexityInitialStateV1: Omit<
  PerplexityBlockDataV1,
  "label" | "type"
> = {
  settings: perplexitySettingsInitialStateV1,
  optimizations: perplexityLLMInitialStateV1
};

export const perplexitySettingsInitialStateV2: PerplexityBlockSettingsV2 = {
  input: null
};

export const perplexityLLMInitialStateV2: PerplexityBlockLLMSettingsV2 = {
  llmModel: {
    type: DynamicFieldType.Preset,
    value: PerplexityModel.SonarHgOnline
  },
  temperature: {
    type: DynamicFieldType.Preset,
    value: 50
  }
};

export const perplexityInitialStateV2: Omit<
  PerplexityBlockDataV2,
  "label" | "type"
> = {
  settings: perplexitySettingsInitialStateV2,
  optimizations: perplexityLLMInitialStateV2
};
