import { useAbortThreadMutation } from "../../../../../../../../../ToolflowAPI/rtkRoutes/threadsApi";
import useThreadRef from "../../hooks/useThreadRef";

const useAbortThreadButton = () => {
  const [abortThread] = useAbortThreadMutation();
  const currentThreadIdRef = useThreadRef();
  return {
    currentThreadIdRef,
    abortThread
  };
};

export default useAbortThreadButton;
