import { WORKFLOW_STEPS_FIELD_LABEL } from "../../WorkflowBuilderConstants";
import useGetStepIndex from "./useGetStepIndex";

const useGetWorkflowStepFieldById = (
  stepId: string
): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}` => {
  const stepIndex = useGetStepIndex(stepId);
  return `${WORKFLOW_STEPS_FIELD_LABEL}.${stepIndex}`;
};

export default useGetWorkflowStepFieldById;
