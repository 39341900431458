import React from "react";
import type { Blocker } from "react-router-dom";
import SaveVersionDialog from "../SaveVersionDialog";
import ResetToolButton from "../ResetToolButton";
import { ConfirmNavigationDialogBaseWithBlocker } from "../../../../navigation/components/ConfirmNavigationDialogBase";
import { EntityType } from "@toolflow/shared";

function ConfirmToolNavigationDialog({
  blocker
}: Readonly<{ blocker: Blocker }>) {
  return (
    <ConfirmNavigationDialogBaseWithBlocker
      blocker={blocker}
      type={EntityType.TOOL}
    >
      {({
        continueNavigating,
        resetBlocker
      }: Readonly<{
        continueNavigating: () => void;
        resetBlocker: () => void;
      }>) => (
        <>
          <ResetToolButton
            resetButtonText="Discard changes"
            continueNavigating={continueNavigating}
          />
          <SaveVersionDialog
            continueNavigating={continueNavigating}
            resetBlocker={resetBlocker}
            text
          />
        </>
      )}
    </ConfirmNavigationDialogBaseWithBlocker>
  );
}

export default ConfirmToolNavigationDialog;
