import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import {
  CardHeader,
  Typography,
  CircularProgress,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from "@mui/material";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import type { RunStatsFE } from "@toolflow/shared";
import { Duration } from "luxon";
import { useGetToolAnalyticsQuery } from "../../../../ToolflowAPI/rtkRoutes/toolVersionResponseApi";
import { formatDuration } from "../../../../utilities/formatters/time/formatDuration";

function AnalyticsRow({
  text,
  runs,
  duration
}: {
  text: string;
  runs: number;
  duration: string;
}) {
  return (
    <TableRow>
      <TableCell>{text}</TableCell>
      <TableCell>{runs}</TableCell>
      <TableCell>{formatDuration(Duration.fromISO(duration))}</TableCell>
    </TableRow>
  );
}

const AnalyticsTable = ({ toolAnalytics }: { toolAnalytics?: RunStatsFE }) => {
  if (!toolAnalytics || toolAnalytics.totalRun === 0) {
    return <Typography>Tool not yet run</Typography>;
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Segment</TableCell>
            <TableCell>Number of Runs</TableCell>
            <TableCell>Average Duration</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <AnalyticsRow
            text="All Users"
            runs={toolAnalytics.totalRun}
            duration={toolAnalytics.totalAverageDuration}
          />
          <AnalyticsRow
            text="Your Runs"
            runs={toolAnalytics.userRun}
            duration={toolAnalytics.userAverageDuration}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

function ToolAnalyticsCard() {
  const { state } = useToolbuilderContext();
  const { toolId } = state;
  const { data, isFetching, refetch } = useGetToolAnalyticsQuery(toolId);

  return (
    <Box mt={4}>
      <Card className="m-b-32px" variant="outlined">
        <CardHeader
          title="Tool Analytics"
          action={
            <Button variant="outlined" onClick={refetch} disabled={isFetching}>
              Refresh
            </Button>
          }
        />
        {isFetching ? (
          <CardContent className="flex align-i-center justify-center">
            <CircularProgress />
          </CardContent>
        ) : (
          <CardContent>
            <AnalyticsTable toolAnalytics={data?.toolAnalytics} />
          </CardContent>
        )}
      </Card>
    </Box>
  );
}

export default ToolAnalyticsCard;
