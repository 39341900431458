import { styled } from "@mui/material";
import { useSelector } from "react-redux";
import { selectSidebarOpen } from "../../../../layout/sidebar/sidebarSlice";
import {
  easingAndDurationWhenClosing,
  easingAndDurationWhenOpening
} from "../../../../layout/sidebar/sidebarDrawerStyles";
import React from "react";
import DragStepMonitor from "./DragStepMonitor";

const StyledMain = styled("main", {
  shouldForwardProp: (prop) => prop !== "open"
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  overflowY: "scroll",
  height: "100vh",
  transition: theme.transitions.create("margin", easingAndDurationWhenClosing),
  marginRight: `calc(50% + ${theme.spacing(3)})`,
  ...(open && {
    transition: theme.transitions.create(
      "margin",
      easingAndDurationWhenOpening
    ),
    marginRight: `calc(50% + 90px)`
  })
}));

const StyledMainStepsWrapper = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const sidebarOpen = useSelector(selectSidebarOpen);
  return (
    <StyledMain open={sidebarOpen} id="closeOnClickId">
      <DragStepMonitor>{children}</DragStepMonitor>
    </StyledMain>
  );
};

export default StyledMainStepsWrapper;
