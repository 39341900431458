import { useDispatch } from "react-redux";
import { useSettingsDropdownContext } from "../../../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import { DrawerEnum, setDrawer } from "../../store/toolBuilderSlice";
import SettingsMenuItem from "../../../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import { GenerateSchemaIcon } from "../../../../../../globalTheme/icons/icons";
import { useLazyGenerateSchemaQuery } from "../../../../../../ToolflowAPI/rtkRoutes/toolsApi";

export function ToolbuilderGenerateSchemaMenuItem({
  toolId
}: {
  toolId: string;
}) {
  const dispatch = useDispatch();
  const { handleClose } = useSettingsDropdownContext();
  const [generateSchema] = useLazyGenerateSchemaQuery();
  const setTool = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await generateSchema(toolId)
      .unwrap()
      .then((resp) => {
        dispatch(
          setDrawer({ type: DrawerEnum.generateSchema, schema: resp.schema })
        );
      });

    handleClose(e);
  };
  if (!toolId) return null;

  return (
    <SettingsMenuItem
      text="Generate schema"
      icon={<GenerateSchemaIcon fontSize="small" />}
      action={setTool}
    />
  );
}
