import { Divider, Tab, Tabs } from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import type { THomeEntityBlockType } from "@toolflow/shared";
import FrequentlyUsedEntitiesContainer from "../../tools/toolsBlock/FrequentlyUsedEntitiesContainer";
import StarredEntitiesContainer from "../../tools/toolsBlock/StarredEntitiesContainer";
import UserCreatedEntitiesContainer from "../../tools/toolsBlock/UserCreatedEntitiesContainer";
import { RootState } from "../../../../../stores/store";
import useAllHomePageMainData from "../../hooks/useAllHomePageMainData";
import { updateToolsBlockTab } from "../../slice/homePageSlice";
import FullWidthCard from "../../../../../utilities/components/cards/FullWidthCard";
import RemixWrapperIconWithTheme from "../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import TabPanel from "../../../../../utilities/components/tabs/TabPanel";
import { a11yProps } from "../../../../../utilities/components/tabs/functions/a11yProps";
import NavigateToHomePageTools from "../../NavigateToHomePageTools";
import { toolflowPrimaryColor } from "../../../../../globalTheme/muiUtils/appTheme";
import { ListCheckIcon } from "../../../../../globalTheme/icons/icons";

type THomeToolsBlockTabDict = {
  [key in THomeEntityBlockType]: {
    value: number;
    component: React.ReactNode;
    label: string;
  };
};

const homeTabDict: THomeToolsBlockTabDict = {
  "frequently-used": {
    value: 0,
    component: <FrequentlyUsedEntitiesContainer />,
    label: "Frequently used"
  },
  starred: {
    value: 1,
    component: <StarredEntitiesContainer />,
    label: "Starred"
  },
  "created-by-user": {
    value: 2,
    component: <UserCreatedEntitiesContainer />,
    label: "Created by you"
  }
};
const ToolsBlockData = () => {
  const homeTab = useSelector((state: RootState) => state.home.toolsBlockTab);
  const dispatch = useDispatch();
  const { homePageEntitiesLength, isLoading } = useAllHomePageMainData();

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: THomeEntityBlockType
  ) => {
    dispatch(updateToolsBlockTab(newValue));
  };

  const isDataEmpty = homePageEntitiesLength === 0 && !isLoading;
  if (isDataEmpty) {
    return null;
  }
  // remix-icons/line/editor/list-check-2
  return (
    <FullWidthCard
      title="Tools"
      icon={null}
      className="p-h-8px"
      headerIcon={
        <RemixWrapperIconWithTheme
          // className doesn't work here
          style={{ marginTop: "5px", marginRight: "5px" }}
          Icon={ListCheckIcon}
          providedColor={toolflowPrimaryColor}
          size={20}
        />
      }
      action={<NavigateToHomePageTools />}
    >
      <Tabs value={homeTab} onChange={handleChange} textColor="secondary">
        {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
          <Tab
            label={tabDetails.label}
            key={tabName}
            value={tabName}
            {...a11yProps(tabDetails.value)}
            className="m-l-12px"
          />
        ))}
      </Tabs>
      <Divider variant="middle" />
      {Object.entries(homeTabDict).map(([tabName, tabDetails]) => (
        <TabPanel value={homeTab} key={tabName} index={tabName}>
          {tabDetails.component}
        </TabPanel>
      ))}
    </FullWidthCard>
  );
};

export default ToolsBlockData;
