import type { CustomToolInputField } from "@toolflow/shared";
import { useToolbuilderContext } from "../../../../../../context/ToolBuilderContext";

const useSetToolInputFieldsToolbuilder = () => {
  const { dispatch } = useToolbuilderContext();
  const setToolInputFields = (
    updatedToolInputFields: CustomToolInputField[]
  ) => {
    dispatch({
      type: "SET_TOOL_INPUT_FIELDS",
      toolInputFields: updatedToolInputFields
    });
  };
  return setToolInputFields;
};

export default useSetToolInputFieldsToolbuilder;
