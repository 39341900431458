import { ResolvedPos } from "@tiptap/pm/model";
import { TextSelection } from "@tiptap/pm/state";
import { EditorView } from "@tiptap/pm/view";

const executeEnter = (view: EditorView, event: KeyboardEvent) => {
  const docSize = view.state.doc.content.size;
  const cursorPos = view.state.selection.$from.pos;
  const { $from, $to } = view.state.selection;
  const doc = view.state.doc;

  // Function to find the list item node from the current position
  const findListItemNode = ($pos: ResolvedPos) => {
    for (let depth = $pos.depth; depth > 0; depth--) {
      const node = $pos.node(depth);
      if (node.type.name === "listItem") {
        return { node, depth };
      }
    }
    return null;
  };

  const listItemInfo = findListItemNode($from);
  // Check if selection is within a list item
  if (listItemInfo) {
    const { node: listItemNode, depth } = listItemInfo;
    const listNode = $from.node(depth - 1);

    // Check if it is the last list item in the list
    const isLastListItem = listItemNode === listNode.lastChild;

    // Check if cursor is at the end of the list item
    const isCursorAtEndOfListItem =
      $to.pos + 1 === doc.resolve($from.after()).pos;

    // if its the last iitem, and the cursor is at the end of the list item, and shift isnt pressed
    // then we want to go to the next node and focus elsewhere
    if (isLastListItem && isCursorAtEndOfListItem && !event.shiftKey) {
      return true;
      // if shift key is pressed, we want to change it from a hard break to
      // creating a new list item
    } else if (event.shiftKey) {
      event.preventDefault();
      event.stopPropagation();

      // Insert a new list item (bullet) at the current position
      const tr = view.state.tr;
      const insertPos = $from.end($from.depth);
      const n = listItemNode.type.createAndFill();
      if (n) {
        tr.insert(insertPos, n);
        const nextPos = tr.doc.resolve(insertPos + 1);
        tr.setSelection(TextSelection.near(nextPos));
        view.dispatch(tr);
      }
      return false;
      // if its not the last item of the last node and shift isnt pressed, then
      // maintain "normal" behavior and dont bubble up the event that focuses it to the
      // next node
    } else {
      event.stopPropagation();
      return false;
    }

    // if shift key is pressed when in the node (and not a list item)
    // then we want it to maintain normal behavior
  } else if (
    event.shiftKey ||
    !(cursorPos === docSize || cursorPos + 1 === docSize)
  ) {
    event.stopPropagation();
    return false;
  } else {
    return true;
  }
};

export default executeEnter;
