import { EdgeLabelRenderer } from "reactflow";
import { useToolbuilderContext } from "../../context/ToolBuilderContext";
import { CloseIcon } from "../../../../../../globalTheme/icons/icons";
import styles from "./deleteEdgeButton.module.css";
import { parsedClassNames } from "../../../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

function DeleteEdgeButton({
  id,
  labelX,
  labelY
}: {
  id: string;
  labelX: number;
  labelY: number;
}) {
  const { reactflowUtility } = useToolbuilderContext();
  const { handleEdgeDelete: onDelete } = reactflowUtility;
  return (
    <EdgeLabelRenderer>
      <div
        onClick={() => onDelete(id)}
        className={cx(
          "nodrag nopan pos-absolute flex align-i-center justify-center cursor-pointer",
          styles["outer-div"]
        )}
        style={{
          transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px) translate(0.5px, 0.5px)`
        }}
      >
        <div className={cx("nodrag nopan", styles["circle-outline"])}>
          <CloseIcon size={19} className={styles["close-icon"]} />
        </div>
      </div>
    </EdgeLabelRenderer>
  );
}

export default DeleteEdgeButton;
