import type { TTransformedMessage } from "@toolflow/shared";
import React, { ReactNode, createContext, useContext } from "react";

interface ThreadMessageContextType {
  threadMessage: TTransformedMessage;
}

const ThreadMessageContext = createContext<
  ThreadMessageContextType | undefined
>(undefined);

interface ThreadMessageContextComponentProps {
  children: ReactNode;
  threadMessage?: TTransformedMessage;
}

export function ThreadMessageContextComponent({
  children,
  threadMessage
}: Readonly<ThreadMessageContextComponentProps>) {
  if (!threadMessage) {
    return null;
  }
  return (
    <ThreadMessageContext.Provider value={{ threadMessage }}>
      {children}
    </ThreadMessageContext.Provider>
  );
}

export const useThreadMessageContext = () => {
  const context = useContext(ThreadMessageContext) as
    | ThreadMessageContextType
    | undefined;
  if (context === undefined) {
    throw new Error(
      "useThreadMessageContext must be used within a ThreadMessageContextComponent"
    );
  }
  return context;
};

export default ThreadMessageContext;
