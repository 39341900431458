import { useCanRunAgentQuery } from "../../../../../../../ToolflowAPI/rtkRoutes/agentApi";
import { useAuthStore } from "../../../../../../auth/hooks/useAuthStore";

export const useCanRunAgent = () => {
  const { isAuthenticated } = useAuthStore();
  const { data, isFetching } = useCanRunAgentQuery(undefined, {
    skip: !isAuthenticated
  });
  return {
    canRunAgent: data?.result?.canRunAgent ?? true,
    isFetching
  };
};
