import React, { useEffect } from "react";

import { PostHogProvider } from "posthog-js/react";
import { posthog } from "posthog-js";
import useGetCurrentUser from "../../features/user/hooks/useGetCurrentUser";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST
};

const useIdentifyPosthogUser = () => {
  const user = useGetCurrentUser();

  useEffect(() => {
    if (user?._id) {
      posthog.identify(user._id.toString());
    }
  }, [user?._id]);
};

const InitializedPostHogProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  useIdentifyPosthogUser();
  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      {children}
    </PostHogProvider>
  );
};

export default InitializedPostHogProvider;
