import type { Extension } from "@tiptap/core";
import {
  AssetExtensionConfig,
  FlowArtifactExtensionConfig,
  FlowThinkingExtensionConfig,
  NodeOutputViewerExtensionConfig,
  SearchToolResponseExtensionConfig,
  SnippetExtensionConfig,
  ToolbuilderInputExtensionConfig
} from "@toolflow/shared";
import AssetViewerComponent from "../../tiptapExtensions/AssetViewerComponent";
import createNodeFromConfig from "../../tiptapExtensions/helpers/createNodeFromConfig";
import NodeOutputViewerComponent from "../../tiptapExtensions/NodeOutputViewerComponent";
import SearchToolResponseComponent from "../../tiptapExtensions/SearchToolResponseComponent/SearchToolResponseComponent";
import SnippetViewerComponent from "../../tiptapExtensions/SnippetViewerComponent";
import ToolbuilderInputViewerComponent from "../../tiptapExtensions/ToolbuilderInputViewerComponent";
import FlowThinkingViewerComponent from "../../tiptapExtensions/FlowThinkingViewerComponent";
import FlowArtifactViewerComponent from "../../tiptapExtensions/FlowArtifactViewerComponent";

const extensionsConfig = {
  InputViewerExtension: {
    config: AssetExtensionConfig,
    component: AssetViewerComponent
  },
  SnippetViewerExtension: {
    config: SnippetExtensionConfig,
    component: SnippetViewerComponent
  },
  ToolbuilderInputViewerExtension: {
    config: ToolbuilderInputExtensionConfig,
    component: ToolbuilderInputViewerComponent
  },
  SearchToolResponseExtension: {
    config: SearchToolResponseExtensionConfig,
    component: SearchToolResponseComponent
  },
  NodeOutputViewerExtension: {
    config: NodeOutputViewerExtensionConfig,
    component: NodeOutputViewerComponent
  },
  FlowThinkingExtension: {
    config: FlowThinkingExtensionConfig,
    component: FlowThinkingViewerComponent
  },
  FlowArtifactExtension: {
    config: FlowArtifactExtensionConfig,
    component: FlowArtifactViewerComponent
  }
};

export const customExtensions: Extension[] = Object.values(
  extensionsConfig
).map(({ config, component }) => createNodeFromConfig(config, component));
