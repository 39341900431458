// appWarningSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";

interface IAppWarningInitialState {
  open: boolean;
}

const initialState: IAppWarningInitialState = {
  open: false
};

export type TAppWarningReducer = "appWarning";
export const APP_WARNING_REDUCER_NAME: TAppWarningReducer = "appWarning";

const appWarningSlice = createSlice({
  name: APP_WARNING_REDUCER_NAME,
  initialState,
  reducers: {
    openAppWarning(state) {
      state.open = true;
    },
    closeAppWarning(state) {
      state.open = false;
    }
  }
});

export const selectAppWarningOpen = (state: RootState) =>
  state.layout[APP_WARNING_REDUCER_NAME].open;

export const { openAppWarning, closeAppWarning } = appWarningSlice.actions;

export default appWarningSlice.reducer;
