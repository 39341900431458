import type { ExternalToolFE, TEnrichedPreviousStep } from "@toolflow/shared";
import useGetPreviousSteps from "../../hooks/useGetPreviousSteps";
import {
  INPUTS_TO_COLLECT_FIELD,
  TOOL_ID_FIELD
} from "../../../WorkflowBuilderConstants";
import { useEffect, useState } from "react";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../../../../stores/store";
import { toolsApi } from "../../../../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useSelector } from "react-redux";
import useGetWorkflowStepToolIds from "../../hooks/useGetWorkflowStepToolIds";

export const selectToolsData = createSelector(
  (state: RootState) => state,
  (state: RootState, toolIds: string[]) => toolIds,
  (state, toolIds) => {
    return toolIds.reduce(
      (acc, toolId) => {
        const result = toolsApi.endpoints.getTool.select(toolId)(state);
        if (result.data) {
          acc[toolId] = result.data.tool;
        }
        return acc;
      },
      {} as Record<string, ExternalToolFE>
    );
  }
);

const useGetPreviousToolsAndInputs = () => {
  const previousSteps = useGetPreviousSteps();
  const [results, setResults] = useState<TEnrichedPreviousStep[]>([]);
  const toolIds = useGetWorkflowStepToolIds();

  const toolsData = useSelector((state: RootState) =>
    selectToolsData(state, toolIds)
  );

  useEffect(() => {
    const processSteps = async () => {
      const stepsResults = await Promise.all(
        previousSteps.map(async (step) => {
          if (step.settings && TOOL_ID_FIELD in step.settings) {
            const toolId = step.settings.toolId;
            if (toolId && toolsData[toolId]) {
              return { step, tool: toolsData[toolId] };
            }
          } else if (
            step.settings &&
            INPUTS_TO_COLLECT_FIELD in step.settings
          ) {
            return {
              step,
              inputs: step.settings[INPUTS_TO_COLLECT_FIELD]
            };
          }
          return null;
        })
      );

      setResults(stepsResults.filter(Boolean) as TEnrichedPreviousStep[]);
    };

    processSteps();
  }, []);

  return results;
};

export default useGetPreviousToolsAndInputs;
