import { Grid } from "@mui/material";
import type {
  JSONArraySchema,
  JSONSchemaItem,
  JSONSchemaItemType
} from "@toolflow/shared";
import { Fragment } from "react";
import GuidelineSchemaField from "./GuidelineSchemaField";
import ObjectTypeSchemaFields from "./ObjectTypeSchemaFields";
import EnumSchemaFields from "./EnumSchemaFields";
import SchemaTypeField from "./SchemaTypeField";

export default function ArrayTypeSchemaFields({
  skipTypes = [],
  schemaItem,
  onChange
}: {
  skipTypes?: JSONSchemaItemType[];
  schemaItem: JSONArraySchema;
  onChange(schemaItem: JSONArraySchema): void;
}) {
  return (
    <Fragment>
      <Grid item xs={12}>
        <SchemaTypeField
          schemaItem={schemaItem.itemType}
          skip={["array", ...skipTypes]}
          onChange={(itemType) => {
            onChange({ ...schemaItem, itemType });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <GuidelineSchemaField
          schemaItem={schemaItem}
          onChange={(guideline) => {
            onChange({ ...schemaItem, guideline });
          }}
        />
      </Grid>

      {schemaItem.itemType.type === "enum" && (
        <EnumSchemaFields
          enums={schemaItem.itemType.enums}
          onChange={(enums) => {
            onChange({
              ...schemaItem,
              itemType: { ...schemaItem.itemType, enums } as JSONSchemaItem
            });
          }}
        />
      )}

      {schemaItem.itemType.type === "object" && (
        <Grid container spacing={2} className="p-t-16px m-l-4px">
          <ObjectTypeSchemaFields
            schemaItem={schemaItem.itemType}
            onChange={(items) => {
              onChange({
                ...schemaItem,
                itemType: { ...schemaItem.itemType, items } as JSONSchemaItem
              });
            }}
          />
        </Grid>
      )}
    </Fragment>
  );
}
