import { Stack, Typography } from "@mui/material";

function InputLabelWrapper({
  label,
  subtitle = "",
  children,
  className = ""
}: {
  label: string;
  subtitle?: string;
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <Stack spacing={2} className={className}>
      <div>
        <Typography variant="h8">{label}</Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </div>
      {children}
    </Stack>
  );
}

export default InputLabelWrapper;
