import { useSelector } from "react-redux";
import { useWebsocketContext } from "../../../../../../../../../ToolflowAPI/websocket/contexts/WebsocketContext";
import { useHasSelectedEntity } from "../../../../../../hooks/useHasSelectedEntity";
import { useCanRunAgent } from "../../../hooks/useCanRunAgent";
import { RootState } from "../../../../../../../../../stores/store";

const useSendChatDisabled = () => {
  const { isFetching, canRunAgent } = useCanRunAgent();
  const { showReconnectButton } = useWebsocketContext();
  // if a tool or workflow is selected, disable the chat button
  const hasSelectedEntity = useHasSelectedEntity();

  const actionButtonDisabled = useSelector(
    (state: RootState) => state.chat.actionButtonDisabled
  );
  return (
    showReconnectButton ||
    hasSelectedEntity ||
    !canRunAgent ||
    isFetching ||
    actionButtonDisabled
  );
};

export default useSendChatDisabled;
