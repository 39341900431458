import React from "react";
import { TextField } from "@mui/material";
import useIsUniqueField from "./useIsUniqueField";
import useUpdateField from "./useUpdateField";
import { useBlockContext } from "./BlockContext";
import { useToolbuilderContext } from "./context/ToolBuilderContext";

function UpdateLabelTextField({ className = "" }: { className?: string }) {
  const { blockData: data, id } = useBlockContext();
  const { dispatch, state } = useToolbuilderContext();
  const { label, type } = data;
  const updateField = useUpdateField(id);
  const { currentState } = state;
  const isUniqueField = useIsUniqueField();

  const updateLabel = (e: React.ChangeEvent<{ value: string }>) => {
    // updateField needs to go first, because it overwrites blocks
    // then we need to update all the prompts with the new label
    if (isUniqueField(e.target.value, currentState.toolOutputOptions)) {
      updateField(e.target.value, "label");
      dispatch({
        type: "UPDATE_OUTPUT_LABEL_IN_AVAILABLE_FIELDS",
        oldLabel: label || "",
        newLabel: e.target.value,
        toolWithinTool: type === "toolWithinTool" ? data.tool : undefined
      });
    }
  };

  return (
    <TextField
      autoFocus
      size="small"
      label="Name"
      value={label}
      fullWidth
      onChange={updateLabel}
      className={className}
    />
  );
}
export default UpdateLabelTextField;
