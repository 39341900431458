import React from "react";
import ReactDOMClient from "react-dom/client";
import "./globalTheme/index.css";

import RootComponent from "./initializers/RootComponent";
import initializeInfrastructure from "./initializers/functions/initializeInfrastructure";

if (
  ["development", "staging"].includes(process.env.REACT_APP_ENVIRONMENT || "")
) {
  window.reducerStates = {};
}

if (process.env.REACT_APP_ENVIRONMENT !== "development") {
  initializeInfrastructure();
}

const container = document.getElementById("root");
// createRoot(container!) if you use TypeScript
const root = ReactDOMClient.createRoot(container!); //eslint-disable-line @typescript-eslint/no-non-null-assertion
root.render(<RootComponent />);
