import { Stack } from "@mui/material";
import {
  BlockNode,
  type ScraperBlockData,
  type ScraperBlockPropsV2
} from "@toolflow/shared";
import { uniq } from "lodash";
import { useMemo } from "react";
import useGetChips from "../../../../../../../../utilities/formatters/strings/tiptap/hooks/useGetChips";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import { textInputContainerExtensionsConfig } from "../../../../common/fieldsByType/fields/textInputContainer/TextInputContainer";
import TestToolRun from "../../../../common/TestToolRun";
import TestOutput from "../../../components/testWrapper/TestOutput";
import useTest from "../../../hooks/useTest";
import useTestInputFromFieldsByType from "../../../hooks/useTestInputFromFieldsByType";
import TestFieldsWithPrompt from "../../../components/testWrapper/TestFieldsWithPrompt";
import usePromptFiller from "../../../promptBlocks/usePromptFiller";
import useScraperFieldsConfigs from "./useScraperFieldsConfigs";

function TestScraperV2({ data, id }: Omit<ScraperBlockPropsV2, "selected">) {
  const getChipsList = useGetChips();
  const fieldsByType = useScraperFieldsConfigs(id);
  const { url } = data.settings;

  const urlFieldsToTest = useMemo(() => getChipsList(url), [url]);

  const { fields, userInput, setUserInput } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings]
  );

  const combinedFields = uniq([...urlFieldsToTest, ...fields]);

  const { action, text, endIcon, showUpgrade, output, label, loading } =
    useTest<ScraperBlockData>({
      data,
      id,
      blockType: BlockNode.ScraperBlockNode,
      fields: combinedFields,
      userInput
    });

  const insertUserInputIntoPrompt = usePromptFiller(
    textInputContainerExtensionsConfig
  );

  const filledOutPrompt = useMemo(
    () => insertUserInputIntoPrompt(url, userInput),
    [userInput, url, insertUserInputIntoPrompt]
  );

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <TestFieldsWithPrompt
            fields={combinedFields}
            uI={userInput}
            setUI={setUserInput}
            filledOutPrompt={filledOutPrompt}
          />
          <TestOutput output={output} label={label} />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          disabled={!url}
          loading={loading}
        />
      }
    />
  );
}

export default TestScraperV2;
