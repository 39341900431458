import { Paper } from "@mui/material";
import React from "react";
import ChatInputPaper from "./chat/chatbox/ChatInputPaper/ChatInputPaper";
import ToolPaper from "./entities/toolPaper/ToolPaper";
import ChatBoxPage from "./chat/ChatBoxPage";
import { useSavedWorkstationDrawer } from "./useSavedWorkstationDrawer";

function SavedWorkstationDrawer() {
  const { ref, height, hasSelectedEntity, showChat } =
    useSavedWorkstationDrawer();
  return (
    <div className="flex flex-column h-100-percent">
      <Paper
        variant="workspaceWrapper"
        className="w-100-percent m-b-8px flex-grow-1"
        style={{ maxHeight: `calc(100% - ${height}px  - 8px)` }}
      >
        {hasSelectedEntity ? <ToolPaper /> : <ChatBoxPage />}
      </Paper>
      <div ref={ref}>{showChat && <ChatInputPaper />}</div>
    </div>
  );
}

export default SavedWorkstationDrawer;
