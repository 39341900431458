import React from "react";
import LargeLogo from "../../../../globalTheme/icons/images/Logo.png";
import SmallLogo from "../../../../globalTheme/icons/images/ToolflowIcon.png";
import ToolflowLogoBase from "./ToolflowLogoBase";

function ToolflowLogoName({
  className = "",
  height = 24,
  variant = "large"
}: {
  variant?: "large" | "small";
  height?: number;
  className?: string;
}) {
  const toolflowIcons = {
    large: LargeLogo,
    small: SmallLogo
  };
  return (
    <ToolflowLogoBase
      className={className}
      icon={toolflowIcons[variant]}
      height={height}
    />
  );
}

export default ToolflowLogoName;
