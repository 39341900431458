import type { Asset } from "@toolflow/shared";
import { useWorkspaceAssetsFieldArrayContext } from "../../../../pages/workstation/contexts/WorkspaceAssetsContext";
import { useEditorToolCardContext } from "../EditorToolCardContext";
import { setLoadingAssetFalse } from "../../../../pages/workstation/assets/functions/setLoadingAssetFalse";

const useSetInputLoadingFalse = () => {
  const { componentId } = useEditorToolCardContext();
  const { update } = useWorkspaceAssetsFieldArrayContext();
  // this is so that we can edit the tool output.
  // Changes it from generic richtext to editable workspace
  // note race conditions are possible here, so
  // we set it false when percentCompleted is 100
  // percentCompleted is set to 100 when the tool is done generating
  // or it has errored
  const setInputLoadingFalse = (currentAssets: Asset[]) => {
    currentAssets.map((i, index) => {
      if (i.sourceIdentifier === componentId) {
        const newInput = setLoadingAssetFalse(i);
        update(index, newInput);
      }
    });
  };
  return setInputLoadingFalse;
};

export default useSetInputLoadingFalse;
