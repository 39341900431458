import { Button, InputLabel } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ToolflowBoundary = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-column align-i-center justify-center h-100vh">
      <Button onClick={() => navigate(0)}>Refresh</Button>
      <InputLabel>Oops, there was an error</InputLabel>
    </div>
  );
};

export default ToolflowBoundary;
