import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  List,
  ListSubheader
} from "@mui/material";
import type {
  ExternalToolFE,
  MapperObject,
  ToolWithinToolBlockData
} from "@toolflow/shared";

import OutputListItem from "./OutputListItem";
import useCompatibilityMap from "./hooks/useCompatibilityMap";

function OutputList({
  inputMap,
  id,
  tool
}: {
  inputMap: MapperObject | null;
  id: string;
  tool: ExternalToolFE;
}) {
  const compatibilityMap = useCompatibilityMap({ id, tool });
  return (
    <>
      {Object.entries(compatibilityMap).map(([field, value], idx) => (
        <OutputListItem
          field={field}
          value={value}
          key={idx}
          inputMap={inputMap}
          id={id}
        />
      ))}
    </>
  );
}

function OutputMapper({
  data,
  id
}: {
  data: ToolWithinToolBlockData;
  id: string;
}) {
  const { tool, inputMap } = data;

  return (
    <Card>
      <CardHeader title="Map Inputs" />
      <CardContent>
        <Typography>
          This tool uses the following inputs to function. Map these tool inputs
          to available fields from the dropdown menu or create them as new
          fields.
        </Typography>
        <List disablePadding className="m-t-16px">
          <ListSubheader>Inputs</ListSubheader>
          {tool && <OutputList id={id} inputMap={inputMap} tool={tool} />}
        </List>
      </CardContent>
    </Card>
  );
}

export default OutputMapper;
