import React, { ReactNode } from "react";
import { Box, Divider } from "@mui/material";

import styles from "./drawerHeader.module.css";
import { parsedClassNames } from "../../../../../../../utilities/functions/parsedClassNames";
import BlockIconWrapper from "../../../blocks/components/blockIcons/BlockIcon";
import CloseDrawerButton from "./CloseDrawerButton";

const cx = parsedClassNames.bind(styles);

interface DrawerHeaderContainerProps {
  className?: string;
  BlockIcon: ReactNode;
  title: ReactNode;
  blockIconClassName?: string;
  rightSlot?: ReactNode;
}

export function DrawerHeader({
  BlockIcon,
  title,
  className,
  blockIconClassName,
  rightSlot
}: DrawerHeaderContainerProps) {
  return (
    <>
      <Box
        className={cx(
          "flex align-i-center justify-space-between p-16px",
          className
        )}
      >
        <div
          className={cx(
            "align-i-center flex w-100-percent",
            styles["max-width"]
          )}
        >
          <BlockIconWrapper
            BlockIcon={BlockIcon}
            className={blockIconClassName}
          />
          <div className="m-l-8px w-100-percent">{title}</div>
        </div>
        <div className="flex align-i-center m-l-8px">{rightSlot}</div>
      </Box>
      <Divider />
    </>
  );
}

function DrawerHeaderContainer(props: DrawerHeaderContainerProps) {
  return <DrawerHeader {...props} rightSlot={<CloseDrawerButton />} />;
}

export default DrawerHeaderContainer;
