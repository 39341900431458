import React from "react";
import CircularLoading from "../../utilities/components/loading/CircularLoading";
import useLoginRedirectToGetStarted from "./hooks/useLoginRedirectToGetStarted";

const LoginRoute = ({ signUp }: { signUp?: boolean }) => {
  useLoginRedirectToGetStarted({ signUp });

  return <CircularLoading className="h-100vh" />;
};

export default LoginRoute;
