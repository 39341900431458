import type { ValidatedInput, ValidatedInputTypes } from "@toolflow/shared";
import {
  CHECKBOX,
  FILE_UPLOAD
} from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";

const getFilteredFields = (toolInputFields: ValidatedInput[]) => {
  return toolInputFields.filter(
    (i) => !([FILE_UPLOAD, CHECKBOX] as ValidatedInputTypes[]).includes(i.type)
  );
};

export default getFilteredFields;
