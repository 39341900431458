import React from "react";
import { useHandleCopy } from "../../../../copyContent/hooks/useHandleCopy";
import type { CopyTypes } from "@toolflow/shared";
import { useSettingsDropdownContext } from "../../../../dropdowns/generic/useSettingsDropownContext";
import RemixButton from "../../../../icons/RemixButton";
import { LinkIcon } from "../../../../../../globalTheme/icons/icons";

const CopyLinkMenuItemInHome = ({
  objectId,
  type,
  text = "Copy Link"
}: {
  objectId: string;
  type: CopyTypes;
  text?: string;
}) => {
  const handleCopy = useHandleCopy(objectId, type);
  const { handleClose } = useSettingsDropdownContext();

  const copy = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleCopy();
    handleClose(e);
  };
  return <RemixButton text={text} icon={LinkIcon} onClick={copy} />;
};

export default CopyLinkMenuItemInHome;
