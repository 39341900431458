import { Button } from "@mui/material";
import React from "react";
import { TMaterialTable } from "../sheetTypes";

const ClearSelectionButton = ({ table }: { table: TMaterialTable }) => {
  return (
    <Button onClick={() => table.resetRowSelection()}>Clear selection</Button>
  );
};

export default ClearSelectionButton;
