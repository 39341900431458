import React from "react";
import RemixWrapperIconWithTheme from "../../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import { MoreIcon } from "../../../../../globalTheme/icons/icons";
import { toolflowTextPrimaryColor } from "../../../../../globalTheme/muiUtils/appTheme";
import { WORKSPACE } from "../../../../../utilities/constants/constants";
import SettingsDropdownMenuButton from "../../../../../utilities/components/dropdowns/generic/SettingsDropdownMenuButton";
import BaseSettingsDropdown from "../../../../../utilities/components/dropdowns/generic/base/BaseSettingsDropdown";
import DeleteMenuItem from "../../../../../utilities/components/dropdowns/menuItems/deleteMenuItem/DeleteMenuItem";
import UpdateWorkspaceVisibilityMenuItem from "../menuItems/UpdateWorkspaceVisibilityMenuItem";
import useWorkspaceSettingsDropdown from "../../../../../utilities/components/textFields/workspace/dropdowns/hooks/useWorkspaceSettingsDropdown";

function WorkspaceSettingsDropdown() {
  const { workspaceId, workspaceName, workspaceVisibility } =
    useWorkspaceSettingsDropdown();
  const menuItems = [
    <UpdateWorkspaceVisibilityMenuItem
      visibility={workspaceVisibility}
      id={workspaceId}
      name={workspaceName}
      key="updateVisibility"
    />,
    <DeleteMenuItem
      key="delete"
      id={workspaceId}
      shouldNavigateHome
      type={WORKSPACE}
    />
  ];
  return (
    <BaseSettingsDropdown>
      <SettingsDropdownMenuButton
        menuItems={menuItems}
        icon={
          <RemixWrapperIconWithTheme
            Icon={MoreIcon}
            size={16}
            providedColor={toolflowTextPrimaryColor}
          />
        }
        MenuButtonProps={{
          tooltipLabel: "More options"
        }}
      />
    </BaseSettingsDropdown>
  );
}

export default WorkspaceSettingsDropdown;
