import { zodResolver } from "@hookform/resolvers/zod";
import {
  EntityType,
  VisibilityTypes,
  type TWorkspaceDataToSave,
  type Workspace
} from "@toolflow/shared";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as z from "zod";
import { WorkspaceAssetsFormProvider } from "../../contexts/WorkspaceAssetsContext";
import getDefaultValues from "../../functions/getDefaultValues";
import {
  ASSET_SOURCES,
  ASSET_TYPES,
  CHAT_TAB_BASE,
  DEFAULT_WORKSPACE,
  TOOL_TAB_BASE,
  WORKFLOW_USE_BASE,
  WORKSPACE_ABOUT_FIELD_LABEL,
  WORKSPACE_ASSETS_FIELD_LABEL,
  WORKSPACE_ENTITY_BASE,
  WORKSPACE_ID_FIELD_LABEL,
  WORKSPACE_NAME_FIELD_LABEL,
  WORKSPACE_VISIBILITY_FIELD_LABEL,
  WORKSPACE_VISIBLE_ASSET_ID,
  WORKSPACE_VISIBLE_SUB_ASSET_ID
} from "../../helpers/workspaceConstants";
import useDefaultWorkspaceFormValues from "../../hooks/useDefaultWorkspaceFormValues";
import useGetDefaultWorkspaceFields from "../../hooks/useGetDefaultWorkspaceFields";
import { useKeepFormWorkspaceIdInSync } from "../../hooks/useKeepFormWorkspaceIdInSync";
import useReset from "../../hooks/useReset";
import { useResetWorspaceOnUnmount } from "../../hooks/useResetWorspaceOnUnmount";
import useSetDefaultValues from "../../hooks/useSetDefaultValues";

const WorkflowInputZodSchema = z.object({
  id: z.string(),
  name: z.string(),
  source: z.enum(ASSET_SOURCES),
  sourceIdentifier: z.string().optional(),
  loading: z.boolean().optional(),
  valueType: z.enum(ASSET_TYPES).optional(),
  data: z
    .object({
      toolId: z.string().optional(),
      tvrId: z.string().optional(),
      creatorId: z.string().optional(),
      userInput: z.record(z.string(), z.any()).optional(),
      outputId: z.string().optional()
    })
    .optional()
});

const EntityTypeSchema = z.object({
  entityId: z.string(),
  componentId: z.string(),
  type: z.enum([EntityType.WORKFLOW, EntityType.TOOL])
});

const schema = z.object({
  [WORKSPACE_ENTITY_BASE]: EntityTypeSchema,
  [WORKSPACE_VISIBLE_ASSET_ID]: z.string(),
  [WORKSPACE_VISIBLE_SUB_ASSET_ID]: z.string(),
  [WORKSPACE_ASSETS_FIELD_LABEL]: z.array(WorkflowInputZodSchema),
  [WORKSPACE_ID_FIELD_LABEL]: z.string(),
  [WORKSPACE_NAME_FIELD_LABEL]: z
    .string()
    .min(1, { message: "Workspace name is required" }),
  [WORKSPACE_ABOUT_FIELD_LABEL]: z.string().min(0),
  [WORKSPACE_VISIBILITY_FIELD_LABEL]: z.enum([
    VisibilityTypes.PUBLIC,
    VisibilityTypes.PRIVATE,
    VisibilityTypes.RESTRICTED
  ]),
  [CHAT_TAB_BASE]: z.object({
    currentThreadId: z.string(),
    agentId: z.string().optional()
  }),
  [WORKFLOW_USE_BASE]: z.object({
    workflowId: z.string(),
    componentId: z.string(),
    activeStep: z.number(),
    workflowProgress: z.record(
      z.object({
        inputs: z.record(z.string(), z.any()),
        outputs: z.record(z.string(), z.string()),
        componentId: z.string()
      })
    )
  }),
  [TOOL_TAB_BASE]: z.object({
    toolId: z.string(),
    componentId: z.string(),
    userInput: z.record(z.string(), z.any())
  })
});

const WorkspaceInner = ({
  children,
  workspace,
  isFetching
}: {
  children: React.ReactNode;
  workspace: TWorkspaceDataToSave;
  isFetching: boolean;
}) => {
  const { parsedObject, type } = useDefaultWorkspaceFormValues();

  const methods = useForm<Workspace>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(workspace),
    mode: "onChange"
  });

  useSetDefaultValues({ type, methods, parsedObject });
  useReset(methods, workspace, isFetching);
  useKeepFormWorkspaceIdInSync(methods);

  return (
    <FormProvider {...methods}>
      <WorkspaceAssetsFormProvider>{children}</WorkspaceAssetsFormProvider>
    </FormProvider>
  );
};

const WorkspaceFormProvider = ({ children }: { children: React.ReactNode }) => {
  const { data, isLoading, isFetching } = useGetDefaultWorkspaceFields();
  useResetWorspaceOnUnmount();

  if (isLoading) {
    return null;
  }
  return (
    <WorkspaceInner
      workspace={data?.workspace || DEFAULT_WORKSPACE}
      isFetching={isFetching}
    >
      {children}
    </WorkspaceInner>
  );
};

export default WorkspaceFormProvider;
