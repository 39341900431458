import { Container } from "@mui/material";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import ToggleSidebarButton from "../../../features/layout/sidebar/ToggleSidebarButton";
import { selectSidebarOpen } from "../../../features/layout/sidebar/sidebarSlice";

interface IRightSideContainerProps {
  topHeaderSection?: React.ReactNode;
  headerMidSection?: React.ReactNode;
  actionSection?: React.ReactNode;
  children: React.ReactNode;
}

const RightSideContainer: FC<IRightSideContainerProps> = ({
  children,
  topHeaderSection,
  headerMidSection,
  actionSection
}) => {
  const open = useSelector(selectSidebarOpen);
  return (
    <Container maxWidth={false} className="p-t-4px">
      <div className="flex justify-space-between">
        <div className="flex align-i-center w-100-percent flex-wrap">
          {!open && <ToggleSidebarButton />}
          {topHeaderSection}
          {headerMidSection}
        </div>
        {actionSection}
      </div>
      {children}
    </Container>
  );
};

export default RightSideContainer;
