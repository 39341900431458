import React from "react";
import { ErrorMessage } from "@hookform/error-message";

function ErrorContainer({
  fieldName
}: Readonly<{ className?: string; fieldName: string }>) {
  return (
    <ErrorMessage name={fieldName} render={({ message }) => <>{message}</>} />
  );
}

export default ErrorContainer;
