import React from "react";
import ToolsBlockTable from "./ToolsBlockTable";
import { useGetUserCreatedEntities } from "../../../../entities/hooks/useGetUserCreatedEntities";

const EntitiesCreatedByUserContainer = () => {
  const { userCreatedEntities } = useGetUserCreatedEntities(6);
  return <ToolsBlockTable toolsBlockEntities={userCreatedEntities} />;
};

export default EntitiesCreatedByUserContainer;
