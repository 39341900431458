import { IconButton } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { updateStepId } from "../workflowBuilderSlice";
import { CloseIcon } from "../../../../../globalTheme/icons/icons";

function CloseStepButton({ className = "" }: { className?: string }) {
  const dispatch = useDispatch();

  const close = () => {
    dispatch(updateStepId(undefined));
  };

  return (
    <IconButton size="small" onClick={close} className={className}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
}

export default CloseStepButton;
