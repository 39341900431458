import React from "react";
import WorkflowStepOutputs from "./WorkflowStepOutputs";
import ToolInputs from "./runTool/ToolInputs";

function ToolStepDetails() {
  return (
    <>
      <ToolInputs />
      <WorkflowStepOutputs />
    </>
  );
}

export default ToolStepDetails;
