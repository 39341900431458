import useGetSelectedStepIndex from "./useGetSelectedStepId";
import useGetSteps from "./useGetSteps";

const useGetPreviousSteps = () => {
  const steps = useGetSteps();
  const stepIndex = useGetSelectedStepIndex();
  return steps.slice(0, stepIndex);
};

export default useGetPreviousSteps;
