import { toolflowPrimaryColor } from "../../../../../../globalTheme/muiUtils/appTheme";
import styles from "./markerStyles.module.css";

function StartMarker() {
  return (
    <svg className={styles["marker-position"]}>
      <defs>
        <marker
          id="purple-circle"
          viewBox="0 0 10 10"
          refX="5"
          refY="5"
          markerWidth="10"
          markerHeight="10"
        >
          <circle
            cx="5"
            cy="5"
            r="4"
            fill="white"
            stroke={toolflowPrimaryColor}
            strokeWidth="2"
          />
        </marker>
      </defs>
    </svg>
  );
}

export default StartMarker;
