import { useSelector } from "react-redux";
import Guidelines from "../../legal/Guidelines";
import Privacy from "../../legal/Privacy";
import TermsOfService from "../../legal/TermsOfService";
import TermsSeparator from "./TermsSeparator";
import { selectSidebarOpen } from "./sidebarSlice";
import Docs from "../../legal/Docs";

function TermsOfServiceSidebar() {
  const open = useSelector(selectSidebarOpen);

  return (
    <div className="flex align-i-center p-v-16px justify-center ">
      {open ? (
        <>
          <TermsOfService typographyClassName="grey" />
          <TermsSeparator />
          <Privacy typographyClassName="grey" />
          <TermsSeparator />
          <Guidelines typographyClassName="grey" />
        </>
      ) : (
        <>
          <Docs typographyClassName="grey" />
        </>
      )}
    </div>
  );
}

export default TermsOfServiceSidebar;
