import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import type { SelectChangeEvent } from "@mui/material/Select";
import type { VisibilityTypes } from "@toolflow/shared";
import type { FormControlProps } from "@mui/material/FormControl";
import visibilityMenuItemsArray from "../helpers/visibilityMenuItemsArray";

export function UpdateVisibilitySelectBase({
  noMargin,
  formProps,
  disabled,
  handleSelectChange,
  visibility
}: {
  noMargin?: boolean;
  formProps?: FormControlProps;
  disabled?: boolean;
  handleSelectChange: (event: SelectChangeEvent<VisibilityTypes>) => void;
  visibility: VisibilityTypes;
}) {
  return (
    <FormControl
      margin={noMargin ? "none" : "normal"}
      size="small"
      {...formProps}
    >
      <InputLabel>Privacy</InputLabel>
      <Select
        name="Privacy"
        label="Privacy"
        id="Privacy"
        value={visibility}
        onChange={handleSelectChange}
        onClick={(e) => {
          e.stopPropagation();
        }}
        disabled={disabled}
      >
        {visibilityMenuItemsArray}
      </Select>
    </FormControl>
  );
}
