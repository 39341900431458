import React from "react";
import OpenAssetDialogButton from "../../../assetDialog/OpenAssetDialogButton";
import DeleteCurrentAssetButton from "../../../deleteAssetButtons/DeleteCurrentAssetButton";
import CopyInputText from "./CopyInputText";
import TextSettingsDropdown from "./TextSettingsDropdown";

const TextAssetIcons = () => {
  return (
    <div className="flex align-i-center show-on-hover">
      <CopyInputText />
      <DeleteCurrentAssetButton />
      <OpenAssetDialogButton />
      <TextSettingsDropdown />
    </div>
  );
};

export default TextAssetIcons;
