import React from "react";
import { EntityType, type ExternalToolFE } from "@toolflow/shared";
import ToolSettingsDropdown from "../../../../../tools/components/ToolSettingsDropdown";
import ToolDetailsButton from "../../../../../tools/components/toolDetailsButton/ToolDetailsButton";
import ToggleFavorite from "../../../../../../utilities/components/toggleFavorite/ToggleFavorite";
import { useAuthStore } from "../../../../../auth/hooks/useAuthStore";

function ExternalToolButton({
  tool,
  onClickHandler,
  isChatView = false
}: Readonly<{
  tool: ExternalToolFE;
  onClickHandler?: () => void;
  isChatView?: boolean;
}>) {
  const { isAuthenticated } = useAuthStore();

  // if toolDetailsButton gets passed an icon, even if it returns null, it will render a div
  const icon = !isChatView && isAuthenticated && (
    <ToggleFavorite
      id={tool._id}
      type={EntityType.TOOL}
      creatorId={tool.creator._id}
      inMarketplace={tool.isFeatured}
      favorited={tool.userFavorited}
    />
  );

  const secondaryAction = !isChatView && <ToolSettingsDropdown tool={tool} />;

  return (
    <ToolDetailsButton
      tool={tool}
      key={tool._id}
      icon={icon}
      secondaryAction={secondaryAction}
      onClickHandler={onClickHandler}
    />
  );
}
export default ExternalToolButton;
