import React from "react";
import { useGetInitialStateQuery } from "../../ToolflowAPI/rtkRoutes/initialStateApi";
import { useRerouteToBillingPage } from "./hooks/useRerouteToBillingPage";
import { useGetAllUserEntities } from "../entities/hooks/useGetAllUserEntities";
import { useNavigate } from "react-router-dom";
import { BILLING_CTA_ROUTE_PATH } from "../navigation/helpers/routePaths";

export function AuthenticationWrapper({
  children
}: {
  children: React.ReactNode;
}) {
  useGetInitialStateQuery();

  // we need user entities queries to always be subscribed to
  useGetAllUserEntities();
  const rerouteToBilling = useRerouteToBillingPage();
  const navigate = useNavigate();
  const location = window.location.pathname;
  if (rerouteToBilling && location !== BILLING_CTA_ROUTE_PATH) {
    navigate(BILLING_CTA_ROUTE_PATH);
  }
  return <>{children}</>;
}
