import { Skeleton } from "@mui/material";
import React from "react";

const ListRowLoading = ({ numOfRows }: { numOfRows: number }) => {
  const rows = [];
  for (let i = 0; i < numOfRows; i++) {
    rows.push(
      <div key={i} className="m-t-8px">
        <Skeleton
          variant="rectangular"
          className="border-radius-8px"
          height={40}
        />
      </div>
    );
  }
  return <>{rows}</>;
};

const TableSkeleton = ({
  numOfRows,
  className
}: {
  numOfRows: number;
  className?: string;
}) => {
  return (
    <div className={className}>
      <ListRowLoading numOfRows={numOfRows} />
    </div>
  );
};

export default TableSkeleton;
