import { useEffect, useRef } from "react";

const useIsSlashRef = (isSlash: boolean) => {
  const isSlashRef = useRef(isSlash);
  useEffect(() => {
    isSlashRef.current = isSlash;
  }, [isSlash]);
  return isSlashRef;
};

export default useIsSlashRef;
