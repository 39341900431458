import { useGetWorkspaceQuery } from "../../../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { useAuthStore } from "../../../../../features/auth/hooks/useAuthStore";
import { useParams } from "react-router-dom";
import { useMuiTipTapContext } from "../context/MuiTipTapContext";
import useValidateObject from "../../../../hooks/useValidateObject";

const useGetInitialWorkspace = () => {
  const { isLoading } = useAuthStore();
  const { muiTipTapProps } = useMuiTipTapContext();
  const { externalId = "" } = useParams();
  const { page, routeType } = muiTipTapProps;
  const { isLoading: hasValidated } = useValidateObject();

  // receive_workspace

  return useGetWorkspaceQuery(externalId, {
    skip: !(
      hasValidated &&
      page &&
      !isLoading &&
      routeType === "workspace" &&
      externalId
    )
  });
};

export default useGetInitialWorkspace;
