import type { CustomToolInputField } from "@toolflow/shared";
import { getToolInputFieldIndexById } from "../hooks/useGetToolInputFieldIndexById";
import { TSetToolInputFields } from "./ToolInputFieldsContext";
import { isInputWithOptions } from "../../../../../../../../../tools/components/editorToolCard/inputs/helpers/constants";

const useAddInputOption = ({
  setToolInputFields,
  inputs
}: {
  inputs: CustomToolInputField[];
  setToolInputFields: TSetToolInputFields;
}) => {
  const handleAddOption = (toolInputField: CustomToolInputField) => {
    if (!isInputWithOptions(toolInputField)) {
      console.log("Input field does not support options.");
      return;
    }

    const options = toolInputField.options || [];
    const updatedToolInputField = {
      ...toolInputField,
      options: [...options, ""]
    };
    const index = getToolInputFieldIndexById(inputs, toolInputField.id);
    const updatedToolInputFields = [...inputs];
    updatedToolInputFields[index] = updatedToolInputField;

    setToolInputFields(updatedToolInputFields);
  };

  return handleAddOption;
};

export default useAddInputOption;
