import { Box, IconButton, Stack } from "@mui/material";
import { ReactNode, useState } from "react";
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon,
  DeleteIcon
} from "../../../../../../../../../globalTheme/icons/icons";

export default function SimpleAccordion({
  header,
  children,
  onDelete
}: {
  header: ReactNode;
  children: ReactNode;
  onDelete?(): void;
}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div
      style={{
        position: "relative",
        padding: 8,
        borderRadius: 8,
        paddingBottom: 16,
        backgroundColor: "#FAF8F7"
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        style={{
          position: "absolute",
          right: 8,
          top: 8
        }}
      >
        {onDelete && (
          <IconButton
            aria-label="delete"
            size="small"
            style={{ borderRadius: 6 }}
            onClick={onDelete}
          >
            <DeleteIcon color="#12172961" size={14} />
          </IconButton>
        )}
        <IconButton
          onClick={() => setIsOpen((prev) => !prev)}
          aria-label="delete"
          style={{
            padding: 5,
            borderRadius: 6,
            border: "1px solid rgba(228, 223, 212, 0.50)"
          }}
        >
          {isOpen ? (
            <ArrowUpSmallIcon color="#12172961" size={14} />
          ) : (
            <ArrowDownSmallIcon color="#12172961" size={14} />
          )}
        </IconButton>
      </Stack>
      <div className="p-h-8px p-t-16px">
        {header}
        <Box
          className="p-t-16px"
          sx={{
            display: "grid",
            gridTemplateRows: isOpen ? "1fr" : "0fr",
            transition: "grid-template-rows 300ms ease"
          }}
        >
          <Box sx={{ overflow: "hidden" }}>{children}</Box>
        </Box>
      </div>
    </div>
  );
}
