import React from "react";
import DeleteSnippetButton from "./DeleteSnippetButton";
import EditSnippetButton from "../../EditSnippetButton";

function SnippetRowActions({ id }: { id: string }) {
  return (
    <>
      <EditSnippetButton id={id} />
      <DeleteSnippetButton id={id} />
    </>
  );
}

export default SnippetRowActions;
