import LoadingButton from "@mui/lab/LoadingButton";
import React, { useEffect, useRef, useState } from "react";
import { useToolbuilderContext } from "./context/ToolBuilderContext";
import {
  VisibilityTypes,
  type SaveToolResponseFE,
  type ButtonVariantTypes,
  type ButtonSizeTypes,
  type SaveToolBody
} from "@toolflow/shared";
import { ABOUT_CHARACTER_LENGTH } from "../../../tools/components/editorToolCard/inputs/helpers/constants";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import validateSaveFields from "./validateSaveFields";
import { useLocation } from "react-router-dom";
import { UpdateVisibilitySelectBase } from "../../../../utilities/components/sharing/updateVisibilitySelect/UpdateVisibilitySelectBase";
import { defaultToolName } from "./context/generateToolHelpers";
import { IconButton, type SelectChangeEvent } from "@mui/material";
import useHideResetTool from "./hooks/useHideResetTool";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../layout/alertMessageSnackbar/alertMessageSlice";
import { TOOLBUILDER_BASE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import useNavigateReplace from "../../../navigation/hooks/useNavigateReplace";
import AssignCategoriesFromToolBuilder from "./toolCategorization/AssignCategoriesFromToolBuilder";
import { useSaveToolMutation } from "../../../../ToolflowAPI/rtkRoutes/toolsApi";
import { SaveIcon } from "../../../../globalTheme/icons/icons";
import useGetDrawerNodeId from "./store/hooks/useGetDrawerNodeId";
import useSetNodeDrawer from "./store/hooks/useSetNodeDrawer";
import { UpdateEnableForkingBase } from "../../../../utilities/components/sharing/updateEnableForking/UpdateEnableForkingBase";

function SaveVersionDialog({
  className = "",
  size = "small",
  continueNavigating,
  resetBlocker,
  variant = "contained",
  text
}: Readonly<{
  className?: string;
  variant?: ButtonVariantTypes;
  size?: ButtonSizeTypes;
  continueNavigating?: () => void;
  resetBlocker?: () => void;
  text?: boolean;
}>) {
  const [open, setOpen] = useState(false);
  const { state, dispatch } = useToolbuilderContext();
  const dialogRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState("");
  const [about, setAbout] = useState("");
  const [saveTool] = useSaveToolMutation();
  const hideReset = useHideResetTool();
  const { toolId, currentState } = state;
  const [hasNameError, setHasNameError] = useState(false);
  const [visibility, setVisibility] = useState<VisibilityTypes>(
    VisibilityTypes.PUBLIC
  );
  const [enableForking, setEnableForking] = useState(false);
  const reduxDispatch = useDispatch();

  const { toolInputFields, toolOutputFields, blocks, edges, availableFields } =
    currentState;
  const location = useLocation();
  const navigate = useNavigateReplace();
  const openNodeId = useGetDrawerNodeId();
  const setNodeDrawer = useSetNodeDrawer();

  useEffect(() => {
    // If the name is the default tool name, then we want them to have to put in a name
    if (state.currentState.toolName !== defaultToolName) {
      setName(state.currentState.toolName);
    } else {
      setName("");
    }
    setAbout(state.currentState.toolAbout || "");
    setVisibility(state.currentState.visibility || VisibilityTypes.PUBLIC);
  }, [
    state.currentState.visibility,
    state.toolVersionId,
    state.currentState.toolName,
    state.currentState.toolAbout,
    open
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
    setHasNameError(false);
  };

  let openNodeLabel = "";
  if (openNodeId) {
    openNodeLabel = state.currentState.blocks.filter(
      (b) => b.id === openNodeId
    )[0]?.data?.label;
  }

  // LOOK HERE
  const handleSaveTool = async () => {
    const parseResponse = (resp: SaveToolResponseFE) => {
      dispatch({
        type: "RECEIVE_TOOL_VERSIONS",
        toolName: resp.tool.name,
        toolAbout: resp.tool.about ?? "",
        toolVersions: resp.toolVersions,
        main: resp.tool.main,
        creator: resp.tool.creator,
        toolId: resp.tool._id,
        tempViewport: state.tempViewport,
        visibility: resp.tool.visibility,
        tag: resp.tool.tag,
        enableForking: resp.tool.enableForking
      });

      // hacky -> the ids change on save, so we need to get the old openNodeId
      // however, labels need to be unique too, so we get the returned block label and get the new id
      if (openNodeId) {
        if (openNodeLabel) {
          setNodeDrawer(
            resp.toolVersions[resp.tool.main._id]?.blocks.filter(
              (b) => b.data.label === openNodeLabel
            )[0].id
          );
        }
      }

      // if new
      //    if tab
      //      navigate to tool id and continue with tab
      //    if somewhere else
      //      continue navigating

      // if old
      //    if tab
      //      continue navigating
      //    if somewhere else
      //      continue navigating

      const isNewTool = location.pathname === TOOLBUILDER_BASE_ROUTE_PATH;

      if (continueNavigating) {
        continueNavigating();
      } else if (isNewTool) {
        navigate(`${TOOLBUILDER_BASE_ROUTE_PATH}${resp.tool._id}`); // we want to replace it, so that navigation in redux doesnt have two entries
      }
    };

    const errors = validateSaveFields(
      toolInputFields,
      blocks,
      toolOutputFields
    );
    if (errors) {
      if (resetBlocker) {
        resetBlocker();
      }
      reduxDispatch(setErrorMessage(errors));
      dispatch({ type: "SET_TOOL_LOADING_FALSE" });
      return;
    }
    const saveParams: SaveToolBody = {
      tool: {
        name,
        about: about || "",
        visibility: visibility || VisibilityTypes.PUBLIC,
        tag: state.currentState.tag,
        enableForking
      },
      toolVersion: {
        blocks,
        edges,
        availableFields,
        toolInputFields,
        toolOutputFields
      }
    };

    // we need this reset tool because otherwise it checks if things are dirty when returning
    // and the answer is yes, so the navigation blocker pops up.
    dispatch({ type: "RESET_TOOL" });
    await saveTool({ toolData: saveParams, toolId })
      .unwrap()
      .then((resp) => {
        parseResponse(resp);
      });
  };

  const saveAndClose = () => {
    if (!name) {
      setHasNameError(true);
      return;
    } else {
      setHasNameError(false);
    }
    handleClose();
    handleSaveTool();
  };

  const handleClick = () => {
    if (!toolId) {
      setOpen(true);
    } else {
      handleSaveTool();
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<VisibilityTypes>) => {
    setVisibility(event.target.value as VisibilityTypes);
  };

  const handleEnableForking = (value: boolean) => {
    setEnableForking(value);
  };

  return (
    <>
      {text ? (
        <LoadingButton
          size={size}
          variant={variant}
          disabled={hideReset}
          onClick={handleClick}
          className={className}
        >
          Save
        </LoadingButton>
      ) : (
        <IconButton
          size={size}
          color="primary"
          disabled={hideReset}
          onClick={handleClick}
          className={className}
        >
          <SaveIcon fontSize="small" />
        </IconButton>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        ref={dialogRef}
      >
        <DialogTitle>Save Tool</DialogTitle>
        <DialogContent className="flex flex-column">
          <TextField
            autoFocus // This works, but doesn't work with React.StrictMode
            value={name}
            size="small"
            margin="normal"
            autoComplete="off"
            fullWidth
            onChange={(e) => setName(e.target.value)}
            label="Name"
            error={hasNameError && name === ""}
            helperText={
              hasNameError && name === "" ? "Please enter a name" : ""
            }
          />
          <TextField
            value={about}
            size="small"
            margin="normal"
            autoComplete="off"
            fullWidth
            onChange={(e) => setAbout(e.target.value)}
            rows={2}
            multiline
            label="Brief Description"
            inputProps={{
              maxLength: ABOUT_CHARACTER_LENGTH
            }}
            helperText={`Enter details to help people find and discover this tool in dropdowns, profile pages, and the marketplace. ${about.length}/${ABOUT_CHARACTER_LENGTH}`}
          />
          <UpdateVisibilitySelectBase
            handleSelectChange={handleSelectChange}
            visibility={visibility}
          />
          <UpdateEnableForkingBase
            handleEnableForking={handleEnableForking}
            enableForking={enableForking}
          />
          <AssignCategoriesFromToolBuilder />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={saveAndClose}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SaveVersionDialog;
