import { createTheme } from "@mui/material";
import utilityTheme from "../../../../globalTheme/muiUtils/utilityTheme";
import "./workspace.css";
import { lightButtonBorder } from "../../../../globalTheme/muiUtils/appTheme";

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    workspaceWrapper: true;
  }
}

declare module "@mui/material/styles" {
  interface PaperVariants {
    workspaceWrapper: React.CSSProperties;
  }

  interface PaperVariantsOptions {
    workspaceWrapper?: React.CSSProperties;
  }
}

const workspaceTheme = createTheme(utilityTheme, {
  components: {
    MuiPaper: {
      variants: [
        {
          props: { variant: "workspaceWrapper" },
          style: {
            borderRadius: "16px",
            border: `1px solid ${lightButtonBorder}`
          }
        }
      ],
      styleOverrides: {
        root: {
          background: "white"
        }
      }
    }
  }
});

export default workspaceTheme;
