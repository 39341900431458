import { ListSubheader, MenuItem } from "@mui/material";
import { CopyableField } from "@toolflow/shared";
import { useCustomToolInputFieldContext } from "../../../../../../../utilities/contexts/CustomToolInputFieldContext";
import {
  INPUT_TEXTFIELD_ARRAY,
  isSimilarInputType
} from "../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import {
  WORKFLOW_PREVIOUS_FIELD_INPUT,
  WORKFLOW_PREVIOUS_FIELD_OUTPUT
} from "../../../WorkflowBuilderConstants";
import serializePreviousField from "../serializePreviousField";
import useGetPreviousInputFields from "./useGetPreviousInputFields";

const useGetPreviousFieldOptions = () => {
  const { input: inputFromContext } = useCustomToolInputFieldContext();
  const enrichedInputFields = useGetPreviousInputFields();
  const seenFields = new Set<string>();

  const options: JSX.Element[] = [];
  enrichedInputFields.forEach((enrichedInputStep) => {
    const inputOptions: JSX.Element[] = [];
    const outputOptions: JSX.Element[] = [];
    enrichedInputStep.inputs
      .filter(
        (input) =>
          isSimilarInputType(inputFromContext.type, input.type) &&
          !seenFields.has(input.id)
      )
      .forEach((input) => {
        const structuredPreviousInput = {
          stepId: enrichedInputStep.step.id,
          fieldId: input.id,
          type: WORKFLOW_PREVIOUS_FIELD_INPUT
        };
        seenFields.add(input.id);
        const val = serializePreviousField(structuredPreviousInput);
        inputOptions.push(
          <MenuItem key={val} value={val}>
            {input.name}
          </MenuItem>
        );
      });
    if (inputOptions.length > 0) {
      options.push(
        <ListSubheader key={`${enrichedInputStep.step.id}-Inputs`}>
          {enrichedInputStep.step.name} - Inputs
        </ListSubheader>
      );
      options.push(...inputOptions);
    }
    enrichedInputStep.outputs
      .filter((output) => {
        if (
          INPUT_TEXTFIELD_ARRAY.includes(inputFromContext.type) &&
          output.type === CopyableField.LargeText
        ) {
          return true;
        } else {
          return false;
        }
      })
      .forEach((output) => {
        const structuredPreviousOutput = {
          stepId: enrichedInputStep.step.id,
          fieldId: output.id,
          type: WORKFLOW_PREVIOUS_FIELD_OUTPUT
        };
        const val = serializePreviousField(structuredPreviousOutput);
        outputOptions.push(
          <MenuItem key={val} value={val}>
            {output.name}
          </MenuItem>
        );
      });
    if (outputOptions.length > 0) {
      options.push(
        <ListSubheader key={`${enrichedInputStep.step.id}-Outputs`}>
          {enrichedInputStep.step.name} - Outputs
        </ListSubheader>
      );
      options.push(...outputOptions);
    }
  });
  return options;
};

export default useGetPreviousFieldOptions;
