import { FieldType, type TFieldsByType } from "@toolflow/shared";
import { scraperSettingsInitialStateV2 } from "../../../../context/initialStates";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";

export default function useScraperFieldsConfigs(id: string) {
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);
  const { preferredScraperResultType } = scraperSettingsInitialStateV2.settings;

  const configs: TFieldsByType = [
    {
      type: FieldType.PromptInputV2,
      label: "Url",
      subtitle: "Please type in a URL to scrape",
      config: {
        fieldKey: "settings.url",
        autofocus: true,
        placeholder: `Type "/" or click "+" for dynamic inputs`,
        chipsToUse
      }
    },
    {
      type: FieldType.DynamicSelectField,
      label: "Preferred Result Type",
      subtitle: "Select your preferred type of results",
      config: {
        typeKey: "settings.preferredScraperResultType.type",
        fieldKey: "settings.preferredScraperResultType.value",
        options: [
          { label: "Markdown", value: "markdown" },
          { label: "HTML", value: "html" },
          { label: "Text", value: "text" }
        ],
        defaultValue: preferredScraperResultType.value
      }
    }
  ];

  return configs;
}
