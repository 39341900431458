import { selectAllProfileTools } from "../../../ToolflowAPI/rtkRoutes/selectors/toolsSelectors";
import { useSelector } from "react-redux";
import { useGetProfileToolsQuery } from "../../../ToolflowAPI/rtkRoutes/toolsApi";
import { useGetCurrentUserProfileId } from "../../user/hooks/useGetCurrentUserProfileId";

export const useGetCurrentUserTools = () => {
  const profileId = useGetCurrentUserProfileId();
  const { isFetching, isLoading } = useGetProfileToolsQuery(profileId, {
    skip: !profileId
  }); // we need this to stay subscribed to the query
  const tools = useSelector(selectAllProfileTools(profileId));

  return {
    tools,
    loading: isLoading,
    fetching: isFetching
  };
};
