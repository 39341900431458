import {
  type SheetAsset,
  AssetSourceType,
  AssetValueType
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";

export const getNewSheetParams = (selectedToolId = ""): SheetAsset => {
  const id = uuidv4();
  return {
    name: `New sheet`,
    label: `New sheet`,
    source: AssetSourceType.Manual,
    value: {
      sheetData: [],
      selectedToolId
    },
    valueType: AssetValueType.Sheet,
    id
  };
};
