import useExecuteDeleteAsset from "./useExecuteDeleteAsset";
import useGetWorkspaceAssetIndexById from "./useGetWorkspaceAssetIndexById";
import useSetVisibleAsset from "./useSetVisibleAsset";
import useWorkspaceAssets from "./useWorkspaceAssets";

const useDeleteAsset = () => {
  const executeDelete = useExecuteDeleteAsset();
  const { setVisibleAsset } = useSetVisibleAsset();
  const getInputIndex = useGetWorkspaceAssetIndexById();
  const inputs = useWorkspaceAssets();
  const deleteAsset = (assetId: string) => {
    const currentIndex = getInputIndex(assetId);
    if (currentIndex === -1) {
      return;
    } else {
      // We queue this microtask, because execute delete is based on index
      // and if we don't have this, settingVisibleAsset will not work correctly
      queueMicrotask(() => {
        if (currentIndex === 0) {
          if (inputs.length > 1) {
            setVisibleAsset(inputs[currentIndex + 1].id);
          } else {
            setVisibleAsset("");
          }
        } else {
          setVisibleAsset(inputs[currentIndex - 1].id);
        }
      });
    }
    executeDelete(assetId);
  };
  return deleteAsset;
};

export default useDeleteAsset;
