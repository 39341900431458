import { Button, Chip, FormHelperText, TextField } from "@mui/material";
import { default as TagsInput } from "react-tagsinput";
import "./react-inputtags-override.css";
import React, { FC } from "react";

interface IAddKeywordsSettingsProps {
  handleChange: (newTags: string[]) => void;
  tags: string[];
  disableHelperText?: boolean;
  placeholder?: string;
}

const AddKeywordsSettings: FC<IAddKeywordsSettingsProps> = (props) => {
  const {
    tags = [],
    handleChange,
    disableHelperText,
    placeholder = "Add keywords"
  } = props;

  const unsavedTagPrefix = "unsaved:";
  return (
    <TagsInput
      value={tags}
      renderInput={(renderInputProps) => {
        const { onChange, value, onFocus, addTag } = renderInputProps;
        const tagsCopy = [...tags];

        return (
          <>
            <div className="flex align-i-center justify-space-between min-w-300px">
              <TextField
                onChange={(e) => {
                  const unsavedTagIndex = tagsCopy.findIndex((tag) =>
                    tag.includes(unsavedTagPrefix)
                  );
                  if (unsavedTagIndex !== -1) {
                    tagsCopy[unsavedTagIndex] =
                      unsavedTagPrefix + e.target.value;
                  } else {
                    tagsCopy.push(unsavedTagPrefix + e.target.value);
                  }
                  const latestTags = [...tagsCopy];
                  handleChange(latestTags);
                  onChange(e);
                }}
                value={value}
                fullWidth
                placeholder={placeholder}
                onKeyDown={(e) => {
                  const charAtCursor = e.key;
                  if (charAtCursor === "Enter") {
                    const unsavedTagIndex = tagsCopy.findIndex((tag) =>
                      tag.includes(unsavedTagPrefix)
                    );
                    if (unsavedTagIndex !== -1) {
                      tagsCopy[unsavedTagIndex] = tagsCopy[
                        unsavedTagIndex
                      ].replace(unsavedTagPrefix, "");
                      onChange({ target: { value: "" } } as React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >);
                      const latestTags = [...tagsCopy];
                      handleChange(latestTags);
                    }
                  }
                }}
                onFocus={onFocus}
              />
              <Button
                type="submit"
                variant="outlined"
                className="m-l-8px"
                onClick={() => {
                  if (value !== "") {
                    addTag(value);
                  }
                }}
              >
                Add
              </Button>
            </div>
            {!disableHelperText && (
              <FormHelperText>
                These keywords will be included every time the tool is run.
              </FormHelperText>
            )}
          </>
        );
      }}
      onChange={handleChange}
      renderTag={(renderTagProps) => {
        const { tag, key, onRemove, onClick } = renderTagProps;
        return (
          !tag.includes(unsavedTagPrefix) && (
            <Chip
              className="m-r-8px m-b-8px"
              label={tag}
              variant="outlined"
              key={key}
              onClick={onClick}
              color="primary"
              onDelete={() => onRemove(renderTagProps.key)}
            />
          )
        );
      }}
    />
  );
};

export default AddKeywordsSettings;
