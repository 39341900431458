import React from "react";
import useGreeting from "../../features/pages/home/hooks/useGreeting";
import { Typography, type TypographyProps } from "@mui/material";

const Greetings = (typographyProps: TypographyProps) => {
  const message = useGreeting();
  return (
    <Typography variant="h6" {...typographyProps}>
      {message}
    </Typography>
  );
};

export default Greetings;
