import { useSelector } from "react-redux";
import { useGetCurrentUserProfileId } from "../user/hooks/useGetCurrentUserProfileId";
import { useGetProfileAgentsQuery } from "../../ToolflowAPI/rtkRoutes/agentApi";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { EFeatureFlags } from "@toolflow/shared";
import { selectAllProfileAgents } from "../../ToolflowAPI/rtkRoutes/selectors/agentSelectors";

export const useGetCurrentUserAgents = () => {
  const addAgentEnabled = useFeatureFlagEnabled(EFeatureFlags.AGENT_BUILDER);
  const profileId = useGetCurrentUserProfileId();
  const { isFetching, isLoading } = useGetProfileAgentsQuery(profileId, {
    skip: !profileId || !addAgentEnabled
  }); // we need this to stay subscribed to the query
  const agents = useSelector(selectAllProfileAgents(profileId));

  return {
    agents,
    loading: isLoading || isFetching
  };
};
