/**
 * Converts a size in bytes to a string representation in megabytes (MB).
 * @param bytes The size in bytes.
 * @param decimalPlaces The number of decimal places to round to (default: 2).
 * @returns A string representing the size in MB.
 */
export function formatSizeInMB(bytes: number, decimalPlaces = 1): string {
  const megabytes = bytes / (1024 * 1024);
  const roundedMB = megabytes.toFixed(decimalPlaces);
  return `${roundedMB} MB`;
}
