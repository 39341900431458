import React from "react";

import ToolBuilderWrapper from "../../pages/builder/toolBuilder/ToolBuilderWrapper";
import LoginRoute from "../../auth/LoginRoute";
import ToolsPage from "../../pages/toolsPage/ToolsPage";
import WorkflowBuilderPage from "../../pages/builder/workflowBuilder/WorkflowBuilderPage";

import {
  EXTERNAL_TOOL_ROUTE_PATH,
  EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH,
  EXTERNAL_WORKFLOW_ROUTE_PATH,
  EXTERNAL_WORKSPACE_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  AGENT_BUILDER_ROUTE_PATH,
  AGENTS_ROUTE_PATH,
  LOGIN_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  SIGNUP_ROUTE_PATH,
  TOOLBUILDER_ROUTE_PATH,
  WORKFLOWBUILDER_ROUTE_PATH,
  WORKSPACE_ROUTE_PATH,
  SNIPPET_ROUTE_PATH,
  HOME_ROUTE_PATH_DEV,
  PROFILE_ROUTE_PATH,
  EXTERNAL_AGENT_ROUTE_PATH,
  BILLING_CTA_ROUTE_PATH
} from "./routePaths";
import ReroutePage from "../../pages/external/ReroutePage";
import HomePage from "../../pages/home/HomePage";
import KnowledgePage from "../../pages/knowledge/KnowledgePage";
import SnippetFormPage from "../../pages/knowledge/snippetsFormPage/SnippetFormPage";
import ProfilePage from "../../pages/profile/ProfilePage";
import NewWorkspacePage from "../../pages/workstation/components/newWorkspacePage/NewWorkspacePage";
import AgentsPage from "../../pages/agents/AgentsPage";
import AgentBuilderPage from "../../pages/builder/agentBuilder/AgentBuilderPage";
import BillingPageContentWrapper from "../../pages/profile/pages/billing/BillingPageContent";

const routeElementDict = {
  [TOOLBUILDER_ROUTE_PATH]: <ToolBuilderWrapper />,
  [BILLING_CTA_ROUTE_PATH]: <BillingPageContentWrapper />,

  [SNIPPET_ROUTE_PATH]: <SnippetFormPage />,

  [WORKFLOWBUILDER_ROUTE_PATH]: <WorkflowBuilderPage />,
  [KNOWLEDGE_ROUTE_PATH]: <KnowledgePage />,

  [AGENT_BUILDER_ROUTE_PATH]: <AgentBuilderPage />,
  [AGENTS_ROUTE_PATH]: <AgentsPage />,
  [LOGIN_ROUTE_PATH]: <LoginRoute />,
  [SIGNUP_ROUTE_PATH]: <LoginRoute signUp />,

  [PROFILE_ROUTE_PATH]: <ProfilePage />,

  [TOOLS_PAGE_ROUTE_PATH]: <ToolsPage />,
  [HOME_ROUTE_PATH_DEV]: <HomePage />,

  [WORKSPACE_ROUTE_PATH]: <NewWorkspacePage />,

  [EXTERNAL_TOOL_ROUTE_PATH]: <ReroutePage />,
  [EXTERNAL_AGENT_ROUTE_PATH]: <ReroutePage />,

  [EXTERNAL_WORKSPACE_ROUTE_PATH]: <ReroutePage />,

  [EXTERNAL_WORKFLOW_ROUTE_PATH]: <ReroutePage />,

  [EXTERNAL_TOOL_VERSION_RESPONSE_ROUTE_PATH]: <ReroutePage />
};

export default routeElementDict;
