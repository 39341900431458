import { Typography } from "@mui/material";
import React from "react";

const SnippetField = ({
  title,
  subheader,
  textField
}: {
  title: string;
  subheader: string;
  textField: React.ReactNode;
}) => {
  return (
    <div className="m-b-32px">
      <Typography variant="h7" className="m-b-8px">
        {title}
      </Typography>
      <Typography
        className="max-w-640px"
        sx={{ color: (theme) => theme.palette.text.secondary }}
      >
        {subheader}
      </Typography>
      {textField}
    </div>
  );
};

export default SnippetField;
