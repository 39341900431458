import { v4 as uuidv4 } from "uuid";

const getDefaultRowData = () => {
  return {
    componentId: uuidv4(),
    rowLoading: false,
    columnIdSelected: ""
  };
};

export default getDefaultRowData;
