import { Button, type SxProps } from "@mui/material";
import React, { useMemo } from "react";
import useSetActiveStep from "../hooks/useSetActiveStep";
import useGetWorkflowActiveStep from "../hooks/useGetWorkflowActiveStep";

function HandlePreviousButton({ sx }: { sx?: SxProps }) {
  const setActiveStep = useSetActiveStep();
  const activeStep = useGetWorkflowActiveStep();
  // we only want to render it if is is the first step,
  // but if the active step changes, the button will flash
  // this state just keeps track of whether it is the first step initally
  // so we dont get a flash

  const isFirstStep = useMemo(() => activeStep === 0, []);

  const handlePrevious = () => {
    setActiveStep(activeStep - 1);
  };

  if (isFirstStep) return null;

  return (
    <Button onClick={handlePrevious} sx={sx} disabled={activeStep === 0}>
      Back
    </Button>
  );
}

export default HandlePreviousButton;
