import useWorkspaceId from "../../../hooks/useWorkspaceId";
import { useHasSelectedEntity } from "../../../hooks/useHasSelectedEntity";
import useWorkspaceAssets from "../../../assets/useWorkspaceAssets";

export const useGetStarted = () => {
  const workspaceId = useWorkspaceId();
  const hasSelectedEntity = useHasSelectedEntity();
  const inputs = useWorkspaceAssets(); // don't use get started when there are assets already, this is particularly true when loading a workspace from a tool run (tvr)
  return !(workspaceId || hasSelectedEntity || inputs.length > 0);
};
