import React from "react";
import useSaveSnippet from "../../../pages/knowledge/hooks/useSaveSnippet";
import SaveSnippetButtonBase from "../../../pages/knowledge/uI/SaveSnippetButtonBase";
import useFormIsDirty from "../../../../utilities/hooks/useIsDirty";

const SaveSnippetButton = () => {
  const { saveSnippet, loading } = useSaveSnippet();
  const { isDirty } = useFormIsDirty();
  return (
    <SaveSnippetButtonBase
      disabled={!isDirty}
      loading={loading}
      onClick={saveSnippet}
    />
  );
};

export default SaveSnippetButton;
