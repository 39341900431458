import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../../stores/store";

const useIsMessageLoading = () => {
  const isMessageLoadingSelector = useSelector(
    (store: RootState) => store.chat.isLoading
  );
  return isMessageLoadingSelector;
};

export default useIsMessageLoading;
