import { FieldType, type TFieldsByType } from "@toolflow/shared";
import { knowledgeSettingsInitialState } from "../../context/initialStates/knowledgeInitialState";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";

export default function useKnowledgeFieldsConfig(id: string) {
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);
  const fields: TFieldsByType = [
    {
      type: FieldType.PromptInputV2,
      label: "Query",
      subtitle: "Set instructions for querying your knowledge base.",
      config: { fieldKey: "settings.query", chipsToUse }
    },
    {
      type: FieldType.DynamicInputField,
      label: "Count",
      subtitle: "Set maximum number of results to return.",
      config: {
        typeKey: "settings.count.type",
        fieldKey: "settings.count.value",
        htmlInputType: "number"
      }
    },
    {
      type: FieldType.DynamicSliderField,
      label: "Score",
      subtitle:
        "Adjust minimum confidence level of response. Higher level returns only the most accurate results.",
      config: {
        typeKey: "settings.minScore.type",
        fieldKey: "settings.minScore.value",
        defaultValue: knowledgeSettingsInitialState.minScore.value
      }
    }
  ];

  return fields;
}
