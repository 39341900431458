import { Dialog } from "@mui/material";
import React from "react";
import styles from "./fullScreenDialog.module.css";

const FullScreenDialog = ({
  innerSlot,
  open,
  setClosed
}: {
  innerSlot: React.ReactNode;
  open: boolean;
  setClosed: () => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={setClosed}
      className="w-100-percent"
      PaperProps={{
        className: `w-100-percent oflow-y-hidden ${styles["asset-expanded-paper"]}`
      }}
    >
      {innerSlot}
    </Dialog>
  );
};

export default FullScreenDialog;
