import { useEffect } from "react";
import useGetSteps from "./useGetSteps";
import useOpenStepById from "./useOpenStepById";

const useOpenInitialStepOnLoad = () => {
  const steps = useGetSteps();
  const openStep = useOpenStepById(steps[0]?.id);

  useEffect(() => {
    openStep();
  }, []);
};

export default useOpenInitialStepOnLoad;
