import React from "react";
import UpdateTextFieldBase from "../../../../utilities/components/textFields/UpdateTextFieldBase";
import type { IUpdateTextFieldContainerProps } from "@toolflow/shared";
import { AGENT_NAME_FIELD_LABEL } from "./constants/fields";
import { AGENT_NAME_REQUIRED_ERROR } from "./constants/errors";

const UpdateAgentNameTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder,
  showErrors
}: IUpdateTextFieldContainerProps) => {
  return (
    <UpdateTextFieldBase
      size={size}
      label={label}
      margin={margin}
      placeholder={placeholder}
      fieldLabel={AGENT_NAME_FIELD_LABEL}
      registerOptions={{ required: AGENT_NAME_REQUIRED_ERROR }}
      showErrors={showErrors}
    />
  );
};

export default UpdateAgentNameTextField;
