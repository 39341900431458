import type { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { RootState } from "../../../stores/store";
import { AGENT_TAG_TYPE, GET_AGENTS_TAG_TYPE } from "../../cacheTagConstants";
import { authenticatedApi } from "../../authenticatedAPI";

const invalidateAgentTags = (
  dispatch: ThunkDispatch<$TSAllowedAny, $TSAllowedAny, UnknownAction>,
  state: RootState,
  agentId?: string
) => {
  if (agentId) {
    dispatch(
      authenticatedApi.util.invalidateTags([
        GET_AGENTS_TAG_TYPE,
        {
          type: AGENT_TAG_TYPE,
          id: agentId
        }
      ])
    );
  }
};

export default invalidateAgentTags;
