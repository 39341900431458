import type { TWorkspaceDataToSave, Workspace } from "@toolflow/shared";
import type { UseFormReturn } from "react-hook-form";
import { useResetFromSessionClick } from "./useResetFromSessionClick";
import { useResetFromSidebar } from "./useResetFromSidebar";

// this handles when the workspace needs to rerender from the sidebar
// or from a session click on get started
const useReset = (
  methods: UseFormReturn<Workspace, $TSAllowedAny, undefined>,
  workspace: TWorkspaceDataToSave,
  isFetching: boolean
) => {
  useResetFromSessionClick(methods, workspace, isFetching);
  useResetFromSidebar(methods, workspace, isFetching);
};

export default useReset;
