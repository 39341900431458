import type { TMarketplaceUniversalEntity } from "@toolflow/shared";
import React, { FC } from "react";
import { IconButton, Typography, useTheme } from "@mui/material";
import { getCategoryIconAndLabel } from "../../../tools/components/searchToolsContainer/functions/getCategoryIconAndLabel";
import RemixWrapperIconWithTheme from "../../../../utilities/components/icons/RemixWrapperIconWithTheme";
import styles from "./entityCardSubHeader.module.css";
import { parsedClassNames } from "../../../../utilities/functions/parsedClassNames";

const cx = parsedClassNames.bind(styles);

interface IEntityCardSubHeaderProps {
  entity: TMarketplaceUniversalEntity;
}
const EntityCardSubHeader: FC<IEntityCardSubHeaderProps> = ({ entity }) => {
  const theme = useTheme();
  return (
    <div className={cx("flex align-i-center pos-relative", styles.subheader)}>
      {entity?.tag?.categories?.map((category) => {
        const categoryDict = getCategoryIconAndLabel(category);
        return (
          <React.Fragment key={categoryDict.label}>
            <IconButton className="p-r-2px">
              <RemixWrapperIconWithTheme
                Icon={categoryDict.icon}
                fill={theme.palette.primary.main}
                size={16}
              />
            </IconButton>
            <Typography variant="caption" color="primary">
              {categoryDict.label}
            </Typography>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default EntityCardSubHeader;
