import React from "react";
import { Card, CardContent, CardHeader, List, Typography } from "@mui/material";
import useGetSelectedStepToolId from "./hooks/useGetSelectedStepToolId";
import useGetToolFromQuery from "../../../../../tools/hooks/useGetToolFromQuery";
import ToolInputMapper from "./ToolInputMapper";

function ToolInputs() {
  const toolId = useGetSelectedStepToolId();
  const tool = useGetToolFromQuery(toolId);
  if (!tool) return null;
  return (
    <Card variant="outlined">
      <CardHeader title="Inputs" />
      <CardContent>
        <Typography>
          This tool uses the following inputs to function. Map these tool inputs
          to available fields from the dropdown menu or create them as new
          fields.
        </Typography>
        <List>
          {tool.main.toolInputFields.map((field, index) => (
            <ToolInputMapper
              inputField={field}
              key={field.id}
              isLast={tool.main.toolInputFields.length === index + 1}
            />
          ))}
        </List>
      </CardContent>
    </Card>
  );
}

export default ToolInputs;
