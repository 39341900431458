import React from "react";
import useToolflowNavigate from "../navigation/hooks/useToolflowNavigate";
import { WORKFLOWBUILDER_BASE_ROUTE_PATH } from "../navigation/helpers/routePaths";
import { EditIcon } from "../../globalTheme/icons/icons";
import RemixButton from "../../utilities/components/icons/RemixButton";

function EditWorkflowButton({
  workflowId,
  className = "",
  disabled
}: {
  workflowId: string;
  className?: string;
  disabled?: boolean;
}) {
  const navigate = useToolflowNavigate();

  if (!workflowId) return null;

  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation(); // necessary for ContentActionArea on ProfileToolCard
    navigate(`${WORKFLOWBUILDER_BASE_ROUTE_PATH}${workflowId}`, e);
  };

  return (
    <RemixButton
      text="Edit"
      onClick={handleNavigate}
      className={className}
      disabled={disabled}
      icon={EditIcon}
    />
  );
}

export default EditWorkflowButton;
