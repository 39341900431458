import { Chip } from "@mui/material";
import { IterationStartIcon } from "../../../../../../../../../globalTheme/icons/icons";

export default function IteratedChip() {
  return (
    <Chip
      icon={<IterationStartIcon size={14} color="#2196F3" />}
      label="Iterated"
      sx={{
        color: "#2196F3",
        backgroundColor: "#E3F2FD",
        border: "1px solid #90CAF9",
        paddingInline: 1
      }}
    />
  );
}
