import type { TWorkflowBuilderForm } from "@toolflow/shared";
import { WORKFLOW_STEPS_FIELD_LABEL } from "../../WorkflowBuilderConstants";
import { useFormContext } from "react-hook-form";

const useGetSteps = () => {
  const { watch } = useFormContext<TWorkflowBuilderForm>();
  return watch(WORKFLOW_STEPS_FIELD_LABEL);
};

export default useGetSteps;
