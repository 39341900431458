import { Divider } from "@mui/material";
import List from "@mui/material/List";
import { ThemeProvider } from "@mui/material/styles";
import { EFeatureFlags } from "@toolflow/shared";
import { useFeatureFlagEnabled } from "posthog-js/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAgentFillIcon,
  AddAgentIcon,
  AgentsFillIcon,
  AgentsIcon,
  BuilderFillIcon,
  BuilderIcon,
  HomeFillIcon,
  HomeIcon,
  KnowledgeFillIcon,
  KnowledgeIcon,
  ToolFillIcon,
  ToolIcon,
  WorkflowIcon
} from "../../../globalTheme/icons/icons";
import ToolflowLogoName from "../../../globalTheme/icons/logos/toolflowLogo/ToolflowLogoName";
import sidebarTheme from "../../../globalTheme/sidebarTheme";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import {
  addAgentMatcher,
  agentsMatcher,
  findToolsPageMatcher,
  homeMatcher,
  knowledgeMatcher,
  toolBuilderMatcher,
  workflowBuilderMatcher
} from "../../navigation/functions/matchers";
import {
  AGENT_BUILDER_BASE_ROUTE_PATH,
  AGENTS_ROUTE_PATH,
  HOME_NESTED_MAIN_ROUTE_PATH,
  KNOWLEDGE_ROUTE_PATH,
  TOOLBUILDER_BASE_ROUTE_PATH,
  TOOLS_PAGE_ROUTE_PATH,
  WORKFLOWBUILDER_BASE_ROUTE_PATH
} from "../../navigation/helpers/routePaths";
import SidebarActionButton from "./SidebarActionButton";
import SidebarButtonContainer from "./SidebarButtonContainer";
import { SidebarCredits } from "./SidebarCredits";
import SidebarIcon from "./SidebarIcon";
import StyledSidebarDrawer from "./StyledSidebarDrawer";
import TermsOfServiceSidebar from "./TermsOfServiceSidebar";
import ToggleSidebarButton from "./ToggleSidebarButton";
import styles from "./sidebar.module.css";
import { selectSidebarOpen, setSidebarClose } from "./sidebarSlice";

const cx = parsedClassNames.bind(styles);

function SidebarBrandIcon() {
  const open = useSelector(selectSidebarOpen);
  return (
    <div className={cx("brand-logo-container")}>
      {open ? (
        <div className="flex align-i-center justify-space-around h-100-percent">
          <ToolflowLogoName variant="large" />
          <ToggleSidebarButton />
        </div>
      ) : (
        <div className="flex align-i-center justify-center h-100-percent">
          <ToolflowLogoName variant="small" />
        </div>
      )}
    </div>
  );
}

function SidebarCreditsDisplay() {
  const open = useSelector(selectSidebarOpen);
  if (!open) return null;

  return (
    <List>
      <SidebarCredits />
    </List>
  );
}

interface SidebarProps {
  isMediumScreen: boolean;
  open: boolean;
  isAuthenticated: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({
  isMediumScreen,
  open,
  isAuthenticated
}) => {
  const workflowBuilderEnabled = useFeatureFlagEnabled(
    EFeatureFlags.WORKFLOW_BUILDER
  );
  const dispatch = useDispatch();
  const closeSidebar = () => {
    dispatch(setSidebarClose());
  };
  const addAgentEnabled = useFeatureFlagEnabled(EFeatureFlags.AGENT_BUILDER);

  const topButtons = [
    {
      route: HOME_NESTED_MAIN_ROUTE_PATH,
      icon: <SidebarIcon Icon={HomeIcon} />,
      selectedIcon: <SidebarIcon Icon={HomeFillIcon} />,
      text: "Home",
      authRequired: true,
      matcher: homeMatcher
    },
    {
      route: TOOLS_PAGE_ROUTE_PATH,
      icon: <SidebarIcon Icon={ToolIcon} />,
      selectedIcon: <SidebarIcon Icon={ToolFillIcon} />,
      text: "Tools",
      matcher: findToolsPageMatcher
    },
    {
      route: TOOLBUILDER_BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={BuilderIcon} />,
      selectedIcon: <SidebarIcon Icon={BuilderFillIcon} />,
      text: "Builder",
      authRequired: true,
      matcher: toolBuilderMatcher,
      callback: closeSidebar
    },
    {
      route: KNOWLEDGE_ROUTE_PATH,
      icon: <SidebarIcon Icon={KnowledgeIcon} />,
      selectedIcon: <SidebarIcon Icon={KnowledgeFillIcon} />,
      text: "Knowledge",
      authRequired: true,
      matcher: knowledgeMatcher
    },
    {
      route: AGENTS_ROUTE_PATH,
      icon: <SidebarIcon Icon={AgentsIcon} />,
      selectedIcon: <SidebarIcon Icon={AgentsFillIcon} />,
      text: "Agents",
      authRequired: true,
      matcher: agentsMatcher,
      featureFlagEnabled: addAgentEnabled
    },
    {
      route: AGENT_BUILDER_BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={AddAgentIcon} />,
      selectedIcon: <SidebarIcon Icon={AddAgentFillIcon} />,
      text: "Agent Builder",
      authRequired: true,
      matcher: addAgentMatcher,
      featureFlagEnabled: addAgentEnabled
    },
    {
      route: WORKFLOWBUILDER_BASE_ROUTE_PATH,
      icon: <SidebarIcon Icon={WorkflowIcon} />,
      selectedIcon: <SidebarIcon Icon={WorkflowIcon} />,
      text: "Workflow Builder",
      matcher: workflowBuilderMatcher,
      authRequired: true,
      featureFlagEnabled: workflowBuilderEnabled
    }
  ];

  return (
    <ThemeProvider theme={sidebarTheme}>
      <StyledSidebarDrawer
        variant="permanent"
        open={open}
        PaperProps={{ square: true, className: "scrollable-content" }}
        className={`sidebar flex-column justify-space-between flex ${
          isMediumScreen ? "h-100-percent" : "h-100vh"
        }`}
      >
        <SidebarBrandIcon />
        <Divider className={cx("divider")} />
        <div
          className={cx(
            "h-100-percent flex flex-column justify-space-between",
            { "p-h-16px": open }
          )}
        >
          <div>
            <SidebarActionButton className="m-v-12px flex justify-center" />
            <List>
              {topButtons.map((button, index) => {
                if (button.authRequired && !isAuthenticated) {
                  return null;
                }
                if (
                  "featureFlagEnabled" in button &&
                  !button.featureFlagEnabled
                ) {
                  return null;
                }
                return (
                  <SidebarButtonContainer
                    key={index}
                    route={button.route}
                    icon={button.icon}
                    selectedIcon={button.selectedIcon}
                    text={button.text}
                    matcher={button.matcher}
                    callback={button.callback}
                  />
                );
              })}
            </List>
          </div>
          <SidebarCreditsDisplay />
        </div>
        <Divider className={cx("divider")} />
        <TermsOfServiceSidebar />
      </StyledSidebarDrawer>
    </ThemeProvider>
  );
};

export default Sidebar;
