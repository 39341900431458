import { FormHelperText } from "@mui/material";
import { WORKFLOW_STEPS_FIELD_LABEL } from "../WorkflowBuilderConstants";
import React from "react";
import type { TWorkflowBuilderForm } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import ErrorContainer from "../../../../../utilities/components/errors/ErrorContainer";

function WorkflowStepsError({ className = "" }: { className?: string }) {
  const {
    formState: { errors }
  } = useFormContext<TWorkflowBuilderForm>();
  const hasError = errors.steps?.message;
  if (!hasError) return null;
  return (
    <FormHelperText error className={className}>
      <ErrorContainer fieldName={WORKFLOW_STEPS_FIELD_LABEL} />
    </FormHelperText>
  );
}

export default WorkflowStepsError;
