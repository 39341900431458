import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AGENT_BUILDER_BASE_ROUTE_PATH,
  BYPASS_BLOCKER_QUERY_PARAM
} from "../../../../../navigation/helpers/routePaths";

const useNavigateToNewAgent = (hasSaved: boolean, agentId?: string) => {
  const navigate = useNavigate();
  const navigateToNewAgent = () => {
    navigate(
      `${AGENT_BUILDER_BASE_ROUTE_PATH}${agentId}${BYPASS_BLOCKER_QUERY_PARAM}`
    );
  };
  useEffect(() => {
    if (hasSaved && agentId) {
      navigateToNewAgent();
    }
  }, [agentId]);
};

export default useNavigateToNewAgent;
