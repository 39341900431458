import React from "react";
import { IconButton, Tooltip, type TooltipProps } from "@mui/material";
import { IIconButtonProps } from "./settingsTypes";

const WrappedIconButton = ({
  children,
  IconButtonProps,
  TooltipProps,
  handleClick,
  open
}: {
  children: React.ReactNode;
  IconButtonProps?: IIconButtonProps;
  TooltipProps?: Omit<TooltipProps, "children">;
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement | HTMLElement>
  ) => void;
  open: boolean;
}) => {
  const IconButtonBase = (
    <IconButton
      id="basic-button"
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      onClick={handleClick}
      {...IconButtonProps}
    >
      {children}
    </IconButton>
  );

  return TooltipProps ? (
    <Tooltip {...TooltipProps}>{IconButtonBase}</Tooltip>
  ) : (
    IconButtonBase
  );
};

export default WrappedIconButton;
