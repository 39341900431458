import { NodeViewWrapper } from "@tiptap/react";
import { ChipNodeHtmlTags } from "@toolflow/shared";

const FlowThinkingViewerComponent = () => {
  return (
    <NodeViewWrapper
      className={`hidden ${ChipNodeHtmlTags.FlowThinkingComponent} dontTriggerBubble`}
    />
  );
};

export default FlowThinkingViewerComponent;
