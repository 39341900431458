import { type Content } from "@tiptap/core";
import { getNewStringAsset } from "./functions/getNewStringAsset";
import { useWorkspaceAssetsFieldArrayContext } from "../contexts/WorkspaceAssetsContext";
import useSetVisibleAsset from "./useSetVisibleAsset";

function useAddNewStringAsset() {
  const { append } = useWorkspaceAssetsFieldArrayContext();
  const { setVisibleAsset } = useSetVisibleAsset();

  const addNewAsset = (value?: Content) => {
    const newStringAsset = getNewStringAsset({ value });
    append(newStringAsset);
    setVisibleAsset(newStringAsset.id);

    return newStringAsset.id;
  };
  return addNewAsset;
}

export default useAddNewStringAsset;
