import { Box, Grid } from "@mui/material";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import DeleteAssetButtonFromList from "../../../deleteAssetButtons/DeleteAssetButtonFromList";
import AssetIndicator from "./AssetIndicator";
import styles from "./assetListItem.module.css";
import AssetText from "./assetText";
import useAssetListItem from "./hooks/useAssetListItem";

const cx = parsedClassNames.bind(styles);

const AssetListItem = () => {
  const {
    isLastButton,
    isSelected,
    loading,
    assetText,
    assetId,
    assetType,
    selectedSubAsset,
    configs,
    onClick,
    onSubAssetClick
  } = useAssetListItem();

  return (
    <>
      <Box
        className={cx(
          "parent-hover m-t-8px p-8px flex cursor-pointer pos-relative align-i-center",
          styles["asset-button-wrapper"],
          {
            "m-b-8px": !isLastButton,
            "bg-color-white": isSelected
          }
        )}
        onClick={onClick}
      >
        <div className={styles["asset-indicator-width"]}>
          <AssetIndicator loading={loading} assetType={assetType} />
        </div>
        <AssetText text={assetText} />
        <div className={cx("pos-absolute show-on-hover", styles.wrapper)}>
          <DeleteAssetButtonFromList assetId={assetId} />
        </div>
      </Box>

      {configs.splitOutputs &&
        configs.splittedOutputs.map((text, i) => {
          const subAssetId = `${assetId}/${i}`;
          return (
            <Grid key={text} container>
              <Grid item sm={2} sx={{ paddingLeft: 1 }}>
                <div
                  className={cx("nested-asset-line", {
                    "last-nested-asset-line":
                      i === configs.splittedOutputs.length - 1
                  })}
                />
              </Grid>
              <Grid item sm={10}>
                <Box
                  className={cx(
                    "parent-hover m-t-8px p-8px flex cursor-pointer pos-relative align-i-center",
                    styles["asset-button-wrapper"],
                    {
                      "m-b-8px": !isLastButton,
                      "bg-color-white": selectedSubAsset === subAssetId
                    }
                  )}
                  onClick={() => onSubAssetClick(subAssetId)}
                >
                  <div className={styles["asset-indicator-width"]}>
                    <AssetIndicator loading={loading} assetType={assetType} />
                  </div>
                  <AssetText text={text} />
                </Box>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};

export default AssetListItem;
