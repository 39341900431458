import React from "react";
import { useDeleteSecretMutation } from "../../../../../ToolflowAPI/rtkRoutes/secretApi";
import LLMKeysTable from "./LLMKeysTable";
import useGetLLMTableData from "./hooks/useGetLLMTableData";

const LLMKeysTableContainer = () => {
  const [deleteSecret] = useDeleteSecretMutation();

  const { tableRows } = useGetLLMTableData();
  const handleDeleteSecret = (secretId: string) => {
    deleteSecret(secretId);
  };
  console.log(tableRows);

  return (
    <LLMKeysTable rows={tableRows} handleDeleteSecret={handleDeleteSecret} />
  );
};

export default LLMKeysTableContainer;
