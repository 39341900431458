import { Stack, Typography } from "@mui/material";
import DataCardImage from "../../../../../../globalTheme/icons/images/data-card.svg";

export default function EmptyTestBlock({
  text,
  subText
}: {
  text: string;
  subText: string;
}) {
  return (
    <Stack
      spacing={1}
      sx={{
        marginTop: "20%",
        marginInline: 6,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <img src={DataCardImage} />
      <Typography variant="h7">{text}</Typography>
      <Typography variant="subtitleMedium" sx={{ textAlign: "center" }}>
        {subText}
      </Typography>
    </Stack>
  );
}
