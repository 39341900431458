import { type Content, generateHTML } from "@tiptap/core";
import useExtension from "../../../../components/textFields/workspace/hooks/useExtensions/useExtension";

const useConvertContentToHtml = () => {
  const extensions = useExtension();
  const convertContentToHtml = (content: Content) => {
    if (!content) return "";
    return typeof content === "string"
      ? content
      : generateHTML(content, extensions);
  };
  return convertContentToHtml;
};

export default useConvertContentToHtml;
