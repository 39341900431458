import { Typography } from "@mui/material";
import styles from "./emptyBase.module.css";
import { parsedClassNames } from "../../../../../../../../../../utilities/functions/parsedClassNames";
import DrawerBottom from "../../../../drawerBottom/DrawerBottom";

const cx = parsedClassNames.bind(styles);

function EmptyBase({
  firstText,
  secondText,
  title,
  button,
  icon = null
}: {
  firstText: string;
  secondText: string;
  title: string;
  button: React.ReactNode;
  icon?: React.ReactNode;
}) {
  return (
    <DrawerBottom
      mainContent={
        <>
          <div className={"flex justify-center"}>{icon}</div>
          <div className="flex flex-column flex-grow-1">
            <Typography variant="h7" align="center" className="m-t-8px">
              {title}
            </Typography>
            <Typography
              variant="body2"
              className={cx("m-t-10px m-b-24px", styles["empty-base"])}
              color="text.secondary"
              align="center"
            >
              {firstText}
            </Typography>
            <div className="flex justify-center">{button}</div>
            <Typography
              variant="body2"
              color="text.secondary"
              className={cx("p-v-24px", styles["empty-base"])}
              align="center"
            >
              {secondText}
            </Typography>
          </div>
        </>
      }
    />
  );
}

export default EmptyBase;
