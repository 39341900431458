import React from "react";
import PricingSection from "./PricingSection";
import UsageSection from "./UsageSection";
import { Container, ThemeProvider } from "@mui/material";
import profilePageTheme from "../../theme/profilePageTheme";
import LogoutButton from "../../../../auth/LogoutButton";

export const BillingPageContent = () => {
  return (
    <div className="p-b-32px">
      <PricingSection />
      <UsageSection />
    </div>
  );
};

// Note, we only have this at the /billing route, which is the CTA for free users
// Most of the time, people can't acccess this because it will go to Profile/billing
// The reason they are separate is that the profile heading is a bit weird on the CTA
// We can streamline this later
const BillingPageContentWrapper = () => {
  return (
    <div className="pos-relative">
      <ThemeProvider theme={profilePageTheme}>
        <div className="pos-absolute" style={{ top: 16, right: 16 }}>
          <LogoutButton />
        </div>
        <Container maxWidth="md">
          <BillingPageContent />
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default BillingPageContentWrapper;
