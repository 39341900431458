import {
  Box,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip
} from "@mui/material";
import React from "react";
import { parsedClassNames } from "../../../utilities/functions/parsedClassNames";
import styles from "./sidebarButton.module.css";

const cx = parsedClassNames.bind(styles);

interface SidebarButtonProps {
  text: string;
  icon: React.ReactNode;
  open?: boolean;
  showSelected?: boolean;
  onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
}

interface InternalIconAndTextProps {
  icon: React.ReactNode;
  text: string;
}

export const InternalIconAndText: React.FC<InternalIconAndTextProps> = ({
  icon,
  text
}) => {
  return (
    <>
      <ListItemIcon style={{ color: "inherit" }} className="min-w-42px">
        {icon}
      </ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </>
  );
};

const SidebarButton: React.FC<SidebarButtonProps> = ({
  text,
  icon,
  open,
  showSelected,
  onClick
}) => {
  if (open) {
    return (
      <>
        {onClick ? (
          <ListItemButton
            onClick={onClick}
            selected={showSelected}
            disableTouchRipple
          >
            <InternalIconAndText icon={icon} text={text} />
          </ListItemButton>
        ) : (
          <ListItem>
            <InternalIconAndText icon={icon} text={text} />
          </ListItem>
        )}
      </>
    );
  }

  return (
    <Tooltip title={text} placement="right">
      <Box className={`m-t-8px flex align-i-center justify-center`}>
        <IconButton
          onClick={onClick}
          className={cx("list-item", { "list-item-active": showSelected })}
        >
          {icon}
        </IconButton>
      </Box>
    </Tooltip>
  );
};

export default SidebarButton;
