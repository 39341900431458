import type { IntegrationPriceResponseFE } from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";

export const integrationPriceApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrationPrices: builder.query<IntegrationPriceResponseFE, void>({
      query: () => ({
        url: `integrationPrice`,
        method: "GET"
      })
    })
  })
});

export const apiKeyApiEndpoints = integrationPriceApi.endpoints;

export const { useGetIntegrationPricesQuery } = integrationPriceApi;
