import type { FileReference } from "@toolflow/shared";
import { DeleteIcon } from "../../../globalTheme/icons/icons";
import RemixButton from "../../../utilities/components/icons/RemixButton";
import SelectedFilePaper from "./SelectedFilePaper";
import { getFileType } from "./helpers/fileHelpers";

const SelectedFile = ({
  currentFile,
  setCurrentFile
}: {
  currentFile: FileReference;
  setCurrentFile: (file: FileReference | null) => void;
}) => {
  const clearFile = () => {
    setCurrentFile(null);
  };
  return (
    <SelectedFilePaper
      fileSizeInBytes={currentFile.fileSizeInBytes}
      action={
        <RemixButton
          icon={DeleteIcon}
          size={20}
          onClick={clearFile}
          className="m-r-8px"
        />
      }
      fileName={currentFile?.fileName || ""}
      fileType={getFileType(currentFile)}
    />
  );
};

export default SelectedFile;
