import React from "react";
import useAssetDialogOpen from "./hooks/useAssetDialogOpen";
import FullScreenDialog from "../../../../../../utilities/components/dialogs/fullScreenDialog/FullScreenDialog";

const AssetDialog = ({ innerSlot }: { innerSlot: React.ReactNode }) => {
  const { open, setClosed } = useAssetDialogOpen();
  return (
    <FullScreenDialog open={open} setClosed={setClosed} innerSlot={innerSlot} />
  );
};

export default AssetDialog;
