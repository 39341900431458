import React from "react";
import { DeleteIcon } from "../../../../../../../globalTheme/icons/icons";
import useDeleteNodeButton from "./useDeleteNodeButton";
import { classNames } from "../../../../../../../utilities/functions/parsedClassNames";

function DeleteNodeButton({ transparentBg }: { transparentBg?: boolean }) {
  const deleteNode = useDeleteNodeButton();
  return (
    <div
      onClick={deleteNode}
      className={classNames(
        "asset-button border-radius-6px flex align-i-center justify-center",
        { "bg-color-transparent-important": transparentBg }
      )}
      style={{ width: 22, height: 22 }}
    >
      <DeleteIcon size={14} />
    </div>
  );
}

export default DeleteNodeButton;
