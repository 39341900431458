import type {
  TrackEvent,
  TrackEventResponse,
  TrackExternalEvent
} from "@toolflow/shared";
import { authenticatedApi } from "../authenticatedAPI";

export const trackEventApi = authenticatedApi.injectEndpoints({
  endpoints: (builder) => ({
    trackEvent: builder.mutation<
      TrackEventResponse,
      TrackExternalEvent | TrackEvent
    >({
      query: (body) => ({
        url: "trackEvent",
        method: "POST",
        body
      }),
      async onQueryStarted(undefined, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          console.error("Error sending data:", error);
        }
      }
    })
  })
});

export const trackEventApiEndpoints = trackEventApi.endpoints;

export const { useTrackEventMutation } = trackEventApi;
