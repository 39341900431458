import { type DeepgramBlockPropsV2 } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useDeepgramFieldConfigs from "./useDeepgramFieldConfigs";

function DeepgramBlockInnerV2({
  data,
  id
}: Omit<DeepgramBlockPropsV2, "selected">) {
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const { label } = data;
  const config = useDeepgramFieldConfigs({ id, data });

  return (
    <>
      <FieldsByType configs={config} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </>
  );
}

export default DeepgramBlockInnerV2;
