import { useEffect, useRef, useState } from "react";

function useGetElementHeight() {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  const updateHeight = () => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  };

  useEffect(() => {
    updateHeight(); // Set the initial height

    // Create a MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(updateHeight);

    if (ref.current) {
      observer.observe(ref.current, {
        childList: true, // Watch for changes in direct children
        subtree: true, // Watch for changes in all descendants
        attributes: true // Watch for changes in attributes
      });
    }

    window.addEventListener("resize", updateHeight);

    return () => {
      observer.disconnect(); // Clean up the observer
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  return { ref, height };
}

export default useGetElementHeight;
