import type { TUniversalAgent } from "@toolflow/shared";
import React, { createContext, useContext } from "react";

interface AgentContextType {
  agent: TUniversalAgent;
  isDefault?: boolean;
}

const AgentContext = createContext<AgentContextType | undefined>(undefined);

export function AgentContextComponent({
  children,
  agent,
  isDefault
}: Readonly<{
  children: React.ReactNode;
  agent?: TUniversalAgent;
  isDefault?: boolean;
}>) {
  if (!agent) {
    return null;
  }
  return (
    <AgentContext.Provider value={{ agent, isDefault }}>
      {children}
    </AgentContext.Provider>
  );
}

export const useAgentContext = () => {
  const context = useContext(AgentContext);
  if (context === undefined) {
    throw new Error("useAgentContext must be used within a AgentProvider");
  }
  return context;
};

export default AgentContext;
