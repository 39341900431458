import {
  FieldType,
  type FieldConfig,
  type StructuredBlockPropsV2
} from "@toolflow/shared";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import CopyableLargeTextField from "../../../../../../../tools/components/editorToolCard/outputs/CopyableLargeTextField";
import FieldsByType from "../../../../common/fieldsByType/FieldsByType";
import { useToolbuilderContext } from "../../../../context/ToolBuilderContext";
import useSyncCurrentAndPrevSettings from "../../../../hooks/useSyncCurrentAndPrevSettings";
import useUpdateField from "../../../../useUpdateField";
import useToolbuilderAvailableFieldsChipsToUse from "../../../../../../../../utilities/components/textFields/tipTapTextField/hooks/useToolbuilderAvailableFieldsChipsToUse";

function StructuredBlockInnerV2({
  data,
  id
}: Omit<StructuredBlockPropsV2, "selected">) {
  const { label, settings } = data;
  useSyncCurrentAndPrevSettings(settings);
  const updateField = useUpdateField(id);
  const { state } = useToolbuilderContext();
  const { toolOutput } = state;
  const chipsToUse = useToolbuilderAvailableFieldsChipsToUse(id);

  const configs: FieldConfig = {
    id,
    data,
    updateField,
    fields: [
      {
        type: FieldType.PromptInputV2,
        label: "Source text",
        subtitle: "Paste your source text here",
        config: {
          fieldKey: "settings.input",
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          autofocus: true,
          chipsToUse
        }
      },
      {
        type: FieldType.JsonSchemaInput,
        label: "Configuration",
        subtitle: "Define what information to extract",
        config: {
          fieldKey: "settings.schema"
        }
      }
    ]
  };

  return (
    <div className="m-b-32px m-t-16px">
      <FieldsByType configs={configs} />
      {toolOutput && label && label in toolOutput && (
        <AccordionWrapper title="Previous Output" startsExpanded elevation={0}>
          <CopyableLargeTextField
            value={(toolOutput[label] as string) || ""}
            name={label}
          />
        </AccordionWrapper>
      )}
    </div>
  );
}

export default StructuredBlockInnerV2;
