import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";

function SaveSnippetButtonBase({
  disabled,
  loading,
  onClick
}: {
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
}) {
  return (
    <LoadingButton
      variant="contained"
      color="primary"
      size="large"
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      Save
    </LoadingButton>
  );
}

export default SaveSnippetButtonBase;
