import { CircularProgress, Typography } from "@mui/material";

function AuthenticationLoading() {
  return (
    <div className="w-100-percent justify-center align-i-center flex h-100vh flex-column bg-color-app-background">
      <CircularProgress />
      <Typography variant="h6" className="m-t-8px">
        Logging you in
      </Typography>
    </div>
  );
}

export default AuthenticationLoading;
