import CopyLinkMenuItem from "../../../utilities/components/dropdowns/menuItems/CopyLinkMenuItem";
import { AGENT } from "@toolflow/shared";

function CopyAgentLinkMenuItem({ agentId }: { agentId: string }) {
  return (
    <CopyLinkMenuItem objectId={agentId} text="Copy agent link" type={AGENT} />
  );
}

export default CopyAgentLinkMenuItem;
