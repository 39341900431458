import { useClearAnyEntity } from "../../../hooks/entities/useClearAnyEntity";
import useWorkspaceId from "../../../hooks/useWorkspaceId";

// we want to hide this button if the user has not saved a session yet
export const useClearAnyEntityButton = () => {
  const clearAnyEntity = useClearAnyEntity();
  const workspaceId = useWorkspaceId();
  return {
    clearAnyEntity,
    hideButton: !workspaceId
  };
};
