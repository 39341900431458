import { Stack } from "@mui/material";
import {
  BlockNode,
  type DeepgramBlockDataV2,
  type DeepgramBlockPropsV2
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";
import { FILE_UPLOAD } from "../../../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";
import DrawerBottom from "../../../../builderDrawer/components/drawerBottom/DrawerBottom";
import TestToolRun from "../../../../common/TestToolRun";
import TestOutput from "../../../components/testWrapper/TestOutput";
import useTest from "../../../hooks/useTest";
import useTestInputFromFieldsByType from "../../../hooks/useTestInputFromFieldsByType";
import DeepgramTestFields from "./DeepgramTestFields";
import useDeepgramFieldConfigs from "./useDeepgramFieldConfigs";

export default function TestDeepgramV2({
  data,
  id
}: Omit<DeepgramBlockPropsV2, "selected">) {
  const { fields: fieldsByType } = useDeepgramFieldConfigs({ id, data });

  const { userInput, setUserInput, fields } = useTestInputFromFieldsByType(
    fieldsByType,
    data,
    [data.settings]
  );

  const { action, label, text, endIcon, showUpgrade, loading, output } =
    useTest<DeepgramBlockDataV2>({
      data,
      id,
      customFields: [
        {
          name: data.settings.file,
          id: uuidv4(),
          type: FILE_UPLOAD
        }
      ],
      blockType: BlockNode.DeepgramBlockNode,
      fields: fields,
      userInput
    });

  return (
    <DrawerBottom
      mainContent={
        <Stack spacing={2} className="m-t-32px">
          <DeepgramTestFields
            uI={userInput}
            setUI={setUserInput}
            fields={fields}
            file={data.settings.file}
          />
          <TestOutput output={output} label={label} />
        </Stack>
      }
      rightSlot={
        <TestToolRun
          action={action}
          text={text}
          endIcon={endIcon}
          showUpgrade={showUpgrade}
          loading={loading}
        />
      }
    />
  );
}
