import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import React, { useState } from "react";
import getData from "./functions/getData";
import { useSheetContext } from "../SheetContext";

const AddRowsSelect = () => {
  const { selectedTool, setSheetData, sheetData } = useSheetContext();
  const [rowsValue, setRowsValue] = useState(1);
  if (!selectedTool) return null;
  return (
    <>
      <FormControl sx={{ width: 120 }}>
        <InputLabel id="number-of-rows-label">Number of rows</InputLabel>
        <Select
          labelId="number-of-rows-label"
          id="number-of-rows"
          value={rowsValue}
          label="Number of rows"
          size="small"
          fullWidth
          notched
          onChange={(e) =>
            setRowsValue(
              typeof e.target.value === "string"
                ? parseInt(e.target.value)
                : e.target.value
            )
          }
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={40}>40</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </FormControl>
      <Button
        size="small"
        variant="outlined"
        className="m-l-8px"
        onClick={() =>
          setSheetData(sheetData.concat(getData(rowsValue, selectedTool)))
        }
      >
        Add row{rowsValue > 1 ? "s" : ""}
      </Button>
    </>
  );
};
export default AddRowsSelect;
