import { useEffect, useRef } from "react";

const useClickOutsideListener = (close: () => void) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (["Enter", "Escape"].includes(event.key) && wrapperRef.current) {
        close();
      }
    }
    function handleClickOutside(event: MouseEvent) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as Node)
      ) {
        close();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [wrapperRef]);
  return wrapperRef;
};

export default useClickOutsideListener;
