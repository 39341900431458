import React from "react";
import RemixButton from "../../../../utilities/components/icons/RemixButton";
import { EditIcon } from "../../../../globalTheme/icons/icons";
import { WORKFLOWBUILDER_BASE_ROUTE_PATH } from "../../../navigation/helpers/routePaths";
import useToolflowNavigate from "../../../navigation/hooks/useToolflowNavigate";

function NavigateToWorkflowBuilderButton({
  workflowId,
  providedColor,
  className
}: {
  workflowId: string;
  providedColor?: string;
  className?: string;
}) {
  const navigate = useToolflowNavigate();
  const handleNavigate = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`${WORKFLOWBUILDER_BASE_ROUTE_PATH}${workflowId}`, e);
  };

  return (
    <RemixButton
      text="Edit"
      icon={EditIcon}
      onClick={handleNavigate}
      disabled={!workflowId}
      providedColor={providedColor}
      className={className}
    />
  );
}

export default NavigateToWorkflowBuilderButton;
