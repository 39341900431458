import { useSelector } from "react-redux";
import { selectLimitedProfileWorkspaces } from "../../../../ToolflowAPI/rtkRoutes/selectors/workspaceSelectors";
import { useGetProfileWorkspacesQuery } from "../../../../ToolflowAPI/rtkRoutes/workspaceApi";
import useGetCurrentUser from "../../../user/hooks/useGetCurrentUser";
import { useGetAllUserEntities } from "../../../entities/hooks/useGetAllUserEntities";

const useAllHomePageMainData = () => {
  const user = useGetCurrentUser();
  const { entities, loading } = useGetAllUserEntities();
  const profileId = user?._id || "";
  const workspaces = useSelector(selectLimitedProfileWorkspaces(profileId, 5));
  const { isFetching, isLoading: isWorkspaceLoading } =
    useGetProfileWorkspacesQuery(profileId, { skip: !profileId });
  return {
    homePageDataLength: [...entities, ...workspaces].length,
    homePageEntitiesLength: entities.length,
    isLoading: loading || isFetching || isWorkspaceLoading
  };
};

export default useAllHomePageMainData;
