export type TUseCase = string;

export const USE_CASES_ARRAY: readonly TUseCase[] = [
  "prospecting",
  "content creation",
  "call notes",
  "research",
  "web scraping",
  "other"
] as const;
