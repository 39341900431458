import { type JSONContent, generateJSON } from "@tiptap/core";
import useExtension from "../../../../components/textFields/workspace/hooks/useExtensions/useExtension";
import { getHtmlFromText } from "@toolflow/shared";

const useConvertStringToTipTap = () => {
  const extensions = useExtension();
  const convertTextToTipTap = (value: string): JSONContent => {
    return generateJSON(getHtmlFromText(value as string), extensions);
  };

  return convertTextToTipTap;
};

export default useConvertStringToTipTap;
