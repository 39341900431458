import useGetToolFromQuery from "../../../tools/hooks/useGetToolFromQuery";
import { useGetSelectedEntityType } from "./useGetSelectedEntityType";
import { useGetSelectedEntityId } from "./useGetSelectedEntityId";
import { EntityType } from "@toolflow/shared";

const useGetSelectedWorkspaceTool = (refetch = false) => {
  const entityId = useGetSelectedEntityId();
  const type = useGetSelectedEntityType();
  return useGetToolFromQuery(entityId, {
    refetchOnMountOrArgChange: refetch,
    skip: type !== EntityType.TOOL
  });
};

export default useGetSelectedWorkspaceTool;
