import React, { FC } from "react";
import ToolflowHeader from "../../../utilities/components/headers/toolflowHeader/ToolflowHeader";
import useGetHomePageContent from "./hooks/useGetHomePageContent";
import { Breadcrumbs } from "@mui/material";
import CenterContentContainer from "../../../utilities/components/containers/CenterContentContainer";
import { ArrowRightSmallIcon } from "../../../globalTheme/icons/icons";

interface IHomePageBaseProps {
  action?: React.ReactNode;
}
const HomePageBase: FC<IHomePageBaseProps> = ({ action }) => {
  const { content, nestedRouteLabel, nestedRouteBreadcrumb } =
    useGetHomePageContent();
  return (
    <ToolflowHeader title={nestedRouteLabel} action={action}>
      <CenterContentContainer className="m-t-16px">
        <Breadcrumbs
          separator={<ArrowRightSmallIcon fontSize="small" />}
          aria-label="breadcrumb"
          className="p-h-16px"
        >
          {nestedRouteBreadcrumb}
        </Breadcrumbs>
        {content}
      </CenterContentContainer>
    </ToolflowHeader>
  );
};

export default HomePageBase;
