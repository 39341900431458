import React from "react";
import useGetCurrentToolOutputFieldIndex from "./hooks/useGetCurrentToolOutputFieldIndex";
import { useToolbuilderContext } from "../../../../../context/ToolBuilderContext";
import { toolflowTextDisabledColor } from "../../../../../../../../../globalTheme/muiUtils/appTheme";
import useOpenOutputs from "../../../../../store/hooks/useOpenOutputs";
import DeleteIconButtonBase from "../../../../../blocks/components/deleteIconButtonBase/DeleteIconButtonBase";

function DeleteToolOutputFieldButton({
  disabled,
  size = 20,
  color = toolflowTextDisabledColor,
  className
}: {
  disabled?: boolean;
  size?: number;
  color?: string;
  className?: string;
}) {
  const { dispatch } = useToolbuilderContext();
  const index = useGetCurrentToolOutputFieldIndex();
  const openOutputs = useOpenOutputs();
  const handleDeleteToolOutputField = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    dispatch({
      type: "DELETE_TOOL_OUTPUT_FIELD",
      index
    });
    openOutputs();
  };

  return (
    <DeleteIconButtonBase
      className={className}
      onClick={handleDeleteToolOutputField}
      disabled={disabled}
      size={size}
      color={color}
    />
  );
}

export default DeleteToolOutputFieldButton;
