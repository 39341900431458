import { FormControl, Stack, Typography } from "@mui/material";
import {
  CustomToolInputFieldTypesEnum,
  type CustomToolInputField,
  type CustomToolInputFieldTypes,
  type DeepgramBlockDataV2,
  type FileFieldType
} from "@toolflow/shared";
import { useState } from "react";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import AddInputDialog from "../../../../addInput/AddInputDialog";
import DeepgramFileUploadSettings from "../../../../settings/deepgram/DeepgramFileUploadSettings";
import useUpdateField from "../../../../useUpdateField";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";
import styles from "../dynamicFieldWrapper/dynamicFieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export function FileField() {
  const { id, data, field } = useFieldsByTypeContext<
    FileFieldType,
    DeepgramBlockDataV2
  >();

  const { label, subtitle } = field;
  const { settings } = data;
  const { fieldKey } = field.config;

  const [openInputModal, setOpenInputModal] = useState(false);
  const [initialFieldType, setInitialFieldType] =
    useState<CustomToolInputFieldTypes>();

  const updateField = useUpdateField(id);

  const updateFromAdd = (value: CustomToolInputField) => {
    if (value.type === CustomToolInputFieldTypesEnum.FILE_UPLOAD) {
      updateField(value.name, "settings.file");
    }
    if (value.type === CustomToolInputFieldTypesEnum.TAGS_INPUT) {
      updateField(value.name, "settings.userKeywordsFieldKey");
    }
    if (value.type === CustomToolInputFieldTypesEnum.DOUBLE_TEXTFIELD) {
      updateField(value.name, "settings.userWordsToReplaceFieldKey");
    }
  };

  return (
    <>
      <FormControl margin="normal" fullWidth>
        <div className={cx("p-16px border-radius-8px", "container")}>
          <Stack spacing={1}>
            <div>
              <Typography variant="subtitle1" fontWeight="500">
                {label}
              </Typography>
              {subtitle && (
                <Typography variant="caption" color="text.secondary">
                  {subtitle}
                </Typography>
              )}
            </div>
            <DeepgramFileUploadSettings
              id={id}
              openInput={() => setOpenInputModal(true)}
              settings={settings}
              updateFieldKey={fieldKey}
              handleChangeInitialFieldType={(value) =>
                setInitialFieldType(value)
              }
            />
          </Stack>
        </div>
      </FormControl>
      <AddInputDialog
        openModal={openInputModal}
        setOpenModal={setOpenInputModal}
        callback={updateFromAdd}
        hideTypeDropdown
        initialType={initialFieldType}
      />
    </>
  );
}
