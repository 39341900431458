import { isAxiosError } from "axios";
import type { TBadRequest } from "@toolflow/shared";
import { isErrorWithMessage } from "./isErrorWithMessage";
import { isErrorWithData } from "./isErrorWithData";
import { isErrorWithError } from "./isErrorWithError";

const BAD_REQUEST_STRING_FE: TBadRequest = "Bad request";

export const getErrorMessage = (
  error: unknown,
  defaultErrorMessage = "An unknown error occurred"
): string => {
  let errorMessage = defaultErrorMessage;
  // Specifically handle Axios errors
  if (isAxiosError(error)) {
    errorMessage = error?.response?.data?.message || defaultErrorMessage;

    // if generic error thrown, use default error message
    if (error?.response?.data?.message === BAD_REQUEST_STRING_FE) {
      errorMessage = defaultErrorMessage;
    }
  } else if (isErrorWithError(error)) {
    return getErrorMessage(error.error, defaultErrorMessage);
  } else if (isErrorWithData(error)) {
    return getErrorMessage(error.data, defaultErrorMessage);
  } else if (isErrorWithMessage(error)) {
    errorMessage = error.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (typeof error === "string") {
    errorMessage = error;
  } else {
    errorMessage = defaultErrorMessage;
  }
  return errorMessage;
};
