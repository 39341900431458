import React from "react";
import { useParams } from "react-router-dom";
import NavigateToWorkflowButton from "./NavigateToWorkflowButton";

const NavigateToWorkflowButtonContainer = () => {
  const { workflowId = "" } = useParams();

  return (
    <NavigateToWorkflowButton workflowId={workflowId} className="m-l-8px" />
  );
};

export default NavigateToWorkflowButtonContainer;
