import {
  AssetSourceType,
  AssetValueType,
  type DallEOutputType,
  type TDallEAsset
} from "@toolflow/shared";
import { v4 as uuidv4 } from "uuid";

export const getNewDallEAsset = ({
  value,
  id,
  name,
  version = 1
}: {
  value?: DallEOutputType;
  id?: string;
  name?: string;
  version?: number;
}): TDallEAsset => {
  return {
    label: name || `New image asset`,
    name: name || `New image asset`,
    source: AssetSourceType.Manual,
    value: value || [],
    valueType: AssetValueType.DallE,
    id: id || uuidv4(),
    data: {
      version
    }
  };
};
