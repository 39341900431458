import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_ASSETS_FIELD_LABEL } from "../helpers/workspaceConstants";

const useWorkspaceAssets = () => {
  const formContext = useFormContext<TWorkspaceDataToSave>();
  if (!formContext) {
    return [];
  }
  const { watch } = formContext;
  return watch(WORKSPACE_ASSETS_FIELD_LABEL) || [];
};

export default useWorkspaceAssets;
