import type { RowUserInputDictType } from "@toolflow/shared";
import { useUpdateQueue } from "./useUpdateQueue";
import { useEffect } from "react";
import { useSheetContext } from "../../SheetContext";

const useUpdateSheetDataQueue = () => {
  const { setSheetData } = useSheetContext();
  const { queueUpdate, processNextUpdate } =
    useUpdateQueue<RowUserInputDictType[]>();
  useEffect(() => {
    const handleUpdate = () => {
      const updateFunction = processNextUpdate();
      if (updateFunction) {
        setSheetData((currentState) => updateFunction(currentState));
      }
    };

    // Consider setting up a more appropriate trigger for updates if needed
    handleUpdate();
  }, [processNextUpdate]);

  return { queueUpdate };
};

export default useUpdateSheetDataQueue;
