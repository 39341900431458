import { StyledEngineProvider } from "@mui/material";
import React from "react";
import InitializedAuthProvider from "../infrastructure/auth0/InitializedAuthProvider";
import { Providers } from "./components/Providers";

const RootComponent = () => {
  return (
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <InitializedAuthProvider>
          <Providers />
        </InitializedAuthProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
};

export default RootComponent;
