import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  Skeleton,
  Typography,
  type SxProps,
  ListItemText,
  ListItemSecondaryAction
} from "@mui/material";
import React from "react";
import { useAuthStore } from "../../../features/auth/hooks/useAuthStore";
import type { TMarginTypes } from "@toolflow/shared";

function ToolflowSkeletonWrapper({
  children,
  variant = "text",
  sx = {},
  width,
  height,
  loading = false,
  isTextField = false,
  margin = "none"
}: {
  children?: React.ReactNode;
  sx?: SxProps;
  variant?: "text" | "circular" | "rectangular" | "rounded";
  width?: string | number | undefined;
  height?: number;
  loading?: boolean;
  isTextField?: boolean;
  margin?: TMarginTypes;
}) {
  const { isLoading } = useAuthStore();
  let computedHeight = height;
  let computedVariant = variant;
  let computedSx = sx;

  if (isTextField) {
    computedHeight = 40;
    computedVariant = "rounded";
  }

  if (margin === "normal") {
    computedSx = { ...sx, marginTop: 2, marginBottom: 1 };
  } else if (margin === "dense") {
    computedSx = { ...sx, marginTop: 1, marginBottom: 0.5 };
  }

  if (isLoading || loading) {
    return (
      <Skeleton
        sx={computedSx}
        variant={computedVariant}
        width={width}
        height={computedHeight}
      />
    );
  }

  return <>{children}</>;
}

export const LoadingTool = () => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant="h6">
            <Skeleton className="w-100-percent max-w-70-percent" />
          </Typography>
        }
        secondary={
          <Typography variant="body2">
            <Skeleton className="w-100-percent max-w-70-percent" />
          </Typography>
        }
        disableTypography
      />
      <ListItemSecondaryAction className="flex align-i-center">
        <Skeleton variant="rounded" className="m-r-8px" width={64}>
          <Avatar />
        </Skeleton>
        <Skeleton variant="circular">
          <Avatar />
        </Skeleton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export function ToolsLoading() {
  return (
    <List>
      <Skeleton className="m-l-8px">
        <ListSubheader>Favorites</ListSubheader>
      </Skeleton>
      <LoadingTool />
      <LoadingTool />
      <LoadingTool />
      <LoadingTool />
      <LoadingTool />
    </List>
  );
}

export default ToolflowSkeletonWrapper;
