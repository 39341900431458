import { useRichTextEditorContext } from "mui-tiptap";
import { Mark } from "@tiptap/pm/model";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";

const useHandleClickMenuControl = ({
  hasDeletedSlash,
  setHasDeletedSlash
}: {
  hasDeletedSlash: boolean;
  setHasDeletedSlash: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isSlash, setIsSlash } = useMuiTipTapContext();
  const editor = useRichTextEditorContext();
  // we do this so that we can delete the slash on the first instance
  // when someone has clicked a button
  const handleClick = () => {
    if (!hasDeletedSlash && isSlash && editor) {
      // Capture the marks that are currently on the range
      const { from, to } = editor.state.selection;
      const marks =
        editor.state.storedMarks || editor.state.selection.$from.marks() || [];
      let marksBeforeCursor: readonly Mark[] = [];
      try {
        marksBeforeCursor = editor.state.doc.resolve(from - 1).marks();
      } catch {
        marksBeforeCursor = [];
      }
      const uniqueMarks = new Set([...marks, ...marksBeforeCursor]);
      const differentMarks = Array.from(uniqueMarks).filter(
        (mark) => !(marks.includes(mark) && marksBeforeCursor.includes(mark))
      );

      let chain = editor.chain().deleteRange({ from: to - 1, to });

      // we need to reset the marks. When you untoggle a mark, "marks" becomes [], so we need
      // to find the mark two before it and then compare the two. If there is an extra mark,
      // it means the mark has been toggled on, if there is one fewer mark, it has been toggled off
      differentMarks.forEach((mark) => {
        chain = chain.toggleMark(mark.type.name);
      });

      chain
        .focus()
        // this is a hack, but for some reason, the popper re-renders when
        // clicking a button for the first time. This adds a zeroWidth space
        // to make sure the popper rerenders in the correct spot. I've tried updating the selection
        // forcing rerenders etc. This seems to work the best
        .insertContentAt({ from: to - 1, to: to - 1 }, "\u200B", {
          updateSelection: true
        })
        .run();
      setHasDeletedSlash(true);
    }
    setIsSlash(false);
  };
  return handleClick;
};

export default useHandleClickMenuControl;
