export const AGENT_DESCRIPTION_FIELD_LABEL = "description";
export const AGENT_NAME_FIELD_LABEL = "name";
export const AGENT_VISIBILITY_FIELD_LABEL = "visibility";
export const AGENT_ENABLE_FORKING_FIELD_LABEL = "enableForking";
export const AGENT_TAG_FIELD_LABEL = "tag";
export const AGENT_INSTRUCTIONS_FIELD_LABEL = "instructions";
export const AGENT_MODEL_FIELD_LABEL = "model";
export const AGENT_TEMPERATURE_FIELD_LABEL = "temperature";
export const AGENT_TOOLS_FIELD_LABEL = "toolIds";

export const AGENT_TAG_USE_CASES_FIELD_LABEL =
  `${AGENT_TAG_FIELD_LABEL}.useCases` as const;
export const AGENT_TAG_CATEGORIES_FIELD_LABEL =
  `${AGENT_TAG_FIELD_LABEL}.categories` as const;
