import { useMaterialReactTable } from "material-react-table";
import ToolbarInternalActions from "../ToolbarInternalActions";
import RowActions from "../RowActions";
import EmptyRowsFallback from "../EmptyRowsFallback";
import React from "react";
import { BottomToolbarCustomActions } from "../BottomToolbarCustomActions";
import useColumnVisibility from "./useColumnVisibility";
import { useSheetContext } from "../../SheetContext";
import useUnhighlightCell from "./useUnhighlightCell";
import useColumns from "./useColumns";
import useHandleSheetPaste from "./useHandleSheetPaste";
import useRunSingleTool from "./useRunSingleTool";
import useHandleRunMultipleRows from "./useHandleRunMultipleRows";
import useUpdateTableInstanceRef from "./useUpdateTableInstanceRef";

const useGetMaterialTable = () => {
  const { sheetData, selectedTool } = useSheetContext();
  const columnVisibility = useColumnVisibility();
  useUnhighlightCell();

  const { columns, columnMap, columnOrder, setColumnOrder } = useColumns();

  useHandleSheetPaste({
    columns,
    columnMap
  });

  const runSingleTool = useRunSingleTool({
    columnMap
  });

  const handleRunMultipleRows = useHandleRunMultipleRows({ columnMap });

  const materialTable = useMaterialReactTable({
    columns,
    state: {
      showAlertBanner: false,
      columnOrder,
      columnVisibility
    },
    initialState: {
      pagination: {
        pageSize: 100,
        pageIndex: 0
      }
    },
    onColumnOrderChange: setColumnOrder,
    data: sheetData, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableRowSelection: true,
    enableColumnOrdering: true,
    muiTablePaperProps: ({ table }) => ({
      //not sx
      style: {
        zIndex: table.getState().isFullScreen ? 1201 : undefined
      }
    }),
    muiTableContainerProps: () => ({
      className: `sheetTableContainer`
    }),
    enableRowVirtualization: true,
    enablePagination: false,
    rowVirtualizerOptions: {
      overscan: 20 //adjust the number or rows that are rendered above and below the visible area of the table
      // estimateSize: () => 100 //if your rows are taller than normal, try tweaking this value to make scrollbar size more accurate
    },
    enableEditing: true,
    selectAllMode: "all",
    renderEmptyRowsFallback: () => <EmptyRowsFallback />,
    enableSelectAll: selectedTool ? true : false,
    enableDensityToggle: false,
    enableRowActions: true,
    positionCreatingRow: "bottom",
    renderToolbarInternalActions: ({ table }) => (
      <ToolbarInternalActions
        table={table}
        handleRunMultipleRows={handleRunMultipleRows}
      />
    ),
    enableStickyHeader: true,
    enableRowPinning: false,
    // rowPinningDisplayMode: "select-sticky",
    enableColumnActions: false,
    displayColumnDefOptions: {
      "mrt-row-select": {
        header: "",
        grow: false,
        muiTableBodyCellProps: () => ({ className: "p-v-0px" })
      },
      "mrt-row-actions": {
        header: "",
        size: 160,
        grow: false,
        muiTableBodyCellProps: () => ({ className: "p-v-0px" })
      }
    },
    layoutMode: "grid",
    createDisplayMode: "custom",
    enableColumnResizing: true,
    mrtTheme: {
      selectedRowBackgroundColor: "#FFFFFF"
    },

    positionToolbarAlertBanner: "none",
    renderRowActions: ({ row }) => (
      <RowActions row={row} runSingleTool={runSingleTool} />
    ),
    getRowId: (originalRow) => originalRow.componentId,
    renderBottomToolbarCustomActions: ({ table }) => (
      <BottomToolbarCustomActions
        table={table}
        handleRunMultipleRows={handleRunMultipleRows}
      />
    )
  });

  useUpdateTableInstanceRef(materialTable);

  return materialTable;
};

export default useGetMaterialTable;
