// alertMessageSlice.ts
import { createSlice } from "@reduxjs/toolkit";
import { setErrorMessage, setSuccessMessage } from "../../../stores/actions";

type MessageType = TMessageError | TMessageSuccess | "warning" | "info" | null;
type TMessageSuccess = "success";
type TMessageError = "error";
const MESSAGE_SUCCESS: TMessageSuccess = "success";
const MESSAGE_ERROR: TMessageError = "error";

interface IAlertMessageInitialState {
  message: string;
  messageType: MessageType;
}

const initialState: IAlertMessageInitialState = {
  message: "",
  messageType: null
};

export type TAlertMesssageReducerName = "alertMessage";
export const ALERT_MESSAGE_REDUCER_NAME: TAlertMesssageReducerName =
  "alertMessage";

const alertMessageSlice = createSlice({
  name: ALERT_MESSAGE_REDUCER_NAME,
  initialState,
  reducers: {
    // setSuccessMessage(state, action: PayloadAction<string>) {
    //   state.message = action.payload;
    //   state.messageType = MESSAGE_SUCCESS;
    // },
    // setErrorMessage(state, action: PayloadAction<string>) {
    //   state.message = action.payload;
    //   state.messageType = MESSAGE_ERROR;
    // },
    clearAlert(state) {
      state.message = "";
      state.messageType = null;
    }
  },

  // we need this while we are still refactoring tempLoading
  extraReducers: (builder) => {
    builder.addCase(setSuccessMessage, (state, action) => {
      state.message = action.payload;
      state.messageType = MESSAGE_SUCCESS;
    });
    builder.addCase(setErrorMessage, (state, action) => {
      state.message = action.payload;
      state.messageType = MESSAGE_ERROR;
    });
  }
});

export { setErrorMessage, setSuccessMessage } from "../../../stores/actions";

export const { clearAlert } = alertMessageSlice.actions;

export default alertMessageSlice.reducer;
