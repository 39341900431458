import AssetName from "../AssetName";
import StructuredAssetIcons from "./StructuredAssetIcons";

export default function StructuredAssetHeader({ name }: { name: string }) {
  return (
    <div className="flex justify-space-between align-i-center p-v-24px asset-max-width m-h-auto">
      <AssetName text={name} />
      <StructuredAssetIcons />
    </div>
  );
}
