import type { EditorView } from "@tiptap/pm/view";
import executeSlash from "../../keyboardEvents/executeSlash";
import { useMuiTipTapContext } from "../../context/MuiTipTapContext";
import useIsLoadingRef from "../useIsLoadingRef";
import type { RichTextEditorRef } from "mui-tiptap";

const useHandleKeysForBubble = (rteRef: React.RefObject<RichTextEditorRef>) => {
  const isLoadingRef = useIsLoadingRef();
  const { setIsSlash, setShouldRenderBubble } = useMuiTipTapContext();

  const handleKeysForBubble = ({
    view,
    key
  }: {
    view: EditorView;
    key: string;
  }) => {
    if (key === "/") {
      executeSlash({
        view,
        isLoadingRef,
        rteRef,
        setIsSlash,
        setShouldRenderBubble
      });
    }
    if (key === "Escape") {
      setShouldRenderBubble(false);
    }
  };
  return handleKeysForBubble;
};

export default useHandleKeysForBubble;
