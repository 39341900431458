import React from "react";
import RunToolCard from "./RunToolCard";
import RunWorkflowCard from "./RunWorkflowCard";
import { EntityType } from "@toolflow/shared";

export function EntityBody({ entityType }: { entityType: EntityType | "" }) {
  if (entityType === EntityType.TOOL) {
    return <RunToolCard />;
  }
  if (entityType === EntityType.WORKFLOW) {
    return <RunWorkflowCard />;
  }
  return null;
}
