import { generateText, type JSONContent } from "@tiptap/core";
import type { UpdateSType } from "@toolflow/shared";
import useExtension from "../../../../../utilities/components/textFields/workspace/hooks/useExtensions/useExtension";

/**
 * Custom hook that validates JSON content for the instructions field
 * @param property - The property name to check against
 * @returns A function that validates whether content should be sent
 * - Returns true if property is not instructions field
 * - Returns true if property is instructions field and has text content
 * - Returns false if property is instructions field and has no text
 */
export const useSendJSONContent = (property: string) => {
  const extensions = useExtension();

  const sendJSONContent = (updateValue: UpdateSType, dataProperty: string) => {
    if (dataProperty === property) {
      if (!!generateText(updateValue as JSONContent, extensions)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  return sendJSONContent;
};
