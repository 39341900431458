import React from "react";
import MuiAlert from "@mui/material/Alert";

type AlertProps = React.ComponentProps<typeof MuiAlert>;

// prettier-ignore
const StyledAlert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default StyledAlert;
