import { AGENT, EntityType } from "@toolflow/shared";
import useGetAgentSelectorOptions from "./useGetAgentSelectorOptions";
import useGetToolSelectorOptions from "./useGetToolSelectorOptions";
import useGetWorkflowSelectorOptions from "./useGetWorkflowSelectorOptions";

const useGetAllEntityOptions = ({
  disableWorkflowsOptions,
  disableToolsOptions,
  disableAgentsOptions
}: {
  disableWorkflowsOptions?: boolean;
  disableToolsOptions?: boolean;
  disableAgentsOptions?: boolean;
}) => {
  const toolOptions = useGetToolSelectorOptions();
  const workflowOptions = useGetWorkflowSelectorOptions();
  const agentOptions = useGetAgentSelectorOptions();

  let allOptions = [...toolOptions, ...workflowOptions, ...agentOptions];

  if (disableWorkflowsOptions) {
    allOptions = allOptions.filter(
      (option) => option.type !== EntityType.WORKFLOW
    );
  }
  if (disableToolsOptions) {
    allOptions = allOptions.filter((option) => option.type !== EntityType.TOOL);
  }
  if (disableAgentsOptions) {
    allOptions = allOptions.filter((option) => option.type !== AGENT);
  }

  return allOptions;
};

export default useGetAllEntityOptions;
