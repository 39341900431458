import DeleteCurrentAssetButton from "../../deleteAssetButtons/DeleteCurrentAssetButton";
import CopyJsonText from "./CopyJsonText";

export default function StructuredAssetIcons() {
  return (
    <div className="flex align-i-center show-on-hover">
      <CopyJsonText />
      <DeleteCurrentAssetButton />
    </div>
  );
}
