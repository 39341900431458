import React from "react";
import { Link } from "react-router-dom";
import { HOME_NESTED_TOOLS_ROUTE_PATH } from "../../navigation/helpers/routePaths";
import { Button } from "@mui/material";

const NavigateToHomePageTools = () => {
  return (
    <Link to={HOME_NESTED_TOOLS_ROUTE_PATH}>
      <Button size="small" variant="contained" className="m-t-8px">
        Show all
      </Button>
    </Link>
  );
};

export default NavigateToHomePageTools;
