import React from "react";
import UpdateTextFieldBase from "../../../../../utilities/components/textFields/UpdateTextFieldBase";
import type { IUpdateTextFieldContainerProps } from "@toolflow/shared";
import useGetSelectedStep from "./hooks/useGetSelectedStep";
import useGetWorkflowStepDescriptionFieldById from "./hooks/useGetWorkflowStepDescriptionFieldById";
import { MAX_ROWS_LARGE_TEXTFIELD } from "../../../../../globalTheme/muiUtils/appTheme";

const UpdateSelectedStepDescriptionTextField = ({
  margin = "none",
  size = "small",
  label,
  placeholder,
  showErrors
}: IUpdateTextFieldContainerProps) => {
  const step = useGetSelectedStep();
  const fieldLabel = useGetWorkflowStepDescriptionFieldById(step?.id || "");
  return (
    <UpdateTextFieldBase
      size={size}
      label={label}
      margin={margin}
      placeholder={placeholder}
      fieldLabel={fieldLabel}
      showErrors={showErrors}
      multiline
      maxRows={MAX_ROWS_LARGE_TEXTFIELD}
    />
  );
};

export default UpdateSelectedStepDescriptionTextField;
