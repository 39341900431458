import React, { useMemo } from "react";

import EditorToolCardContainerInnerWorkspace from "../EditorToolCardContainerInnerWorkspace";
import { v4 as uuidv4 } from "uuid";
import useHandleCloseForWorkflow from "../hooks/useHandleCloseForWorkflow";
import HandlePreviousButton from "./HandlePreviousButton";
import useGetUserInputForWorkflow from "../../../../hooks/useGetUserInputForWorkflow";
import type { CustomToolOutputField, RunToolStep } from "@toolflow/shared";
import useSetWorkflowProgress from "../hooks/useSetWorkflowProgress";
import useGetWorkflowProgress from "../hooks/useGetWorkflowProgress";
import HandleNextButton from "./HandleNextButton";
import { useWorkflowStepContext } from "../../../../../../workflow/contexts/WorkflowStepContext";

function EditorToolCardContainerOuterWorkflow() {
  const { step } = useWorkflowStepContext<RunToolStep>();
  const { userInput, setUserInput, fieldsToHide } =
    useGetUserInputForWorkflow(step);
  const handleClose = useHandleCloseForWorkflow();
  const setWorkflowProgress = useSetWorkflowProgress(step.id);
  const workflowProgress = useGetWorkflowProgress();

  const componentId = useMemo(() => uuidv4(), []);

  const addInputsCallback = (
    id: string,
    outputField: CustomToolOutputField
  ) => {
    setWorkflowProgress({
      ...workflowProgress[step.id],
      outputs: { ...workflowProgress[step.id].outputs, [outputField.id]: id }
    });
  };

  return (
    <EditorToolCardContainerInnerWorkspace
      componentId={componentId}
      userInput={userInput}
      handleClose={handleClose}
      setUserInput={setUserInput}
      hideEntityHeader
      additionalActions={
        <div className="flex align-i-center">
          <HandlePreviousButton />
          <HandleNextButton />
        </div>
      }
      fieldsToHide={fieldsToHide}
      addInputsCallback={addInputsCallback}
    />
  );
}

export default EditorToolCardContainerOuterWorkflow;
