import {
  InputType,
  type ScraperBlockSettingsV2,
  type ScraperBlockSettingsV1,
  DynamicFieldType
} from "@toolflow/shared";

export const scraperSettingsInitialStateV1: {
  settings: ScraperBlockSettingsV1;
} = {
  settings: {
    inputType: InputType.FixedInput,
    urlFieldInputKey: "",
    preferredScraperResultType: "markdown"
  }
};

export const scraperSettingsInitialStateV2: {
  settings: ScraperBlockSettingsV2;
} = {
  settings: {
    url: "",
    preferredScraperResultType: {
      type: DynamicFieldType.Preset,
      value: "markdown"
    }
  }
};
