import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import { WORKSPACE_VISIBLE_SUB_ASSET_ID } from "../helpers/workspaceConstants";

const useGetSubAssetId = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_VISIBLE_SUB_ASSET_ID) ?? "";
};

export default useGetSubAssetId;
