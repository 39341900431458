// useHandleCopy.ts
import { useLocation } from "react-router-dom";
import {
  getAgentLink,
  getTVRLink,
  getToolLink,
  getWorkflowLink,
  getWorkspaceLink
} from "../../../formatters/strings/links";
import { getLocationTrackProperties } from "../../../functions/getLocationTrackProperties";
import {
  AGENT,
  EntityType,
  getHtmlFromText,
  type CopyTypes
} from "@toolflow/shared";
import { useDispatch } from "react-redux";
import { setSuccessMessage } from "../../../../features/layout/alertMessageSnackbar/alertMessageSlice";
import { useTrackEventMutation } from "../../../../ToolflowAPI/rtkRoutes/trackEventApi";

type UseHandleCopyOptions = string;

export const useHandleCopy = (id: UseHandleCopyOptions, type: CopyTypes) => {
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();
  const reduxDispatch = useDispatch();

  const handleCopy = () => {
    if (!id) return;
    let linkText = "";

    if (type === "toolVersionResponse") {
      linkText = getTVRLink(id);
      trackEvent({
        eventString: "Used output",
        toolVersionResponseId: id,
        properties: {
          ...getLocationTrackProperties(location.pathname),
          action_outcome_type: "copy_tool_version_response_link"
        }
      });
    } else if (type === EntityType.TOOL) {
      linkText = getToolLink(id);
      trackEvent({
        eventString: "Copied link",
        properties: {
          link_type: EntityType.TOOL,
          page_id: id,
          link_url: linkText,
          ...getLocationTrackProperties(location.pathname)
        }
      });
    } else if (type === "workspace") {
      linkText = getWorkspaceLink(id);
      trackEvent({
        eventString: "Copied link",
        properties: {
          link_type: "workspace",
          page_id: id,
          link_url: linkText,
          ...getLocationTrackProperties(location.pathname)
        }
      });
    } else if (type === EntityType.WORKFLOW) {
      linkText = getWorkflowLink(id);
      trackEvent({
        eventString: "Copied link",
        properties: {
          link_type: EntityType.WORKFLOW,
          page_id: id,
          link_url: linkText,
          ...getLocationTrackProperties(location.pathname)
        }
      });
    } else if (type === AGENT) {
      linkText = getAgentLink(id);
      trackEvent({
        eventString: "Copied link",
        properties: {
          link_type: AGENT,
          page_id: id,
          link_url: linkText,
          ...getLocationTrackProperties(location.pathname)
        }
      });
    }
    navigator.clipboard.writeText(linkText);
    reduxDispatch(setSuccessMessage("Link Copied"));
  };

  return handleCopy;
};

export const useHandleCopyText = () => {
  const reduxDispatch = useDispatch();
  const [trackEvent] = useTrackEventMutation();
  const location = useLocation();

  const handleCopyText = (value: string, tvrId?: string) => {
    const html = getHtmlFromText(value);
    navigator.clipboard.write([
      new ClipboardItem({
        "text/plain": new Blob([value], { type: "text/plain" }),
        "text/html": new Blob([html], { type: "text/html" })
      })
    ]);
    reduxDispatch(setSuccessMessage("Copied"));
    trackEvent({
      eventString: "Used output",
      properties: {
        ...getLocationTrackProperties(location.pathname),
        action_outcome_type: "copy_tool_output",
        output_type: "text"
      },
      toolVersionResponseId: tvrId || ""
    });
  };

  return handleCopyText;
};
