import { InputType, type CustomToolInputField } from "@toolflow/shared";
import { useState } from "react";
import useToolInputFieldsAndAvailableFields from "../../../../useToolInputFieldsAndAvailableFields";
import { useFieldsByTypeContext } from "../../FieldsByTypeContext";

export default function useSelectWithInput({
  id,
  inputKey,
  userFieldKey,
  hasTextInput,
  isArrayInput
}: {
  id: string;
  inputKey: string;
  userFieldKey?: string;
  hasTextInput: boolean;
  isArrayInput: boolean;
}) {
  const { updateField } = useFieldsByTypeContext();
  const inputTextFields = useToolInputFieldsAndAvailableFields(id);

  const [openInputModal, setOpenInputModal] = useState(false);

  const selectOptions = [
    ...(hasTextInput
      ? [
          {
            label: "+ Insert fixed source text",
            value: InputType.FixedInput,
            type: InputType.FixedInput
          }
        ]
      : []),
    ...inputTextFields
      .map((inputTextField) => ({
        label: inputTextField.name,
        value: inputTextField.name,
        type: inputTextField.type
      }))
      .filter(
        (itf) =>
          itf.type === "textField" ||
          itf.type === "tagsInput" ||
          itf.type === "blockOutput"
      )
  ];

  function $updateInputField(value: string) {
    const arrayValue = isArrayInput ? value.split(",") : value;
    updateField(arrayValue, `settings.${inputKey}`);
  }

  function $updateInputType(value: InputType) {
    updateField(value, "settings.inputType");
  }

  function $updateFieldKey(value: string) {
    if (userFieldKey) updateField(value, `settings.${userFieldKey}`);
  }

  function updateFromAdd(field: CustomToolInputField) {
    $updateFieldKey(field.name);
    $updateInputField("");
  }

  function updateInputType(value: InputType) {
    if (value === InputType.UserInput) {
      $updateInputType(value);
    } else if (value === InputType.FixedInput) {
      $updateInputType(value);
      $updateInputField("");
    } else {
      const field = inputTextFields.find((itf) => itf.name === value);
      if (field?.type === "blockOutput") {
        $updateInputType(InputType.BlockInput);
        $updateInputField(field.name);
        $updateFieldKey("");
      } else {
        $updateFieldKey(value);
        $updateInputType(InputType.UserInput);
        updateFromAdd(field as CustomToolInputField);
      }
    }
  }

  return {
    selectOptions,
    openInputModal,
    setOpenInputModal,
    updateInputType,
    updateFromAdd
  };
}
