import {
  type FieldConfig,
  FieldType,
  type TAgentBuilderForm,
  type UpdateSType
} from "@toolflow/shared";
import React from "react";
import { getOpenAIModelOptions } from "../toolBuilder/helpers/priceDict";
import FieldsByType from "../toolBuilder/common/fieldsByType/FieldsByType";
import { Container, Paper } from "@mui/material";
import { useFormContext } from "react-hook-form";
import {
  AGENT_INSTRUCTIONS_FIELD_LABEL,
  AGENT_MODEL_FIELD_LABEL,
  AGENT_TEMPERATURE_FIELD_LABEL,
  AGENT_TOOLS_FIELD_LABEL
} from "./constants/fields";
import { useSendJSONContent } from "./hooks/useSendJSONContent";

const useGetAgent = () => {
  const { watch } = useFormContext<TAgentBuilderForm>();
  return watch();
};

const AgentBuilder = () => {
  const agent = useGetAgent();
  const { setValue } = useFormContext<TAgentBuilderForm>();

  const promptInstructions = `config.${AGENT_INSTRUCTIONS_FIELD_LABEL}`;
  const sendJSONContent = useSendJSONContent(promptInstructions);

  const updateField = (updateValue: UpdateSType, dataProperty: string) => {
    if (updateValue && sendJSONContent(updateValue, dataProperty)) {
      setValue(
        dataProperty as keyof TAgentBuilderForm,
        updateValue as $TSAllowedAny,
        {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true
        }
      );
    }
  };

  const data = {
    config: {
      [AGENT_INSTRUCTIONS_FIELD_LABEL]:
        agent.config[AGENT_INSTRUCTIONS_FIELD_LABEL],
      [AGENT_MODEL_FIELD_LABEL]: agent.config[AGENT_MODEL_FIELD_LABEL],
      [AGENT_TEMPERATURE_FIELD_LABEL]:
        agent.config[AGENT_TEMPERATURE_FIELD_LABEL],
      [AGENT_TOOLS_FIELD_LABEL]: agent.config[AGENT_TOOLS_FIELD_LABEL]
    }
  };

  const configs: FieldConfig = {
    id: "agentBuilder",
    data,
    updateField,
    fields: [
      {
        type: FieldType.NewSelectInput,
        label: "Model",
        subtitle: "Select text generation technology",
        config: {
          fieldKey: `config.${AGENT_MODEL_FIELD_LABEL}`,
          options: getOpenAIModelOptions()
        }
      },
      {
        type: FieldType.PromptInputV2,
        label: "System Instructions",
        subtitle: "Set and preview AI instructions for response generation.",
        config: {
          fieldKey: promptInstructions,
          placeholder: `Type "/" or click "+" for dynamic inputs`,
          textFieldClassName: "min-h-246px",
          className: "m-t-8px",
          autofocus: true
        }
      },
      {
        type: FieldType.Slider,
        label: "Temperature",
        subtitle:
          "Adjust temperature of response. Higher is more random, and lower is more deterministic.",
        config: {
          fieldKey: `config.${AGENT_TEMPERATURE_FIELD_LABEL}`,
          className: "m-v-16px"
        }
      },
      {
        type: FieldType.SelectTools,
        label: "Tools",
        subtitle: "Select tools to use in your agent",
        config: {
          fieldKey: `config.${AGENT_TOOLS_FIELD_LABEL}`
        }
      }
    ]
  };

  return (
    <Container maxWidth="md">
      <Paper variant="outlined" className="border-radius-8px m-16px">
        <FieldsByType configs={configs} />
      </Paper>
    </Container>
  );
};

export default AgentBuilder;
