import React from "react";
import LoadingBase from "./LoadingBase";
import { TMaterialCell } from "../sheetTypes";

const CellWrapper = ({
  cell,
  children
}: {
  cell: TMaterialCell;
  children: React.ReactNode;
}) => {
  const row = cell.row;
  const loading = row.original.rowLoading;
  return <LoadingBase loading={loading}>{children}</LoadingBase>;
};

export default CellWrapper;
