import { Typography, Link } from "@mui/material";

const CompressFileCTA = () => {
  return (
    <Typography
      variant="caption"
      className="m-t-8px"
      sx={{ color: (theme) => theme.palette.text.secondary }}
    >
      File too big? Use a tool like{" "}
      <Link href="https://www.freeconvert.com/" target="_blank">
        FreeConvert
      </Link>
      . Toolflow does not control this tool, so please do your research first.
    </Typography>
  );
};
export default CompressFileCTA;
