import { NodeViewWrapper, type NodeViewProps } from "@tiptap/react";
import React from "react";
import SearchedToolContainer from "./SearchedToolContainer";
import { NodeViewContextProvider } from "../../context/NodeViewContext";
import VisitToolsPageButton from "../../../../../../features/pages/toolsPage/VisitToolsPageButton";

const SearchToolResponseComponent = (props: NodeViewProps) => {
  return (
    <NodeViewWrapper className="tip-tap-tool-component flex negative-horizontal-margin-8">
      <NodeViewContextProvider nodeViewProps={props}>
        <div className="w-100-percent">
          <div className="w-100-percent">
            {(props.node.attrs.toolIds || []).map(
              (toolId: string, idx: number) => (
                <SearchedToolContainer key={toolId} index={idx} />
              )
            )}
          </div>
          <VisitToolsPageButton fullWidth />
        </div>
      </NodeViewContextProvider>
    </NodeViewWrapper>
  );
};

export default SearchToolResponseComponent;
