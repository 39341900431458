import { useBlocker } from "react-router-dom";
import type { TAgentBuilderForm } from "@toolflow/shared";
import useFormIsDirty from "../../../../../utilities/hooks/useIsDirty";

const useAgentBlocker = () => {
  const { isDirtyFunc } = useFormIsDirty<TAgentBuilderForm>();

  const blocker = useBlocker(isDirtyFunc);

  return blocker;
};

export default useAgentBlocker;
