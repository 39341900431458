import { WORKFLOW_STEPS_FIELD_LABEL } from "../../WorkflowBuilderConstants";
import useGetSelectedStepIndex from "./useGetSelectedStepId";
import useGetWorkflowStepFieldByIndex from "./useGetWorkflowStepFieldByIndex";

const useGetSelectedWorkflowStepField =
  (): `${typeof WORKFLOW_STEPS_FIELD_LABEL}.${number}` => {
    const selectedStepIndex = useGetSelectedStepIndex();
    return useGetWorkflowStepFieldByIndex(selectedStepIndex);
  };

export default useGetSelectedWorkflowStepField;
// export const a = "a";
