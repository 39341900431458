import React from "react";

const handleEnterBlur = (event: React.KeyboardEvent<HTMLInputElement>) => {
  if (event.key === "Enter" && !event.shiftKey) {
    event.preventDefault();
    const inputElement = event.target as HTMLInputElement;
    const focusableElements = Array.from(
      document.querySelectorAll<HTMLElement>(
        "input, button, select, textarea, a[href]"
      )
    ).filter(
      (element) => !element.hasAttribute("disabled") && element.tabIndex >= 0
    );
    const currentIndex = focusableElements.indexOf(inputElement);
    const nextElement = focusableElements[currentIndex + 1];

    if (nextElement) {
      nextElement.focus();
    }
  }
};

export default handleEnterBlur;
