import { CardContent, CardHeader, Paper } from "@mui/material";
import React, { FC } from "react";

interface ISubSettingsLayoutProps {
  children: React.ReactNode;
  title?: string;
  subheader?: string;
}

const SubSettingsLayout: FC<ISubSettingsLayoutProps> = ({
  children,
  title = "",
  subheader = ""
}) => {
  return (
    <Paper
      variant="outlined"
      className="w-100-percent m-b-16px border-radius-8px"
    >
      {title && (
        <CardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={{ variant: "body2" }}
          subheaderTypographyProps={{ variant: "caption" }}
        />
      )}
      <CardContent>{children}</CardContent>
    </Paper>
  );
};

export default SubSettingsLayout;
