import React from "react";

const OutputLoadingWrapper = ({
  showLoading,
  children
}: {
  showLoading?: boolean;
  children?: React.ReactNode;
}) => {
  return <>{!showLoading && <>{children}</>}</>;
};

export default OutputLoadingWrapper;
