import { Edge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/dist/types/types";
import {
  BaseEventPayload,
  ElementDragType
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";

export type TOnDrop = (e: BaseEventPayload<ElementDragType>) => void;

export const DRAGGING = "dragging";
export const PREVIEW = "preview";
export const IDLE = "idle";

export type DraggableState =
  | { type: typeof IDLE }
  | { type: typeof PREVIEW; container: HTMLElement }
  | { type: typeof DRAGGING };

export const VERTICAL = "vertical";
export const HORIZONTAL = "horizontal";

export const IS_OVER = "is-over";
export type TState = typeof IDLE | typeof IS_OVER;
export type TClosestEdge = Edge | null | typeof MIDDLE;

export const MIDDLE = "middle";
export const TOP = "top";
export const BOTTOM = "bottom";
export const LEFT = "left";
export const RIGHT = "right";

export const closestEdgeSymbol = Symbol("closestEdge");
