import useHandleRunAgent from "../../hooks/useHandleRunAgent";
import useThreadRef from "../../hooks/useThreadRef";
import type { JSONContent } from "@tiptap/core";
import useSendChatDisabled from "./useSendChatDisabled";
import useAgentRef from "../../hooks/useAgentRef";

const useSendChatMessageButton = (
  setUserTextMessage: React.Dispatch<React.SetStateAction<JSONContent>>
) => {
  const handleRunAgent = useHandleRunAgent(setUserTextMessage);
  const disabled = useSendChatDisabled();

  const currentThreadIdRef = useThreadRef();
  const currentAgentIdRef = useAgentRef();

  return {
    disabled,
    currentThreadIdRef,
    currentAgentIdRef,
    handleRunAgent
  };
};

export default useSendChatMessageButton;
