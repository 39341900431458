import React from "react";
import { useGetFrequentlyUsedEntities } from "../../../../entities/hooks/useGetFrequentlyUsedEntities";
import ToolsBlockTable from "./ToolsBlockTable";

const FrequentlyUsedEntitiesContainer = () => {
  const { frequentlyUsedEntities } = useGetFrequentlyUsedEntities(6);
  return <ToolsBlockTable toolsBlockEntities={frequentlyUsedEntities} />;
};

export default FrequentlyUsedEntitiesContainer;
