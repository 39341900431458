import { Paper, Tooltip, Typography } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";

export const UtiltityTooltip = ({
  children,
  title,
  placement = "top",
  className = ""
}: {
  children: ReactElement;
  title: ReactNode;
  placement?: "top" | "bottom" | "left" | "right";
  className?: string;
}) => {
  if (!title) return <>{children}</>;
  return (
    <Tooltip
      arrow
      placement={placement}
      classes={{
        popper: `p-0px ${className}`,
        tooltip: `p-0px ${className}`
      }}
      title={
        <Paper className={`p-16px ${className}`}>
          {typeof title === "string" ? <Typography>{title}</Typography> : title}
        </Paper>
      }
    >
      {children}
    </Tooltip>
  );
};
