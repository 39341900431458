import { useToolbuilderContext } from "../context/ToolBuilderContext";
import { useDuplicateObject } from "../../../../../utilities/hooks/useDuplicateObject";
import { EntityType } from "@toolflow/shared";

// this is hack for now -> we should save and duplicate

const useDuplicateObjectToolbuilderToolbuilder = (id: string) => {
  const { loading, duplicateObject } = useDuplicateObject();
  const { dispatch: tDispatch } = useToolbuilderContext();
  const duplicate = () => {
    tDispatch({ type: "RESET_TOOL" });
    duplicateObject(id, EntityType.TOOL);
  };
  return { duplicate, loading };
};

export default useDuplicateObjectToolbuilderToolbuilder;
