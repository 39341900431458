import React from "react";
import type { LogicBlockData } from "@toolflow/shared";
import { Typography, Button } from "@mui/material";
import useUpdateField from "../../useUpdateField";
import { LogicRow } from "./LogicRow";
import { generateInitialLogicFromToolInput } from "../../helpers/logicBlockHelpers";
import useToolInputFieldsAndAvailableFields from "../../useToolInputFieldsAndAvailableFields";

function LogicInner({ id, data }: { id: string; data: LogicBlockData }) {
  const updateField = useUpdateField(id);
  const toolInputFields = useToolInputFieldsAndAvailableFields(id);

  const addRow = () => {
    // Update the 'logicArray' field with a new logic item
    const newLogicItem = generateInitialLogicFromToolInput(toolInputFields[0]);
    if (newLogicItem) {
      updateField([...data.logicArray, newLogicItem], "logicArray");
    }
  };

  const deleteRow = (idx: number) => {
    // Remove the logic item at index 'idx'
    const updatedLogicArray = data.logicArray.filter(
      (_, index) => index !== idx
    );
    updateField(updatedLogicArray, "logicArray");
  };

  return (
    <div className="flex flex-column m-16px">
      <Typography variant="subtitle1" className="m-t-8px m-b-8px">
        Connected outputs will run only if
      </Typography>
      {data.logicArray.map((logicItem, idx) => (
        <LogicRow
          key={idx}
          item={logicItem}
          id={id}
          idx={idx}
          deleteRow={deleteRow}
          last={idx + 1 === data.logicArray.length}
        />
      ))}
      {data.logicArray.length === 0 && (
        <Typography>
          Add a filter to determine which blocks should run
        </Typography>
      )}
      <Button onClick={addRow} className="m-t-16px m-l-auto">
        Add
      </Button>
    </div>
  );
}
export default LogicInner;
