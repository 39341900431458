import { Autocomplete, TextField } from "@mui/material";
import type { TMultiSelectInput } from "@toolflow/shared";
import React from "react";

export const InputMultiSelect = ({
  toolInputField,
  disabled,
  autofocus
}: {
  toolInputField: TMultiSelectInput;
  disabled?: boolean;
  autofocus?: boolean;
}) => {
  return (
    <Autocomplete
      multiple
      size="small"
      id="autocomplete-outlined"
      options={toolInputField.options || []}
      filterSelectedOptions
      autoFocus={autofocus}
      disabled={disabled}
      renderInput={(params) => (
        <TextField {...params} label={toolInputField.name} />
      )}
    />
  );
};
