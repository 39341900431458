import React from "react";
import useGetCurrentUser from "../user/hooks/useGetCurrentUser";

const ToolflowHireSplitter = ({
  component
}: {
  component: React.ReactNode;
}) => {
  const user = useGetCurrentUser();

  if (
    user?.toolflowEmployeeType === "contractor" ||
    user?.toolflowEmployeeType === "employee"
  ) {
    return <>{component}</>;
  }
  return null;
};
export default ToolflowHireSplitter;
