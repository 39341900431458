import { useSelector } from "react-redux";
import { useGetWorkspaceQuery } from "../../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { RootState } from "../../../../stores/store";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";

const useGetDefaultWorkspaceFields = () => {
  const { isLoading } = useAuthStore();
  const currentWorkspaceId = useSelector(
    (state: RootState) => state.workspace.workspaceId
  );
  const {
    data,
    isFetching,
    isLoading: workspaceLoading
  } = useGetWorkspaceQuery(currentWorkspaceId, {
    skip: !currentWorkspaceId || isLoading,
    refetchOnMountOrArgChange: true
  });
  return {
    data: currentWorkspaceId ? data : null,
    isFetching,
    currentWorkspaceId,
    isLoading: workspaceLoading
  };
};

export default useGetDefaultWorkspaceFields;
