import React from "react";
import Workspace from "../../../../../../../utilities/components/textFields/workspace/Workspace";
import useAssetTextField from "./hooks/useAssetTextField";

function AssetTextFieldContainer() {
  const { initialContent, onUpdate, disableEditing, id, chipsToUse } =
    useAssetTextField();

  return (
    <>
      <Workspace
        initialContent={initialContent}
        chipsToUse={chipsToUse}
        assetNodeType="text"
        disableAutofocus
        extensionsConfig={{
          enableAutolinkExtension: true
        }}
        variant="standard"
        onUpdate={onUpdate}
        disableEditing={disableEditing}
        id={id}
        BoxProps={{
          className: "w-100-percent oflow-x-hidden asset-max-width m-h-auto"
        }}
      />
    </>
  );
}

export default AssetTextFieldContainer;
