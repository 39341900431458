import {
  Block,
  type DecimalString,
  sortSemanticVersions,
  type DraggableItem,
  type BlockDataTypesWithDefault
} from "@toolflow/shared";
import { blockConfig } from "../blockConfig";
import createBlockDict from "./createBlockDict";

export const getLatestBlockVersion = (block: Block): DecimalString => {
  const blockVersions = blockConfig[block];
  const versions = Object.keys(blockVersions);
  const sortedVersions = sortSemanticVersions(versions);
  return sortedVersions[0];
};

export const getLatestBlockVersionByType = (
  type: BlockDataTypesWithDefault["type"]
) => {
  const blockDict = createBlockDict<DraggableItem>(
    "draggableItem",
    undefined,
    true
  );
  return blockDict[type];
};
