import { Container } from "@mui/material";
import React from "react";
import StyledMainStepsWrapper from "./StyledMainStepsWrapper";

const StepsWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <StyledMainStepsWrapper>
      <Container className="p-v-16px oflow-auto m-0px flex-grow-1">
        {" "}
        {children}
      </Container>
    </StyledMainStepsWrapper>
  );
};

export default StepsWrapper;
