import { FormControl, Stack, Typography } from "@mui/material";
import {
  type WordReplaceDict,
  type WordReplaceFieldProps
} from "@toolflow/shared";
import { parsedClassNames } from "../../../../../../../../utilities/functions/parsedClassNames";
import ReplaceWordsForm from "../../../../settings/deepgram/ReplaceWordsForm";
import useUpdateField from "../../../../useUpdateField";
import useFieldsByTypeHelper from "../../useFieldsByTypeHelper";
import styles from "../dynamicFieldWrapper/dynamicFieldWrapper.module.css";

const cx = parsedClassNames.bind(styles);

export function WordReplaceField({
  field,
  settings,
  id
}: WordReplaceFieldProps) {
  const { label, subtitle } = field;
  const { fieldKey, defaultValue = {} } = field.config;
  const { getFieldValue } = useFieldsByTypeHelper({ settings });
  const updateField = useUpdateField(id);

  const fieldValue = getFieldValue(fieldKey, defaultValue) as WordReplaceDict;

  return (
    <FormControl margin="normal" fullWidth>
      <div className={cx("p-16px border-radius-8px", "container")}>
        <Stack spacing={1}>
          <div>
            <Typography variant="subtitle1" fontWeight="500">
              {label}
            </Typography>
            {subtitle && (
              <Typography variant="caption" color="text.secondary">
                {subtitle}
              </Typography>
            )}
          </div>
          <ReplaceWordsForm
            handleChange={(value) => {
              updateField(value, fieldKey);
            }}
            wordsToReplace={fieldValue}
          />
        </Stack>
      </div>
    </FormControl>
  );
}
