import React from "react";
import { useSheetContext } from "../SheetContext";
import { MarketplaceGroup, type TSelectAutocomplete } from "@toolflow/shared";
import ToolSelectedText from "./ToolSelectedText";
import UniversalAutocomplete from "../../../../../../../../../utilities/components/autocomplete/universalAutocomplete/UniversalAutocomplete";
const useSheetHeader = () => {
  const { selectedTool, tableInstanceRef, setSelectedTool } = useSheetContext();

  const handleSelectEntity: TSelectAutocomplete = (id, type) => {
    if (type === MarketplaceGroup.TOOL) {
      tableInstanceRef.current?.resetRowPinning();
      setSelectedTool(id);
    }
  };

  return {
    selectedTool,
    handleSelectEntity,
    setSelectedTool
  };
};

// there is an error that if something is pinned and you deselect the cell, it will crash
// so reset the pinning when the tool changes
const SheetHeader = () => {
  const { selectedTool, handleSelectEntity, setSelectedTool } =
    useSheetHeader();

  if (selectedTool) {
    return (
      <ToolSelectedText
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
      />
    );
  }
  return (
    <UniversalAutocomplete
      handleSelectEntity={handleSelectEntity}
      className={"w-300px m-t-8px max-w-600px"}
      placeholder="Search for a tool to run in bulk"
      disabledFields={{
        useCases: true,
        workflows: true
      }}
    />
  );
};

export default SheetHeader;
