import useHandleCloseEditorToolCardWorkspace from "../../../../../../utilities/components/textFields/workspace/hooks/useHandleCloseEditorToolCardWorkspace";
import useGetSelectedWorkspaceToolComponentId from "../../../hooks/useGetSelectedWorkspaceToolComponentId";
import useGetUserInput from "../../../hooks/useGetUserInput";
import EditorToolCardContainerInnerWorkspace from "./EditorToolCardContainerInnerWorkspace";

function EditorToolCardContainerWorkspace() {
  const { userInput, setUserInput } = useGetUserInput();

  // this will mess up if we call the same tool again
  // right now it overwrites the asset
  const componentId = useGetSelectedWorkspaceToolComponentId();

  const handleClose = useHandleCloseEditorToolCardWorkspace();

  return (
    <EditorToolCardContainerInnerWorkspace
      componentId={componentId}
      userInput={userInput}
      handleClose={handleClose}
      setUserInput={setUserInput}
    />
  );
}

export default EditorToolCardContainerWorkspace;
