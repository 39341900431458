import CircularLoading from "../../../../../../../../../utilities/components/loading/CircularLoading";
import React from "react";

const LoadingSheet = () => {
  return (
    <div
      style={{ height: `calc(100vh - 52px)` }}
      className="flex flex-column justify-center align-i-center"
    >
      <CircularLoading />
    </div>
  );
};

export default LoadingSheet;
