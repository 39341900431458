import React from "react";
import { FormHelperText } from "@mui/material";
import {
  WORKSPACE_NAME_FIELD_LABEL,
  WORKSPACE_NAME_PLACEHOLDER
} from "../helpers/workspaceConstants";
import ErrorContainer from "../../../../utilities/components/errors/ErrorContainer";
import TogglePrimaryHeaderTextFieldBase from "../../../../utilities/components/textFields/TogglePrimaryHeaderTextFieldBase";
import UpdateWorkspaceNameTextField from "./UpdateWorkspaceNameTextField";
import useWorkspaceName from "../hooks/useWorkspaceName";

const ToggleWorkspaceName = () => {
  const workspaceName = useWorkspaceName();

  return (
    <TogglePrimaryHeaderTextFieldBase
      value={workspaceName || WORKSPACE_NAME_PLACEHOLDER}
      errorMessage={
        <FormHelperText error className="pos-absolute">
          <ErrorContainer
            fieldName={WORKSPACE_NAME_FIELD_LABEL}
            className="pos-absolute"
          />
        </FormHelperText>
      }
    >
      <UpdateWorkspaceNameTextField />
    </TogglePrimaryHeaderTextFieldBase>
  );
};

export default ToggleWorkspaceName;
