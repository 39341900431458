import React from "react";

import EditorToolCardContent from "./EditorToolCardContent";
import EditorToolCardActions from "./EditorToolCardActions";
import EditorToolCardWrapper from "./EditorToolCardWrapper";
import EntityHeader from "../../../pages/workstation/components/entities/toolPaper/EntityHeader";
import useGetElementHeight from "../../../pages/workstation/components/assets/asset/textAsset/hooks/useGetElementHeight";
import { Divider } from "@mui/material";
import { useToolPadding } from "../../../entities/components/entityInfo/useToolPadding";
import { classNames } from "../../../../utilities/functions/parsedClassNames";
import { useEditorToolCardContext } from "./EditorToolCardContext";

function EditorToolCard() {
  const { ref, height } = useGetElementHeight();
  const padding = useToolPadding();
  const { hideEntityHeader } = useEditorToolCardContext();
  return (
    <EditorToolCardWrapper>
      <div ref={ref}>
        {!hideEntityHeader && (
          <>
            <EntityHeader />
            <Divider />
          </>
        )}
      </div>
      <div
        className={classNames("h-100-percent scrollable-content", padding)}
        style={{ maxHeight: `calc(100% - ${height}px - 92px)` }}
      >
        <EditorToolCardContent />
      </div>
      <Divider />
      <EditorToolCardActions />
    </EditorToolCardWrapper>
  );
}

export default EditorToolCard;
