import type { FileReference, UploadFileResponseFE } from "@toolflow/shared";
import type { AxiosProgressEvent } from "axios";
import { useCallback, useState } from "react";
import { type Accept } from "react-dropzone";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "../../../../stores/actions";
import { authenticatedApi } from "../../../../ToolflowAPI/authenticatedAPI";
import useToolflowAPI from "../../../../ToolflowAPI/useToolflowAPI";
import { useAuthStore } from "../../../auth/hooks/useAuthStore";
import { FileUploadError, useGetFileErrorMessage } from "../errors/fileErrors";
import handleAudioDuration from "../functions/handleAudioDuration";
import useValidateFile from "./useValidateFile";
import { FileType, getFileType } from "../helpers/fileHelpers";

const useSelectFile = ({
  accept,
  setCurrentFile
}: {
  accept?: Accept;
  setCurrentFile: (file: FileReference | null) => void;
}) => {
  const { uploadFile } = useToolflowAPI();

  const [tmpFile, setTmpFile] = useState<{
    name: string;
    size: number;
    type: FileType;
  } | null>(null);
  const [progress, setProgress] = useState(0);
  const [compressFileCTA, setCompressFileCTA] = useState(false);
  const getFileErrorMessage = useGetFileErrorMessage();

  const validateFile = useValidateFile(
    //? Is there a need to validate this?
    // pass all the valid mimeTypes and file extensions for validation.
    Object.entries(accept || {})
      .map(([key, value]) => [key, ...value])
      .flat()
  );

  const reset = () => {
    setTmpFile(null);
    setProgress(0);
  };

  const reduxDispatch = useDispatch();
  const handleError = (error: FileUploadError) => {
    reset();
    reduxDispatch(setErrorMessage(getFileErrorMessage(error)));
    if (error === FileUploadError.FILE_SIZE) {
      setCompressFileCTA(true);
    }
  };

  const initializeFileUpload = (files: File[]) => {
    const file = files[0];
    setCompressFileCTA(false);
    setTmpFile({ name: file.name, size: file.size, type: getFileType(file) });
    setProgress(0);
    reduxDispatch(setErrorMessage(""));
    return file;
  };

  const { cancelUpload } = useToolflowAPI();
  const abortUpload = () => {
    cancelUpload();
    setCurrentFile(null);
    reset();
  };

  const handleFileSelect = useCallback(
    async (files: File[] | null) => {
      if (files && files.length > 0) {
        const file = initializeFileUpload(files);
        const errorMessage = validateFile(file);
        if (errorMessage) {
          handleError(errorMessage);
          return;
        }

        const fileType = getFileType(file);
        let audioDuration: number | undefined;

        if (fileType === FileType.Audio) {
          audioDuration = await handleAudioDuration(file);
        }

        const parseResponse = (resp: UploadFileResponseFE) => {
          reset();
          reduxDispatch(authenticatedApi.util.invalidateTags(["File"]));
          setCurrentFile(resp.savedFile);
        };
        const onUploadProgress = (progressEvent: AxiosProgressEvent) => {
          try {
            if (progressEvent.total) {
              setProgress(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
            }
          } catch (error) {
            console.error("Error in onUploadProgress:", error);
            handleError(FileUploadError.GENERIC);
          }
        };

        await uploadFile(
          { file, duration: audioDuration },
          parseResponse,
          reset,
          onUploadProgress
        );
      }
    },
    [setCurrentFile]
  );
  return {
    tmpFile,
    progress,
    abortUpload,
    handleFileSelect,
    compressFileCTA
  };
};

const useFileUpload = ({
  accept,
  disabled,
  setCurrentFile
}: {
  accept?: Accept;
  disabled?: boolean;
  setCurrentFile: (file: FileReference | null) => void;
}) => {
  const { tmpFile, progress, handleFileSelect, compressFileCTA, abortUpload } =
    useSelectFile({ accept, setCurrentFile });
  const { isAuthenticated } = useAuthStore();

  return {
    tmpFile,
    progress,
    handleFileSelect,
    abortUpload,
    compressFileCTA,
    computedDisabled: !isAuthenticated || disabled
  };
};

export default useFileUpload;
