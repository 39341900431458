import React from "react";
import { SearchIcon } from "../../../../../../../globalTheme/icons/icons";
import useFindToolButton from "./hooks/useFindToolButton";
import TextButtonGray from "./textButtonGray/textButtonGray";
import {
  SearchEntitiesDialog,
  SearchEntitiesDialogType
} from "../../../../../../tools/components/searchEntitiesDialog/SearchEntitiesDialog";

function FindToolButton({ className = "" }: { className?: string }) {
  const { selectTool } = useFindToolButton();
  return (
    <SearchEntitiesDialog
      onClickEntity={selectTool}
      title="Find tool"
      type={SearchEntitiesDialogType.TOOLS}
      disabledEntities={{ agents: true }}
      button={
        <TextButtonGray
          onClick={() => {}}
          className={className}
          text="Find tool"
          Icon={SearchIcon}
        />
      }
    />
  );
}

export default FindToolButton;
