import { Box } from "@mui/material";
import {
  type FieldsByTypeProps,
  type FieldsGroup,
  type FieldTypeConfigs
} from "@toolflow/shared";
import { FieldsByTypeContextComponent } from "../FieldsByTypeContext";
import { fieldTypeComponents } from "../fieldTypeComponents";
import GroupAccordion from "./GroupAccordion";

function GroupedFields({
  field,
  configs
}: FieldsByTypeProps & {
  field: FieldTypeConfigs;
}) {
  const { data, id, updateField } = configs;
  const Component = fieldTypeComponents[field.type];
  if (!Component) return null;
  return (
    <Box sx={{ marginInline: 1 }}>
      <FieldsByTypeContextComponent
        configs={{
          data,
          field,
          id,
          updateField
        }}
      >
        <Component field={field} settings={data} id={id} />
      </FieldsByTypeContextComponent>
    </Box>
  );
}

export default function GroupedFieldWrapper({
  group,
  configs
}: FieldsByTypeProps & {
  group: FieldsGroup;
}) {
  return (
    <GroupAccordion title={group.label} subtitle={group.subtitle}>
      {group.fields.map((groupedField, idx2) => (
        <GroupedFields key={idx2} field={groupedField} configs={configs} />
      ))}
    </GroupAccordion>
  );
}
