import { useEffect, useState } from "react";
import { useInfiniteScrollHeight } from "./useInfiniteScrollHeight";

export function useInfiniteScroll<T>(allEntities: T[], itemsPerPage: number) {
  const [visibleEntities, setVisibleEntities] = useState<T[]>([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    setVisibleEntities(allEntities.slice(0, itemsPerPage));
  }, [allEntities, itemsPerPage]);

  const loadMoreEntities = () => {
    const nextPage = page + 1;
    const newEntities = allEntities.slice(0, (nextPage + 1) * itemsPerPage);
    setVisibleEntities(newEntities);
    setPage(nextPage);
  };

  const { scrollHeight, scrollRef } = useInfiniteScrollHeight();

  return {
    visibleEntities,
    loadMoreEntities,
    hasMore: visibleEntities.length < allEntities.length,
    scrollHeight,
    scrollRef
  };
}
