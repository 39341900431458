import { useFormContext } from "react-hook-form";
import type { TWorkspaceDataToSave } from "@toolflow/shared";
import { WORKSPACE_VISIBILITY_FIELD_LABEL } from "../helpers/workspaceConstants";

const useWorkspaceVisibility = () => {
  const { watch } = useFormContext<TWorkspaceDataToSave>();
  return watch(WORKSPACE_VISIBILITY_FIELD_LABEL) || "";
};

export default useWorkspaceVisibility;
