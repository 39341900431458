import React, { useMemo } from "react";
import { useSettingsDropdownContext } from "../../../../utilities/components/dropdowns/generic/useSettingsDropownContext";
import SettingsMenuItem from "../../../../utilities/components/dropdowns/generic/SettingsMenuItem";
import ToolflowHireSplitter from "../../../auth/ToolflowHireGuard";
import useToolIsFeatured from "../../../tools/hooks/useToolIsFeatured";
import { MarketplaceIcon } from "../../../../globalTheme/icons/icons";
import { type TMarketplaceUniversalEntityType } from "@toolflow/shared";
import { useUpdateMarketplaceFeaturedEntityMutation } from "../../../../ToolflowAPI/rtkRoutes/marketplaceApi";

function ToggleMarketplaceObjectMenuItem({
  objectId,
  type
}: {
  objectId: string;
  type: TMarketplaceUniversalEntityType;
}) {
  const [toggleFeatured] = useUpdateMarketplaceFeaturedEntityMutation();
  const { handleClose } = useSettingsDropdownContext();
  const isFeatured = useToolIsFeatured(objectId);

  const text = useMemo(() => {
    return isFeatured ? "Unfeature in marketplace" : "Feature in marketplace";
  }, [isFeatured]);

  const copy = (e: React.MouseEvent) => {
    const newFeaturedState = !isFeatured;
    toggleFeatured({
      objectId,
      isFeatured: newFeaturedState,
      type,
      successMessage: newFeaturedState
        ? "Featured in marketplace"
        : "Unfeatured in marketplace"
    });
    handleClose(e);
  };

  return (
    <ToolflowHireSplitter
      component={
        <SettingsMenuItem
          action={copy}
          text={text}
          icon={<MarketplaceIcon size={16} />}
        />
      }
    />
  );
}

export default ToggleMarketplaceObjectMenuItem;
