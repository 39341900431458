import type { UserInputDictType } from "@toolflow/shared";
import AccordionWrapper from "../../../../../../../../utilities/components/accordions/AccordionWrapper";
import UrlForm from "../../../../common/UrlForm";
import { TSetTestUserInput } from "../../../hooks/useFields";

const TestScraperFields = ({
  id,
  uI,
  setUI,
  urlFieldInputKey
}: {
  id: string;
  uI: UserInputDictType;
  setUI: TSetTestUserInput;
  urlFieldInputKey: string;
}) => {
  return (
    <AccordionWrapper title="Test" elevation={0} startsExpanded>
      <UrlForm
        id={id}
        userInput={uI}
        setFormState={setUI}
        updateFieldKey={urlFieldInputKey}
        placeholder="Add url"
      />
    </AccordionWrapper>
  );
};

export default TestScraperFields;
