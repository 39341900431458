import { useSelector } from "react-redux";
import { selectAllProfileWorkspaces } from "../../../ToolflowAPI/rtkRoutes/selectors/workspaceSelectors";
import { useGetProfileWorkspacesQuery } from "../../../ToolflowAPI/rtkRoutes/workspaceApi";
import { useGetCurrentUserProfileId } from "../../user/hooks/useGetCurrentUserProfileId";

const useGetAllProfileWorkspaces = () => {
  const profileId = useGetCurrentUserProfileId();
  const { isLoading, isFetching } = useGetProfileWorkspacesQuery(profileId, {
    skip: !profileId
  });
  const workspaces = useSelector(selectAllProfileWorkspaces(profileId));
  return {
    workspaces,
    loading: isLoading || isFetching
  };
};

export default useGetAllProfileWorkspaces;
