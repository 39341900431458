import type { RichTextEditorRef } from "mui-tiptap";
import { useEffect, useRef, useState } from "react";

// we need the state so that the workspace rerenders
// so that content will be set in useValidateInitialContent
const useRteRef = () => {
  const rteRef = useRef<RichTextEditorRef>(null);
  const [editorLoaded, setEditorLoaded] = useState(false);
  useEffect(() => {
    if (rteRef.current && !editorLoaded) {
      setEditorLoaded(true);
    }
  }, [rteRef.current]);
  return rteRef;
};

export default useRteRef;
