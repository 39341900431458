import type { Workspace } from "@toolflow/shared";
import { useEffect } from "react";
import type { UseFormReturn } from "react-hook-form";
import { WORKSPACE_ID_FIELD_LABEL } from "../helpers/workspaceConstants";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";

export const useKeepFormWorkspaceIdInSync = (
  methods: UseFormReturn<Workspace, $TSAllowedAny, undefined>
) => {
  const { workspaceId } = useSelector((state: RootState) => state.workspace);
  useEffect(() => {
    methods.setValue(WORKSPACE_ID_FIELD_LABEL, workspaceId);
  }, [workspaceId]);
};
