import React, { useEffect, useRef, useState } from "react";

import type { IUpdateTextFieldBaseProps } from "@toolflow/shared";
import { useFormContext } from "react-hook-form";
import TextField from "@mui/material/TextField";
import ErrorContainer from "../errors/ErrorContainer";
import handleEnterBlur from "../../functions/handleEnterBlur";

function UpdateTextFieldOnUnmount({
  label,
  fieldLabel,
  variant = "outlined",
  size = "small",
  margin = "normal",
  fullWidth = true,
  placeholder = "",
  registerOptions,
  showErrors,
  className = "",
  multiline,
  maxRows,
  rows
}: Readonly<IUpdateTextFieldBaseProps>) {
  const {
    register,
    formState: { errors },
    getValues,
    setValue
  } = useFormContext();

  const { name, ref } = register(fieldLabel, registerOptions);
  const [text, setText] = useState(getValues(fieldLabel));

  const textRef = useRef(text);

  useEffect(() => {
    textRef.current = text;
  }, [text]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.target.value);
  };

  useEffect(() => {
    return () => {
      setValue(fieldLabel, textRef.current, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true
      });
    };
  }, []);

  return (
    <TextField
      key={fieldLabel}
      multiline={multiline}
      maxRows={maxRows}
      value={text}
      label={label}
      variant={variant}
      placeholder={placeholder}
      onChange={handleChange}
      name={name}
      inputRef={ref}
      onKeyDown={handleEnterBlur}
      autoComplete="off"
      className={className}
      size={size}
      margin={margin}
      rows={rows}
      error={showErrors && !!errors[fieldLabel]}
      helperText={showErrors && <ErrorContainer fieldName={fieldLabel} />}
      fullWidth={fullWidth}
    />
  );
}

export default UpdateTextFieldOnUnmount;
