import type { CustomToolInputField, UserInputDictType } from "@toolflow/shared";
import { useEffect, useState } from "react";
import arrayToObject from "../functions/arrayToObject";
import { v4 as uuidv4 } from "uuid";
import { TEXTFIELD } from "../../../../../tools/components/editorToolCard/inputs/helpers/inputConstants";

export type TSetTestUserInput = (newInput: UserInputDictType) => void;

const useFields = (fields: string[]) => {
  const [customFields, setCustomFields] = useState<CustomToolInputField[]>([]);
  const [userInput, setUserInputState] = useState<UserInputDictType>(() =>
    arrayToObject(fields)
  );

  function addCustomField(name: string) {
    setCustomFields((prev) => {
      // Don't add existing customFields
      if (prev.some((p) => p.name === name)) return prev;

      return [
        ...prev,
        {
          name,
          id: uuidv4(),
          type: TEXTFIELD
        }
      ];
    });
  }

  /**
   * Updated setUserInput function that accepts a single UserInputDictType parameter
   * and updates both flat keys and nested structures.
   */
  const setUserInput: TSetTestUserInput = (newInput) => {
    setUserInputState((prevUserInput) => {
      const updatedUserInput = { ...prevUserInput };

      Object.keys(newInput).forEach((key) => {
        const value = newInput[key];
        const keyParts = key.split(".");
        const topLevelKey = keyParts[0];
        const isNested = keyParts.length > 1;

        if (isNested) {
          // Update the nested structure
          if (
            typeof updatedUserInput[topLevelKey] !== "object" ||
            updatedUserInput[topLevelKey] === null
          ) {
            updatedUserInput[topLevelKey] = {};
          }

          let currentLevel: $TSAllowedAny = updatedUserInput[topLevelKey];

          // Traverse to the correct depth
          for (let i = 1; i < keyParts.length - 1; i++) {
            const part = keyParts[i];
            if (
              typeof currentLevel[part] !== "object" ||
              currentLevel[part] === null
            ) {
              currentLevel[part] = {};
            }
            currentLevel = currentLevel[part];
          }

          // Set the value at the deepest level
          currentLevel[keyParts[keyParts.length - 1]] = value;

          // Also update the flat key
          updatedUserInput[key] = value;
          addCustomField(topLevelKey);
        } else {
          // Handle top-level keys
          updatedUserInput[key] = value;
        }
      });

      return updatedUserInput;
    });
  };

  useEffect(() => {
    setUserInputState((prevUserInput) => {
      const newUserInput = { ...prevUserInput };

      // Remove fields that are no longer present
      Object.keys(newUserInput).forEach((key) => {
        const keyParts = key.split(".");
        const isKeyPresent = fields.some((field) => {
          const fieldParts = field.split(".");
          return keyParts.every((part, index) => fieldParts[index] === part);
        });

        if (!isKeyPresent) {
          delete newUserInput[key];
          if (keyParts.length > 1) {
            delete newUserInput[keyParts[0]];
          }
        }
      });

      // Add new fields
      fields.forEach((field) => {
        if (!(field in newUserInput)) {
          newUserInput[field] = "";
          if (field.includes(".")) {
            const splitted = field.split(".");
            newUserInput[splitted[0]] = { [splitted[1]]: "" };
          }
        }
      });

      return newUserInput;
    });
  }, [JSON.stringify(fields)]);

  return { fields, customFields, userInput, setUserInput };
};

export default useFields;
