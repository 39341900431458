import {
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React, { FC } from "react";
import { BoldTableCell } from "../../../home/layout/TableLayouts";
import AddLLMApiKeyDialogButton from "./AddLLMApiKeyDialog";
import { TLLMModelRow } from "./hooks/useGetLLMTableData";
import { DeleteIcon } from "../../../../../globalTheme/icons/icons";

interface ILLMKeysTableProps {
  rows: TLLMModelRow[];
  handleDeleteSecret: (secretId: string) => void;
}
const LLMKeysTable: FC<ILLMKeysTableProps> = ({ rows, handleDeleteSecret }) => {
  if (rows?.length === 0) {
    return null;
  }
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: (theme) => theme.palette.text.secondary }}>
              Name
            </TableCell>
            <TableCell sx={{ color: (theme) => theme.palette.text.secondary }}>
              API key
            </TableCell>
            <TableCell
              align="right"
              sx={{ color: (theme) => theme.palette.text.secondary }}
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Stack direction="row" spacing={1}>
                  {row.logo}
                  <Typography variant="body2">{row.name}</Typography>
                </Stack>
              </TableCell>
              {
                <BoldTableCell
                  sx={{
                    color: (theme) =>
                      row.hasValue
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary
                  }}
                >
                  {row.apiKey}
                </BoldTableCell>
              }
              <TableCell align="right">
                {row.hasValue ? (
                  <IconButton
                    sx={{ color: (theme) => theme.palette.text.disabled }}
                    onClick={() => handleDeleteSecret(row.id)}
                  >
                    <DeleteIcon size={16} />
                  </IconButton>
                ) : (
                  <AddLLMApiKeyDialogButton
                    secretType={row.type}
                    secretName={row.name}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LLMKeysTable;
