import type {
  BlockConfig,
  DecimalString,
  VersionedBlockConfig
} from "@toolflow/shared";

const getBlockConfigVersion = (
  version: DecimalString,
  config: BlockConfig
): VersionedBlockConfig => {
  return {
    [version]: {
      ...config,
      draggableItem: {
        ...config.draggableItem,
        data: { ...config.draggableItem.data, version }
      }
    }
  };
};

export default getBlockConfigVersion;
