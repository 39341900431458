import React from "react";
import LoadingBase from "./LoadingBase";
import { TMaterialRow } from "../sheetTypes";

const RowWrapper = ({
  row,
  children
}: {
  row: TMaterialRow;
  children: React.ReactNode;
}) => {
  const loading = row.original.rowLoading;
  return <LoadingBase loading={loading}>{children}</LoadingBase>;
};

export default RowWrapper;
