import { useContext } from "react";
import SettingsDropdownContext, {
  ISettingsDropdownContext
} from "./base/SettingsDropdownContext";

export const useSettingsDropdownContext = (): ISettingsDropdownContext => {
  const context = useContext(SettingsDropdownContext);

  if (context === undefined) {
    throw new Error(
      "useSettingsDropdownContext must be used within a SettingsDropdownProvider"
    );
  }

  return context;
};
