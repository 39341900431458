import { useToolbuilderContext } from "../../../../../../context/ToolBuilderContext";

const useChangeInputDescriptionToolbuiler = () => {
  const { dispatch } = useToolbuilderContext();
  const handleToolInputChangeDescription = (value: string, index: number) => {
    dispatch({
      type: "UPDATE_TOOL_INPUT_FIELD_DESCRIPTION",
      index,
      description: value
    });
  };
  return handleToolInputChangeDescription;
};

export default useChangeInputDescriptionToolbuiler;
