import type { TWorkflowBuilderForm } from "@toolflow/shared";
import React, { createContext, useContext } from "react";
import {
  useFormContext,
  type UseFieldArrayReturn,
  useFieldArray
} from "react-hook-form";
import { WORKFLOW_STEPS_FIELD_LABEL } from "../WorkflowBuilderConstants";

// RHF create their own ids for each field in the array,
// this makes using ids from fields not intuitive because
// we have our own ids. so we omit fields to minimze bugs
type TStepsContext = Omit<
  UseFieldArrayReturn<
    TWorkflowBuilderForm,
    typeof WORKFLOW_STEPS_FIELD_LABEL,
    "id"
  >,
  "fields"
>;

const FieldArrayFormContext = createContext<TStepsContext | undefined>(
  undefined
);

export const useStepsFieldArrayContext = () => {
  const context = useContext(FieldArrayFormContext);
  if (context === undefined) {
    throw new Error("useSheetContext must be used within a SheetProvider");
  }
  return context;
};

export const FieldArrayFormProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { control } = useFormContext<TWorkflowBuilderForm>();
  const { fields, ...fieldArrayMethods } = useFieldArray({
    control,
    name: WORKFLOW_STEPS_FIELD_LABEL
  });
  return (
    <FieldArrayFormContext.Provider value={fieldArrayMethods}>
      {children}
    </FieldArrayFormContext.Provider>
  );
};
