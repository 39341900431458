import React from "react";
import { Button } from "@mui/material";
import { useToolInputFieldsContext } from "./contexts/ToolInputFieldsContext";
import useGetCurrentToolInputField from "./hooks/useGetCurrentToolInputField";

function AddInputOptionButton({ disabled }: { disabled?: boolean }) {
  const { addToolInputOption } = useToolInputFieldsContext();
  const input = useGetCurrentToolInputField();

  if (!input) {
    return null;
  }

  return (
    <Button disabled={disabled} onClick={() => addToolInputOption(input)}>
      Add Option
    </Button>
  );
}

export default AddInputOptionButton;
