import React, { useMemo } from "react";
import { toggleSnippetNameSort } from "../slice/knowledgeSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../stores/store";
import { Order } from "../../home/utlils/tableTypes";
import HeaderSortableCell from "./headerSortableCell/HeaderSortableCell";

function NameHeaderCellContainer() {
  const isAToZ = useSelector(
    (state: RootState) => state.knowledge.snippetNameSortAToZ
  );
  const sortDirection: Order = useMemo(() => {
    return isAToZ ? "asc" : "desc";
  }, [isAToZ]);

  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(toggleSnippetNameSort());
  };
  return (
    <HeaderSortableCell
      sortDirection={sortDirection}
      label={"Name"}
      onClick={onClick}
    />
  );
}

export default NameHeaderCellContainer;
