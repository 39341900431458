import { type JSONContent, generateText } from "@tiptap/core";
import useExtension from "./useExtensions/useExtension";

const useGenerateTextFromJSON = () => {
  const extensions = useExtension();
  const getInputHtml = (jsonToConvert: JSONContent) => {
    return generateText(jsonToConvert, extensions);
  };
  return getInputHtml;
};

export default useGenerateTextFromJSON;
