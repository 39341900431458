import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type {
  TEntityFilterByCategoriesFE,
  TEntityFiltersByUseCasesFE,
  TSearchToolsTabType
} from "@toolflow/shared";

interface ISearchToolsInitialStateBase {
  useCases: TEntityFiltersByUseCasesFE;
  category: TEntityFilterByCategoriesFE;
  tab: TSearchToolsTabType;
}

const initialState: ISearchToolsInitialStateBase = {
  useCases: [],
  category: "all",
  tab: "userTools"
};
export type TSearchToolsReducerName = "searchTools";
export const SEARCH_TOOLS_REDUCER_NAME: TSearchToolsReducerName = "searchTools";

const searchToolsSlice = createSlice({
  name: SEARCH_TOOLS_REDUCER_NAME,
  initialState,
  reducers: {
    setSearchToolsTab(state, action: PayloadAction<TSearchToolsTabType>) {
      state.tab = action.payload;
    },
    setUseCases(state, action: PayloadAction<TEntityFiltersByUseCasesFE>) {
      state.useCases = action.payload;
    },
    setCategory(state, action: PayloadAction<TEntityFilterByCategoriesFE>) {
      const selectedCategory = action.payload;
      state.category = selectedCategory;
      state.useCases = [];
    },
    setCategoryWithUseCases(
      state,
      action: PayloadAction<{
        category: TEntityFilterByCategoriesFE;
        useCases: TEntityFiltersByUseCasesFE;
      }>
    ) {
      const selectedCategory = action.payload.category;
      const selectedUseCases = action.payload.useCases;
      state.category = selectedCategory;
      state.useCases = selectedUseCases;
    }
  }
});

export const {
  setCategory,
  setUseCases,
  setCategoryWithUseCases,
  setSearchToolsTab
} = searchToolsSlice.actions;

export default searchToolsSlice.reducer;
