import InOutNodeHandle from "../inOutNodeHandle/InOutNodeHandle";
import React from "react";
import { ArrowDownIcon } from "../../../../../../../globalTheme/icons/icons";
import { useBlockContext } from "../../../BlockContext";

const InHandle = () => {
  const { id } = useBlockContext();
  return (
    <InOutNodeHandle
      icon={<ArrowDownIcon size={16} />}
      handleType="target"
      id={id}
    />
  );
};

export default InHandle;
