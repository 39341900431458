import React, { useState } from "react";

const useTablePagination = () => {
  const rowsPerPageOptions = [5, 10, 25];
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return {
    rowsPerPageOptions,
    rowsPerPage,
    handleChangePage,
    page,
    handleChangeRowsPerPage
  };
};

export default useTablePagination;
