import React from "react";
import { useParams } from "react-router-dom";
import SettingsDropdown from "../../../../utilities/components/dropdowns/generic/SettingsDropdown";
import DeleteMenuItem from "../../../../utilities/components/dropdowns/menuItems/deleteMenuItem/DeleteMenuItem";
import { AGENT, VisibilityTypes } from "@toolflow/shared";
import CopyAgentLinkMenuItem from "../../../agents/dropdowns/CopyAgentLinkMenuItem";
import useGetAgentVisibility from "./state/hooks/useGetAgentVisibility";
import AddAgentCategoriesMenuItem from "../../../agents/dropdowns/AddAgentCategoriesMenuItem";
import UpdateAgentVisibilityMenuItem from "../../../agents/dropdowns/UpdateAgentVisibilityMenuItem";
import useGetAgentName from "./state/hooks/useGetAgentName";
import ResetAgentMenuItem from "./ResetAgentMenuItem";
import useGetAgentEnableForking from "./state/hooks/useGetAgentEnableForking";

function AgentBuilderHeaderSettingsDropdown() {
  const { agentId = "" } = useParams();
  const name = useGetAgentName();
  const visibility = useGetAgentVisibility();
  const enableForking = useGetAgentEnableForking();
  const menuItems = [
    <CopyAgentLinkMenuItem key="copyAgentLink" agentId={agentId} />,
    <UpdateAgentVisibilityMenuItem
      key="updateAgentVisibility"
      id={agentId}
      name={name}
      visibility={visibility || VisibilityTypes.PRIVATE}
      enableForking={enableForking}
    />,
    <ResetAgentMenuItem key="resetAgent" />,
    <AddAgentCategoriesMenuItem key="addAgentCategories" agentId={agentId} />,
    <DeleteMenuItem id={agentId} shouldNavigateHome type={AGENT} key="delete" />
  ];

  return <SettingsDropdown menuItems={menuItems} />;
}

export default AgentBuilderHeaderSettingsDropdown;
