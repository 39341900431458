import { Button } from "@mui/material";
import React, { FC } from "react";

interface IJoinSlackButtonProps {
  className?: string;
}
const JoinSlackButton: FC<IJoinSlackButtonProps> = ({ className = "" }) => {
  const redirectToSlackLink = () => {
    window.open(
      "https://join.slack.com/t/toolflowconnect/shared_invite/zt-28ksi2tvn-BACaJOxZWWr3CISVjbFedg",
      "_blank"
    );
  };
  return (
    <Button
      className={className}
      variant="text"
      size="medium"
      onClick={redirectToSlackLink}
    >
      Open Slack
    </Button>
  );
};

export default JoinSlackButton;
