import { ListItem, ListItemText } from "@mui/material";
import React from "react";

const ToolbuilderOutputMenuSubheader = ({
  primary = "",
  secondary = ""
}: {
  primary?: string;
  secondary?: string;
}) => {
  return (
    <ListItem disablePadding className="m-l-8px">
      <ListItemText primary={primary} secondary={secondary} />
    </ListItem>
  );
};

export default ToolbuilderOutputMenuSubheader;
