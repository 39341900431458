import React from "react";
import { useLimitedHomePageEntities } from "../../../entities/hooks/useLimitedHomePageEntities";
import ToolsTable from "./ToolsTable";
import TableSkeleton from "../layout/TableSkeleton";
import NoRecentData from "../NoRecentData";

const ToolsTableContainer = () => {
  const { limitedEntities, loading } = useLimitedHomePageEntities(5);
  if (loading) {
    return <TableSkeleton numOfRows={5} className="p-16px" />;
  }
  const isHomePageEntitiesEmpty = limitedEntities?.length === 0 && !loading;
  if (isHomePageEntitiesEmpty) {
    return (
      <NoRecentData
        header="Your recent tools will be stored here"
        subHeader="Ready to start? Easily find your first tool in our marketplace and begin exploring the possibilities."
      />
    );
  }
  return <ToolsTable homePageEntities={limitedEntities} />;
};

export default ToolsTableContainer;
