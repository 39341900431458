import {
  DynamicFieldType,
  type KnowledgeBlockSettings
} from "@toolflow/shared";

export const knowledgeSettingsInitialState: KnowledgeBlockSettings = {
  query: "",
  count: {
    type: DynamicFieldType.Preset,
    value: 5
  },
  minScore: {
    type: DynamicFieldType.Preset,
    value: 0
  }
};

export const knowledgeInitialState: {
  settings: KnowledgeBlockSettings;
} = {
  settings: knowledgeSettingsInitialState
};
