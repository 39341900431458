import React from "react";
import CopyLinkMenuItem from "../../../../../utilities/components/dropdowns/menuItems/CopyLinkMenuItem";
import { EntityType } from "@toolflow/shared";

function CopyToolLinkMenuItem({ toolId }: { toolId: string }) {
  return (
    <CopyLinkMenuItem
      objectId={toolId}
      text="Copy link"
      type={EntityType.TOOL}
    />
  );
}

export default CopyToolLinkMenuItem;
