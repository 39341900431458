import React, { FC } from "react";
import { Box, Chip } from "@mui/material";
import { TUseCaseTag } from "../../editorToolCard/toolCategorization/hooks/useCategoryTags";
import styles from "./useCaseChips.module.css";
import { CheckIcon } from "../../../../../globalTheme/icons/icons";

interface IMarketplaceUseCasesProps {
  useCases: TUseCaseTag[];
  handleToggleUseCaseSelection: (useCase: TUseCaseTag) => void;
}

// Silence the ripple effect on the chips, but TS errors without this
declare module "@mui/material/Chip" {
  interface ChipOwnProps {
    disableRipple?: boolean;
  }
}

export const MarketplaceChip = ({
  selected,
  value,
  onClick
}: {
  selected: boolean;
  value: string;
  onClick: () => void;
}) => {
  return (
    <Chip
      disableRipple
      clickable
      className={`m-r-8px m-b-8px capitalize ${
        selected ? styles.selected : styles.unselected
      }`}
      label={<span style={{ color: "#121729DE" }}>{value}</span>}
      variant="outlined"
      icon={selected ? <CheckIcon /> : undefined}
      key={value}
      color={selected ? "primary" : "default"}
      onClick={onClick}
    />
  );
};

const UseCaseChips: FC<IMarketplaceUseCasesProps> = (props) => {
  const { useCases, handleToggleUseCaseSelection } = props;
  return (
    <Box className="m-v-16px">
      {useCases.map((useCase) => (
        <MarketplaceChip
          key={useCase.value}
          value={useCase.value}
          selected={useCase.selected}
          onClick={() => handleToggleUseCaseSelection(useCase)}
        />
      ))}
    </Box>
  );
};

export default UseCaseChips;
